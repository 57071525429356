import { MdHubNotInitializedError } from '../errors'
import { AdditionalData } from '../domain/AdditionalData'

export default async function setMemedAdditionalData(additionalData: AdditionalData): Promise<void> {
  if (!('MdHub' in window)) {
    throw MdHubNotInitializedError
  }

  const { registro } = additionalData
  await window.MdHub.command.send('plataforma.prescricao', 'setAdditionalData', {
    header: [
      {
          Registro: registro,
      }
    ]
  })
}
