import { IMedicalRecord, IEvolucao } from "../../../models/MedicalRecord"
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, AppDispatch } from "store/index";
import { toast } from "react-toastify";
import toastOptions from "utils/toastOptions";
import api from "utils/API";

interface IInitialState {
  isFetchingEvolution: boolean;
  isUpdatingEvolution: boolean;
  isCreatingEvolution: boolean;
  evolution: IEvolucao | null,
  isSyncingAnamnese: boolean,
}

const initialState: IInitialState = {
  isFetchingEvolution: false,
  isUpdatingEvolution: false,
  isCreatingEvolution: false,
  evolution: null,
  isSyncingAnamnese: false
};

const evolutionsSlice = createSlice({
  name: "evolutionsSlice",
  initialState,
  reducers: {
    setIsFetchingEvolution: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingEvolution = payload;
    },
    setIsSyncingAnamnese: (state, { payload }: PayloadAction<boolean>) => {
      state.isSyncingAnamnese = payload;
    },
    setIsUpdatingEvolution: (state, { payload }: PayloadAction<boolean>) => {
      state.isUpdatingEvolution = payload;
    },
    setIsCreatingEvolution: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreatingEvolution = payload;
    },
    setEvolution: (state, { payload }: PayloadAction<IEvolucao>) => {
      state.evolution = payload
    },
  },
});

export const createEvolution = ({
  idMedicalRecord,
  idEvolution,
  data
}: {
  idMedicalRecord: string;
  idEvolution: string;
  data: any;
}): AppThunk => async (
  dispatch: AppDispatch
) => {
  const { setIsCreatingEvolution } = evolutionsSlice.actions;
  try {
    dispatch(setIsCreatingEvolution(true));
    await api.put(`/api/evolution/${idMedicalRecord}/${idEvolution}`, data);
    toast.success("Anamnese cadastrada com sucesso", toastOptions);
    dispatch(setIsCreatingEvolution(false));
  } catch (error) {
    dispatch(setIsCreatingEvolution(false));
    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);
    } else {
      console.log(error.message);
    }
  }
};

export const evolutionSyncUpdate = ({
  idMedicalRecord,
  idEvolution,
  data
}: {
  idMedicalRecord: string;
  idEvolution: string;
  data: any;
}): AppThunk => async (
    dispatch: AppDispatch
) => {
  const { setIsSyncingAnamnese } = evolutionsSlice.actions;
  try{
    dispatch(setIsSyncingAnamnese(true))
    await api.put(`/api/evolution/${idMedicalRecord}/${idEvolution}`, data);
    dispatch(setIsSyncingAnamnese(false))
    // toast.success("Prontuário atualizado com sucesso!", {
    //  ...toastOptions,
    //  toastId: "medical-record-success-update",
    //  autoClose: 1000
    // });
  }catch(error){
    dispatch(setIsSyncingAnamnese(false))
    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);
    } else {
      console.log(error.message);
    }
  }
}

export const fetchEvolution = ({
  idMedicalRecord,
}: {
  idMedicalRecord: string,
}): AppThunk => async (dispatch: AppDispatch) => {
  const { setIsFetchingEvolution, setEvolution } = evolutionsSlice.actions

  dispatch(setIsFetchingEvolution(true))
  try {
    const response = await api.get(`/api/evolution/${idMedicalRecord}`)
    dispatch(setEvolution(response.data.data));
    dispatch(setIsFetchingEvolution(false))
  } catch (error) {
    dispatch(setIsFetchingEvolution(false))
    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);
    } else {
      console.log(error)
    }
  }
}

export default evolutionsSlice.reducer;
