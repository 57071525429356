export interface IPatientMedicalReportAnamneseOftalmo {
  idpaciente?: string;
  acuidadevisual_od_sc?: string;
  acuidadevisual_od_cc?: string;
  acuidadevisual_od_cc_lente?: string;
  acuidadevisual_oe_sc?: string;
  acuidadevisual_oe_cc?: string;
  acuidadevisual_oe_cc_lente?: string;
  oculosemuso_od_esf?: string;
  oculosemuso_od_cil?: string;
  oculosemuso_od_eixo?: string;
  oculosemuso_oe_esf?: string;
  oculosemuso_oe_cil?: string;
  oculosemuso_oe_eixo?: string;
  oculosemuso_od_adicao?: string;
  oculosemuso_oe_adicao?: string;
  tonometria_od?: string;
  tonometria_oe?: string;
  autorefração_dinamica_od_esf?: string;
  autorefração_dinamica_od_cil?: string;
  autorefração_dinamica_od_eixo?: string;
  autorefração_dinamica_od_av?: string;
  autorefração_dinamica_oe_esf?: string;
  autorefração_dinamica_oe_cil?: string;
  autorefração_dinamica_oe_eixo?: string;
  autorefração_dinamica_oe_av?: string;
  autorefração_estatica_od_esf?: string;
  autorefração_estatica_od_cil?: string;
  autorefração_estatica_od_eixo?: string;
  autorefração_estatica_od_av?: string;
  autorefração_estatica_oe_esf?: string;
  autorefração_estatica_oe_cil?: string;
  autorefração_estatica_oe_eixo?: string;
  autorefração_estatica_oe_av?: string;
  ceratometria_od_k1?: string;
  ceratometria_od_k2?: string;
  ceratometria_od_km?: string;
  ceratometria_od_eixo?: string;
  ceratometria_oe_k1?: string;
  ceratometria_oe_k2?: string;
  ceratometria_oe_km?: string;
  ceratometria_oe_eixo?: string;
  observacoes?: string;
  avaliacaoqueixa?: string;
  refracaodinamica_od_esf?: string;
  refracaodinamica_od_cil?: string;
  refracaodinamica_od_eixo?: string;
  refracaodinamica_od_av?: string;
  refracaodinamica_od_adicao?: string;
  refracaodinamica_od_avpreto?: string;
  refracaodinamica_oe_esf?: string;
  refracaodinamica_oe_cil?: string;
  refracaodinamica_oe_eixo?: string;
  refracaodinamica_oe_av?: string;
  refracaodinamica_oe_adicao?: string;
  refracaodinamica_oe_avpreto?: string;
  refracaoestatica_od_esf?: string;
  refracaoestatica_od_cil?: string;
  refracaoestatica_od_eixo?: string;
  refracaoestatica_od_av?: string;
  refracaoestatica_oe_esf?: string;
  refracaoestatica_oe_cil?: string;
  refracaoestatica_oe_eixo?: string;
  refracaoestatica_oe_av?: string;
  biomicroscopia_od_k1?: string;
  biomicroscopia_oe_k1?: string;
  fundoscopia_od_k1?: string;
  fundoscopia_oe_k1?: string;
  avaliacao?: string;
  conduta?: string;
  diagnostico?: string;
  status?: string;
  datanascimento?: String;
  datacriacao?: Date;
  peso?: number;
  altura?: number;
  nomepaciente?: string;
  sexo?: string;
  status_triagem?: boolean;
  tabagismo?: boolean;
  glaucoma?: boolean;
  colesterol_alto?: boolean;
  cardiopatia?: boolean;
  dmri?: boolean;
  ceratocone?: boolean;
  tabagismo_detalhe?: String;
  glaucoma_detalhe?: String;
  colesterol_detalhe?: String;
  cardiopatia_detalhe?: String;
  dmri_detalhe?: String;
  ceratocone_detalhe?: String;
  outros_antecedentes?: String;
  medicamentos_em_uso?: String;
  cirurgias_previas?: String;
  // Non-oftalm infos
  pressao_arterial?: string;
  temperatura?: string;
  oximetria?: string;
  notas?: string;
  hda?: string;
}

export const initialState: IPatientMedicalReportAnamneseOftalmo = {};
