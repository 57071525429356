import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AppThunk } from "store";
import api from "utils/API";
import toastOptions from "utils/toastOptions";
import {
	HealthPlanServiceTussResponse,
  HealthPlanServiceTussState
} from "./HealthPlanServiceTussReducer.types";

const initialState: HealthPlanServiceTussState = {
  isFetchingHealthPlanServiceTuss: false,
  healthPlanServicesTuss: [],
};

const healthPlanServiceTussSlice = createSlice({
	name: "healthPlanServiceTuss",
	initialState,
	reducers: {
		setIsFetchingHealthPlanServiceTuss: (state, { payload }) => {
			state.isFetchingHealthPlanServiceTuss = payload;
		},
		setHealthPlanServicesTuss: (state, { payload }) => {
			state.healthPlanServicesTuss = payload;
		},
	},
});

export const fetchHealthPlanServiceTuss =	({ 
	tussDescription 
}: { 
	tussDescription?: string
}): AppThunk =>
	async (dispatch) => {
		const {
			setIsFetchingHealthPlanServiceTuss,
      setHealthPlanServicesTuss
		} = healthPlanServiceTussSlice.actions;
		dispatch(setIsFetchingHealthPlanServiceTuss(true));
		try {
			let queryParams = "";

			if (tussDescription) {
				queryParams = `?descricao=${tussDescription}`;
			}

			const response = await api.get<HealthPlanServiceTussResponse>(`/api/healthplanservicetuss${queryParams}`);

			dispatch(setHealthPlanServicesTuss(response.data.data.health_plan_tuss));
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingHealthPlanServiceTuss(false));
		}
	};

export const {
	setHealthPlanServicesTuss
} = healthPlanServiceTussSlice.actions;

export default healthPlanServiceTussSlice.reducer;
