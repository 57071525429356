import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import {
  Dialog,
  Toolbar,
  IconButton,
  AppBar,
  Typography,
  Grid,
  TextField,
  Button,
  Chip,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import FullScreenDialogTransition from "components/FullScreenDialogTransition";
import { FullWidthContainer } from "components/layout/base";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useSelector } from "react-redux";
import {
  setDisponibilityMapProfessionals,
  setDisponibilityMapServiceCategories,
  setDisponibilityMapServiceItems,
  setDisponibilityMapCurrentClinics,
  setDisponibilityMapLength,
  startUpdateDisponibilityMapFetchInterval,
  setCameFromCombos,
  setDisponibilityMapCurrentHealthPlan,
  setDisponibilityMap,
  setModalHover,
} from "store/reducers/DisponibilityMapReducer";
import { Face as FaceIcon } from "@material-ui/icons";
import {
  resetClinicTimetableFilters,
  resetDisponibilityMapFilters,
  setBlockPatientSearch,
  setCheckinRemarcacao,
  setIdConsultaRemarcacao,
  setIsDisponibilityMapDialogOpen,
  setRemarcacao,
  setSchedulePageHealthPlans,
  setSchedulepageserviceItems,
  updateSchedulePageFilterArray,
} from "store/reducers/SchedulePageReducer";
import { useDispatch } from "react-redux";
import { rootState } from "store";
import DisponibilityList from "./components/DisponibilityList";
import {
  fetchDisponibilityMap,
  updateDisponibilityMapFilterArray,
} from "store/reducers/DisponibilityMapReducer";
import {
  fetchServiceCategoriesActive,
  resetServiceCategories,
} from "store/reducers/ServiceCategoriesReducer";
import {
  fetchServiceItemsByProfessionalAndCategory,
  updateServiceItemsSchedulePageFilter,
} from "store/reducers/ServiceItemsReducer";
import { autocompleteDefaultProps } from "models/Autocomplete";
import {
  fetchProfessionals,
  fetchProfessionalsByServiceCategory,
} from "store/reducers/ProfessionalsReducer";

import { setSchedulePageClinics } from "store/reducers/SchedulePageReducer";
import { Loader } from "components/Loader";
import { initialOptions } from "pages/HealthPlanCategory/Form/Form.types";
import useDebounce from "hooks/useDebouce";
import ModalHoverCard from "./components/DisponibilityListItem/ModalHoverCard";
import DateRangeDialog from "components/DateRangeDialog";
import dayjs from "dayjs";
import { differenceInDays, startOfDay } from "date-fns";
import { toast } from "react-toastify";
import toastOptions from "utils/toastOptions";

interface IProps {
  open: boolean;
  onClose: Function;
  onFilterScheduleOnEventClick: Function;
}

interface ISelectOption {
  label: string;
  value: string;
  initialDay?: number;
}

export const dateRangeOptions = [
  { label: "7 dias", value: "7", initialDay: 0 },
  { label: "Próximos 7 dias (8º ao 14º dia)", value: "7", initialDay: 7 },
  { label: "Próximos 7 dias (15º ao 21º dia)", value: "7", initialDay: 14 },
  { label: "Próximos 7 dias (22º ao 28º dia)", value: "7", initialDay: 21 },
  { label: "Escolher período", value: "1", initialDay: 0 },
  // { label: "7 dias", value: "7" },
  // { label: "15 dias", value: "15" },
  // { label: "30 dias", value: "30" },
  // { label: "60 dias", value: "60" },
  // { label: "90 dias", value: "90" },
  // { label: "120 dias", value: "120" },
];

const initialDateState = [
  new Date(),
  new Date(),
]

const DisponibilityMapDialog: React.FC<IProps> = ({
  onClose,
  onFilterScheduleOnEventClick,
  open,
}) => {
  // REDUX
  const dispatch = useDispatch();
  const {
    length,
    disponibilityMap,
    isFetchingDisponibilityMap,
    currentProfessionals,
    currentServiceCategories,
    currentClinics,
    currentServiceItems,
    cameFromCombos,
    currentHealthPlan,
    modalHover,
    dataModalHover,
    disponibilityMapFilterArray,
  } = useSelector((state: rootState) => state.disponibilityMap);
  const { signedInUser } = useSelector((state: rootState) => state.users);
  const { isFetchingClinics } = useSelector(
    (state: rootState) => state.clinics
  );
  const { isFetchingServiceCategories, serviceCategories } = useSelector(
    (state: rootState) => state.serviceCategories
  );
  const {
    schedulePageCurrentClinics,
    isDisponibilityMapDialogOpen,
    currentPatient,
    isSchedulingCombo,
  } = useSelector((state: rootState) => state.schedulePage);
  const { isFetchingProfessionals, professionalsSelectOptions } = useSelector(
    (state: rootState) => state.professionals
  );
  const {
    serviceItemsSelectOptions,
    isFetchingServiceItems,
    serviceItemsSchedulePageFilterArray,
  } = useSelector((state: rootState) => state.serviceItems);
  const { allHealthPlans, isFetchingHealthPlans } = useSelector(
    (state: rootState) => state.healthPlan
  );
  const { allHealthPlansCategory, isFetchingHealthPlansCategory } = useSelector(
    (state: rootState) => state.healthPlanCategory
  );

  const searchButtonRef = useRef<HTMLButtonElement>(null);

  // STATES
  const [serviceCategoriesFirstRun, setServiceCategoriesFirstRun] =
    useState<boolean>(true);
  const [professionalsFirstRun, setProfessionalsFirstRun] =
    useState<boolean>(true);
  const [serviceItemsFirstRun, setServiceItemsFirstRun] =
    useState<boolean>(true);
  const [clinicsItemsFirstRun, setClinicsItemsFirstRun] =
    useState<boolean>(true);
  const [serviceItemsFilterArrayFirstRun, setServiceItemsFilterArrayFirstRun] =
    useState<boolean>(true);
  const [clinicsOptions, setClinicsOptions] = useState<ISelectOption[]>([]);
  const [serviceCategoriesOptions, setServiceCategoriesOptions] = useState<
    ISelectOption[]
  >([]);
  const [professionalsOptions, setProfessionalsOptions] = useState<
    ISelectOption[]
  >([]);
  const [serviceItemsOptions, setServiceItemsOptions] = useState<
    ISelectOption[]
  >([]);
  const [initialDay, setInitialDay] = useState<number>(0);
  const [changePeriod, setChangePeriod] = useState(false);
  const [isDateRangeOpen, setIsDateRangeOpen] = useState(false);
  const [filterDateRange, setFilterDateRange] = useState(initialDateState);

  const [currentServiceItemSearch, setCurrentServiceItemSearch] = useState("");
  const debouncedCurrentServiceSearch = useDebounce(
    currentServiceItemSearch,
    500
  );

  useEffect(() => {
    if (currentServiceItemSearch.length > 2) {
      dispatch(
        updateServiceItemsSchedulePageFilter({
          key: "search",
          value: debouncedCurrentServiceSearch,
        })
      );
    }
  }, [debouncedCurrentServiceSearch, dispatch]);

  const healthPlansOptions = useMemo(() => {
    if (allHealthPlans.length > 0) {
      const healthPlansOptions = allHealthPlans.map((healthPlan) => ({
        value: healthPlan.idconvenio,
        label: healthPlan.nome.toUpperCase(),
      }));
      return healthPlansOptions as ISelectOption[];
    }

    return [];
  }, [allHealthPlans]);

  const healthPlansCategoriesOptions = useMemo(() => {
    if (allHealthPlansCategory.length > 0) {
      const healthPlansCategoryOptions = allHealthPlansCategory.map(
        (healthPlanCategory) => ({
          value: healthPlanCategory.id,
          label: healthPlanCategory.nome.toUpperCase(),
        })
      );
      return healthPlansCategoryOptions as ISelectOption[];
    }

    return [];
  }, [allHealthPlansCategory]);

  function fetchData() {
    // dispatch(fetchDisponibilityMap({}));
    dispatch(resetServiceCategories());
    dispatch(fetchServiceCategoriesActive({ limit: 9999 }));
    dispatch(fetchProfessionals({ limit: 999 }));
    // dispatch(fetchServiceItemsByProfessionalAndCategory({ limit: 99 }));
  }

  function handleChangeServiceItems(event: any, newValue: ISelectOption[]) {
    dispatch(setSchedulepageserviceItems(newValue));
    dispatch(
      updateSchedulePageFilterArray({
        key: "iditemservico",
        value: newValue.map((item) => item.value),
      })
    );
    dispatch(setDisponibilityMapServiceItems(newValue));
    dispatch(
      updateDisponibilityMapFilterArray({
        key: "iditemservico",
        value: newValue.map((item) => item.value),
      })
    );
  }

  function handleChangeServiceCategories(
    event: any,
    newValue: ISelectOption[]
  ) {
    dispatch(setDisponibilityMapServiceCategories(newValue));

    // limpa o campo de profissionais
    dispatch(setDisponibilityMapProfessionals([]));
    // limpa itens de serviço
    dispatch(
      updateSchedulePageFilterArray({
        key: "iditemservico",
        value: [],
      })
    );
    dispatch(setDisponibilityMapServiceItems([]));
    dispatch(
      updateDisponibilityMapFilterArray({
        key: "iditemservico",
        value: [],
      })
    );
  }

  function handleChangeProfessionals(event: any, newValue: ISelectOption[]) {
    dispatch(setDisponibilityMapProfessionals(newValue));
    // limpa itens de serviço
    dispatch(
      updateSchedulePageFilterArray({
        key: "iditemservico",
        value: [],
      })
    );
    dispatch(setDisponibilityMapServiceItems([]));
    dispatch(
      updateDisponibilityMapFilterArray({
        key: "iditemservico",
        value: [],
      })
    );
  }

  function onSelectItem() {
    dispatch(setIsDisponibilityMapDialogOpen(false));
  }

  function handleCleanDisponibilityMapSerchFilters() {
    setCurrentServiceItemSearch("");
    dispatch(resetClinicTimetableFilters());
    dispatch(resetDisponibilityMapFilters());
    dispatch(setRemarcacao(false));
    dispatch(setCheckinRemarcacao(false));
    dispatch(setIdConsultaRemarcacao(""));
  }

  function filterAnalysis() {
    if (isFetchingDisponibilityMap) return true;
    if (!currentClinics.length) return true;
    if (
      !isFetchingDisponibilityMap &&
      (currentServiceCategories.length ||
        currentProfessionals.length ||
        currentServiceItems.length) &&
      currentClinics.length
    )
      return false;
    return true;
  }
  function handleCloseModal() {
    dispatch(setModalHover(false));
  }

  function hoursFormat(hours: string[]): string {
    let text = "";
    if (!hours) return "";
    hours.forEach((hour) => {
      return (text = `${text ? text + " - " : ""} ${hour}`);
    });
    return text;
  }

  useEffect(() => {
    setChangePeriod(false);
    setFilterDateRange(initialDateState);
    setInitialDay(0);
    dispatch(setDisponibilityMapLength({ label: "7 dias", value: "7", initialDay: 0 }));
    dispatch(
      updateDisponibilityMapFilterArray({
        key: "length",
        value: 7,
      })
    );
    dispatch(setDisponibilityMap(null));
  }, [
    currentClinics,
    currentServiceCategories,
    currentProfessionals,
    currentServiceItems,
    currentHealthPlan,
  ]);

  useEffect(() => {
    if (cameFromCombos) {
      if (searchButtonRef.current) {
        searchButtonRef.current.click();
        dispatch(setCameFromCombos(false));
      }
    }
  }, []);

  useEffect(() => {
    if (serviceCategories) {
      setServiceCategoriesOptions(
        serviceCategories.map((category) => {
          return {
            label: category.nome.toUpperCase(),
            value: category.idcategoriaservico.toString(),
          };
        })
      );
    }
  }, [serviceCategories]);

  useEffect(() => {
    if (signedInUser && signedInUser.clinicas) {
      setClinicsOptions(
        signedInUser.clinicas?.map(({ nome, idclinica }) => {
          return { label: nome.toUpperCase(), value: idclinica };
        })
      );
    }
  }, [signedInUser]);

  useEffect(() => {
    if (professionalsSelectOptions) {
      setProfessionalsOptions(
        professionalsSelectOptions
          .filter((option) => option.hasSchedule)
          .map((professional) => {
            return {
              label: professional.name.toUpperCase(),
              value: professional.id,
            };
          })
      );
    }
  }, [professionalsSelectOptions, dispatch]);

  useEffect(() => {
    if (serviceItemsSelectOptions) {
      setServiceItemsOptions(
        serviceItemsSelectOptions.map(({ name, id }) => {
          return {
            label: name.toUpperCase(),
            value: id,
          };
        })
      );
    }
  }, [serviceItemsSelectOptions]);

  useEffect(() => {
    if (serviceCategoriesFirstRun) {
      setServiceCategoriesFirstRun(false);
    } else {
      dispatch(
        fetchProfessionalsByServiceCategory(
          currentServiceCategories.map((category) => category.value)
        )
      );
      dispatch(
        updateServiceItemsSchedulePageFilter({
          key: "idcategories",
          value: currentServiceCategories.map((category) => category.value),
        })
      );
      dispatch(
        updateDisponibilityMapFilterArray({
          key: "idcategoriaservico",
          value: currentServiceCategories.map((category) => category.value),
        })
      );
      dispatch(
        updateDisponibilityMapFilterArray({
          key: "search",
          value: "",
        })
      );
      dispatch(
        updateServiceItemsSchedulePageFilter({
          key: "search",
          value: "",
        })
      );
      setServiceItemsOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentServiceCategories, dispatch]);

  useEffect(() => {
    if (serviceItemsFilterArrayFirstRun) {
      setServiceItemsFilterArrayFirstRun(false);
    } else {
      const findServiceItemsSchedulePage =
        serviceItemsSchedulePageFilterArray.find(
          (value) => value.key === "search" && value.value
        );

      if (findServiceItemsSchedulePage) {
        dispatch(
          updateDisponibilityMapFilterArray({
            key: "search",
            value: findServiceItemsSchedulePage.value,
          })
        );
        dispatch(
          updateServiceItemsSchedulePageFilter({
            key: "search",
            value: findServiceItemsSchedulePage.value,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    serviceItemsSchedulePageFilterArray,
    dispatch,
  ]);

  useEffect(() => {
    if (professionalsFirstRun) {
      setProfessionalsFirstRun(false);
    } else {
      dispatch(
        updateDisponibilityMapFilterArray({
          key: "idprofissional",
          value: currentProfessionals.map((professional) => professional.value),
        })
      );
      dispatch(
        updateServiceItemsSchedulePageFilter({
          key: "idprofessionals",
          value: currentProfessionals.map((professional) => professional.value),
        })
      );
      dispatch(
        updateDisponibilityMapFilterArray({
          key: "search",
          value: "",
        })
      );
      dispatch(
        updateServiceItemsSchedulePageFilter({
          key: "search",
          value: "",
        })
      );
      setServiceItemsOptions([]);
    }
  }, [currentProfessionals, dispatch]);

  useEffect(() => {
    if (clinicsItemsFirstRun) {
      setClinicsItemsFirstRun(false);
      dispatch(setDisponibilityMapCurrentClinics(schedulePageCurrentClinics));
    } else {
      dispatch(
        updateDisponibilityMapFilterArray({
          key: "idclinica",
          value: currentClinics.map((clinics) => {
            return clinics.value;
          }),
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentClinics,
    dispatch,
    schedulePageCurrentClinics,
  ]);

  useEffect(() => {
    if (!open) {
      dispatch(setSchedulePageClinics(currentClinics));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, dispatch, currentClinics]);

  useEffect(() => {
    if (serviceItemsFirstRun) {
      setServiceItemsFirstRun(false);
    } else {
      if (currentServiceItems.length === 0) {
        dispatch(
          updateDisponibilityMapFilterArray({
            key: "iditemservico",
            value: null,
          })
        );
      } else {
        dispatch(
          updateDisponibilityMapFilterArray({
            key: "iditemservico",
            value: currentServiceItems.map((serviceItem) => serviceItem.value),
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentServiceItems, dispatch]);

  useEffect(() => {
    if (isDisponibilityMapDialogOpen) {
      dispatch(setDisponibilityMapLength({ label: "7 dias", value: "7", initialDay: 0 }));
      dispatch(
        updateDisponibilityMapFilterArray({
          key: "length",
          value: 7,
        })
      );
      if (
        disponibilityMapFilterArray.some(
          (e) => e.key === "idclinica" && e.value && e.value.length > 0
        ) &&
        (currentServiceCategories.length ||
          currentProfessionals.length ||
          currentServiceItems.length)
      ) {
        dispatch(fetchDisponibilityMap({}));
      }
    }
  }, [isDisponibilityMapDialogOpen]);

  function onChangeDateRange(dateRange: any) {
    setFilterDateRange([dateRange[0].startDate, dateRange[0].endDate]);

    const interval = differenceInDays(dateRange[0].endDate, dateRange[0].startDate);
    const intervalInitialDay = differenceInDays(dateRange[0].startDate, startOfDay(new Date()));


    if (interval > 60) {
      toast.warn("Insira um período inferior a 60 dias", toastOptions);
      setFilterDateRange(initialDateState);
      return;
    }

    setInitialDay(intervalInitialDay);

    dispatch(
      updateDisponibilityMapFilterArray({
        key: "length",
        value: interval + 1,
      })
    );
  }

  return (
    <Dialog
      TransitionComponent={FullScreenDialogTransition}
      fullScreen
      open={open}
      style={{ marginTop: 64 }}
    >
      <AppBar>
        <Toolbar style={{ color: "#fff" }}>
          <IconButton style={{ color: "#fff" }} onClick={() => onClose()}>
            <Close />
          </IconButton>
          <Typography variant="h5" style={{ marginLeft: "1rem" }}>
            Mapa de disponibilidade
          </Typography>
        </Toolbar>
      </AppBar>
      <FullWidthContainer>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              spacing={1}
              alignItems="center"
              justify="space-between"
              style={{
                transform: "scale(0.9)",
                flexWrap: "nowrap",
              }}
            >
              {/* FILTRO PERÍODO */}
              <Grid item xs={12} md={2}>
                <Autocomplete
                  {...autocompleteDefaultProps}
                  disableClearable
                  options={dateRangeOptions ?? initialOptions}
                  getOptionSelected={(
                    option: ISelectOption,
                    value: ISelectOption
                  ) => {
                    return value.initialDay === option.initialDay && value.value === option.value;
                  }}
                  value={length}
                  onChange={(_: any, newValue: ISelectOption) => {
                    dispatch(setDisponibilityMapLength(newValue));
                    dispatch(
                      updateDisponibilityMapFilterArray({
                        key: "length",
                        value: Number(newValue.value),
                      })
                    );

                    if (newValue.initialDay) {
                      setInitialDay(newValue.initialDay)
                    }

                    if (newValue.initialDay === 0 && newValue.value === "1") {
                      setChangePeriod(true);
                    } else {
                      setChangePeriod(false);
                      setFilterDateRange(initialDateState);
                    }
                  }}
                  fullWidth
                  renderInput={(params: any) => (
                    <TextField variant="outlined" {...params} label="Período" />
                  )}
                />
              </Grid>

              {changePeriod &&
                <Grid item xs={12} md={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setIsDateRangeOpen(true)}
                  >
                    <Typography
                      component="span"
                      style={{
                        fontSize: 14,
                      }}
                    >
                      {`${dayjs(filterDateRange[0]).format(
                        "DD/MM/YYYY"
                      )} - ${dayjs(filterDateRange[1]).format("DD/MM/YYYY")}`}
                    </Typography>
                  </Button>
                </Grid>
              }

              {/* FILTRO CLÍNICA */}
              <Grid item xs={12} md={2}>
                <Autocomplete
                  {...autocompleteDefaultProps}
                  disableClearable
                  multiple
                  options={clinicsOptions ?? initialOptions}
                  value={currentClinics}
                  loading={isFetchingClinics}
                  onChange={(event: any, newValue: ISelectOption[]) => {
                    dispatch(setDisponibilityMapCurrentClinics(newValue));
                    dispatch(
                      updateDisponibilityMapFilterArray({
                        key: "idclinica",
                        value: newValue.map((item) => item.value),
                      })
                    );
                  }}
                  fullWidth
                  renderInput={(params: any) => (
                    <TextField variant="outlined" {...params} label="Clínica" />
                  )}
                />
              </Grid>

              {/* FILTRO ESPECIALIDADES */}
              <Grid item xs={12} md={3}>
                <Autocomplete
                  {...autocompleteDefaultProps}
                  loading={isFetchingServiceCategories}
                  multiple
                  options={serviceCategoriesOptions ?? initialOptions}
                  value={currentServiceCategories}
                  onChange={(event: any, newValue: ISelectOption[]) => {
                    handleChangeServiceCategories(event, newValue);
                  }}
                  fullWidth
                  renderInput={(params: any) => (
                    <TextField
                      variant="outlined"
                      {...params}
                      label="Especialidade"
                    />
                  )}
                />
              </Grid>

              {/* FILTRO PROFISSIONAIS */}
              <Grid item xs={12} md={3}>
                <Autocomplete
                  multiple
                  {...autocompleteDefaultProps}
                  loading={isFetchingProfessionals}
                  value={currentProfessionals}
                  onChange={(event: any, newValue: ISelectOption[]) => {
                    handleChangeProfessionals(event, newValue);
                  }}
                  options={professionalsOptions ?? initialOptions}
                  fullWidth
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Profissionais"
                    />
                  )}
                />
              </Grid>

              {/* FILTRO ITENS DE SERVIÇO */}
              <Grid item xs={12} md={3}>
                <Autocomplete
                  {...autocompleteDefaultProps}
                  loading={isFetchingServiceItems}
                  loadingText="Carregando..."
                  multiple
                  value={currentServiceItems}
                  onChange={(event: any, newValue: ISelectOption[]) => {
                    handleChangeServiceItems(event, newValue);
                  }}
                  options={serviceItemsOptions ?? initialOptions}
                  fullWidth
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Itens de serviço"
                      onChange={(event) => {
                        setCurrentServiceItemSearch(event.target.value);
                      }}
                    />
                  )}
                />
              </Grid>

              {/* FILTRO PLANO DE CONVÊNIO */}
              <Grid item xs={12} md={2}>
                <Autocomplete
                  {...autocompleteDefaultProps}
                  loading={isFetchingHealthPlansCategory}
                  loadingText="Carregando..."
                  value={currentHealthPlan}
                  onChange={(event: any, newValue: ISelectOption | null) => {
                    dispatch(
                      setSchedulePageHealthPlans(newValue as ISelectOption)
                    );
                    dispatch(
                      setDisponibilityMapCurrentHealthPlan(
                        newValue as ISelectOption
                      )
                    );
                    dispatch(
                      updateDisponibilityMapFilterArray({
                        key: "idplano",
                        value: newValue && newValue.value,
                      })
                    );
                    dispatch(
                      updateSchedulePageFilterArray({
                        key: "idplano",
                        value: newValue && newValue.value,
                      })
                    );
                  }}
                  options={healthPlansCategoriesOptions ?? initialOptions}
                  fullWidth
                  disabled={isSchedulingCombo}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Plano de convênio"
                    />
                  )}
                />
              </Grid>

              {/* FILTRO CONVÊNIO */}
              {/* <Grid item xs={12} md={2}>
                <Autocomplete
                  {...autocompleteDefaultProps}
                  loading={isFetchingHealthPlans}
                  loadingText="Carregando..."
                  value={currentHealthPlan}
                  onChange={(event: any, newValue: ISelectOption | null) => {
                    dispatch(setSchedulePageHealthPlans(newValue as ISelectOption));
                    dispatch(setDisponibilityMapCurrentHealthPlan(newValue as ISelectOption));
                    dispatch(
                      updateDisponibilityMapFilterArray({
                        key: "idconvenio",
                        value: newValue && newValue.value
                      })
                    );
                  }}
                  options={healthPlansOptions ?? initialOptions}
                  fullWidth
                  renderInput={(params: any) => <TextField {...params} variant="outlined" label="Convênio" />}
                />
              </Grid> */}

              {/* BOTÃO LIMPAR */}
              <Grid item xs={12} md={1}>
                <Button
                  fullWidth
                  color="secondary"
                  variant="contained"
                  onClick={() => handleCleanDisponibilityMapSerchFilters()}
                  disabled={
                    currentClinics.length === 0 &&
                    currentProfessionals.length === 0 &&
                    currentServiceCategories.length === 0 &&
                    currentServiceItems.length === 0
                  }
                >
                  Limpar
                </Button>
              </Grid>

              {/* BOTÃO BUSCAR */}
              <Grid item xs={12} md={1}>
                <Button
                  ref={searchButtonRef}
                  fullWidth
                  disabled={filterAnalysis()}
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    dispatch(fetchDisponibilityMap({ initialDay }));
                    dispatch(startUpdateDisponibilityMapFetchInterval());
                  }}
                >
                  Buscar
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {currentPatient.label && (
            <Grid
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Chip
                color="primary"
                style={{ backgroundColor: "#00a1a9" }}
                size="small"
                label={currentPatient.label}
                icon={<FaceIcon />}
              />
            </Grid>
          )}
          {/* LOADER OU NÃO TEM NENHUM DADO */}
          <Grid item xs={12}>
            {isFetchingDisponibilityMap ? (
              <Loader />
            ) : disponibilityMap && disponibilityMap.length > 0 ? (
              <DisponibilityList
                onSelectItem={onSelectItem}
                data={disponibilityMap}
              />
            ) : disponibilityMap === null ? (
              <Typography
                variant="h5"
                style={{ fontSize: "1.2rem", textAlign: "center" }}
              >
                Preencha os filtros acima e clique em buscar
              </Typography>
            ) : (
              <Typography
                variant="h5"
                style={{ fontSize: "1.2rem", textAlign: "center" }}
              >
                Sem registro de agendas nos próximos dias, verifique o
                cadastro da especialidade, médico ou serviço.
              </Typography>
            )}
          </Grid>

          {isDateRangeOpen && (
            <DateRangeDialog
              onChangeDate={onChangeDateRange}
              handleClose={() => setIsDateRangeOpen(false)}
              open={isDateRangeOpen}
              minDate
            />
          )}
        </Grid>
      </FullWidthContainer>
      <ModalHoverCard
        name={dataModalHover.nome || ""}
        getProfessionalHoursString={hoursFormat(dataModalHover.horario) || ""}
        appointment={dataModalHover || {}}
        clinic={dataModalHover.clinica || ""}
        speciality={dataModalHover.itensservico || []}
        open={modalHover}
        onClose={handleCloseModal}
      ></ModalHoverCard>
    </Dialog>
  );
};

export default memo(DisponibilityMapDialog);
