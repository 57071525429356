import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { IFilter } from "models/shared/index";
import { toast } from "react-toastify";
import { AppThunk } from "store/index";
import api from "utils/API";
import { queryStringFromFilterArray } from "utils/network";
import toastOptions from "utils/toastOptions";

import { IReportsProfessionals } from "models/ProfessionalReport";

interface IInitialState {
  isFetchingReport: boolean;
  receivedProfessionalsFilterArray: IFilter[];
  professionals: IReportsProfessionals | null;
}

const initialState: IInitialState = {
  isFetchingReport: false,
  receivedProfessionalsFilterArray: [
    { key: "idclinica", value: null },
    { key: "idcategoriaservico", value: [] },
    { key: "idprofissional", value: null },
    { key: "idconvenio", value: [] },
    { key: "status", value: null },
    { key: "idcanalatendimento", value: [] },
    { key: "idusuario", value: null },
    { key: "paciente", value: null },
    { key: "start_date", value: null },
    { key: "end_date", value: null },
    { key: "status_invoice", value: null },
    { key: "idpaciente", value: null },
    { key: "professional_type", value: [] },
    { key: "tipoconsulta", value: null },
  ],
  professionals: null,
};

const reportsReducer = createSlice({
  name: "ReportsComboReducer",
  initialState,
  reducers: {
    setIsFetchingReport: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingReport = payload;
    },
    clearFilter: (state, { payload }: PayloadAction<IFilter[]>) => {
      state.receivedProfessionalsFilterArray = payload;
    },
    setProfessionals: (
      state,
      {
        payload: { professionals },
      }: PayloadAction<{
        professionals: any;
      }>
    ) => {
      state.professionals = professionals;
    },
    updateReceivedProfessionalsFilterArray: (
      state,
      { payload: { key, value } }: PayloadAction<IFilter>
    ) => {
      state.receivedProfessionalsFilterArray =
        state.receivedProfessionalsFilterArray.map((filter) => {
          if (filter.key === key) {
            filter.value = value;
            state.professionals = null
          }
          return filter;
        });
    },
  },
});

export const clearFilterProfessionals =
  (): AppThunk => async (dispatch, getState) => {
    const { clearFilter, setProfessionals } = reportsReducer.actions;
    dispatch(
      clearFilter([
        { key: "idclinica", value: null },
        { key: "idcategoriaservico", value: [] },
        { key: "idprofissional", value: [] },
        { key: "idconvenio", value: [] },
        { key: "status", value: null },
        { key: "idcanalatendimento", value: [] },
        { key: "idusuario", value: null },
        { key: "paciente", value: null },
        { key: "start_date", value: null },
        { key: "end_date", value: null },
        { key: "status_invoice", value: null },
        { key: "idpaciente", value: null },
        { key: "professional_type", value: [] },
        { key: "tipoconsulta", value: null },
      ])
    );
    dispatch(
      setProfessionals({
        professionals: null,
      })
    );
  };

export const fetchReportProfessionals =
  (): AppThunk => async (dispatch, getState) => {
    const { setIsFetchingReport, setProfessionals } = reportsReducer.actions;
    const state = getState();
    const { receivedProfessionalsFilterArray } = state.reportsProfessionals;
    dispatch(setIsFetchingReport(true));

    const queryParameters = queryStringFromFilterArray(
      receivedProfessionalsFilterArray
    );
    try {
      const response = await api.get(
        `api/reports/professionals${queryParameters}`
      );
      dispatch(setProfessionals({ professionals: response.data.data }));
      dispatch(setIsFetchingReport(false));
    } catch (error: AxiosError | any) {
      dispatch(setIsFetchingReport(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const { setIsFetchingReport, updateReceivedProfessionalsFilterArray } =
  reportsReducer.actions;

export default reportsReducer.reducer;
