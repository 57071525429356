import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ISchedule } from "models/Schedule";
import { toast } from "react-toastify";
import { AppThunk } from "store";
import api from "utils/API";
import toastOptions from "utils/toastOptions";
import {
  IAppointmentGroupResponse,
  IAppointmentInfoResponse,
  IAppointmentUniqueCreation,
  IAppointmentsExceded,
  IFinalizeGroupAppointmentRequest,
} from "./interfaces";
import { AppointmentGroupModel } from "models/AppointmentGroup";
import { IAppointment } from "models/Appointments";
import { AppointmentGroupChangeLogModel } from "models/AppointmentGroupChangeLog";

interface IGroupAppointmentInfo {
  idgrupoconsulta: string;
  datacriacao: Date;
  idcombo: string | null;
  nomecombo: string;
  idplano: string | null;
  nomeplano: string;
  agendamentos: IAppointment[];
}

interface IInitialState {
  isFetchingPossibleSchedules: boolean;
  isCreatingAppointmentGroup: boolean;
  possibleSchedules: ISchedule[];
  recurringAppointmentResponses: IAppointmentGroupResponse[];
  hasRecurringAppointmentError: boolean;
  currentAppointmentGroup: AppointmentGroupModel | null;
  serviceItemsIds: string[];
  patientGroupAppointmentInfo: IGroupAppointmentInfo[];
  isFetchingPatientGroupAppointmentsInfo: boolean;
  currentAppointmentGroupHistory: AppointmentGroupChangeLogModel[];
  isFetchingAppointmentGroupHistory: boolean;
  isCreatingAppointmentGroupHistory: boolean;
  isFetchingAppointmentGroupNextInfoSet: boolean;
  appointmentGroupNextInfoSet: IAppointmentInfoResponse[];
  groupAppointmentInfoToFinalize: IFinalizeGroupAppointmentRequest;
  isFinalizingAppointmentGroup: boolean;
  isFinalizeStarted: boolean;
  appointmentSelectedOptions: IAppointmentUniqueCreation[];
  firstAppointmentInfo: IAppointmentUniqueCreation | null;
  itensRecorrencia: any;
  appointmentStartGeneral: any;
  appointmentsExceded: IAppointmentsExceded[];
}

const initialState: IInitialState = {
  isFetchingPossibleSchedules: false,
  isCreatingAppointmentGroup: false,
  possibleSchedules: [],
  recurringAppointmentResponses: [],
  hasRecurringAppointmentError: false,
  currentAppointmentGroup: null,
  serviceItemsIds: [],
  patientGroupAppointmentInfo: [],
  isFetchingPatientGroupAppointmentsInfo: false,
  currentAppointmentGroupHistory: [],
  isFetchingAppointmentGroupHistory: false,
  isCreatingAppointmentGroupHistory: false,
  appointmentGroupNextInfoSet: [],
  isFetchingAppointmentGroupNextInfoSet: false,
  groupAppointmentInfoToFinalize: {
    appointmentInfo: null,
    appointmentsToCreate: [],
    idgrupoconsulta: "",
  },
  isFinalizingAppointmentGroup: false,
  isFinalizeStarted: false,
  appointmentSelectedOptions: [],
  firstAppointmentInfo: null,
  itensRecorrencia: [],
  appointmentStartGeneral: null,
  appointmentsExceded: []
};

const appointmentsGroupSlice = createSlice({
  name: "appointmentsGroupSlice",
  initialState,
  reducers: {
    setPossibleSchedules: (state, { payload }: PayloadAction<ISchedule[]>) => {
      state.possibleSchedules = payload;
    },
    setIsFetchingPossibleSchedules: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingPossibleSchedules = payload;
    },
    setIsCreatingAppointmentGroup: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isCreatingAppointmentGroup = payload;
    },
    setRecurringAppointmentResponses: (
      state,
      { payload }: PayloadAction<IAppointmentGroupResponse[]>
    ) => {
      state.recurringAppointmentResponses = payload;
    },
    setHasRecurringAppointmentError: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.hasRecurringAppointmentError = payload;
    },
    setCurrentAppointmentGroup: (
      state,
      { payload }: PayloadAction<AppointmentGroupModel | null>
    ) => {
      state.currentAppointmentGroup = payload;
    },
    setServiceItemsIds: (state, { payload }: PayloadAction<string[]>) => {
      state.serviceItemsIds = payload;
    },
    setPatientGroupAppointmentInfo: (
      state,
      { payload }: PayloadAction<IGroupAppointmentInfo[]>
    ) => {
      state.patientGroupAppointmentInfo = payload;
    },
    setIsFetchingPatientGroupAppointmentsInfo: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingPatientGroupAppointmentsInfo = payload;
    },
    setPatientGroupAppointmentHistory: (
      state,
      { payload }: PayloadAction<AppointmentGroupChangeLogModel[]>
    ) => {
      state.currentAppointmentGroupHistory = payload;
    },
    setItensRecorrencia: (
      state,
      { payload }: PayloadAction<any[]>
    ) => {
      state.itensRecorrencia = payload;
    },
    setIsFetchingPatientGroupAppointmentsHistory: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingAppointmentGroupHistory = payload;
    },
    setIsCreatingPatientGroupAppointmentsHistory: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isCreatingAppointmentGroupHistory = payload;
    },
    setIsFetchingAppointmentGroupNextInfoSet: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingAppointmentGroupNextInfoSet = payload;
    },
    setAppointmentStartGeneral: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      state.appointmentStartGeneral = payload;
    },
    setAppointmentNextInfoSet: (
      state,
      { payload }: PayloadAction<IAppointmentInfoResponse[]>
    ) => {
      state.appointmentGroupNextInfoSet = payload;
    },
    setGroupAppointmentInfoToFinalize: (
      state,
      { payload }: PayloadAction<IFinalizeGroupAppointmentRequest>
    ) => {
      state.groupAppointmentInfoToFinalize = payload;
    },
    setIsFinalizingGroupAppointment: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFinalizingAppointmentGroup = payload;
    },
    setIsFinalizeStarted: (state, { payload }: PayloadAction<boolean>) => {
      state.isFinalizeStarted = payload;
    },
    setUpdateAppointmentSelectedOptions: (
      state,
      { payload }: PayloadAction<IAppointmentUniqueCreation>
    ) => {
      const newState: IAppointmentUniqueCreation[] = [
        ...state.appointmentSelectedOptions,
      ];

      newState.push(payload);

      state.appointmentSelectedOptions = newState;
    },
    setCleanSelectedAppointmentsOptions: (state) => {
      state.appointmentSelectedOptions = [];
    },
    setFirstAppointmentInfo: (
      state,
      { payload }: PayloadAction<IAppointmentUniqueCreation>
    ) => {
      state.firstAppointmentInfo = payload;
    },
    setAppointmentsExceded: (
      state,
      { payload }: PayloadAction<IAppointmentsExceded[]>
    ) => {
      state.appointmentsExceded = payload;
    },
  },
});

export const fetchItensRecorrencia =
  (  content?: any, cb: (data:any) => any): AppThunk =>
  async (dispatch) => {
    const { setIsFetchingPossibleSchedules, setItensRecorrencia } =
      appointmentsGroupSlice.actions;
    dispatch(setIsFetchingPossibleSchedules(true));
    try {
      const response = await api.post(
        `/api/group-appointments/start-schedule`, content
      );

      cb(response.data.data.appointmentsOptions);
      dispatch(setIsFetchingPossibleSchedules(false));
    } catch (error: any) {
      dispatch(setIsFetchingPossibleSchedules(false));

      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchPossibleAppointmentsGroupSchedule =
  (data: string, serviceitemsids: string, clinicId: string, idcombo?:string, idHealthPlan?: string): AppThunk =>
  async (dispatch) => {
    const { setIsFetchingPossibleSchedules, setPossibleSchedules } =
      appointmentsGroupSlice.actions;
    dispatch(setIsFetchingPossibleSchedules(true));
    try {
      const response = await api.get(
        `/api/group-appointments/schedules?data=${data}&serviceitemsids=${serviceitemsids}&clinicId=${clinicId}${idcombo ? '&idcombo='+idcombo : ""}${idHealthPlan ? '&idhealthplan='+idHealthPlan : ""}`
      );

      dispatch(setPossibleSchedules(response.data.data));
      dispatch(setIsFetchingPossibleSchedules(false));
    } catch (error: any) {
      dispatch(setIsFetchingPossibleSchedules(false));

      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const createAppointmentGroup =
  ({
    appointmentInfo,
    groupAppointmentInfo,
  }: {
    appointmentInfo: any;
    groupAppointmentInfo: {
      unidaderepeticao: string;
      valorrepeticao: number;
      status: string;
      qualquermedico: boolean;
      qualqueragenda: boolean;
      limiterepeticao?: number;
      datafim?: string;
      diasdasemana?: string[];
      opcaodomes?: string;
    };
  }): AppThunk =>
  async (dispatch) => {
    const {
      setIsCreatingAppointmentGroup,
      setRecurringAppointmentResponses,
      setHasRecurringAppointmentError,
      setCurrentAppointmentGroup,
      setServiceItemsIds,
    } = appointmentsGroupSlice.actions;

    dispatch(setIsCreatingAppointmentGroup(true));

    try {
      const response = await api.post(`/api/group-appointments`, {
        appointmentInfo,
        groupAppointmentInfo,
      });
      dispatch(setIsCreatingAppointmentGroup(false));

      dispatch(
        setRecurringAppointmentResponses(
          response.data.data.appointmentsResponse
        )
      );

      dispatch(setCurrentAppointmentGroup(response.data.data.appointmentGroup));

      dispatch(setServiceItemsIds(response.data.data.serviceItemsIds));

      const recurringResponse = response.data.data
        .appointmentsResponse as IAppointmentGroupResponse[];

      const hasAtLeastOneAppointmentWithError = recurringResponse.some(
        (res) => !res.success
      );

      if (hasAtLeastOneAppointmentWithError) {
        dispatch(setHasRecurringAppointmentError(true));
        toast.warn("Pelo menos um agendamento com conflito", {
          ...toastOptions,
          autoClose: 5000,
        });
      } else {
        dispatch(setHasRecurringAppointmentError(false));
        toast.success("Todos os agendamentos foram criados", toastOptions);
      }
    } catch (error: any) {
      dispatch(setIsCreatingAppointmentGroup(false));

      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export interface IAppointmentOnWaitInfo {
  idagenda: string;
  idprofissional: string;
  data: string;
  idagendamentoemespera: string;
}

export const fetchPatientGroupAppointments =
  (patientId: string): AppThunk =>
  async (dispatch) => {
    const {
      setIsFetchingPatientGroupAppointmentsInfo,
      setPatientGroupAppointmentInfo,
    } = appointmentsGroupSlice.actions;
    dispatch(setIsFetchingPatientGroupAppointmentsInfo(true));
    try {
      const response = await api.get(`/api/group-appointments/${patientId}`);

      dispatch(setPatientGroupAppointmentInfo(response.data.data));
      dispatch(setIsFetchingPatientGroupAppointmentsInfo(false));
    } catch (error: any) {
      dispatch(setIsFetchingPatientGroupAppointmentsInfo(false));

      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchAppointmentGroupHistory =
  (idgrupoconsulta: string): AppThunk =>
  async (dispatch) => {
    const {
      setIsFetchingPatientGroupAppointmentsHistory,
      setPatientGroupAppointmentHistory,
    } = appointmentsGroupSlice.actions;
    dispatch(setIsFetchingPatientGroupAppointmentsHistory(true));
    try {
      const response = await api.get(
        `/api/group-appointments-changelogs/${idgrupoconsulta}`
      );

      dispatch(setPatientGroupAppointmentHistory(response.data.data));
      dispatch(setIsFetchingPatientGroupAppointmentsHistory(false));
    } catch (error: any) {
      dispatch(setIsFetchingPatientGroupAppointmentsHistory(false));

      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const createAppointmentGroupHistory =
  ({
    idgrupoconsulta,
    idagendamento,
    descricao,
    observacao,
    callback,
  }: {
    idgrupoconsulta: string;
    idagendamento?: string;
    descricao: string;
    observacao?: string;
    callback?: () => void;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingPatientGroupAppointmentsHistory } =
      appointmentsGroupSlice.actions;
    dispatch(setIsCreatingPatientGroupAppointmentsHistory(true));
    try {
      await api.post(`/api/group-appointments-changelogs`, {
        idgrupoconsulta,
        idagendamento,
        descricao,
        observacao,
      });

      dispatch(setIsCreatingPatientGroupAppointmentsHistory(false));

      if (callback) {
        callback();
      }
    } catch (error: any) {
      dispatch(setIsCreatingPatientGroupAppointmentsHistory(false));

      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchAppointmentGroupNextInfoSet =
  ({
    appointmentInfo,
    groupAppointmentInfo,
    cb,
  }: {
    appointmentInfo: any;
    groupAppointmentInfo: {
      unidaderepeticao: string;
      valorrepeticao: number;
      status: string;
      qualquermedico: boolean;
      qualqueragenda: boolean;
      limiterepeticao?: number;
      datafim?: string;
      diasdasemana?: string[];
      opcaodomes?: string;
    };
    cb?: () => void;
  }): AppThunk =>
  async (dispatch) => {
    const {
      setIsFetchingAppointmentGroupNextInfoSet,
      setAppointmentNextInfoSet,
      setGroupAppointmentInfoToFinalize,
      setCurrentAppointmentGroup,
      setFirstAppointmentInfo,
      setServiceItemsIds,
      setCleanSelectedAppointmentsOptions,
      setAppointmentStartGeneral,
      setAppointmentsExceded
    } = appointmentsGroupSlice.actions;

    const { groupAppointmentInfoToFinalize } = initialState;

    dispatch(setIsFetchingAppointmentGroupNextInfoSet(true));

    try {
      const response = await api.post(`/api/group-appointments/start`, {
        appointmentInfo,
        groupAppointmentInfo,
      });
      if(response.data.data?.appointmentGroup?.eh_combo){
        dispatch(
          setRecurringAppointmentResponses(
            response.data.data.appointmentsResponse
            )
          );
        dispatch(setAppointmentStartGeneral(response.data.data));

        dispatch(setCurrentAppointmentGroup(response.data.data.appointmentGroup));
  
        dispatch(setServiceItemsIds(response.data.data.serviceItemsIds));
  
        const recurringResponse = response.data.data
          .appointmentsResponse as IAppointmentGroupResponse[];
  
        const hasAtLeastOneAppointmentWithError = recurringResponse.some(
          (res) => !res.success
        );
  
        if (hasAtLeastOneAppointmentWithError) {
          dispatch(setHasRecurringAppointmentError(true));
          toast.warn("Pelo menos um agendamento com conflito", {
            ...toastOptions,
            autoClose: 5000,
          });
        } else {
          dispatch(setHasRecurringAppointmentError(false));
          toast.success("Todos os agendamentos foram criados", toastOptions);
        }
        dispatch(
          setRecurringAppointmentResponses(
            response.data.data.appointmentsResponse
          )
        );
        dispatch(setCleanSelectedAppointmentsOptions());
        if (cb) {
          cb();
        }
        
      }
      
      dispatch(setAppointmentsExceded(response.data.data.appointmentsExceded))

      if (cb) {
        cb();
      }
      dispatch(setIsFetchingAppointmentGroupNextInfoSet(false));

      dispatch(
        setCurrentAppointmentGroup(response.data.data.newAppointmentGroup)
      );

      dispatch(
        setFirstAppointmentInfo(response.data.data.firstAppointmentInfo)
      );

      dispatch(
        setGroupAppointmentInfoToFinalize({
          ...groupAppointmentInfoToFinalize,
          idgrupoconsulta:
            response.data.data.newAppointmentGroup.idgrupoconsulta,
          appointmentsToCreate: (
            response.data.data.appointmentsOptions as IAppointmentInfoResponse[]
          ).map((appointment) => {
            return {
              data: appointment.appointmentDate,
              idprofissional: appointment.professionals[0].professionalId,
              idagenda: appointment.professionals[0].schedules[0].scheduleId,
              iditemservico:
                appointment.professionals[0].schedules[0].serviceItems[0]
                  .serviceItemId,
            };
          }),
        })
      );

      dispatch(
        setAppointmentNextInfoSet(response.data.data.appointmentsOptions)
      );

      
    } catch (error: any) {
      dispatch(setIsFetchingAppointmentGroupNextInfoSet(false));

      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const finalizeGroupAppointment =
  ({
    data,
    cb,
  }: {
    data: IFinalizeGroupAppointmentRequest;
    cb?: () => void;
  }): AppThunk =>
  async (dispatch) => {
    const {
      setIsFinalizingGroupAppointment,
      setRecurringAppointmentResponses,
      setCurrentAppointmentGroup,
      setServiceItemsIds,
      setCleanSelectedAppointmentsOptions,
    } = appointmentsGroupSlice.actions;

    dispatch(setIsFinalizingGroupAppointment(true));

    try {
      const response = await api.post(`/api/group-appointments/finish`, {
        ...data,
      });
      dispatch(setIsFinalizingGroupAppointment(false));

      dispatch(
        setRecurringAppointmentResponses(
          response.data.data.appointmentsResponse
        )
      );
     

      dispatch(setCurrentAppointmentGroup(response.data.data.appointmentGroup));

      dispatch(setServiceItemsIds(response.data.data.serviceItemsIds));

      const recurringResponse = response.data.data
        .appointmentsResponse as IAppointmentGroupResponse[];

      const hasAtLeastOneAppointmentWithError = recurringResponse.some(
        (res) => !res.success
      );

      if (hasAtLeastOneAppointmentWithError) {
        dispatch(setHasRecurringAppointmentError(true));
        toast.warn("Pelo menos um agendamento com conflito", {
          ...toastOptions,
          autoClose: 5000,
        });
      } else {
        dispatch(setHasRecurringAppointmentError(false));
        toast.success("Todos os agendamentos foram criados", toastOptions);
      }

      dispatch(setCleanSelectedAppointmentsOptions());

      if (cb) {
        cb();
      }
    } catch (error: any) {
      dispatch(setIsFinalizingGroupAppointment(false));

      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const {
  setRecurringAppointmentResponses,
  setHasRecurringAppointmentError,
  setPatientGroupAppointmentInfo,
  setPatientGroupAppointmentHistory,
  setGroupAppointmentInfoToFinalize,
  setIsFinalizeStarted,
  setUpdateAppointmentSelectedOptions,
  setCleanSelectedAppointmentsOptions,
  setAppointmentNextInfoSet,
  setAppointmentStartGeneral,
  setCurrentAppointmentGroup,
  setServiceItemsIds
} = appointmentsGroupSlice.actions;

export default appointmentsGroupSlice.reducer;
