import React from 'react'

import { setMemedAdditionalData } from '../actions'
import { AdditionalData } from '../domain/AdditionalData'

interface SetupAdditionalDataParams {
  additionalData?: AdditionalData
  prescriptionLoaded: boolean
}

interface SetupAdditionalDataResult {
  additionalDataSet: boolean
}

export default function useSetupWorkplace(params: SetupAdditionalDataParams): SetupAdditionalDataResult {
  const { additionalData, prescriptionLoaded } = params

  const [additionalDataSet, setAdditionalDataSet] = React.useState(false)

  React.useEffect(() => {
    if (additionalData && prescriptionLoaded) {
        setMemedAdditionalData(additionalData).then(() => {
        setAdditionalDataSet(true)
      })
    }
  }, [additionalData, prescriptionLoaded])

  return { additionalDataSet }
}