import {
  AnyAction,
  createSlice,
  PayloadAction,
  ThunkAction,
} from "@reduxjs/toolkit";
import { rootState } from "store/index";
import { toast } from "react-toastify";
import toastOptions from "utils/toastOptions";
import { history } from "utils/history";
import api from "utils/API";
import {
  ICustomerQuotes,
  IItemOrcamento,
  IITemOrcamentoAcrescimo,
  IItemOrcamentoDesconto,
  IItemOrcamentoForm,
  IOrcamento,
} from "models/CustomerQuote";
import { IFilter } from "models/shared";
import { queryStringFromFilterArray } from "utils/network";
import { IItemsToBeScheduled, ISelectOption } from "pages/CustomersQuote/types";
import { IHistory } from "models/Appointments";
import { setServiceOrders } from "../ServiceOrderReducer";
import { AxiosError } from "axios";
import dayjs from "dayjs";

export interface IInitialState {
  isSchedulingFromQuote: boolean;
  isCreatingCustomerQuote: boolean;
  isFetchingNewCustomerQuote: boolean;
  isFetchingNewCustomerQuoteById: boolean;
  isFetchingCustomersQuote: boolean;
  isUpdatingCustomerQuotePatient: boolean;
  isUpdatingCustomerQuoteObservation: boolean;
  isUpdatingCustomerQuote: boolean;
  isDeletingCustomerQuote: boolean;
  isUpdatingCustomerQuoteItems: boolean;
  isInsertingArrayOfQuoteItems: boolean;
  isFetchingCustomerQuoteItems: boolean;
  isFetchingCustomerQuoteItem: boolean;
  customersQuotes: IOrcamento[] | null;
  currentCustomerQuote: IOrcamento | null;
  itemsFromQuoteToSchedule: IItemsToBeScheduled[];
  customerQuotes: ICustomerQuotes | null;
  scheduleFromQuote: boolean | null;
  serviceOrderFromCustomerQuoteKey: string | null;
  currentCustomerQuoteItems: IItemOrcamento[] | null;
  currentCustomerQuoteItem: IItemOrcamento | null;
  currentCustomerQuoteServiceItemsTotal: number;
  currentCustomerQuoteServiceItemsPage: number;
  isSchedulingFromQuoteDialogOpen: boolean;
  total: number;
  page: number;
  pageToCostumerQuotesTable: number;
  filterArray: IFilter[];
  isCreatingOSByQuote: boolean;
  currentCodigoFilter: string;
  serviceOrderData: {
    idordemdeservico: string | null;
    idpaciente: string | null;
  };
  clinicCustomerQuotesDisponibilityMap: any;
  healthPlanCategoryFromQuote: ISelectOption;
  forceOpenServiceOrderDialog: boolean;
}

const initialState: IInitialState = {
  isSchedulingFromQuoteDialogOpen: false,
  isSchedulingFromQuote: false,
  itemsFromQuoteToSchedule: [],
  isCreatingCustomerQuote: false,
  isFetchingNewCustomerQuote: false,
  isFetchingNewCustomerQuoteById: false,
  isFetchingCustomersQuote: false,
  isUpdatingCustomerQuoteObservation: false,
  isUpdatingCustomerQuotePatient: false,
  isUpdatingCustomerQuote: false,
  isUpdatingCustomerQuoteItems: false,
  isInsertingArrayOfQuoteItems: false,
  isDeletingCustomerQuote: false,
  isFetchingCustomerQuoteItems: false,
  isFetchingCustomerQuoteItem: false,
  customersQuotes: null,
  currentCustomerQuote: null,
  scheduleFromQuote: null,
  customerQuotes: null,
  serviceOrderFromCustomerQuoteKey: null,
  currentCustomerQuoteItems: null,
  currentCustomerQuoteItem: null,
  currentCustomerQuoteServiceItemsTotal: 0,
  currentCustomerQuoteServiceItemsPage: 0,
  total: 0,
  page: 0,
  pageToCostumerQuotesTable: 1,
  filterArray: [
    { key: "email", value: null },
    { key: "telefone", value: null },
    { key: "cargos", value: [] },
    { key: "paciente", value: [] },
    { key: "codigo", value: [] },
    { key: "status", value: [] },
    { key: "usuario", value: [] },
    { key: "modificado", value: null },
    { key: "quitado", value: null },
    { key: "profissionalsolicitante", value: [] },
    { key: "start_date", value: dayjs(new Date()).format("YYYY-MM-DD") },
    { key: "end_date", value: dayjs(new Date()).format("YYYY-MM-DD") },
    { key: "changelogs", value: false },
    { key: "dataquitado", value: null },
    // { key: "page", value: 1 },
  ],
  isCreatingOSByQuote: false,
  currentCodigoFilter: "",
  serviceOrderData: {
    idordemdeservico: null,
    idpaciente: null,
  },
  clinicCustomerQuotesDisponibilityMap: null,
  healthPlanCategoryFromQuote: {
    label: "",
    value: "",
  },
  forceOpenServiceOrderDialog: false,
};

const customersQuotesSlice = createSlice({
  name: "customersQuotesSlice",
  initialState,
  reducers: {
    setClinicCustomerQuotesDisponibilityMap: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      state.clinicCustomerQuotesDisponibilityMap = payload;
    },
    setIsCreatingOSByQuote: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreatingOSByQuote = payload;
    },
    setIsSchedulingFromQuoteDialogOpen: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isSchedulingFromQuoteDialogOpen = payload;
    },
    setIsSchedulingItemsFromQuote: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isSchedulingFromQuote = payload;
    },
    setHealthPlanCategoryFromQuote: (
      state,
      { payload }: PayloadAction<ISelectOption>
    ) => {
      state.healthPlanCategoryFromQuote = payload;
    },
    resetItemsFromQuoteToBeScheduled: (state, { payload }: PayloadAction) => {
      state.itemsFromQuoteToSchedule = [];
    },
    setItemsFromQuoteToBeScheduled: (
      state,
      { payload }: PayloadAction<IItemsToBeScheduled[]>
    ) => {
      state.itemsFromQuoteToSchedule = payload;
    },
    removeItemFromQuoteToBeScheduled: (
      state,
      {
        payload: { serviceItemId },
      }: PayloadAction<{
        serviceItemId: string;
      }>
    ) => {
      state.itemsFromQuoteToSchedule = state.itemsFromQuoteToSchedule.filter(
        (serviceItems) => serviceItems.itemId !== serviceItemId
      );
    },
    setIsCreatingCustomerQuote: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isCreatingCustomerQuote = payload;
    },
    setIsFetchingNewCustomerQuote: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingNewCustomerQuote = payload;
    },
    setIsFetchingNewCustomerQuoteById: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingNewCustomerQuoteById = payload;
    },
    setIsFetchingCustomersQuotes: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingCustomersQuote = payload;
    },
    setIsUpdatingCustomerQuotePatient: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isUpdatingCustomerQuotePatient = payload;
    },
    setIsUpdatingCustomerQuoteObservation: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isUpdatingCustomerQuoteObservation = payload;
    },
    setIsUpdatingCustomerQuote: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isUpdatingCustomerQuote = payload;
    },
    setIsDeletingCustomerQuote: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isDeletingCustomerQuote = payload;
    },
    setIsUpdatingCustomerQuoteItems: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isUpdatingCustomerQuoteItems = payload;
    },
    setIsInsertingArrayOfQuoteItems: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isInsertingArrayOfQuoteItems = payload;
    },
    setIsFetchingCustomerQuoteItems: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingCustomerQuoteItems = payload;
    },
    setIsFetchingCustomerQuoteItem: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingCustomerQuoteItem = payload;
    },
    setServiceOrderFromCustomerQuoteKey: (
      state,
      { payload }: PayloadAction<string | null>
    ) => {
      state.serviceOrderFromCustomerQuoteKey = payload;
    },
    setCurrentCodigoFilter: (state, { payload }: PayloadAction<string>) => {
      state.currentCodigoFilter = payload;
    },
    setCustomersQuotes: (
      state,
      {
        payload: { customer_quotes, page, total },
      }: PayloadAction<{
        customer_quotes: IOrcamento[];
        total: number;
        page: number;
      }>
    ) => {
      state.total = total;
      state.page = page;

      var sortedQuotes = customer_quotes.sort((q1, q2) => {
        if (+q1.codigo < +q2.codigo) {
          return 1;
        }

        if (+q1.codigo > +q2.codigo) {
          return -1;
        }

        return 0;
      });

      state.customersQuotes = sortedQuotes;
    },
    setCustomerQuotes: (state, { payload }: PayloadAction<ICustomerQuotes>) => {
      state.customerQuotes = payload;
    },
    setCurrentCustomerQuote: (
      state,
      { payload }: PayloadAction<IOrcamento | null>
    ) => {
      state.currentCustomerQuote = payload;
    },
    setScheduleFromQuote: (state, { payload }: PayloadAction<boolean>) => {
      state.scheduleFromQuote = payload;
    },
    setCurrentCustomerQuoteItems: (
      state,
      {
        payload: { customer_quote_items, total, page },
      }: PayloadAction<{
        customer_quote_items: IItemOrcamento[];
        total: number;
        page: number;
      }>
    ) => {
      state.currentCustomerQuoteItems = customer_quote_items;
      state.currentCustomerQuoteServiceItemsTotal = total;
      state.currentCustomerQuoteServiceItemsPage = page;
    },
    setCurrentCustomerQuoteItem: (
      state,
      { payload }: PayloadAction<IItemOrcamento>
    ) => {
      state.currentCustomerQuoteItem = payload;
    },
    updateFilter: (state, { payload }: PayloadAction<IFilter>) => {
      const index = state.filterArray.findIndex(
        (item) => item.key === payload.key
      );
      if (index === -1) {
        state.filterArray.push({ key: payload.key, value: payload.value });
      } else {
        state.filterArray[index].value = payload.value;
      }
      state.customersQuotes = null;
    },
    setPageToCostumerQuotesTable: (
      state,
      { payload }: PayloadAction<number>
    ) => {
      state.pageToCostumerQuotesTable = payload;
    },
    setServiceOrderData: (
      state,
      {
        payload,
      }: PayloadAction<{
        idordemdeservico: string | null;
        idpaciente: string | null;
      }>
    ) => {
      state.serviceOrderData = payload;
    },
    updateClearFilter: (state) => {
      state.filterArray = [
        { key: "email", value: null },
        { key: "telefone", value: null },
        { key: "cargos", value: [] },
        { key: "paciente", value: [] },
        { key: "codigo", value: [] },
        { key: "status", value: [] },
        { key: "usuario", value: [] },
        { key: "modificado", value: null },
        { key: "quitado", value: null },
        { key: "profissionalsolicitante", value: [] },
        { key: "start_date", value: dayjs(new Date()).format("YYYY-MM-DD") },
        { key: "end_date", value: dayjs(new Date()).format("YYYY-MM-DD") },
        { key: "changelogs", value: false },
        { key: "dataquitado", value: null },
        // { key: "page", value: 1 },
      ];
      state.customersQuotes = null;
    },
    setForceOpenServiceOrderDialog: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.forceOpenServiceOrderDialog = payload;
    },
  },
});

export const updateCustomerQuoteGeneralDiscount =
  ({
    customerQuoteId,
    cb,
    acrescimo,
    desconto,
    login,
    password,
    isForced,
  }: {
    login?: string;
    password?: string;
    desconto?: IItemOrcamentoDesconto;
    acrescimo?: IITemOrcamentoAcrescimo;
    customerQuoteId: string;
    isForced?: boolean;
    cb?: Function;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { setIsUpdatingCustomerQuote } = customersQuotesSlice.actions;
    dispatch(setIsUpdatingCustomerQuote(true));
    const data = {
      desconto,
      acrescimo,
      email: login,
      password: password,
      isForced,
    };
    try {
      await api.put(`api/customerquotes/${customerQuoteId}`, data);
      dispatch(fetchCustomerQuote(customerQuoteId));
      toast.success("Desconto adicionado ao Orçamento", toastOptions);
      dispatch(setIsUpdatingCustomerQuote(false));
    } catch (error: any | AxiosError) {
      dispatch(setIsUpdatingCustomerQuote(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchCustomerQuotes =
  ({
    page = 1,
    limit = 6,
  }: {
    page?: number;
    limit?: number;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { setIsFetchingCustomersQuotes, setCustomersQuotes } =
      customersQuotesSlice.actions;
    dispatch(setIsFetchingCustomersQuotes(true));
    try {
      const state = getState();
      const { filterArray } = state.customersQuote;
      const queryParameters = queryStringFromFilterArray(filterArray);
      const pageAndLimit =
        queryParameters.length === 0
          ? `?page=${page}&limit=${limit}`
          : `&page=${page}&limit=${limit}`;
      const response = await api.get(
        `/api/customerquotes${queryParameters}${pageAndLimit}`
      );
      // console.log(response)
      dispatch(setCustomersQuotes(response.data.data));
      dispatch(setIsFetchingCustomersQuotes(false));
    } catch (error: any | AxiosError) {
      dispatch(setIsFetchingCustomersQuotes(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const deleteCustomerQuote =
  (
    customerQuoteId: string,
    email: string,
    password: string,
    cancelReason?: string,
    cb?: () => void
  ): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsDeletingCustomerQuote } = customersQuotesSlice.actions;
    try {
      dispatch(setIsDeletingCustomerQuote(true));
      await api.delete(`/api/customerquotes/${customerQuoteId}`, {
        data: { email, password, cancelReason },
      });
      dispatch(fetchCustomerQuotes({}));
      dispatch(setIsDeletingCustomerQuote(false));
      if (cb) {
        cb();
      }
    } catch (error: any | AxiosError) {
      dispatch(setIsDeletingCustomerQuote(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchCustomerQuote =
  (
    customerQuoteId: string,
    callback?: Function
  ): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { setIsFetchingCustomersQuotes, setCurrentCustomerQuote } =
      customersQuotesSlice.actions;
    try {
      dispatch(setIsFetchingCustomersQuotes(true));
      const quote = await api.get(`/api/customerquotes/${customerQuoteId}`);
      dispatch(setCurrentCustomerQuote(quote.data.data));
      dispatch(setIsFetchingCustomersQuotes(false));
      if (callback) {
        callback();
      }
    } catch (error: any | AxiosError) {
      dispatch(setIsFetchingCustomersQuotes(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const updateCustomerQuoteAllData =
  (
    formData: any,
    customerQuoteId: string,
    cb?: (quoteId: string) => void
  ): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsCreatingCustomerQuote } = customersQuotesSlice.actions;

    try {
      dispatch(setIsCreatingCustomerQuote(true));
      const res = await api.put(
        `/api/customerquotes/${customerQuoteId}`,
        formData
      );
      // await Promise.all( itemsData.map( async (item:any)=>{
      //   await api.put(`/api/custormerquotes/${customerQuoteId}/${item.iditemorcamento}`, item.itemOrcamentoData)
      // }))
      toast.success("Orçamento Atualizado", toastOptions);
      dispatch(fetchCustomerQuote(res.data.data.idorcamento));
      dispatch(setIsCreatingCustomerQuote(false));
      // if(cb){
      //   cb(res.data.data.idorcamento)
      // }
    } catch (err: any | AxiosError) {
      if (err.response) {
        toast.error(err.response.data?.error?.message, toastOptions);
      } else {
        console.log(err.message);
      }
    }
  };

export const createCustomerQuoteAllData =
  (
    customerQuoteData: any,
    cb?: (quoteId: string) => void
  ): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsCreatingCustomerQuote } = customersQuotesSlice.actions;

    try {
      dispatch(setIsCreatingCustomerQuote(true));
      const res = await api.post(`/api/customerquotes`, customerQuoteData);
      dispatch(fetchCustomerQuote(res.data.data.idorcamento));
      if(cb) cb(res.data.data.idorcamento)
      toast.success("Orçamento Criado com Sucesso", toastOptions);
      dispatch(setIsCreatingCustomerQuote(false));
    } catch (err: any | AxiosError) {
      dispatch(setIsCreatingCustomerQuote(false));
      if (err.response) {
        toast.error(err.response.data?.error?.message, toastOptions);
      } else {
        console.log(err.message);
      }
    }
  };

export const fetchCustomerQuoteById =
  ({
    customerQuoteId,
    status,
    page = 1,
    limit = 6,
  }: {
    customerQuoteId: string;
    status?: string;
    page?: number;
    limit: number;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsFetchingCustomersQuotes, setCustomerQuotes } =
      customersQuotesSlice.actions;

    let queryParameters = "";
    if (status) {
      queryParameters = `idpaciente=${customerQuoteId}&status=${status}`;
    } else {
      queryParameters = `idpaciente=${customerQuoteId}`;
    }
    try {
      dispatch(setIsFetchingCustomersQuotes(true));
      const quote = await api.get(
        `/api/customerquotes?${queryParameters}&page=${page}&limit=${limit}`
      );
      dispatch(setCustomerQuotes(quote.data.data));
      dispatch(setIsFetchingCustomersQuotes(false));
    } catch (error: any | AxiosError) {
      dispatch(setIsFetchingCustomersQuotes(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const selectCustomerQuote =
  (
    customerQuote: IOrcamento | null,
    callback?: Function
  ): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setCurrentCustomerQuote } = customersQuotesSlice.actions;
    try {
      if (callback) callback();
      // console.log(customerQuote);
      dispatch(setCurrentCustomerQuote(customerQuote));
      // dispatch(fetchCustomerQuoteServiceItems({ customerQuoteId: customerQuote.idorcamento }));
    } catch (error: any | AxiosError) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const createCustomerQuote =
  ({
    patientId,
    cb,
  }: {
    patientId?: string;
    cb?: () => void;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const {
      setIsFetchingNewCustomerQuote,
      setCurrentCustomerQuote,
      setIsFetchingCustomersQuotes,
    } = customersQuotesSlice.actions;
    try {
      dispatch(setIsFetchingNewCustomerQuote(true));
      const query = patientId ? `?pid=${patientId}` : "";
      const quote = await api.post("/api/customerquotes");
      if (patientId) {
        dispatch(
          updateCustomerQuotePatient({
            idpaciente: patientId,
            customerQuoteId: quote.data.data.idorcamento,
          })
        );
      }
      if (cb) {
        cb();
        dispatch(setIsFetchingCustomersQuotes(true));
        dispatch(setCurrentCustomerQuote(quote.data.data));
        dispatch(
          fetchCustomerQuoteServiceItems({
            customerQuoteId: quote.data.data.idorcamento,
          })
        );
      } else {
        history.push(
          `/customers-quote/form/${quote.data.data.idorcamento}${query}`
        );
      }
      dispatch(setIsFetchingCustomersQuotes(false));
      dispatch(setIsFetchingNewCustomerQuote(true));
    } catch (error: any | AxiosError) {
      dispatch(setIsFetchingNewCustomerQuote(true));
      dispatch(setIsFetchingCustomersQuotes(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const updateCustomerQuoteObservation =
  ({
    customerQuoteId,
    customerQuoteObservation,
  }: {
    customerQuoteObservation: string;
    customerQuoteId: string;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { setIsUpdatingCustomerQuoteObservation, setCurrentCustomerQuote } =
      customersQuotesSlice.actions;
    try {
      dispatch(setIsUpdatingCustomerQuoteObservation(true));
      const quote = await api.put(`/api/customerquotes/${customerQuoteId}`, {
        observacao: customerQuoteObservation,
      });
      // history.push(`/customers-quote/form/${quote.data.data.idorcamento}`);
      dispatch(setIsUpdatingCustomerQuoteObservation(false));
      dispatch(setCurrentCustomerQuote(quote.data.data));
      toast.success("Observação salva com sucesso", toastOptions);
    } catch (error: any | AxiosError) {
      dispatch(setIsUpdatingCustomerQuoteObservation(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const updateCustomerQuotePatient =
  ({
    idpaciente,
    customerQuoteId,
  }: {
    idpaciente: string;
    customerQuoteId: string;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { setIsUpdatingCustomerQuotePatient, setCurrentCustomerQuote } =
      customersQuotesSlice.actions;
    try {
      dispatch(setIsUpdatingCustomerQuotePatient(true));
      const quote = await api.put(`/api/customerquotes/${customerQuoteId}`, {
        idpaciente,
      });
      // history.push(`/customers-quote/form/${quote.data.data.idorcamento}`);
      dispatch(setIsUpdatingCustomerQuotePatient(false));
      dispatch(setCurrentCustomerQuote(quote.data.data));
    } catch (error: any | AxiosError) {
      dispatch(setIsUpdatingCustomerQuotePatient(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const editCustomerQuoteItemsType =
  ({
    customerQuoteItemId,
    customerQuoteId,
    healthPlanCategoryId,
    callback,
  }: {
    customerQuoteItemId: string[];
    customerQuoteId: string;
    healthPlanCategoryId?: string;
    callback?: Function;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { setIsUpdatingCustomerQuoteItems } = customersQuotesSlice.actions;
    try {
      dispatch(setIsUpdatingCustomerQuoteItems(true));

      const data = {
        iditemorcamento: customerQuoteItemId,
      };

      if (healthPlanCategoryId) {
        Object.assign(data, {
          idplano: healthPlanCategoryId,
        });
      }

      await api.patch(`/api/customerquotes/${customerQuoteId}/items`, data);

      toast.success("Item modificados com sucesso", toastOptions);
      if (callback) {
        callback();
      }

      dispatch(fetchCustomerQuote(customerQuoteId));
    } catch (error: any | AxiosError) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setIsUpdatingCustomerQuoteItems(false));
    }
  };

export const addServiceItemToCustomerQuote =
  ({
    serviceItem,
    customerQuoteId,
    callback,
  }: {
    serviceItem: IItemOrcamentoForm;
    customerQuoteId: string;
    callback?: Function;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    // const { setIsUpdatingCustomerQuoteItems } = customersQuotesSlice.actions;
    try {
      // dispatch(setIsUpdatingCustomerQuoteItems(true));
      await api.post(
        `/api/customerquotes/${customerQuoteId}/items`,
        serviceItem
      );
      toast.success("Item adicionado com sucesso", toastOptions);
      dispatch(fetchCustomerQuoteServiceItems({ customerQuoteId }));
      // dispatch(setIsUpdatingCustomerQuoteItems(false));
      if (callback) {
        callback();
      }
    } catch (error: any | AxiosError) {
      // dispatch(setIsUpdatingCustomerQuoteItems(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const addArrayOfServiceItemsToCustomerQuote =
  ({
    serviceItemsArray,
    customerQuoteId,
    callback,
  }: {
    serviceItemsArray: { itensorcamento: IItemOrcamentoForm[] };
    customerQuoteId: string;
    callback?: Function;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { setIsInsertingArrayOfQuoteItems } = customersQuotesSlice.actions;
    try {
      dispatch(setIsInsertingArrayOfQuoteItems(true));
      await api.post(
        `/api/customerquotes/${customerQuoteId}/items-batch`,
        serviceItemsArray
      );
      toast.success("Adicionado com sucesso", toastOptions);
      dispatch(
        fetchCustomerQuoteServiceItems({ customerQuoteId, page: 1, limit: 100 })
      );
      dispatch(setIsInsertingArrayOfQuoteItems(false));
      if (callback) {
        callback();
      }
    } catch (error: any | AxiosError) {
      dispatch(setIsInsertingArrayOfQuoteItems(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const editCustomerQuoteServiceItem =
  ({
    serviceItem,
    serviceItemId,
    customerQuoteId,
    callback,
    userLogin,
    userPassword,
    isForced,
  }: {
    userLogin?: string;
    userPassword?: string;
    serviceItem: IItemOrcamentoForm;
    customerQuoteId: string;
    serviceItemId: string;
    callback?: Function;
    isForced?: boolean;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { setIsUpdatingCustomerQuoteItems } = customersQuotesSlice.actions;
    const data = {
      email: userLogin,
      password: userPassword,
      desconto: serviceItem.desconto,
      acrescimo: serviceItem.acrescimo,
      iditemservico: serviceItem.iditemservico,
      quantidade: serviceItem.quantidade,
      isForced,
    };
    try {
      dispatch(setIsUpdatingCustomerQuoteItems(true));
      await api.put(
        `/api/customerquotes/${customerQuoteId}/items/${serviceItemId}`,
        data
      );
      toast.success("Item atualizado com sucesso", toastOptions);
      dispatch(fetchCustomerQuote(customerQuoteId, callback));
      dispatch(setIsUpdatingCustomerQuoteItems(false));
    } catch (error: any | AxiosError) {
      dispatch(setIsUpdatingCustomerQuoteItems(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchCustomerQuoteServiceItems =
  ({
    customerQuoteId,
    page = 1,
    limit = 6,
  }: {
    customerQuoteId: string;
    page?: number;
    limit?: number;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { setIsFetchingCustomerQuoteItems, setCurrentCustomerQuoteItems } =
      customersQuotesSlice.actions;
    try {
      dispatch(setIsFetchingCustomerQuoteItems(true));
      const data = await api.get(
        `/api/customerquotes/${customerQuoteId}/items?page=${page}&limit=${limit}`
      );
      dispatch(setCurrentCustomerQuoteItems(data.data.data));
      dispatch(setIsFetchingCustomerQuoteItems(false));
    } catch (error: any | AxiosError) {
      dispatch(setIsFetchingCustomerQuoteItems(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const removeItemFromCustomerQuote =
  ({
    customerQuoteId,
    serviceItemId,
    cb,
  }: {
    customerQuoteId: string;
    serviceItemId: string;
    cb?: () => void;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { setIsUpdatingCustomerQuoteItems } = customersQuotesSlice.actions;
    try {
      dispatch(setIsUpdatingCustomerQuoteItems(true));
      await api.delete(
        `/api/customerquotes/${customerQuoteId}/items/${serviceItemId}`
      );
      dispatch(setIsUpdatingCustomerQuoteItems(false));
      toast.success("Item removido com sucesso", toastOptions);
      dispatch(fetchCustomerQuoteServiceItems({ customerQuoteId }));
      if (cb) {
        cb();
      }
    } catch (error: any | AxiosError) {
      dispatch(setIsUpdatingCustomerQuoteItems(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchCustomerQuoteServiceItem =
  ({
    customerQuoteId,
    serviceItemId,
  }: {
    customerQuoteId: string;
    serviceItemId: string;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { setIsFetchingCustomerQuoteItem, setCurrentCustomerQuoteItem } =
      customersQuotesSlice.actions;
    try {
      dispatch(setIsFetchingCustomerQuoteItem(true));
      const data = await api.get(
        `/api/customerquotes/${customerQuoteId}/items/${serviceItemId}`
      );
      dispatch(setCurrentCustomerQuoteItem(data.data.data));
      dispatch(setIsFetchingCustomerQuoteItem(false));
    } catch (error: any | AxiosError) {
      dispatch(setIsFetchingCustomerQuoteItem(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const addOrderServicesByConsumerQuotes =
  ({
    pacienteId,
    itensservico,
    customerQuoteId,
    idclinica,
    idagendamento,
    preferencial = false,
    prioritario = false,
    joinItems = false,
    cb,
  }: {
    pacienteId: string;
    itensservico: any[];
    customerQuoteId: string;
    idclinica?: string;
    idagendamento?: string[];
    preferencial?: boolean;
    prioritario?: boolean;
    joinItems?: boolean;
    cb?: () => void;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const {
      setIsUpdatingCustomerQuoteItems,
      setServiceOrderFromCustomerQuoteKey,
      setIsCreatingOSByQuote,
      setServiceOrderData,
      setForceOpenServiceOrderDialog,
    } = customersQuotesSlice.actions;

    try {
      dispatch(setIsUpdatingCustomerQuoteItems(true));
      const response = await api.post(`/api/serviceorders`, {
        idpaciente: pacienteId,
        itensservico: itensservico,
        idclinica,
        idagendamento,
        preferencial,
        prioritario,
        joinItems,
      });
      dispatch(setServiceOrderFromCustomerQuoteKey(response.data.data.codigo));
      dispatch(fetchCustomerQuoteServiceItems({ customerQuoteId }));
      dispatch(setIsUpdatingCustomerQuoteItems(false));
      dispatch(
        setServiceOrders({
          service_orders: [],
          total: 0,
          page: 0,
        })
      );
      dispatch(
        setServiceOrderData({
          idordemdeservico: response.data.data.idordemdeservico,
          idpaciente: response.data.data.idpaciente,
        })
      );
      dispatch(setForceOpenServiceOrderDialog(true));
      toast.success("Ordem de serviço adicionada com sucesso!", toastOptions);
      if (cb) {
        cb();
      }
      // setTimeout(() => {
      //   history.replace("/service-orders");
      // }, 2000);
      dispatch(setIsCreatingOSByQuote(false));
    } catch (error: any | AxiosError) {
      dispatch(setIsUpdatingCustomerQuoteItems(false));
      dispatch(setIsCreatingOSByQuote(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const insertCustomerQuoteGlobalDiscountOrAddition =
  ({
    customerQuoteId,
    data,
    callback,
  }: {
    customerQuoteId: string;
    data: any;
    callback: Function;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsUpdatingCustomerQuoteItems } = customersQuotesSlice.actions;
    dispatch(setIsUpdatingCustomerQuoteItems(true));
    try {
      await api.put(
        `/api/customerquotes/${customerQuoteId}/discount-increase`,
        data
      );
      dispatch(setIsUpdatingCustomerQuoteItems(false));

      if (callback) {
        callback();
      }

      toast.success("Itens atualizados com sucesso", toastOptions);

      dispatch(fetchCustomerQuote(customerQuoteId, callback));
      dispatch(setIsUpdatingCustomerQuoteItems(false));
    } catch (error: any) {
      dispatch(setIsUpdatingCustomerQuoteItems(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fakeDeleteDiscountAddition =
  ({
    customerQuoteId,
    data,
    callback,
  }: {
    customerQuoteId: string;
    data: any;
    callback: Function;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsUpdatingCustomerQuoteItems } = customersQuotesSlice.actions;
    dispatch(setIsUpdatingCustomerQuoteItems(true));
    try {
      await api.delete(
        `/api/customerquotes/${customerQuoteId}/discount-increase`,
        { data }
      );
      dispatch(setIsUpdatingCustomerQuoteItems(false));

      if (callback) {
        callback();
      }

      toast.success("Desconto ou acréscimo deletado com sucesso", toastOptions);

      dispatch(fetchCustomerQuote(customerQuoteId, callback));
      dispatch(setIsUpdatingCustomerQuoteItems(false));
    } catch (error: any) {
      dispatch(setIsUpdatingCustomerQuoteItems(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const linkServicePackageToCustomerQuote =
  ({
    servicePackageId,
    customerQuoteId,
    callback,
  }: {
    servicePackageId: string;
    customerQuoteId: string;
    callback?: Function;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsInsertingArrayOfQuoteItems } = customersQuotesSlice.actions;

    dispatch(setIsInsertingArrayOfQuoteItems(true));
    try {
      await api.post(
        `/api/combos-into-quote/${servicePackageId}/${customerQuoteId}`
      );
      toast.success("Combo vinculado ao orçamento", toastOptions);

      dispatch(fetchCustomerQuoteServiceItems({ customerQuoteId }));

      if (callback) {
        callback();
      }
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setIsInsertingArrayOfQuoteItems(false));
    }
  };

export const {
  setHealthPlanCategoryFromQuote,
  setCurrentCustomerQuote,
  removeItemFromQuoteToBeScheduled,
  setScheduleFromQuote,
  updateFilter: updateCustomerQuotesFilter,
  setServiceOrderFromCustomerQuoteKey,
  setItemsFromQuoteToBeScheduled,
  resetItemsFromQuoteToBeScheduled,
  setIsSchedulingItemsFromQuote,
  setIsSchedulingFromQuoteDialogOpen,
  setCurrentCustomerQuoteItems,
  setIsCreatingOSByQuote,
  setCurrentCodigoFilter,
  setPageToCostumerQuotesTable,
  setServiceOrderData,
  updateClearFilter,
  setClinicCustomerQuotesDisponibilityMap,
  setForceOpenServiceOrderDialog,
} = customersQuotesSlice.actions;

export default customersQuotesSlice.reducer;
