import {
  Button,
  Card,
  Grid,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateRangeDialog from "components/DateRangeDialog";
import { FullWidthContainer } from "components/layout/base";
import { Loader } from "components/Loader";
import TitleBar from "components/TitleBar";
import dayjs from "dayjs";
import { autocompleteDefaultProps } from "models/Autocomplete";
import { ISelectOption } from "pages/SchedulePage";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { rootState } from "store";
import { fetchClinics } from "store/reducers/ClinicsReducer";
import { fetchAllHealthPlans } from "store/reducers/HealthPlanReducer";
import {
  fetchRankCollaborators,
  updateClearReceivedPaymentsFilterArray,
  updateReceivedPaymentsFilterArray,
} from "store/reducers/ReportsReducer";
import { fetchRoles } from "store/reducers/RolesReducer";
import { fetchServiceCategories } from "store/reducers/ServiceCategoriesReducer";
import { fetchUsers } from "store/reducers/UsersReducer";

import { fetchServiceItems } from "store/reducers/ServiceItemsReducer";
import RankingCollaboratorsTable from "./RankingCollaboratorsTable";

const RankingCollaborators = () => {
  const dispatch = useDispatch();
  const match = useMediaQuery("(max-width: 500px)");
  const [filterDate, setFilterDate] = useState([Date(), Date()]);
  const [firstDateFilterChange, setFirstDateFilterChange] = useState(true);
  const [isDateRangeDialogOpen, setIsDateRangeDialogOpen] = useState(false);
  const [selectedClinic, setSelectedClinic] = useState<ISelectOption[]>([]);

  const [clinicsOptions, setClinicsOptions] = useState<ISelectOption[]>([
    { label: "", value: "" },
  ]);
  

  // Dev commit changes 
  // Update changes test JIRA links

  const { roles } = useSelector((state: rootState) => state.roles);

// Cargos //
  const [cargosValue, setCargosValue] = useState<ISelectOption[]>([]);
  const rolesToBeListed = useMemo(() => {
    const options = roles?.map((role) => ({ label: role.nome.toUpperCase(), value: role.idcargo }));
    return options ? [{ label: "TODOS", value: "" }, ...options] : [];
  }, [roles]);
// End cargos //


  useEffect(() => {
    dispatch(updateClearReceivedPaymentsFilterArray())
    dispatch(fetchServiceCategories({ limit: 9999 }));
    dispatch(fetchServiceItems({ limit: 9999 }));
    dispatch(fetchUsers({ limit: 9999 }));
    dispatch(fetchRoles());
    dispatch(fetchClinics({ limit: 9999 }));
  }, [dispatch]);

  const {
    reports: { rankCollaborators, isFetchingReport },
    users: { users, signedInUser },
    healthPlan: { allHealthPlans },
  } = useSelector((state: rootState) => state);

  const collaboratorsOptions = useMemo(() => {
    if (users && users.length > 0) {
      const filteredUsers = users.filter(
        (user) =>
          user.cargo.nome.toUpperCase() !== "PROFISSIONAL" ||
          user.cargo.nome.toUpperCase() === "CHATBOT" ||
          user.nome.toUpperCase() === "BOT WHATSAPP"
      );

      return filteredUsers.map((user) => {
        return {
          label: user.nome.toUpperCase(),
          value: user.idusuario,
        };
      });
    }

    return [];
  }, [users]);

  const [selectedCategory, setSelectedCategory] = useState<ISelectOption[]>([]);
  const {
    professionals: { isFetchingProfessionals },
    serviceCategories: { serviceCategories },
    serviceItems: { serviceItems },
  } = useSelector((state: rootState) => state);

  const healthPlansOptions = useMemo(() => {
    if (allHealthPlans) {
      return allHealthPlans.map((healthPlan) => ({
        label: healthPlan.nome,
        value: healthPlan.idconvenio as string,
      }));
    }

    return [];
  }, [allHealthPlans]);

  const serviceCategoriesOptions: ISelectOption[] = useMemo(() => {
    if (serviceCategories) {
      return serviceCategories.map((category) => {
        return {
          label: category.nome.toUpperCase(),
          value: category.idcategoriaservico.toString(),
        };
      });
    }

    return [];
  }, [serviceCategories]);


  function handleChangeCargoOptions(event: any, newValue: ISelectOption[]) {
    setCargosValue(newValue);
    if (newValue.length > 0) {
      dispatch(
        updateReceivedPaymentsFilterArray({
          key: "idcargo",
          value: newValue.map((value) => value.value),
        })
      );
    } else {
      dispatch(updateReceivedPaymentsFilterArray({ key: "idcargo", value: [] }));
    }
  }

  useEffect(() => {
    if (signedInUser && signedInUser.clinicas) {
      setClinicsOptions(
        signedInUser.clinicas?.map(({ nome, idclinica }) => {
          return { label: nome.toUpperCase(), value: idclinica };
        })
      );

      if (signedInUser.clinicas.length === 1) {
        setSelectedClinic([{
          label: signedInUser.clinicas[0].nome.toUpperCase(),
          value: signedInUser.clinicas[0].idclinica,
        }]);
        dispatch(
          updateReceivedPaymentsFilterArray({
            key: "idclinica",
            value: signedInUser.clinicas[0].idclinica,
          })
        );
      }
    }
  }, [signedInUser, dispatch]);

  const onChangeDateRange = (dateRange: any) => {
    setFilterDate([dateRange[0].startDate, dateRange[0].endDate]);
  };

  useEffect(() => {
    if (firstDateFilterChange) {
      setFirstDateFilterChange(false);
    } else {
      // resetPage();
      dispatch(
        updateReceivedPaymentsFilterArray({
          key: "start_date",
          value: dayjs(filterDate[0]).format("YYYY-MM-DD"),
        })
      );
      dispatch(
        updateReceivedPaymentsFilterArray({
          key: "end_date",
          value: dayjs(filterDate[1]).format("YYYY-MM-DD"),
        })
      );
    }
  }, [filterDate, dispatch, firstDateFilterChange]);

  useEffect(() => {
    dispatch(fetchAllHealthPlans());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showCollaborators = () => {
    if (isFetchingReport) {
      return <Loader />;
    }
    if (!rankCollaborators) {
      return (
        <Grid container justify="center">
        <Grid item>
          <Typography variant="body1" style={{ textTransform: "uppercase" }}>
            Selecione os filtros e click em buscar!
          </Typography>
        </Grid>
    </Grid>
      );
    }
    if (!rankCollaborators.groupByRoleResponse.length && !rankCollaborators.ordersItens.length) {
      return (
        <Grid container justify="center">
          <Grid item>
            <Typography variant="h6">
              Nenhum resultado encontrado (Verifique os filtros)
            </Typography>
          </Grid>
        </Grid>
      );
    }

    return (
      <Paper>
        <RankingCollaboratorsTable data={rankCollaborators} filter={filterDate} />
      </Paper>
    );
  };

  return (
    <>
      <Grid container direction="column">
        <Grid item xs={12}>
          <TitleBar title="Performance de colaboradores" wrap>
            <Grid
              container
              spacing={2}
              style={{ padding: "20px 0 0 20px" }}
            >
              <Grid item xs={match ? 12 : "auto"}>
                <Autocomplete
                  {...autocompleteDefaultProps}
                  multiple
                  value={selectedClinic}
                  options={clinicsOptions}
                  getOptionLabel={(option: ISelectOption) =>
                    option.label.toUpperCase()
                  }
                  // @ts-ignore
                  onChange={(event: any, newValue: ISelectOption[]) => {
                    setSelectedClinic(newValue);
                    dispatch(
                      updateReceivedPaymentsFilterArray({
                        key: "idclinica",
                        value: newValue.map((item) => item.value),
                      })
                    )
                  }}
                  fullWidth
                  renderInput={(params: any) => (
                    <TextField
                      SelectProps={{ style: { fontSize: 12 } }}
                      InputProps={{ style: { padding: 5 } }}
                      style={{ minWidth: match ? 200 : 0, width: match ? "100%" : "200px" }}
                      variant="outlined"
                      {...params}
                      label="Clínica"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={match ? 12 : "auto"}>
                <Autocomplete
                  {...autocompleteDefaultProps}
                  options={collaboratorsOptions}
                  onChange={(
                    event: React.ChangeEvent<{}>,
                    value: ISelectOption | null
                  ) => {
                    if (value && value?.value) {
                      dispatch(
                        updateReceivedPaymentsFilterArray({
                          key: "idusuario",
                          value: value.value,
                        })
                      );
                    } else {
                      dispatch(
                        updateReceivedPaymentsFilterArray({
                          key: "idusuario",
                          value: "",
                        })
                      );
                    }
                  }}
                  fullWidth
                  renderInput={(params: any) => (
                    <TextField
                      style={{ minWidth: match ? 200 : 0, width: match ? "100%" : "200px" }}
                      variant="outlined"
                      {...params}
                      label="Colaboradores"
                    />
                  )}
                />
              </Grid>

            {/* CARGO */}
            <Grid item xs={match ? 12 : "auto"}>
              <Autocomplete
                {...autocompleteDefaultProps}
                options={rolesToBeListed}
                value={cargosValue}
                multiple
                // @ts-ignore
                onChange={(event: any, newValue: ISelectOption[]) => {
                  handleChangeCargoOptions(event, newValue);
                }}
                fullWidth
                renderInput={(params: any) => (
                  <TextField style={{ minWidth: match ? 200 : 0, width: match ? "100%" : "200px" }} variant="outlined" {...params} label="Cargos" />
                )}
              />
            </Grid>

            {/* ESPECIALIDADE */}
            <Grid item xs={match ? 12 : "auto"}>
              <Autocomplete
                loading={isFetchingProfessionals}
                options={serviceCategoriesOptions}
                value={selectedCategory}
                noOptionsText="Nenhuma especialidade"
                multiple
                getOptionLabel={(option: any) => option.label + ""}
                onChange={(_: any, newValue: ISelectOption[]) => {
                  setSelectedCategory(newValue);

                  dispatch(
                    updateReceivedPaymentsFilterArray({
                      key: "idcategoriaservico",
                      value: newValue?.map((value) => value.value),
                    })
                  );
                }}
                fullWidth
                renderInput={(params: any) => (
                  <TextField
                    SelectProps={{ style: { fontSize: 12 } }}
                    InputProps={{ style: { padding: 5 } }}
                    style={{ minWidth: match ? 200 : 0, width: match ? "100%" : "200px" }}
                    variant="outlined"
                    {...params}
                    label="Especialidade"
                  />
                )}
              />
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setIsDateRangeDialogOpen(true)}
                style={{ height: "55px" }}
              >
                {`${dayjs(filterDate[0]).format("DD/MM/YYYY")} - ${dayjs(
                  filterDate[1]
                ).format("DD/MM/YYYY")}`}
              </Button>
            </Grid>

            <Grid item>
              <Button
                startIcon={<SearchIcon />}
                variant="contained"
                color="primary"
                disabled={!selectedClinic.length}
                style={{ height: "55px" }}
                onClick={() => {
                  dispatch(fetchRankCollaborators({}));
                }}
              >
                Buscar
              </Button>
            </Grid>
            </Grid>
            <DateRangeDialog
              onChangeDate={onChangeDateRange}
              handleClose={() => setIsDateRangeDialogOpen(false)}
              open={isDateRangeDialogOpen}
            />
          </TitleBar>
        </Grid>

        <Grid item xs={12}>
          <FullWidthContainer>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card>{showCollaborators()}</Card>
              </Grid>
            </Grid>
          </FullWidthContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default RankingCollaborators;
