import styled from "styled-components";

interface ISizedBoxProps {
  width?: number | string;
  height?: number | string;
}

export const SizedBox = styled.div<ISizedBoxProps>`
  height: ${(props) => (props.height ? props.height : 0)};
  width: ${(props) => (props.width ? props.width : 0)};
`;
