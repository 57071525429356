import {
  AnyAction,
  createSlice,
  PayloadAction,
  ThunkAction,
} from "@reduxjs/toolkit";
import { AppThunk, rootState } from "store/index";
import { toast } from "react-toastify";
import toastOptions from "utils/toastOptions";
import api from "utils/API";
import { IFilter } from "models/shared";
import { queryStringFromFilterArray } from "utils/network";
import { history } from "utils/history";
import { ITotem, ITotemForm } from "models/Totem";

interface IInitialState {
  isFetchingTotem: boolean;
  isCreatingTotem: boolean;
  isDeletingTotem: boolean;
  isEditBondDialogOpen: boolean;
  amountOfItemsToShow: number;
  totem: ITotem[] | null;
  currentTotem: ITotem | null;
  toAddPage: number;
  toAddTotal: number;
  total: number;
  page: number;
  filterArray: IFilter[];
  pageToTotem: number;
}

const initialState: IInitialState = {
  isFetchingTotem: false,
  isCreatingTotem: false,
  isDeletingTotem: false,
  isEditBondDialogOpen: false,
  toAddPage: 0,
  toAddTotal: 0,
  totem: null,
  currentTotem: null,
  amountOfItemsToShow: 6,
  total: 0,
  page: 0,
  filterArray: [{ key: "nome", value: [] }],
  pageToTotem: 1,
};

const Totemlice = createSlice({
  name: "Totemslice",
  initialState,
  reducers: {
    setIsFetchingTotem: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingTotem = payload;
    },
    setIsCreatingTotem: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreatingTotem = payload;
    },
    setisDeletingTotem: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeletingTotem = payload;
    },
    setIsEditBondDialogOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isEditBondDialogOpen = payload;
    },
    setAmountOfItemsToShow: (state, { payload }: PayloadAction<number>) => {
      state.amountOfItemsToShow = payload;
    },
    setTotem: (
      state,
      {
        payload: { totem, total, page },
      }: PayloadAction<{
        totem: ITotem[];
        total: number;
        page: number;
      }>
    ) => {
      state.totem = totem;
      state.total = total;
      state.page = page;
    },
    setToAddPagination: (
      state,
      {
        payload: { total, page },
      }: PayloadAction<{
        page: number;
        total: number;
      }>
    ) => {
      state.toAddPage = page;
      state.toAddTotal = total;
    },
    setCurrentCounter: (state, { payload }: PayloadAction<ITotem>) => {
      state.currentTotem = payload;
    },
    setPageToTotem: (state, { payload }: PayloadAction<number>) => {
      state.pageToTotem = payload;
    },
  },
});

export const fetchTotem =
  ({
    page = 1,
    limit = 6,
    idclinica,
    idbloco,
    idsetor,
  }: {
    page?: number;
    limit?: number;
    idclinica?: string;
    idsetor?: string;
    idbloco?: string;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { setIsFetchingTotem, setTotem } = Totemlice.actions;
    dispatch(setIsFetchingTotem(true));
    try {
      const state = getState();
      const { filterArray } = state.totem;
      const queryParameters = queryStringFromFilterArray(filterArray);
      const pageAndLimit =
        queryParameters.length === 0
          ? `?page=${page}&limit=${limit}`
          : `&page=${page}&limit=${limit}`;
      const response = await api.get(
        `/api/counter-totem${pageAndLimit}&idclinica=${idclinica}&idbloco=${idbloco}&idsetor=${idsetor}`
      );
      dispatch(setTotem(response.data.data));
      dispatch(setIsFetchingTotem(false));
    } catch (error: any) {
      dispatch(setIsFetchingTotem(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchTotemById =
  (totemId: string): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsFetchingTotem, setCurrentCounter } = Totemlice.actions;
    dispatch(setIsFetchingTotem(true));
    try {
      const response = await api.get(
        `/api/counter-totem-id?idtotem=${totemId}`
      );
      dispatch(setCurrentCounter(response.data.data));
      dispatch(setIsFetchingTotem(false));
    } catch (error: any) {
      dispatch(setIsFetchingTotem(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const updateTotem =
  ({
    totem,
    totemId,
    idclinica,
    idbloco,
    idsetor,
    idstiposervico,
    idsconjuntoguiche,
    cb,
  }: {
    totem: ITotemForm;
    totemId: string | number;
    idclinica?: string;
    idbloco?: string;
    idsetor?: string;
    idstiposervico?: string[];
    idsconjuntoguiche?: string[];
    cb?: () => void;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingTotem } = Totemlice.actions;

    dispatch(setIsCreatingTotem(true));
    try {
      await api.put(`/api/counter-totem-id?idtotem=${totemId}`, {
        ...totem,
        idclinica: idclinica,
        idstiposervico,
        idsetor,
        idbloco,
        idsconjuntoguiche,
      });
      dispatch(setIsCreatingTotem(false));
      toast.success("Totem atualizado", toastOptions);
      history.replace(
        `/clinics/${idclinica}/departments/${idsetor}/blocks/${idbloco}/attendance/totem`
      );
      if (cb) cb();
    } catch (error: any) {
      dispatch(setIsCreatingTotem(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const createTotem =
  (
    totem: ITotemForm,
    idclinica?: string,
    idbloco?: string,
    idsetor?: string,
    idstiposervico?: string[],
    idsconjuntoguiche?: string[]
  ): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsCreatingTotem } = Totemlice.actions;
    dispatch(setIsCreatingTotem(true));
    try {
      await api.post(`/api/counter-totem`, {
        ...totem,
        idclinica: idclinica,
        idstiposervico,
        idsconjuntoguiche,
        idsetor,
        idbloco,
      });
      dispatch(setIsCreatingTotem(false));
      toast.success("Totem cadastrado", toastOptions);
      history.replace(
        `/clinics/${idclinica}/departments/${idsetor}/blocks/${idbloco}/attendance/totem`
      );
    } catch (error: any) {
      dispatch(setIsCreatingTotem(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const deleteTotem =
  (
    idtotem: string,
    idclinica: string,
    idbloco: string,
    idsetor: string
  ): AppThunk =>
  async (dispatch) => {
    const { setisDeletingTotem } = Totemlice.actions;
    dispatch(setisDeletingTotem(true));
    try {
      await api.delete(`/api/counter-totem-id?idtotem=${idtotem}`);
      dispatch(setisDeletingTotem(false));
      toast.success("Totem excluido com sucesso", toastOptions);
      dispatch(
        fetchTotem({
          idclinica,
          idbloco,
          idsetor,
        })
      );
    } catch (error: any) {
      dispatch(setisDeletingTotem(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const {
  setIsEditBondDialogOpen,
  setPageToTotem,
  setAmountOfItemsToShow,
} = Totemlice.actions;

export default Totemlice.reducer;
