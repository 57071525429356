import {
  Image,
  StyleSheet,
  Text,
  View
} from "@react-pdf/renderer";
import dayjs from "dayjs";
import React, { ReactNode } from "react";

interface Props {
  title?: string;
  subtitle?: string;
  dateToPrint?: Date | string | undefined;
  dateStringToPrint?: string;
  isMedicalAppointment?: boolean;
  isProcessingDate?: boolean;
  date?: boolean;
  children?: ReactNode;
}

export default ({
  title,
  subtitle,
  dateToPrint,
  dateStringToPrint,
  isMedicalAppointment = true,
  isProcessingDate = false,
  date = true,
  children,
}: Props) => {
  return (
    <View style={styles.flexDiv}>
      <Image
        style={styles.image}
        src={process.env.REACT_APP_LOGO_BASE64}
      ></Image>
      <View style={styles.headerContainer}>
        <View style={styles.titleDateContainer}>
          <View
            style={
              isMedicalAppointment
                ? styles.titleSubtitleContainer
                : { ...styles.titleSubtitleContainer, maxWidth: "350px" }
            }
          >
            <Text style={styles.titleText}>{`${
              title || process.env.REACT_APP_NETWORK_NAME
            }`}</Text>
            <Text style={styles.subtitle}>{subtitle}</Text>
          </View>
          {date ? (
            <Text style={styles.titleDate}>
              {isProcessingDate
                ? "Data de impressão:"
                : isMedicalAppointment
                ? "Data da consulta:"
                : "Data:"}{" "}
              {dateStringToPrint
                ? dateStringToPrint
                : dateToPrint && dateToPrint === dayjs().format("DD/MM/YYYY")
                ? dateToPrint
                : dateToPrint
                ? dayjs(dateToPrint).format("DD/MM/YYYY")
                : dayjs().format("DD/MM/YYYY")}
            </Text>
          ) : (
            children
          )}
        </View>

        <View style={styles.borderBottom} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 10,
    width: "85%",
  },
  titleSubtitleContainer: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "300px",
  },
  titleText: {
    fontSize: 14,
    fontWeight: "ultrabold",
    fontFamily: "Open Sans",
  },
  titleDateContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: "bold",
  },
  titleDate: {
    fontSize: 10,
    fontWeight: "medium",
    fontFamily: "Open Sans",
  },
  subtitle: {
    fontSize: 12,
    fontWeight: "bold",
    fontFamily: "Open Sans",
    marginVertical: 10,
  },
  image: {
    marginVertical: 0,
    marginHorizontal: 0,
    width: 60,
    height: 60,
  },
  flexDiv: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  borderBottom: {
    borderBottomColor: "black",
    borderBottomWidth: 1,
    // marginTop: -2
  },
});
