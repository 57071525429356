import { IFilter } from "models/shared/index";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "store/index";
import { toast } from "react-toastify";
import toastOptions from "utils/toastOptions";
import api from "utils/API";
import { queryStringFromFilterArray } from "utils/network";
import { IDisponibilityMapDay } from "models/DisponibilityMap";
import dayjs from "dayjs";
import { ISelectOption } from "pages/SchedulePage";
import { clearUpdateSchedulesFecthInterval } from "../AppointmentsReducer";
import axios, { CancelTokenSource } from "axios";
import { dateRangeOptions } from "pages/SchedulePage/components/DisponibilityMapDialog";
import { addDays, startOfDay } from "date-fns";

interface IInitialState {
  length: ISelectOption;
  isFetchingDisponibilityMap: boolean;
  disponibilityMap: IDisponibilityMapDay[] | null;
  disponibilityMapFilterArray: IFilter[];
  currentServiceCategories: ISelectOption[];
  currentProfessionals: ISelectOption[];
  currentClinics: ISelectOption[];
  currentServiceItems: ISelectOption[];
  currentHealthPlan: ISelectOption;
  updateDisponibilityMapFetchIntervalId: number;
  requestAbortTokenSource: CancelTokenSource | null;
  cameFromCombos: boolean;
  modalHover : boolean;
  permissionOpenModalMap: boolean;
  dataModalHover : any;
}

const initialState: IInitialState = {
  length: { label: "7 dias", value: "7" },
  isFetchingDisponibilityMap: false,
  disponibilityMap: null,
  disponibilityMapFilterArray: [
    { key: "length", value: 7 },
    { key: "iditemservico", value: null },
    { key: "idcategoriaservico", value: null },
    { key: "idprofissional", value: null },
    { key: "idclinica", value: null },
    { key: "idconvenio", value: null },
    { key: "idplano", value: null },
    { key: "search", value: null },
  ],
  currentServiceCategories: [],
  currentProfessionals: [],
  currentServiceItems: [],
  currentClinics: [],
  currentHealthPlan: { label: "", value: "" },
  updateDisponibilityMapFetchIntervalId: -1,
  requestAbortTokenSource: null,
  cameFromCombos: false,
  modalHover: false,
  dataModalHover: {},
  permissionOpenModalMap: true,
};

const disponibilityMapSlice = createSlice({
  name: "disponibilityMapSlice",
  initialState,
  reducers: {
    setPermissionOpenModalMap : (state, { payload }:PayloadAction<boolean>) => {
      state.permissionOpenModalMap = payload
    },
    setDataModalHover : (state, { payload }:PayloadAction<any>) => {
      state.dataModalHover = payload;
    },
    setModalHover: (state, { payload }: PayloadAction<boolean>) => {
      state.modalHover = payload;
    },
    setIsFetchingDisponibilityMap: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingDisponibilityMap = payload;
    },
    setRequestAbortTokenSource: (state, { payload }: PayloadAction<CancelTokenSource>) => {
      state.requestAbortTokenSource = payload;
    },
    setUpdateDisponibilityMapFetchIntervalId: (state, { payload }: PayloadAction<number>) => {
      state.updateDisponibilityMapFetchIntervalId = payload;
    },
    setDisponibilityMap: (state, { payload }: PayloadAction<IDisponibilityMapDay[] | null>) => {
      state.disponibilityMap = payload;
    },
    updateDisponibilityMapFilterArray: (state, { payload }: PayloadAction<IFilter>) => {
      const index = state.disponibilityMapFilterArray.findIndex((item) => item.key === payload.key);
      if (index === -1) {
        state.disponibilityMapFilterArray.push({ key: payload.key, value: payload.value });
      } else {
        state.disponibilityMapFilterArray[index].value = payload.value;
      }
    },
    resetDisponibilityMapFilterArray: (state) => {
      state.disponibilityMapFilterArray = [];
    },
    setDisponibilityMapServiceCategories: (state, { payload }: PayloadAction<ISelectOption[]>) => {
      state.currentServiceCategories = payload;
    },
    setDisponibilityMapProfessionals: (state, { payload }: PayloadAction<ISelectOption[]>) => {
      state.currentProfessionals = payload;
    },
    setDisponibilityMapServiceItems: (state, { payload }: PayloadAction<ISelectOption[]>) => {
      state.currentServiceItems = payload;
    },
    setDisponibilityMapCurrentClinics: (state, { payload }: PayloadAction<ISelectOption[]>) => {
      state.currentClinics = payload;
    },
    setDisponibilityMapCurrentHealthPlan: (state, { payload }: PayloadAction<ISelectOption>) => {
      state.currentHealthPlan = payload;
    },
    setDisponibilityMapLength: (state, { payload }: PayloadAction<ISelectOption>) => {
      state.length = payload;
    },
    setCameFromCombos: (state, { payload }: PayloadAction<boolean>) => {
      state.cameFromCombos = payload;
    },
  },
});

export const fetchDisponibilityMap =
  ({ clinicId, initialDay }: { clinicId?: string[], initialDay?: number }): AppThunk =>
  async (dispatch, getState) => {
    const { setDisponibilityMapLength, setIsFetchingDisponibilityMap, setDisponibilityMap, setRequestAbortTokenSource, updateDisponibilityMapFilterArray } =
      disponibilityMapSlice.actions;
    dispatch(setIsFetchingDisponibilityMap(true));
    try {
      const state = getState();
      const { disponibilityMapFilterArray, requestAbortTokenSource, length } = state.disponibilityMap;
      const { guidance } = state.appointments
      const { currentPatient } = state.schedulePage
      const queryParameters = queryStringFromFilterArray(disponibilityMapFilterArray);

      try {
        if (requestAbortTokenSource) {
          requestAbortTokenSource.cancel("fetchDisponibilityMap request canceled");
        }
      } catch (error: any) {
        console.log("fetchDisponibilityMap request not aborted");
      }

      if (!requestAbortTokenSource) {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        setRequestAbortTokenSource(source);
      }
      const patient = currentPatient.idpaciente ? queryParameters.length === 0 ? `?idpatient=${currentPatient.idpaciente}` : `&idpatient=${currentPatient.idpaciente}` : ""

      let dayFormat = new Date();

      if (initialDay && initialDay > 0) {
        dayFormat = startOfDay(addDays(dayFormat, initialDay));
      }

      const data = queryParameters.length === 0 ? `?data=${dayjs(dayFormat).format()}` : `&data=${dayjs(dayFormat).format()}`;
      const response = await api.get(`/api/clinics/availability-map${queryParameters}${data}${patient}`, {
        cancelToken: requestAbortTokenSource ? requestAbortTokenSource.token : undefined,
      });

      const hasOption = dateRangeOptions.find(e => e.initialDay === length.initialDay && length.value !== "1");

      if (
        response.data.data.length === 0 && 
        hasOption
      ) {
        const index = dateRangeOptions.findIndex(item => item.initialDay === length.initialDay);
        const nextItem = dateRangeOptions[index + 1];

        if (nextItem.value !== "1") {
          dispatch(updateDisponibilityMapFilterArray({
            key: "length",
            value: nextItem.value,
          }))
  
          dispatch(setDisponibilityMapLength({ 
            value: nextItem.value, 
            label: nextItem.label, 
            initialDay: nextItem.initialDay 
          }))
  
          if (hasOption) {
            dispatch(setDisponibilityMap(response.data.data));
            return dispatch(fetchDisponibilityMap({ initialDay: hasOption.initialDay }));
          }
        }
      }
      dispatch(setDisponibilityMap(response.data.data));
      dispatch(setIsFetchingDisponibilityMap(false));
    } catch (error: any) {
      dispatch(setIsFetchingDisponibilityMap(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const startUpdateDisponibilityMapFetchInterval = (): AppThunk => async (dispatch, getState) => {
  const { setUpdateDisponibilityMapFetchIntervalId } = disponibilityMapSlice.actions;

  const state = getState();
  const { updateDisponibilityMapFetchIntervalId } = state.disponibilityMap;

  if (updateDisponibilityMapFetchIntervalId) {
    clearInterval(updateDisponibilityMapFetchIntervalId);
  }

  var _tid = setInterval(() => {
    dispatch(fetchDisponibilityMap({}));
  }, 180000);

  dispatch(clearUpdateSchedulesFecthInterval());
  dispatch(setUpdateDisponibilityMapFetchIntervalId(_tid));
};

export const clearUpdateDisponibilityMapFetchInterval = (): AppThunk => async (dispatch, getState) => {
  const state = getState();
  const { updateDisponibilityMapFetchIntervalId } = state.disponibilityMap;

  if (updateDisponibilityMapFetchIntervalId) {
    clearInterval(updateDisponibilityMapFetchIntervalId);
  }
};

export const {
  resetDisponibilityMapFilterArray,
  updateDisponibilityMapFilterArray,
  setDisponibilityMapCurrentClinics,
  setDisponibilityMapProfessionals,
  setDisponibilityMapServiceCategories,
  setDisponibilityMapServiceItems,
  setDisponibilityMapLength,
  setUpdateDisponibilityMapFetchIntervalId,
  setRequestAbortTokenSource,
  setDisponibilityMap,
  setCameFromCombos,
  setDisponibilityMapCurrentHealthPlan,
  setModalHover,
  setDataModalHover,
  setPermissionOpenModalMap
} = disponibilityMapSlice.actions;

export default disponibilityMapSlice.reducer;
