import React, { useCallback, useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  Autocomplete,
  IconButton,
  Stack,
} from "@mui/material";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";

import { ISelectOption } from "components/CallPatient/CallPatient.types";
import { FormBaseContainer } from "components/layout/base";
import { Formik, Form as FormikForm } from "formik";
import { initialState, validationSchema } from "./data";
import {
  IAppointmentWebSetupInstallmentsForm,
  IAppointmentWebSetupInstallmentsRoles,
  IInstallment,
  createInstallmentRolesAppointmentWebSetup,
} from "store/reducers/AppointmentWebSetup";
import { useDispatch, useSelector } from "react-redux";
import { fetchPaymentMethods } from "store/reducers/PaymentMethodsReducer";
import { fetchServiceGroups } from "store/reducers/ServiceGroupsReducer";
import { rootState } from "store";
import { IPaymentMethod } from "models/PaymentMethod";
// @ts-ignore
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import { Close } from "@material-ui/icons";

interface IProps {
  open: boolean;
  onClose: Function;
  paymentInstallment: IInstallment | null;
}

export const AddAppointmentInstallmentRoleModal = ({
  onClose,
  open,
  paymentInstallment,
}: IProps) => {
  const [initialValues, setInitialValues] = useState(initialState);

  const [serviceGroupOptions, setServiceGroupOptions] = useState<
    ISelectOption[]
  >([]);
  const [selectedServiceGroup, setSelectedServiceGroup] = useState<any>({
    label: "",
    value: "",
  });

  const [paymentMethod, setPaymentMethod] = useState<
    IPaymentMethod | undefined
  >(undefined);

  const [appointmentRoles, setAppointmentRoles] = useState<
    IAppointmentWebSetupInstallmentsRoles[]
  >([]);

  const [paymentMethodOptions, setPaymentMethodOptions] = useState<
    ISelectOption[]
  >([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<any>({
    label: "",
    value: "",
  });

  const dispatch = useDispatch();

  const { isFetchingServiceGroups, serviceGroups } = useSelector(
    (state: rootState) => state.serviceGroups
  );
  const { isFetchingPaymentMethods, paymentMethods } = useSelector(
    (state: rootState) => state.paymentMethods
  );

  const { currentAppointmentWebSetup, isCreatingRole } = useSelector(
    (state: rootState) => state.appointmentWebSetup
  );

  const handleChangeServiceGroup = (
    event: any,
    newValue: ISelectOption,
    setFieldValue: Function
  ) => {
    setSelectedServiceGroup(newValue);
    if (newValue) {
      setFieldValue("idgruposervico", newValue.value);
    } else {
      setFieldValue("idgruposervico", "");
    }
  };

  const handleChangePaymentMethod = (
    event: any,
    newValue: ISelectOption,
    setFieldValue: Function
  ) => {
    setSelectedPaymentMethod(newValue);
    if (newValue) {
      setFieldValue("idmetodopagamento", newValue.value);
    } else {
      setFieldValue("idmetodopagamento", "");
    }
  };

  useEffect(() => {
    if (paymentInstallment) {
      setSelectedServiceGroup({
        label: paymentInstallment.gruposervico.nome.toUpperCase(),
        value: paymentInstallment.idgruposervico,
      });
      setSelectedPaymentMethod({
        label: paymentInstallment.metodopagamento.nome.toUpperCase(),
        value: paymentInstallment.idmetodopagamento,
      });
      setInitialValues({
        idgruposervico: paymentInstallment.idgruposervico,
        idmetodopagamento: paymentInstallment.idmetodopagamento,
        regrasparcelamento: paymentInstallment.regrasparcelamento,
      });
    }
  }, [paymentInstallment]);

  const handleSubmit = () => {
    const newValues: IAppointmentWebSetupInstallmentsForm = {
      idgruposervico: selectedServiceGroup.value,
      idmetodopagamento: selectedPaymentMethod.value,
      regrasparcelamento: appointmentRoles,
    };

    dispatch(
      createInstallmentRolesAppointmentWebSetup({
        appointmentWebRoleForm: newValues,
      })
    );
    onClose();
  };

  useEffect(() => {
    setInitialValues(initialState);
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      fetchPaymentMethods({
        limit: 100000,
        list_all_methods: true
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      fetchServiceGroups({
        limit: 100000,
        forceAll: true,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (serviceGroups) {
      let serviceGroupsEnabled =
        currentAppointmentWebSetup && currentAppointmentWebSetup.modules.combo
          ? serviceGroups
          : serviceGroups.filter(
              (group) => group.nome.toLowerCase() !== "combos"
            );
      setServiceGroupOptions(
        serviceGroupsEnabled
          .filter((item) => item.agendamentoonline)
          .map(({ idgruposervico, nome }) => ({
            label: nome.toUpperCase(),
            value: idgruposervico,
          }))
      );
    }
  }, [serviceGroups, currentAppointmentWebSetup]);

  useEffect(() => {
    if (paymentMethods) {
      setPaymentMethodOptions(
        paymentMethods
          .filter((item) => item.agendamentoonline)
          .map(({ idmetodopagamento, nome }) => ({
            label: nome.toUpperCase(),
            value: idmetodopagamento ? idmetodopagamento : "",
          }))
      );
    }
  }, [paymentMethods]);

  useEffect(() => {
    setPaymentMethod(
      paymentMethods?.find(
        (item) => item.idmetodopagamento === selectedPaymentMethod?.value
      )
    );
  }, [paymentMethods, selectedPaymentMethod]);

  useEffect(() => {
    if (paymentMethod && currentAppointmentWebSetup) {
      const minInstallment = 1;
      const maxInstallment = currentAppointmentWebSetup.modules.parcelamento
        ? paymentMethod.taxas_credito
          ? paymentMethod.taxas_credito.length
          : 1
        : 1;

      const roles: IAppointmentWebSetupInstallmentsRoles[] =
        paymentInstallment?.regrasparcelamento
          ? paymentInstallment?.regrasparcelamento
          : [];

      if (!paymentInstallment?.regrasparcelamento) {
        for (let index = minInstallment; index <= maxInstallment; index++) {
          roles.push({
            valorminimo: 0,
            parcela: index,
          });
        }
      }

      setAppointmentRoles(roles);
    }
  }, [paymentMethod, currentAppointmentWebSetup, paymentInstallment]);

  const handleChangeRole = (index: number, install: number, value: number) => {
    const arr: IAppointmentWebSetupInstallmentsRoles[] = [];

    const temp = [...appointmentRoles];

    for (const role of temp) {
      let newValue = role.valorminimo;
      if (role.parcela === install) {
        newValue = value;
      }

      arr.push({
        parcela: role.parcela,
        valorminimo: newValue,
      });
    }

    setAppointmentRoles(arr);
  };

  return (
    <Dialog open={open} onClose={() => onClose()} maxWidth="lg">
      <DialogTitle>
        Adicionar Regra de Parcelamento (Pagamento Antecipado)
        <IconButton
          onClick={() => onClose()}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <FormBaseContainer>
          <Formik
            onSubmit={() => {}}
            validationSchema={validationSchema}
            initialValues={initialValues}
            enableReinitialize
          >
            {({ errors, setFieldValue, values }) => (
              <FormikForm>
                <Grid
                  container
                  spacing={2}
                  style={{
                    marginTop: 2,
                    marginBottom: 48,
                  }}
                >
                  <Grid item lg={12}>
                    <Alert color="info">
                      Para configurar as regras de parcelamento, selecione o
                      Grupo de Serviço e o Método de Pagamento desejados!
                    </Alert>
                  </Grid>
                  <Grid item lg={6}>
                    <Autocomplete
                      name="idgruposervico"
                      getOptionLabel={(option: ISelectOption) => option.label}
                      options={serviceGroupOptions}
                      disabled={
                        isFetchingServiceGroups || paymentInstallment
                          ? true
                          : false
                      }
                      value={selectedServiceGroup}
                      loading={isFetchingServiceGroups}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      getOptionSelected={(
                        option: ISelectOption,
                        value: ISelectOption
                      ) => {
                        return value.value === option.value;
                      }}
                      // @ts-ignore
                      onChange={(event: any, newValue: ISelectOption) => {
                        handleChangeServiceGroup(
                          event,
                          newValue,
                          setFieldValue
                        );
                      }}
                      fullWidth
                      renderInput={(params: any) => (
                        <TextField
                          error={errors.idgruposervico}
                          helperText={errors.idgruposervico}
                          name="idgruposervico"
                          style={{ minWidth: 200 }}
                          variant="outlined"
                          placeholder="Selecione um grupo de serviço..."
                          {...params}
                          label="Grupo de serviço"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item lg={6}>
                    <Autocomplete
                      name="idmetodopagamento"
                      getOptionLabel={(option: ISelectOption) => option.label}
                      options={paymentMethodOptions}
                      disabled={
                        isFetchingPaymentMethods ||
                        !values.idgruposervico ||
                        paymentInstallment
                          ? true
                          : false
                      }
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.label
                      }
                      value={selectedPaymentMethod}
                      loading={isFetchingPaymentMethods}
                      getOptionSelected={(
                        option: ISelectOption,
                        value: ISelectOption
                      ) => {
                        return value.value === option.value;
                      }}
                      // @ts-ignore
                      onChange={(event: any, newValue: ISelectOption) => {
                        handleChangePaymentMethod(
                          event,
                          newValue,
                          setFieldValue
                        );
                      }}
                      fullWidth
                      renderInput={(params: any) => (
                        <TextField
                          error={errors.idmetodopagamento}
                          helperText={errors.idmetodopagamento}
                          name="idmetodopagamento"
                          style={{ minWidth: 200 }}
                          variant="outlined"
                          placeholder="Selecione um método de pagamento..."
                          {...params}
                          label="Método de pagamento"
                        />
                      )}
                    />
                  </Grid>

                  {paymentMethod && (
                    <Grid item lg={12}>
                      <Grid
                        container
                        spacing={2}
                        style={{
                          marginTop: 2,
                        }}
                      >
                        <Grid item lg={12}>
                          <Alert color="warning">
                            <AlertTitle>Atenção</AlertTitle>
                            Nesse momento você definirá as regras de
                            parcelamento para agendamentos de{" "}
                            <b>{selectedServiceGroup?.label}</b> com pagamento
                            no <b>{selectedPaymentMethod?.label}</b>
                          </Alert>
                        </Grid>
                        {appointmentRoles.map((role, index) => (
                          <Grid item lg={6}>
                            <Stack spacing={2} alignItems="start">
                              <CurrencyTextField
                                name="taxaadm"
                                variant="outlined"
                                placeholder="Digite o valor mínimo para parcelar"
                                fullWidth
                                label={`Valor mínimo para (${role.parcela}x)`}
                                value={appointmentRoles[index].valorminimo}
                                currencySymbol="R$"
                                //minimumValue="0"
                                outputFormat="number"
                                decimalCharacter=","
                                digitGroupSeparator="."
                                onChange={(event: any, value: any) =>
                                  handleChangeRole(index, role.parcela, value)
                                }
                              />
                            </Stack>
                          </Grid>
                        ))}
                        <Grid item lg={12}>
                          <Alert color="info" icon={false}>
                            Para editar a quantidade de parcelas, acesse a aba{" "}
                            <b>Financeiro</b> / <b>Métodos de pagamento</b>
                          </Alert>
                        </Grid>

                        <Grid
                          item
                          lg={12}
                          style={{
                            marginTop: 8,
                          }}
                        >
                          <Button
                            color="primary"
                            variant="contained"
                            type="button"
                            fullWidth
                            size="large"
                            disabled={isCreatingRole || !paymentMethod}
                            onClick={() => handleSubmit()}
                          >
                            Cadastrar regra
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </FormikForm>
            )}
          </Formik>
        </FormBaseContainer>
      </DialogContent>
    </Dialog>
  );
};
