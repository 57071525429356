import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProfessionalReport } from "models/ProfessionalReport";
import { IFilter } from "models/shared";
import { toast } from "react-toastify";
import { AppThunk } from "store/index";
import api from "utils/API";
import { queryStringFromFilterArray } from "utils/network";
import toastOptions from "utils/toastOptions";

interface IInitialState {
  isFetchingProfessionalsReports: boolean;
  professionalsReports: null | IProfessionalReport[];
  professionalsProductionReports: null | IProfessionalReport[];
  page: number;
  total: number;
  professionalsReportsFilterArray: IFilter[];
  isTransferModalOpen: boolean;
  isTransferModalType: "input" | "output";
}

const initialState: IInitialState = {
  isFetchingProfessionalsReports: false,
  professionalsReports: null,
  professionalsProductionReports: null,
  page: 0,
  total: 0,
  professionalsReportsFilterArray: [
    { key: "idordemdeservico", value: null },
    { key: "idprofissional", value: null },
    { key: "idclinica", value: null },
    { key: "status", value: null },
    { key: "banco", value: null },
    { key: "by_payment_date", value: null },
    { key: "start_date", value: null },
    { key: "end_date", value: null },
    { key: "idmovimentacao", value: null },
    { key: "idfluxocaixa", value: null },
  ],
  isTransferModalOpen: false,
  isTransferModalType: "output",
};

const professionalsReportsSlice = createSlice({
  name: "professionalsReportsSlice",
  initialState,
  reducers: {
    setIsFetchingProfessionalsReports: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingProfessionalsReports = payload;
    },
    setProfessionalsReports: (
      state,
      {
        payload: { transactions, page, total },
      }: PayloadAction<{
        transactions: IProfessionalReport[];
        page: number;
        total: number;
      }>
    ) => {
      state.page = page;
      state.professionalsReports = transactions;
      state.total = total;
    },
    setProfessionalsProductionReports: (
      state,
      {
        payload: { transactions, page, total },
      }: PayloadAction<{
        transactions: IProfessionalReport[];
        page: number;
        total: number;
      }>
    ) => {
      state.page = page;
      state.professionalsProductionReports = transactions;
      state.total = total;
    },
    updateProfessionalsReportsFilter: (
      state,
      { payload }: PayloadAction<IFilter>
    ) => {
      const index = state.professionalsReportsFilterArray.findIndex(
        (item) => item.key === payload.key
      );

      if (index === -1) {
        state.professionalsReportsFilterArray.push({
          key: payload.key,
          value: payload.value,
        });
      } else {
        state.professionalsReportsFilterArray[index].value = payload.value;
      }
    },
    setIsTransferModalOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isTransferModalOpen = payload;
    },
    setIsTransferModalType: (state, { payload }: PayloadAction<"input" | "output">) => {
      state.isTransferModalType = payload;
    },
  },
});

export const fetchProfessionalsReports =
  ({ page = 1, limit = 9999 }: { page?: number; limit?: number }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingProfessionalsReports, setProfessionalsReports } =
      professionalsReportsSlice.actions;
    try {
      const state = getState();
      const { professionalsReportsFilterArray } = state.professionalsReports;

      const queryParameters = queryStringFromFilterArray(
        professionalsReportsFilterArray
      );
      const pageAndLimit =
        queryParameters.length === 0
          ? `?page=${page}&limit=${limit}`
          : `&page=${page}&limit=${limit}`;
      dispatch(setIsFetchingProfessionalsReports(true));

      const response = await api.get(
        `/api/financial/professional-report${queryParameters}${pageAndLimit}`
      );

      dispatch(setProfessionalsReports(response.data.data));
      dispatch(setIsFetchingProfessionalsReports(false));
    } catch (error: any) {
      dispatch(setIsFetchingProfessionalsReports(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };
export const fetchProfessionalsProductionReports =
  ({ professionalId, page = 1, limit = 9999 }: { professionalId: string; page?: number; limit?: number }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingProfessionalsReports, setProfessionalsProductionReports } =
      professionalsReportsSlice.actions;
    try {
      const state = getState();
      const { professionalsReportsFilterArray } = state.professionalsReports;

      const queryParameters = queryStringFromFilterArray(
        professionalsReportsFilterArray
      );
      const pageAndLimit =
        queryParameters.length === 0
          ? `?page=${page}&limit=${limit}`
          : `&page=${page}&limit=${limit}`;
      dispatch(setIsFetchingProfessionalsReports(true));

      const response = await api.get(
        `/api/financial/professional-production-report/${professionalId}/${queryParameters}${pageAndLimit}`
      );

      dispatch(setProfessionalsProductionReports(response.data.data));
      dispatch(setIsFetchingProfessionalsReports(false));
    } catch (error: any) {
      dispatch(setIsFetchingProfessionalsReports(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const { 
  updateProfessionalsReportsFilter, 
  setIsTransferModalOpen, 
  setIsTransferModalType 
} =
  professionalsReportsSlice.actions;

export default professionalsReportsSlice.reducer;
