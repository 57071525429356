import { IFilter } from "models/shared/index";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "store/index";
import { toast } from "react-toastify";
import toastOptions from "utils/toastOptions";
import api from "utils/API";
import { queryStringFromFilterArray } from "utils/network";
import { Summary } from "pages/Reports/SchedulesOfDay/Summary/Summary.types";

export interface IReceivedPayment {
  nomepaciente: string;
}

export interface IStatementTable {
  creationDate: string;
  statusText: string;
  patient: string;
  professional: string;
  category: string;
  service: string;
  value: string;
  health_plan_value: string;
  scheduleDate: string;
}

interface IInitialState {
  isFetchingReport: boolean;
  filterArray: IFilter[];
  total: number;
  scheduleStatement: IStatementTable[];
  scheduleRoleStatement: any;
  scheduleSummary: Summary;
}

const initialState: IInitialState = {
  isFetchingReport: false,
  filterArray: [
    { key: "user", value: [] },
    { key: "start_date", value: null },
    { key: "end_date", value: null },
    { key: "idprofissional", value: null },
    { key: "idcategoriaservico", value: null },
    { key: "idclinica", value: null },
    { key: "idcanalatendimento", value: null },
    { key: "idcanalorigem", value: null },
    { key: "new_patients", value: null },
    { key: "idconvenio", value: null },
  ],
  scheduleStatement: [],
  scheduleRoleStatement: [],
  scheduleSummary: {
    mainSummary: {
      users: 0,
      schedules: {
        cons: 0,
        exam: 0,
        proc: 0,
        ret: 0,
      },
      patientsIds: [],
      newPatientsIds: [],
      patients: 0,
      newPatients: 0,
      revenue: 0,
    },
    serviceChannelSummary: [],
    serviceOriginSummary: [],
  },
  total: 0,
};

const reportsScheduleReducer = createSlice({
  name: "reportsScheduleReducer",
  initialState,
  reducers: {
    setIsFetchingReport: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingReport = payload;
    },
    setReport: (state, { payload: { summary, statement, roleStatement } }: PayloadAction<any>) => {
      state.scheduleRoleStatement = roleStatement;
      state.scheduleStatement = statement;
      state.scheduleSummary = summary;
    },
    clearFilter:(state, { payload }:PayloadAction<IFilter[]>) =>{
      state.filterArray = payload
    },
    updateFilter: (state, { payload }: PayloadAction<IFilter>) => {
      const index = state.filterArray.findIndex((item) => item.key === payload.key);
      if (index === -1) {
        state.filterArray.push({ key: payload.key, value: payload.value });
      } else {
        state.filterArray[index].value = payload.value;
      }
      state.scheduleRoleStatement = null
    },
  },
});

export const clearListScheduleReport = ():AppThunk =>(dispatch) => {
  const { setIsFetchingReport, setReport, clearFilter } = reportsScheduleReducer.actions;
  dispatch(
    setReport(initialState)
  );
  dispatch(clearFilter([
    { key: "user", value: [] },
    { key: "start_date", value: null },
    { key: "end_date", value: null },
    { key: "idprofissional", value: null },
    { key: "idcategoriaservico", value: null },
    { key: "idclinica", value: null },
    { key: "idcanalatendimento", value: null },
    { key: "idcanalorigem", value: null },
    { key: "new_patients", value: null },
    { key: "idconvenio", value: null },
  ]))
}

export const fetchScheduleReport = (): AppThunk => async (dispatch, getState) => {
  const { setIsFetchingReport, setReport } = reportsScheduleReducer.actions;
  dispatch(setIsFetchingReport(true));
  const state = getState();
  const { filterArray } = state.reportsSchedule;
  const queryParameters = queryStringFromFilterArray(filterArray);

  try {
    const response = await api.get(`/api/reports/schedulesofday${queryParameters}`);
    dispatch(setReport(response.data.data));
    dispatch(setIsFetchingReport(false));
  } catch (error: any) {
    dispatch(setIsFetchingReport(false));
    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);
    } else {
      console.log(error.message);
    }
  }
};

export const { setIsFetchingReport, setReport, updateFilter } = reportsScheduleReducer.actions;

export default reportsScheduleReducer.reducer;
