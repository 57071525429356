import {
  Action,
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";

import AdditionsReducer from "store/reducers/AdditionsReducer";
import AppointmentsReducer from "store/reducers/AppointmentsReducer";
import BankAccountsReducer from "store/reducers/BankAccountsReducer";
import BlocksReducer from "store/reducers/BlocksReducer";
import CashDesksReducer from "store/reducers/CashDesksReducer";
import ClinicsReducer from "store/reducers/ClinicsReducer";
import CustomersQuotesReducer from "store/reducers/CustomerQuotesReducer";
import DepartmentsReducer from "store/reducers/DepartmentsReducer";
import DiagnosticReducer from "store/reducers/DiagnosticReducer";
import DiscountsReducer from "store/reducers/DiscountsReducer";
import DisponibilityMapReducer from "store/reducers/DisponibilityMapReducer";
import ExamRequestReducer from "store/reducers/ExamRequestReducer";
import HealthPlanReducer from "store/reducers/HealthPlanReducer";
import HealthPlanCategoryReducer from "store/reducers/HealthPlanCategoryReducer";
import HealthPlanProfessionalReducer from "store/reducers/HealthPlanProfessionalReducer";
import HealthPlansReportsReducer from "./reducers/HealthPlansReportsReducer";
import AnamnesisAndEvolutionReducer from "store/reducers/AnamnesisAndEvolutionReducer";
import LabReducer from "store/reducers/LabReducer";
import OfficesReducer from "store/reducers/OfficesReducer";
import MedicalFormReducer from "store/reducers/MedicalFormReducer";
import MedicalRecordReducer from "store/reducers/MedicalRecordReducer";
import MedicinesReducer from "store/reducers/Medicines";
import MedicalKitsReducer from "store/reducers/MedicalKitsReducer";
import ExamsKitsReducer from "store/reducers/ExamsKitsReducer";
import PatientsReducer from "store/reducers/PatientsReducer";
import PatientAttendenceHistoryReducer from "store/reducers/PatientAttendenceHistoryReducer";
import PatientsAttendedReducer from "store/reducers/PatientsAttendedReducer";
import PaymentMethodsReducer from "store/reducers/PaymentMethodsReducer";
import PersistFormReducer from "store/reducers/PersistFormReducer";
import ProfessionalsReducer from "store/reducers/ProfessionalsReducer";
import ProfessionalsReportsReducer from "store/reducers/ProfessionalsReportsReducer";
import RolesReducer from "store/reducers/RolesReducer";
import ScheduleReducer from "store/reducers/ScheduleReducer";
import SchedulePageReducer from "store/reducers/SchedulePageReducer";
import ServiceCategoriesReducer from "store/reducers/ServiceCategoriesReducer";
import ServiceChannelsReducer from "store/reducers/ServiceChannels";
import ServiceGroupsReducer from "store/reducers/ServiceGroupsReducer";
import ServiceItemsReducer from "store/reducers/ServiceItemsReducer";
import ServiceOrdersReducer from "store/reducers/ServiceOrderReducer";
import ServicePackagesReducer from "store/reducers/ServicePackages";
import TransactionsReducer from "store/reducers/TransactionsReducer";
import UiReducer from "store/reducers/UiReducer";
import UsersReducer from "store/reducers/UsersReducer";
import PaginationReducer from "./reducers/PaginationReducer";
import ReportsReducer from "store/reducers/ReportsReducer";
import ReportsCombosReducer from "store/reducers/ReportsCombosReducer";
import DashboardReducer from "./reducers/DashboardReducer";
import ReportsScheduleReducer from "./reducers/ReportsScheduleReducer";
import ReportsHealthPlanProductionReducer from "./reducers/ReportsHealthPlanProductionReducer";
import ReportsProfessionals from "./reducers/ReportsProfessionalsReducer";
import CounterReducer from "./reducers/CounterReducer";
import WindowSetReducer from "./reducers/WindowSetReducer";
import CounterTypeServiceReducer from "./reducers/CounterTypeServiceReducer";
import AttendanceGroupReducer from "./reducers/AttendanceGroupReducer";
import CallPatientReducer from "./reducers/CallPatientReducer";
import CallPanelReducer from "./reducers/CallPanel";
import TotemReducer from "./reducers/TotemReducer";
import ReleaseMedicalRecordsReducer from "./reducers/ReleaseMedicalRecordsReducer";
import ClienteFornecedorReducer from "./reducers/ClienteFornecedorReducer";
import PaymentMachinesReducer from "./reducers/PaymentMachinesReducer";
import BillsReducer from "./reducers/BillsReducer";
import CashFlowReducer from "./reducers/CashFlowReducer";
import AppointmentWebSetupSlice from "./reducers/AppointmentWebSetup";
import PriceListReducer from "./reducers/PriceListReducer";
import AuthReducer from "./reducers/AuthReducer";
import MedicalTemplatesReducer from "./reducers/MedicalTemplates";
import HealthPlanServiceTussReducer from "./reducers/HealthPlanServiceTussReducer";
import CovenantReducer from "./reducers/Covenant";
import ManufacturersReducer from "./reducers/ManufacturersReducer";
import LocationsReducer from "./reducers/LocalReducer";
import categoryStockSlice from "./reducers/CategoryStockReducer";
import itemsStockReducer from "./reducers/StockItemsReducer";
import ProfessionalFavoritesReducer from "./reducers/ProfessionalFavorites";
import AppointmentsGroupReducer from "./reducers/AppointmentGroupReducer";
import OdontogramReducer from "./reducers/OdontogramReducer";

const reducer = combineReducers({
  auth: AuthReducer,
  additions: AdditionsReducer,
  appointments: AppointmentsReducer,
  bankAccounts: BankAccountsReducer,
  blocks: BlocksReducer,
  callPatients: CallPatientReducer,
  cashDesks: CashDesksReducer,
  clinics: ClinicsReducer,
  customersQuote: CustomersQuotesReducer,
  customersProviders: ClienteFornecedorReducer,
  dashboard: DashboardReducer,
  departments: DepartmentsReducer,
  diagnostic: DiagnosticReducer,
  discounts: DiscountsReducer,
  disponibilityMap: DisponibilityMapReducer,
  examRequest: ExamRequestReducer,
  healthPlan: HealthPlanReducer,
  healthPlanCategory: HealthPlanCategoryReducer,
  healthPlanProfessional: HealthPlanProfessionalReducer,
  healthPlanServiceTuss: HealthPlanServiceTussReducer,
  healthPlansReports: HealthPlansReportsReducer,
  anamnesisAndEvolution: AnamnesisAndEvolutionReducer,
  lab: LabReducer,
  offices: OfficesReducer,
  medicalForm: MedicalFormReducer,
  medicalRecords: MedicalRecordReducer,
  medicines: MedicinesReducer,
  medicalKits: MedicalKitsReducer,
  examsKits: ExamsKitsReducer,
  roles: RolesReducer,
  users: UsersReducer,
  patients: PatientsReducer,
  patientAttendenceHistory: PatientAttendenceHistoryReducer,
  patientAttended: PatientsAttendedReducer,
  paymentMethods: PaymentMethodsReducer,
  persist: PersistFormReducer,
  professionals: ProfessionalsReducer,
  professionalsReports: ProfessionalsReportsReducer,
  schedule: ScheduleReducer,
  schedulePage: SchedulePageReducer,
  serviceCategories: ServiceCategoriesReducer,
  serviceChannels: ServiceChannelsReducer,
  serviceGroups: ServiceGroupsReducer,
  serviceItems: ServiceItemsReducer,
  serviceOrders: ServiceOrdersReducer,
  servicePackages: ServicePackagesReducer,
  transactions: TransactionsReducer,
  ui: UiReducer,
  pagination: PaginationReducer,
  reports: ReportsReducer,
  reportsCombos: ReportsCombosReducer,
  reportsSchedule: ReportsScheduleReducer,
  reportsProfessionals: ReportsProfessionals,
  reportsHealthPlanProduction: ReportsHealthPlanProductionReducer,
  counter: CounterReducer,
  windowSet: WindowSetReducer,
  counterTypeService: CounterTypeServiceReducer,
  attendanceGroup: AttendanceGroupReducer,
  callPanel: CallPanelReducer,
  totem: TotemReducer,
  releaseMedicalRecords: ReleaseMedicalRecordsReducer,
  paymentMachines: PaymentMachinesReducer,
  bills: BillsReducer,
  cashFlows: CashFlowReducer,
  appointmentWebSetup: AppointmentWebSetupSlice,
  priceList: PriceListReducer,
  medicalTemplates: MedicalTemplatesReducer,
  covenant: CovenantReducer,
  professionalFavorites: ProfessionalFavoritesReducer,
  appointmentGroup: AppointmentsGroupReducer,
  manufacturers: ManufacturersReducer,
  locations: LocationsReducer,
  categoryStock: categoryStockSlice,
  itemsStock: itemsStockReducer,
  odontogram: OdontogramReducer,
});

const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: {
      warnAfter: 128,
    },
  }),
});

export type rootState = ReturnType<typeof store.getState>;
export type AppThunk = ThunkAction<void, rootState, unknown, Action<string>>;
export type AppDispatch = typeof store.dispatch;

export default store;
