import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, AppDispatch } from "store/index";
import { toast } from "react-toastify";
import toastOptions from "utils/toastOptions";
import api from "utils/API";
import {
  IDiscount,
  IServiceOrderServiceItemDiscountAdditionForm,
  IServiceOrderServiceItemDiscountAdditionUpdate,
  IServiceOrderDiscountAdditionForm,
  IServiceOrderAdditionUpdate,
} from "models/Discount";
import { fetchServiceOrderById } from "../ServiceOrderReducer";

interface IInitialState {
  isApplyingDiscount: boolean;
  isFetchingDiscount: boolean;
  isDeletingDiscount: boolean;
  currentDiscount: IDiscount | null;
}

const initialState: IInitialState = {
  isApplyingDiscount: false,
  isFetchingDiscount: false,
  isDeletingDiscount: false,
  currentDiscount: null,
};

const discountsSlice = createSlice({
  name: "discountsSlice",
  initialState,
  reducers: {
    setIsApplyingDiscount: (state, { payload }: PayloadAction<boolean>) => {
      state.isApplyingDiscount = payload;
    },
    setIsFetchingDiscount: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingDiscount = payload;
    },
    setIsDeletingDiscount: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeletingDiscount = payload;
    },
    setCurrentDiscount: (state, { payload }: PayloadAction<IDiscount>) => {
      state.currentDiscount = payload;
    },
    clearCurrentDiscount: (state) => {
      state.currentDiscount = null;
    },
  },
});

export const updateServiceOrderServiceItemDiscount =
  ({
    data,
    callback,
  }: {
    data: IServiceOrderServiceItemDiscountAdditionUpdate;
    callback?: Function;
  }): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { setIsApplyingDiscount } = discountsSlice.actions;
    try {
      dispatch(setIsApplyingDiscount(true));
      await api.put(`/api/discount/order-item/${data.id}`, data);
      if (callback) {
        callback();
      }
      toast.success("Desconto atualizado com sucesso", toastOptions);
      dispatch(setIsApplyingDiscount(false));
      dispatch(fetchServiceOrderById(data.idordemservico));
    } catch (error) {
      dispatch(setIsApplyingDiscount(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const updateServiceOrderDiscount =
  ({
    data,
    callback,
  }: {
    data: IServiceOrderAdditionUpdate;
    callback?: Function;
  }): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { setIsApplyingDiscount } = discountsSlice.actions;
    try {
      dispatch(setIsApplyingDiscount(true));
      await api.put(`/api/discount/service-order/${data.id}`, data);
      if (callback) {
        callback();
      }
      toast.success("Desconto atualizado com sucesso", toastOptions);
      dispatch(setIsApplyingDiscount(false));
      dispatch(fetchServiceOrderById(data.idordemservico));
    } catch (error) {
      dispatch(setIsApplyingDiscount(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const createServiceOrderServiceItemDiscount =
  ({
    data,
    callback,
  }: {
    data: IServiceOrderServiceItemDiscountAdditionForm;
    callback?: Function;
  }): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { setIsApplyingDiscount } = discountsSlice.actions;
    try {
      dispatch(setIsApplyingDiscount(true));
      await api.post(`/api/discount/order-item`, data);
      if (callback) {
        callback();
      }
      toast.success("Desconto aplicado com sucesso", toastOptions);
      dispatch(setIsApplyingDiscount(false));
      dispatch(fetchServiceOrderById(data.idordemservico));
    } catch (error) {
      dispatch(setIsApplyingDiscount(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const createServiceOrderDiscount =
  ({
    data,
    callback,
  }: {
    data: IServiceOrderDiscountAdditionForm;
    callback?: Function;
  }): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { setIsApplyingDiscount } = discountsSlice.actions;
    try {
      dispatch(setIsApplyingDiscount(true));
      await api.post(`/api/discount/service-order`, data);
      if (callback) {
        callback();
      }
      toast.success("Desconto aplicado com sucesso", toastOptions);
      dispatch(setIsApplyingDiscount(false));
      dispatch(fetchServiceOrderById(data.idordemservico));
    } catch (error) {
      dispatch(setIsApplyingDiscount(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchDiscount =
  (discountId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { setIsFetchingDiscount, setCurrentDiscount } =
      discountsSlice.actions;
    try {
      dispatch(setIsFetchingDiscount(true));
      const response = await api.get(`/api/discount/${discountId}`);
      dispatch(setCurrentDiscount(response.data.data));
      dispatch(setIsFetchingDiscount(false));
    } catch (error) {
      dispatch(setIsFetchingDiscount(false));

      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const deleteServiceOrderServiceItemDiscount =
  ({
    discountId,
    callback,
    idordemservico,
    email,
    password,
    isForced,
  }: {
    discountId: string;
    idordemservico: string;
    email: string | undefined;
    password: string | undefined;
    callback?: Function;
    isForced?: boolean;
  }): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { setIsDeletingDiscount } = discountsSlice.actions;
    try {
      dispatch(setIsDeletingDiscount(true));
      await api.delete(`/api/discount/order-item/${discountId}`, {
        data: {
          email,
          password,
          isForced,
        },
      });
      if (callback) {
        callback();
      }
      dispatch(fetchServiceOrderById(idordemservico));
      toast.success("Desconto excluido com sucesso", toastOptions);
      dispatch(setIsDeletingDiscount(false));
    } catch (error) {
      dispatch(setIsDeletingDiscount(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const deleteServiceOrderDiscount =
  ({
    callback,
    idordemservico,
    email,
    password,
    motivo,
    isForced,
  }: {
    idordemservico: string;
    callback?: Function;
    email: string;
    password: string;
    motivo?: string;
    isForced?: boolean;
  }): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { setIsDeletingDiscount } = discountsSlice.actions;
    try {
      dispatch(setIsDeletingDiscount(true));
      await api.delete(`/api/discount/service-order`, {
        data: { idordemservico, email, password, motivo, isForced },
      });
      if (callback) {
        callback();
      }
      dispatch(fetchServiceOrderById(idordemservico));
      toast.success("Desconto excluido com sucesso", toastOptions);
      dispatch(setIsDeletingDiscount(false));
    } catch (error) {
      dispatch(setIsDeletingDiscount(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const { clearCurrentDiscount } = discountsSlice.actions;

export default discountsSlice.reducer;
