import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AppThunk } from "store";
import api from "utils/API";
import toastOptions from "utils/toastOptions";
import {
	MedicineKit,
	MedicineKitItemModel,
	MedicinesKitsState,
} from "./MedicalKits.types";

const initialState: MedicinesKitsState = {
	user_id: "",
	medicinesKits: [],
	medicinesKit: {
		name: "",
		idusuario: "",
	},
	medicineKitFilterId: "",
	isFetchingMedicinesKits: false,
	isFetchingMedicinesKitsItems: false,
	listPage: 0,
	listLimit: 6,
	listTotalSize: 0,
	isCreatingMedicineKit: false,
	medicine_kit_items: [],
	idmedicinekit: "",
};

const medicalKitsSlice = createSlice({
	name: "medicines-kits",
	initialState,
	reducers: {
		setUserId: (state, action) => {
			state.user_id = action.payload;
		},
		setMedicineKits: (state, action) => {
			state.medicinesKits = action.payload;
		},
		setMedicineKit: (state, action) => {
			state.medicinesKit = action.payload;
		},
		setMedicineKitFilterId: (state, action) => {
			state.medicineKitFilterId = action.payload;
		},
		setIsFetchingMedicineKits: (state, action) => {
			state.isFetchingMedicinesKits = action.payload;
		},
		setIsFetchingMedicineKitsItems: (state, action) => {
			state.isFetchingMedicinesKitsItems = action.payload;
		},
		setListPage: (state, { payload }) => {
			state.listPage = payload;
		},
		setListLimit: (state, { payload }) => {
			state.listLimit = payload;
		},
		setListTotalSize: (state, { payload }) => {
			state.listTotalSize = payload;
		},
		setIsCreatingMedicineKit: (state, { payload }) => {
			state.isCreatingMedicineKit = payload;
		},
		setMedicineKitItems: (state, { payload }) => {
			state.medicine_kit_items = payload;
		},
		setIdMedicineKit: (state, { payload }) => {
			state.idmedicinekit = payload;
		},
	},
});

export const fetchMedicinesKits =
	({
		user_id,
		page = 1,
		limit = 6,
	}: {
		user_id: string;
		page: number;
		limit: number;
	}): AppThunk =>
	async (dispatch, getState) => {
		const {
			setMedicineKits,
			setListTotalSize,
			setIsFetchingMedicineKits,
		} = medicalKitsSlice.actions;
		dispatch(setIsFetchingMedicineKits(true));

		try {
			const { data } = await api.get(
				`/api/medicinekits?user_id=${user_id}&page=${page}&limit=${limit}`
			);
			dispatch(setMedicineKits(data.data.medicine_kits));
			dispatch(setListTotalSize(data.data.total));
		} catch (error: any) {
			console.log(error);

			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingMedicineKits(false));
		}
	};

export const fetchMedicineKitsById =
	(idmedicinekit: string): AppThunk =>
	async (dispatch, getState) => {
		const { setMedicineKit, setIsFetchingMedicineKits } =
			medicalKitsSlice.actions;
		dispatch(setIsFetchingMedicineKits(true));
		try {
			const { data } = await api.get(`/api/medicinekits/${idmedicinekit}`);
			dispatch(setMedicineKit(data.data));
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingMedicineKits(false));
		}
	};

export const fetchMedicineKitsItems =
	(medicine_kit_id: string): AppThunk =>
	async (dispatch, getState) => {
		const { setMedicineKitItems, setIsFetchingMedicineKitsItems } =
			medicalKitsSlice.actions;
		dispatch(setIsFetchingMedicineKitsItems(true));
		try {
			const { data } = await api.get(
				`/api/medicinekititems?medicine_kit_id=${medicine_kit_id}`
			);
			dispatch(setMedicineKitItems(data.data.medicine_kit_items));
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingMedicineKitsItems(false));
		}
	};

export const createMedicineKitItem =
	(medicine_kit_item: MedicineKitItemModel): AppThunk =>
	async (dispatch, getState) => {
		const { setIsCreatingMedicineKit } = medicalKitsSlice.actions;
		const { idmedicinekit } = getState().medicalKits;

		dispatch(setIsCreatingMedicineKit(true));
		try {
			await api.post(`/api/medicinekititems`, medicine_kit_item);
			toast.success("Item vinculado ao kit com sucesso", {
				toastId: "create-medicine-kit-item",
			});
			dispatch(fetchMedicineKitsItems(idmedicinekit));
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsCreatingMedicineKit(false));
		}
	};

export const createMedicineKit =
	(medicineKit: MedicineKit): AppThunk =>
	async (dispatch, getState) => {
		dispatch(setIsCreatingMedicineKit(true));
		const { setIdMedicineKit } = medicalKitsSlice.actions;
		try {
			const { data } = await api.post("/api/medicinekits", medicineKit);
			toast.success("Kit criado com sucesso");
			dispatch(setIdMedicineKit(data.data.idmedicinekit));
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsCreatingMedicineKit(false));
		}
	};

export const editMedicineKit =
	({
		medicine_kit_id,
		body,
	}: {
		medicine_kit_id: string;
		body: MedicineKit;
	}): AppThunk =>
	async (dispatch, getState) => {
		const { setIsFetchingMedicineKits } = medicalKitsSlice.actions;
		dispatch(setIsFetchingMedicineKits(true));
		try {
			await api.put(`/api/medicinekits/${medicine_kit_id}`, body);
			toast.success("Kit editado com sucesso");
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingMedicineKits(false));
		}
	};

export const editMedicineKitItem =
	({
		medicine_kit_item_id,
		body,
	}: {
		medicine_kit_item_id: string;
		body: MedicineKitItemModel;
	}): AppThunk =>
	async (dispatch, getState) => {
		const { setIsFetchingMedicineKits } = medicalKitsSlice.actions;
		const { idmedicinekit } = getState().medicalKits;
		dispatch(setIsFetchingMedicineKits(true));
		try {
			await api.put(`/api/medicinekititems/${medicine_kit_item_id}`, body);
			toast.success("Item editado com sucesso", {
				toastId: "edit-medicine-kit-item",
			});
			dispatch(fetchMedicineKitsItems(idmedicinekit));
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingMedicineKits(false));
		}
	};

export const deleteMedicineKit =
	(medicineKitId: string): AppThunk =>
	async (dispatch, getState) => {
		const { user_id, listLimit, listPage } = getState().medicalKits;
		const { setIsFetchingMedicineKits } = medicalKitsSlice.actions;
		dispatch(setIsFetchingMedicineKits(true));
		try {
			await api.delete(`/api/medicinekits/${medicineKitId}`);
			dispatch(
				fetchMedicinesKits({ user_id, page: listPage, limit: listLimit })
			);
			toast.success("Kit excluído com sucesso", {
				toastId: "delete-kit",
			});
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingMedicineKits(false));
		}
	};

export const deleteMedicineKitItem =
	(medicineKitItemId: string): AppThunk =>
	async (dispatch, getState) => {
		const { setIsFetchingMedicineKitsItems } = medicalKitsSlice.actions;
		const { idmedicinekit } = getState().medicalKits;
		dispatch(setIsFetchingMedicineKitsItems(true));
		try {
			await api.delete(`/api/medicinekititems/${medicineKitItemId}`);
			toast.success("Item excluído com sucesso", {
				toastId: "delete-kit-item",
			});
			dispatch(fetchMedicineKitsItems(idmedicinekit));
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingMedicineKitsItems(false));
		}
	};

export const {
	setUserId,
	setMedicineKits,
	setIsFetchingMedicineKits,
	setListPage,
	setListTotalSize,
	setListLimit,
	setMedicineKitFilterId,
	setIsCreatingMedicineKit,
	setMedicineKitItems,
	setIsFetchingMedicineKitsItems,
	setMedicineKit,
	setIdMedicineKit,
} = medicalKitsSlice.actions;

export default medicalKitsSlice.reducer;
