import React from "react";

import { v4 as uuidv4 } from "uuid";

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@material-ui/core";

import GenericTableHead from "components/table/GenericTableHead";
import { IHealthPlanRank } from "store/reducers/ReportsReducer";
import { formatCurrency } from "utils/formatCurrency";
import { ExpandMore } from "@material-ui/icons";
import TableDefault from "components/TableDefault";

const extractAnalytics = [
  {
    id: uuidv4(),
    alignment: "left",
    label: "Atendimento",
    key: "served_date",
    typeData: "date",
  },
  {
    id: uuidv4(),
    alignment: "left",
    label: "Pagamento",
    key: "paid_date",
    typeData: "date",
  },
  {
    id: uuidv4(),
    alignment: "left",
    label: "Profissional",
    key: "professional_name",
  },
  {
    id: uuidv4(),
    alignment: "left",
    label: "Paciente",
    key: "patient_name",
  },
  {
    id: uuidv4(),
    alignment: "left",
    label: "Ordem de Serviço",
    key: "os_number",
  },
  {
    id: uuidv4(),
    alignment: "center",
    label: "Item",
    key: "item_description",
  },
  {
    id: uuidv4(),
    alignment: "left",
    label: "Plano",
    key: "health_plan"
  },
  {
    id: uuidv4(),
    alignment: "left",
    label: "Valor da venda",
    key: "base_cost",
    typeData: "money"
  },
  {
    id: uuidv4(),
    alignment: "left",
    label: "Fat. Convênio",
    key: "plan_pass",
    typeData: "money",
  },
  // {
  //   id: uuidv4(),
  //   alignment: "left",
  //   label: "Valor Desconto",
  //   key: "amount_discount",
  //   typeData: "money",
  // },
  // {
  //   id: uuidv4(),
  //   alignment: "left",
  //   label: "Valor Líquido",
  //   key: "amount_paid",
  //   typeData: "money",
  // },

  // {
  //   id: uuidv4(),
  //   alignment: "left",
  //   label: "Custo direto",
  //   key: "direct_costs",
  //   typeData: "money",
  // },
];
const tableCells = [
  // { id: uuidv4(), alignment: "left", label: "NFS-e em Lote" },
  { id: uuidv4(), alignment: "left", label: "Top" },
  { id: uuidv4(), alignment: "left", label: "Convênio" },
  { id: uuidv4(), alignment: "center", label: "Faturamento total" },
  { id: uuidv4(), alignment: "center", label: "Repasses do convênio" },
  {
    id: uuidv4(),
    alignment: "center",
    label: "Repasses do cliente",
    whiteSpace: "nowrap",
  },
  {
    id: uuidv4(),
    alignment: "center",
    label: "Total de vendas",
    whiteSpace: "nowrap",
  },
];

interface IProps {
  data: IHealthPlanRank;
}

const RankingServicesTable = ({ data }: IProps) => {
  return (
    <>
      <TableContainer>
        <Grid
          item
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "1rem 1rem",
          }}
        >
          <Typography variant="body1" style={{ margin: "1rem 1rem 0" }}>
            <strong style={{ color: "#00A1A9" }}>
              Resumo geral de Convênios
            </strong>
          </Typography>
        </Grid>

        <Table size="small" style={{ marginTop: "2rem" }}>
          <TableBody>
            <TableRow key={"resumo"}></TableRow>
          </TableBody>
        </Table>
        <Table size="small">
          <GenericTableHead headCells={tableCells} />
          <TableBody>
            {data.health_plan_service_order_results.map((row, index) => (
              <TableRow key={uuidv4()}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{row.health_plan}</TableCell>
                <TableCell
                  align="center"
                  style={{ textTransform: "uppercase" }}
                >
                  {formatCurrency(Number(row.amount_paid))}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ textTransform: "uppercase" }}
                >
                  {formatCurrency(Number(row.amount_transfer_health_plan))}
                </TableCell>
                <TableCell align="center">
                  {formatCurrency(Number(row.amount_transfer_client))}
                </TableCell>
                <TableCell align="center">
                  {formatCurrency(Number(row.amount_health_plan_billed))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* EXTRATO ANALÍTICO DAS MOVIMENTAÇÕES FINANCEIRAS */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="body1" style={{ margin: "1rem 1rem 0" }}>
            <strong style={{ color: "#00A1A9" }}>
              Extrato analitico das movimentacões:
            </strong>
          </Typography>

        </AccordionSummary>
        <AccordionDetails>
          <Grid xs={12}>
            <TableDefault
              fileNameCsv="Extrato analitico das movimentações"
              table={extractAnalytics}
              data={data.analytic_service_order_results}
              pagination="front"
            >
            </TableDefault>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default RankingServicesTable;
