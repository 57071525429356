import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "store/index";
import { toast } from "react-toastify";
import toastOptions from "utils/toastOptions";
import api from "utils/API";
import { IMedicalForm } from "models/MedicalForm";

interface IInitialState {
  isFetchingTemplates: boolean;
  isCreatingTemplate: boolean;
  isDeletingTemplate: boolean;
  isFetchingMedicalForms: boolean;
  isCreatingMedicalForm: boolean;
  isUpdatingMedicalForm: boolean;
  isDeletingMedicalForm: boolean;
  formTemplates: IMedicalForm[];
  medicalForms: IMedicalForm[];
  currentFormTemplate: IMedicalForm | null;
  currentMedicalForm: IMedicalForm | null;
  reportText: string;
  examsResultsText: string;
}

const initialState: IInitialState = {
  isFetchingTemplates: false,
  isCreatingTemplate: false,
  isDeletingTemplate: false,
  isFetchingMedicalForms: false,
  isCreatingMedicalForm: false,
  isUpdatingMedicalForm: false,
  isDeletingMedicalForm: false,
  formTemplates: [],
  medicalForms: [],
  currentFormTemplate: null,
  currentMedicalForm: null,
  reportText: "",
  examsResultsText: "",
};

const medicalFormSlice = createSlice({
  name: "medicalFormSlice",
  initialState,
  reducers: {
    setIsFetchingTemplates: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingTemplates = payload;
    },
    setIsCreatingTemplate: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreatingTemplate = payload;
    },
    setIsDeletingTemplate: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeletingTemplate = payload;
    }, //
    setIsFetchingMedicalForms: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingMedicalForms = payload;
    },
    setIsCreatingMedicalForm: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreatingMedicalForm = payload;
    },
    setIsUpdatingMedicalForm: (state, { payload }: PayloadAction<boolean>) => {
      state.isUpdatingMedicalForm = payload;
    },
    setIsDeletingMedicalForm: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeletingMedicalForm = payload;
    }, //
    setTemplates: (
      state,
      {
        payload: { medical_forms },
      }: PayloadAction<{ medical_forms: IMedicalForm[] }>
    ) => {
      state.formTemplates = medical_forms;
    }, //
    setMedicalForms: (
      state,
      {
        payload: { medical_forms },
      }: PayloadAction<{ medical_forms: IMedicalForm[] }>
    ) => {
      state.medicalForms = medical_forms;
    },
    setReportText: (state, { payload }: PayloadAction<string>) => {
      state.reportText = payload;
    },
    setExamsResultsText: (state, { payload }: PayloadAction<string>) => {
      state.examsResultsText = payload;
    },
    setCurrentMedicalForm: (
      state,
      { payload }: PayloadAction<IMedicalForm>
    ) => {
      state.currentMedicalForm = payload;
    },
  },
});

export const fetchTemplates =
  (type = "laudo"): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingTemplates, setTemplates } = medicalFormSlice.actions;
    try {
      dispatch(setIsFetchingTemplates(true));
      const response = await api.get(`/api/medicalforms?tipo=${type}`);
      dispatch(setTemplates(response.data.data));
      dispatch(setIsFetchingTemplates(false));
    } catch (error: any) {
      dispatch(setIsFetchingTemplates(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchMedicalForms =
  (
    {
      idMedicalRecord,
    }: {
      idMedicalRecord: string;
    },
    type = "laudo",
    types?: string[],
    cb?: Function
  ): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingMedicalForms, setMedicalForms } =
      medicalFormSlice.actions;
    try {
      dispatch(setIsFetchingMedicalForms(true));
      let uri = `/api/medicalforms?idMedicalRecord=${idMedicalRecord}&tipo=${type}`;

      if (types && types.length > 0) {
        types.map((t) => {
          uri += "&tipos[]=" + t;
        });
      }

      const response = await api.get(uri);
      dispatch(setMedicalForms(response.data.data));
      dispatch(setIsFetchingMedicalForms(false));
      if (cb) {
        cb();
      }
    } catch (error: any) {
      dispatch(setIsFetchingMedicalForms(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const createTemplate =
  (
    data: {
      nome: string;
      tipo: string;
      texto: string;
    },
    cb?: () => void
  ): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingTemplate } = medicalFormSlice.actions;
    dispatch(setIsCreatingTemplate(true));
    try {
      await api.post(`/api/medicalforms`, data);
      dispatch(setIsCreatingTemplate(false));
      dispatch(fetchTemplates());
      if (cb) {
        cb();
      }
    } catch (error: any) {
      dispatch(setIsCreatingTemplate(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const createMedicalForm =
  (
    data: {
      idMedicalRecord: string;
      nome: string;
      tipo: string;
      texto: string;
      isnew?: boolean;
      disableToast?: boolean;
    },
    cb?: () => void
  ): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingMedicalForm, setCurrentMedicalForm } =
      medicalFormSlice.actions;
    dispatch(setIsCreatingMedicalForm(true));
    try {
      const createdMedicalForm = await api.post(`/api/medicalforms`, data);
      dispatch(setIsCreatingMedicalForm(false));
      dispatch(setCurrentMedicalForm(createdMedicalForm.data.data));

      if (data.tipo !== "declaracao" && data.tipo !== "atestado") {
        dispatch(
          fetchMedicalForms(
            { idMedicalRecord: data.idMedicalRecord },
            data.tipo
          )
        );
      }

      if (!data.disableToast) {
        toast.success("Salvo com sucesso", toastOptions);
      }

      if (cb) {
        cb();
      }
    } catch (error: any) {
      dispatch(setIsCreatingMedicalForm(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const updateMedicalForm =
  (
    idMedicalForm: string,
    data: {
      idMedicalRecord: string;
      nome: string;
      tipo: string;
      texto: string;
      disableToast?: boolean;
    },
    cb?: () => void
  ): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingMedicalForm } = medicalFormSlice.actions;
    dispatch(setIsCreatingMedicalForm(true));
    try {
      await api.put(`/api/medicalforms/${idMedicalForm}`, data);
      dispatch(setIsCreatingMedicalForm(false));
      dispatch(
        fetchMedicalForms({ idMedicalRecord: data.idMedicalRecord }, data.tipo)
      );
      if (cb) {
        cb();
      }

      if (!data.disableToast) {
        toast.success("Editado com sucesso", toastOptions);
      }
    } catch (error: any) {
      dispatch(setIsCreatingMedicalForm(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const deleteMedicalForm =
  (idformulario: string, cb?: () => void): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingMedicalForm } = medicalFormSlice.actions;
    dispatch(setIsCreatingMedicalForm(true));
    try {
      await api.delete(`/api/medicalforms/${idformulario}`);
      dispatch(setIsCreatingMedicalForm(false));
      if (cb) {
        cb();
      }
      toast.success("Excluído com sucesso", toastOptions);
    } catch (error: any) {
      dispatch(setIsCreatingMedicalForm(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

// export const deleteDiagnostic = (data: {
//   idMedicalRecord: string;
//   idDiagnostic: string;
// }): AppThunk => async (dispatch) => {
//   try {
//     await api.delete(`/api/ciddiagnostics/${data.idMedicalRecord}/${data.idDiagnostic}`);
//     toast.success("Diagnóstico removido", toastOptions);
//     dispatch(fetchDiagnostics({ idMedicalRecord: data.idMedicalRecord }));
//   } catch (error: any) {
//     if (error.response) {
//       toast.error(error.response.data?.error?.message, toastOptions);
//     } else {
//       console.log(error.message);
//     }
//   }
// };

export const { setReportText, setExamsResultsText } = medicalFormSlice.actions;

export default medicalFormSlice.reducer;
