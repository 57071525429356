import React from "react";

import * as S from "./styles";
import { Typography } from "@material-ui/core";

interface IProps {
  title: string;
  wrap?: boolean;
}

const TitleBar: React.FC<IProps> = ({ children, title, wrap }) => {
  return (
    <S.RootContainer wrap={wrap}>
      <Typography className="title" variant="h5" style={{ textTransform: "uppercase" }}>
        {title}
      </Typography>

      <div className="children">{children}</div>
    </S.RootContainer>
  );
};

export default TitleBar;
