import { ISelectOption } from "pages/SchedulePage";

export interface ISelectLab {
  nome: string;
  idlaboratorio: string;
  contato: string;
  logradouro: string;
  numero: string;
  complemento: string;
  bairro: string;
  cidade: string;
  estado: string;
}


export const autocompleteDefaultProps = {
  getOptionLabel: (option: ISelectOption) => option.label,
  getOptionSelected: (option: ISelectOption, value: ISelectOption) => {
    return value.value === option.value;
  },
};




export const autocompleteLabProps = {
  getOptionLabel: (option: ISelectLab) => option.nome,
  getOptionSelected: (option: ISelectLab, value: ISelectLab) => {
    return value.idlaboratorio === option.idlaboratorio;
  },
  getOptionPhone: (option: ISelectLab) => option.contato,
  getOptionAddress: (option: ISelectLab) => option.logradouro,
  getOptionNumber: (option: ISelectLab) => option.numero,
  getOptionComplement: (option: ISelectLab) => option.complemento,

}

