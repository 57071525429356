import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, AppDispatch } from "store/index";
import { toast } from "react-toastify";
import toastOptions from "utils/toastOptions";
import api from "utils/API";
import { fetchServiceOrderById } from "../ServiceOrderReducer";
import { IOriginChannel, IServiceChannel } from "models/ServiceChannel";

interface IInitialState {
  serviceChannels: null | IServiceChannel[];
  originChannels: null | IOriginChannel[];
  isCreatingServiceChannel: boolean;
  isFetchingServiceChannels: boolean;
  isDeletingServiceChannel: boolean;
  currentServiceChannel: IServiceChannel | null;
}

const initialState: IInitialState = {
  serviceChannels: null,
  originChannels: null,
  isCreatingServiceChannel: false,
  isFetchingServiceChannels: false,
  isDeletingServiceChannel: false,
  currentServiceChannel: null,
};

const serviceChannelsSlice = createSlice({
  name: "serviceChannelsSlice",
  initialState,
  reducers: {
    setIsCreatingServiceChannel: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isCreatingServiceChannel = payload;
    },
    setIsFetchingServiceChannels: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingServiceChannels = payload;
    },
    setIsDeletingServiceChannel: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isDeletingServiceChannel = payload;
    },
    setServiceChannels: (
      state,
      { payload }: PayloadAction<IServiceChannel[]>
    ) => {
      state.serviceChannels = payload;
    },
    setOriginChannels: (
      state,
      { payload }: PayloadAction<IOriginChannel[]>
    ) => {
      state.originChannels = payload;
    },
    setCurrentServiceChannel: (
      state,
      { payload }: PayloadAction<IServiceChannel>
    ) => {
      state.currentServiceChannel = payload;
    },
    clearCurrentServiceChannel: (state) => {
      state.currentServiceChannel = null;
    },
  },
});

// export const updateServiceOrderServiceItemDiscount = ({
//   data,
//   callback,
// }: {
//   data: IServiceOrderServiceItemDiscountAdditionUpdate;
//   callback?: Function;
// }): AppThunk => async (dispatch: AppDispatch) => {
//   const { setIsCreatingServiceChannel } = serviceChannelsSlice.actions;
//   try {
//     dispatch(setIsCreatingServiceChannel(true));
//     await api.put(`/api/discount/order-item/${data.id}`, data);
//     if (callback) {
//       callback();
//     }
//     toast.success("Desconto editado com sucesso", toastOptions);
//     dispatch(setIsCreatingServiceChannel(false));
//     dispatch(fetchServiceOrderById(data.idordemservico));
//   } catch (error) {
//     dispatch(setIsCreatingServiceChannel(false));
//     if (error.response) {
//       toast.error(error.response.data?.error?.message, toastOptions);
//     } else {
//       console.log(error.message);
//     }
//   }
// };

// export const createServiceOrderServiceItemDiscount = ({
//   data,
//   callback,
// }: {
//   data: IServiceOrderServiceItemDiscountAdditionForm;
//   callback?: Function;
// }): AppThunk => async (dispatch: AppDispatch) => {
//   const { setIsCreatingServiceChannel } = serviceChannelsSlice.actions;
//   try {
//     dispatch(setIsCreatingServiceChannel(true));
//     await api.post(`/api/discount/order-item`, data);
//     if (callback) {
//       callback();
//     }
//     toast.success("Desconto aplicado com sucesso", toastOptions);
//     dispatch(setIsCreatingServiceChannel(false));
//     dispatch(fetchServiceOrderById(data.idordemservico));
//   } catch (error) {
//     dispatch(setIsCreatingServiceChannel(false));
//     if (error.response) {
//       toast.error(error.response.data?.error?.message, toastOptions);
//     } else {
//       console.log(error.message);
//     }
//   }
// };

export const fetchServiceChannels = ({
  page = 1,
  limit = 6,
}: {
  page?: number;
  limit?: number;
}): AppThunk => async (dispatch: AppDispatch) => {
  const {
    setIsFetchingServiceChannels,
    setServiceChannels,
  } = serviceChannelsSlice.actions;
  try {
    dispatch(setIsFetchingServiceChannels(true));
    const response = await api.get(
      `/api/service-channels?page=${page}&limit=${limit}`
    );
    dispatch(setServiceChannels(response.data.data.serviceChannels));
    dispatch(setIsFetchingServiceChannels(false));
  } catch (error) {
    dispatch(setIsFetchingServiceChannels(false));

    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);
    } else {
      console.log(error.message);
    }
  }
};

export const fetchOriginChannels = ({
  page = 1,
  limit = 6,
}: {
  page?: number;
  limit?: number;
}): AppThunk => async (dispatch: AppDispatch) => {
  const {
    setOriginChannels,
  } = serviceChannelsSlice.actions;
  try {
    const response = await api.get(
      `/api/origin-channels?page=${page}&limit=${limit}`
    );
    dispatch(setOriginChannels(response.data.data.originChannels));
  } catch (error) {

    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);
    } else {
      console.log(error.message);
    }
  }
};

export const deleteDiscount = ({
  discountId,
  callback,
  idordemservico,
}: {
  discountId: string;
  idordemservico: string;
  callback?: Function;
}): AppThunk => async (dispatch: AppDispatch) => {
  const { setIsDeletingServiceChannel } = serviceChannelsSlice.actions;
  try {
    dispatch(setIsDeletingServiceChannel(true));
    await api.delete(`/api/discount/order-item/${discountId}`);
    if (callback) {
      callback();
    }
    dispatch(fetchServiceOrderById(idordemservico));
    toast.success("Desconto excluido com sucesso", toastOptions);
    dispatch(setIsDeletingServiceChannel(false));
  } catch (error) {
    dispatch(setIsDeletingServiceChannel(false));
    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);
    } else {
      console.log(error.message);
    }
  }
};

export const { clearCurrentServiceChannel } = serviceChannelsSlice.actions;

export default serviceChannelsSlice.reducer;
