import * as Yup from "yup";
import { IPatientForm } from "models/Patients";
import dayjs from "dayjs";

export const validationSchema = Yup.object().shape({
  nomepaciente: Yup.string().required("Campo obrigatório"),
  sexo: Yup.string().required("Campo obrigatório"),
  // cpf: Yup.string().required("Campo obrigatório"),
  cpf: Yup.string()
    .matches(/^([0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}|[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2})$/),
  telefonecelular: Yup.string()
    .matches(/\(\d{2}\)\s\d{4,5}-\d{4}/, "Inválido"),

  telefoneresidencial: Yup.string()
    .matches(/\(\d{2}\)\s\d{4,5}-\d{4}/, "Inválido"),

  telefonecomercial: Yup.string().matches(
    /\(\d{2}\)\s\d{4,5}-\d{4}/,
    "Inválido"
  ),
  descricaopacientefonte: Yup.string(),
});

export const initialState: IPatientForm = {
  nomepaciente: "",
  sexo: "",
  datanascimento: dayjs(new Date()).format("YYYY-MM-DD"),
  cpf: "",
  rg: "",
  siglaestado: "",
  nomecidade: "",
  logradouro: "",
  numero: "",
  complemento: "",
  bairro: "",
  idcanalorigem: "",
  idcanalatendimento: "",
  cep: "",
  email: "",
  telefonecelular: "",
  telefoneresidencial: "",
  telefonecomercial: "",
  descricaopacientefonte: "",
  observacao: "",
  planopaciente: []
};

export const patientSexOptions: { label: string; value: string }[] = [
  { label: "Feminino", value: "Feminino" },
  { label: "Masculino", value: "Masculino" },
];
