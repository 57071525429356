import { IFilter } from "models/shared/index";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "store/index";
import { toast } from "react-toastify";
import toastOptions from "utils/toastOptions";
import api from "utils/API";
import { queryStringFromFilterArray } from "utils/network";
import { Summary } from "pages/Reports/SchedulesOfDay/Summary/Summary.types";
import { IHealthPlanProduction } from "models/ReportsHealthPlanProduction";

export interface IReceivedPayment {
  nomepaciente: string;
}

export interface IStatementTable {
  creationDate: string;
  statusText: string;
  patient: string;
  professional: string;
  category: string;
  service: string;
  value: string;
  scheduleDate: string;
}

interface IInitialState {
  isFetchingHealthPlanProduction: boolean;
  filterArray: IFilter[];
  total: number;
  healthPlanProduction: IHealthPlanProduction[] | null;
}

const initialState: IInitialState = {
  isFetchingHealthPlanProduction: false,
  filterArray: [
    { key: "start_date", value: null },
    { key: "end_date", value: null },
  ],
  healthPlanProduction: null,
  total: 0,
};

const ReportsHealthPlanProductionReducer = createSlice({
  name: "ReportsHealthPlanProductionReducer",
  initialState,
  reducers: {
    setIsFetchingHealthPlanProduction: (state, { payload }: PayloadAction<boolean>) => {
        state.isFetchingHealthPlanProduction = payload;
    },
    setHealthPlanProduction: (state, { payload }: PayloadAction<IHealthPlanProduction[]>) => {
        state.healthPlanProduction = payload;
    },
    clearFilter:(state, { payload }:PayloadAction<IFilter[]>) =>{
        state.filterArray = payload
    },
    updateFilter: (state, { payload }: PayloadAction<IFilter>) => {
      const index = state.filterArray.findIndex((item) => item.key === payload.key);
      if (index === -1) {
        state.filterArray.push({ key: payload.key, value: payload.value });
      } else {
        state.filterArray[index].value = payload.value;
      }
    },
  },
});

export const fetchReportsHealthPlanProduction = (): AppThunk => async (dispatch, getState) => {
    const { setIsFetchingHealthPlanProduction, setHealthPlanProduction } = ReportsHealthPlanProductionReducer.actions;
    dispatch(setIsFetchingHealthPlanProduction(true));
    const state = getState();
    const { filterArray } = state.reportsHealthPlanProduction;
    const queryParameters = queryStringFromFilterArray(filterArray);
  
    try {
      const response = await api.get(`/api/reports/health-plan-production${queryParameters}`);
      dispatch(setHealthPlanProduction(response.data.data.transactions));
      dispatch(setIsFetchingHealthPlanProduction(false));
    } catch (error: any) {
      dispatch(setIsFetchingHealthPlanProduction(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const {
    setIsFetchingHealthPlanProduction,
    setHealthPlanProduction,
    clearFilter,
    updateFilter
} = ReportsHealthPlanProductionReducer.actions;

export default ReportsHealthPlanProductionReducer.reducer;
