import { CardContent, Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import React, { memo, useCallback, useEffect, useState } from "react";
import * as S from "./styles";

import {
  Especialidade,
  IDisponibilityMapProfessional,
} from "models/DisponibilityMap";
import { useDispatch, useSelector } from "react-redux";
import {
  setDataModalHover,
  setModalHover,
  setPermissionOpenModalMap,
  startUpdateDisponibilityMapFetchInterval,
} from "store/reducers/DisponibilityMapReducer";
import {
  setEffectRequestMap,
  setIsDisponibilityMapDialogOpen,
  setIsPatientTimelineDialogOpen,
  setModalHoverScheduleProfessinal,
  setQuoteDialogOpen,
  setSchedulePageClinics,
  setSchedulePageCurrentDate,
  setSchedulePagePatient,
  setSchedulePageProfessionals,
  setSchedulePageServiceCategories,
  setSchedulepageserviceItems,
  setSelectedScheduleId,
  updateMultiSchedulePageFilterArray,
} from "store/reducers/SchedulePageReducer";
import { ISelectOption } from "pages/SchedulePage";
import { v4 as uuidv4 } from "uuid";
import { rootState } from "store";
import ModalHoverCard from "./ModalHoverCard";
import { Info } from "@material-ui/icons";

interface IProps {
  data: IDisponibilityMapProfessional;
  onSelectItem: Function;
  date: Date;
  dateIndex: number;
}

export interface ISpecialityDictionary {
  name: string;
  itemServices: string[];
  fullName: string;
}

const DisponibilityListItem = ({
  date,
  data,
  onSelectItem,
  dateIndex,
}: IProps) => {
  const dispatch = useDispatch();
  const {
    currentServiceCategories,
    currentServiceItems,
    modalHover,
    permissionOpenModalMap,
  } = useSelector((state: rootState) => state.disponibilityMap);
  const { isDisponibilityMapDialogOpen } = useSelector(
    (state: rootState) => state.schedulePage
  );
  const [timer, setTimer] = useState<any>(null);
  const [openModal, setOpenModal] = React.useState(false);

  const getSpecialityAndItems = useCallback((itemservices: any[]) => {
    const specialities: ISpecialityDictionary[] = [];

    itemservices.forEach((itemservice) => {
      const exists = specialities.find(
        (sp) => sp.name === itemservice.categoriaservico.nome
      );
      if (!exists && itemservice.categoriaservico) {
        specialities.push({
          name: itemservice.categoriaservico.nome,
          fullName: itemservice.categoriaservico.nome,
          itemServices: [],
        });
      }
    });

    //Adiciona os itens de serviço
    specialities.forEach((sp) => {
      itemservices.forEach((itemServico: any) => {
        if (sp.name === itemServico.categoriaservico.nome) {
          const exists = sp.itemServices.find((i) => i === itemServico.nome);
          if (!exists) sp.itemServices.push(itemServico.nome);
        }
      });
    });

    //Concatena itens de serviço:
    var newSpecialities: string[] = [];
    specialities.forEach((sp) => {
      let spName = sp.name + ":";
      sp.itemServices.forEach((item) => {
        const splittedSpName = spName.split(",");
        if (splittedSpName.length === 0) {
          spName += item + ",";
        }
        if (
          splittedSpName.length >= 1 &&
          item !== sp.itemServices[sp.itemServices.length - 1]
        ) {
          spName += " " + item + ",";
        }

        if (item === sp.itemServices[sp.itemServices.length - 1]) {
          spName += " " + item + ".";
        }
      });
      newSpecialities.push(spName);
    });

    return newSpecialities.map((especialidades, index) => (
      <Grid key={index} item xs={12}>
        <Typography style={{ fontSize: 14 }} variant="body1">
          - {especialidades}
        </Typography>
      </Grid>
    ));
  }, []);

  function getSpecialityLabels(specialities: Especialidade[]) {
    const labels: string[] = [];
    specialities.forEach((sp) => {
      if (sp && !labels.includes(sp.nome.toUpperCase())) {
        labels.push(sp.nome.toUpperCase());
      }
    });
    return labels.toString().replaceAll(",", " - ");
  }

  function getSpecialitySelectItems(specialities: Especialidade[]) {
    if (currentServiceCategories && currentServiceCategories.length > 0) {
      return currentServiceCategories;
    }

    const items: ISelectOption[] = [];
    specialities.forEach((sp) => {
      const has = items.some((item) => item.value === sp.idcategoriaservico);
      if (!has && sp) {
        items.push({ label: sp.nome.toUpperCase(), value: sp.idcategoriaservico });
      }
    });
    return items;
  }

  function getServiceItems(itemservices: any[]) {
    if (currentServiceItems && currentServiceItems.length > 0) {
      return currentServiceItems;
    }

    return [];
  }

  function getCardBgColor({
    availability,
    patientsLimit,
  }: {
    availability: number;
    patientsLimit: number;
  }): string {
    const availabilityPercentage = (availability / patientsLimit) * 100;

    if (availabilityPercentage < 50) {
      return "#FF9E99";
    } else if (availabilityPercentage < 100) {
      return "#f4f496";
    } else {
      return "#BDEDB6";
    }
  }

  function handleSelectItem() {
    const professionalSpecialties: string[] = [];
    
    data.especialidades.forEach((specialty) => {
      const hasSpecialty = professionalSpecialties.includes(specialty.idcategoriaservico);

      if (!hasSpecialty) {
        professionalSpecialties.push(specialty.idcategoriaservico);
      }
    });

    dispatch(
      updateMultiSchedulePageFilterArray([
        {
          key: "idprofissional",
          value: data.idProfessional,
        },
        {
          key: "idclinica",
          value: data.idclinica,
        },
        {
          key: "idcategoriaservico",
          value:
            currentServiceCategories && currentServiceCategories.length > 0
              ? currentServiceCategories.map((e) => e.value)
              : professionalSpecialties
        },
        {
          key: "iditemservico",
          value:
            currentServiceItems && currentServiceItems.length > 0
              ? currentServiceItems.map((e) => e.value)
              : null, //data.itensservico.map(itemservico => itemservico.iditemservico)),
        },
      ])
    );
    dispatch(
      setSchedulePageClinics([
        {
          label: data.clinica,
          value: data.idclinica,
        },
      ])
    );
    dispatch(setSchedulePagePatient(""));
    dispatch(
      setSchedulePageServiceCategories(
        getSpecialitySelectItems(data.especialidades)
      )
    );
    dispatch(setSchedulepageserviceItems(getServiceItems(data.itensservico)));
    dispatch(
      setSchedulePageProfessionals([
        { value: data.idProfessional, label: data.nome },
      ])
    );
    dispatch(setSchedulePageCurrentDate(date));
    dispatch(setQuoteDialogOpen(false));
    dispatch(setIsPatientTimelineDialogOpen(false));
    dispatch(setSelectedScheduleId(data.idschedule));
    dispatch(setEffectRequestMap());
    dispatch(setPermissionOpenModalMap(false));
    timeoutPermissionOpenModal();
    closeModal();
    onSelectItem();
  }

  function timeoutPermissionOpenModal() {
    setTimeout(() => {
      dispatch(setPermissionOpenModalMap(true));
    }, 10000);
  }

  const handleClickOpen = () => {
    dispatch(setDataModalHover(data));
    dispatch(setModalHover(true));
  };

  useEffect(() => {
    
    dispatch(setModalHoverScheduleProfessinal(false));
  }, [isDisponibilityMapDialogOpen]);

  const closeModal = () => {
    dispatch(setModalHoverScheduleProfessinal(false));
    dispatch(setIsDisponibilityMapDialogOpen(false));
  };

  return (
    <>
      <S.ColoredCard
        onClick={() => handleSelectItem()}
        unavailable={(data.livre / data.limitepacientes) * 100 < 50}
        backgroundcolorprop={
          getCardBgColor({
            availability: data.livre,
            patientsLimit: data.limitepacientes,
          }) + " !important"
        }
        variant="outlined"
        onMouseEnter={() => {
          //Refresh no timer do interval
          dispatch(startUpdateDisponibilityMapFetchInterval());
        }}
      >
            <Grid style={{ position: "absolute", right: 5, top : 5, padding: 0 , margin: 0, display: "flex", alignItems: "center"}}>
              <Tooltip title="informações adicionais" placement="top">
                <IconButton style={{padding: 0 , margin: 0}} onClick={(e) =>{
                  e.stopPropagation();
                  handleClickOpen()
                  }} >
                    <Info style={{ fontSize: 14,}}></Info>
                </IconButton>
              </Tooltip>
            </Grid>
        <div>
          {/* <Tooltip
        enterDelay={1000}
        enterNextDelay={1000}
        placement="top"
        title={
          <>
            <Grid
              spacing={1}
              justify="center"
              style={{
                backgroundColor: "#F6F600",
                color: "#000",
              }}
              container
            >
              <Grid item style={{ fontSize: 14 }} xs={12}>
                <Typography variant="body2">Especialidades</Typography>
                <Grid container spacing={1}>
                  {getSpecialityAndItems(data.itensservico)}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid key={uuidv4()} item xs={12}>
                    <Typography style={{ fontSize: 14 }} variant="body1">
                      Atende homens: {data.atendehomens ? "Sim" : "Não"}
                    </Typography>
                    <Typography style={{ fontSize: 14 }} variant="body1">
                      Atende Mulheres: {data.atendemulheres ? "Sim" : "Não"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid key={uuidv4()} item xs={12}>
                    <Typography style={{ fontSize: 14 }} variant="body1">
                      Idade Mínima: {data.idademinima ? data.idademinima : "N/D"}
                    </Typography>
                    <Typography style={{ fontSize: 14 }} variant="body1">
                      Idade Máxima: {data.idademaxima ? data.idademaxima : "N/D"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography style={{ fontSize: 14 }} variant="body2">
                  Observações
                </Typography>
                <Grid container spacing={1}>
                  <Grid key={uuidv4()} item xs={12}>
                    <Typography style={{ fontSize: 14 }} variant="body1">
                      {data.observacoes}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        }
        >
        </Tooltip> */}
          <CardContent
          >
            <Typography variant="body1">
              <S.BoldText>Profissional:</S.BoldText> {data.nome}
            </Typography>
            <Typography variant="body1">
              <S.BoldText>Especialidade:</S.BoldText>{" "}
              {getSpecialityLabels(data.especialidades)}
            </Typography>
            <Typography variant="body1">
              <S.BoldText>Clínica:</S.BoldText> {data.clinica}
            </Typography>
            <Typography variant="body1">
              <S.BoldText>Horário:</S.BoldText> {data.horario.map((i) => i)}
            </Typography>
            <Typography variant="body1">
              <S.BoldText>
                {data.livre === 0
                  ? "Agenda cheia"
                  : `Pode atender mais ${data.livre} ${
                      data.livre === 1 ? "paciente" : "pacientes"
                    }`}
              </S.BoldText>
            </Typography>
            <Typography variant="body1">
              <S.BoldText>
                {`Retornos restantes: ${
                  data.limiteretornos === 999
                    ? "Sem retornos"
                    : data.limiteretornos - data.retornos < 0
                    ? 0
                    : data.limiteretornos - data.retornos
                }`}
              </S.BoldText>
            </Typography>
          </CardContent>
        </div>
      </S.ColoredCard>
    </>
  );
};

export default memo(DisponibilityListItem);
