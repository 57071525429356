export const queryStringFromFilterArray = (filterArr: any) => {
  let query = "";
  filterArr.forEach((filter: any) => {
    if (filter?.value !== null) {
      if (Array.isArray(filter?.value)) {
        if (filter?.value?.length === 0) {
          query = query + "";
        } else {
          if (query.length === 0) {
            query = query + `?${filter.key}=${filter?.value.join()}`;
          } else {
            query = query + `&${filter.key}=${filter?.value.join()}`;
          }
        }
      } else {
        if (filter?.value?.length === 0) {
          query = query + "";
        } else {
          if (query.length === 0) {
            query = query + `?${filter.key}=${filter?.value}`;
          } else {
            query = query + `&${filter.key}=${filter?.value}`;
          }
        }
      }
    }
  });

  return query;
};
