import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, AppDispatch } from "store/index";
import { toast } from "react-toastify";
import toastOptions from "utils/toastOptions";
import api from "utils/API";
import { IBankAccount, IBankAccountForm } from "models/BankAccount";
import { history } from "utils/history";
import { IFilter } from "models/shared";
import { queryStringFromFilterArray } from "utils/network";
import axios from "axios";

interface IInitialState {
  isFetchingBankAccounts: boolean;
  isCreatingOrUpdatingBankAccount: boolean;
  isDeletingBankAccount: boolean;
  bankAccounts: null | IBankAccount[];
  page: number;
  total: number;
  currentBankAccount: null | IBankAccount;
  bankAccountsFilterArray: IFilter[];
  bankList: IBankList[]
}
interface IBankList {
  ispb: string,
  name: string,
  code: number,
  fullName: string
}
const initialState: IInitialState = {
  isFetchingBankAccounts: false,
  isCreatingOrUpdatingBankAccount: false,
  isDeletingBankAccount: false,
  bankAccounts: null,
  page: 0,
  total: 0,
  currentBankAccount: null,
  bankList: [],
  bankAccountsFilterArray: [
    { key: "ativo", value: null },
    { key: "nome", value: null },
    { key: "valorlimite", value: null },
    { key: "banco", value: null },
    { key: "agencia", value: null },
    { key: "conta", value: null },
    { key: "idclinica", value: null },
  ],
};

const bankAccountsSlice = createSlice({
  name: "bankAccountsSlice",
  initialState,
  reducers: {
    setBankList: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      state.bankList = payload;
    },
    setIsCreatingOrUpdatingBankAccount: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isCreatingOrUpdatingBankAccount = payload;
    },
    setIsFetchingBankAccounts: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingBankAccounts = payload;
    },
    setIsDeletingBankAccount: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeletingBankAccount = payload;
    },
    setBankAccounts: (
      state,
      {
        payload: { bank_accounts, page, total },
      }: PayloadAction<{
        bank_accounts: IBankAccount[] | null;
        page: number;
        total: number;
      }>
    ) => {
      state.page = page;
      state.bankAccounts = bank_accounts;
      state.total = total;
    },
    setCurrentBankAccount: (
      state,
      { payload }: PayloadAction<IBankAccount>
    ) => {
      state.currentBankAccount = payload;
    },
    clearCurrentBankAccount: (state) => {
      state.currentBankAccount = null;
    },
    updateBankAccountsFilter: (state, { payload }: PayloadAction<IFilter>) => {
      const index = state.bankAccountsFilterArray.findIndex(
        (item) => item.key === payload.key
      );
      state.bankAccountsFilterArray[index].value = payload.value;
    },
  },
});

export const fetchBankAccounts = ({
  page = 1,
  limit = 6,
}: {
  page?: number;
  limit?: number;
}): AppThunk => async (dispatch: AppDispatch, getState) => {
  const {
    setIsFetchingBankAccounts,
    setBankAccounts,
  } = bankAccountsSlice.actions;
  try {
    const state = getState();
    const { bankAccountsFilterArray } = state.bankAccounts;
    const queryParameters = queryStringFromFilterArray(bankAccountsFilterArray);
    dispatch(setIsFetchingBankAccounts(true));
    const pageAndLimit =
      queryParameters.length === 0
        ? `?page=${page}&limit=${limit}`
        : `&page=${page}&limit=${limit}`;
    const response = await api.get(
      `/api/bankaccounts${queryParameters}${pageAndLimit}`
    );
    dispatch(setBankAccounts(response.data.data));
    dispatch(setIsFetchingBankAccounts(false));
  } catch (error) {
    dispatch(setIsFetchingBankAccounts(false));
    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);
    } else {
      console.log(error.message);
    }
  }
};

export const fetchBankAccountById = (bankAccountId: string): AppThunk => async (
  dispatch: AppDispatch
) => {
  const {
    setIsFetchingBankAccounts,
    setCurrentBankAccount,
  } = bankAccountsSlice.actions;
  try {
    dispatch(setIsFetchingBankAccounts(true));
    const response = await api.get(`/api/bankaccounts/${bankAccountId}`);
    dispatch(setCurrentBankAccount(response.data.data));
    dispatch(setIsFetchingBankAccounts(false));
  } catch (error) {
    dispatch(setIsFetchingBankAccounts(false));
    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);
    } else {
      console.log(error.message);
    }
  }
};

export const createBankAccount = (
  bankAccount: IBankAccountForm
): AppThunk => async (dispatch: AppDispatch) => {
  const { setIsCreatingOrUpdatingBankAccount } = bankAccountsSlice.actions;
  dispatch(setIsCreatingOrUpdatingBankAccount(true));
  try {
    await api.post(`/api/bankaccounts`, bankAccount);
    dispatch(setIsCreatingOrUpdatingBankAccount(false));
    dispatch(fetchBankAccounts({ page: 1, limit: 6 }));
    history.replace("/bank-accounts");
    toast.success("Conta bancária cadastrada", toastOptions);
  } catch (error) {
    dispatch(setIsCreatingOrUpdatingBankAccount(false));
    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);
    } else {
      console.log(error.message);
    }
  }
};
export const fetchBankList = (): AppThunk => async (dispatch) => {
  const { setBankList } = bankAccountsSlice.actions;
  try {
    const response = await axios.get(`https://brasilapi.com.br/api/banks/v1`,{
      headers: {
          'Content-Type': 'application/json'
      }
  });
    dispatch(setBankList(response.data))
  } catch (error:any) {
    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);
    } else {
      console.log(error.message);
    }
  }
};

export const updateBankAccount = ({
  bankAccountData,
  bankAccountId,
}: {
  bankAccountData: IBankAccountForm;
  bankAccountId: string;
}): AppThunk => async (dispatch: AppDispatch) => {
  const { setIsCreatingOrUpdatingBankAccount } = bankAccountsSlice.actions;
  dispatch(setIsCreatingOrUpdatingBankAccount(true));
  try {
    await api.put(`/api/bankaccounts/${bankAccountId}`, bankAccountData);
    dispatch(setIsCreatingOrUpdatingBankAccount(false));
    dispatch(fetchBankAccounts({ page: 1, limit: 6 }));
    history.replace("/bank-accounts");
    toast.success("Conta bancária atualizada", toastOptions);
  } catch (error) {
    dispatch(setIsCreatingOrUpdatingBankAccount(false));
    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);
    } else {
      console.log(error.message);
    }
  }
};

export const updateBankAccountStatus = ({
  newBankAccountStatus,
  bankAccountId,
}: {
  newBankAccountStatus: boolean;
  bankAccountId: string;
}): AppThunk => async (dispatch: AppDispatch) => {
  const { setIsCreatingOrUpdatingBankAccount } = bankAccountsSlice.actions;
  dispatch(setIsCreatingOrUpdatingBankAccount(true));
  try {
    await api.put(`/api/bankaccounts/${bankAccountId}`, {
      ativo: newBankAccountStatus,
    });
    dispatch(setIsCreatingOrUpdatingBankAccount(false));
    dispatch(fetchBankAccounts({}));
    toast.success(newBankAccountStatus ? "Conta ativada" : "Conta desativada", toastOptions);
  } catch (error) {
    dispatch(setIsCreatingOrUpdatingBankAccount(false));
    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);
    } else {
      console.log(error.message);
    }
  }
};

export const deleteBankAccount = (bankAccountId: string): AppThunk => async (
  dispatch: AppDispatch
) => {
  const { setIsDeletingBankAccount } = bankAccountsSlice.actions;
  dispatch(setIsDeletingBankAccount(true));
  try {
    await api.delete(`/api/bankaccounts/${bankAccountId}`);
    dispatch(setIsDeletingBankAccount(false));
    dispatch(fetchBankAccounts({}));
    toast.success("Conta excluida", toastOptions);
  } catch (error) {
    dispatch(setIsDeletingBankAccount(false));
    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);
    } else {
      console.log(error.message);
    }
  }
};

export const { updateBankAccountsFilter,setBankAccounts,setBankList  } = bankAccountsSlice.actions;

export default bankAccountsSlice.reducer;
