import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { useDispatch, useSelector } from "react-redux";
import { rootState } from "store";

import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Chip from "@material-ui/core/Chip";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { Close, PlayArrow, SettingsBackupRestore } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

// import {
//   Edit as EditIcon,
//   Queue,
//   LocalAtm,
//   EventNote,
//   PowerSettingsNewRounded as PowerSettingsNew,
// } from "@material-ui/icons";

import GenericTableHead from "components/table/GenericTableHead";

import PreAppointmentModal from "pages/MedicalRecord/components/PatientMedicalRecord/pages/AnamneseOftalmo/PreAppointmentModal";
import {
  MedicalRecord,
  changeStatusBatchMedicalRecords,
  changeStatusMedicalRecord,
  clearSelectedMedicalRecord,
  fetchMedicalRecordsByPatientId,
  setMedicalRecordCallerOrigin,
  setcurrentPreAppointment,
} from "store/reducers/MedicalRecordReducer";
import {
  fetchMedicalRecordsPreApps,
  fetchReleaseMedicalRecords,
  setPage,
} from "store/reducers/ReleaseMedicalRecordsReducer";
import { ReleaseMedicalRecords } from "store/reducers/ReleaseMedicalRecordsReducer/ReleaseMedicalRecords.types";
import {
  clearCurrentPatient,
  fetchPatientById,
} from "store/reducers/PatientsReducer";
import { AppBar, Toolbar, Dialog } from "@material-ui/core";
import { SizedBox } from "components/layout/utils";
import BasicInfo from "pages/MedicalRecord/components/PatientMedicalRecord/components/BasicInfo";
import PreAppointmentMedicalRecord from "../PreAppointmentMedicalRecord";

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);

const tableCells = [
  { id: uuidv4(), alignment: "left", label: "Checkin" },
  { id: uuidv4(), alignment: "left", label: "Agendamento" },
  { id: uuidv4(), alignment: "center", label: "Paciente" },
  { id: uuidv4(), alignment: "center", label: "Prioridade" },
  { id: uuidv4(), alignment: "center", label: "Pré consulta" },
  { id: uuidv4(), alignment: "center", label: "Item de serviço" },
  { id: uuidv4(), alignment: "center", label: "Tipo de Serviço" },
  { id: uuidv4(), alignment: "center", label: "Ações" },
  // { id: uuidv4(), alignment: "center", label: "Paciente" },
];

const dialogTableCells = [
  { id: uuidv4(), alignment: "left", label: "Item" },
  { id: uuidv4(), alignment: "left", label: "Categoria" },
];

export default function ReleaseMedicalRecordsTable() {
  const dispatch = useDispatch();
  const {
    releaseMedicalRecords: { releaseMedicalRecords, limit, page, total },
    medicalRecords: { medicalRecordCallerOrigin, selectedMedicalRecord },
    patients: { currentPatient },
  } = useSelector((state: rootState) => state);

  const [isItemsDialogOpen, setItemsDialogOpen] = useState<boolean>(false);
  const [isOpenPreAppointmentModal, setIsOpenPreAppointmentModal] =
    useState<boolean>(false);
  const [isOpenMedicalRecordBasicInfo, setIsOpenMedicalRecordBasicInfo] =
    useState<boolean>(false);
  const [medicalRecordId, setMedicalRecordId] = useState<string | undefined>(
    undefined
  );
  const [patientName, setPatient] = useState<string | undefined>(undefined);
  const [itemsToShow, setItemsToShow] = useState<any[]>([]);
  const [selectedCheckbox, setSelectedCheckbox] = useState<
    ReleaseMedicalRecords[]
  >([]);

  const history = useHistory();

  function getServiceCategory(releaseMedicalRecord: ReleaseMedicalRecords) {
    let serviceCategory = "";

    if (releaseMedicalRecord.orderitems.length > 0) {
      releaseMedicalRecord.orderitems.forEach((orderItem) => {
        serviceCategory = orderItem.itemservicos.categoriaservico.nome;
      });
    }

    return serviceCategory;
  }

  function getItemNames(row: any) {
    const items: any[] = [];

    row.orderitems.forEach((item: any) => {
      items.push(item.itemservicos);
    });

    setItemsToShow(items);
    setItemsDialogOpen(true);
  }

  function handleChangePage(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) {
    dispatch(setPage(page + 1));
  }

  function getPriority(item: ReleaseMedicalRecords) {
    if (item.consulta.prioritario) {
      return "Prioritário";
    }

    if (item.consulta.preferencial) {
      return "Preferencial";
    }

    return "Não é prioridade";
  }

  function selectAllCheckbox() {
    setSelectedCheckbox(releaseMedicalRecords);
  }

  function clearAllCheckbox() {
    setSelectedCheckbox([]);
  }

  function selectCheckbox(item: ReleaseMedicalRecords) {
    const alreadyAdded = selectedCheckbox.find(
      (checkbox) => checkbox.idconsulta === item.idconsulta
    );

    if (!alreadyAdded) {
      setSelectedCheckbox((prevState) => [...prevState, item]);
    }
  }

  function endService(item: ReleaseMedicalRecords) {
    dispatch(
      changeStatusMedicalRecord({
        idMedicalRecord: item.idMedicalRecord,
        status: "fechado",
        idprofissional: item.idprofissional,
        prioritario: item.consulta.prioritario,
        preferencial: item.consulta.preferencial,
        cb: () => {
          dispatch(fetchReleaseMedicalRecords({ page, limit }));
        },
      })
    );
  }

  function endAllSelectedServices() {
    const typedMedicalRecords: MedicalRecord[] = selectedCheckbox.map(
      (checkbox) => {
        return {
          idMedicalRecord: checkbox.idMedicalRecord,
          idprofissional: checkbox.idprofissional,
          status: "fechado",
          prioritario: checkbox.consulta.prioritario,
          preferencial: checkbox.consulta.preferencial,
        };
      }
    );

    dispatch(
      changeStatusBatchMedicalRecords({
        medicalRecords: typedMedicalRecords,
        cb: () => {
          dispatch(fetchReleaseMedicalRecords({ page, limit }));
        },
      })
    );
  }

  function goToPreAppointment({
    idmedicalrecord,
    patient,
    patientId,
  }: {
    idmedicalrecord: string;
    patient: string;
    patientId: string;
  }) {
    setMedicalRecordId(idmedicalrecord);
    dispatch(fetchMedicalRecordsByPatientId(patientId, idmedicalrecord));
    setIsOpenPreAppointmentModal(true);
    setPatient(patient);
  }

  const goToPatientMedicalRecord = (
    patientId: string,
    medicalRecordId: string
  ) => {
    setMedicalRecordId(medicalRecordId);
    dispatch(fetchMedicalRecordsByPatientId(patientId, medicalRecordId));
    dispatch(fetchPatientById({ patientId }));
    setIsOpenMedicalRecordBasicInfo(true);
    // dispatch(setMedicalRecordCallerOrigin("PRE_APPOINTMENT"));
    // history.push(`/patients/medical-records/${patientId}/${medicalRecordId}`);
  };

  return (
    <Card>
      <TableContainer>
        <Table size="small">
          <colgroup>
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
          </colgroup>
          <GenericTableHead headCells={tableCells} />
          <TableBody>
            {releaseMedicalRecords.map((patient) => (
              <TableRow key={patient.idconsulta}>
                {/* DATA */}
                <TableCell>
                  {dayjs(patient.datacriacao).format("DD/MM/YYYY HH:mm")}
                </TableCell>
                {/* ATENDIMENTO */}
                <TableCell>
                  {dayjs(patient.consulta.data).format("DD/MM/YYYY HH:mm")}
                </TableCell>
                {/* PACIENTE */}
                <TableCell
                  style={{ textTransform: "uppercase" }}
                  align="center"
                >
                  {patient.paciente.nomepaciente}
                </TableCell>
                {/* PRIORIDADE */}
                <TableCell align="center">
                  <Chip
                    label={getPriority(patient)}
                    color={
                      getPriority(patient) === "Prioritário"
                        ? "secondary"
                        : getPriority(patient) === "Preferencial"
                        ? "primary"
                        : "default"
                    }
                  />
                </TableCell>
                {/* STATUS */}
                <TableCell align="center">
                  {patient.preconsulta === "realizado" ? (
                    <Chip label="REALIZADO" color="primary" />
                  ) : (
                    <Chip label="PENDENTE" color="secondary" />
                  )}
                  {/* {!patient.orderitems.every(
                    (item) =>
                      !item.itemservicos.categoriaservico.nome
                        .toLowerCase()
                        .includes("oftalmo")
                  ) ? (
                    patient.preconsulta === "realizado" ? (
                      <Chip label="REALIZADO" color="primary" />
                    ) : (
                      <Chip label="PENDENTE" color="secondary" />
                    )
                  ) : (
                    <Chip label="N/A" color="default" />
                  )} */}
                </TableCell>
                {/* ITEM DE SERVIÇO */}
                <TableCell align="center">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      getItemNames(patient);
                    }}
                  >
                    Ver itens
                  </Button>
                </TableCell>
                {/* TIPO DE SERVIÇO */}
                <TableCell align="center">
                  {getServiceCategory(patient)}
                </TableCell>
                <TableCell align="center">
                  <Tooltip
                    title={
                      !patient.orderitems.every(
                        (item) =>
                          !item.itemservicos.categoriaservico.nome
                            .toLowerCase()
                            .includes("oftalmo")
                      )
                        ? "Preencher Pré-consulta"
                        : "Ir para prontuário"
                    }
                    placement="top"
                    onClick={
                      !patient.orderitems.every(
                        (item) =>
                          !item.itemservicos.categoriaservico.nome
                            .toLowerCase()
                            .includes("oftalmo")
                      )
                        ? () =>
                            goToPreAppointment({
                              idmedicalrecord: patient.idMedicalRecord,
                              patient: patient.paciente.nomepaciente,
                              patientId: patient.paciente.idpaciente,
                            })
                        : () =>
                            goToPatientMedicalRecord(
                              patient.paciente.idpaciente,
                              patient.idMedicalRecord
                            )
                    }
                    // onClick={() => {
                    //   goToPreAppointment({
                    //     idmedicalrecord: patient.idMedicalRecord,
                    //     patient: patient.paciente.nomepaciente,
                    //   });
                    // }}
                  >
                    <IconButton component="span">
                      <PlayArrow />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Grid container justify="flex-end">
          <Grid item>
            <TablePagination
              component="div"
              count={total}
              page={page - 1}
              rowsPerPageOptions={[]}
              onChangePage={(
                event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
                page: number
              ) => handleChangePage(event, page)}
              rowsPerPage={limit}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count}`
              }
            />
          </Grid>
        </Grid>
      </TableContainer>

      <Dialog
        open={isItemsDialogOpen}
        onClose={() => setItemsDialogOpen(false)}
      >
        <DialogTitle>
          <Typography variant="h6" style={{ textTransform: "uppercase" }}>
            Itens de serviço
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Table size="medium">
            <GenericTableHead headCells={dialogTableCells} />
            {itemsToShow.map((item) => (
              <TableBody>
                <TableRow key={String(item)}>
                  <TableCell style={{ textTransform: "uppercase" }}>
                    {item.nome}
                  </TableCell>
                  <TableCell style={{ textTransform: "uppercase" }}>
                    {item.categoriaservico.nome}
                  </TableCell>
                </TableRow>
              </TableBody>
            ))}
          </Table>
        </DialogContent>
      </Dialog>

      {isOpenMedicalRecordBasicInfo &&
        selectedMedicalRecord &&
        currentPatient &&
        medicalRecordId && (
          <PreAppointmentMedicalRecord
            isOpenMedicalRecordBasicInfo={isOpenMedicalRecordBasicInfo}
            onClose={() => {
              dispatch(clearCurrentPatient());
              dispatch(clearSelectedMedicalRecord());
              dispatch(setcurrentPreAppointment(null));
              dispatch(fetchMedicalRecordsPreApps({ page, limit }));
              setIsOpenMedicalRecordBasicInfo(false);
              setMedicalRecordId(undefined);
              setPatient(undefined);
            }}
            patientName={currentPatient.nomepaciente}
            idMedicalRecord={medicalRecordId}
            patientId={currentPatient.idpaciente}
          />
        )}

      {medicalRecordId && isOpenPreAppointmentModal && (
        <PreAppointmentModal
          open={isOpenPreAppointmentModal}
          idMedicalRecord={medicalRecordId}
          onClose={() => {
            dispatch(fetchMedicalRecordsPreApps({ page, limit }));
            setIsOpenPreAppointmentModal(false);
            setMedicalRecordId(undefined);
            setPatient(undefined);
          }}
          patient={patientName}
        />
      )}
    </Card>
  );
}
