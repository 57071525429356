import { Dialog, DialogTitle, Grid, Typography } from "@material-ui/core";
import * as React from "react";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import { v4 as uuidv4 } from "uuid";
import { ISchedule, IScheduleByProfessional } from "models/Clinics/timetable";
import CloseIcon from "@material-ui/icons/Close";
import { IDisponibilityMapProfessional } from "models/DisponibilityMap";
import { ISpecialityDictionary } from ".";
export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  name: string;
  speciality: string[];
  clinic: string;
  appointment: IDisponibilityMapProfessional;
  getProfessionalHoursString: string;
}

export default function ModalHoverCard(props: SimpleDialogProps) {
  const {
    onClose,
    open,
    name,
    speciality,
    getProfessionalHoursString,
    appointment,
    clinic,
  } = props;

  const handleClose = () => {
    onClose();
  };

  const getSpecialityAndItems = React.useCallback((itemservices: any[]) => {
    const specialities: ISpecialityDictionary[] = [];

    itemservices.forEach((itemservice) => {
      const exists = specialities.find(
        (sp) => sp.name === itemservice.categoriaservico.nome
      );
      if (!exists && itemservice.categoriaservico) {
        specialities.push({
          name: itemservice.categoriaservico.nome,
          fullName: itemservice.categoriaservico.nome,
          itemServices: [],
        });
      }
    });

    //Adiciona os itens de serviço
    specialities.forEach((sp) => {
      itemservices.forEach((itemServico: any) => {
        if (sp.name === itemServico.categoriaservico.nome) {
          const exists = sp.itemServices.find((i) => i === itemServico.nome);
          if (!exists) sp.itemServices.push(itemServico.nome);
        }
      });
    });

    //Concatena itens de serviço:
    var newSpecialities: string[] = [];
    specialities.forEach((sp) => {
      let spName = sp.name + ":";
      sp.itemServices.forEach((item) => {
        const splittedSpName = spName.split(",");
        if (splittedSpName.length === 0) {
          spName += item + ",";
        }
        if (
          splittedSpName.length >= 1 &&
          item !== sp.itemServices[sp.itemServices.length - 1]
        ) {
          spName += " " + item + ",";
        }

        if (item === sp.itemServices[sp.itemServices.length - 1]) {
          spName += " " + item + ".";
        }
      });
      newSpecialities.push(spName);
    });

    return newSpecialities.map((especialidades, index) => (
      <Grid key={index} item xs={12}>
        <Typography style={{ fontSize: 14 }} variant="body1">
          - {especialidades}
        </Typography>
      </Grid>
    ));
  }, []);
  
  return (
    <div>
      {appointment && (
        <Dialog onClose={handleClose} open={open}>
          <Grid
            style={{
              color: "#fff",
              backgroundColor: "#00a1a9",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              paddingRight: "2rem",
            }}
          >
            <DialogTitle>{name}</DialogTitle>
            
            <IconButton aria-label="delete" style={{ cursor: "pointer" }} onClick={() => handleClose()}>
              <CloseIcon style={{color: "#fff"}}/>
            </IconButton>
          </Grid>

          <DialogContent
            style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          >
            <Grid item style={{ fontSize: 14 }} xs={12}>
              <Typography variant="subtitle2">Especialidades</Typography>
              <Grid container spacing={1}>
                {getSpecialityAndItems(speciality)}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ fontSize: 14 }} variant="subtitle2">
                Clínica
              </Typography>
              <Grid container spacing={1}>
                <Grid key={uuidv4()} item xs={12}>
                  <Typography style={{ fontSize: 14 }} variant="body1">
                    {clinic}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ fontSize: 14 }} variant="subtitle2">
                Horários
              </Typography>
              <Grid container spacing={1}>
                <Typography style={{ fontSize: 14 }} variant="body1">
                {getProfessionalHoursString}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid key={uuidv4()} item xs={12}>
                  <Typography style={{ fontSize: 14 }} variant="subtitle2">
                    Atende homens: {appointment.atendehomens ? "Sim" : "Não"}
                  </Typography>
                  <Typography style={{ fontSize: 14 }} variant="subtitle2">
                    Atende Mulheres:{" "}
                    {appointment.atendemulheres ? "Sim" : "Não"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid key={uuidv4()} item xs={12}>
                  <Typography style={{ fontSize: 14 }} variant="subtitle2">
                    Idade Mínima:{" "}
                    {appointment.idademinima ? appointment.idademinima : "N/D"}
                  </Typography>
                  <Typography style={{ fontSize: 14 }} variant="subtitle2">
                    Idade Máxima:{" "}
                    {appointment.idademaxima ? appointment.idademaxima : "N/D"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {appointment.restricaoporservico && appointment.restricaoporservico.length > 0  && <Grid item xs={12}>
          <Typography style={{ fontSize: 14 }} variant="subtitle2">
            Idade por serviço
          </Typography>
          <Grid container spacing={1}>
            {appointment.restricaoporservico && appointment.restricaoporservico.length > 0  && appointment.restricaoporservico.map((service, index:number)=>{
                    return <>
                      <Grid key={index} item xs={12} style={{display:"flex", gap: 8,  margin: 0 , padding: "0 4px"}}>
                        <Typography style={{ fontSize: 13 }} variant="subtitle2">
                          - {service.itemservico.nome}:
                        </Typography>
                        <Typography style={{ fontSize: 13 }} variant="body1">
                          {service.idademinima} - {service.idademaxima}
                        </Typography>
                      </Grid>
                    </>
                 })
            }
          </Grid>
        </Grid>}
        {appointment.restricaoporconvenio && appointment.restricaoporconvenio.length > 0  && <Grid item xs={12}>
          <Typography style={{ fontSize: 14 }} variant="subtitle2">
            Limite de agendamentos por convênio
          </Typography>
          <Grid container spacing={1}>
            {appointment.restricaoporconvenio && appointment.restricaoporconvenio.length > 0 && appointment.restricaoporconvenio.map((health:any, index:number)=>{
                    return <>
                      <Grid key={index} item xs={12} style={{display:"flex", gap: 8,margin: 0 , padding: "0 4px"}}>
                        <Typography style={{ fontSize: 12 }} variant="subtitle2">
                          {health.convenio}:
                        </Typography>
                        <Typography style={{ fontSize: 13 }} variant="body1">
                          {health.limiteconvenios} 
                        </Typography>
                      </Grid>
                    </>
                 })
            }
          </Grid>
        </Grid>}

            <Grid item xs={12}>
              <Typography style={{ fontSize: 14 }} variant="subtitle2">
                Observações
              </Typography>
              <Grid container spacing={1}>
                <Grid key={uuidv4()} item xs={12}>
                  <Typography style={{ fontSize: 14 }} variant="body1">
                    {appointment?.observacoes ? appointment.observacoes : ""}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}
