import styled from "styled-components";

interface ILoaderWrapper {
  top?: number;
  left?: number;
}

export const LoaderWrapper = styled.div<ILoaderWrapper>`
  display: inline-block;
  width: 64px;
  height: 64px;
  border: 6px solid rgba(0, 161, 169, .5);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  position: fixed;
  top: calc(${({ top }) => top || 50}% - 32px);
  left: calc(${({ left }) => left || 50}% - 32px);

  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
`;

