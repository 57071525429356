import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AppThunk } from "store";
import api from "utils/API";
import toastOptions from "utils/toastOptions";
import { ExamKit, ExamKitItemModel, ExamKitsState } from "./ExamsKits.types";

const initialState: ExamKitsState = {
	user_id: "",
	examsKits: [],
	examKit: {
		name: "",
		idusuario: "",
		serviceItemKitItems: [],
	},
	examKitFilterId: "",
	isFetchingExamsKits: false,
	isFetchingExamsKitsItems: false,
	listPage: 0,
	listLimit: 6,
	listTotalSize: 0,
	isCreatingExamKit: false,
	isCreatingExamKitItem: false,
	exams_kit_items: [],
	idserviceitemkit: "",
};

const examsKitSlice = createSlice({
	name: "exams-kits",
	initialState,
	reducers: {
		setUserId: (state, action) => {
			state.user_id = action.payload;
		},
		setExamsKits: (state, action) => {
			state.examsKits = action.payload;
		},
		setExamKit: (state, action) => {
			state.examKit = action.payload;
		},
		setExamKitFilterId: (state, action) => {
			state.examKitFilterId = action.payload;
		},
		setIsFetchingExamsKits: (state, action) => {
			state.isFetchingExamsKits = action.payload;
		},
		setIsFetchingExamsKitsItems: (state, action) => {
			state.isFetchingExamsKitsItems = action.payload;
		},
		setListPage: (state, action) => {
			state.listPage = action.payload;
		},
		setListLimit: (state, action) => {
			state.listLimit = action.payload;
		},
		setListTotalSize: (state, action) => {
			state.listTotalSize = action.payload;
		},
		setIsCreatingExamKit: (state, action) => {
			state.isCreatingExamKit = action.payload;
		},
		setExamsKitItems: (state, action) => {
			state.exams_kit_items = action.payload;
		},
		setIdServiceItemKit: (state, action) => {
			state.idserviceitemkit = action.payload;
		},
		setIsCreatingExamKitItem: (state, action) => {
			state.isCreatingExamKitItem = action.payload;
		},
	},
});

export const fetchExamsKits =
	({
		user_id,
		page = 1,
		limit = 6,
	}: {
		user_id: string;
		page: number;
		limit: number;
	}): AppThunk =>
	async (dispatch, getState) => {
		const { setIsFetchingExamsKits, setExamsKits, setListTotalSize } =
			examsKitSlice.actions;
		dispatch(setIsFetchingExamsKits(true));

		try {
			const { data } = await api.get(
				`/api/serviceitemkits?user_id=${user_id}&page=${page}&limit=${limit}`
			);
			dispatch(setExamsKits(data.data.service_item_kits));
			dispatch(setListTotalSize(data.data.total));
		} catch (error: any) {
			console.log(error);

			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingExamsKits(false));
		}
	};

export const fetchExamKitById =
	(service_item_kit_id: string): AppThunk =>
	async (dispatch, getState) => {
		const { setExamKit, setIsFetchingExamsKits } = examsKitSlice.actions;
		dispatch(setIsFetchingExamsKits(true));
		try {
			const { data } = await api.get(
				`/api/serviceitemkits/${service_item_kit_id}`
			);
			dispatch(setExamKit(data.data));
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingExamsKits(false));
		}
	};

export const fetchExamsKitItems =
	(service_item_kit_id: string): AppThunk =>
	async (dispatch, getState) => {
		const { setExamsKitItems, setIsFetchingExamsKitsItems } =
			examsKitSlice.actions;
		dispatch(setIsFetchingExamsKitsItems(true));
		try {
			const { data } = await api.get(
				`/api/serviceitemkititem?service_item_kit_id=${service_item_kit_id}`
			);
			dispatch(setExamsKitItems(data.data.service_item_kit_items));
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingExamsKitsItems(false));
		}
	};

export const createExamKitItem =
	(exam_kit_item: ExamKitItemModel): AppThunk =>
	async (dispatch, getState) => {
		const { setIsCreatingExamKitItem } = examsKitSlice.actions;
		const { idserviceitemkit } = getState().examsKits;

		dispatch(setIsCreatingExamKitItem(true));
		try {
			await api.post(`/api/serviceitemkititem`, exam_kit_item);
			toast.success("Item vinculado ao kit com sucesso", {
				toastId: "create-exams-kit-item",
			});
			dispatch(fetchExamsKitItems(idserviceitemkit));
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsCreatingExamKitItem(false));
		}
	};

export const createExamKit =
	(examKit: ExamKit): AppThunk =>
	async (dispatch, getState) => {
		const { setIsCreatingExamKit, setIdServiceItemKit } = examsKitSlice.actions;
		dispatch(setIsCreatingExamKit(true));
		try {
			const { data } = await api.post("/api/serviceitemkits", examKit);
			toast.success("Kit criado com sucesso");
			dispatch(setIdServiceItemKit(data.data.idserviceitemkit));
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsCreatingExamKit(false));
		}
	};

export const editExamKit =
	({ exam_kit_id, body }: { exam_kit_id: string; body: ExamKit }): AppThunk =>
	async (dispatch, getState) => {
		const { setIsFetchingExamsKits } = examsKitSlice.actions;
		dispatch(setIsFetchingExamsKits(true));
		try {
			await api.put(`/api/serviceitemkits/${exam_kit_id}`, body);
			toast.success("Kit editado com sucesso");
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingExamsKits(false));
		}
	};

export const editExamKitItem =
	({
		exam_kit_item_id,
		body,
	}: {
		exam_kit_item_id: string;
		body: ExamKit;
	}): AppThunk =>
	async (dispatch, getState) => {
		const { setIsFetchingExamsKitsItems } = examsKitSlice.actions;
		const { idserviceitemkit } = getState().examsKits;
		dispatch(setIsFetchingExamsKitsItems(true));
		try {
			await api.put(`/api/serviceitemkititem/${exam_kit_item_id}`, body);
			toast.success("Item editado com sucesso", {
				toastId: "edit-exam-kit-item",
			});
			dispatch(fetchExamsKitItems(idserviceitemkit));
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingExamsKitsItems(false));
		}
	};

export const deleteExamKit =
	(examKitId: string): AppThunk =>
	async (dispatch, getState) => {
		const { user_id, listLimit, listPage } = getState().examsKits;
		const { setIsFetchingExamsKits } = examsKitSlice.actions;
		dispatch(setIsFetchingExamsKits(true));
		try {
			await api.delete(`/api/serviceitemkits/${examKitId}`);
			dispatch(fetchExamsKits({ user_id, page: listPage, limit: listLimit }));
			toast.success("Kit excluído com sucesso", {
				toastId: "delete-kit",
			});
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingExamsKits(false));
		}
	};

export const deleteExamKitItem =
	(examKitItemId: string): AppThunk =>
	async (dispatch, getState) => {
		const { setIsFetchingExamsKitsItems } = examsKitSlice.actions;
		const { idserviceitemkit } = getState().examsKits;
		dispatch(setIsFetchingExamsKitsItems(true));
		try {
			await api.delete(`/api/serviceitemkititem/${examKitItemId}`);
			toast.success("Item excluído com sucesso", {
				toastId: "delete-kit-item",
			});
			dispatch(fetchExamsKitItems(idserviceitemkit));
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingExamsKitsItems(false));
		}
	};

export const {
	setUserId,
	setListPage,
	setListTotalSize,
	setListLimit,
	setExamsKits,
	setExamKit,
	setExamKitFilterId,
	setIsCreatingExamKit,
	setIsFetchingExamsKits,
	setIsFetchingExamsKitsItems,
	setExamsKitItems,
	setIdServiceItemKit,
	setIsCreatingExamKitItem,
} = examsKitSlice.actions;

export default examsKitSlice.reducer;
