import {
  AnyAction,
  createSlice,
  PayloadAction,
  ThunkAction,
} from "@reduxjs/toolkit";
import {
  ICallPanel,
  ICallPanelForm,
  ICallPanelOfAttendanceGroup,
  ICallPanelOffice,
} from "models/CallPanel";
import { IFilter } from "models/shared";
import { toast } from "react-toastify";
import { AppThunk, rootState } from "store";
import api from "utils/API";
import { history } from "utils/history";
import { queryStringFromFilterArray } from "utils/network";
import toastOptions from "utils/toastOptions";

interface IInitialState {
  isFetchingCallPanel: boolean;
  isCreatingCallPanel: boolean;
  isDeletingCallPanel: boolean;
  isEditBondDialogOpen: boolean;
  amountOfItemsToShow: number;
  callPanel: ICallPanel[] | null;
  currentCallPanel: ICallPanel | null;
  callPanelByIds: ICallPanelOfAttendanceGroup[] | null;
  callPanelOfficeByIds: ICallPanelOffice[] | null;
  toAddPage: number;
  toAddTotal: number;
  total: number;
  page: number;
  filterArray: IFilter[];
  pageToCallPanel: number;
}
const initialState: IInitialState = {
  isFetchingCallPanel: false,
  isCreatingCallPanel: false,
  isDeletingCallPanel: false,
  isEditBondDialogOpen: false,
  toAddPage: 0,
  toAddTotal: 0,
  callPanel: null,
  currentCallPanel: null,
  callPanelByIds: null,
  callPanelOfficeByIds: null,
  amountOfItemsToShow: 6,
  total: 0,
  page: 0,
  filterArray: [{ key: "nome", value: [] }],
  pageToCallPanel: 1,
};

const callPanelSlice = createSlice({
  name: "callPanel",
  initialState,
  reducers: {
    setIsFetchingCallPanel: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingCallPanel = payload;
    },
    setIsCreatingCallPanel: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreatingCallPanel = payload;
    },
    setisDeletingCallPanel: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeletingCallPanel = payload;
    },
    setIsEditBondDialogOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isEditBondDialogOpen = payload;
    },
    setAmountOfItemsToShow: (state, { payload }: PayloadAction<number>) => {
      state.amountOfItemsToShow = payload;
    },
    setCallPanel: (
      state,
      {
        payload: { call_panels, total, page },
      }: PayloadAction<{
        call_panels: ICallPanel[];
        total: number;
        page: number;
      }>
    ) => {
      state.callPanel = call_panels;
      state.total = total;
      state.page = page;
    },
    setToAddPagination: (
      state,
      {
        payload: { total, page },
      }: PayloadAction<{
        page: number;
        total: number;
      }>
    ) => {
      state.toAddPage = page;
      state.toAddTotal = total;
    },
    setCurrentCallPanel: (state, { payload }: PayloadAction<ICallPanel>) => {
      state.currentCallPanel = payload;
    },
    setCallPanelByIds: (
      state,
      { payload }: PayloadAction<ICallPanelOfAttendanceGroup[]>
    ) => {
      state.callPanelByIds = payload;
    },
    setCallPanelOfficeByIds: (
      state,
      { payload }: PayloadAction<ICallPanelOffice[]>
    ) => {
      state.callPanelOfficeByIds = payload;
    },
    setPageToCallPanel: (state, { payload }: PayloadAction<number>) => {
      state.pageToCallPanel = payload;
    },
  },
});

export const fetchCallPanels =
  ({
    page = 1,
    limit = 6,
    idclinica,
    idbloco,
    idsetor,
    type,
  }: {
    page?: number;
    limit?: number;
    idclinica?: string;
    idsetor?: string;
    idbloco?: string;
    type?: "CONSULTÓRIO" | "GUICHÊ";
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { setIsFetchingCallPanel, setCallPanel } = callPanelSlice.actions;
    dispatch(setIsFetchingCallPanel(true));
    try {
      const state = getState();
      const { filterArray } = state.counter;
      const queryParameters = queryStringFromFilterArray(filterArray);
      const pageAndLimit =
        queryParameters.length === 0
          ? `?page=${page}&limit=${limit}`
          : `&page=${page}&limit=${limit}`;
      const typeQuery = type !== undefined ? `&type=${type}` : "";
      const response = await api.get(
        `/api/users-patients/call-panel${pageAndLimit}&idclinica=${idclinica}${typeQuery}&idbloco=${idbloco}&idsetor=${idsetor}`
      );
      dispatch(setCallPanel(response.data.data));
      dispatch(setIsFetchingCallPanel(false));
    } catch (error: any) {
      dispatch(setIsFetchingCallPanel(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };
export const fetchCallPanelByIds =
  ({
    idgrupoatendimento,
  }: {
    idgrupoatendimento: string;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { setIsFetchingCallPanel, setCallPanelByIds } =
      callPanelSlice.actions;
    dispatch(setIsFetchingCallPanel(true));
    try {
      const response = await api.get(
        `/api/users-patients/call-panel-by-attendanceId?idgrupoatendimento=${idgrupoatendimento}`
      );
      dispatch(setCallPanelByIds(response.data.data));
      dispatch(setIsFetchingCallPanel(false));
    } catch (error: any) {
      dispatch(setIsFetchingCallPanel(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };
export const fetchCallPanelById =
  ({
    idpainelchamado,
  }: {
    idpainelchamado: string;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { setIsFetchingCallPanel, setCurrentCallPanel } =
      callPanelSlice.actions;
    dispatch(setIsFetchingCallPanel(true));
    try {
      const response = await api.get(
        `/api/users-patients/call-panel-by-id?idpainelchamado=${idpainelchamado}`
      );
      dispatch(setCurrentCallPanel(response.data.data));
      dispatch(setIsFetchingCallPanel(false));
    } catch (error: any) {
      dispatch(setIsFetchingCallPanel(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchCallPanelOfficeByIds =
  ({
    idconsultorio,
  }: {
    idconsultorio: string;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { setIsFetchingCallPanel, setCallPanelOfficeByIds } =
      callPanelSlice.actions;
    dispatch(setIsFetchingCallPanel(true));
    try {
      const response = await api.get(
        `/api/users-patients/call-panel-by-officeId?idconsultorio=${idconsultorio}`
      );
      dispatch(setCallPanelOfficeByIds(response.data.data));
      dispatch(setIsFetchingCallPanel(false));
    } catch (error: any) {
      dispatch(setIsFetchingCallPanel(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const updateCallPanel =
  ({
    callPanel,
    callPanelId,
    idclinica,
    idbloco,
    idsetor,
    idsconjuntoguiches,
    cb,
  }: {
    callPanel: ICallPanelForm;
    callPanelId: string | number;
    idclinica?: string;
    idbloco?: string;
    idsetor?: string;
    idsconjuntoguiches?: string[];
    cb?: () => void;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingCallPanel } = callPanelSlice.actions;
    dispatch(setIsCreatingCallPanel(true));
    try {
      await api.put(
        `/api/users-patients/call-panel?idpainelchamado=${callPanelId}`,
        {
          ...callPanel,
          idclinica: idclinica,
          idsetor,
          idbloco,
          idsconjuntoguiches,
        }
      );
      dispatch(setIsCreatingCallPanel(false));
      toast.success("Painel de chamados atualizado", toastOptions);
      history.replace(
        `/clinics/${idclinica}/departments/${idsetor}/blocks/${idbloco}/attendance/call-panel`
      );
      if (cb) cb();
    } catch (error: any) {
      dispatch(setIsCreatingCallPanel(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const createCallPanel =
  (
    callPanel: ICallPanelForm,
    idclinica?: string,
    idbloco?: string,
    idsetor?: string,
    idsconjuntoguiches?: string[]
  ): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsCreatingCallPanel } = callPanelSlice.actions;
    dispatch(setIsCreatingCallPanel(true));
    try {
      await api.post(`/api/users-patients/call-panel`, {
        ...callPanel,
        idclinica: idclinica,
        idsconjuntoguiches,
        idsetor,
        idbloco,
      });
      dispatch(setIsCreatingCallPanel(false));
      history.replace(
        `/clinics/${idclinica}/departments/${idsetor}/blocks/${idbloco}/attendance/call-panel`
      );
      toast.success("Painel de chamados cadastrado", toastOptions);
    } catch (error: any) {
      dispatch(setIsCreatingCallPanel(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const deleteCallPanel =
  (
    callPanelId: string,
    idclinica: string,
    idbloco: string,
    idsetor: string,
    cb?: () => void
  ): AppThunk =>
  async (dispatch) => {
    const { setisDeletingCallPanel } = callPanelSlice.actions;
    dispatch(setisDeletingCallPanel(true));
    try {
      await api.delete(
        `/api/users-patients/call-panel?idpainelchamado=${callPanelId}`
      );
      dispatch(setisDeletingCallPanel(false));
      toast.success("Painel de chamados excluido com sucesso", toastOptions);
      dispatch(
        fetchCallPanels({
          idclinica,
          idbloco,
          idsetor,
        })
      );
      if (cb) cb();
    } catch (error: any) {
      dispatch(setisDeletingCallPanel(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const {
  setIsEditBondDialogOpen,
  setPageToCallPanel,
  setAmountOfItemsToShow,
} = callPanelSlice.actions;

export default callPanelSlice.reducer;
