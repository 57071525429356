import {
  Button,
  Card,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateRangeDialog from "components/DateRangeDialog";
import { Loader } from "components/Loader";
import TitleBar from "components/TitleBar";
import { FullWidthContainer } from "components/layout/base";
import dayjs from "dayjs";
import { autocompleteDefaultProps } from "models/Autocomplete";
import { ISelectOption } from "pages/SchedulePage";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { rootState } from "store";
import { fetchClinics } from "store/reducers/ClinicsReducer";
import { fetchAllHealthPlans } from "store/reducers/HealthPlanReducer";
import {
  clearListRankingServices,
  fetchRankHealthPlan,
  updateReceivedPaymentsFilterArray,
} from "store/reducers/ReportsReducer";
import RankingServicesTable from "./RankingServicesTable";

const HealthPlanRanking = () => {
  const dispatch = useDispatch();
  const [filterDate, setFilterDate] = useState([Date(), Date()]);
  const [firstDateFilterChange, setFirstDateFilterChange] = useState(true);
  const [isDateRangeDialogOpen, setIsDateRangeDialogOpen] = useState(false);
  const [selectedClinic, setSelectedClinic] = useState<ISelectOption[]>([]);
  const [clinicsOptions, setClinicsOptions] = useState<ISelectOption[]>([
    { label: "", value: "" },
  ]);
  const [currentHealthPlanFilter, setCurrentHealthPlanFilter] = useState<
    ISelectOption[]
  >([]);

  useEffect(() => {
    dispatch(fetchClinics({ limit: 9999 }));
    // dispatch(fetchRankHealthPlan());
  }, [dispatch]);

  const {
    reports: { rankHealthPlan, isFetchingReport },
    users: { signedInUser },
    healthPlan: { allHealthPlans },
  } = useSelector((state: rootState) => state);

  const healthPlansOptions = useMemo(() => {
    if (allHealthPlans) {
      return allHealthPlans.map((healthPlan) => ({
        label: healthPlan.nome,
        value: healthPlan.idconvenio as string,
      }));
    }

    return [];
  }, [allHealthPlans]);

  useEffect(() => {
    if (signedInUser && signedInUser.clinicas) {
      setClinicsOptions(
        signedInUser.clinicas?.map(({ nome, idclinica }) => {
          return { label: nome.toUpperCase(), value: idclinica };
        })
      );

      if (signedInUser.clinicas.length === 1) {
        setSelectedClinic([
          {
            label: signedInUser.clinicas[0].nome.toUpperCase(),
            value: signedInUser.clinicas[0].idclinica,
          },
        ]);
        dispatch(
          updateReceivedPaymentsFilterArray({
            key: "idclinica",
            value: signedInUser.clinicas[0].idclinica,
          })
        );
      }
    }
  }, [signedInUser, dispatch]);

  const onChangeDateRange = (dateRange: any) => {
    setFilterDate([dateRange[0].startDate, dateRange[0].endDate]);
  };

  useEffect(() => {
    if (firstDateFilterChange) {
      setFirstDateFilterChange(false);
    } else {
      // resetPage();
      dispatch(
        updateReceivedPaymentsFilterArray({
          key: "start_date",
          value: dayjs(filterDate[0]).format("YYYY-MM-DD"),
        })
      );
      dispatch(
        updateReceivedPaymentsFilterArray({
          key: "end_date",
          value: dayjs(filterDate[1]).format("YYYY-MM-DD"),
        })
      );
    }
  }, [filterDate, dispatch, firstDateFilterChange]);

  useEffect(() => {
    dispatch(clearListRankingServices());
    dispatch(fetchAllHealthPlans());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showServiceOrders = () => {
    if (isFetchingReport) {
      return <Loader />;
    }
    if (!rankHealthPlan) {
      return (
        <Grid container justify="center">
          <Grid item>
            <Typography variant="body1" style={{ textTransform: "uppercase" }}>
              Selecione os filtros e click em buscar!
            </Typography>
          </Grid>
        </Grid>
      );
    }

    if (rankHealthPlan.health_plan_service_order_results.length === 0) {
      return (
        <Grid container justify="center">
          <Grid item>
            <Typography variant="h6">
              Nenhum resultado encontrado (Verifique os filtros)
            </Typography>
          </Grid>
        </Grid>
      );
    }

    return (
      <Paper>
        <RankingServicesTable data={rankHealthPlan} />
      </Paper>
    );
  };

  return (
    <>
      <Grid container direction="column">
        <Grid item xs={12}>
          <TitleBar title="Relatório de faturamento de convênios" wrap>
            <Grid
              container
              spacing={2}
              style={{ padding: 20 }}
              alignItems="center"
            >
              <Grid item>
                <Autocomplete
                  value={selectedClinic}
                  multiple
                  options={clinicsOptions}
                  getOptionLabel={(option: ISelectOption) =>
                    option.label.toUpperCase()
                  }
                  // @ts-ignore
                  onChange={(event: any, newValue: ISelectOption[]) => {
                    setSelectedClinic(newValue);
                    dispatch(
                      updateReceivedPaymentsFilterArray({
                        key: "idclinica",
                        value: newValue.map((item) => item.value),
                      })
                    );
                  }}
                  fullWidth
                  renderInput={(params: any) => (
                    <TextField
                      SelectProps={{ style: { fontSize: 12 } }}
                      InputProps={{ style: { padding: 5 } }}
                      style={{ minWidth: 200, maxWidth: 350, fontSize: 12 }}
                      variant="outlined"
                      {...params}
                      label="Clínica"
                    />
                  )}
                />
              </Grid>

              {/* HEALTH PLANS FILTER */}
              <Grid item>
                <Autocomplete
                  {...autocompleteDefaultProps}
                  multiple
                  value={currentHealthPlanFilter}
                  options={healthPlansOptions}
                  getOptionLabel={(option: ISelectOption) =>
                    option.label.toUpperCase()
                  }
                  onChange={(
                    event: React.ChangeEvent<{}>,
                    value: ISelectOption[] | null
                  ) => {
                    setCurrentHealthPlanFilter(value as ISelectOption[]);
                    dispatch(
                      updateReceivedPaymentsFilterArray({
                        key: "idconvenio", // VERIFICAR AQUI
                        value: value?.map((item) => item.value),
                      })
                    );
                  }}
                  fullWidth
                  renderInput={(params: any) => (
                    <TextField
                      style={{ minWidth: 200 }}
                      variant="outlined"
                      name="idconvenio"
                      {...params}
                      label="Convênios"
                    />
                  )}
                />
              </Grid>

              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setIsDateRangeDialogOpen(true)}
                  style={{ height: "55px" }}
                >
                  {`${dayjs(filterDate[0]).format("DD/MM/YYYY")} - ${dayjs(
                    filterDate[1]
                  ).format("DD/MM/YYYY")}`}
                </Button>
              </Grid>

              <Grid item>
                <Button
                  startIcon={<SearchIcon />}
                  variant="contained"
                  color="primary"
                  style={{ height: "55px" }}
                  disabled={selectedClinic.length === 0}
                  onClick={() => {
                    dispatch(fetchRankHealthPlan());
                  }}
                >
                  Buscar
                </Button>
              </Grid>
            </Grid>
            <DateRangeDialog
              onChangeDate={onChangeDateRange}
              handleClose={() => setIsDateRangeDialogOpen(false)}
              open={isDateRangeDialogOpen}
            />
          </TitleBar>
        </Grid>

        <Grid item xs={12}>
          <FullWidthContainer>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card>{showServiceOrders()}</Card>
              </Grid>
            </Grid>
          </FullWidthContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default HealthPlanRanking;
