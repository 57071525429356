import * as Yup from "yup";
import { IAppointmentWebSetupInstallmentsForm } from "store/reducers/AppointmentWebSetup";

export const validationSchema = Yup.object().shape({
  idgruposervico: Yup.string().required("Campo obrigatório"),
  idmetodopagamento: Yup.string().required("Campo obrigatório"),
});

export const initialState: IAppointmentWebSetupInstallmentsForm = {
  idgruposervico: "",
  idmetodopagamento: "",
};
