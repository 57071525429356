import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFilter } from "models/shared";
import { AppThunk, AppDispatch } from "store/index";
import api from "utils/API";
import { toast } from "react-toastify";
import toastOptions from "utils/toastOptions";
import { queryStringFromFilterArray } from "utils/network";
import { IExame } from "pages/MedicalRecord/components/PatientMedicalRecord/pages/PedidosDeExames/components/ExameCard";
import { IExamForm, IManualExamForm } from "models/MedicalRecord";

interface IInitialState {
  isFetchingUserExam: boolean;
  userExams: IExame[] | null;
  currentUserExam: IExame | null;
  isCreatingUserExam: boolean;
  isDeletingUserExam: boolean;
}

const initialState: IInitialState = {
  isFetchingUserExam: false,
  userExams: null,
  currentUserExam: null,
  isCreatingUserExam: false,
  isDeletingUserExam: false,
};

const userExamSlice = createSlice({
  name: "userExamSlice",
  initialState,
  reducers: {
    setisFetchingUserExam: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingUserExam = payload;
    },
    setUserExams: (state, { payload }: PayloadAction<IExame[]>) => {
      state.userExams = payload;
    },
    setIsCreatingUserExam: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreatingUserExam = payload;
    },
    setIsDeletingUserExam: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeletingUserExam = payload;
    },
  },
});

export const fetchUserExams =
  (medicalRecordId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { setisFetchingUserExam, setUserExams } = userExamSlice.actions;
    dispatch(setisFetchingUserExam(true));
    try {
      const response = await api.get(`/api/exam-order/${medicalRecordId}`);
      dispatch(setUserExams(response.data.data.examOrders));
      dispatch(setisFetchingUserExam(false));
    } catch (error) {
      dispatch(setisFetchingUserExam(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error);
      }
    }
  };

// ({
//     prescription,
//     manualPrescription,
//     cb
//   }: {
//     prescription?: IPrescriptionForm,
//     manualPrescription?: IManualPrescriptionForm,
//     cb?: Function
//   }):

//   manualExam: IManualExamForm,
//     newExam?: {
//         idMedicalRecord: string,
//         idexame: string, preparo:
//         string, detalhamento: string

export const createUserExam =
  (
    {
      manualExam,
      newExam,
    }: {
      manualExam?: IManualExamForm;
      newExam?: IExamForm;
    },
    cb?: any
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { setIsCreatingUserExam } = userExamSlice.actions;
    dispatch(setIsCreatingUserExam(true));
    try {
      if (manualExam) {
        await api.post(`/api/exam-order`, manualExam);
      }
      if (newExam) {
        await api.post(`/api/exam-order`, newExam);
      }
      dispatch(setIsCreatingUserExam(false));
      if (cb) {
        cb();
      }
      toast.success("Exame incluído com sucesso", {
        ...toastOptions,
        toastId: "createUserExam",
      });
    } catch (error) {
      dispatch(setIsCreatingUserExam(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error);
      }
    }
  };

export const deleteUserExam =
  (
    {
      idprontuario,
      idpedidoexame,
    }: {
      idprontuario: string;
      idpedidoexame: string;
    },
    cb?: any
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { setIsDeletingUserExam } = userExamSlice.actions;
    dispatch(setIsDeletingUserExam(true));
    try {
      await api.delete(`/api/exam-order/${idprontuario}/${idpedidoexame}`);
      dispatch(setIsDeletingUserExam(false));
      if (cb) {
        cb();
      }
      toast.success("Exame excluído com sucesso", toastOptions);
    } catch (error) {
      dispatch(setIsDeletingUserExam(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error);
      }
    }
  };

export const updateUserExam =
  (
    {
      idprontuario,
      idpedidoexame,
      preparo,
      detalhamento,
      valorflutuante,
    }: {
      idprontuario: string;
      idpedidoexame: string;
      preparo: string;
      detalhamento: string;
      valorflutuante?: string | number;
    },
    cb?: any
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { setIsCreatingUserExam } = userExamSlice.actions;
    dispatch(setIsCreatingUserExam(true));
    try {
      await api.put(`/api/exam-order/${idprontuario}/${idpedidoexame}`, {
        preparo,
        detalhamento,
        valorflutuante,
      });
      dispatch(setIsCreatingUserExam(false));
      if (cb) {
        cb();
      }
      toast.success("Exame atualizado com sucesso", toastOptions);
    } catch (error) {
      dispatch(setIsCreatingUserExam(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error);
      }
    }
  };

export default userExamSlice.reducer;
