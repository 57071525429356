import {
  AnyAction,
  createSlice,
  PayloadAction,
  ThunkAction,
} from "@reduxjs/toolkit";
import { AppThunk, rootState } from "store/index";
import { toast } from "react-toastify";
import toastOptions from "utils/toastOptions";
import api from "utils/API";
import { IFilter } from "models/shared";
import { queryStringFromFilterArray } from "utils/network";
import { history } from "utils/history";
import {
  ITotemWindowSet,
  IWindowSet,
  IWindowSetForm,
  IWindowSettings,
  IWindowsPanelsAndSetsModel,
} from "models/WindowSet";

interface IInitialState {
  isFetchingWindowSet: boolean;
  isCreatingWindowSet: boolean;
  isDeletingWindowSet: boolean;
  isEditBondDialogOpen: boolean;
  amountOfItemsToShow: number;
  windowSets: IWindowSet[] | null;
  windowSettings: IWindowSettings[];
  currentWindowSet: IWindowSet | null;
  windowSetsCallPanel: IWindowsPanelsAndSetsModel[] | null;
  windowSetsTotem: ITotemWindowSet[] | null;
  toAddPage: number;
  toAddTotal: number;
  total: number;
  page: number;
  filterArray: IFilter[];
  pageToWindowSet: number;
}

const initialState: IInitialState = {
  isFetchingWindowSet: false,
  isCreatingWindowSet: false,
  isDeletingWindowSet: false,
  isEditBondDialogOpen: false,
  toAddPage: 0,
  toAddTotal: 0,
  windowSets: null,
  currentWindowSet: null,
  windowSetsTotem: null,
  windowSetsCallPanel: null,
  amountOfItemsToShow: 6,
  total: 0,
  page: 0,
  filterArray: [{ key: "nome", value: [] }],
  pageToWindowSet: 1,
  windowSettings: []
};

const windowSetSlice = createSlice({
  name: "windowSlice",
  initialState,
  reducers: {
    setIsFetchingWindowSet: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingWindowSet = payload;
    },
    setIsCreatingWindowSet: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreatingWindowSet = payload;
    },
    setisDeletingWindowSet: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeletingWindowSet = payload;
    },
    setIsEditBondDialogOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isEditBondDialogOpen = payload;
    },
    setAmountOfItemsToShow: (state, { payload }: PayloadAction<number>) => {
      state.amountOfItemsToShow = payload;
    },
    setWindowSets: (
      state,
      {
        payload: { window_sets, total, page },
      }: PayloadAction<{
        window_sets: IWindowSet[];
        total: number;
        page: number;
      }>
    ) => {
      state.windowSets = window_sets;
      state.total = total;
      state.page = page;
    },
    setToAddPagination: (
      state,
      {
        payload: { total, page },
      }: PayloadAction<{
        page: number;
        total: number;
      }>
    ) => {
      state.toAddPage = page;
      state.toAddTotal = total;
    },
    setCurrentWindowSet: (state, { payload }: PayloadAction<IWindowSet>) => {
      state.currentWindowSet = payload;
    },
    setPageToWindowSet: (state, { payload }: PayloadAction<number>) => {
      state.pageToWindowSet = payload;
    },
    setWindowSetsCallPanel: (
      state,
      { payload }: PayloadAction<IWindowsPanelsAndSetsModel[]>
    ) => {
      state.windowSetsCallPanel = payload;
    },
    setWindowSetsTotem: (
      state,
      { payload }: PayloadAction<ITotemWindowSet[]>
    ) => {
      state.windowSetsTotem = payload;
    },
    setWindowSettings: (
      state,
      { payload }: PayloadAction<IWindowSettings[]>
    ) => {
      state.windowSettings = payload;
    },
  },
});

export const fetchWindowSet =
  ({
    page = 1,
    limit = 6,
    idclinica,
    idbloco,
    idsetor,
  }: {
    page?: number;
    limit?: number;
    idclinica?: string;
    idsetor?: string;
    idbloco?: string;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { setIsFetchingWindowSet, setWindowSets } = windowSetSlice.actions;
    dispatch(setIsFetchingWindowSet(true));
    try {
      const state = getState();
      const { filterArray } = state.counter;
      const queryParameters = queryStringFromFilterArray(filterArray);
      const pageAndLimit =
        queryParameters.length === 0
          ? `?page=${page}&limit=${limit}`
          : `&page=${page}&limit=${limit}`;
      // const response = await api.get(
      //   `/api/window-sets${pageAndLimit}&idclinica=${idclinica}`
      // );
      let params = "";
      if(idsetor) {
        params = `&idsetor=${idsetor}`
      }
      if(idbloco) {
        params = `&idbloco=${idbloco}`
      }
      const response = await api.get(
        `/api/window-sets${pageAndLimit}&idclinica=${idclinica}${params}`
      );
      dispatch(setWindowSets(response.data.data));
      dispatch(setIsFetchingWindowSet(false));
    } catch (error: any) {
      dispatch(setIsFetchingWindowSet(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };
  export const fetchWindowSetSettings = (): ThunkAction<void, rootState, unknown, AnyAction> => async (dispatch) => {
    const { setIsFetchingWindowSet, setWindowSettings } =
      windowSetSlice.actions;
    dispatch(setIsFetchingWindowSet(true));
    try {
      const response = await api.get(`/api/counter/list/settings`);
      dispatch(setWindowSettings(response.data.data.counter_activity));
      dispatch(setIsFetchingWindowSet(false));
    } catch (error: any) {
      dispatch(setIsFetchingWindowSet(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchWindowSetById =
  (windowSetId: string): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsFetchingWindowSet, setCurrentWindowSet } =
      windowSetSlice.actions;
    dispatch(setIsFetchingWindowSet(true));
    try {
      const response = await api.get(`/api/window-sets/${windowSetId}`);
      dispatch(setCurrentWindowSet(response.data.data));
      dispatch(setIsFetchingWindowSet(false));
    } catch (error: any) {
      dispatch(setIsFetchingWindowSet(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchWindowSetByCallPanelId =
  ({
    idpainelchamado,
  }: {
    idpainelchamado: string;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsFetchingWindowSet, setWindowSetsCallPanel } =
      windowSetSlice.actions;
    dispatch(setIsFetchingWindowSet(true));
    try {
      const response = await api.get(
        `/api/windowsets_by_call_panelid?idpainelchamado=${idpainelchamado}`
      );
      dispatch(setWindowSetsCallPanel(response.data.data));
      dispatch(setIsFetchingWindowSet(false));
    } catch (error: any) {
      dispatch(setIsFetchingWindowSet(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchWindowSetByTotemId =
  ({
    idtotem,
  }: {
    idtotem: string;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsFetchingWindowSet, setWindowSetsTotem } =
      windowSetSlice.actions;
    dispatch(setIsFetchingWindowSet(true));
    try {
      const response = await api.get(
        `/api/windowsets_by_totemid?idtotem=${idtotem}`
      );
      dispatch(setWindowSetsTotem(response.data.data));
      dispatch(setIsFetchingWindowSet(false));
    } catch (error: any) {
      dispatch(setIsFetchingWindowSet(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const updateWindowSet =
  ({
    windowSet,
    windowSetId,
    idclinica,
    idbloco,
    idsetor,
    idstiposervico,
    cb,
  }: {
    windowSet: IWindowSetForm;
    windowSetId: string | number;
    idclinica?: string;
    idsetor?: string;
    idbloco?: string;
    idstiposervico?: string[];
    cb?: () => void;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingWindowSet } = windowSetSlice.actions;
    dispatch(setIsCreatingWindowSet(true));
    try {
      await api.put(`/api/window-sets/${windowSetId}`, {
        ...windowSet,
        idclinica: idclinica,
        idstiposervico,
      });
      dispatch(setIsCreatingWindowSet(false));
      toast.success("Conjuntos de Guichê atualizado", toastOptions);
      history.replace(
        `/clinics/${idclinica}/departments/${idsetor}/blocks/${idbloco}/attendance/window-sets`
      );
      if (cb) cb();
    } catch (error: any) {
      dispatch(setIsCreatingWindowSet(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const createWindowSet =
  (
    windowSet: IWindowSetForm,
    idclinica?: string,
    idsetor?: string,
    idbloco?: string,
    idstiposervico?: string[]
  ): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsCreatingWindowSet } = windowSetSlice.actions;
    dispatch(setIsCreatingWindowSet(true));
    try {
      await api.post(`/api/window-sets`, {
        ...windowSet,
        idclinica: idclinica,
        idsetor,
        idbloco,
        idstiposervico,
      });
      dispatch(setIsCreatingWindowSet(false));
      history.replace(
        `/clinics/${idclinica}/departments/${idsetor}/blocks/${idbloco}/attendance/window-sets`
      );
      toast.success("Conjunto de Guichê cadastrado", toastOptions);
    } catch (error: any) {
      dispatch(setIsCreatingWindowSet(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const deleteWindowSet =
  (
    windowSetId: string,
    idclinica: string,
    idbloco: string,
    idsetor: string
  ): AppThunk =>
  async (dispatch) => {
    const { setisDeletingWindowSet } = windowSetSlice.actions;
    dispatch(setisDeletingWindowSet(true));
    try {
      await api.delete(`/api/window-sets/${windowSetId}`);
      dispatch(setisDeletingWindowSet(false));
      toast.success("Conjunto de Guichês excluido com sucesso", toastOptions);
      dispatch(
        fetchWindowSet({
          idclinica,
          idbloco,
          idsetor,
        })
      );
    } catch (error: any) {
      dispatch(setisDeletingWindowSet(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const {
  setIsEditBondDialogOpen,
  setPageToWindowSet,
  setAmountOfItemsToShow,
} = windowSetSlice.actions;

export default windowSetSlice.reducer;
