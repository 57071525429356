import React, { useState } from "react";
// @ts-ignore
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from "@material-ui/core";
import { DateRange } from "react-date-range";

import ptBrLocale from "date-fns/locale/pt-BR";

interface IProps {
  open: boolean;
  handleClose: Function;
  onChangeDate: Function;
  resetRange?: boolean;
  minDate?: boolean;
  setResetRange? : React.Dispatch<React.SetStateAction<boolean>>
}

const DateRangeDialog: React.FC<IProps> = ({
  open,
  handleClose,
  onChangeDate,
  resetRange,
  minDate,
  setResetRange
}) => {
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const handleSubmit = () => {
    onChangeDate(dateRange);
    handleClose();
  };
  
  React.useEffect(()=>{
    if(setResetRange){
      if(resetRange){
        setDateRange([
          {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
          },
        ])
      }
      setResetRange(false)
    }
  },[resetRange, setResetRange])

  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <DateRange
              locale={ptBrLocale}
              dragSelectionEnabled={false}
              moveRangeOnFirstSelection={false}
              ranges={dateRange}
              minDate={minDate ? new Date() : undefined}
              onChange={(item: any) => setDateRange([item.selection])}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1}>
          <Grid item>
            <Button
              onClick={() => handleSubmit()}
              variant="contained"
              color="primary"
            >
              Selecionar
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleClose()}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default DateRangeDialog;
