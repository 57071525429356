import { ISmsRule, MINUTES_NOTIFY, SmsType } from "store/reducers/AppointmentWebSetup";
import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  value: Yup.string().required("Campo obrigatório"),
  idclinica: Yup.string().required("Campo obrigatório"),
});

export const initialState: ISmsRule = {
  greeting: "Olá",
  value: "",
  idclinica: "",
  type: SmsType.CONFIRMATION,
  minutes: MINUTES_NOTIFY.ONE_DAY
};
