import {
  IDeparmentForm,
  IDepartment,
} from "./../../../models/Departments/index";
import { IFilter } from "models/shared/index";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "store/index";
import { toast } from "react-toastify";
import toastOptions from "utils/toastOptions";
import api from "utils/API";
import { history } from "utils/history";
import { queryStringFromFilterArray } from "utils/network";

interface IInitialState {
  isFetchingDepartments: boolean;
  isCreatingDepartment: boolean;
  isDeletingDepartment: boolean;
  departments: IDepartment[] | null;
  currentDepartment: IDepartment | null;
  total: number;
  page: number;
  filterArray: IFilter[];
}

const initialState: IInitialState = {
  isFetchingDepartments: false,
  isCreatingDepartment: false,
  isDeletingDepartment: false,
  departments: null,
  currentDepartment: null,
  total: 0,
  page: 0,
  filterArray: [{ key: "nome", value: [] }],
};

const departmentsSlice = createSlice({
  name: "departmentsSlice",
  initialState,
  reducers: {
    setIsFetchingDepartments: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingDepartments = payload;
    },
    setisCreatingDepartment: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreatingDepartment = payload;
    },
    setisDeletingDepartment: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeletingDepartment = payload;
    },
    setDepartments: (
      state,
      {
        payload: { departments, total, page },
      }: PayloadAction<{
        departments: IDepartment[];
        total: number;
        page: number;
      }>
    ) => {
      state.departments = departments;
      state.total = total;
      state.page = page;
    },
    setCurrentDepartment: (state, { payload }: PayloadAction<IDepartment>) => {
      state.currentDepartment = payload;
    },
    updateFilter: (state, { payload }: PayloadAction<IFilter>) => {
      const index = state.filterArray.findIndex(
        (item) => item.key === payload.key
      );
      state.filterArray[index].value = payload.value;
    },
  },
});

export const fetchDepartments =
  ({
    page = 1,
    limit = 6,
    clinicId,
  }: {
    page?: number;
    limit?: number;
    clinicId: number | string;
  }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingDepartments, setDepartments } =
      departmentsSlice.actions;
    dispatch(setIsFetchingDepartments(true));
    try {
      const state = getState();
      const { filterArray } = state.departments;
      const queryParameters = queryStringFromFilterArray(filterArray);
      const pageAndLimit =
        queryParameters.length === 0
          ? `?page=${page}&limit=${limit}`
          : `&page=${page}&limit=${limit}`;
      const response = await api.get(
        `/api/departments${queryParameters}${pageAndLimit}&idclinica=${clinicId}`
      );
      dispatch(setDepartments(response.data.data));
      dispatch(setIsFetchingDepartments(false));
    } catch (error: any) {
      dispatch(setIsFetchingDepartments(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const departmentActivation =
  ({
    isDepartmentActive,
    clinicId,
    departmentId,
  }: {
    isDepartmentActive: boolean;
    clinicId: string | number;
    departmentId: string | number;
  }): AppThunk =>
  async (dispatch) => {
    const { setisCreatingDepartment } = departmentsSlice.actions;
    dispatch(setisCreatingDepartment(true));
    try {
      await api.put(`/api/departments/${departmentId}`, {
        ativo: !isDepartmentActive,
      });
      dispatch(setisCreatingDepartment(false));
      dispatch(
        fetchDepartments({
          clinicId,
        })
      );
      toast.success(
        `Setor ${isDepartmentActive ? "desativado" : "ativado"} com sucesso`,
        toastOptions
      );
    } catch (error: any) {
      dispatch(setisCreatingDepartment(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchDepartmentById =
  (departmentId: string): AppThunk =>
  async (dispatch) => {
    const { setIsFetchingDepartments, setCurrentDepartment } =
      departmentsSlice.actions;
    dispatch(setIsFetchingDepartments(true));
    try {
      const response = await api.get(`/api/departments/${departmentId}`);
      dispatch(setCurrentDepartment(response.data.data));
      dispatch(setIsFetchingDepartments(false));
    } catch (error: any) {
      dispatch(setIsFetchingDepartments(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const createDepartment =
  ({
    department,
    clinicId,
  }: {
    department: IDeparmentForm;
    clinicId: string;
  }): AppThunk =>
  async (dispatch) => {
    const { setisCreatingDepartment } = departmentsSlice.actions;
    dispatch(setisCreatingDepartment(true));
    try {
      await api.post(`/api/departments`, department);
      dispatch(setisCreatingDepartment(false));
      history.replace(`/clinics/${clinicId}/departments`);
      toast.success("Setor cadastrado", toastOptions);
    } catch (error: any) {
      dispatch(setisCreatingDepartment(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const updateDepartment =
  ({
    department,
    departmentId,
    clinicId,
  }: {
    department: IDeparmentForm;
    departmentId: string | number;
    clinicId: string | number;
  }): AppThunk =>
  async (dispatch) => {
    const { setisCreatingDepartment } = departmentsSlice.actions;
    dispatch(setisCreatingDepartment(true));
    try {
      await api.put(`/api/departments/${departmentId}`, department);
      dispatch(setisCreatingDepartment(false));
      toast.success("Setor atualizado", toastOptions);
      history.replace(`/clinics/${clinicId}/departments`);
    } catch (error: any) {
      dispatch(setisCreatingDepartment(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const deleteDepartment =
  ({
    clinicId,
    departmentId,
  }: {
    clinicId: string;
    departmentId: string;
  }): AppThunk =>
  async (dispatch) => {
    const { setisDeletingDepartment } = departmentsSlice.actions;
    dispatch(setisDeletingDepartment(true));
    try {
      await api.delete(`/api/departments/${departmentId}`);
      dispatch(setisDeletingDepartment(false));
      toast.success("Setor excluido com sucesso", toastOptions);
      dispatch(fetchDepartments({ clinicId }));
    } catch (error: any) {
      dispatch(setisDeletingDepartment(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const { updateFilter: updateDepartmentsFilter } =
  departmentsSlice.actions;

export default departmentsSlice.reducer;
