import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "store/index";
import { toast } from "react-toastify";
import toastOptions from "utils/toastOptions";
import api from "utils/API";
import { IDiagnostic, ICid } from "models/Diagnostic";

interface IInitialState {
  isFetchingDiagnostics: boolean;
  isFetchingCids: boolean;
  isCreatingDiagnostic: boolean;
  isDeletingDiagnostic: boolean;
  diagnostics: IDiagnostic[];
  cids: ICid[];
  total: number;
  page: number;
}

const initialState: IInitialState = {
  isFetchingDiagnostics: false,
  isFetchingCids: false,
  isCreatingDiagnostic: false,
  isDeletingDiagnostic: false,
  diagnostics: [],
  cids: [],
  total: 0,
  page: 0,
};

const diagnosticSlice = createSlice({
  name: "diagnosticSlice",
  initialState,
  reducers: {
    setIsFetchingDiagnostics: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingDiagnostics = payload;
    },
    setIsCreatingDiagnostic: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreatingDiagnostic = payload;
    },
    setIsDeletingDiagnostic: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeletingDiagnostic = payload;
    },
    setCids: (state, { payload }: PayloadAction<ICid[]>) => {
      state.cids = payload;
    },
    setDiagnostics: (
      state,
      {
        payload: { diagnosticos, total, page },
      }: PayloadAction<{ diagnosticos: IDiagnostic[]; total: number; page: number }>
    ) => {
      state.diagnostics = diagnosticos;
      state.total = total;
      state.page = page;
    },
    setIsFetchingCids: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingCids = payload;
    },
  },
});

export const fetchCids =
  ({ nome, codigo }: { nome: string; codigo: string }): AppThunk =>
  async (dispatch, getState) => {
    const { setCids, setIsFetchingCids } = diagnosticSlice.actions;
    dispatch(setIsFetchingCids(true));
    try {
      const response = await api.get(`/api/cid?nome=${nome}&codigo=${codigo}`);
      dispatch(setCids(response.data.data));
      dispatch(setIsFetchingCids(false));
    } catch (error: any) {
      dispatch(setIsFetchingCids(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchDiagnostics =
  ({ idMedicalRecord, page = 1, limit = 6 }: { idMedicalRecord: string; page?: number; limit?: number }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingDiagnostics, setDiagnostics } = diagnosticSlice.actions;
    dispatch(setIsFetchingDiagnostics(true));
    try {
      const response = await api.get(`/api/ciddiagnostics/${idMedicalRecord}?page=${page}&limit=${limit}`);
      dispatch(setDiagnostics(response.data.data));
      dispatch(setIsFetchingDiagnostics(false));
    } catch (error: any) {
      dispatch(setIsFetchingDiagnostics(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const createDiagnostic =
  (
    data: {
      idMedicalRecord: string;
      cid: string;
      diagnostico: string;
    },
    cb?: () => void
  ): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingDiagnostic } = diagnosticSlice.actions;
    dispatch(setIsCreatingDiagnostic(true));
    try {
      const response = await api.post(`/api/ciddiagnostics`, data);
      dispatch(setIsCreatingDiagnostic(false));
      dispatch(fetchDiagnostics({ idMedicalRecord: data.idMedicalRecord }));
      if (cb) {
        cb();
      }
    } catch (error: any) {
      dispatch(setIsCreatingDiagnostic(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const updateDiagnostic =
  (data: { idMedicalRecord: string; idDiagnostic: string; cid: string; diagnostico: string }): AppThunk =>
  async (dispatch) => {
    try {
      await api.put(`/api/ciddiagnostics/${data.idMedicalRecord}/${data.idDiagnostic}`, data);
      toast.success("Diagnóstico atualizado", toastOptions);
      dispatch(fetchDiagnostics({ idMedicalRecord: data.idMedicalRecord }));
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const deleteDiagnostic =
  (data: { idMedicalRecord: string; idDiagnostic: string }): AppThunk =>
  async (dispatch) => {
    try {
      await api.delete(`/api/ciddiagnostics/${data.idMedicalRecord}/${data.idDiagnostic}`);
      toast.success("Diagnóstico removido", toastOptions);
      dispatch(fetchDiagnostics({ idMedicalRecord: data.idMedicalRecord }));
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export default diagnosticSlice.reducer;
