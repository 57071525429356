import React from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import { history } from "utils/history";
import { Router, Route, Switch } from "react-router-dom";
import Routes from "./Routes";

// * Auth
import SigninPage from "pages/auth/SigninPage";
import { QueryParamProvider } from "use-query-params";
import { Loader } from "components/Loader";

const App = () => {
  return (
    <Router history={history}>
      <QueryParamProvider ReactRouterRoute={Route}>
        <React.Suspense fallback={<Loader />}>
          <Switch>
            <Route exact path="/auth/signin" component={SigninPage} />
            <Routes />
          </Switch>
        </React.Suspense>
      </QueryParamProvider>
    </Router>
  );
};

export default App;
