// import "./wdyr";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import App from "./App";
import ThemeProvider from "themes/ThemeProvider";
import store from "store";
import "./app.css";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DayjsUtils from "@date-io/dayjs";
import ptBr from "dayjs/locale/pt-br";
import MemedProvider from "memed/providers/MemedProvider";

ReactDOM.render(
  <React.StrictMode>
    <MuiPickersUtilsProvider utils={DayjsUtils} locale={ptBr}>
      {/* <MemedProvider color="#00B8D6" scriptSrc={productionSrc} scriptId={scriptId}> */}
      <MemedProvider color="#00B8D6" scriptSrc={process.env.MEMED_SCRIPT_URL} >
        <Provider store={store}>
          {/* <StoreProvider store={easyPeasyStore}> */}
          <ThemeProvider>
            <App />
            <ToastContainer />
          </ThemeProvider>
          {/* </StoreProvider> */}
        </Provider>
      </MemedProvider>
    </MuiPickersUtilsProvider>
  </React.StrictMode>,
  document.getElementById("root")
);