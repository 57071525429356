import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AppThunk } from "store";
import api from "utils/API";
import toastOptions from "utils/toastOptions";
import { CashFlowFetchResponse, CashFlowState, ICashFlowFilter } from "./CashFlowReducer.types";
import { queryStringFromFilterArray } from "utils/network";
import { IFilter } from "models/shared";

import dayjs from "dayjs";
import { CashFlow, ICreateCashFlow } from "models/financeiro/CashFlow";

const initialState: CashFlowState = {
  cashFlows: null,
  cashFlowsExport: [],
  cashFlowsRetroactive: [],
  cashFlow: null,
  isFetchingBalance: false,
  isFetchingCashFlows: false,
  isFetchingCashFlowsExport: false,
  isFetchingCashFlowsRetroactive: false,
  initialBalanceChanged: false,
  isFetchingCashFlow: false,
  isDeletingCashFlow: false,
  isCreatingCashFlow: false,
  cashFlowFilterArray: [
    { key: "idclinica", value: null, detailed: false },
    { key: "tipo", value: null, detailed: false },
    { key: "cancelado", value: null, detailed: false },
    { key: "status", value: null, detailed: false },
    { key: "clientefornecedor", value: null, detailed: false },
    { key: "documento", value: null, detailed: true },
    { key: "criador", value: null, detailed: false },
    { key: "autorizadopor", value: null, detailed: false },
    { key: "efetivadopor", value: null, detailed: false },
    { key: "idconta", value: null, detailed: false },
    { key: "start_date", value: dayjs(new Date()).format("YYYY-MM-DD"), detailed: false },
    { key: "end_date", value: dayjs(new Date()).format("YYYY-MM-DD"), detailed: false},
    { key: "idpaciente", value: null, detailed: true },
    { key: "idprestador", value: null, detailed: true },
    { key: "idprofissional", value: null, detailed: true },
    { key: "idclientefornecedor", value: null, detailed: true },
    { key: "idmaquinapagamento", value: null, detailed: true },
    { key: "operacao", value: null, detailed: true },
    { key: "bandeira", value: null, detailed: true },
    { key: "formapagamento", value: null, detailed: true },
  ],
  isClearTableCashFlow: false,
  cashFlowFilterArrayExport: [
    { key: "idclinica", value: null },
    { key: "tipo", value: null },
    { key: "status", value: null },
    { key: "clientefornecedor", value: null },
    { key: "documento", value: null },
    { key: "criador", value: null },
    { key: "autorizadopor", value: null },
    { key: "efetivadopor", value: null },
    { key: "idconta", value: null },
    { key: "start_date", value: dayjs(new Date()).format("YYYY-MM-DD") },
    { key: "end_date", value: dayjs(new Date()).format("YYYY-MM-DD") },
  ],
  page: 1,
  total: 0,
  itemsPerPage: 6,
  initialBalance: 0,
  incomes: 0,
  outcomes: 0,
  totalBalance: 0,
  isBatchModalOpen: false,
  cashFlowRecordsSelected: [],
  isRedirectToForm: false,
  doCashFlowSearch: false,
};

const cashFlowSlice = createSlice({
  name: "cashFlow",
  initialState,
  reducers: {
    setCashFlows: (state, { payload }: PayloadAction<CashFlow[] | null>) => {
      state.cashFlows = payload;
    },
    setCashFlowsExport: (state, { payload }: PayloadAction<CashFlow[]>) => {
      state.cashFlowsExport = payload;
    },
    setCashFlowsRetroactive: (state, { payload }: PayloadAction<CashFlow[]>) => {
      state.cashFlowsRetroactive = payload;
    },
    setCashFlow: (state, { payload }: PayloadAction<CashFlow>) => {
      state.cashFlow = payload;
    },
    setIsFetchingBalance: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingBalance = payload;
    },
    setIsFetchingCashFlows: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingCashFlows = payload;
    },
    setIsFetchingCashFlowsRetroactive: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingCashFlowsRetroactive = payload;
    },
    setIsClearTableCashFlow: (state, { payload }: PayloadAction<boolean>) => {
      state.isClearTableCashFlow = payload;
    },
    setCashFlowFilterArrayExport: (
      state,
      { payload }: PayloadAction<IFilter[]>
    ) => {
      state.cashFlowFilterArrayExport = payload;
    },
    setIsFetchingCashFlowsExport: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingCashFlowsExport = payload;
    },
    setIsFetchingCashFlow: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingCashFlow = payload;
    },
    setIsDeletingCashFlow: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeletingCashFlow = payload;
    },
    setIsCreatingCashFlow: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreatingCashFlow = payload;
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setTotal: (state, { payload }: PayloadAction<number>) => {
      state.total = payload;
    },
    setItemsPerPage: (state, { payload }: PayloadAction<number>) => {
      state.itemsPerPage = payload;
    },
    updateCashFlowFilterArray: (state, { payload }: PayloadAction<ICashFlowFilter>) => {
      const index = state.cashFlowFilterArray.findIndex(
        (item) => item.key === payload.key
      );
      state.cashFlowFilterArray[index].value = payload.value;
      state.cashFlowFilterArray[index].label = payload.label;
      state.cashFlowFilterArray[index].islab = payload.isLab;
    },
    setInitialBalance: (state, { payload }: PayloadAction<number>) => {
      state.initialBalance = payload;
    },
    setIncomes: (state, { payload }: PayloadAction<number>) => {
      state.incomes = payload;
    },
    setOutcomes: (state, { payload }: PayloadAction<number>) => {
      state.outcomes = payload;
    },
    setTotalBalance: (state, { payload }: PayloadAction<number>) => {
      state.totalBalance = payload;
    },
    setInitialBalanceChanged: (state, { payload }: PayloadAction<boolean>) => {
      state.initialBalanceChanged = payload;
    },
    setIsBatchModalOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isBatchModalOpen = payload;
    },
    setCashFlowRecordsSelected: (
      state,
      { payload }: PayloadAction<string[]>
    ) => {
      state.cashFlowRecordsSelected = payload;
    },
    setIsRedirectToForm: (state, { payload }: PayloadAction<boolean>) => {
      state.isRedirectToForm = payload;
    },
    setDoCashFlowSearch: (state, { payload }: PayloadAction<boolean>) => {
      state.doCashFlowSearch = payload;
    },
  },
});

export const createCashFlow =
  (cashFlow: ICreateCashFlow, cb?: Function): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setIsCreatingCashFlow(true));
    try {
      await api.post("/api/cashflow", cashFlow);
      toast.success("Movimentação criada com sucesso");
      if(cb) cb()
    } catch (error: any) {
      console.log(error);
      if (error.response) {
        toast.error(error.response.data.message, toastOptions);
      }
    } finally {
      dispatch(setIsCreatingCashFlow(false));
    }
  };

export const fetchCashFlows =
  ({
    page = 1,
    limit = 6,
    cb,
  }: {
    page?: number;
    limit?: number;
    cb?: (cashFlowsReturn: CashFlow[]) => void;
  }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingCashFlows, setCashFlows, setPage, setTotal } =
      cashFlowSlice.actions;
    const { cashFlowFilterArray } = getState().cashFlows;

    dispatch(setIsFetchingCashFlows(true));
    const queryParameters = queryStringFromFilterArray(cashFlowFilterArray);
    const pageAndLimit =
      queryParameters.length === 0
        ? `?page=${page}&limit=${limit}`
        : `&page=${page}&limit=${limit}`;

    try {
      const { data }: CashFlowFetchResponse = await api.get(
        `/api/cashflow${queryParameters}${pageAndLimit}`
      );
      dispatch(setCashFlows(data.data.cash_flow));
      dispatch(setPage(data.data.page));
      dispatch(setTotal(data.data.total));
      //dispatch(setIncomes(data.data.balance.incomes));
      //dispatch(setOutcomes(data.data.balance.outcomes));
      //dispatch(setTotalBalance(data.data.balance.total));
      //dispatch(setInitialBalance(data.data.balance.initial));

      if (cb) {
        cb(data.data.cash_flow);
      }
    } catch (error: any) {
      console.log(error);

      if (error.response) {
        toast.error(error.response.data.error.message, toastOptions);
      }
    } finally {
      dispatch(setIsFetchingCashFlows(false));
    }
  };

export const fetchRetroactiveCashFlows =
  ({
    idclinica,
    start_date,
    end_date,
    idconta,
    cancelado,
    cb,
  }: {
    idclinica?: string;
    start_date?: string;
    end_date?: string;
    idconta?: string;
    cancelado?: boolean;
    cb?: (cashFlowsReturn: CashFlow[]) => void;
  }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingCashFlowsRetroactive, setCashFlowsRetroactive } =
      cashFlowSlice.actions;

    dispatch(setIsFetchingCashFlowsRetroactive(true));

    let queryParameters = `?idclinica=${idclinica}&start_date=${start_date}&end_date=${end_date}`

    if (idconta) {
      queryParameters += `&idconta=${idconta}`
    }

    if (typeof cancelado === "boolean") {
      queryParameters += `&cancelado=${cancelado}`
    }

    try {
      const { data }: CashFlowFetchResponse = await api.get(
        `/api/cashflow/retroactive${queryParameters}`
      );

      dispatch(setCashFlowsRetroactive(data.data.cash_flow));

      // if (cb) {
      //   cb(data.data.cash_flow);
      // }
    } catch (error: any) {
      console.log(error);

      if (error.response) {
        toast.error(error.response.data.error.message, toastOptions);
      }
    } finally {
      dispatch(setIsFetchingCashFlowsRetroactive(false));
    }
  };

export const fetchCashFlowsExport =
  (): AppThunk => async (dispatch, getState) => {
    const { setIsFetchingCashFlowsExport, setCashFlowsExport } =
      cashFlowSlice.actions;
    const { cashFlowFilterArrayExport } = getState().cashFlows;

    dispatch(setIsFetchingCashFlowsExport(true));
    const queryParameters = queryStringFromFilterArray(
      cashFlowFilterArrayExport
    );
    const pageAndLimit =
      queryParameters.length === 0
        ? `?page=${1}&limit=${999}`
        : `&page=${1}&limit=${999}`;
    try {
      const { data }: CashFlowFetchResponse = await api.get(
        `/api/cashflow${queryParameters}${pageAndLimit}`
      );
      dispatch(setCashFlowsExport(data.data.cash_flow));
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data.error.message, toastOptions);
      }
    } finally {
      dispatch(setIsFetchingCashFlowsExport(false));
    }
  };

export const fetchBalance =
  ({
    cashFlows,
    page = 1,
    limit = 6,
  }: {
    cashFlows?: boolean;
    page?: number;
    limit?: number;
  }): AppThunk =>
  async (dispatch, getState) => {
    const {
      setIsFetchingBalance,
      setIncomes,
      setOutcomes,
      setTotalBalance,
      setInitialBalance,
      setInitialBalanceChanged,
      setCashFlowFilterArrayExport,
    } = cashFlowSlice.actions;
    const { cashFlowFilterArray } = getState().cashFlows;
    dispatch(setCashFlowFilterArrayExport(cashFlowFilterArray));
    dispatch(setIsFetchingBalance(true));
    const queryParameters = queryStringFromFilterArray(cashFlowFilterArray);
    const pageAndLimit =
      queryParameters.length === 0
        ? `?page=${page}&limit=${limit}`
        : `&page=${page}&limit=${limit}`;

    try {
      const { data }: CashFlowFetchResponse = await api.get(
        `/api/cashflow/balance${queryParameters}${pageAndLimit}`
      );
      dispatch(setIncomes(data.data.balance.incomes));
      dispatch(setOutcomes(data.data.balance.outcomes));
      dispatch(setTotalBalance(data.data.balance.total));
      dispatch(setInitialBalance(data.data.balance.initial));
      dispatch(setInitialBalanceChanged(data.data.initial_balance_changed));

      if (cashFlows) {
        dispatch(fetchCashFlows({}));
      }
    } catch (error: any) {
      console.log(error);

      if (error.response) {
        toast.error(error.response.data.error.message, toastOptions);
      }
    } finally {
      dispatch(setIsFetchingBalance(false));
    }
  };

export const fetchCashFlowById =
  (idfluxocaixa: string): AppThunk =>
  async (dispatch, getState) => {
    const { setCashFlow, setIsFetchingCashFlow } = cashFlowSlice.actions;
    dispatch(setIsFetchingCashFlow(true));
    try {
      const { data } = await api.get(`/api/financialactivity/${idfluxocaixa}`);
      dispatch(setCashFlow(data.data));
    } catch (error: any) {
      console.log(error);
      if (error.response) {
        toast.error(error.response.data.message, toastOptions);
      }
    } finally {
      dispatch(setIsFetchingCashFlow(false));
    }
  };

export const cancelCashFlowRegister =
  (idfluxocaixa: string, body: any, cb?: Function): AppThunk =>
  async (dispatch, getState) => {
    const { setIsDeletingCashFlow } = cashFlowSlice.actions;
    dispatch(setIsDeletingCashFlow(true));
    try {
      await api.delete(`/api/cashflow/${idfluxocaixa}`, {
        data: body
      });
      
      toast.success("Movimentação cancelada com sucesso");
      
      if (cb) {
        cb();
      }

      dispatch(setDoCashFlowSearch(false));
      dispatch(setItemsPerPage(6));
      dispatch(fetchBalance({ cashFlows: true }));
    } catch (error: any) {
      console.log(error);
      if (error.response) {
        toast.error(error.response.data.error.message, toastOptions);
      }
    } finally {
      dispatch(setIsDeletingCashFlow(false));
    }
  };

export const {
  setIsFetchingCashFlows,
  setIsFetchingCashFlow,
  setIsDeletingCashFlow,
  setIsCreatingCashFlow,
  updateCashFlowFilterArray,
  setCashFlows,
  setCashFlowsRetroactive,
  setCashFlow,
  setPage,
  setTotal,
  setItemsPerPage,
  setInitialBalance,
  setIncomes,
  setOutcomes,
  setTotalBalance,
  setIsBatchModalOpen,
  setCashFlowRecordsSelected,
  setIsRedirectToForm,
  setDoCashFlowSearch,
  setIsClearTableCashFlow
} = cashFlowSlice.actions;

export default cashFlowSlice.reducer;
