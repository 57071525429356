import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IServiceItemPrice, IHealthPlanItemsPrice } from "models/PriceList";
import { toast } from "react-toastify";
import { AppThunk } from "store";
import api from "utils/API";
import { queryStringFromFilterArray } from "utils/network";
import toastOptions from "utils/toastOptions";

interface IFilter {
  key: string;
  value: any;
}

interface IInitialState {
  isFetchingServiceItemsPrices: boolean;
  isFetchingExportServiceItemsPrices: boolean;
  isFetchingAgreementItemsPrices: boolean,
  serviceItemsPrices: IServiceItemPrice[] | null;
  serviceExportItemsPrices: IServiceItemPrice[] | null;
  agreementItemsPrices: IHealthPlanItemsPrice[] | null;
  serviceItemsPricesFilter: IFilter[];
  agreementItemsPricesFilter: IFilter[];
  total: number;
  page: number;
}

const initialState: IInitialState = {
  isFetchingServiceItemsPrices: false,
  isFetchingExportServiceItemsPrices: false,
  isFetchingAgreementItemsPrices: false,
  serviceItemsPrices: null,
  serviceExportItemsPrices: null,
  agreementItemsPrices:null,
  total: 0,
  page: 0,
  agreementItemsPricesFilter: [
    { key: "item", value: null },
    { key: "minvalue", value: null },
    { key: "maxvalue", value: null },
    { key: "specialty", value: [] },
    { key: "status", value: null },
    { key: "idconvenio", value: [] }
  ],
  serviceItemsPricesFilter: [
    { key: "item", value: null },
    { key: "minvalue", value: null },
    { key: "maxvalue", value: null },
    { key: "specialty", value: [] },
    { key: "status", value: null },
  ],
};

const priceListSlice = createSlice({
  name: "priceListSlice",
  initialState,
  reducers: {
    setIsFetchingServiceItemsPrices: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingServiceItemsPrices = payload;
    },
    setIsFetchingExportServiceItemsPrices: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingExportServiceItemsPrices = payload;
    },
    setIsFetchingAgreementItemsPrices: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingAgreementItemsPrices = payload;
    },
    setExportServiceItemsPricesList: (
      state,
      {
        payload: { items_qt, items_extract, page },
      }: PayloadAction<{
        items_qt: number;
        items_extract: IServiceItemPrice[] | null;
        page: number;
      }>
    ) => {
      state.serviceExportItemsPrices = items_extract;
    },
    setServiceItemsPricesList: (
      state,
      {
        payload: { items_qt, items_extract, page },
      }: PayloadAction<{
        items_qt: number;
        items_extract: IServiceItemPrice[] | null;
        page: number;
      }>
    ) => {
      state.serviceItemsPrices = items_extract;
      state.total = items_qt;
      state.page = page;
    },
    setAgreementItemsList: (
      state,
      {
        payload: { items_extract },
      }: PayloadAction<{
        // items_qt: number;
        items_extract: IHealthPlanItemsPrice[] | null;
        // page: number;
      }>
    ) => {
      state.agreementItemsPrices = items_extract;
      // state.total = items_qt;
      // state.page = page;
    },
    updateServiceItemsFilter: (state, { payload:{ key, value } }: PayloadAction<IFilter>) => {
      state.serviceItemsPricesFilter = state.serviceItemsPricesFilter.map(
        (filter) => {
          if (filter.key === key) {
            filter.value = value;
          }
          return filter;
        }
      );
    },
    updateAgreementItemsFilter: (state, { payload:{ key, value } }: PayloadAction<IFilter>) => {
      state.agreementItemsPricesFilter = state.agreementItemsPricesFilter.map(
        (filter) => {
          if (filter.key === key) {
            filter.value = value;
          }
          return filter;
        }
      );
    },
    clearServiceItemsFilter: (state) => {
      state.serviceItemsPricesFilter = [
        { key: "item", value: null },
        { key: "minvalue", value: null },
        { key: "maxvalue", value: null },
        { key: "specialty", value: [] },
        { key: "status", value: null },
      ];
    },
    clearAgreementItemsFilter: (state) => {
      state.agreementItemsPricesFilter = [
        { key: "item", value: null },
        { key: "minvalue", value: null },
        { key: "maxvalue", value: null },
        { key: "specialty", value: [] },
        { key: "status", value: null },
        { key: "idconvenio", value: [] }
      ];
    },
  },
});

export const fetchExportServiceItemsPrices =
  ({ page = 1, limit = 6 }: { page?: number; limit?: number }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingExportServiceItemsPrices, setExportServiceItemsPricesList} =
      priceListSlice.actions;
    dispatch(setIsFetchingExportServiceItemsPrices(true));
    try {
      const state = getState();
      const { serviceItemsPricesFilter } = state.priceList;
      console.log(serviceItemsPricesFilter, "serviceItemsPricesFilter")
      const queryParameters = queryStringFromFilterArray(
        serviceItemsPricesFilter
      );
      const pageAndLimit =
        queryParameters.length === 0
          ? `?page=${page}&limit=${limit}`
          : `&page=${page}&limit=${limit}`;
      const response = await api.get(
        `/api/price-list/service-items${queryParameters}${pageAndLimit}`
      );
      dispatch(setExportServiceItemsPricesList(response.data.data));
      dispatch(setIsFetchingExportServiceItemsPrices(false));
    } catch (error: any) {
      dispatch(setIsFetchingExportServiceItemsPrices(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };





export const fetchServiceItemsPrices =
  ({ page = 1, limit = 6 }: { page?: number; limit?: number }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingServiceItemsPrices, setServiceItemsPricesList} =
      priceListSlice.actions;
    dispatch(setIsFetchingServiceItemsPrices(true));
    try {
      const state = getState();
      const { serviceItemsPricesFilter } = state.priceList;
      console.log(serviceItemsPricesFilter, "serviceItemsPricesFilter")
      const queryParameters = queryStringFromFilterArray(
        serviceItemsPricesFilter
      );
      const pageAndLimit =
        queryParameters.length === 0
          ? `?page=${page}&limit=${limit}`
          : `&page=${page}&limit=${limit}`;
      const response = await api.get(
        `/api/price-list/service-items${queryParameters}${pageAndLimit}`
      );
      dispatch(setServiceItemsPricesList(response.data.data));
      dispatch(setIsFetchingServiceItemsPrices(false));
    } catch (error: any) {
      dispatch(setIsFetchingServiceItemsPrices(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchAgreementItemsPrices = ({ page = 1, limit = 6 }: { page?: number; limit?: number }):AppThunk =>
    async (dispatch, getState) => {
      const { setIsFetchingAgreementItemsPrices, setAgreementItemsList} =
      priceListSlice.actions;
    dispatch(setIsFetchingAgreementItemsPrices(true));
    try {
      const state = getState();
      const { agreementItemsPricesFilter } = state.priceList;
      const queryParameters = queryStringFromFilterArray(
        agreementItemsPricesFilter
      );
      const pageAndLimit =
        queryParameters.length === 0
          ? `?page=${page}&limit=${limit}`
          : `&page=${page}&limit=${limit}`;
      const response = await api.get(
        `/api/price-list/health-plan-items${queryParameters}${pageAndLimit}`
      );
      dispatch(setAgreementItemsList({items_extract: response.data.data}));
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setIsFetchingAgreementItemsPrices(false));
    }
  }

export const { updateServiceItemsFilter, clearServiceItemsFilter, setServiceItemsPricesList, updateAgreementItemsFilter, clearAgreementItemsFilter, setAgreementItemsList } =
  priceListSlice.actions;

export default priceListSlice.reducer;
