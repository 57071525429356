import { IMedicalRecord, IEvolucao, IPaciente, IConsulta, ICurrentMedicalRecord, IPrescriptionForm } from "models/MedicalRecord";
import { IPatient, IPatientForm } from "models/Patients";
import { object } from "yup";

export interface IPatientMedicalRecordBasicInfo {
  numerocartao: number;
  name: string;
  peso: string;
  altura: string;
  cpf: string;
  sexo: string;
}

export const initialState: IPatientMedicalRecordBasicInfo = {
  numerocartao: 1,
  name: "",
  peso: "",
  altura: "",
  cpf: "",
  sexo: "masculino",
};

export const initialPatientState: IMedicalRecord = {

  idMedicalRecord: "",
  idclinica: "",
  idconsulta: "",
  idmedico: "",
  idpaciente: "",
  sigiloso: false,
  paciente: null,
  evolucao: null,
  consulta: null,
  datafinalizado: ""


}

export const patientInitialState: IPatient = {
  nomepaciente: "",
  sexo: "",
  datanascimento: "",
  cpf: "",
  rg: "",
  siglaestado: "",
  nomecidade: "",
  logradouro: "",
  numero: "",
  complemento: "",
  bairro: "",
  cep: "",
  email: "",
  telefonecelular: "",
  telefoneresidencial: "",
  telefonecomercial: "",
  descricaopacientefonte: "",
  identificador: "",
  datacadastro: "",
  idpaciente: "",
  codigo: "",
  canalatendimento: {
    descricao: "",
    nome: ""
  },
  idcanalorigem: "",
  canalorigem: {
    descricao: "",
    nome: ""
  },
  idcanalatendimento: "",
  ativo: true
};

export const initialCurretMedicalRecordState: ICurrentMedicalRecord = {

  medicalRecords: [
    {
      idMedicalRecord: "",
      idclinica: "",
      idconsulta: "",
      idmedico: "",
      idpaciente: "",
      sigiloso: false,
      paciente: null,
      evolucao: null,
      consulta: null,
      datafinalizado: ""
    }
  ]
}

export const initialPrescriptionState: IPrescriptionForm = {
  idMedicalRecord: "",
  medicamentos: "",
  posologia: "",
  quantidade: 0,
  unidadeMedida: "Unidade",
  uso: "Oral",
  prescricaomanual: "",
  usorestrito: false,
}
