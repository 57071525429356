import styled from "styled-components";
import { Card } from "@material-ui/core";

interface IColoredCardProps {
  backgroundcolorprop: string;
  unavailable: boolean;
}

export const ColoredCard = styled(Card)<IColoredCardProps>`
  background: ${({ backgroundcolorprop }) => backgroundcolorprop};
  cursor: ${({ unavailable }) => (unavailable ? "not-allowed" : "pointer")};
  position: relative;
`;

export const BoldText = styled.span`
  font-weight: bold;
`;
