import {
  AnyAction,
  createSlice,
  PayloadAction,
  ThunkAction,
} from "@reduxjs/toolkit";
import { IPaymentMethod } from "models/PaymentMethod";
import { IServiceGroup } from "models/services/groups";
import { toast } from "react-toastify";
import { rootState } from "store";
import api from "utils/API";
import toastOptions from "utils/toastOptions";

export interface IAppointmentWebSetup {
  modules: Modules;
  palette: Palette;
  configs: Configs;
}

export interface IAppointmentWebSetupForm {
  convenio: boolean;
  combo: boolean;
  pagamentoonline: boolean;
  parcelamento: boolean;
  checkin: boolean;
  sms: boolean;
  cor_primaria?: string;
  cor_secundaria?: string;
  cor_terciaria?: string;
  cor_background?: string;
  texto_titulo?: string;
  texto_subtitulo?: string;
  texto_secao_agendamento?: string;
  text_botao_redirecionar_agendamento?: string;
  url_imagem_fundo?: string;
  texto_footer?: string;
  url_logo?: string;
  url_site_empresa?: string;
  text_card_agendamento?: string;
  url_imagem_card_agendamento?: string;
}

export interface IAppointmentWebSetupInstallments {
  idparcelamentoagendamentoonline: string;
  idsetup: string;
  setup: IAppointmentWebSetup;
  idgruposervico: string;
  gruposervico: IServiceGroup;
  idmetodopagamento: string;
  metodopagamento: IPaymentMethod;
  regrasparcelamento?: {
    valorminimo: number;
    parcela?: number;
  }[];
}

export interface IAppointmentWebSetupInstallmentsForm {
  idgruposervico: string;
  idmetodopagamento: string;
  regrasparcelamento?: IAppointmentWebSetupInstallmentsRoles[];
}

export interface IAppointmentWebSetupCheckinForm {
  idclinica: string;
  url: string;
}

export interface IAppointmentWebSetupInstallmentsRoles {
  valorminimo: number;
  parcela: number;
}

export interface Modules {
  convenio: boolean;
  checkin: boolean;
  combo: boolean;
  sms: boolean;
  pagamentoonline: boolean;
  parcelamento: boolean;
  regrasparcelamento: IInstallment[] | null;
  regrascheckin?: ICheckinRule[] | null;
  regrassms?: ISmsRule[] | null;
}

export interface ICheckinRule {
  url: string;
  idclinica?: string;
}

export interface ISmsRule {
  value: string;
  idclinica: string;
  type: SmsType;
  greeting: string;
  minutes: MINUTES_NOTIFY;
}

export enum SmsType {
  CONFIRMATION='confirmation',
  REMEMBER='remember',
}

export enum MINUTES_NOTIFY {
  ONE_HOUR='ONE_HOUR',
  TWO_HOUR='TWO_HOUR',
  ONE_DAY='ONE_DAY',
  TWO_DAY='TWO_DAY'
}

export interface IInstallment {
  idgruposervico: string;
  idmetodopagamento: string;
  regrasparcelamento?: IAppointmentWebSetupInstallmentsRoles[];
  metodopagamento: IPaymentMethod;
  gruposervico: IServiceGroup;
}

export interface Palette {
  primary?: string;
  secondary?: string;
  tertiary?: string;
  background?: string;
}

export interface Configs {
  home: Home;
  general: General;
  appointment: Appointment;
}

export interface Home {
  title?: string;
  subtitle?: string;
  text_appointment_card?: string;
  text_appointment_button?: string;
  url_background_image?: string;
}

export interface General {
  text_footer?: string;
  url_logo?: string;
  url_site?: string;
}

export interface Appointment {
  text_appointment_card?: string;
  url_image_card?: string;
}

interface IInitialState {
  isFetching: boolean;
  isCreating: boolean;
  isCreatingRole: boolean;
  currentAppointmentWebSetup: IAppointmentWebSetup | null;
}

const initialState: IInitialState = {
  currentAppointmentWebSetup: null,
  isCreating: false,
  isFetching: false,
  isCreatingRole: false,
};

const appointmentWebSetupSlice = createSlice({
  name: "appointmentWebSetup",
  initialState,
  reducers: {
    setIsFetching: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetching = payload;
    },
    setIsCreating: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreating = payload;
    },
    setIsCreatingRole: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreatingRole = payload;
    },
    setCurrentAppointmentWebSetup: (
      state,
      { payload }: PayloadAction<IAppointmentWebSetup | null>
    ) => {
      state.currentAppointmentWebSetup = payload;
    },
  },
});

export const fetchAppointmentWebSetup =
  (): ThunkAction<void, rootState, unknown, AnyAction> => async (dispatch) => {
    const { setIsFetching, setCurrentAppointmentWebSetup } =
      appointmentWebSetupSlice.actions;
    dispatch(setIsFetching(true));
    try {
      const response = await api.get(`/api/users-patients/setup`);
      dispatch(setCurrentAppointmentWebSetup(response.data.data));
      dispatch(setIsFetching(false));
    } catch (error: any) {
      dispatch(setIsFetching(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const updateAppointmentWebSetup =
  ({
    appointmentWebSetupForm,
  }: {
    appointmentWebSetupForm: IAppointmentWebSetupForm;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsCreating } = appointmentWebSetupSlice.actions;
    dispatch(setIsCreating(true));
    try {
      await api.put(`/api/users-patients/setup`, {
        ...appointmentWebSetupForm,
      });
      toast.success(
        "Configurações para o agendamento online atualizadas",
        toastOptions
      );
      dispatch(setIsCreating(false));
      dispatch(fetchAppointmentWebSetup());
    } catch (error: any) {
      dispatch(setIsCreating(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const createInstallmentRolesAppointmentWebSetup =
  ({
    appointmentWebRoleForm,
  }: {
    appointmentWebRoleForm: IAppointmentWebSetupInstallmentsForm;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsCreatingRole } = appointmentWebSetupSlice.actions;
    dispatch(setIsCreatingRole(true));
    try {
      await api.post(`/api/users-patients/setup/installment-roles`, {
        ...appointmentWebRoleForm,
      });
      toast.success("Regra de parcelamento criada com sucesso", toastOptions);
      dispatch(fetchAppointmentWebSetup());
      dispatch(setIsCreatingRole(false));
    } catch (error: any) {
      dispatch(setIsCreatingRole(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };
export const createCheckinRolesAppointmentWebSetup =
  ({
    data,
  }: {
    data: ICheckinRule;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsCreatingRole } = appointmentWebSetupSlice.actions;
    dispatch(setIsCreatingRole(true));
    try {
      await api.post(`/api/users-patients/setup/checkin-roles`, {
        ...data,
      });
      toast.success("QR Code criado com sucesso", toastOptions);
      dispatch(fetchAppointmentWebSetup());
      dispatch(setIsCreatingRole(false));
    } catch (error: any) {
      dispatch(setIsCreatingRole(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };
export const createSmsRolesAppointmentWebSetup =
  ({
    data,
  }: {
    data: ISmsRule;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsCreatingRole } = appointmentWebSetupSlice.actions;
    dispatch(setIsCreatingRole(true));
    try {
      await api.post(`/api/users-patients/setup/sms-roles`, {
        ...data,
      });
      toast.success("Mensagem criado com sucesso", toastOptions);
      dispatch(fetchAppointmentWebSetup());
      dispatch(setIsCreatingRole(false));
    } catch (error: any) {
      dispatch(setIsCreatingRole(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export default appointmentWebSetupSlice.reducer;
