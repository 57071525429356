export const cityOptions = [
  {
    codigo_do_IGBE: 3100104,
    UF: "MG",
    nome_da_cidade: "ABADIA DOS DOURADOS",
  },
  {
    codigo_do_IGBE: 5200100,
    UF: "GO",
    nome_da_cidade: "ABADIANIA",
  },
  {
    codigo_do_IGBE: 3100203,
    UF: "MG",
    nome_da_cidade: "ABAETE",
  },
  {
    codigo_do_IGBE: 1500107,
    UF: "PA",
    nome_da_cidade: "ABAETETUBA",
  },
  {
    codigo_do_IGBE: 2300101,
    UF: "CE",
    nome_da_cidade: "ABAIARA",
  },
  {
    codigo_do_IGBE: 2900108,
    UF: "BA",
    nome_da_cidade: "ABAIRA",
  },
  {
    codigo_do_IGBE: 2900207,
    UF: "BA",
    nome_da_cidade: "ABARE",
  },
  {
    codigo_do_IGBE: 4100103,
    UF: "PR",
    nome_da_cidade: "ABATIA",
  },
  {
    codigo_do_IGBE: 1500131,
    UF: "PA",
    nome_da_cidade: "ABEL FIGUEIREDO",
  },
  {
    codigo_do_IGBE: 4200101,
    UF: "SC",
    nome_da_cidade: "ABELARDO LUZ",
  },
  {
    codigo_do_IGBE: 3100302,
    UF: "MG",
    nome_da_cidade: "ABRE CAMPO",
  },
  {
    codigo_do_IGBE: 2600054,
    UF: "PE",
    nome_da_cidade: "ABREU E LIMA",
  },
  {
    codigo_do_IGBE: 1700251,
    UF: "TO",
    nome_da_cidade: "ABREULANDIA",
  },
  {
    codigo_do_IGBE: 3100401,
    UF: "MG",
    nome_da_cidade: "ACAIACA",
  },
  {
    codigo_do_IGBE: 2900306,
    UF: "BA",
    nome_da_cidade: "ACAJUTIBA",
  },
  {
    codigo_do_IGBE: 1500206,
    UF: "PA",
    nome_da_cidade: "ACARA",
  },
  {
    codigo_do_IGBE: 2300150,
    UF: "CE",
    nome_da_cidade: "ACARAPE",
  },
  {
    codigo_do_IGBE: 2300200,
    UF: "CE",
    nome_da_cidade: "ACARAU",
  },
  {
    codigo_do_IGBE: 2400109,
    UF: "RN",
    nome_da_cidade: "ACARI",
  },
  {
    codigo_do_IGBE: 2200053,
    UF: "PI",
    nome_da_cidade: "ACAUA",
  },
  {
    codigo_do_IGBE: 4300034,
    UF: "RS",
    nome_da_cidade: "ACEGUA",
  },
  {
    codigo_do_IGBE: 2300309,
    UF: "CE",
    nome_da_cidade: "ACOPIARA",
  },
  {
    codigo_do_IGBE: 5100102,
    UF: "MT",
    nome_da_cidade: "ACORIZAL",
  },
  {
    codigo_do_IGBE: 5200134,
    UF: "GO",
    nome_da_cidade: "ACREUNA",
  },
  {
    codigo_do_IGBE: 2400208,
    UF: "RN",
    nome_da_cidade: "ACU",
  },
  {
    codigo_do_IGBE: 3500105,
    UF: "SP",
    nome_da_cidade: "ADAMANTINA",
  },
  {
    codigo_do_IGBE: 5200159,
    UF: "GO",
    nome_da_cidade: "ADELANDIA",
  },
  {
    codigo_do_IGBE: 3500204,
    UF: "SP",
    nome_da_cidade: "ADOLFO",
  },
  {
    codigo_do_IGBE: 4100202,
    UF: "PR",
    nome_da_cidade: "ADRIANOPOLIS",
  },
  {
    codigo_do_IGBE: 2900355,
    UF: "BA",
    nome_da_cidade: "ADUSTINA",
  },
  {
    codigo_do_IGBE: 2600104,
    UF: "PE",
    nome_da_cidade: "AFOGADOS DA INGAZEIRA",
  },
  {
    codigo_do_IGBE: 2400307,
    UF: "RN",
    nome_da_cidade: "AFONSO BEZERRA",
  },
  {
    codigo_do_IGBE: 3200102,
    UF: "ES",
    nome_da_cidade: "AFONSO CLAUDIO",
  },
  {
    codigo_do_IGBE: 2100105,
    UF: "MA",
    nome_da_cidade: "AFONSO CUNHA",
  },
  {
    codigo_do_IGBE: 2600203,
    UF: "PE",
    nome_da_cidade: "AFRANIO",
  },
  {
    codigo_do_IGBE: 1500305,
    UF: "PA",
    nome_da_cidade: "AFUA",
  },
  {
    codigo_do_IGBE: 4200200,
    UF: "SC",
    nome_da_cidade: "AGROLANDIA",
  },
  {
    codigo_do_IGBE: 4200309,
    UF: "SC",
    nome_da_cidade: "AGRONOMICA",
  },
  {
    codigo_do_IGBE: 1500347,
    UF: "PA",
    nome_da_cidade: "AGUA AZUL DO NORTE",
  },
  {
    codigo_do_IGBE: 5100201,
    UF: "MT",
    nome_da_cidade: "AGUA BOA",
  },
  {
    codigo_do_IGBE: 2700102,
    UF: "AL",
    nome_da_cidade: "AGUA BRANCA",
  },
  {
    codigo_do_IGBE: 2500106,
    UF: "PB",
    nome_da_cidade: "AGUA BRANCA",
  },
  {
    codigo_do_IGBE: 2200202,
    UF: "PI",
    nome_da_cidade: "AGUA BRANCA",
  },
  {
    codigo_do_IGBE: 5000203,
    UF: "MS",
    nome_da_cidade: "AGUA CLARA",
  },
  {
    codigo_do_IGBE: 3100708,
    UF: "MG",
    nome_da_cidade: "AGUA COMPRIDA",
  },
  {
    codigo_do_IGBE: 4200408,
    UF: "SC",
    nome_da_cidade: "AGUA DOCE",
  },
  {
    codigo_do_IGBE: 2900405,
    UF: "BA",
    nome_da_cidade: "AGUA FRIA",
  },
  {
    codigo_do_IGBE: 5200175,
    UF: "GO",
    nome_da_cidade: "AGUA FRIA DE GOIAS",
  },
  {
    codigo_do_IGBE: 5200209,
    UF: "GO",
    nome_da_cidade: "AGUA LIMPA",
  },
  {
    codigo_do_IGBE: 2400406,
    UF: "RN",
    nome_da_cidade: "AGUA NOVA",
  },
  {
    codigo_do_IGBE: 2600401,
    UF: "PE",
    nome_da_cidade: "AGUA PRETA",
  },
  {
    codigo_do_IGBE: 4300059,
    UF: "RS",
    nome_da_cidade: "AGUA SANTA",
  },
  {
    codigo_do_IGBE: 3500303,
    UF: "SP",
    nome_da_cidade: "AGUAI",
  },
  {
    codigo_do_IGBE: 3100807,
    UF: "MG",
    nome_da_cidade: "AGUANIL",
  },
  {
    codigo_do_IGBE: 2600500,
    UF: "PE",
    nome_da_cidade: "AGUAS BELAS",
  },
  {
    codigo_do_IGBE: 3500402,
    UF: "SP",
    nome_da_cidade: "AGUAS DA PRATA",
  },
  {
    codigo_do_IGBE: 4200507,
    UF: "SC",
    nome_da_cidade: "AGUAS DE CHAPECO",
  },
  {
    codigo_do_IGBE: 3500501,
    UF: "SP",
    nome_da_cidade: "AGUAS DE LINDOIA",
  },
  {
    codigo_do_IGBE: 3500600,
    UF: "SP",
    nome_da_cidade: "AGUAS DE SAO PEDRO",
  },
  {
    codigo_do_IGBE: 3100906,
    UF: "MG",
    nome_da_cidade: "AGUAS FORMOSAS",
  },
  {
    codigo_do_IGBE: 4200556,
    UF: "SC",
    nome_da_cidade: "AGUAS FRIAS",
  },
  {
    codigo_do_IGBE: 4200606,
    UF: "SC",
    nome_da_cidade: "AGUAS MORNAS",
  },
  {
    codigo_do_IGBE: 3101003,
    UF: "MG",
    nome_da_cidade: "AGUAS VERMELHAS",
  },
  {
    codigo_do_IGBE: 4300109,
    UF: "RS",
    nome_da_cidade: "AGUDO",
  },
  {
    codigo_do_IGBE: 3500709,
    UF: "SP",
    nome_da_cidade: "AGUDOS",
  },
  {
    codigo_do_IGBE: 4100301,
    UF: "PR",
    nome_da_cidade: "AGUDOS DO SUL",
  },
  {
    codigo_do_IGBE: 2200251,
    UF: "PI",
    nome_da_cidade: "ALAGOINHA DO PIAUI",
  },
  {
    codigo_do_IGBE: 2900702,
    UF: "BA",
    nome_da_cidade: "ALAGOINHAS",
  },
  {
    codigo_do_IGBE: 3500758,
    UF: "SP",
    nome_da_cidade: "ALAMBARI",
  },
  {
    codigo_do_IGBE: 3101409,
    UF: "MG",
    nome_da_cidade: "ALBERTINA",
  },
  {
    codigo_do_IGBE: 2100204,
    UF: "MA",
    nome_da_cidade: "ALCANTARA",
  },
  {
    codigo_do_IGBE: 2300507,
    UF: "CE",
    nome_da_cidade: "ALCANTARAS",
  },
  {
    codigo_do_IGBE: 2500536,
    UF: "PB",
    nome_da_cidade: "ALCANTIL",
  },
  {
    codigo_do_IGBE: 5000252,
    UF: "MS",
    nome_da_cidade: "ALCINOPOLIS",
  },
  {
    codigo_do_IGBE: 2900801,
    UF: "BA",
    nome_da_cidade: "ALCOBACA",
  },
  {
    codigo_do_IGBE: 2100303,
    UF: "MA",
    nome_da_cidade: "ALDEIAS ALTAS",
  },
  {
    codigo_do_IGBE: 4300307,
    UF: "RS",
    nome_da_cidade: "ALECRIM",
  },
  {
    codigo_do_IGBE: 3200201,
    UF: "ES",
    nome_da_cidade: "ALEGRE",
  },
  {
    codigo_do_IGBE: 2200277,
    UF: "PI",
    nome_da_cidade: "ALEGRETE DO PIAUI",
  },
  {
    codigo_do_IGBE: 4300455,
    UF: "RS",
    nome_da_cidade: "ALEGRIA",
  },
  {
    codigo_do_IGBE: 3101508,
    UF: "MG",
    nome_da_cidade: "ALEM PARAIBA",
  },
  {
    codigo_do_IGBE: 1500404,
    UF: "PA",
    nome_da_cidade: "ALENQUER",
  },
  {
    codigo_do_IGBE: 2400505,
    UF: "RN",
    nome_da_cidade: "ALEXANDRIA",
  },
  {
    codigo_do_IGBE: 5200308,
    UF: "GO",
    nome_da_cidade: "ALEXANIA",
  },
  {
    codigo_do_IGBE: 3101607,
    UF: "MG",
    nome_da_cidade: "ALFENAS",
  },
  {
    codigo_do_IGBE: 3200300,
    UF: "ES",
    nome_da_cidade: "ALFREDO CHAVES",
  },
  {
    codigo_do_IGBE: 3500808,
    UF: "SP",
    nome_da_cidade: "ALFREDO MARCONDES",
  },
  {
    codigo_do_IGBE: 3101631,
    UF: "MG",
    nome_da_cidade: "ALFREDO VASCONCELOS",
  },
  {
    codigo_do_IGBE: 2500577,
    UF: "PB",
    nome_da_cidade: "ALGODAO DE JANDAIRA",
  },
  {
    codigo_do_IGBE: 2500601,
    UF: "PB",
    nome_da_cidade: "ALHANDRA",
  },
  {
    codigo_do_IGBE: 2600708,
    UF: "PE",
    nome_da_cidade: "ALIANCA",
  },
  {
    codigo_do_IGBE: 1700350,
    UF: "TO",
    nome_da_cidade: "ALIANCA DO TOCANTINS",
  },
  {
    codigo_do_IGBE: 2900900,
    UF: "BA",
    nome_da_cidade: "ALMADINA",
  },
  {
    codigo_do_IGBE: 1700400,
    UF: "TO",
    nome_da_cidade: "ALMAS",
  },
  {
    codigo_do_IGBE: 1500503,
    UF: "PA",
    nome_da_cidade: "ALMEIRIM",
  },
  {
    codigo_do_IGBE: 3101706,
    UF: "MG",
    nome_da_cidade: "ALMENARA",
  },
  {
    codigo_do_IGBE: 2400604,
    UF: "RN",
    nome_da_cidade: "ALMINO AFONSO",
  },
  {
    codigo_do_IGBE: 4100400,
    UF: "PR",
    nome_da_cidade: "ALMIRANTE TAMANDARE",
  },
  {
    codigo_do_IGBE: 4300471,
    UF: "RS",
    nome_da_cidade: "ALMIRANTE TAMANDARE DO SUL",
  },
  {
    codigo_do_IGBE: 5200506,
    UF: "GO",
    nome_da_cidade: "ALOANDIA",
  },
  {
    codigo_do_IGBE: 3101805,
    UF: "MG",
    nome_da_cidade: "ALPERCATA",
  },
  {
    codigo_do_IGBE: 4300505,
    UF: "RS",
    nome_da_cidade: "ALPESTRE",
  },
  {
    codigo_do_IGBE: 3101904,
    UF: "MG",
    nome_da_cidade: "ALPINOPOLIS",
  },
  {
    codigo_do_IGBE: 1100015,
    UF: "RO",
    nome_da_cidade: "ALTA FLORESTA D'OESTE",
  },
  {
    codigo_do_IGBE: 3500907,
    UF: "SP",
    nome_da_cidade: "ALTAIR",
  },
  {
    codigo_do_IGBE: 1500602,
    UF: "PA",
    nome_da_cidade: "ALTAMIRA",
  },
  {
    codigo_do_IGBE: 2100402,
    UF: "MA",
    nome_da_cidade: "ALTAMIRA DO MARANHAO",
  },
  {
    codigo_do_IGBE: 4100459,
    UF: "PR",
    nome_da_cidade: "ALTAMIRA DO PARANA",
  },
  {
    codigo_do_IGBE: 2300606,
    UF: "CE",
    nome_da_cidade: "ALTANEIRA",
  },
  {
    codigo_do_IGBE: 3102001,
    UF: "MG",
    nome_da_cidade: "ALTEROSA",
  },
  {
    codigo_do_IGBE: 2600807,
    UF: "PE",
    nome_da_cidade: "ALTINHO",
  },
  {
    codigo_do_IGBE: 3501004,
    UF: "SP",
    nome_da_cidade: "ALTINOPOLIS",
  },
  {
    codigo_do_IGBE: 1400050,
    UF: "RR",
    nome_da_cidade: "ALTO ALEGRE",
  },
  {
    codigo_do_IGBE: 4300554,
    UF: "RS",
    nome_da_cidade: "ALTO ALEGRE",
  },
  {
    codigo_do_IGBE: 3501103,
    UF: "SP",
    nome_da_cidade: "ALTO ALEGRE",
  },
  {
    codigo_do_IGBE: 2100477,
    UF: "MA",
    nome_da_cidade: "ALTO ALEGRE DO PINDARE",
  },
  {
    codigo_do_IGBE: 1100379,
    UF: "RO",
    nome_da_cidade: "ALTO ALEGRE DOS PARECIS",
  },
  {
    codigo_do_IGBE: 5100300,
    UF: "MT",
    nome_da_cidade: "ALTO ARAGUAIA",
  },
  {
    codigo_do_IGBE: 4200754,
    UF: "SC",
    nome_da_cidade: "ALTO BELA VISTA",
  },
  {
    codigo_do_IGBE: 3102050,
    UF: "MG",
    nome_da_cidade: "ALTO CAPARAO",
  },
  {
    codigo_do_IGBE: 4300570,
    UF: "RS",
    nome_da_cidade: "ALTO FELIZ",
  },
  {
    codigo_do_IGBE: 5100409,
    UF: "MT",
    nome_da_cidade: "ALTO GARCAS",
  },
  {
    codigo_do_IGBE: 5200555,
    UF: "GO",
    nome_da_cidade: "ALTO HORIZONTE",
  },
  {
    codigo_do_IGBE: 3153509,
    UF: "MG",
    nome_da_cidade: "ALTO JEQUITIBA",
  },
  {
    codigo_do_IGBE: 2200301,
    UF: "PI",
    nome_da_cidade: "ALTO LONGA",
  },
  {
    codigo_do_IGBE: 5100508,
    UF: "MT",
    nome_da_cidade: "ALTO PARAGUAI",
  },
  {
    codigo_do_IGBE: 4128625,
    UF: "PR",
    nome_da_cidade: "ALTO PARAISO",
  },
  {
    codigo_do_IGBE: 1100403,
    UF: "RO",
    nome_da_cidade: "ALTO PARAISO",
  },
  {
    codigo_do_IGBE: 5200605,
    UF: "GO",
    nome_da_cidade: "ALTO PARAISO DE GOIAS",
  },
  {
    codigo_do_IGBE: 4100608,
    UF: "PR",
    nome_da_cidade: "ALTO PARANA",
  },
  {
    codigo_do_IGBE: 2100501,
    UF: "MA",
    nome_da_cidade: "ALTO PARNAIBA",
  },
  {
    codigo_do_IGBE: 3200359,
    UF: "ES",
    nome_da_cidade: "ALTO RIO NOVO",
  },
  {
    codigo_do_IGBE: 2300705,
    UF: "CE",
    nome_da_cidade: "ALTO SANTO",
  },
  {
    codigo_do_IGBE: 5100607,
    UF: "MT",
    nome_da_cidade: "ALTO TAQUARI",
  },
  {
    codigo_do_IGBE: 2200400,
    UF: "PI",
    nome_da_cidade: "ALTOS",
  },
  {
    codigo_do_IGBE: 3501152,
    UF: "SP",
    nome_da_cidade: "ALUMINIO",
  },
  {
    codigo_do_IGBE: 1300029,
    UF: "AM",
    nome_da_cidade: "ALVARAES",
  },
  {
    codigo_do_IGBE: 3102209,
    UF: "MG",
    nome_da_cidade: "ALVARENGA",
  },
  {
    codigo_do_IGBE: 3501202,
    UF: "SP",
    nome_da_cidade: "ALVARES FLORENCE",
  },
  {
    codigo_do_IGBE: 3501301,
    UF: "SP",
    nome_da_cidade: "ALVARES MACHADO",
  },
  {
    codigo_do_IGBE: 3501400,
    UF: "SP",
    nome_da_cidade: "ALVARO DE CARVALHO",
  },
  {
    codigo_do_IGBE: 3501509,
    UF: "SP",
    nome_da_cidade: "ALVINLANDIA",
  },
  {
    codigo_do_IGBE: 3102308,
    UF: "MG",
    nome_da_cidade: "ALVINOPOLIS",
  },
  {
    codigo_do_IGBE: 4300604,
    UF: "RS",
    nome_da_cidade: "ALVORADA",
  },
  {
    codigo_do_IGBE: 1700707,
    UF: "TO",
    nome_da_cidade: "ALVORADA",
  },
  {
    codigo_do_IGBE: 5200803,
    UF: "GO",
    nome_da_cidade: "ALVORADA DO NORTE",
  },
  {
    codigo_do_IGBE: 4100806,
    UF: "PR",
    nome_da_cidade: "ALVORADA DO SUL",
  },
  {
    codigo_do_IGBE: 1100346,
    UF: "RO",
    nome_da_cidade: "ALVORADA D'OESTE",
  },
  {
    codigo_do_IGBE: 1400027,
    UF: "RR",
    nome_da_cidade: "AMAJARI",
  },
  {
    codigo_do_IGBE: 5000609,
    UF: "MS",
    nome_da_cidade: "AMAMBAI",
  },
  {
    codigo_do_IGBE: 1600105,
    UF: "AP",
    nome_da_cidade: "AMAPA",
  },
  {
    codigo_do_IGBE: 2100550,
    UF: "MA",
    nome_da_cidade: "AMAPA DO MARANHAO",
  },
  {
    codigo_do_IGBE: 4100905,
    UF: "PR",
    nome_da_cidade: "AMAPORA",
  },
  {
    codigo_do_IGBE: 2600906,
    UF: "PE",
    nome_da_cidade: "AMARAJI",
  },
  {
    codigo_do_IGBE: 4300638,
    UF: "RS",
    nome_da_cidade: "AMARAL FERRADOR",
  },
  {
    codigo_do_IGBE: 5200829,
    UF: "GO",
    nome_da_cidade: "AMARALINA",
  },
  {
    codigo_do_IGBE: 2200509,
    UF: "PI",
    nome_da_cidade: "AMARANTE",
  },
  {
    codigo_do_IGBE: 2901007,
    UF: "BA",
    nome_da_cidade: "AMARGOSA",
  },
  {
    codigo_do_IGBE: 1300060,
    UF: "AM",
    nome_da_cidade: "AMATURA",
  },
  {
    codigo_do_IGBE: 2901155,
    UF: "BA",
    nome_da_cidade: "AMERICA DOURADA",
  },
  {
    codigo_do_IGBE: 3501608,
    UF: "SP",
    nome_da_cidade: "AMERICANA",
  },
  {
    codigo_do_IGBE: 5200852,
    UF: "GO",
    nome_da_cidade: "AMERICANO DO BRASIL",
  },
  {
    codigo_do_IGBE: 3501707,
    UF: "SP",
    nome_da_cidade: "AMERICO BRASILIENSE",
  },
  {
    codigo_do_IGBE: 3501806,
    UF: "SP",
    nome_da_cidade: "AMERICO DE CAMPOS",
  },
  {
    codigo_do_IGBE: 4300646,
    UF: "RS",
    nome_da_cidade: "AMETISTA DO SUL",
  },
  {
    codigo_do_IGBE: 2300754,
    UF: "CE",
    nome_da_cidade: "AMONTADA",
  },
  {
    codigo_do_IGBE: 5200902,
    UF: "GO",
    nome_da_cidade: "AMORINOPOLIS",
  },
  {
    codigo_do_IGBE: 2500734,
    UF: "PB",
    nome_da_cidade: "AMPARO",
  },
  {
    codigo_do_IGBE: 3501905,
    UF: "SP",
    nome_da_cidade: "AMPARO",
  },
  {
    codigo_do_IGBE: 3102506,
    UF: "MG",
    nome_da_cidade: "AMPARO DO SERRA",
  },
  {
    codigo_do_IGBE: 4101002,
    UF: "PR",
    nome_da_cidade: "AMPERE",
  },
  {
    codigo_do_IGBE: 2700201,
    UF: "AL",
    nome_da_cidade: "ANADIA",
  },
  {
    codigo_do_IGBE: 2901205,
    UF: "BA",
    nome_da_cidade: "ANAGE",
  },
  {
    codigo_do_IGBE: 4101051,
    UF: "PR",
    nome_da_cidade: "ANAHY",
  },
  {
    codigo_do_IGBE: 2100709,
    UF: "MA",
    nome_da_cidade: "ANAJATUBA",
  },
  {
    codigo_do_IGBE: 3502002,
    UF: "SP",
    nome_da_cidade: "ANALANDIA",
  },
  {
    codigo_do_IGBE: 1300086,
    UF: "AM",
    nome_da_cidade: "ANAMA",
  },
  {
    codigo_do_IGBE: 1701002,
    UF: "TO",
    nome_da_cidade: "ANANAS",
  },
  {
    codigo_do_IGBE: 1500800,
    UF: "PA",
    nome_da_cidade: "ANANINDEUA",
  },
  {
    codigo_do_IGBE: 5201108,
    UF: "GO",
    nome_da_cidade: "ANAPOLIS",
  },
  {
    codigo_do_IGBE: 1500859,
    UF: "PA",
    nome_da_cidade: "ANAPU",
  },
  {
    codigo_do_IGBE: 2100808,
    UF: "MA",
    nome_da_cidade: "ANAPURUS",
  },
  {
    codigo_do_IGBE: 5000708,
    UF: "MS",
    nome_da_cidade: "ANASTACIO",
  },
  {
    codigo_do_IGBE: 5000807,
    UF: "MS",
    nome_da_cidade: "ANAURILANDIA",
  },
  {
    codigo_do_IGBE: 3200409,
    UF: "ES",
    nome_da_cidade: "ANCHIETA",
  },
  {
    codigo_do_IGBE: 4200804,
    UF: "SC",
    nome_da_cidade: "ANCHIETA",
  },
  {
    codigo_do_IGBE: 2901353,
    UF: "BA",
    nome_da_cidade: "ANDORINHA",
  },
  {
    codigo_do_IGBE: 3102605,
    UF: "MG",
    nome_da_cidade: "ANDRADAS",
  },
  {
    codigo_do_IGBE: 3502101,
    UF: "SP",
    nome_da_cidade: "ANDRADINA",
  },
  {
    codigo_do_IGBE: 4300661,
    UF: "RS",
    nome_da_cidade: "ANDRE DA ROCHA",
  },
  {
    codigo_do_IGBE: 3102803,
    UF: "MG",
    nome_da_cidade: "ANDRELANDIA",
  },
  {
    codigo_do_IGBE: 3502200,
    UF: "SP",
    nome_da_cidade: "ANGATUBA",
  },
  {
    codigo_do_IGBE: 3102852,
    UF: "MG",
    nome_da_cidade: "ANGELANDIA",
  },
  {
    codigo_do_IGBE: 5000856,
    UF: "MS",
    nome_da_cidade: "ANGELICA",
  },
  {
    codigo_do_IGBE: 2601003,
    UF: "PE",
    nome_da_cidade: "ANGELIM",
  },
  {
    codigo_do_IGBE: 4200903,
    UF: "SC",
    nome_da_cidade: "ANGELINA",
  },
  {
    codigo_do_IGBE: 2901403,
    UF: "BA",
    nome_da_cidade: "ANGICAL",
  },
  {
    codigo_do_IGBE: 1701051,
    UF: "TO",
    nome_da_cidade: "ANGICO",
  },
  {
    codigo_do_IGBE: 3300100,
    UF: "RJ",
    nome_da_cidade: "ANGRA DOS REIS",
  },
  {
    codigo_do_IGBE: 2901502,
    UF: "BA",
    nome_da_cidade: "ANGUERA",
  },
  {
    codigo_do_IGBE: 4101150,
    UF: "PR",
    nome_da_cidade: "ANGULO",
  },
  {
    codigo_do_IGBE: 5201207,
    UF: "GO",
    nome_da_cidade: "ANHANGUERA",
  },
  {
    codigo_do_IGBE: 3502309,
    UF: "SP",
    nome_da_cidade: "ANHEMBI",
  },
  {
    codigo_do_IGBE: 3502408,
    UF: "SP",
    nome_da_cidade: "ANHUMAS",
  },
  {
    codigo_do_IGBE: 5201306,
    UF: "GO",
    nome_da_cidade: "ANICUNS",
  },
  {
    codigo_do_IGBE: 2200707,
    UF: "PI",
    nome_da_cidade: "ANISIO DE ABREU",
  },
  {
    codigo_do_IGBE: 4201000,
    UF: "SC",
    nome_da_cidade: "ANITA GARIBALDI",
  },
  {
    codigo_do_IGBE: 4201109,
    UF: "SC",
    nome_da_cidade: "ANITAPOLIS",
  },
  {
    codigo_do_IGBE: 1300102,
    UF: "AM",
    nome_da_cidade: "ANORI",
  },
  {
    codigo_do_IGBE: 4300703,
    UF: "RS",
    nome_da_cidade: "ANTA GORDA",
  },
  {
    codigo_do_IGBE: 2300804,
    UF: "CE",
    nome_da_cidade: "ANTONINA DO NORTE",
  },
  {
    codigo_do_IGBE: 2200806,
    UF: "PI",
    nome_da_cidade: "ANTONIO ALMEIDA",
  },
  {
    codigo_do_IGBE: 2901700,
    UF: "BA",
    nome_da_cidade: "ANTONIO CARDOSO",
  },
  {
    codigo_do_IGBE: 3102902,
    UF: "MG",
    nome_da_cidade: "ANTONIO CARLOS",
  },
  {
    codigo_do_IGBE: 4201208,
    UF: "SC",
    nome_da_cidade: "ANTONIO CARLOS",
  },
  {
    codigo_do_IGBE: 3103009,
    UF: "MG",
    nome_da_cidade: "ANTONIO DIAS",
  },
  {
    codigo_do_IGBE: 2901809,
    UF: "BA",
    nome_da_cidade: "ANTONIO GONCALVES",
  },
  {
    codigo_do_IGBE: 5000906,
    UF: "MS",
    nome_da_cidade: "ANTONIO JOAO",
  },
  {
    codigo_do_IGBE: 2400901,
    UF: "RN",
    nome_da_cidade: "ANTONIO MARTINS",
  },
  {
    codigo_do_IGBE: 4101309,
    UF: "PR",
    nome_da_cidade: "ANTONIO OLINTO",
  },
  {
    codigo_do_IGBE: 2500775,
    UF: "PB",
    nome_da_cidade: "APARECIDA",
  },
  {
    codigo_do_IGBE: 3502507,
    UF: "SP",
    nome_da_cidade: "APARECIDA",
  },
  {
    codigo_do_IGBE: 5201405,
    UF: "GO",
    nome_da_cidade: "APARECIDA DE GOIANIA",
  },
  {
    codigo_do_IGBE: 5201454,
    UF: "GO",
    nome_da_cidade: "APARECIDA DO RIO DOCE",
  },
  {
    codigo_do_IGBE: 1701101,
    UF: "TO",
    nome_da_cidade: "APARECIDA DO RIO NEGRO",
  },
  {
    codigo_do_IGBE: 5001003,
    UF: "MS",
    nome_da_cidade: "APARECIDA DO TABOADO",
  },
  {
    codigo_do_IGBE: 3502606,
    UF: "SP",
    nome_da_cidade: "APARECIDA D'OESTE",
  },
  {
    codigo_do_IGBE: 3300159,
    UF: "RJ",
    nome_da_cidade: "APERIBE",
  },
  {
    codigo_do_IGBE: 3200508,
    UF: "ES",
    nome_da_cidade: "APIACA",
  },
  {
    codigo_do_IGBE: 5100805,
    UF: "MT",
    nome_da_cidade: "APIACAS",
  },
  {
    codigo_do_IGBE: 3502705,
    UF: "SP",
    nome_da_cidade: "APIAI",
  },
  {
    codigo_do_IGBE: 2100832,
    UF: "MA",
    nome_da_cidade: "APICUM-ACU",
  },
  {
    codigo_do_IGBE: 4201257,
    UF: "SC",
    nome_da_cidade: "APIUNA",
  },
  {
    codigo_do_IGBE: 2401008,
    UF: "RN",
    nome_da_cidade: "APODI",
  },
  {
    codigo_do_IGBE: 2901908,
    UF: "BA",
    nome_da_cidade: "APORA",
  },
  {
    codigo_do_IGBE: 2901957,
    UF: "BA",
    nome_da_cidade: "APUAREMA",
  },
  {
    codigo_do_IGBE: 4101408,
    UF: "PR",
    nome_da_cidade: "APUCARANA",
  },
  {
    codigo_do_IGBE: 1300144,
    UF: "AM",
    nome_da_cidade: "APUI",
  },
  {
    codigo_do_IGBE: 2800209,
    UF: "SE",
    nome_da_cidade: "AQUIDABA",
  },
  {
    codigo_do_IGBE: 5001102,
    UF: "MS",
    nome_da_cidade: "AQUIDAUANA",
  },
  {
    codigo_do_IGBE: 2301000,
    UF: "CE",
    nome_da_cidade: "AQUIRAZ",
  },
  {
    codigo_do_IGBE: 4201273,
    UF: "SC",
    nome_da_cidade: "ARABUTA",
  },
  {
    codigo_do_IGBE: 2500809,
    UF: "PB",
    nome_da_cidade: "ARACAGI",
  },
  {
    codigo_do_IGBE: 3103207,
    UF: "MG",
    nome_da_cidade: "ARACAI",
  },
  {
    codigo_do_IGBE: 2800308,
    UF: "SE",
    nome_da_cidade: "ARACAJU",
  },
  {
    codigo_do_IGBE: 3502754,
    UF: "SP",
    nome_da_cidade: "ARACARIGUAMA",
  },
  {
    codigo_do_IGBE: 2902005,
    UF: "BA",
    nome_da_cidade: "ARACATU",
  },
  {
    codigo_do_IGBE: 3502804,
    UF: "SP",
    nome_da_cidade: "ARACATUBA",
  },
  {
    codigo_do_IGBE: 2902104,
    UF: "BA",
    nome_da_cidade: "ARACI",
  },
  {
    codigo_do_IGBE: 3103306,
    UF: "MG",
    nome_da_cidade: "ARACITABA",
  },
  {
    codigo_do_IGBE: 2601052,
    UF: "PE",
    nome_da_cidade: "ARACOIABA",
  },
  {
    codigo_do_IGBE: 3502903,
    UF: "SP",
    nome_da_cidade: "ARACOIABA DA SERRA",
  },
  {
    codigo_do_IGBE: 3200607,
    UF: "ES",
    nome_da_cidade: "ARACRUZ",
  },
  {
    codigo_do_IGBE: 5201603,
    UF: "GO",
    nome_da_cidade: "ARACU",
  },
  {
    codigo_do_IGBE: 3103405,
    UF: "MG",
    nome_da_cidade: "ARACUAI",
  },
  {
    codigo_do_IGBE: 5201702,
    UF: "GO",
    nome_da_cidade: "ARAGARCAS",
  },
  {
    codigo_do_IGBE: 5201801,
    UF: "GO",
    nome_da_cidade: "ARAGOIANIA",
  },
  {
    codigo_do_IGBE: 1701903,
    UF: "TO",
    nome_da_cidade: "ARAGUACEMA",
  },
  {
    codigo_do_IGBE: 1702000,
    UF: "TO",
    nome_da_cidade: "ARAGUACU",
  },
  {
    codigo_do_IGBE: 5101001,
    UF: "MT",
    nome_da_cidade: "ARAGUAIANA",
  },
  {
    codigo_do_IGBE: 1702109,
    UF: "TO",
    nome_da_cidade: "ARAGUAINA",
  },
  {
    codigo_do_IGBE: 5101209,
    UF: "MT",
    nome_da_cidade: "ARAGUAINHA",
  },
  {
    codigo_do_IGBE: 1702158,
    UF: "TO",
    nome_da_cidade: "ARAGUANA",
  },
  {
    codigo_do_IGBE: 5202155,
    UF: "GO",
    nome_da_cidade: "ARAGUAPAZ",
  },
  {
    codigo_do_IGBE: 3103504,
    UF: "MG",
    nome_da_cidade: "ARAGUARI",
  },
  {
    codigo_do_IGBE: 1702208,
    UF: "TO",
    nome_da_cidade: "ARAGUATINS",
  },
  {
    codigo_do_IGBE: 2100907,
    UF: "MA",
    nome_da_cidade: "ARAIOSES",
  },
  {
    codigo_do_IGBE: 5001243,
    UF: "MS",
    nome_da_cidade: "ARAL MOREIRA",
  },
  {
    codigo_do_IGBE: 4300851,
    UF: "RS",
    nome_da_cidade: "ARAMBARE",
  },
  {
    codigo_do_IGBE: 2100956,
    UF: "MA",
    nome_da_cidade: "ARAME",
  },
  {
    codigo_do_IGBE: 3503109,
    UF: "SP",
    nome_da_cidade: "ARANDU",
  },
  {
    codigo_do_IGBE: 3103603,
    UF: "MG",
    nome_da_cidade: "ARANTINA",
  },
  {
    codigo_do_IGBE: 3503158,
    UF: "SP",
    nome_da_cidade: "ARAPEI",
  },
  {
    codigo_do_IGBE: 2700300,
    UF: "AL",
    nome_da_cidade: "ARAPIRACA",
  },
  {
    codigo_do_IGBE: 1702307,
    UF: "TO",
    nome_da_cidade: "ARAPOEMA",
  },
  {
    codigo_do_IGBE: 3103702,
    UF: "MG",
    nome_da_cidade: "ARAPONGA",
  },
  {
    codigo_do_IGBE: 4101507,
    UF: "PR",
    nome_da_cidade: "ARAPONGAS",
  },
  {
    codigo_do_IGBE: 3103751,
    UF: "MG",
    nome_da_cidade: "ARAPORA",
  },
  {
    codigo_do_IGBE: 4101606,
    UF: "PR",
    nome_da_cidade: "ARAPOTI",
  },
  {
    codigo_do_IGBE: 5101258,
    UF: "MT",
    nome_da_cidade: "ARAPUTANGA",
  },
  {
    codigo_do_IGBE: 4201307,
    UF: "SC",
    nome_da_cidade: "ARAQUARI",
  },
  {
    codigo_do_IGBE: 2500908,
    UF: "PB",
    nome_da_cidade: "ARARA",
  },
  {
    codigo_do_IGBE: 4201406,
    UF: "SC",
    nome_da_cidade: "ARARANGUA",
  },
  {
    codigo_do_IGBE: 3503208,
    UF: "SP",
    nome_da_cidade: "ARARAQUARA",
  },
  {
    codigo_do_IGBE: 3503307,
    UF: "SP",
    nome_da_cidade: "ARARAS",
  },
  {
    codigo_do_IGBE: 2301257,
    UF: "CE",
    nome_da_cidade: "ARARENDA",
  },
  {
    codigo_do_IGBE: 2101004,
    UF: "MA",
    nome_da_cidade: "ARARI",
  },
  {
    codigo_do_IGBE: 4300877,
    UF: "RS",
    nome_da_cidade: "ARARICA",
  },
  {
    codigo_do_IGBE: 2301307,
    UF: "CE",
    nome_da_cidade: "ARARIPE",
  },
  {
    codigo_do_IGBE: 2601102,
    UF: "PE",
    nome_da_cidade: "ARARIPINA",
  },
  {
    codigo_do_IGBE: 2501005,
    UF: "PB",
    nome_da_cidade: "ARARUNA",
  },
  {
    codigo_do_IGBE: 2902252,
    UF: "BA",
    nome_da_cidade: "ARATACA",
  },
  {
    codigo_do_IGBE: 4300901,
    UF: "RS",
    nome_da_cidade: "ARATIBA",
  },
  {
    codigo_do_IGBE: 2301406,
    UF: "CE",
    nome_da_cidade: "ARATUBA",
  },
  {
    codigo_do_IGBE: 2902302,
    UF: "BA",
    nome_da_cidade: "ARATUIPE",
  },
  {
    codigo_do_IGBE: 2800407,
    UF: "SE",
    nome_da_cidade: "ARAUA",
  },
  {
    codigo_do_IGBE: 4101804,
    UF: "PR",
    nome_da_cidade: "ARAUCARIA",
  },
  {
    codigo_do_IGBE: 3103900,
    UF: "MG",
    nome_da_cidade: "ARAUJOS",
  },
  {
    codigo_do_IGBE: 3104007,
    UF: "MG",
    nome_da_cidade: "ARAXA",
  },
  {
    codigo_do_IGBE: 3104106,
    UF: "MG",
    nome_da_cidade: "ARCEBURGO",
  },
  {
    codigo_do_IGBE: 3503356,
    UF: "SP",
    nome_da_cidade: "ARCO-IRIS",
  },
  {
    codigo_do_IGBE: 2601201,
    UF: "PE",
    nome_da_cidade: "ARCOVERDE",
  },
  {
    codigo_do_IGBE: 3104304,
    UF: "MG",
    nome_da_cidade: "AREADO",
  },
  {
    codigo_do_IGBE: 3503406,
    UF: "SP",
    nome_da_cidade: "AREALVA",
  },
  {
    codigo_do_IGBE: 2501104,
    UF: "PB",
    nome_da_cidade: "AREIA",
  },
  {
    codigo_do_IGBE: 2401107,
    UF: "RN",
    nome_da_cidade: "AREIA BRANCA",
  },
  {
    codigo_do_IGBE: 2800506,
    UF: "SE",
    nome_da_cidade: "AREIA BRANCA",
  },
  {
    codigo_do_IGBE: 2501153,
    UF: "PB",
    nome_da_cidade: "AREIA DE BARAUNAS",
  },
  {
    codigo_do_IGBE: 2501203,
    UF: "PB",
    nome_da_cidade: "AREIAL",
  },
  {
    codigo_do_IGBE: 3503505,
    UF: "SP",
    nome_da_cidade: "AREIAS",
  },
  {
    codigo_do_IGBE: 3503604,
    UF: "SP",
    nome_da_cidade: "AREIOPOLIS",
  },
  {
    codigo_do_IGBE: 5101308,
    UF: "MT",
    nome_da_cidade: "ARENAPOLIS",
  },
  {
    codigo_do_IGBE: 2401206,
    UF: "RN",
    nome_da_cidade: "ARES",
  },
  {
    codigo_do_IGBE: 3104403,
    UF: "MG",
    nome_da_cidade: "ARGIRITA",
  },
  {
    codigo_do_IGBE: 3104452,
    UF: "MG",
    nome_da_cidade: "ARICANDUVA",
  },
  {
    codigo_do_IGBE: 5101407,
    UF: "MT",
    nome_da_cidade: "ARIPUANA",
  },
  {
    codigo_do_IGBE: 1100023,
    UF: "RO",
    nome_da_cidade: "ARIQUEMES",
  },
  {
    codigo_do_IGBE: 3503703,
    UF: "SP",
    nome_da_cidade: "ARIRANHA",
  },
  {
    codigo_do_IGBE: 4101853,
    UF: "PR",
    nome_da_cidade: "ARIRANHA DO IVAI",
  },
  {
    codigo_do_IGBE: 3300233,
    UF: "RJ",
    nome_da_cidade: "ARMACAO DOS BUZIOS",
  },
  {
    codigo_do_IGBE: 4201505,
    UF: "SC",
    nome_da_cidade: "ARMAZEM",
  },
  {
    codigo_do_IGBE: 2301505,
    UF: "CE",
    nome_da_cidade: "ARNEIROZ",
  },
  {
    codigo_do_IGBE: 2200905,
    UF: "PI",
    nome_da_cidade: "AROAZES",
  },
  {
    codigo_do_IGBE: 2200954,
    UF: "PI",
    nome_da_cidade: "AROEIRAS DO ITAIM",
  },
  {
    codigo_do_IGBE: 2201002,
    UF: "PI",
    nome_da_cidade: "ARRAIAL",
  },
  {
    codigo_do_IGBE: 1702406,
    UF: "TO",
    nome_da_cidade: "ARRAIAS",
  },
  {
    codigo_do_IGBE: 4301008,
    UF: "RS",
    nome_da_cidade: "ARROIO DO MEIO",
  },
  {
    codigo_do_IGBE: 4301073,
    UF: "RS",
    nome_da_cidade: "ARROIO DO PADRE",
  },
  {
    codigo_do_IGBE: 4301057,
    UF: "RS",
    nome_da_cidade: "ARROIO DO SAL",
  },
  {
    codigo_do_IGBE: 4301206,
    UF: "RS",
    nome_da_cidade: "ARROIO DO TIGRE",
  },
  {
    codigo_do_IGBE: 4301107,
    UF: "RS",
    nome_da_cidade: "ARROIO DOS RATOS",
  },
  {
    codigo_do_IGBE: 4301305,
    UF: "RS",
    nome_da_cidade: "ARROIO GRANDE",
  },
  {
    codigo_do_IGBE: 3503802,
    UF: "SP",
    nome_da_cidade: "ARTUR NOGUEIRA",
  },
  {
    codigo_do_IGBE: 5202502,
    UF: "GO",
    nome_da_cidade: "ARUANA",
  },
  {
    codigo_do_IGBE: 3503901,
    UF: "SP",
    nome_da_cidade: "ARUJA",
  },
  {
    codigo_do_IGBE: 4201653,
    UF: "SC",
    nome_da_cidade: "ARVOREDO",
  },
  {
    codigo_do_IGBE: 4301404,
    UF: "RS",
    nome_da_cidade: "ARVOREZINHA",
  },
  {
    codigo_do_IGBE: 4201703,
    UF: "SC",
    nome_da_cidade: "ASCURRA",
  },
  {
    codigo_do_IGBE: 3503950,
    UF: "SP",
    nome_da_cidade: "ASPASIA",
  },
  {
    codigo_do_IGBE: 4101903,
    UF: "PR",
    nome_da_cidade: "ASSAI",
  },
  {
    codigo_do_IGBE: 2301604,
    UF: "CE",
    nome_da_cidade: "ASSARE",
  },
  {
    codigo_do_IGBE: 1200054,
    UF: "AC",
    nome_da_cidade: "ASSIS BRASIL",
  },
  {
    codigo_do_IGBE: 4102000,
    UF: "PR",
    nome_da_cidade: "ASSIS CHATEAUBRIAND",
  },
  {
    codigo_do_IGBE: 2501351,
    UF: "PB",
    nome_da_cidade: "ASSUNCAO",
  },
  {
    codigo_do_IGBE: 2201051,
    UF: "PI",
    nome_da_cidade: "ASSUNCAO DO PIAUI",
  },
  {
    codigo_do_IGBE: 4102109,
    UF: "PR",
    nome_da_cidade: "ASTORGA",
  },
  {
    codigo_do_IGBE: 2700409,
    UF: "AL",
    nome_da_cidade: "ATALAIA",
  },
  {
    codigo_do_IGBE: 4102208,
    UF: "PR",
    nome_da_cidade: "ATALAIA",
  },
  {
    codigo_do_IGBE: 1300201,
    UF: "AM",
    nome_da_cidade: "ATALAIA DO NORTE",
  },
  {
    codigo_do_IGBE: 4201802,
    UF: "SC",
    nome_da_cidade: "ATALANTA",
  },
  {
    codigo_do_IGBE: 3104700,
    UF: "MG",
    nome_da_cidade: "ATALEIA",
  },
  {
    codigo_do_IGBE: 3504107,
    UF: "SP",
    nome_da_cidade: "ATIBAIA",
  },
  {
    codigo_do_IGBE: 1702554,
    UF: "TO",
    nome_da_cidade: "AUGUSTINOPOLIS",
  },
  {
    codigo_do_IGBE: 1500909,
    UF: "PA",
    nome_da_cidade: "AUGUSTO CORREA",
  },
  {
    codigo_do_IGBE: 3104809,
    UF: "MG",
    nome_da_cidade: "AUGUSTO DE LIMA",
  },
  {
    codigo_do_IGBE: 4301503,
    UF: "RS",
    nome_da_cidade: "AUGUSTO PESTANA",
  },
  {
    codigo_do_IGBE: 2401305,
    UF: "RN",
    nome_da_cidade: "AUGUSTO SEVERO",
  },
  {
    codigo_do_IGBE: 4301552,
    UF: "RS",
    nome_da_cidade: "AUREA",
  },
  {
    codigo_do_IGBE: 3504206,
    UF: "SP",
    nome_da_cidade: "AURIFLAMA",
  },
  {
    codigo_do_IGBE: 5202601,
    UF: "GO",
    nome_da_cidade: "AURILANDIA",
  },
  {
    codigo_do_IGBE: 2301703,
    UF: "CE",
    nome_da_cidade: "AURORA",
  },
  {
    codigo_do_IGBE: 4201901,
    UF: "SC",
    nome_da_cidade: "AURORA",
  },
  {
    codigo_do_IGBE: 1500958,
    UF: "PA",
    nome_da_cidade: "AURORA DO PARA",
  },
  {
    codigo_do_IGBE: 1702703,
    UF: "TO",
    nome_da_cidade: "AURORA DO TOCANTINS",
  },
  {
    codigo_do_IGBE: 1300300,
    UF: "AM",
    nome_da_cidade: "AUTAZES",
  },
  {
    codigo_do_IGBE: 3504305,
    UF: "SP",
    nome_da_cidade: "AVAI",
  },
  {
    codigo_do_IGBE: 3504404,
    UF: "SP",
    nome_da_cidade: "AVANHANDAVA",
  },
  {
    codigo_do_IGBE: 1501006,
    UF: "PA",
    nome_da_cidade: "AVEIRO",
  },
  {
    codigo_do_IGBE: 2201101,
    UF: "PI",
    nome_da_cidade: "AVELINO LOPES",
  },
  {
    codigo_do_IGBE: 5202809,
    UF: "GO",
    nome_da_cidade: "AVELINOPOLIS",
  },
  {
    codigo_do_IGBE: 2101103,
    UF: "MA",
    nome_da_cidade: "AXIXA",
  },
  {
    codigo_do_IGBE: 1703008,
    UF: "TO",
    nome_da_cidade: "BABACULANDIA",
  },
  {
    codigo_do_IGBE: 2101202,
    UF: "MA",
    nome_da_cidade: "BACABAL",
  },
  {
    codigo_do_IGBE: 2101251,
    UF: "MA",
    nome_da_cidade: "BACABEIRA",
  },
  {
    codigo_do_IGBE: 3504602,
    UF: "SP",
    nome_da_cidade: "BADY BASSITT",
  },
  {
    codigo_do_IGBE: 3104908,
    UF: "MG",
    nome_da_cidade: "BAEPENDI",
  },
  {
    codigo_do_IGBE: 4301602,
    UF: "RS",
    nome_da_cidade: "BAGE",
  },
  {
    codigo_do_IGBE: 1501105,
    UF: "PA",
    nome_da_cidade: "BAGRE",
  },
  {
    codigo_do_IGBE: 2401404,
    UF: "RN",
    nome_da_cidade: "BAIA FORMOSA",
  },
  {
    codigo_do_IGBE: 2902500,
    UF: "BA",
    nome_da_cidade: "BAIANOPOLIS",
  },
  {
    codigo_do_IGBE: 1501204,
    UF: "PA",
    nome_da_cidade: "BAIAO",
  },
  {
    codigo_do_IGBE: 2902609,
    UF: "BA",
    nome_da_cidade: "BAIXA GRANDE",
  },
  {
    codigo_do_IGBE: 2301802,
    UF: "CE",
    nome_da_cidade: "BAIXIO",
  },
  {
    codigo_do_IGBE: 3200805,
    UF: "ES",
    nome_da_cidade: "BAIXO GUANDU",
  },
  {
    codigo_do_IGBE: 3504701,
    UF: "SP",
    nome_da_cidade: "BALBINOS",
  },
  {
    codigo_do_IGBE: 3105004,
    UF: "MG",
    nome_da_cidade: "BALDIM",
  },
  {
    codigo_do_IGBE: 5203104,
    UF: "GO",
    nome_da_cidade: "BALIZA",
  },
  {
    codigo_do_IGBE: 4201950,
    UF: "SC",
    nome_da_cidade: "BALNEARIO ARROIO DO SILVA",
  },
  {
    codigo_do_IGBE: 4202057,
    UF: "SC",
    nome_da_cidade: "BALNEARIO BARRA DO SUL",
  },
  {
    codigo_do_IGBE: 4202008,
    UF: "SC",
    nome_da_cidade: "BALNEARIO CAMBORIU",
  },
  {
    codigo_do_IGBE: 4202073,
    UF: "SC",
    nome_da_cidade: "BALNEARIO GAIVOTA",
  },
  {
    codigo_do_IGBE: 4301636,
    UF: "RS",
    nome_da_cidade: "BALNEARIO PINHAL",
  },
  {
    codigo_do_IGBE: 4102307,
    UF: "PR",
    nome_da_cidade: "BALSA NOVA",
  },
  {
    codigo_do_IGBE: 3504800,
    UF: "SP",
    nome_da_cidade: "BALSAMO",
  },
  {
    codigo_do_IGBE: 2101400,
    UF: "MA",
    nome_da_cidade: "BALSAS",
  },
  {
    codigo_do_IGBE: 3105103,
    UF: "MG",
    nome_da_cidade: "BAMBUI",
  },
  {
    codigo_do_IGBE: 2301851,
    UF: "CE",
    nome_da_cidade: "BANABUIU",
  },
  {
    codigo_do_IGBE: 3504909,
    UF: "SP",
    nome_da_cidade: "BANANAL",
  },
  {
    codigo_do_IGBE: 2501500,
    UF: "PB",
    nome_da_cidade: "BANANEIRAS",
  },
  {
    codigo_do_IGBE: 3105202,
    UF: "MG",
    nome_da_cidade: "BANDEIRA",
  },
  {
    codigo_do_IGBE: 4202081,
    UF: "SC",
    nome_da_cidade: "BANDEIRANTE",
  },
  {
    codigo_do_IGBE: 5001508,
    UF: "MS",
    nome_da_cidade: "BANDEIRANTES",
  },
  {
    codigo_do_IGBE: 4102406,
    UF: "PR",
    nome_da_cidade: "BANDEIRANTES",
  },
  {
    codigo_do_IGBE: 1501253,
    UF: "PA",
    nome_da_cidade: "BANNACH",
  },
  {
    codigo_do_IGBE: 2902658,
    UF: "BA",
    nome_da_cidade: "BANZAE",
  },
  {
    codigo_do_IGBE: 4301651,
    UF: "RS",
    nome_da_cidade: "BARAO",
  },
  {
    codigo_do_IGBE: 3505005,
    UF: "SP",
    nome_da_cidade: "BARAO DE ANTONINA",
  },
  {
    codigo_do_IGBE: 3105400,
    UF: "MG",
    nome_da_cidade: "BARAO DE COCAIS",
  },
  {
    codigo_do_IGBE: 4301701,
    UF: "RS",
    nome_da_cidade: "BARAO DE COTEGIPE",
  },
  {
    codigo_do_IGBE: 5101605,
    UF: "MT",
    nome_da_cidade: "BARAO DE MELGACO",
  },
  {
    codigo_do_IGBE: 4301750,
    UF: "RS",
    nome_da_cidade: "BARAO DO TRIUNFO",
  },
  {
    codigo_do_IGBE: 2501534,
    UF: "PB",
    nome_da_cidade: "BARAUNA",
  },
  {
    codigo_do_IGBE: 2401453,
    UF: "RN",
    nome_da_cidade: "BARAUNA",
  },
  {
    codigo_do_IGBE: 2301901,
    UF: "CE",
    nome_da_cidade: "BARBALHA",
  },
  {
    codigo_do_IGBE: 3505104,
    UF: "SP",
    nome_da_cidade: "BARBOSA",
  },
  {
    codigo_do_IGBE: 4102505,
    UF: "PR",
    nome_da_cidade: "BARBOSA FERRAZ",
  },
  {
    codigo_do_IGBE: 1501303,
    UF: "PA",
    nome_da_cidade: "BARCARENA",
  },
  {
    codigo_do_IGBE: 2401503,
    UF: "RN",
    nome_da_cidade: "BARCELONA",
  },
  {
    codigo_do_IGBE: 1300409,
    UF: "AM",
    nome_da_cidade: "BARCELOS",
  },
  {
    codigo_do_IGBE: 3505203,
    UF: "SP",
    nome_da_cidade: "BARIRI",
  },
  {
    codigo_do_IGBE: 2902708,
    UF: "BA",
    nome_da_cidade: "BARRA",
  },
  {
    codigo_do_IGBE: 4202099,
    UF: "SC",
    nome_da_cidade: "BARRA BONITA",
  },
  {
    codigo_do_IGBE: 3505302,
    UF: "SP",
    nome_da_cidade: "BARRA BONITA",
  },
  {
    codigo_do_IGBE: 2601300,
    UF: "PE",
    nome_da_cidade: "BARRA DE GUABIRABA",
  },
  {
    codigo_do_IGBE: 2501609,
    UF: "PB",
    nome_da_cidade: "BARRA DE SANTA ROSA",
  },
  {
    codigo_do_IGBE: 2501575,
    UF: "PB",
    nome_da_cidade: "BARRA DE SANTANA",
  },
  {
    codigo_do_IGBE: 2700508,
    UF: "AL",
    nome_da_cidade: "BARRA DE SANTO ANTONIO",
  },
  {
    codigo_do_IGBE: 3200904,
    UF: "ES",
    nome_da_cidade: "BARRA DE SAO FRANCISCO",
  },
  {
    codigo_do_IGBE: 2700607,
    UF: "AL",
    nome_da_cidade: "BARRA DE SAO MIGUEL",
  },
  {
    codigo_do_IGBE: 2501708,
    UF: "PB",
    nome_da_cidade: "BARRA DE SAO MIGUEL",
  },
  {
    codigo_do_IGBE: 5101704,
    UF: "MT",
    nome_da_cidade: "BARRA DO BUGRES",
  },
  {
    codigo_do_IGBE: 3505351,
    UF: "SP",
    nome_da_cidade: "BARRA DO CHAPEU",
  },
  {
    codigo_do_IGBE: 2902906,
    UF: "BA",
    nome_da_cidade: "BARRA DO CHOCA",
  },
  {
    codigo_do_IGBE: 2101608,
    UF: "MA",
    nome_da_cidade: "BARRA DO CORDA",
  },
  {
    codigo_do_IGBE: 5101803,
    UF: "MT",
    nome_da_cidade: "BARRA DO GARCAS",
  },
  {
    codigo_do_IGBE: 4301859,
    UF: "RS",
    nome_da_cidade: "BARRA DO GUARITA",
  },
  {
    codigo_do_IGBE: 2903003,
    UF: "BA",
    nome_da_cidade: "BARRA DO MENDES",
  },
  {
    codigo_do_IGBE: 3300308,
    UF: "RJ",
    nome_da_cidade: "BARRA DO PIRAI",
  },
  {
    codigo_do_IGBE: 4301875,
    UF: "RS",
    nome_da_cidade: "BARRA DO QUARAI",
  },
  {
    codigo_do_IGBE: 4301909,
    UF: "RS",
    nome_da_cidade: "BARRA DO RIBEIRO",
  },
  {
    codigo_do_IGBE: 4301925,
    UF: "RS",
    nome_da_cidade: "BARRA DO RIO AZUL",
  },
  {
    codigo_do_IGBE: 2903102,
    UF: "BA",
    nome_da_cidade: "BARRA DO ROCHA",
  },
  {
    codigo_do_IGBE: 3505401,
    UF: "SP",
    nome_da_cidade: "BARRA DO TURVO",
  },
  {
    codigo_do_IGBE: 2800605,
    UF: "SE",
    nome_da_cidade: "BARRA DOS COQUEIROS",
  },
  {
    codigo_do_IGBE: 4301958,
    UF: "RS",
    nome_da_cidade: "BARRA FUNDA",
  },
  {
    codigo_do_IGBE: 3105707,
    UF: "MG",
    nome_da_cidade: "BARRA LONGA",
  },
  {
    codigo_do_IGBE: 3300407,
    UF: "RJ",
    nome_da_cidade: "BARRA MANSA",
  },
  {
    codigo_do_IGBE: 4202107,
    UF: "SC",
    nome_da_cidade: "BARRA VELHA",
  },
  {
    codigo_do_IGBE: 4102604,
    UF: "PR",
    nome_da_cidade: "BARRACAO",
  },
  {
    codigo_do_IGBE: 4301800,
    UF: "RS",
    nome_da_cidade: "BARRACAO",
  },
  {
    codigo_do_IGBE: 2201200,
    UF: "PI",
    nome_da_cidade: "BARRAS",
  },
  {
    codigo_do_IGBE: 2903201,
    UF: "BA",
    nome_da_cidade: "BARREIRAS",
  },
  {
    codigo_do_IGBE: 1300508,
    UF: "AM",
    nome_da_cidade: "BARREIRINHA",
  },
  {
    codigo_do_IGBE: 2101707,
    UF: "MA",
    nome_da_cidade: "BARREIRINHAS",
  },
  {
    codigo_do_IGBE: 2601409,
    UF: "PE",
    nome_da_cidade: "BARREIROS",
  },
  {
    codigo_do_IGBE: 3505500,
    UF: "SP",
    nome_da_cidade: "BARRETOS",
  },
  {
    codigo_do_IGBE: 3505609,
    UF: "SP",
    nome_da_cidade: "BARRINHA",
  },
  {
    codigo_do_IGBE: 2302008,
    UF: "CE",
    nome_da_cidade: "BARRO",
  },
  {
    codigo_do_IGBE: 2903235,
    UF: "BA",
    nome_da_cidade: "BARRO ALTO",
  },
  {
    codigo_do_IGBE: 5203203,
    UF: "GO",
    nome_da_cidade: "BARRO ALTO",
  },
  {
    codigo_do_IGBE: 2201408,
    UF: "PI",
    nome_da_cidade: "BARRO DURO",
  },
  {
    codigo_do_IGBE: 2903300,
    UF: "BA",
    nome_da_cidade: "BARRO PRETO",
  },
  {
    codigo_do_IGBE: 1703107,
    UF: "TO",
    nome_da_cidade: "BARROLANDIA",
  },
  {
    codigo_do_IGBE: 2302057,
    UF: "CE",
    nome_da_cidade: "BARROQUINHA",
  },
  {
    codigo_do_IGBE: 4302006,
    UF: "RS",
    nome_da_cidade: "BARROS CASSAL",
  },
  {
    codigo_do_IGBE: 3105905,
    UF: "MG",
    nome_da_cidade: "BARROSO",
  },
  {
    codigo_do_IGBE: 3505708,
    UF: "SP",
    nome_da_cidade: "BARUERI",
  },
  {
    codigo_do_IGBE: 5001904,
    UF: "MS",
    nome_da_cidade: "BATAGUASSU",
  },
  {
    codigo_do_IGBE: 2700706,
    UF: "AL",
    nome_da_cidade: "BATALHA",
  },
  {
    codigo_do_IGBE: 2201507,
    UF: "PI",
    nome_da_cidade: "BATALHA",
  },
  {
    codigo_do_IGBE: 3505906,
    UF: "SP",
    nome_da_cidade: "BATATAIS",
  },
  {
    codigo_do_IGBE: 5002001,
    UF: "MS",
    nome_da_cidade: "BATAYPORA",
  },
  {
    codigo_do_IGBE: 2302107,
    UF: "CE",
    nome_da_cidade: "BATURITE",
  },
  {
    codigo_do_IGBE: 2501807,
    UF: "PB",
    nome_da_cidade: "BAYEUX",
  },
  {
    codigo_do_IGBE: 3506102,
    UF: "SP",
    nome_da_cidade: "BEBEDOURO",
  },
  {
    codigo_do_IGBE: 2302206,
    UF: "CE",
    nome_da_cidade: "BEBERIBE",
  },
  {
    codigo_do_IGBE: 2302305,
    UF: "CE",
    nome_da_cidade: "BELA CRUZ",
  },
  {
    codigo_do_IGBE: 5002100,
    UF: "MS",
    nome_da_cidade: "BELA VISTA",
  },
  {
    codigo_do_IGBE: 5203302,
    UF: "GO",
    nome_da_cidade: "BELA VISTA DE GOIAS",
  },
  {
    codigo_do_IGBE: 3106002,
    UF: "MG",
    nome_da_cidade: "BELA VISTA DE MINAS",
  },
  {
    codigo_do_IGBE: 4102802,
    UF: "PR",
    nome_da_cidade: "BELA VISTA DO PARAISO",
  },
  {
    codigo_do_IGBE: 2201556,
    UF: "PI",
    nome_da_cidade: "BELA VISTA DO PIAUI",
  },
  {
    codigo_do_IGBE: 4202131,
    UF: "SC",
    nome_da_cidade: "BELA VISTA DO TOLDO",
  },
  {
    codigo_do_IGBE: 2101731,
    UF: "MA",
    nome_da_cidade: "BELAGUA",
  },
  {
    codigo_do_IGBE: 2700805,
    UF: "AL",
    nome_da_cidade: "BELEM",
  },
  {
    codigo_do_IGBE: 1501402,
    UF: "PA",
    nome_da_cidade: "BELEM",
  },
  {
    codigo_do_IGBE: 2501906,
    UF: "PB",
    nome_da_cidade: "BELEM",
  },
  {
    codigo_do_IGBE: 2601508,
    UF: "PE",
    nome_da_cidade: "BELEM DE MARIA",
  },
  {
    codigo_do_IGBE: 2601607,
    UF: "PE",
    nome_da_cidade: "BELEM DE SAO FRANCISCO",
  },
  {
    codigo_do_IGBE: 2201572,
    UF: "PI",
    nome_da_cidade: "BELEM DO PIAUI",
  },
  {
    codigo_do_IGBE: 3300456,
    UF: "RJ",
    nome_da_cidade: "BELFORD ROXO",
  },
  {
    codigo_do_IGBE: 3106101,
    UF: "MG",
    nome_da_cidade: "BELMIRO BRAGA",
  },
  {
    codigo_do_IGBE: 2903409,
    UF: "BA",
    nome_da_cidade: "BELMONTE",
  },
  {
    codigo_do_IGBE: 4202156,
    UF: "SC",
    nome_da_cidade: "BELMONTE",
  },
  {
    codigo_do_IGBE: 2903508,
    UF: "BA",
    nome_da_cidade: "BELO CAMPO",
  },
  {
    codigo_do_IGBE: 2601706,
    UF: "PE",
    nome_da_cidade: "BELO JARDIM",
  },
  {
    codigo_do_IGBE: 2700904,
    UF: "AL",
    nome_da_cidade: "BELO MONTE",
  },
  {
    codigo_do_IGBE: 3106309,
    UF: "MG",
    nome_da_cidade: "BELO ORIENTE",
  },
  {
    codigo_do_IGBE: 3106408,
    UF: "MG",
    nome_da_cidade: "BELO VALE",
  },
  {
    codigo_do_IGBE: 2201606,
    UF: "PI",
    nome_da_cidade: "BENEDITINOS",
  },
  {
    codigo_do_IGBE: 2101806,
    UF: "MA",
    nome_da_cidade: "BENEDITO LEITE",
  },
  {
    codigo_do_IGBE: 4202206,
    UF: "SC",
    nome_da_cidade: "BENEDITO NOVO",
  },
  {
    codigo_do_IGBE: 1501501,
    UF: "PA",
    nome_da_cidade: "BENEVIDES",
  },
  {
    codigo_do_IGBE: 1300607,
    UF: "AM",
    nome_da_cidade: "BENJAMIN CONSTANT",
  },
  {
    codigo_do_IGBE: 3506201,
    UF: "SP",
    nome_da_cidade: "BENTO DE ABREU",
  },
  {
    codigo_do_IGBE: 2401602,
    UF: "RN",
    nome_da_cidade: "BENTO FERNANDES",
  },
  {
    codigo_do_IGBE: 4302105,
    UF: "RS",
    nome_da_cidade: "BENTO GONCALVES",
  },
  {
    codigo_do_IGBE: 2101905,
    UF: "MA",
    nome_da_cidade: "BEQUIMAO",
  },
  {
    codigo_do_IGBE: 3106507,
    UF: "MG",
    nome_da_cidade: "BERILO",
  },
  {
    codigo_do_IGBE: 2502052,
    UF: "PB",
    nome_da_cidade: "BERNARDINO BATISTA",
  },
  {
    codigo_do_IGBE: 3506300,
    UF: "SP",
    nome_da_cidade: "BERNARDINO DE CAMPOS",
  },
  {
    codigo_do_IGBE: 2101939,
    UF: "MA",
    nome_da_cidade: "BERNARDO DO MEARIM",
  },
  {
    codigo_do_IGBE: 1703206,
    UF: "TO",
    nome_da_cidade: "BERNARDO SAYAO",
  },
  {
    codigo_do_IGBE: 3506359,
    UF: "SP",
    nome_da_cidade: "BERTIOGA",
  },
  {
    codigo_do_IGBE: 2201705,
    UF: "PI",
    nome_da_cidade: "BERTOLINIA",
  },
  {
    codigo_do_IGBE: 3106606,
    UF: "MG",
    nome_da_cidade: "BERTOPOLIS",
  },
  {
    codigo_do_IGBE: 1300631,
    UF: "AM",
    nome_da_cidade: "BERURI",
  },
  {
    codigo_do_IGBE: 2601805,
    UF: "PE",
    nome_da_cidade: "BETANIA",
  },
  {
    codigo_do_IGBE: 2201739,
    UF: "PI",
    nome_da_cidade: "BETANIA DO PIAUI",
  },
  {
    codigo_do_IGBE: 3106705,
    UF: "MG",
    nome_da_cidade: "BETIM",
  },
  {
    codigo_do_IGBE: 2601904,
    UF: "PE",
    nome_da_cidade: "BEZERROS",
  },
  {
    codigo_do_IGBE: 4202305,
    UF: "SC",
    nome_da_cidade: "BIGUACU",
  },
  {
    codigo_do_IGBE: 3506409,
    UF: "SP",
    nome_da_cidade: "BILAC",
  },
  {
    codigo_do_IGBE: 3107000,
    UF: "MG",
    nome_da_cidade: "BIQUINHAS",
  },
  {
    codigo_do_IGBE: 3506508,
    UF: "SP",
    nome_da_cidade: "BIRIGUI",
  },
  {
    codigo_do_IGBE: 3506607,
    UF: "SP",
    nome_da_cidade: "BIRITIBA-MIRIM",
  },
  {
    codigo_do_IGBE: 2903607,
    UF: "BA",
    nome_da_cidade: "BIRITINGA",
  },
  {
    codigo_do_IGBE: 4102901,
    UF: "PR",
    nome_da_cidade: "BITURUNA",
  },
  {
    codigo_do_IGBE: 4202404,
    UF: "SC",
    nome_da_cidade: "BLUMENAU",
  },
  {
    codigo_do_IGBE: 3201001,
    UF: "ES",
    nome_da_cidade: "BOA ESPERANCA",
  },
  {
    codigo_do_IGBE: 4103008,
    UF: "PR",
    nome_da_cidade: "BOA ESPERANCA",
  },
  {
    codigo_do_IGBE: 3506706,
    UF: "SP",
    nome_da_cidade: "BOA ESPERANCA DO SUL",
  },
  {
    codigo_do_IGBE: 2201770,
    UF: "PI",
    nome_da_cidade: "BOA HORA",
  },
  {
    codigo_do_IGBE: 2903706,
    UF: "BA",
    nome_da_cidade: "BOA NOVA",
  },
  {
    codigo_do_IGBE: 2502102,
    UF: "PB",
    nome_da_cidade: "BOA VENTURA",
  },
  {
    codigo_do_IGBE: 4103040,
    UF: "PR",
    nome_da_cidade: "BOA VENTURA DE SAO ROQUE",
  },
  {
    codigo_do_IGBE: 2302404,
    UF: "CE",
    nome_da_cidade: "BOA VIAGEM",
  },
  {
    codigo_do_IGBE: 2502151,
    UF: "PB",
    nome_da_cidade: "BOA VISTA",
  },
  {
    codigo_do_IGBE: 1400100,
    UF: "RR",
    nome_da_cidade: "BOA VISTA",
  },
  {
    codigo_do_IGBE: 4103057,
    UF: "PR",
    nome_da_cidade: "BOA VISTA DA APARECIDA",
  },
  {
    codigo_do_IGBE: 4302154,
    UF: "RS",
    nome_da_cidade: "BOA VISTA DAS MISSOES",
  },
  {
    codigo_do_IGBE: 4302204,
    UF: "RS",
    nome_da_cidade: "BOA VISTA DO BURICA",
  },
  {
    codigo_do_IGBE: 4302220,
    UF: "RS",
    nome_da_cidade: "BOA VISTA DO CADEADO",
  },
  {
    codigo_do_IGBE: 4302238,
    UF: "RS",
    nome_da_cidade: "BOA VISTA DO INCRA",
  },
  {
    codigo_do_IGBE: 2903805,
    UF: "BA",
    nome_da_cidade: "BOA VISTA DO TUPIM",
  },
  {
    codigo_do_IGBE: 2701001,
    UF: "AL",
    nome_da_cidade: "BOCA DA MATA",
  },
  {
    codigo_do_IGBE: 1300706,
    UF: "AM",
    nome_da_cidade: "BOCA DO ACRE",
  },
  {
    codigo_do_IGBE: 2201804,
    UF: "PI",
    nome_da_cidade: "BOCAINA",
  },
  {
    codigo_do_IGBE: 3506805,
    UF: "SP",
    nome_da_cidade: "BOCAINA",
  },
  {
    codigo_do_IGBE: 3107208,
    UF: "MG",
    nome_da_cidade: "BOCAINA DE MINAS",
  },
  {
    codigo_do_IGBE: 4202438,
    UF: "SC",
    nome_da_cidade: "BOCAINA DO SUL",
  },
  {
    codigo_do_IGBE: 3107307,
    UF: "MG",
    nome_da_cidade: "BOCAIUVA",
  },
  {
    codigo_do_IGBE: 4103107,
    UF: "PR",
    nome_da_cidade: "BOCAIUVA DO SUL",
  },
  {
    codigo_do_IGBE: 2401651,
    UF: "RN",
    nome_da_cidade: "BODO",
  },
  {
    codigo_do_IGBE: 2602001,
    UF: "PE",
    nome_da_cidade: "BODOCO",
  },
  {
    codigo_do_IGBE: 5002159,
    UF: "MS",
    nome_da_cidade: "BODOQUENA",
  },
  {
    codigo_do_IGBE: 3506904,
    UF: "SP",
    nome_da_cidade: "BOFETE",
  },
  {
    codigo_do_IGBE: 2602100,
    UF: "PE",
    nome_da_cidade: "BOM CONSELHO",
  },
  {
    codigo_do_IGBE: 3107406,
    UF: "MG",
    nome_da_cidade: "BOM DESPACHO",
  },
  {
    codigo_do_IGBE: 2102002,
    UF: "MA",
    nome_da_cidade: "BOM JARDIM",
  },
  {
    codigo_do_IGBE: 3300506,
    UF: "RJ",
    nome_da_cidade: "BOM JARDIM",
  },
  {
    codigo_do_IGBE: 4202503,
    UF: "SC",
    nome_da_cidade: "BOM JARDIM DA SERRA",
  },
  {
    codigo_do_IGBE: 5203401,
    UF: "GO",
    nome_da_cidade: "BOM JARDIM DE GOIAS",
  },
  {
    codigo_do_IGBE: 3107505,
    UF: "MG",
    nome_da_cidade: "BOM JARDIM DE MINAS",
  },
  {
    codigo_do_IGBE: 2502201,
    UF: "PB",
    nome_da_cidade: "BOM JESUS",
  },
  {
    codigo_do_IGBE: 2201903,
    UF: "PI",
    nome_da_cidade: "BOM JESUS",
  },
  {
    codigo_do_IGBE: 2401701,
    UF: "RN",
    nome_da_cidade: "BOM JESUS",
  },
  {
    codigo_do_IGBE: 4302303,
    UF: "RS",
    nome_da_cidade: "BOM JESUS",
  },
  {
    codigo_do_IGBE: 2903904,
    UF: "BA",
    nome_da_cidade: "BOM JESUS DA LAPA",
  },
  {
    codigo_do_IGBE: 3107604,
    UF: "MG",
    nome_da_cidade: "BOM JESUS DA PENHA",
  },
  {
    codigo_do_IGBE: 2102036,
    UF: "MA",
    nome_da_cidade: "BOM JESUS DAS SELVAS",
  },
  {
    codigo_do_IGBE: 5203500,
    UF: "GO",
    nome_da_cidade: "BOM JESUS DE GOIAS",
  },
  {
    codigo_do_IGBE: 3107703,
    UF: "MG",
    nome_da_cidade: "BOM JESUS DO AMPARO",
  },
  {
    codigo_do_IGBE: 5101852,
    UF: "MT",
    nome_da_cidade: "BOM JESUS DO ARAGUAIA",
  },
  {
    codigo_do_IGBE: 3107802,
    UF: "MG",
    nome_da_cidade: "BOM JESUS DO GALHO",
  },
  {
    codigo_do_IGBE: 3201100,
    UF: "ES",
    nome_da_cidade: "BOM JESUS DO NORTE",
  },
  {
    codigo_do_IGBE: 4202578,
    UF: "SC",
    nome_da_cidade: "BOM JESUS DO OESTE",
  },
  {
    codigo_do_IGBE: 4103156,
    UF: "PR",
    nome_da_cidade: "BOM JESUS DO SUL",
  },
  {
    codigo_do_IGBE: 1501576,
    UF: "PA",
    nome_da_cidade: "BOM JESUS DO TOCANTINS",
  },
  {
    codigo_do_IGBE: 1703305,
    UF: "TO",
    nome_da_cidade: "BOM JESUS DO TOCANTINS",
  },
  {
    codigo_do_IGBE: 3507100,
    UF: "SP",
    nome_da_cidade: "BOM JESUS DOS PERDOES",
  },
  {
    codigo_do_IGBE: 2102077,
    UF: "MA",
    nome_da_cidade: "BOM LUGAR",
  },
  {
    codigo_do_IGBE: 4302352,
    UF: "RS",
    nome_da_cidade: "BOM PRINCIPIO",
  },
  {
    codigo_do_IGBE: 4302378,
    UF: "RS",
    nome_da_cidade: "BOM PROGRESSO",
  },
  {
    codigo_do_IGBE: 3107901,
    UF: "MG",
    nome_da_cidade: "BOM REPOUSO",
  },
  {
    codigo_do_IGBE: 4202602,
    UF: "SC",
    nome_da_cidade: "BOM RETIRO",
  },
  {
    codigo_do_IGBE: 4302402,
    UF: "RS",
    nome_da_cidade: "BOM RETIRO DO SUL",
  },
  {
    codigo_do_IGBE: 3108008,
    UF: "MG",
    nome_da_cidade: "BOM SUCESSO",
  },
  {
    codigo_do_IGBE: 2502300,
    UF: "PB",
    nome_da_cidade: "BOM SUCESSO",
  },
  {
    codigo_do_IGBE: 4103206,
    UF: "PR",
    nome_da_cidade: "BOM SUCESSO",
  },
  {
    codigo_do_IGBE: 4103222,
    UF: "PR",
    nome_da_cidade: "BOM SUCESSO DO SUL",
  },
  {
    codigo_do_IGBE: 4202453,
    UF: "SC",
    nome_da_cidade: "BOMBINHAS",
  },
  {
    codigo_do_IGBE: 3108107,
    UF: "MG",
    nome_da_cidade: "BONFIM",
  },
  {
    codigo_do_IGBE: 1400159,
    UF: "RR",
    nome_da_cidade: "BONFIM",
  },
  {
    codigo_do_IGBE: 2201929,
    UF: "PI",
    nome_da_cidade: "BONFIM DO PIAUI",
  },
  {
    codigo_do_IGBE: 5203559,
    UF: "GO",
    nome_da_cidade: "BONFINOPOLIS",
  },
  {
    codigo_do_IGBE: 3108206,
    UF: "MG",
    nome_da_cidade: "BONFINOPOLIS DE MINAS",
  },
  {
    codigo_do_IGBE: 2904001,
    UF: "BA",
    nome_da_cidade: "BONINAL",
  },
  {
    codigo_do_IGBE: 2904050,
    UF: "BA",
    nome_da_cidade: "BONITO",
  },
  {
    codigo_do_IGBE: 5002209,
    UF: "MS",
    nome_da_cidade: "BONITO",
  },
  {
    codigo_do_IGBE: 1501600,
    UF: "PA",
    nome_da_cidade: "BONITO",
  },
  {
    codigo_do_IGBE: 2602308,
    UF: "PE",
    nome_da_cidade: "BONITO",
  },
  {
    codigo_do_IGBE: 2502409,
    UF: "PB",
    nome_da_cidade: "BONITO DE SANTA FE",
  },
  {
    codigo_do_IGBE: 5203575,
    UF: "GO",
    nome_da_cidade: "BONOPOLIS",
  },
  {
    codigo_do_IGBE: 2502508,
    UF: "PB",
    nome_da_cidade: "BOQUEIRAO",
  },
  {
    codigo_do_IGBE: 4302451,
    UF: "RS",
    nome_da_cidade: "BOQUEIRAO DO LEAO",
  },
  {
    codigo_do_IGBE: 2201945,
    UF: "PI",
    nome_da_cidade: "BOQUEIRAO DO PIAUI",
  },
  {
    codigo_do_IGBE: 2800670,
    UF: "SE",
    nome_da_cidade: "BOQUIM",
  },
  {
    codigo_do_IGBE: 2904100,
    UF: "BA",
    nome_da_cidade: "BOQUIRA",
  },
  {
    codigo_do_IGBE: 3507209,
    UF: "SP",
    nome_da_cidade: "BORA",
  },
  {
    codigo_do_IGBE: 1300805,
    UF: "AM",
    nome_da_cidade: "BORBA",
  },
  {
    codigo_do_IGBE: 2502706,
    UF: "PB",
    nome_da_cidade: "BORBOREMA",
  },
  {
    codigo_do_IGBE: 3507407,
    UF: "SP",
    nome_da_cidade: "BORBOREMA",
  },
  {
    codigo_do_IGBE: 3108305,
    UF: "MG",
    nome_da_cidade: "BORDA DA MATA",
  },
  {
    codigo_do_IGBE: 3507456,
    UF: "SP",
    nome_da_cidade: "BOREBI",
  },
  {
    codigo_do_IGBE: 4103305,
    UF: "PR",
    nome_da_cidade: "BORRAZOPOLIS",
  },
  {
    codigo_do_IGBE: 3108404,
    UF: "MG",
    nome_da_cidade: "BOTELHOS",
  },
  {
    codigo_do_IGBE: 3507506,
    UF: "SP",
    nome_da_cidade: "BOTUCATU",
  },
  {
    codigo_do_IGBE: 3108503,
    UF: "MG",
    nome_da_cidade: "BOTUMIRIM",
  },
  {
    codigo_do_IGBE: 2904209,
    UF: "BA",
    nome_da_cidade: "BOTUPORA",
  },
  {
    codigo_do_IGBE: 4302584,
    UF: "RS",
    nome_da_cidade: "BOZANO",
  },
  {
    codigo_do_IGBE: 4202800,
    UF: "SC",
    nome_da_cidade: "BRACO DO NORTE",
  },
  {
    codigo_do_IGBE: 4202859,
    UF: "SC",
    nome_da_cidade: "BRACO DO TROMBUDO",
  },
  {
    codigo_do_IGBE: 1501709,
    UF: "PA",
    nome_da_cidade: "BRAGANCA",
  },
  {
    codigo_do_IGBE: 4103354,
    UF: "PR",
    nome_da_cidade: "BRAGANEY",
  },
  {
    codigo_do_IGBE: 2701100,
    UF: "AL",
    nome_da_cidade: "BRANQUINHA",
  },
  {
    codigo_do_IGBE: 3108701,
    UF: "MG",
    nome_da_cidade: "BRAS PIRES",
  },
  {
    codigo_do_IGBE: 1501725,
    UF: "PA",
    nome_da_cidade: "BRASIL NOVO",
  },
  {
    codigo_do_IGBE: 3108552,
    UF: "MG",
    nome_da_cidade: "BRASILANDIA DE MINAS",
  },
  {
    codigo_do_IGBE: 4103370,
    UF: "PR",
    nome_da_cidade: "BRASILANDIA DO SUL",
  },
  {
    codigo_do_IGBE: 1703602,
    UF: "TO",
    nome_da_cidade: "BRASILANDIA DO TOCANTINS",
  },
  {
    codigo_do_IGBE: 1200104,
    UF: "AC",
    nome_da_cidade: "BRASILEIA",
  },
  {
    codigo_do_IGBE: 2201960,
    UF: "PI",
    nome_da_cidade: "BRASILEIRA",
  },
  {
    codigo_do_IGBE: 3108602,
    UF: "MG",
    nome_da_cidade: "BRASILIA DE MINAS",
  },
  {
    codigo_do_IGBE: 5101902,
    UF: "MT",
    nome_da_cidade: "BRASNORTE",
  },
  {
    codigo_do_IGBE: 3108909,
    UF: "MG",
    nome_da_cidade: "BRASOPOLIS",
  },
  {
    codigo_do_IGBE: 3507704,
    UF: "SP",
    nome_da_cidade: "BRAUNA",
  },
  {
    codigo_do_IGBE: 3108800,
    UF: "MG",
    nome_da_cidade: "BRAUNAS",
  },
  {
    codigo_do_IGBE: 5203609,
    UF: "GO",
    nome_da_cidade: "BRAZABRANTES",
  },
  {
    codigo_do_IGBE: 3201159,
    UF: "ES",
    nome_da_cidade: "BREJETUBA",
  },
  {
    codigo_do_IGBE: 2401800,
    UF: "RN",
    nome_da_cidade: "BREJINHO",
  },
  {
    codigo_do_IGBE: 1703701,
    UF: "TO",
    nome_da_cidade: "BREJINHO DE NAZARE",
  },
  {
    codigo_do_IGBE: 2102101,
    UF: "MA",
    nome_da_cidade: "BREJO",
  },
  {
    codigo_do_IGBE: 3507753,
    UF: "SP",
    nome_da_cidade: "BREJO ALEGRE",
  },
  {
    codigo_do_IGBE: 2602605,
    UF: "PE",
    nome_da_cidade: "BREJO DA MADRE DE DEUS",
  },
  {
    codigo_do_IGBE: 2102150,
    UF: "MA",
    nome_da_cidade: "BREJO DE AREIA",
  },
  {
    codigo_do_IGBE: 2502805,
    UF: "PB",
    nome_da_cidade: "BREJO DO CRUZ",
  },
  {
    codigo_do_IGBE: 2201988,
    UF: "PI",
    nome_da_cidade: "BREJO DO PIAUI",
  },
  {
    codigo_do_IGBE: 2800704,
    UF: "SE",
    nome_da_cidade: "BREJO GRANDE",
  },
  {
    codigo_do_IGBE: 2302503,
    UF: "CE",
    nome_da_cidade: "BREJO SANTO",
  },
  {
    codigo_do_IGBE: 2904308,
    UF: "BA",
    nome_da_cidade: "BREJOES",
  },
  {
    codigo_do_IGBE: 2904407,
    UF: "BA",
    nome_da_cidade: "BREJOLANDIA",
  },
  {
    codigo_do_IGBE: 1501782,
    UF: "PA",
    nome_da_cidade: "BREU BRANCO",
  },
  {
    codigo_do_IGBE: 1501808,
    UF: "PA",
    nome_da_cidade: "BREVES",
  },
  {
    codigo_do_IGBE: 5203807,
    UF: "GO",
    nome_da_cidade: "BRITANIA",
  },
  {
    codigo_do_IGBE: 4302659,
    UF: "RS",
    nome_da_cidade: "BROCHIER",
  },
  {
    codigo_do_IGBE: 3507803,
    UF: "SP",
    nome_da_cidade: "BRODOWSKI",
  },
  {
    codigo_do_IGBE: 3507902,
    UF: "SP",
    nome_da_cidade: "BROTAS",
  },
  {
    codigo_do_IGBE: 2904506,
    UF: "BA",
    nome_da_cidade: "BROTAS DE MACAUBAS",
  },
  {
    codigo_do_IGBE: 3109006,
    UF: "MG",
    nome_da_cidade: "BRUMADINHO",
  },
  {
    codigo_do_IGBE: 2904605,
    UF: "BA",
    nome_da_cidade: "BRUMADO",
  },
  {
    codigo_do_IGBE: 4202875,
    UF: "SC",
    nome_da_cidade: "BRUNOPOLIS",
  },
  {
    codigo_do_IGBE: 4202909,
    UF: "SC",
    nome_da_cidade: "BRUSQUE",
  },
  {
    codigo_do_IGBE: 3109204,
    UF: "MG",
    nome_da_cidade: "BUENOPOLIS",
  },
  {
    codigo_do_IGBE: 2602704,
    UF: "PE",
    nome_da_cidade: "BUENOS AIRES",
  },
  {
    codigo_do_IGBE: 2904704,
    UF: "BA",
    nome_da_cidade: "BUERAREMA",
  },
  {
    codigo_do_IGBE: 3109253,
    UF: "MG",
    nome_da_cidade: "BUGRE",
  },
  {
    codigo_do_IGBE: 2602803,
    UF: "PE",
    nome_da_cidade: "BUIQUE",
  },
  {
    codigo_do_IGBE: 1501907,
    UF: "PA",
    nome_da_cidade: "BUJARU",
  },
  {
    codigo_do_IGBE: 3508009,
    UF: "SP",
    nome_da_cidade: "BURI",
  },
  {
    codigo_do_IGBE: 3508108,
    UF: "SP",
    nome_da_cidade: "BURITAMA",
  },
  {
    codigo_do_IGBE: 2102200,
    UF: "MA",
    nome_da_cidade: "BURITI",
  },
  {
    codigo_do_IGBE: 5203906,
    UF: "GO",
    nome_da_cidade: "BURITI ALEGRE",
  },
  {
    codigo_do_IGBE: 2102309,
    UF: "MA",
    nome_da_cidade: "BURITI BRAVO",
  },
  {
    codigo_do_IGBE: 1703800,
    UF: "TO",
    nome_da_cidade: "BURITI DO TOCANTINS",
  },
  {
    codigo_do_IGBE: 2202000,
    UF: "PI",
    nome_da_cidade: "BURITI DOS LOPES",
  },
  {
    codigo_do_IGBE: 2202026,
    UF: "PI",
    nome_da_cidade: "BURITI DOS MONTES",
  },
  {
    codigo_do_IGBE: 2102325,
    UF: "MA",
    nome_da_cidade: "BURITICUPU",
  },
  {
    codigo_do_IGBE: 2904753,
    UF: "BA",
    nome_da_cidade: "BURITIRAMA",
  },
  {
    codigo_do_IGBE: 2102358,
    UF: "MA",
    nome_da_cidade: "BURITIRANA",
  },
  {
    codigo_do_IGBE: 1100452,
    UF: "RO",
    nome_da_cidade: "BURITIS",
  },
  {
    codigo_do_IGBE: 3508207,
    UF: "SP",
    nome_da_cidade: "BURITIZAL",
  },
  {
    codigo_do_IGBE: 3109402,
    UF: "MG",
    nome_da_cidade: "BURITIZEIRO",
  },
  {
    codigo_do_IGBE: 4302709,
    UF: "RS",
    nome_da_cidade: "BUTIA",
  },
  {
    codigo_do_IGBE: 1300839,
    UF: "AM",
    nome_da_cidade: "CAAPIRANGA",
  },
  {
    codigo_do_IGBE: 2503001,
    UF: "PB",
    nome_da_cidade: "CAAPORA",
  },
  {
    codigo_do_IGBE: 5002407,
    UF: "MS",
    nome_da_cidade: "CAARAPO",
  },
  {
    codigo_do_IGBE: 2904852,
    UF: "BA",
    nome_da_cidade: "CABACEIRAS DO PARAGUACU",
  },
  {
    codigo_do_IGBE: 3109451,
    UF: "MG",
    nome_da_cidade: "CABECEIRA GRANDE",
  },
  {
    codigo_do_IGBE: 5204003,
    UF: "GO",
    nome_da_cidade: "CABECEIRAS",
  },
  {
    codigo_do_IGBE: 2202059,
    UF: "PI",
    nome_da_cidade: "CABECEIRAS DO PIAUI",
  },
  {
    codigo_do_IGBE: 2503209,
    UF: "PB",
    nome_da_cidade: "CABEDELO",
  },
  {
    codigo_do_IGBE: 1100031,
    UF: "RO",
    nome_da_cidade: "CABIXI",
  },
  {
    codigo_do_IGBE: 2602902,
    UF: "PE",
    nome_da_cidade: "CABO DE SANTO AGOSTINHO",
  },
  {
    codigo_do_IGBE: 3300704,
    UF: "RJ",
    nome_da_cidade: "CABO FRIO",
  },
  {
    codigo_do_IGBE: 3109501,
    UF: "MG",
    nome_da_cidade: "CABO VERDE",
  },
  {
    codigo_do_IGBE: 3508306,
    UF: "SP",
    nome_da_cidade: "CABRALIA PAULISTA",
  },
  {
    codigo_do_IGBE: 3508405,
    UF: "SP",
    nome_da_cidade: "CABREUVA",
  },
  {
    codigo_do_IGBE: 2603009,
    UF: "PE",
    nome_da_cidade: "CABROBO",
  },
  {
    codigo_do_IGBE: 3508504,
    UF: "SP",
    nome_da_cidade: "CACAPAVA",
  },
  {
    codigo_do_IGBE: 1100601,
    UF: "RO",
    nome_da_cidade: "CACAULANDIA",
  },
  {
    codigo_do_IGBE: 4302907,
    UF: "RS",
    nome_da_cidade: "CACEQUI",
  },
  {
    codigo_do_IGBE: 5102504,
    UF: "MT",
    nome_da_cidade: "CACERES",
  },
  {
    codigo_do_IGBE: 2904902,
    UF: "BA",
    nome_da_cidade: "CACHOEIRA",
  },
  {
    codigo_do_IGBE: 5204102,
    UF: "GO",
    nome_da_cidade: "CACHOEIRA ALTA",
  },
  {
    codigo_do_IGBE: 3109600,
    UF: "MG",
    nome_da_cidade: "CACHOEIRA DA PRATA",
  },
  {
    codigo_do_IGBE: 5204201,
    UF: "GO",
    nome_da_cidade: "CACHOEIRA DE GOIAS",
  },
  {
    codigo_do_IGBE: 3109709,
    UF: "MG",
    nome_da_cidade: "CACHOEIRA DE MINAS",
  },
  {
    codigo_do_IGBE: 1502004,
    UF: "PA",
    nome_da_cidade: "CACHOEIRA DO ARARI",
  },
  {
    codigo_do_IGBE: 1501956,
    UF: "PA",
    nome_da_cidade: "CACHOEIRA DO PIRIA",
  },
  {
    codigo_do_IGBE: 4303004,
    UF: "RS",
    nome_da_cidade: "CACHOEIRA DO SUL",
  },
  {
    codigo_do_IGBE: 2503308,
    UF: "PB",
    nome_da_cidade: "CACHOEIRA DOS INDIOS",
  },
  {
    codigo_do_IGBE: 3109808,
    UF: "MG",
    nome_da_cidade: "CACHOEIRA DOURADA",
  },
  {
    codigo_do_IGBE: 2102374,
    UF: "MA",
    nome_da_cidade: "CACHOEIRA GRANDE",
  },
  {
    codigo_do_IGBE: 3508603,
    UF: "SP",
    nome_da_cidade: "CACHOEIRA PAULISTA",
  },
  {
    codigo_do_IGBE: 3300803,
    UF: "RJ",
    nome_da_cidade: "CACHOEIRAS DE MACACU",
  },
  {
    codigo_do_IGBE: 2603108,
    UF: "PE",
    nome_da_cidade: "CACHOEIRINHA",
  },
  {
    codigo_do_IGBE: 4303103,
    UF: "RS",
    nome_da_cidade: "CACHOEIRINHA",
  },
  {
    codigo_do_IGBE: 1703826,
    UF: "TO",
    nome_da_cidade: "CACHOEIRINHA",
  },
  {
    codigo_do_IGBE: 2503407,
    UF: "PB",
    nome_da_cidade: "CACIMBA DE AREIA",
  },
  {
    codigo_do_IGBE: 2503506,
    UF: "PB",
    nome_da_cidade: "CACIMBA DE DENTRO",
  },
  {
    codigo_do_IGBE: 2503555,
    UF: "PB",
    nome_da_cidade: "CACIMBAS",
  },
  {
    codigo_do_IGBE: 2701209,
    UF: "AL",
    nome_da_cidade: "CACIMBINHAS",
  },
  {
    codigo_do_IGBE: 4303202,
    UF: "RS",
    nome_da_cidade: "CACIQUE DOBLE",
  },
  {
    codigo_do_IGBE: 1100049,
    UF: "RO",
    nome_da_cidade: "CACOAL",
  },
  {
    codigo_do_IGBE: 3508702,
    UF: "SP",
    nome_da_cidade: "CACONDE",
  },
  {
    codigo_do_IGBE: 5204300,
    UF: "GO",
    nome_da_cidade: "CACU",
  },
  {
    codigo_do_IGBE: 2905008,
    UF: "BA",
    nome_da_cidade: "CACULE",
  },
  {
    codigo_do_IGBE: 2905107,
    UF: "BA",
    nome_da_cidade: "CAEM",
  },
  {
    codigo_do_IGBE: 2905156,
    UF: "BA",
    nome_da_cidade: "CAETANOS",
  },
  {
    codigo_do_IGBE: 3110004,
    UF: "MG",
    nome_da_cidade: "CAETE",
  },
  {
    codigo_do_IGBE: 2603207,
    UF: "PE",
    nome_da_cidade: "CAETES",
  },
  {
    codigo_do_IGBE: 2905206,
    UF: "BA",
    nome_da_cidade: "CAETITE",
  },
  {
    codigo_do_IGBE: 2905305,
    UF: "BA",
    nome_da_cidade: "CAFARNAUM",
  },
  {
    codigo_do_IGBE: 4103404,
    UF: "PR",
    nome_da_cidade: "CAFEARA",
  },
  {
    codigo_do_IGBE: 4103453,
    UF: "PR",
    nome_da_cidade: "CAFELANDIA",
  },
  {
    codigo_do_IGBE: 3508801,
    UF: "SP",
    nome_da_cidade: "CAFELANDIA",
  },
  {
    codigo_do_IGBE: 3508900,
    UF: "SP",
    nome_da_cidade: "CAIABU",
  },
  {
    codigo_do_IGBE: 3110103,
    UF: "MG",
    nome_da_cidade: "CAIANA",
  },
  {
    codigo_do_IGBE: 5204409,
    UF: "GO",
    nome_da_cidade: "CAIAPONIA",
  },
  {
    codigo_do_IGBE: 4203105,
    UF: "SC",
    nome_da_cidade: "CAIBI",
  },
  {
    codigo_do_IGBE: 2503605,
    UF: "PB",
    nome_da_cidade: "CAICARA",
  },
  {
    codigo_do_IGBE: 4303400,
    UF: "RS",
    nome_da_cidade: "CAICARA",
  },
  {
    codigo_do_IGBE: 2401859,
    UF: "RN",
    nome_da_cidade: "CAICARA DO NORTE",
  },
  {
    codigo_do_IGBE: 2401909,
    UF: "RN",
    nome_da_cidade: "CAICARA DO RIO DO VENTO",
  },
  {
    codigo_do_IGBE: 2402006,
    UF: "RN",
    nome_da_cidade: "CAICO",
  },
  {
    codigo_do_IGBE: 3509007,
    UF: "SP",
    nome_da_cidade: "CAIEIRAS",
  },
  {
    codigo_do_IGBE: 2905404,
    UF: "BA",
    nome_da_cidade: "CAIRU",
  },
  {
    codigo_do_IGBE: 3509106,
    UF: "SP",
    nome_da_cidade: "CAIUA",
  },
  {
    codigo_do_IGBE: 2102408,
    UF: "MA",
    nome_da_cidade: "CAJAPIO",
  },
  {
    codigo_do_IGBE: 2102507,
    UF: "MA",
    nome_da_cidade: "CAJARI",
  },
  {
    codigo_do_IGBE: 3509254,
    UF: "SP",
    nome_da_cidade: "CAJATI",
  },
  {
    codigo_do_IGBE: 2202075,
    UF: "PI",
    nome_da_cidade: "CAJAZEIRAS DO PIAUI",
  },
  {
    codigo_do_IGBE: 2503753,
    UF: "PB",
    nome_da_cidade: "CAJAZEIRINHAS",
  },
  {
    codigo_do_IGBE: 3509304,
    UF: "SP",
    nome_da_cidade: "CAJOBI",
  },
  {
    codigo_do_IGBE: 2701308,
    UF: "AL",
    nome_da_cidade: "CAJUEIRO",
  },
  {
    codigo_do_IGBE: 3110202,
    UF: "MG",
    nome_da_cidade: "CAJURI",
  },
  {
    codigo_do_IGBE: 3509403,
    UF: "SP",
    nome_da_cidade: "CAJURU",
  },
  {
    codigo_do_IGBE: 2603306,
    UF: "PE",
    nome_da_cidade: "CALCADO",
  },
  {
    codigo_do_IGBE: 1600204,
    UF: "AP",
    nome_da_cidade: "CALCOENE",
  },
  {
    codigo_do_IGBE: 3110301,
    UF: "MG",
    nome_da_cidade: "CALDAS",
  },
  {
    codigo_do_IGBE: 2503803,
    UF: "PB",
    nome_da_cidade: "CALDAS BRANDAO",
  },
  {
    codigo_do_IGBE: 5204508,
    UF: "GO",
    nome_da_cidade: "CALDAS NOVAS",
  },
  {
    codigo_do_IGBE: 5204557,
    UF: "GO",
    nome_da_cidade: "CALDAZINHA",
  },
  {
    codigo_do_IGBE: 2202091,
    UF: "PI",
    nome_da_cidade: "CALDEIRAO GRANDE DO PIAUI",
  },
  {
    codigo_do_IGBE: 4103503,
    UF: "PR",
    nome_da_cidade: "CALIFORNIA",
  },
  {
    codigo_do_IGBE: 4203154,
    UF: "SC",
    nome_da_cidade: "CALMON",
  },
  {
    codigo_do_IGBE: 2603405,
    UF: "PE",
    nome_da_cidade: "CALUMBI",
  },
  {
    codigo_do_IGBE: 2905602,
    UF: "BA",
    nome_da_cidade: "CAMACAN",
  },
  {
    codigo_do_IGBE: 2905701,
    UF: "BA",
    nome_da_cidade: "CAMACARI",
  },
  {
    codigo_do_IGBE: 3110400,
    UF: "MG",
    nome_da_cidade: "CAMACHO",
  },
  {
    codigo_do_IGBE: 2905800,
    UF: "BA",
    nome_da_cidade: "CAMAMU",
  },
  {
    codigo_do_IGBE: 3110509,
    UF: "MG",
    nome_da_cidade: "CAMANDUCAIA",
  },
  {
    codigo_do_IGBE: 5002605,
    UF: "MS",
    nome_da_cidade: "CAMAPUA",
  },
  {
    codigo_do_IGBE: 4303509,
    UF: "RS",
    nome_da_cidade: "CAMAQUA",
  },
  {
    codigo_do_IGBE: 2603454,
    UF: "PE",
    nome_da_cidade: "CAMARAGIBE",
  },
  {
    codigo_do_IGBE: 4303558,
    UF: "RS",
    nome_da_cidade: "CAMARGO",
  },
  {
    codigo_do_IGBE: 4103602,
    UF: "PR",
    nome_da_cidade: "CAMBARA",
  },
  {
    codigo_do_IGBE: 4103701,
    UF: "PR",
    nome_da_cidade: "CAMBE",
  },
  {
    codigo_do_IGBE: 4103800,
    UF: "PR",
    nome_da_cidade: "CAMBIRA",
  },
  {
    codigo_do_IGBE: 4203204,
    UF: "SC",
    nome_da_cidade: "CAMBORIU",
  },
  {
    codigo_do_IGBE: 3300902,
    UF: "RJ",
    nome_da_cidade: "CAMBUCI",
  },
  {
    codigo_do_IGBE: 3110707,
    UF: "MG",
    nome_da_cidade: "CAMBUQUIRA",
  },
  {
    codigo_do_IGBE: 1502103,
    UF: "PA",
    nome_da_cidade: "CAMETA",
  },
  {
    codigo_do_IGBE: 2302602,
    UF: "CE",
    nome_da_cidade: "CAMOCIM",
  },
  {
    codigo_do_IGBE: 2603504,
    UF: "PE",
    nome_da_cidade: "CAMOCIM DE SAO FELIX",
  },
  {
    codigo_do_IGBE: 3110806,
    UF: "MG",
    nome_da_cidade: "CAMPANARIO",
  },
  {
    codigo_do_IGBE: 3110905,
    UF: "MG",
    nome_da_cidade: "CAMPANHA",
  },
  {
    codigo_do_IGBE: 2701357,
    UF: "AL",
    nome_da_cidade: "CAMPESTRE",
  },
  {
    codigo_do_IGBE: 3111002,
    UF: "MG",
    nome_da_cidade: "CAMPESTRE",
  },
  {
    codigo_do_IGBE: 5204607,
    UF: "GO",
    nome_da_cidade: "CAMPESTRE DE GOIAS",
  },
  {
    codigo_do_IGBE: 4103909,
    UF: "PR",
    nome_da_cidade: "CAMPINA DA LAGOA",
  },
  {
    codigo_do_IGBE: 4303707,
    UF: "RS",
    nome_da_cidade: "CAMPINA DAS MISSOES",
  },
  {
    codigo_do_IGBE: 3509452,
    UF: "SP",
    nome_da_cidade: "CAMPINA DO MONTE ALEGRE",
  },
  {
    codigo_do_IGBE: 4103958,
    UF: "PR",
    nome_da_cidade: "CAMPINA DO SIMAO",
  },
  {
    codigo_do_IGBE: 2504009,
    UF: "PB",
    nome_da_cidade: "CAMPINA GRANDE",
  },
  {
    codigo_do_IGBE: 4104006,
    UF: "PR",
    nome_da_cidade: "CAMPINA GRANDE DO SUL",
  },
  {
    codigo_do_IGBE: 3111101,
    UF: "MG",
    nome_da_cidade: "CAMPINA VERDE",
  },
  {
    codigo_do_IGBE: 5204656,
    UF: "GO",
    nome_da_cidade: "CAMPINACU",
  },
  {
    codigo_do_IGBE: 5102603,
    UF: "MT",
    nome_da_cidade: "CAMPINAPOLIS",
  },
  {
    codigo_do_IGBE: 3509502,
    UF: "SP",
    nome_da_cidade: "CAMPINAS",
  },
  {
    codigo_do_IGBE: 2202109,
    UF: "PI",
    nome_da_cidade: "CAMPINAS DO PIAUI",
  },
  {
    codigo_do_IGBE: 4303806,
    UF: "RS",
    nome_da_cidade: "CAMPINAS DO SUL",
  },
  {
    codigo_do_IGBE: 5204706,
    UF: "GO",
    nome_da_cidade: "CAMPINORTE",
  },
  {
    codigo_do_IGBE: 2701407,
    UF: "AL",
    nome_da_cidade: "CAMPO ALEGRE",
  },
  {
    codigo_do_IGBE: 4203303,
    UF: "SC",
    nome_da_cidade: "CAMPO ALEGRE",
  },
  {
    codigo_do_IGBE: 2202117,
    UF: "PI",
    nome_da_cidade: "CAMPO ALEGRE DO FIDALGO",
  },
  {
    codigo_do_IGBE: 3111150,
    UF: "MG",
    nome_da_cidade: "CAMPO AZUL",
  },
  {
    codigo_do_IGBE: 3111200,
    UF: "MG",
    nome_da_cidade: "CAMPO BELO",
  },
  {
    codigo_do_IGBE: 4203402,
    UF: "SC",
    nome_da_cidade: "CAMPO BELO DO SUL",
  },
  {
    codigo_do_IGBE: 4303905,
    UF: "RS",
    nome_da_cidade: "CAMPO BOM",
  },
  {
    codigo_do_IGBE: 4104055,
    UF: "PR",
    nome_da_cidade: "CAMPO BONITO",
  },
  {
    codigo_do_IGBE: 2516409,
    UF: "PB",
    nome_da_cidade: "CAMPO DE SANTANA",
  },
  {
    codigo_do_IGBE: 2801009,
    UF: "SE",
    nome_da_cidade: "CAMPO DO BRITO",
  },
  {
    codigo_do_IGBE: 3111309,
    UF: "MG",
    nome_da_cidade: "CAMPO DO MEIO",
  },
  {
    codigo_do_IGBE: 4104105,
    UF: "PR",
    nome_da_cidade: "CAMPO DO TENENTE",
  },
  {
    codigo_do_IGBE: 5300108,
    UF: "DF",
    nome_da_cidade: "BRASILIA",
  },
  {
    codigo_do_IGBE: 2906006,
    UF: "BA",
    nome_da_cidade: "CAMPO FORMOSO",
  },
  {
    codigo_do_IGBE: 2701506,
    UF: "AL",
    nome_da_cidade: "CAMPO GRANDE",
  },
  {
    codigo_do_IGBE: 5002704,
    UF: "MS",
    nome_da_cidade: "CAMPO GRANDE",
  },
  {
    codigo_do_IGBE: 4104204,
    UF: "PR",
    nome_da_cidade: "CAMPO LARGO",
  },
  {
    codigo_do_IGBE: 2202174,
    UF: "PI",
    nome_da_cidade: "CAMPO LARGO DO PIAUI",
  },
  {
    codigo_do_IGBE: 5204854,
    UF: "GO",
    nome_da_cidade: "CAMPO LIMPO DE GOIAS",
  },
  {
    codigo_do_IGBE: 3509601,
    UF: "SP",
    nome_da_cidade: "CAMPO LIMPO PAULISTA",
  },
  {
    codigo_do_IGBE: 4104253,
    UF: "PR",
    nome_da_cidade: "CAMPO MAGRO",
  },
  {
    codigo_do_IGBE: 2202208,
    UF: "PI",
    nome_da_cidade: "CAMPO MAIOR",
  },
  {
    codigo_do_IGBE: 4104303,
    UF: "PR",
    nome_da_cidade: "CAMPO MOURAO",
  },
  {
    codigo_do_IGBE: 4304002,
    UF: "RS",
    nome_da_cidade: "CAMPO NOVO",
  },
  {
    codigo_do_IGBE: 5102637,
    UF: "MT",
    nome_da_cidade: "CAMPO NOVO DO PARECIS",
  },
  {
    codigo_do_IGBE: 2402105,
    UF: "RN",
    nome_da_cidade: "CAMPO REDONDO",
  },
  {
    codigo_do_IGBE: 5102678,
    UF: "MT",
    nome_da_cidade: "CAMPO VERDE",
  },
  {
    codigo_do_IGBE: 3111507,
    UF: "MG",
    nome_da_cidade: "CAMPOS ALTOS",
  },
  {
    codigo_do_IGBE: 5204904,
    UF: "GO",
    nome_da_cidade: "CAMPOS BELOS",
  },
  {
    codigo_do_IGBE: 4304101,
    UF: "RS",
    nome_da_cidade: "CAMPOS BORGES",
  },
  {
    codigo_do_IGBE: 5102686,
    UF: "MT",
    nome_da_cidade: "CAMPOS DE JULIO",
  },
  {
    codigo_do_IGBE: 3509700,
    UF: "SP",
    nome_da_cidade: "CAMPOS DO JORDAO",
  },
  {
    codigo_do_IGBE: 3111606,
    UF: "MG",
    nome_da_cidade: "CAMPOS GERAIS",
  },
  {
    codigo_do_IGBE: 1703842,
    UF: "TO",
    nome_da_cidade: "CAMPOS LINDOS",
  },
  {
    codigo_do_IGBE: 4203600,
    UF: "SC",
    nome_da_cidade: "CAMPOS NOVOS",
  },
  {
    codigo_do_IGBE: 3509809,
    UF: "SP",
    nome_da_cidade: "CAMPOS NOVOS PAULISTA",
  },
  {
    codigo_do_IGBE: 2302701,
    UF: "CE",
    nome_da_cidade: "CAMPOS SALES",
  },
  {
    codigo_do_IGBE: 5204953,
    UF: "GO",
    nome_da_cidade: "CAMPOS VERDES",
  },
  {
    codigo_do_IGBE: 2603603,
    UF: "PE",
    nome_da_cidade: "CAMUTANGA",
  },
  {
    codigo_do_IGBE: 3111903,
    UF: "MG",
    nome_da_cidade: "CANA VERDE",
  },
  {
    codigo_do_IGBE: 3111705,
    UF: "MG",
    nome_da_cidade: "CANAA",
  },
  {
    codigo_do_IGBE: 1502152,
    UF: "PA",
    nome_da_cidade: "CANAA DOS CARAJAS",
  },
  {
    codigo_do_IGBE: 3509908,
    UF: "SP",
    nome_da_cidade: "CANANEIA",
  },
  {
    codigo_do_IGBE: 2701605,
    UF: "AL",
    nome_da_cidade: "CANAPI",
  },
  {
    codigo_do_IGBE: 2906105,
    UF: "BA",
    nome_da_cidade: "CANAPOLIS",
  },
  {
    codigo_do_IGBE: 3111804,
    UF: "MG",
    nome_da_cidade: "CANAPOLIS",
  },
  {
    codigo_do_IGBE: 2906204,
    UF: "BA",
    nome_da_cidade: "CANARANA",
  },
  {
    codigo_do_IGBE: 5102702,
    UF: "MT",
    nome_da_cidade: "CANARANA",
  },
  {
    codigo_do_IGBE: 3509957,
    UF: "SP",
    nome_da_cidade: "CANAS",
  },
  {
    codigo_do_IGBE: 2906303,
    UF: "BA",
    nome_da_cidade: "CANAVIEIRAS",
  },
  {
    codigo_do_IGBE: 2906402,
    UF: "BA",
    nome_da_cidade: "CANDEAL",
  },
  {
    codigo_do_IGBE: 3112000,
    UF: "MG",
    nome_da_cidade: "CANDEIAS",
  },
  {
    codigo_do_IGBE: 1100809,
    UF: "RO",
    nome_da_cidade: "CANDEIAS DO JAMARI",
  },
  {
    codigo_do_IGBE: 4304200,
    UF: "RS",
    nome_da_cidade: "CANDELARIA",
  },
  {
    codigo_do_IGBE: 2906600,
    UF: "BA",
    nome_da_cidade: "CANDIBA",
  },
  {
    codigo_do_IGBE: 4104402,
    UF: "PR",
    nome_da_cidade: "CANDIDO DE ABREU",
  },
  {
    codigo_do_IGBE: 4304309,
    UF: "RS",
    nome_da_cidade: "CANDIDO GODOI",
  },
  {
    codigo_do_IGBE: 2102606,
    UF: "MA",
    nome_da_cidade: "CANDIDO MENDES",
  },
  {
    codigo_do_IGBE: 3510005,
    UF: "SP",
    nome_da_cidade: "CANDIDO MOTA",
  },
  {
    codigo_do_IGBE: 2906709,
    UF: "BA",
    nome_da_cidade: "CANDIDO SALES",
  },
  {
    codigo_do_IGBE: 4304358,
    UF: "RS",
    nome_da_cidade: "CANDIOTA",
  },
  {
    codigo_do_IGBE: 4104428,
    UF: "PR",
    nome_da_cidade: "CANDOI",
  },
  {
    codigo_do_IGBE: 4304408,
    UF: "RS",
    nome_da_cidade: "CANELA",
  },
  {
    codigo_do_IGBE: 4203709,
    UF: "SC",
    nome_da_cidade: "CANELINHA",
  },
  {
    codigo_do_IGBE: 2402204,
    UF: "RN",
    nome_da_cidade: "CANGUARETAMA",
  },
  {
    codigo_do_IGBE: 4304507,
    UF: "RS",
    nome_da_cidade: "CANGUCU",
  },
  {
    codigo_do_IGBE: 2801108,
    UF: "SE",
    nome_da_cidade: "CANHOBA",
  },
  {
    codigo_do_IGBE: 2603702,
    UF: "PE",
    nome_da_cidade: "CANHOTINHO",
  },
  {
    codigo_do_IGBE: 2302800,
    UF: "CE",
    nome_da_cidade: "CANINDE",
  },
  {
    codigo_do_IGBE: 3510153,
    UF: "SP",
    nome_da_cidade: "CANITAR",
  },
  {
    codigo_do_IGBE: 4304606,
    UF: "RS",
    nome_da_cidade: "CANOAS",
  },
  {
    codigo_do_IGBE: 2906808,
    UF: "BA",
    nome_da_cidade: "CANSANCAO",
  },
  {
    codigo_do_IGBE: 1400175,
    UF: "RR",
    nome_da_cidade: "CANTA",
  },
  {
    codigo_do_IGBE: 4104451,
    UF: "PR",
    nome_da_cidade: "CANTAGALO",
  },
  {
    codigo_do_IGBE: 3301108,
    UF: "RJ",
    nome_da_cidade: "CANTAGALO",
  },
  {
    codigo_do_IGBE: 2102705,
    UF: "MA",
    nome_da_cidade: "CANTANHEDE",
  },
  {
    codigo_do_IGBE: 2202307,
    UF: "PI",
    nome_da_cidade: "CANTO DO BURITI",
  },
  {
    codigo_do_IGBE: 2906824,
    UF: "BA",
    nome_da_cidade: "CANUDOS",
  },
  {
    codigo_do_IGBE: 4304614,
    UF: "RS",
    nome_da_cidade: "CANUDOS DO VALE",
  },
  {
    codigo_do_IGBE: 1300904,
    UF: "AM",
    nome_da_cidade: "CANUTAMA",
  },
  {
    codigo_do_IGBE: 1502202,
    UF: "PA",
    nome_da_cidade: "CAPANEMA",
  },
  {
    codigo_do_IGBE: 4104501,
    UF: "PR",
    nome_da_cidade: "CAPANEMA",
  },
  {
    codigo_do_IGBE: 4203253,
    UF: "SC",
    nome_da_cidade: "CAPAO ALTO",
  },
  {
    codigo_do_IGBE: 3510203,
    UF: "SP",
    nome_da_cidade: "CAPAO BONITO",
  },
  {
    codigo_do_IGBE: 4304655,
    UF: "RS",
    nome_da_cidade: "CAPAO DO CIPO",
  },
  {
    codigo_do_IGBE: 4304663,
    UF: "RS",
    nome_da_cidade: "CAPAO DO LEAO",
  },
  {
    codigo_do_IGBE: 3112109,
    UF: "MG",
    nome_da_cidade: "CAPARAO",
  },
  {
    codigo_do_IGBE: 2701704,
    UF: "AL",
    nome_da_cidade: "CAPELA",
  },
  {
    codigo_do_IGBE: 2801306,
    UF: "SE",
    nome_da_cidade: "CAPELA",
  },
  {
    codigo_do_IGBE: 4304689,
    UF: "RS",
    nome_da_cidade: "CAPELA DE SANTANA",
  },
  {
    codigo_do_IGBE: 3510302,
    UF: "SP",
    nome_da_cidade: "CAPELA DO ALTO",
  },
  {
    codigo_do_IGBE: 2906857,
    UF: "BA",
    nome_da_cidade: "CAPELA DO ALTO ALEGRE",
  },
  {
    codigo_do_IGBE: 3112208,
    UF: "MG",
    nome_da_cidade: "CAPELA NOVA",
  },
  {
    codigo_do_IGBE: 3112307,
    UF: "MG",
    nome_da_cidade: "CAPELINHA",
  },
  {
    codigo_do_IGBE: 3112406,
    UF: "MG",
    nome_da_cidade: "CAPETINGA",
  },
  {
    codigo_do_IGBE: 2504033,
    UF: "PB",
    nome_da_cidade: "CAPIM",
  },
  {
    codigo_do_IGBE: 3112505,
    UF: "MG",
    nome_da_cidade: "CAPIM BRANCO",
  },
  {
    codigo_do_IGBE: 2906873,
    UF: "BA",
    nome_da_cidade: "CAPIM GROSSO",
  },
  {
    codigo_do_IGBE: 4203907,
    UF: "SC",
    nome_da_cidade: "CAPINZAL",
  },
  {
    codigo_do_IGBE: 2302909,
    UF: "CE",
    nome_da_cidade: "CAPISTRANO",
  },
  {
    codigo_do_IGBE: 4304697,
    UF: "RS",
    nome_da_cidade: "CAPITAO",
  },
  {
    codigo_do_IGBE: 3112653,
    UF: "MG",
    nome_da_cidade: "CAPITAO ANDRADE",
  },
  {
    codigo_do_IGBE: 2202406,
    UF: "PI",
    nome_da_cidade: "CAPITAO DE CAMPOS",
  },
  {
    codigo_do_IGBE: 3112703,
    UF: "MG",
    nome_da_cidade: "CAPITAO ENEAS",
  },
  {
    codigo_do_IGBE: 2202455,
    UF: "PI",
    nome_da_cidade: "CAPITAO GERVASIO OLIVEIRA",
  },
  {
    codigo_do_IGBE: 1502301,
    UF: "PA",
    nome_da_cidade: "CAPITAO POCO",
  },
  {
    codigo_do_IGBE: 3112802,
    UF: "MG",
    nome_da_cidade: "CAPITOLIO",
  },
  {
    codigo_do_IGBE: 3510401,
    UF: "SP",
    nome_da_cidade: "CAPIVARI",
  },
  {
    codigo_do_IGBE: 4203956,
    UF: "SC",
    nome_da_cidade: "CAPIVARI DE BAIXO",
  },
  {
    codigo_do_IGBE: 4304671,
    UF: "RS",
    nome_da_cidade: "CAPIVARI DO SUL",
  },
  {
    codigo_do_IGBE: 1200179,
    UF: "AC",
    nome_da_cidade: "CAPIXABA",
  },
  {
    codigo_do_IGBE: 2603801,
    UF: "PE",
    nome_da_cidade: "CAPOEIRAS",
  },
  {
    codigo_do_IGBE: 3112901,
    UF: "MG",
    nome_da_cidade: "CAPUTIRA",
  },
  {
    codigo_do_IGBE: 4304713,
    UF: "RS",
    nome_da_cidade: "CARAA",
  },
  {
    codigo_do_IGBE: 5002803,
    UF: "MS",
    nome_da_cidade: "CARACOL",
  },
  {
    codigo_do_IGBE: 2202505,
    UF: "PI",
    nome_da_cidade: "CARACOL",
  },
  {
    codigo_do_IGBE: 3510500,
    UF: "SP",
    nome_da_cidade: "CARAGUATATUBA",
  },
  {
    codigo_do_IGBE: 3113008,
    UF: "MG",
    nome_da_cidade: "CARAI",
  },
  {
    codigo_do_IGBE: 2906899,
    UF: "BA",
    nome_da_cidade: "CARAIBAS",
  },
  {
    codigo_do_IGBE: 4104659,
    UF: "PR",
    nome_da_cidade: "CARAMBEI",
  },
  {
    codigo_do_IGBE: 3113107,
    UF: "MG",
    nome_da_cidade: "CARANAIBA",
  },
  {
    codigo_do_IGBE: 3113206,
    UF: "MG",
    nome_da_cidade: "CARANDAI",
  },
  {
    codigo_do_IGBE: 3113305,
    UF: "MG",
    nome_da_cidade: "CARANGOLA",
  },
  {
    codigo_do_IGBE: 3300936,
    UF: "RJ",
    nome_da_cidade: "CARAPEBUS",
  },
  {
    codigo_do_IGBE: 3113404,
    UF: "MG",
    nome_da_cidade: "CARATINGA",
  },
  {
    codigo_do_IGBE: 1301001,
    UF: "AM",
    nome_da_cidade: "CARAUARI",
  },
  {
    codigo_do_IGBE: 2402303,
    UF: "RN",
    nome_da_cidade: "CARAUBAS",
  },
  {
    codigo_do_IGBE: 2202539,
    UF: "PI",
    nome_da_cidade: "CARAUBAS DO PIAUI",
  },
  {
    codigo_do_IGBE: 2906907,
    UF: "BA",
    nome_da_cidade: "CARAVELAS",
  },
  {
    codigo_do_IGBE: 4304705,
    UF: "RS",
    nome_da_cidade: "CARAZINHO",
  },
  {
    codigo_do_IGBE: 3113503,
    UF: "MG",
    nome_da_cidade: "CARBONITA",
  },
  {
    codigo_do_IGBE: 2907004,
    UF: "BA",
    nome_da_cidade: "CARDEAL DA SILVA",
  },
  {
    codigo_do_IGBE: 3510708,
    UF: "SP",
    nome_da_cidade: "CARDOSO",
  },
  {
    codigo_do_IGBE: 3113602,
    UF: "MG",
    nome_da_cidade: "CAREACU",
  },
  {
    codigo_do_IGBE: 1301100,
    UF: "AM",
    nome_da_cidade: "CAREIRO",
  },
  {
    codigo_do_IGBE: 1301159,
    UF: "AM",
    nome_da_cidade: "CAREIRO DA VARZEA",
  },
  {
    codigo_do_IGBE: 3201308,
    UF: "ES",
    nome_da_cidade: "CARIACICA",
  },
  {
    codigo_do_IGBE: 2303006,
    UF: "CE",
    nome_da_cidade: "CARIDADE",
  },
  {
    codigo_do_IGBE: 2907103,
    UF: "BA",
    nome_da_cidade: "CARINHANHA",
  },
  {
    codigo_do_IGBE: 2801405,
    UF: "SE",
    nome_da_cidade: "CARIRA",
  },
  {
    codigo_do_IGBE: 2303105,
    UF: "CE",
    nome_da_cidade: "CARIRE",
  },
  {
    codigo_do_IGBE: 1703867,
    UF: "TO",
    nome_da_cidade: "CARIRI DO TOCANTINS",
  },
  {
    codigo_do_IGBE: 2303204,
    UF: "CE",
    nome_da_cidade: "CARIRIACU",
  },
  {
    codigo_do_IGBE: 2303303,
    UF: "CE",
    nome_da_cidade: "CARIUS",
  },
  {
    codigo_do_IGBE: 5102793,
    UF: "MT",
    nome_da_cidade: "CARLINDA",
  },
  {
    codigo_do_IGBE: 4104709,
    UF: "PR",
    nome_da_cidade: "CARLOPOLIS",
  },
  {
    codigo_do_IGBE: 3113701,
    UF: "MG",
    nome_da_cidade: "CARLOS CHAGAS",
  },
  {
    codigo_do_IGBE: 4304853,
    UF: "RS",
    nome_da_cidade: "CARLOS GOMES",
  },
  {
    codigo_do_IGBE: 3113800,
    UF: "MG",
    nome_da_cidade: "CARMESIA",
  },
  {
    codigo_do_IGBE: 3301207,
    UF: "RJ",
    nome_da_cidade: "CARMO",
  },
  {
    codigo_do_IGBE: 3114105,
    UF: "MG",
    nome_da_cidade: "CARMO DE MINAS",
  },
  {
    codigo_do_IGBE: 3114204,
    UF: "MG",
    nome_da_cidade: "CARMO DO CAJURU",
  },
  {
    codigo_do_IGBE: 3114303,
    UF: "MG",
    nome_da_cidade: "CARMO DO PARANAIBA",
  },
  {
    codigo_do_IGBE: 3114402,
    UF: "MG",
    nome_da_cidade: "CARMO DO RIO CLARO",
  },
  {
    codigo_do_IGBE: 5205000,
    UF: "GO",
    nome_da_cidade: "CARMO DO RIO VERDE",
  },
  {
    codigo_do_IGBE: 1703883,
    UF: "TO",
    nome_da_cidade: "CARMOLANDIA",
  },
  {
    codigo_do_IGBE: 2801504,
    UF: "SE",
    nome_da_cidade: "CARMOPOLIS",
  },
  {
    codigo_do_IGBE: 3114501,
    UF: "MG",
    nome_da_cidade: "CARMOPOLIS DE MINAS",
  },
  {
    codigo_do_IGBE: 2603900,
    UF: "PE",
    nome_da_cidade: "CARNAIBA",
  },
  {
    codigo_do_IGBE: 2402501,
    UF: "RN",
    nome_da_cidade: "CARNAUBAIS",
  },
  {
    codigo_do_IGBE: 2303402,
    UF: "CE",
    nome_da_cidade: "CARNAUBAL",
  },
  {
    codigo_do_IGBE: 3114550,
    UF: "MG",
    nome_da_cidade: "CARNEIRINHO",
  },
  {
    codigo_do_IGBE: 2701803,
    UF: "AL",
    nome_da_cidade: "CARNEIROS",
  },
  {
    codigo_do_IGBE: 1400233,
    UF: "RR",
    nome_da_cidade: "CAROEBE",
  },
  {
    codigo_do_IGBE: 2102804,
    UF: "MA",
    nome_da_cidade: "CAROLINA",
  },
  {
    codigo_do_IGBE: 2604007,
    UF: "PE",
    nome_da_cidade: "CARPINA",
  },
  {
    codigo_do_IGBE: 3114600,
    UF: "MG",
    nome_da_cidade: "CARRANCAS",
  },
  {
    codigo_do_IGBE: 2504108,
    UF: "PB",
    nome_da_cidade: "CARRAPATEIRA",
  },
  {
    codigo_do_IGBE: 1703891,
    UF: "TO",
    nome_da_cidade: "CARRASCO BONITO",
  },
  {
    codigo_do_IGBE: 2604106,
    UF: "PE",
    nome_da_cidade: "CARUARU",
  },
  {
    codigo_do_IGBE: 2102903,
    UF: "MA",
    nome_da_cidade: "CARUTAPERA",
  },
  {
    codigo_do_IGBE: 3114808,
    UF: "MG",
    nome_da_cidade: "CARVALHOS",
  },
  {
    codigo_do_IGBE: 3510807,
    UF: "SP",
    nome_da_cidade: "CASA BRANCA",
  },
  {
    codigo_do_IGBE: 3114907,
    UF: "MG",
    nome_da_cidade: "CASA GRANDE",
  },
  {
    codigo_do_IGBE: 2907202,
    UF: "BA",
    nome_da_cidade: "CASA NOVA",
  },
  {
    codigo_do_IGBE: 4304903,
    UF: "RS",
    nome_da_cidade: "CASCA",
  },
  {
    codigo_do_IGBE: 2303501,
    UF: "CE",
    nome_da_cidade: "CASCAVEL",
  },
  {
    codigo_do_IGBE: 4104808,
    UF: "PR",
    nome_da_cidade: "CASCAVEL",
  },
  {
    codigo_do_IGBE: 1703909,
    UF: "TO",
    nome_da_cidade: "CASEARA",
  },
  {
    codigo_do_IGBE: 4304952,
    UF: "RS",
    nome_da_cidade: "CASEIROS",
  },
  {
    codigo_do_IGBE: 3301306,
    UF: "RJ",
    nome_da_cidade: "CASIMIRO DE ABREU",
  },
  {
    codigo_do_IGBE: 2604155,
    UF: "PE",
    nome_da_cidade: "CASINHAS",
  },
  {
    codigo_do_IGBE: 2504157,
    UF: "PB",
    nome_da_cidade: "CASSERENGUE",
  },
  {
    codigo_do_IGBE: 3115102,
    UF: "MG",
    nome_da_cidade: "CASSIA",
  },
  {
    codigo_do_IGBE: 5002902,
    UF: "MS",
    nome_da_cidade: "CASSILANDIA",
  },
  {
    codigo_do_IGBE: 1502400,
    UF: "PA",
    nome_da_cidade: "CASTANHAL",
  },
  {
    codigo_do_IGBE: 5102850,
    UF: "MT",
    nome_da_cidade: "CASTANHEIRA",
  },
  {
    codigo_do_IGBE: 1100908,
    UF: "RO",
    nome_da_cidade: "CASTANHEIRAS",
  },
  {
    codigo_do_IGBE: 5205059,
    UF: "GO",
    nome_da_cidade: "CASTELANDIA",
  },
  {
    codigo_do_IGBE: 3201407,
    UF: "ES",
    nome_da_cidade: "CASTELO",
  },
  {
    codigo_do_IGBE: 3511003,
    UF: "SP",
    nome_da_cidade: "CASTILHO",
  },
  {
    codigo_do_IGBE: 4104907,
    UF: "PR",
    nome_da_cidade: "CASTRO",
  },
  {
    codigo_do_IGBE: 2907301,
    UF: "BA",
    nome_da_cidade: "CASTRO ALVES",
  },
  {
    codigo_do_IGBE: 3115300,
    UF: "MG",
    nome_da_cidade: "CATAGUASES",
  },
  {
    codigo_do_IGBE: 5205109,
    UF: "GO",
    nome_da_cidade: "CATALAO",
  },
  {
    codigo_do_IGBE: 3511102,
    UF: "SP",
    nome_da_cidade: "CATANDUVA",
  },
  {
    codigo_do_IGBE: 4105003,
    UF: "PR",
    nome_da_cidade: "CATANDUVAS",
  },
  {
    codigo_do_IGBE: 4204004,
    UF: "SC",
    nome_da_cidade: "CATANDUVAS",
  },
  {
    codigo_do_IGBE: 2303600,
    UF: "CE",
    nome_da_cidade: "CATARINA",
  },
  {
    codigo_do_IGBE: 2604205,
    UF: "PE",
    nome_da_cidade: "CATENDE",
  },
  {
    codigo_do_IGBE: 3511201,
    UF: "SP",
    nome_da_cidade: "CATIGUA",
  },
  {
    codigo_do_IGBE: 2504207,
    UF: "PB",
    nome_da_cidade: "CATINGUEIRA",
  },
  {
    codigo_do_IGBE: 2907400,
    UF: "BA",
    nome_da_cidade: "CATOLANDIA",
  },
  {
    codigo_do_IGBE: 2907509,
    UF: "BA",
    nome_da_cidade: "CATU",
  },
  {
    codigo_do_IGBE: 4305009,
    UF: "RS",
    nome_da_cidade: "CATUIPE",
  },
  {
    codigo_do_IGBE: 3115458,
    UF: "MG",
    nome_da_cidade: "CATUJI",
  },
  {
    codigo_do_IGBE: 2303659,
    UF: "CE",
    nome_da_cidade: "CATUNDA",
  },
  {
    codigo_do_IGBE: 2907558,
    UF: "BA",
    nome_da_cidade: "CATURAMA",
  },
  {
    codigo_do_IGBE: 2504355,
    UF: "PB",
    nome_da_cidade: "CATURITE",
  },
  {
    codigo_do_IGBE: 3115474,
    UF: "MG",
    nome_da_cidade: "CATUTI",
  },
  {
    codigo_do_IGBE: 2303709,
    UF: "CE",
    nome_da_cidade: "CAUCAIA",
  },
  {
    codigo_do_IGBE: 5205307,
    UF: "GO",
    nome_da_cidade: "CAVALCANTE",
  },
  {
    codigo_do_IGBE: 3115508,
    UF: "MG",
    nome_da_cidade: "CAXAMBU",
  },
  {
    codigo_do_IGBE: 2103000,
    UF: "MA",
    nome_da_cidade: "CAXIAS",
  },
  {
    codigo_do_IGBE: 4305108,
    UF: "RS",
    nome_da_cidade: "CAXIAS DO SUL",
  },
  {
    codigo_do_IGBE: 2202653,
    UF: "PI",
    nome_da_cidade: "CAXINGO",
  },
  {
    codigo_do_IGBE: 2402600,
    UF: "RN",
    nome_da_cidade: "CEARA-MIRIM",
  },
  {
    codigo_do_IGBE: 2103109,
    UF: "MA",
    nome_da_cidade: "CEDRAL",
  },
  {
    codigo_do_IGBE: 3511300,
    UF: "SP",
    nome_da_cidade: "CEDRAL",
  },
  {
    codigo_do_IGBE: 2303808,
    UF: "CE",
    nome_da_cidade: "CEDRO",
  },
  {
    codigo_do_IGBE: 2604304,
    UF: "PE",
    nome_da_cidade: "CEDRO",
  },
  {
    codigo_do_IGBE: 2801603,
    UF: "SE",
    nome_da_cidade: "CEDRO DE SAO JOAO",
  },
  {
    codigo_do_IGBE: 3115607,
    UF: "MG",
    nome_da_cidade: "CEDRO DO ABAETE",
  },
  {
    codigo_do_IGBE: 4204152,
    UF: "SC",
    nome_da_cidade: "CELSO RAMOS",
  },
  {
    codigo_do_IGBE: 4305116,
    UF: "RS",
    nome_da_cidade: "CENTENARIO",
  },
  {
    codigo_do_IGBE: 1704105,
    UF: "TO",
    nome_da_cidade: "CENTENARIO",
  },
  {
    codigo_do_IGBE: 4105102,
    UF: "PR",
    nome_da_cidade: "CENTENARIO DO SUL",
  },
  {
    codigo_do_IGBE: 2907608,
    UF: "BA",
    nome_da_cidade: "CENTRAL",
  },
  {
    codigo_do_IGBE: 2103125,
    UF: "MA",
    nome_da_cidade: "CENTRAL DO MARANHAO",
  },
  {
    codigo_do_IGBE: 3115805,
    UF: "MG",
    nome_da_cidade: "CENTRALINA",
  },
  {
    codigo_do_IGBE: 2103174,
    UF: "MA",
    nome_da_cidade: "CENTRO NOVO DO MARANHAO",
  },
  {
    codigo_do_IGBE: 1100056,
    UF: "RO",
    nome_da_cidade: "CEREJEIRAS",
  },
  {
    codigo_do_IGBE: 5205406,
    UF: "GO",
    nome_da_cidade: "CERES",
  },
  {
    codigo_do_IGBE: 3511409,
    UF: "SP",
    nome_da_cidade: "CERQUEIRA CESAR",
  },
  {
    codigo_do_IGBE: 3511508,
    UF: "SP",
    nome_da_cidade: "CERQUILHO",
  },
  {
    codigo_do_IGBE: 4305124,
    UF: "RS",
    nome_da_cidade: "CERRITO",
  },
  {
    codigo_do_IGBE: 4105201,
    UF: "PR",
    nome_da_cidade: "CERRO AZUL",
  },
  {
    codigo_do_IGBE: 4305132,
    UF: "RS",
    nome_da_cidade: "CERRO BRANCO",
  },
  {
    codigo_do_IGBE: 2402709,
    UF: "RN",
    nome_da_cidade: "CERRO CORA",
  },
  {
    codigo_do_IGBE: 4305157,
    UF: "RS",
    nome_da_cidade: "CERRO GRANDE",
  },
  {
    codigo_do_IGBE: 4305207,
    UF: "RS",
    nome_da_cidade: "CERRO LARGO",
  },
  {
    codigo_do_IGBE: 4204178,
    UF: "SC",
    nome_da_cidade: "CERRO NEGRO",
  },
  {
    codigo_do_IGBE: 3511607,
    UF: "SP",
    nome_da_cidade: "CESARIO LANGE",
  },
  {
    codigo_do_IGBE: 4105300,
    UF: "PR",
    nome_da_cidade: "CEU AZUL",
  },
  {
    codigo_do_IGBE: 5205455,
    UF: "GO",
    nome_da_cidade: "CEZARINA",
  },
  {
    codigo_do_IGBE: 2604502,
    UF: "PE",
    nome_da_cidade: "CHA GRANDE",
  },
  {
    codigo_do_IGBE: 2701902,
    UF: "AL",
    nome_da_cidade: "CHA PRETA",
  },
  {
    codigo_do_IGBE: 3115904,
    UF: "MG",
    nome_da_cidade: "CHACARA",
  },
  {
    codigo_do_IGBE: 3116001,
    UF: "MG",
    nome_da_cidade: "CHALE",
  },
  {
    codigo_do_IGBE: 4305306,
    UF: "RS",
    nome_da_cidade: "CHAPADA",
  },
  {
    codigo_do_IGBE: 1705102,
    UF: "TO",
    nome_da_cidade: "CHAPADA DA NATIVIDADE",
  },
  {
    codigo_do_IGBE: 1704600,
    UF: "TO",
    nome_da_cidade: "CHAPADA DE AREIA",
  },
  {
    codigo_do_IGBE: 3116100,
    UF: "MG",
    nome_da_cidade: "CHAPADA DO NORTE",
  },
  {
    codigo_do_IGBE: 3116159,
    UF: "MG",
    nome_da_cidade: "CHAPADA GAUCHA",
  },
  {
    codigo_do_IGBE: 5205471,
    UF: "GO",
    nome_da_cidade: "CHAPADAO DO CEU",
  },
  {
    codigo_do_IGBE: 4204194,
    UF: "SC",
    nome_da_cidade: "CHAPADAO DO LAGEADO",
  },
  {
    codigo_do_IGBE: 5002951,
    UF: "MS",
    nome_da_cidade: "CHAPADAO DO SUL",
  },
  {
    codigo_do_IGBE: 2103208,
    UF: "MA",
    nome_da_cidade: "CHAPADINHA",
  },
  {
    codigo_do_IGBE: 4204202,
    UF: "SC",
    nome_da_cidade: "CHAPECO",
  },
  {
    codigo_do_IGBE: 4305355,
    UF: "RS",
    nome_da_cidade: "CHARQUEADAS",
  },
  {
    codigo_do_IGBE: 4305371,
    UF: "RS",
    nome_da_cidade: "CHARRUA",
  },
  {
    codigo_do_IGBE: 2303907,
    UF: "CE",
    nome_da_cidade: "CHAVAL",
  },
  {
    codigo_do_IGBE: 3557204,
    UF: "SP",
    nome_da_cidade: "CHAVANTES",
  },
  {
    codigo_do_IGBE: 1502509,
    UF: "PA",
    nome_da_cidade: "CHAVES",
  },
  {
    codigo_do_IGBE: 3116209,
    UF: "MG",
    nome_da_cidade: "CHIADOR",
  },
  {
    codigo_do_IGBE: 4305405,
    UF: "RS",
    nome_da_cidade: "CHIAPETTA",
  },
  {
    codigo_do_IGBE: 4105409,
    UF: "PR",
    nome_da_cidade: "CHOPINZINHO",
  },
  {
    codigo_do_IGBE: 2303931,
    UF: "CE",
    nome_da_cidade: "CHORO",
  },
  {
    codigo_do_IGBE: 2303956,
    UF: "CE",
    nome_da_cidade: "CHOROZINHO",
  },
  {
    codigo_do_IGBE: 2907707,
    UF: "BA",
    nome_da_cidade: "CHORROCHO",
  },
  {
    codigo_do_IGBE: 4305439,
    UF: "RS",
    nome_da_cidade: "CHUI",
  },
  {
    codigo_do_IGBE: 4105508,
    UF: "PR",
    nome_da_cidade: "CIANORTE",
  },
  {
    codigo_do_IGBE: 2907806,
    UF: "BA",
    nome_da_cidade: "CICERO DANTAS",
  },
  {
    codigo_do_IGBE: 4105607,
    UF: "PR",
    nome_da_cidade: "CIDADE GAUCHA",
  },
  {
    codigo_do_IGBE: 5205497,
    UF: "GO",
    nome_da_cidade: "CIDADE OCIDENTAL",
  },
  {
    codigo_do_IGBE: 2103257,
    UF: "MA",
    nome_da_cidade: "CIDELANDIA",
  },
  {
    codigo_do_IGBE: 4305454,
    UF: "RS",
    nome_da_cidade: "CIDREIRA",
  },
  {
    codigo_do_IGBE: 2907905,
    UF: "BA",
    nome_da_cidade: "CIPO",
  },
  {
    codigo_do_IGBE: 3116308,
    UF: "MG",
    nome_da_cidade: "CIPOTANEA",
  },
  {
    codigo_do_IGBE: 4305504,
    UF: "RS",
    nome_da_cidade: "CIRIACO",
  },
  {
    codigo_do_IGBE: 3116407,
    UF: "MG",
    nome_da_cidade: "CLARAVAL",
  },
  {
    codigo_do_IGBE: 5103056,
    UF: "MT",
    nome_da_cidade: "CLAUDIA",
  },
  {
    codigo_do_IGBE: 3116605,
    UF: "MG",
    nome_da_cidade: "CLAUDIO",
  },
  {
    codigo_do_IGBE: 4105706,
    UF: "PR",
    nome_da_cidade: "CLEVELANDIA",
  },
  {
    codigo_do_IGBE: 2908002,
    UF: "BA",
    nome_da_cidade: "COARACI",
  },
  {
    codigo_do_IGBE: 1301209,
    UF: "AM",
    nome_da_cidade: "COARI",
  },
  {
    codigo_do_IGBE: 2202703,
    UF: "PI",
    nome_da_cidade: "COCAL",
  },
  {
    codigo_do_IGBE: 2202711,
    UF: "PI",
    nome_da_cidade: "COCAL DE TELHA",
  },
  {
    codigo_do_IGBE: 4204251,
    UF: "SC",
    nome_da_cidade: "COCAL DO SUL",
  },
  {
    codigo_do_IGBE: 2202729,
    UF: "PI",
    nome_da_cidade: "COCAL DOS ALVES",
  },
  {
    codigo_do_IGBE: 5205513,
    UF: "GO",
    nome_da_cidade: "COCALZINHO DE GOIAS",
  },
  {
    codigo_do_IGBE: 2908101,
    UF: "BA",
    nome_da_cidade: "COCOS",
  },
  {
    codigo_do_IGBE: 1301308,
    UF: "AM",
    nome_da_cidade: "CODAJAS",
  },
  {
    codigo_do_IGBE: 2103307,
    UF: "MA",
    nome_da_cidade: "CODO",
  },
  {
    codigo_do_IGBE: 3116704,
    UF: "MG",
    nome_da_cidade: "COIMBRA",
  },
  {
    codigo_do_IGBE: 2202737,
    UF: "PI",
    nome_da_cidade: "COIVARAS",
  },
  {
    codigo_do_IGBE: 1502608,
    UF: "PA",
    nome_da_cidade: "COLARES",
  },
  {
    codigo_do_IGBE: 3201506,
    UF: "ES",
    nome_da_cidade: "COLATINA",
  },
  {
    codigo_do_IGBE: 5103205,
    UF: "MT",
    nome_da_cidade: "COLIDER",
  },
  {
    codigo_do_IGBE: 3512001,
    UF: "SP",
    nome_da_cidade: "COLINA",
  },
  {
    codigo_do_IGBE: 2103505,
    UF: "MA",
    nome_da_cidade: "COLINAS",
  },
  {
    codigo_do_IGBE: 4305587,
    UF: "RS",
    nome_da_cidade: "COLINAS",
  },
  {
    codigo_do_IGBE: 1705508,
    UF: "TO",
    nome_da_cidade: "COLINAS DO TOCANTINS",
  },
  {
    codigo_do_IGBE: 1716703,
    UF: "TO",
    nome_da_cidade: "COLMEIA",
  },
  {
    codigo_do_IGBE: 5103254,
    UF: "MT",
    nome_da_cidade: "COLNIZA",
  },
  {
    codigo_do_IGBE: 3512100,
    UF: "SP",
    nome_da_cidade: "COLOMBIA",
  },
  {
    codigo_do_IGBE: 4105805,
    UF: "PR",
    nome_da_cidade: "COLOMBO",
  },
  {
    codigo_do_IGBE: 2202752,
    UF: "PI",
    nome_da_cidade: "COLONIA DO GURGUEIA",
  },
  {
    codigo_do_IGBE: 2202778,
    UF: "PI",
    nome_da_cidade: "COLONIA DO PIAUI",
  },
  {
    codigo_do_IGBE: 4105904,
    UF: "PR",
    nome_da_cidade: "COLORADO",
  },
  {
    codigo_do_IGBE: 4305603,
    UF: "RS",
    nome_da_cidade: "COLORADO",
  },
  {
    codigo_do_IGBE: 2202802,
    UF: "PI",
    nome_da_cidade: "CONCEICAO DO CANINDE",
  },
  {
    codigo_do_IGBE: 3201704,
    UF: "ES",
    nome_da_cidade: "CONCEICAO DO CASTELO",
  },
  {
    codigo_do_IGBE: 2908408,
    UF: "BA",
    nome_da_cidade: "CONCEICAO DO COITE",
  },
  {
    codigo_do_IGBE: 2908507,
    UF: "BA",
    nome_da_cidade: "CONCEICAO DO JACUIPE",
  },
  {
    codigo_do_IGBE: 2103554,
    UF: "MA",
    nome_da_cidade: "CONCEICAO DO LAGO-ACU",
  },
  {
    codigo_do_IGBE: 3117504,
    UF: "MG",
    nome_da_cidade: "CONCEICAO DO MATO DENTRO",
  },
  {
    codigo_do_IGBE: 3117603,
    UF: "MG",
    nome_da_cidade: "CONCEICAO DO PARA",
  },
  {
    codigo_do_IGBE: 3117702,
    UF: "MG",
    nome_da_cidade: "CONCEICAO DO RIO VERDE",
  },
  {
    codigo_do_IGBE: 1705607,
    UF: "TO",
    nome_da_cidade: "CONCEICAO DO TOCANTINS",
  },
  {
    codigo_do_IGBE: 3117801,
    UF: "MG",
    nome_da_cidade: "CONCEICAO DOS OUROS",
  },
  {
    codigo_do_IGBE: 3512209,
    UF: "SP",
    nome_da_cidade: "CONCHAL",
  },
  {
    codigo_do_IGBE: 3512308,
    UF: "SP",
    nome_da_cidade: "CONCHAS",
  },
  {
    codigo_do_IGBE: 4204301,
    UF: "SC",
    nome_da_cidade: "CONCORDIA",
  },
  {
    codigo_do_IGBE: 2504504,
    UF: "PB",
    nome_da_cidade: "CONDADO",
  },
  {
    codigo_do_IGBE: 2604601,
    UF: "PE",
    nome_da_cidade: "CONDADO",
  },
  {
    codigo_do_IGBE: 2908606,
    UF: "BA",
    nome_da_cidade: "CONDE",
  },
  {
    codigo_do_IGBE: 2504603,
    UF: "PB",
    nome_da_cidade: "CONDE",
  },
  {
    codigo_do_IGBE: 2908705,
    UF: "BA",
    nome_da_cidade: "CONDEUBA",
  },
  {
    codigo_do_IGBE: 4305702,
    UF: "RS",
    nome_da_cidade: "CONDOR",
  },
  {
    codigo_do_IGBE: 3117836,
    UF: "MG",
    nome_da_cidade: "CONEGO MARINHO",
  },
  {
    codigo_do_IGBE: 3117876,
    UF: "MG",
    nome_da_cidade: "CONFINS",
  },
  {
    codigo_do_IGBE: 5103353,
    UF: "MT",
    nome_da_cidade: "CONFRESA",
  },
  {
    codigo_do_IGBE: 2504702,
    UF: "PB",
    nome_da_cidade: "CONGO",
  },
  {
    codigo_do_IGBE: 3117900,
    UF: "MG",
    nome_da_cidade: "CONGONHAL",
  },
  {
    codigo_do_IGBE: 3118007,
    UF: "MG",
    nome_da_cidade: "CONGONHAS",
  },
  {
    codigo_do_IGBE: 3118106,
    UF: "MG",
    nome_da_cidade: "CONGONHAS DO NORTE",
  },
  {
    codigo_do_IGBE: 4106001,
    UF: "PR",
    nome_da_cidade: "CONGONHINHAS",
  },
  {
    codigo_do_IGBE: 3118205,
    UF: "MG",
    nome_da_cidade: "CONQUISTA",
  },
  {
    codigo_do_IGBE: 3118304,
    UF: "MG",
    nome_da_cidade: "CONSELHEIRO LAFAIETE",
  },
  {
    codigo_do_IGBE: 4106100,
    UF: "PR",
    nome_da_cidade: "CONSELHEIRO MAIRINCK",
  },
  {
    codigo_do_IGBE: 3118403,
    UF: "MG",
    nome_da_cidade: "CONSELHEIRO PENA",
  },
  {
    codigo_do_IGBE: 3118502,
    UF: "MG",
    nome_da_cidade: "CONSOLACAO",
  },
  {
    codigo_do_IGBE: 4305801,
    UF: "RS",
    nome_da_cidade: "CONSTANTINA",
  },
  {
    codigo_do_IGBE: 3118601,
    UF: "MG",
    nome_da_cidade: "CONTAGEM",
  },
  {
    codigo_do_IGBE: 4106209,
    UF: "PR",
    nome_da_cidade: "CONTENDA",
  },
  {
    codigo_do_IGBE: 2908804,
    UF: "BA",
    nome_da_cidade: "CONTENDAS DO SINCORA",
  },
  {
    codigo_do_IGBE: 3118700,
    UF: "MG",
    nome_da_cidade: "COQUEIRAL",
  },
  {
    codigo_do_IGBE: 4305835,
    UF: "RS",
    nome_da_cidade: "COQUEIRO BAIXO",
  },
  {
    codigo_do_IGBE: 2702207,
    UF: "AL",
    nome_da_cidade: "COQUEIRO SECO",
  },
  {
    codigo_do_IGBE: 4305850,
    UF: "RS",
    nome_da_cidade: "COQUEIROS DO SUL",
  },
  {
    codigo_do_IGBE: 3118809,
    UF: "MG",
    nome_da_cidade: "CORACAO DE JESUS",
  },
  {
    codigo_do_IGBE: 4106308,
    UF: "PR",
    nome_da_cidade: "CORBELIA",
  },
  {
    codigo_do_IGBE: 3301504,
    UF: "RJ",
    nome_da_cidade: "CORDEIRO",
  },
  {
    codigo_do_IGBE: 3512407,
    UF: "SP",
    nome_da_cidade: "CORDEIROPOLIS",
  },
  {
    codigo_do_IGBE: 2909000,
    UF: "BA",
    nome_da_cidade: "CORDEIROS",
  },
  {
    codigo_do_IGBE: 4204350,
    UF: "SC",
    nome_da_cidade: "CORDILHEIRA ALTA",
  },
  {
    codigo_do_IGBE: 3118908,
    UF: "MG",
    nome_da_cidade: "CORDISBURGO",
  },
  {
    codigo_do_IGBE: 3119005,
    UF: "MG",
    nome_da_cidade: "CORDISLANDIA",
  },
  {
    codigo_do_IGBE: 2304004,
    UF: "CE",
    nome_da_cidade: "COREAU",
  },
  {
    codigo_do_IGBE: 2504801,
    UF: "PB",
    nome_da_cidade: "COREMAS",
  },
  {
    codigo_do_IGBE: 5003108,
    UF: "MS",
    nome_da_cidade: "CORGUINHO",
  },
  {
    codigo_do_IGBE: 2909109,
    UF: "BA",
    nome_da_cidade: "CORIBE",
  },
  {
    codigo_do_IGBE: 3119104,
    UF: "MG",
    nome_da_cidade: "CORINTO",
  },
  {
    codigo_do_IGBE: 4106407,
    UF: "PR",
    nome_da_cidade: "CORNELIO PROCOPIO",
  },
  {
    codigo_do_IGBE: 3119203,
    UF: "MG",
    nome_da_cidade: "COROACI",
  },
  {
    codigo_do_IGBE: 3512506,
    UF: "SP",
    nome_da_cidade: "COROADOS",
  },
  {
    codigo_do_IGBE: 2103604,
    UF: "MA",
    nome_da_cidade: "COROATA",
  },
  {
    codigo_do_IGBE: 4305871,
    UF: "RS",
    nome_da_cidade: "CORONEL BARROS",
  },
  {
    codigo_do_IGBE: 4305900,
    UF: "RS",
    nome_da_cidade: "CORONEL BICACO",
  },
  {
    codigo_do_IGBE: 4106456,
    UF: "PR",
    nome_da_cidade: "CORONEL DOMINGOS SOARES",
  },
  {
    codigo_do_IGBE: 2402808,
    UF: "RN",
    nome_da_cidade: "CORONEL EZEQUIEL",
  },
  {
    codigo_do_IGBE: 3119401,
    UF: "MG",
    nome_da_cidade: "CORONEL FABRICIANO",
  },
  {
    codigo_do_IGBE: 4204400,
    UF: "SC",
    nome_da_cidade: "CORONEL FREITAS",
  },
  {
    codigo_do_IGBE: 2402907,
    UF: "RN",
    nome_da_cidade: "CORONEL JOAO PESSOA",
  },
  {
    codigo_do_IGBE: 2909208,
    UF: "BA",
    nome_da_cidade: "CORONEL JOAO SA",
  },
  {
    codigo_do_IGBE: 2202851,
    UF: "PI",
    nome_da_cidade: "CORONEL JOSE DIAS",
  },
  {
    codigo_do_IGBE: 3512605,
    UF: "SP",
    nome_da_cidade: "CORONEL MACEDO",
  },
  {
    codigo_do_IGBE: 3119500,
    UF: "MG",
    nome_da_cidade: "CORONEL MURTA",
  },
  {
    codigo_do_IGBE: 3119609,
    UF: "MG",
    nome_da_cidade: "CORONEL PACHECO",
  },
  {
    codigo_do_IGBE: 4305934,
    UF: "RS",
    nome_da_cidade: "CORONEL PILAR",
  },
  {
    codigo_do_IGBE: 5003157,
    UF: "MS",
    nome_da_cidade: "CORONEL SAPUCAIA",
  },
  {
    codigo_do_IGBE: 4106506,
    UF: "PR",
    nome_da_cidade: "CORONEL VIVIDA",
  },
  {
    codigo_do_IGBE: 3119708,
    UF: "MG",
    nome_da_cidade: "CORONEL XAVIER CHAVES",
  },
  {
    codigo_do_IGBE: 3119807,
    UF: "MG",
    nome_da_cidade: "CORREGO DANTA",
  },
  {
    codigo_do_IGBE: 3119906,
    UF: "MG",
    nome_da_cidade: "CORREGO DO BOM JESUS",
  },
  {
    codigo_do_IGBE: 5205703,
    UF: "GO",
    nome_da_cidade: "CORREGO DO OURO",
  },
  {
    codigo_do_IGBE: 3119955,
    UF: "MG",
    nome_da_cidade: "CORREGO FUNDO",
  },
  {
    codigo_do_IGBE: 3120003,
    UF: "MG",
    nome_da_cidade: "CORREGO NOVO",
  },
  {
    codigo_do_IGBE: 4204558,
    UF: "SC",
    nome_da_cidade: "CORREIA PINTO",
  },
  {
    codigo_do_IGBE: 2202901,
    UF: "PI",
    nome_da_cidade: "CORRENTE",
  },
  {
    codigo_do_IGBE: 2604700,
    UF: "PE",
    nome_da_cidade: "CORRENTES",
  },
  {
    codigo_do_IGBE: 2909307,
    UF: "BA",
    nome_da_cidade: "CORRENTINA",
  },
  {
    codigo_do_IGBE: 2604809,
    UF: "PE",
    nome_da_cidade: "CORTES",
  },
  {
    codigo_do_IGBE: 5205802,
    UF: "GO",
    nome_da_cidade: "CORUMBA DE GOIAS",
  },
  {
    codigo_do_IGBE: 5205901,
    UF: "GO",
    nome_da_cidade: "CORUMBAIBA",
  },
  {
    codigo_do_IGBE: 3512704,
    UF: "SP",
    nome_da_cidade: "CORUMBATAI",
  },
  {
    codigo_do_IGBE: 4106555,
    UF: "PR",
    nome_da_cidade: "CORUMBATAI DO SUL",
  },
  {
    codigo_do_IGBE: 1100072,
    UF: "RO",
    nome_da_cidade: "CORUMBIARA",
  },
  {
    codigo_do_IGBE: 4204509,
    UF: "SC",
    nome_da_cidade: "CORUPA",
  },
  {
    codigo_do_IGBE: 2702306,
    UF: "AL",
    nome_da_cidade: "CORURIPE",
  },
  {
    codigo_do_IGBE: 3512803,
    UF: "SP",
    nome_da_cidade: "COSMOPOLIS",
  },
  {
    codigo_do_IGBE: 3512902,
    UF: "SP",
    nome_da_cidade: "COSMORAMA",
  },
  {
    codigo_do_IGBE: 1100080,
    UF: "RO",
    nome_da_cidade: "COSTA MARQUES",
  },
  {
    codigo_do_IGBE: 5003256,
    UF: "MS",
    nome_da_cidade: "COSTA RICA",
  },
  {
    codigo_do_IGBE: 2909406,
    UF: "BA",
    nome_da_cidade: "COTEGIPE",
  },
  {
    codigo_do_IGBE: 4305959,
    UF: "RS",
    nome_da_cidade: "COTIPORA",
  },
  {
    codigo_do_IGBE: 5103379,
    UF: "MT",
    nome_da_cidade: "COTRIGUACU",
  },
  {
    codigo_do_IGBE: 1706001,
    UF: "TO",
    nome_da_cidade: "COUTO DE MAGALHAES",
  },
  {
    codigo_do_IGBE: 3120102,
    UF: "MG",
    nome_da_cidade: "COUTO DE MAGALHAES DE MINAS",
  },
  {
    codigo_do_IGBE: 4305975,
    UF: "RS",
    nome_da_cidade: "COXILHA",
  },
  {
    codigo_do_IGBE: 5003306,
    UF: "MS",
    nome_da_cidade: "COXIM",
  },
  {
    codigo_do_IGBE: 2504850,
    UF: "PB",
    nome_da_cidade: "COXIXOLA",
  },
  {
    codigo_do_IGBE: 2702355,
    UF: "AL",
    nome_da_cidade: "CRAIBAS",
  },
  {
    codigo_do_IGBE: 2304103,
    UF: "CE",
    nome_da_cidade: "CRATEUS",
  },
  {
    codigo_do_IGBE: 2304202,
    UF: "CE",
    nome_da_cidade: "CRATO",
  },
  {
    codigo_do_IGBE: 2909505,
    UF: "BA",
    nome_da_cidade: "CRAVOLANDIA",
  },
  {
    codigo_do_IGBE: 3120151,
    UF: "MG",
    nome_da_cidade: "CRISOLITA",
  },
  {
    codigo_do_IGBE: 2909604,
    UF: "BA",
    nome_da_cidade: "CRISOPOLIS",
  },
  {
    codigo_do_IGBE: 4306007,
    UF: "RS",
    nome_da_cidade: "CRISSIUMAL",
  },
  {
    codigo_do_IGBE: 3513207,
    UF: "SP",
    nome_da_cidade: "CRISTAIS PAULISTA",
  },
  {
    codigo_do_IGBE: 4306056,
    UF: "RS",
    nome_da_cidade: "CRISTAL",
  },
  {
    codigo_do_IGBE: 4306072,
    UF: "RS",
    nome_da_cidade: "CRISTAL DO SUL",
  },
  {
    codigo_do_IGBE: 1706100,
    UF: "TO",
    nome_da_cidade: "CRISTALANDIA",
  },
  {
    codigo_do_IGBE: 3120300,
    UF: "MG",
    nome_da_cidade: "CRISTALIA",
  },
  {
    codigo_do_IGBE: 5206206,
    UF: "GO",
    nome_da_cidade: "CRISTALINA",
  },
  {
    codigo_do_IGBE: 3120409,
    UF: "MG",
    nome_da_cidade: "CRISTIANO OTONI",
  },
  {
    codigo_do_IGBE: 5206305,
    UF: "GO",
    nome_da_cidade: "CRISTIANOPOLIS",
  },
  {
    codigo_do_IGBE: 3120508,
    UF: "MG",
    nome_da_cidade: "CRISTINA",
  },
  {
    codigo_do_IGBE: 2801702,
    UF: "SE",
    nome_da_cidade: "CRISTINAPOLIS",
  },
  {
    codigo_do_IGBE: 2909703,
    UF: "BA",
    nome_da_cidade: "CRISTOPOLIS",
  },
  {
    codigo_do_IGBE: 5206404,
    UF: "GO",
    nome_da_cidade: "CRIXAS",
  },
  {
    codigo_do_IGBE: 1706258,
    UF: "TO",
    nome_da_cidade: "CRIXAS DO TOCANTINS",
  },
  {
    codigo_do_IGBE: 2304236,
    UF: "CE",
    nome_da_cidade: "CROATA",
  },
  {
    codigo_do_IGBE: 5206503,
    UF: "GO",
    nome_da_cidade: "CROMINIA",
  },
  {
    codigo_do_IGBE: 3120607,
    UF: "MG",
    nome_da_cidade: "CRUCILANDIA",
  },
  {
    codigo_do_IGBE: 2304251,
    UF: "CE",
    nome_da_cidade: "CRUZ",
  },
  {
    codigo_do_IGBE: 4306106,
    UF: "RS",
    nome_da_cidade: "CRUZ ALTA",
  },
  {
    codigo_do_IGBE: 2909802,
    UF: "BA",
    nome_da_cidade: "CRUZ DAS ALMAS",
  },
  {
    codigo_do_IGBE: 4106803,
    UF: "PR",
    nome_da_cidade: "CRUZ MACHADO",
  },
  {
    codigo_do_IGBE: 3513306,
    UF: "SP",
    nome_da_cidade: "CRUZALIA",
  },
  {
    codigo_do_IGBE: 4306130,
    UF: "RS",
    nome_da_cidade: "CRUZALTENSE",
  },
  {
    codigo_do_IGBE: 4106571,
    UF: "PR",
    nome_da_cidade: "CRUZEIRO DO IGUACU",
  },
  {
    codigo_do_IGBE: 4106605,
    UF: "PR",
    nome_da_cidade: "CRUZEIRO DO OESTE",
  },
  {
    codigo_do_IGBE: 1200203,
    UF: "AC",
    nome_da_cidade: "CRUZEIRO DO SUL",
  },
  {
    codigo_do_IGBE: 4306205,
    UF: "RS",
    nome_da_cidade: "CRUZEIRO DO SUL",
  },
  {
    codigo_do_IGBE: 2403004,
    UF: "RN",
    nome_da_cidade: "CRUZETA",
  },
  {
    codigo_do_IGBE: 3120805,
    UF: "MG",
    nome_da_cidade: "CRUZILIA",
  },
  {
    codigo_do_IGBE: 4106852,
    UF: "PR",
    nome_da_cidade: "CRUZMALTINA",
  },
  {
    codigo_do_IGBE: 3513504,
    UF: "SP",
    nome_da_cidade: "CUBATAO",
  },
  {
    codigo_do_IGBE: 2505006,
    UF: "PB",
    nome_da_cidade: "CUBATI",
  },
  {
    codigo_do_IGBE: 4106902,
    UF: "PR",
    nome_da_cidade: "CURITIBA",
  },
  {
    codigo_do_IGBE: 2505105,
    UF: "PB",
    nome_da_cidade: "CUITE",
  },
  {
    codigo_do_IGBE: 2505238,
    UF: "PB",
    nome_da_cidade: "CUITE DE MAMANGUAPE",
  },
  {
    codigo_do_IGBE: 2505204,
    UF: "PB",
    nome_da_cidade: "CUITEGI",
  },
  {
    codigo_do_IGBE: 1100940,
    UF: "RO",
    nome_da_cidade: "CUJUBIM",
  },
  {
    codigo_do_IGBE: 5206602,
    UF: "GO",
    nome_da_cidade: "CUMARI",
  },
  {
    codigo_do_IGBE: 2604908,
    UF: "PE",
    nome_da_cidade: "CUMARU",
  },
  {
    codigo_do_IGBE: 1502764,
    UF: "PA",
    nome_da_cidade: "CUMARU DO NORTE",
  },
  {
    codigo_do_IGBE: 2801900,
    UF: "SE",
    nome_da_cidade: "CUMBE",
  },
  {
    codigo_do_IGBE: 3513603,
    UF: "SP",
    nome_da_cidade: "CUNHA",
  },
  {
    codigo_do_IGBE: 4204756,
    UF: "SC",
    nome_da_cidade: "CUNHATAI",
  },
  {
    codigo_do_IGBE: 2605004,
    UF: "PE",
    nome_da_cidade: "CUPIRA",
  },
  {
    codigo_do_IGBE: 2909901,
    UF: "BA",
    nome_da_cidade: "CURACA",
  },
  {
    codigo_do_IGBE: 2203206,
    UF: "PI",
    nome_da_cidade: "CURIMATA",
  },
  {
    codigo_do_IGBE: 1502772,
    UF: "PA",
    nome_da_cidade: "CURIONOPOLIS",
  },
  {
    codigo_do_IGBE: 4204806,
    UF: "SC",
    nome_da_cidade: "CURITIBANOS",
  },
  {
    codigo_do_IGBE: 4107009,
    UF: "PR",
    nome_da_cidade: "CURIUVA",
  },
  {
    codigo_do_IGBE: 2203230,
    UF: "PI",
    nome_da_cidade: "CURRAIS",
  },
  {
    codigo_do_IGBE: 2403103,
    UF: "RN",
    nome_da_cidade: "CURRAIS NOVOS",
  },
  {
    codigo_do_IGBE: 3120870,
    UF: "MG",
    nome_da_cidade: "CURRAL DE DENTRO",
  },
  {
    codigo_do_IGBE: 2203271,
    UF: "PI",
    nome_da_cidade: "CURRAL NOVO DO PIAUI",
  },
  {
    codigo_do_IGBE: 2505303,
    UF: "PB",
    nome_da_cidade: "CURRAL VELHO",
  },
  {
    codigo_do_IGBE: 1502806,
    UF: "PA",
    nome_da_cidade: "CURRALINHO",
  },
  {
    codigo_do_IGBE: 1502855,
    UF: "PA",
    nome_da_cidade: "CURUA",
  },
  {
    codigo_do_IGBE: 1502905,
    UF: "PA",
    nome_da_cidade: "CURUCA",
  },
  {
    codigo_do_IGBE: 2103703,
    UF: "MA",
    nome_da_cidade: "CURURUPU",
  },
  {
    codigo_do_IGBE: 5103437,
    UF: "MT",
    nome_da_cidade: "CURVELANDIA",
  },
  {
    codigo_do_IGBE: 3120904,
    UF: "MG",
    nome_da_cidade: "CURVELO",
  },
  {
    codigo_do_IGBE: 2605103,
    UF: "PE",
    nome_da_cidade: "CUSTODIA",
  },
  {
    codigo_do_IGBE: 1600212,
    UF: "AP",
    nome_da_cidade: "CUTIAS",
  },
  {
    codigo_do_IGBE: 5206701,
    UF: "GO",
    nome_da_cidade: "DAMIANOPOLIS",
  },
  {
    codigo_do_IGBE: 2505352,
    UF: "PB",
    nome_da_cidade: "DAMIAO",
  },
  {
    codigo_do_IGBE: 5206800,
    UF: "GO",
    nome_da_cidade: "DAMOLANDIA",
  },
  {
    codigo_do_IGBE: 1706506,
    UF: "TO",
    nome_da_cidade: "DARCINOPOLIS",
  },
  {
    codigo_do_IGBE: 3121001,
    UF: "MG",
    nome_da_cidade: "DATAS",
  },
  {
    codigo_do_IGBE: 5206909,
    UF: "GO",
    nome_da_cidade: "DAVINOPOLIS",
  },
  {
    codigo_do_IGBE: 2103752,
    UF: "MA",
    nome_da_cidade: "DAVINOPOLIS",
  },
  {
    codigo_do_IGBE: 3121100,
    UF: "MG",
    nome_da_cidade: "DELFIM MOREIRA",
  },
  {
    codigo_do_IGBE: 3121209,
    UF: "MG",
    nome_da_cidade: "DELFINOPOLIS",
  },
  {
    codigo_do_IGBE: 2702405,
    UF: "AL",
    nome_da_cidade: "DELMIRO GOUVEIA",
  },
  {
    codigo_do_IGBE: 3121258,
    UF: "MG",
    nome_da_cidade: "DELTA",
  },
  {
    codigo_do_IGBE: 2203305,
    UF: "PI",
    nome_da_cidade: "DEMERVAL LOBAO",
  },
  {
    codigo_do_IGBE: 5103452,
    UF: "MT",
    nome_da_cidade: "DENISE",
  },
  {
    codigo_do_IGBE: 5003454,
    UF: "MS",
    nome_da_cidade: "DEODAPOLIS",
  },
  {
    codigo_do_IGBE: 4306320,
    UF: "RS",
    nome_da_cidade: "DERRUBADAS",
  },
  {
    codigo_do_IGBE: 3513702,
    UF: "SP",
    nome_da_cidade: "DESCALVADO",
  },
  {
    codigo_do_IGBE: 4204905,
    UF: "SC",
    nome_da_cidade: "DESCANSO",
  },
  {
    codigo_do_IGBE: 3121308,
    UF: "MG",
    nome_da_cidade: "DESCOBERTO",
  },
  {
    codigo_do_IGBE: 2505402,
    UF: "PB",
    nome_da_cidade: "DESTERRO",
  },
  {
    codigo_do_IGBE: 3121506,
    UF: "MG",
    nome_da_cidade: "DESTERRO DO MELO",
  },
  {
    codigo_do_IGBE: 4306353,
    UF: "RS",
    nome_da_cidade: "DEZESSEIS DE NOVEMBRO",
  },
  {
    codigo_do_IGBE: 3513801,
    UF: "SP",
    nome_da_cidade: "DIADEMA",
  },
  {
    codigo_do_IGBE: 2505600,
    UF: "PB",
    nome_da_cidade: "DIAMANTE",
  },
  {
    codigo_do_IGBE: 4107108,
    UF: "PR",
    nome_da_cidade: "DIAMANTE DO NORTE",
  },
  {
    codigo_do_IGBE: 4107124,
    UF: "PR",
    nome_da_cidade: "DIAMANTE DO SUL",
  },
  {
    codigo_do_IGBE: 4107157,
    UF: "PR",
    nome_da_cidade: "DIAMANTE D'OESTE",
  },
  {
    codigo_do_IGBE: 3121605,
    UF: "MG",
    nome_da_cidade: "DIAMANTINA",
  },
  {
    codigo_do_IGBE: 5103502,
    UF: "MT",
    nome_da_cidade: "DIAMANTINO",
  },
  {
    codigo_do_IGBE: 1707009,
    UF: "TO",
    nome_da_cidade: "DIANOPOLIS",
  },
  {
    codigo_do_IGBE: 2910057,
    UF: "BA",
    nome_da_cidade: "DIAS D'AVILA",
  },
  {
    codigo_do_IGBE: 3121704,
    UF: "MG",
    nome_da_cidade: "DIOGO DE VASCONCELOS",
  },
  {
    codigo_do_IGBE: 3121803,
    UF: "MG",
    nome_da_cidade: "DIONISIO",
  },
  {
    codigo_do_IGBE: 4205001,
    UF: "SC",
    nome_da_cidade: "DIONISIO CERQUEIRA",
  },
  {
    codigo_do_IGBE: 5207105,
    UF: "GO",
    nome_da_cidade: "DIORAMA",
  },
  {
    codigo_do_IGBE: 3513850,
    UF: "SP",
    nome_da_cidade: "DIRCE REIS",
  },
  {
    codigo_do_IGBE: 2802007,
    UF: "SE",
    nome_da_cidade: "DIVINA PASTORA",
  },
  {
    codigo_do_IGBE: 3121902,
    UF: "MG",
    nome_da_cidade: "DIVINESIA",
  },
  {
    codigo_do_IGBE: 3122009,
    UF: "MG",
    nome_da_cidade: "DIVINO",
  },
  {
    codigo_do_IGBE: 3122108,
    UF: "MG",
    nome_da_cidade: "DIVINO DAS LARANJEIRAS",
  },
  {
    codigo_do_IGBE: 3513900,
    UF: "SP",
    nome_da_cidade: "DIVINOLANDIA",
  },
  {
    codigo_do_IGBE: 3122207,
    UF: "MG",
    nome_da_cidade: "DIVINOLANDIA DE MINAS",
  },
  {
    codigo_do_IGBE: 3122306,
    UF: "MG",
    nome_da_cidade: "DIVINOPOLIS",
  },
  {
    codigo_do_IGBE: 5208301,
    UF: "GO",
    nome_da_cidade: "DIVINOPOLIS DE GOIAS",
  },
  {
    codigo_do_IGBE: 1707108,
    UF: "TO",
    nome_da_cidade: "DIVINOPOLIS DO TOCANTINS",
  },
  {
    codigo_do_IGBE: 3122355,
    UF: "MG",
    nome_da_cidade: "DIVISA ALEGRE",
  },
  {
    codigo_do_IGBE: 3122405,
    UF: "MG",
    nome_da_cidade: "DIVISA NOVA",
  },
  {
    codigo_do_IGBE: 3122454,
    UF: "MG",
    nome_da_cidade: "DIVISOPOLIS",
  },
  {
    codigo_do_IGBE: 3514007,
    UF: "SP",
    nome_da_cidade: "DOBRADA",
  },
  {
    codigo_do_IGBE: 4306403,
    UF: "RS",
    nome_da_cidade: "DOIS IRMAOS",
  },
  {
    codigo_do_IGBE: 4306429,
    UF: "RS",
    nome_da_cidade: "DOIS IRMAOS DAS MISSOES",
  },
  {
    codigo_do_IGBE: 5003488,
    UF: "MS",
    nome_da_cidade: "DOIS IRMAOS DO BURITI",
  },
  {
    codigo_do_IGBE: 1707207,
    UF: "TO",
    nome_da_cidade: "DOIS IRMAOS DO TOCANTINS",
  },
  {
    codigo_do_IGBE: 4306452,
    UF: "RS",
    nome_da_cidade: "DOIS LAJEADOS",
  },
  {
    codigo_do_IGBE: 2702504,
    UF: "AL",
    nome_da_cidade: "DOIS RIACHOS",
  },
  {
    codigo_do_IGBE: 4107207,
    UF: "PR",
    nome_da_cidade: "DOIS VIZINHOS",
  },
  {
    codigo_do_IGBE: 3514205,
    UF: "SP",
    nome_da_cidade: "DOLCINOPOLIS",
  },
  {
    codigo_do_IGBE: 5103601,
    UF: "MT",
    nome_da_cidade: "DOM AQUINO",
  },
  {
    codigo_do_IGBE: 2910107,
    UF: "BA",
    nome_da_cidade: "DOM BASILIO",
  },
  {
    codigo_do_IGBE: 3122504,
    UF: "MG",
    nome_da_cidade: "DOM CAVATI",
  },
  {
    codigo_do_IGBE: 1502939,
    UF: "PA",
    nome_da_cidade: "DOM ELISEU",
  },
  {
    codigo_do_IGBE: 2203404,
    UF: "PI",
    nome_da_cidade: "DOM EXPEDITO LOPES",
  },
  {
    codigo_do_IGBE: 2203453,
    UF: "PI",
    nome_da_cidade: "DOM INOCENCIO",
  },
  {
    codigo_do_IGBE: 3122603,
    UF: "MG",
    nome_da_cidade: "DOM JOAQUIM",
  },
  {
    codigo_do_IGBE: 2910206,
    UF: "BA",
    nome_da_cidade: "DOM MACEDO COSTA",
  },
  {
    codigo_do_IGBE: 4306601,
    UF: "RS",
    nome_da_cidade: "DOM PEDRITO",
  },
  {
    codigo_do_IGBE: 2103802,
    UF: "MA",
    nome_da_cidade: "DOM PEDRO",
  },
  {
    codigo_do_IGBE: 3122702,
    UF: "MG",
    nome_da_cidade: "DOM SILVERIO",
  },
  {
    codigo_do_IGBE: 3122801,
    UF: "MG",
    nome_da_cidade: "DOM VICOSO",
  },
  {
    codigo_do_IGBE: 3201902,
    UF: "ES",
    nome_da_cidade: "DOMINGOS MARTINS",
  },
  {
    codigo_do_IGBE: 2203420,
    UF: "PI",
    nome_da_cidade: "DOMINGOS MOURAO",
  },
  {
    codigo_do_IGBE: 4205100,
    UF: "SC",
    nome_da_cidade: "DONA EMMA",
  },
  {
    codigo_do_IGBE: 3122900,
    UF: "MG",
    nome_da_cidade: "DONA EUSEBIA",
  },
  {
    codigo_do_IGBE: 2505709,
    UF: "PB",
    nome_da_cidade: "DONA INES",
  },
  {
    codigo_do_IGBE: 3123007,
    UF: "MG",
    nome_da_cidade: "DORES DE CAMPOS",
  },
  {
    codigo_do_IGBE: 3123106,
    UF: "MG",
    nome_da_cidade: "DORES DE GUANHAES",
  },
  {
    codigo_do_IGBE: 3123205,
    UF: "MG",
    nome_da_cidade: "DORES DO INDAIA",
  },
  {
    codigo_do_IGBE: 3202009,
    UF: "ES",
    nome_da_cidade: "DORES DO RIO PRETO",
  },
  {
    codigo_do_IGBE: 3123304,
    UF: "MG",
    nome_da_cidade: "DORES DO TURVO",
  },
  {
    codigo_do_IGBE: 3123403,
    UF: "MG",
    nome_da_cidade: "DORESOPOLIS",
  },
  {
    codigo_do_IGBE: 2605152,
    UF: "PE",
    nome_da_cidade: "DORMENTES",
  },
  {
    codigo_do_IGBE: 5003504,
    UF: "MS",
    nome_da_cidade: "DOURADINA",
  },
  {
    codigo_do_IGBE: 4107256,
    UF: "PR",
    nome_da_cidade: "DOURADINA",
  },
  {
    codigo_do_IGBE: 3514304,
    UF: "SP",
    nome_da_cidade: "DOURADO",
  },
  {
    codigo_do_IGBE: 4107306,
    UF: "PR",
    nome_da_cidade: "DOUTOR CAMARGO",
  },
  {
    codigo_do_IGBE: 4306734,
    UF: "RS",
    nome_da_cidade: "DOUTOR MAURICIO CARDOSO",
  },
  {
    codigo_do_IGBE: 4205159,
    UF: "SC",
    nome_da_cidade: "DOUTOR PEDRINHO",
  },
  {
    codigo_do_IGBE: 2403202,
    UF: "RN",
    nome_da_cidade: "DOUTOR SEVERIANO",
  },
  {
    codigo_do_IGBE: 4128633,
    UF: "PR",
    nome_da_cidade: "DOUTOR ULYSSES",
  },
  {
    codigo_do_IGBE: 5207253,
    UF: "GO",
    nome_da_cidade: "DOVERLANDIA",
  },
  {
    codigo_do_IGBE: 3514403,
    UF: "SP",
    nome_da_cidade: "DRACENA",
  },
  {
    codigo_do_IGBE: 3514502,
    UF: "SP",
    nome_da_cidade: "DUARTINA",
  },
  {
    codigo_do_IGBE: 3301603,
    UF: "RJ",
    nome_da_cidade: "DUAS BARRAS",
  },
  {
    codigo_do_IGBE: 2505808,
    UF: "PB",
    nome_da_cidade: "DUAS ESTRADAS",
  },
  {
    codigo_do_IGBE: 1707306,
    UF: "TO",
    nome_da_cidade: "DUERE",
  },
  {
    codigo_do_IGBE: 3514601,
    UF: "SP",
    nome_da_cidade: "DUMONT",
  },
  {
    codigo_do_IGBE: 3123528,
    UF: "MG",
    nome_da_cidade: "DURANDE",
  },
  {
    codigo_do_IGBE: 3514700,
    UF: "SP",
    nome_da_cidade: "ECHAPORA",
  },
  {
    codigo_do_IGBE: 3202108,
    UF: "ES",
    nome_da_cidade: "ECOPORANGA",
  },
  {
    codigo_do_IGBE: 5207352,
    UF: "GO",
    nome_da_cidade: "EDEALINA",
  },
  {
    codigo_do_IGBE: 5207402,
    UF: "GO",
    nome_da_cidade: "EDEIA",
  },
  {
    codigo_do_IGBE: 1301407,
    UF: "AM",
    nome_da_cidade: "EIRUNEPE",
  },
  {
    codigo_do_IGBE: 5003751,
    UF: "MS",
    nome_da_cidade: "ELDORADO",
  },
  {
    codigo_do_IGBE: 3514809,
    UF: "SP",
    nome_da_cidade: "ELDORADO",
  },
  {
    codigo_do_IGBE: 4306767,
    UF: "RS",
    nome_da_cidade: "ELDORADO DO SUL",
  },
  {
    codigo_do_IGBE: 1502954,
    UF: "PA",
    nome_da_cidade: "ELDORADO DOS CARAJAS",
  },
  {
    codigo_do_IGBE: 2203503,
    UF: "PI",
    nome_da_cidade: "ELESBAO VELOSO",
  },
  {
    codigo_do_IGBE: 3514908,
    UF: "SP",
    nome_da_cidade: "ELIAS FAUSTO",
  },
  {
    codigo_do_IGBE: 3514924,
    UF: "SP",
    nome_da_cidade: "ELISIARIO",
  },
  {
    codigo_do_IGBE: 3123601,
    UF: "MG",
    nome_da_cidade: "ELOI MENDES",
  },
  {
    codigo_do_IGBE: 2505907,
    UF: "PB",
    nome_da_cidade: "EMAS",
  },
  {
    codigo_do_IGBE: 3514957,
    UF: "SP",
    nome_da_cidade: "EMBAUBA",
  },
  {
    codigo_do_IGBE: 3515004,
    UF: "SP",
    nome_da_cidade: "EMBU DAS ARTES",
  },
  {
    codigo_do_IGBE: 3515103,
    UF: "SP",
    nome_da_cidade: "EMBU-GUACU",
  },
  {
    codigo_do_IGBE: 3515129,
    UF: "SP",
    nome_da_cidade: "EMILIANOPOLIS",
  },
  {
    codigo_do_IGBE: 4306809,
    UF: "RS",
    nome_da_cidade: "ENCANTADO",
  },
  {
    codigo_do_IGBE: 2403301,
    UF: "RN",
    nome_da_cidade: "ENCANTO",
  },
  {
    codigo_do_IGBE: 2910404,
    UF: "BA",
    nome_da_cidade: "ENCRUZILHADA",
  },
  {
    codigo_do_IGBE: 4306908,
    UF: "RS",
    nome_da_cidade: "ENCRUZILHADA DO SUL",
  },
  {
    codigo_do_IGBE: 4107405,
    UF: "PR",
    nome_da_cidade: "ENEAS MARQUES",
  },
  {
    codigo_do_IGBE: 3123700,
    UF: "MG",
    nome_da_cidade: "ENGENHEIRO CALDAS",
  },
  {
    codigo_do_IGBE: 3515152,
    UF: "SP",
    nome_da_cidade: "ENGENHEIRO COELHO",
  },
  {
    codigo_do_IGBE: 3301801,
    UF: "RJ",
    nome_da_cidade: "ENGENHEIRO PAULO DE FRONTIN",
  },
  {
    codigo_do_IGBE: 4306924,
    UF: "RS",
    nome_da_cidade: "ENGENHO VELHO",
  },
  {
    codigo_do_IGBE: 3123858,
    UF: "MG",
    nome_da_cidade: "ENTRE FOLHAS",
  },
  {
    codigo_do_IGBE: 2910503,
    UF: "BA",
    nome_da_cidade: "ENTRE RIOS",
  },
  {
    codigo_do_IGBE: 4205175,
    UF: "SC",
    nome_da_cidade: "ENTRE RIOS",
  },
  {
    codigo_do_IGBE: 3123908,
    UF: "MG",
    nome_da_cidade: "ENTRE RIOS DE MINAS",
  },
  {
    codigo_do_IGBE: 4107538,
    UF: "PR",
    nome_da_cidade: "ENTRE RIOS DO OESTE",
  },
  {
    codigo_do_IGBE: 4306957,
    UF: "RS",
    nome_da_cidade: "ENTRE RIOS DO SUL",
  },
  {
    codigo_do_IGBE: 4306932,
    UF: "RS",
    nome_da_cidade: "ENTRE-IJUIS",
  },
  {
    codigo_do_IGBE: 1301506,
    UF: "AM",
    nome_da_cidade: "ENVIRA",
  },
  {
    codigo_do_IGBE: 1200252,
    UF: "AC",
    nome_da_cidade: "EPITACIOLANDIA",
  },
  {
    codigo_do_IGBE: 2403400,
    UF: "RN",
    nome_da_cidade: "EQUADOR",
  },
  {
    codigo_do_IGBE: 4306973,
    UF: "RS",
    nome_da_cidade: "EREBANGO",
  },
  {
    codigo_do_IGBE: 2304277,
    UF: "CE",
    nome_da_cidade: "ERERE",
  },
  {
    codigo_do_IGBE: 4205191,
    UF: "SC",
    nome_da_cidade: "ERMO",
  },
  {
    codigo_do_IGBE: 4307054,
    UF: "RS",
    nome_da_cidade: "ERNESTINA",
  },
  {
    codigo_do_IGBE: 4307203,
    UF: "RS",
    nome_da_cidade: "ERVAL GRANDE",
  },
  {
    codigo_do_IGBE: 4307302,
    UF: "RS",
    nome_da_cidade: "ERVAL SECO",
  },
  {
    codigo_do_IGBE: 4205209,
    UF: "SC",
    nome_da_cidade: "ERVAL VELHO",
  },
  {
    codigo_do_IGBE: 3124005,
    UF: "MG",
    nome_da_cidade: "ERVALIA",
  },
  {
    codigo_do_IGBE: 2605202,
    UF: "PE",
    nome_da_cidade: "ESCADA",
  },
  {
    codigo_do_IGBE: 3124104,
    UF: "MG",
    nome_da_cidade: "ESMERALDAS",
  },
  {
    codigo_do_IGBE: 3124203,
    UF: "MG",
    nome_da_cidade: "ESPERA FELIZ",
  },
  {
    codigo_do_IGBE: 4307450,
    UF: "RS",
    nome_da_cidade: "ESPERANCA DO SUL",
  },
  {
    codigo_do_IGBE: 4107520,
    UF: "PR",
    nome_da_cidade: "ESPERANCA NOVA",
  },
  {
    codigo_do_IGBE: 2203701,
    UF: "PI",
    nome_da_cidade: "ESPERANTINA",
  },
  {
    codigo_do_IGBE: 1707405,
    UF: "TO",
    nome_da_cidade: "ESPERANTINA",
  },
  {
    codigo_do_IGBE: 2104008,
    UF: "MA",
    nome_da_cidade: "ESPERANTINOPOLIS",
  },
  {
    codigo_do_IGBE: 1100098,
    UF: "RO",
    nome_da_cidade: "ESPIGAO D'OESTE",
  },
  {
    codigo_do_IGBE: 3124302,
    UF: "MG",
    nome_da_cidade: "ESPINOSA",
  },
  {
    codigo_do_IGBE: 3205309,
    UF: "ES",
    nome_da_cidade: "VITORIA",
  },
  {
    codigo_do_IGBE: 3515186,
    UF: "SP",
    nome_da_cidade: "ESPIRITO SANTO DO PINHAL",
  },
  {
    codigo_do_IGBE: 3515194,
    UF: "SP",
    nome_da_cidade: "ESPIRITO SANTO DO TURVO",
  },
  {
    codigo_do_IGBE: 2910602,
    UF: "BA",
    nome_da_cidade: "ESPLANADA",
  },
  {
    codigo_do_IGBE: 4307500,
    UF: "RS",
    nome_da_cidade: "ESPUMOSO",
  },
  {
    codigo_do_IGBE: 4307559,
    UF: "RS",
    nome_da_cidade: "ESTACAO",
  },
  {
    codigo_do_IGBE: 2802106,
    UF: "SE",
    nome_da_cidade: "ESTANCIA",
  },
  {
    codigo_do_IGBE: 4307609,
    UF: "RS",
    nome_da_cidade: "ESTANCIA VELHA",
  },
  {
    codigo_do_IGBE: 4307708,
    UF: "RS",
    nome_da_cidade: "ESTEIO",
  },
  {
    codigo_do_IGBE: 3124500,
    UF: "MG",
    nome_da_cidade: "ESTIVA",
  },
  {
    codigo_do_IGBE: 3557303,
    UF: "SP",
    nome_da_cidade: "ESTIVA GERBI",
  },
  {
    codigo_do_IGBE: 2104057,
    UF: "MA",
    nome_da_cidade: "ESTREITO",
  },
  {
    codigo_do_IGBE: 4307807,
    UF: "RS",
    nome_da_cidade: "ESTRELA",
  },
  {
    codigo_do_IGBE: 3124609,
    UF: "MG",
    nome_da_cidade: "ESTRELA DALVA",
  },
  {
    codigo_do_IGBE: 3124708,
    UF: "MG",
    nome_da_cidade: "ESTRELA DO INDAIA",
  },
  {
    codigo_do_IGBE: 3515301,
    UF: "SP",
    nome_da_cidade: "ESTRELA DO NORTE",
  },
  {
    codigo_do_IGBE: 3515202,
    UF: "SP",
    nome_da_cidade: "ESTRELA D'OESTE",
  },
  {
    codigo_do_IGBE: 4307815,
    UF: "RS",
    nome_da_cidade: "ESTRELA VELHA",
  },
  {
    codigo_do_IGBE: 2910701,
    UF: "BA",
    nome_da_cidade: "EUCLIDES DA CUNHA",
  },
  {
    codigo_do_IGBE: 3515350,
    UF: "SP",
    nome_da_cidade: "EUCLIDES DA CUNHA PAULISTA",
  },
  {
    codigo_do_IGBE: 4307831,
    UF: "RS",
    nome_da_cidade: "EUGENIO DE CASTRO",
  },
  {
    codigo_do_IGBE: 3124906,
    UF: "MG",
    nome_da_cidade: "EUGENOPOLIS",
  },
  {
    codigo_do_IGBE: 2910727,
    UF: "BA",
    nome_da_cidade: "EUNAPOLIS",
  },
  {
    codigo_do_IGBE: 2304285,
    UF: "CE",
    nome_da_cidade: "EUSEBIO",
  },
  {
    codigo_do_IGBE: 3125002,
    UF: "MG",
    nome_da_cidade: "EWBANK DA CAMARA",
  },
  {
    codigo_do_IGBE: 3125101,
    UF: "MG",
    nome_da_cidade: "EXTREMA",
  },
  {
    codigo_do_IGBE: 2403608,
    UF: "RN",
    nome_da_cidade: "EXTREMOZ",
  },
  {
    codigo_do_IGBE: 2605301,
    UF: "PE",
    nome_da_cidade: "EXU",
  },
  {
    codigo_do_IGBE: 2506103,
    UF: "PB",
    nome_da_cidade: "FAGUNDES",
  },
  {
    codigo_do_IGBE: 4307864,
    UF: "RS",
    nome_da_cidade: "FAGUNDES VARELA",
  },
  {
    codigo_do_IGBE: 5207535,
    UF: "GO",
    nome_da_cidade: "FAINA",
  },
  {
    codigo_do_IGBE: 3125200,
    UF: "MG",
    nome_da_cidade: "FAMA",
  },
  {
    codigo_do_IGBE: 2304301,
    UF: "CE",
    nome_da_cidade: "FARIAS BRITO",
  },
  {
    codigo_do_IGBE: 1503002,
    UF: "PA",
    nome_da_cidade: "FARO",
  },
  {
    codigo_do_IGBE: 4107553,
    UF: "PR",
    nome_da_cidade: "FAROL",
  },
  {
    codigo_do_IGBE: 4307906,
    UF: "RS",
    nome_da_cidade: "FARROUPILHA",
  },
  {
    codigo_do_IGBE: 3515400,
    UF: "SP",
    nome_da_cidade: "FARTURA",
  },
  {
    codigo_do_IGBE: 2203750,
    UF: "PI",
    nome_da_cidade: "FARTURA DO PIAUI",
  },
  {
    codigo_do_IGBE: 2910750,
    UF: "BA",
    nome_da_cidade: "FATIMA",
  },
  {
    codigo_do_IGBE: 1707553,
    UF: "TO",
    nome_da_cidade: "FATIMA",
  },
  {
    codigo_do_IGBE: 5003801,
    UF: "MS",
    nome_da_cidade: "FATIMA DO SUL",
  },
  {
    codigo_do_IGBE: 4107603,
    UF: "PR",
    nome_da_cidade: "FAXINAL",
  },
  {
    codigo_do_IGBE: 4308052,
    UF: "RS",
    nome_da_cidade: "FAXINALZINHO",
  },
  {
    codigo_do_IGBE: 5207600,
    UF: "GO",
    nome_da_cidade: "FAZENDA NOVA",
  },
  {
    codigo_do_IGBE: 4107652,
    UF: "PR",
    nome_da_cidade: "FAZENDA RIO GRANDE",
  },
  {
    codigo_do_IGBE: 4308078,
    UF: "RS",
    nome_da_cidade: "FAZENDA VILANOVA",
  },
  {
    codigo_do_IGBE: 1200302,
    UF: "AC",
    nome_da_cidade: "FEIJO",
  },
  {
    codigo_do_IGBE: 2910776,
    UF: "BA",
    nome_da_cidade: "FEIRA DA MATA",
  },
  {
    codigo_do_IGBE: 2910800,
    UF: "BA",
    nome_da_cidade: "FEIRA DE SANTANA",
  },
  {
    codigo_do_IGBE: 2702603,
    UF: "AL",
    nome_da_cidade: "FEIRA GRANDE",
  },
  {
    codigo_do_IGBE: 2605400,
    UF: "PE",
    nome_da_cidade: "FEIRA NOVA",
  },
  {
    codigo_do_IGBE: 2802205,
    UF: "SE",
    nome_da_cidade: "FEIRA NOVA",
  },
  {
    codigo_do_IGBE: 2104073,
    UF: "MA",
    nome_da_cidade: "FEIRA NOVA DO MARANHAO",
  },
  {
    codigo_do_IGBE: 3125408,
    UF: "MG",
    nome_da_cidade: "FELICIO DOS SANTOS",
  },
  {
    codigo_do_IGBE: 2403707,
    UF: "RN",
    nome_da_cidade: "FELIPE GUERRA",
  },
  {
    codigo_do_IGBE: 3125606,
    UF: "MG",
    nome_da_cidade: "FELISBURGO",
  },
  {
    codigo_do_IGBE: 3125705,
    UF: "MG",
    nome_da_cidade: "FELIXLANDIA",
  },
  {
    codigo_do_IGBE: 4307401,
    UF: "RS",
    nome_da_cidade: "ESMERALDAS",
  },
  {
    codigo_do_IGBE: 5103700,
    UF: "MT",
    nome_da_cidade: "FELIZ NATAL",
  },
  {
    codigo_do_IGBE: 4107702,
    UF: "PR",
    nome_da_cidade: "FENIX",
  },
  {
    codigo_do_IGBE: 4107736,
    UF: "PR",
    nome_da_cidade: "FERNANDES PINHEIRO",
  },
  {
    codigo_do_IGBE: 3125804,
    UF: "MG",
    nome_da_cidade: "FERNANDES TOURINHO",
  },
  {
    codigo_do_IGBE: 2403756,
    UF: "RN",
    nome_da_cidade: "FERNANDO PEDROZA",
  },
  {
    codigo_do_IGBE: 3515608,
    UF: "SP",
    nome_da_cidade: "FERNANDO PRESTES",
  },
  {
    codigo_do_IGBE: 3515657,
    UF: "SP",
    nome_da_cidade: "FERNAO",
  },
  {
    codigo_do_IGBE: 3515707,
    UF: "SP",
    nome_da_cidade: "FERRAZ DE VASCONCELOS",
  },
  {
    codigo_do_IGBE: 1600238,
    UF: "AP",
    nome_da_cidade: "FERREIRA GOMES",
  },
  {
    codigo_do_IGBE: 2605509,
    UF: "PE",
    nome_da_cidade: "FERREIROS",
  },
  {
    codigo_do_IGBE: 3125903,
    UF: "MG",
    nome_da_cidade: "FERROS",
  },
  {
    codigo_do_IGBE: 3125952,
    UF: "MG",
    nome_da_cidade: "FERVEDOURO",
  },
  {
    codigo_do_IGBE: 4107751,
    UF: "PR",
    nome_da_cidade: "FIGUEIRA",
  },
  {
    codigo_do_IGBE: 5003900,
    UF: "MS",
    nome_da_cidade: "FIGUEIRAO",
  },
  {
    codigo_do_IGBE: 1707652,
    UF: "TO",
    nome_da_cidade: "FIGUEIROPOLIS",
  },
  {
    codigo_do_IGBE: 5103809,
    UF: "MT",
    nome_da_cidade: "FIGUEIROPOLIS D'OESTE",
  },
  {
    codigo_do_IGBE: 2910859,
    UF: "BA",
    nome_da_cidade: "FILADELFIA",
  },
  {
    codigo_do_IGBE: 1707702,
    UF: "TO",
    nome_da_cidade: "FILADELFIA",
  },
  {
    codigo_do_IGBE: 5207808,
    UF: "GO",
    nome_da_cidade: "FIRMINOPOLIS",
  },
  {
    codigo_do_IGBE: 2702801,
    UF: "AL",
    nome_da_cidade: "FLEXEIRAS",
  },
  {
    codigo_do_IGBE: 4205357,
    UF: "SC",
    nome_da_cidade: "FLOR DO SERTAO",
  },
  {
    codigo_do_IGBE: 3515806,
    UF: "SP",
    nome_da_cidade: "FLORA RICA",
  },
  {
    codigo_do_IGBE: 4107801,
    UF: "PR",
    nome_da_cidade: "FLORAI",
  },
  {
    codigo_do_IGBE: 2403806,
    UF: "RN",
    nome_da_cidade: "FLORANIA",
  },
  {
    codigo_do_IGBE: 3515905,
    UF: "SP",
    nome_da_cidade: "FLOREAL",
  },
  {
    codigo_do_IGBE: 2605608,
    UF: "PE",
    nome_da_cidade: "FLORES",
  },
  {
    codigo_do_IGBE: 4308201,
    UF: "RS",
    nome_da_cidade: "FLORES DA CUNHA",
  },
  {
    codigo_do_IGBE: 5207907,
    UF: "GO",
    nome_da_cidade: "FLORES DE GOIAS",
  },
  {
    codigo_do_IGBE: 2203800,
    UF: "PI",
    nome_da_cidade: "FLORES DO PIAUI",
  },
  {
    codigo_do_IGBE: 2605707,
    UF: "PE",
    nome_da_cidade: "FLORESTA",
  },
  {
    codigo_do_IGBE: 4107900,
    UF: "PR",
    nome_da_cidade: "FLORESTA",
  },
  {
    codigo_do_IGBE: 2911006,
    UF: "BA",
    nome_da_cidade: "FLORESTA AZUL",
  },
  {
    codigo_do_IGBE: 3126000,
    UF: "MG",
    nome_da_cidade: "FLORESTAL",
  },
  {
    codigo_do_IGBE: 4108007,
    UF: "PR",
    nome_da_cidade: "FLORESTOPOLIS",
  },
  {
    codigo_do_IGBE: 2203909,
    UF: "PI",
    nome_da_cidade: "FLORIANO",
  },
  {
    codigo_do_IGBE: 4308250,
    UF: "RS",
    nome_da_cidade: "FLORIANO PEIXOTO",
  },
  {
    codigo_do_IGBE: 4108106,
    UF: "PR",
    nome_da_cidade: "FLORIDA",
  },
  {
    codigo_do_IGBE: 3516002,
    UF: "SP",
    nome_da_cidade: "FLORIDA PAULISTA",
  },
  {
    codigo_do_IGBE: 3516101,
    UF: "SP",
    nome_da_cidade: "FLORINIA",
  },
  {
    codigo_do_IGBE: 1301605,
    UF: "AM",
    nome_da_cidade: "FONTE BOA",
  },
  {
    codigo_do_IGBE: 4308300,
    UF: "RS",
    nome_da_cidade: "FONTOURA XAVIER",
  },
  {
    codigo_do_IGBE: 3126109,
    UF: "MG",
    nome_da_cidade: "FORMIGA",
  },
  {
    codigo_do_IGBE: 4308409,
    UF: "RS",
    nome_da_cidade: "FORMIGUEIRO",
  },
  {
    codigo_do_IGBE: 5208004,
    UF: "GO",
    nome_da_cidade: "FORMOSA",
  },
  {
    codigo_do_IGBE: 4108205,
    UF: "PR",
    nome_da_cidade: "FORMOSA DO OESTE",
  },
  {
    codigo_do_IGBE: 2911105,
    UF: "BA",
    nome_da_cidade: "FORMOSA DO RIO PRETO",
  },
  {
    codigo_do_IGBE: 5208103,
    UF: "GO",
    nome_da_cidade: "FORMOSO",
  },
  {
    codigo_do_IGBE: 3126208,
    UF: "MG",
    nome_da_cidade: "FORMOSO",
  },
  {
    codigo_do_IGBE: 1708205,
    UF: "TO",
    nome_da_cidade: "FORMOSO DO ARAGUAIA",
  },
  {
    codigo_do_IGBE: 4308433,
    UF: "RS",
    nome_da_cidade: "FORQUETINHA",
  },
  {
    codigo_do_IGBE: 2304350,
    UF: "CE",
    nome_da_cidade: "FORQUILHA",
  },
  {
    codigo_do_IGBE: 4205456,
    UF: "SC",
    nome_da_cidade: "FORQUILHINHA",
  },
  {
    codigo_do_IGBE: 2304400,
    UF: "CE",
    nome_da_cidade: "FORTALEZA",
  },
  {
    codigo_do_IGBE: 3126307,
    UF: "MG",
    nome_da_cidade: "FORTALEZA DE MINAS",
  },
  {
    codigo_do_IGBE: 1708254,
    UF: "TO",
    nome_da_cidade: "FORTALEZA DO TABOCAO",
  },
  {
    codigo_do_IGBE: 2104107,
    UF: "MA",
    nome_da_cidade: "FORTALEZA DOS NOGUEIRAS",
  },
  {
    codigo_do_IGBE: 4308458,
    UF: "RS",
    nome_da_cidade: "FORTALEZA DOS VALOS",
  },
  {
    codigo_do_IGBE: 2304459,
    UF: "CE",
    nome_da_cidade: "FORTIM",
  },
  {
    codigo_do_IGBE: 2104206,
    UF: "MA",
    nome_da_cidade: "FORTUNA",
  },
  {
    codigo_do_IGBE: 3126406,
    UF: "MG",
    nome_da_cidade: "FORTUNA DE MINAS",
  },
  {
    codigo_do_IGBE: 4205506,
    UF: "SC",
    nome_da_cidade: "FRAIBURGO",
  },
  {
    codigo_do_IGBE: 3516200,
    UF: "SP",
    nome_da_cidade: "FRANCA",
  },
  {
    codigo_do_IGBE: 2204006,
    UF: "PI",
    nome_da_cidade: "FRANCINOPOLIS",
  },
  {
    codigo_do_IGBE: 4108320,
    UF: "PR",
    nome_da_cidade: "FRANCISCO ALVES",
  },
  {
    codigo_do_IGBE: 2204105,
    UF: "PI",
    nome_da_cidade: "FRANCISCO AYRES",
  },
  {
    codigo_do_IGBE: 3126505,
    UF: "MG",
    nome_da_cidade: "FRANCISCO BADARO",
  },
  {
    codigo_do_IGBE: 4108403,
    UF: "PR",
    nome_da_cidade: "FRANCISCO BELTRAO",
  },
  {
    codigo_do_IGBE: 2403905,
    UF: "RN",
    nome_da_cidade: "FRANCISCO DANTAS",
  },
  {
    codigo_do_IGBE: 3126604,
    UF: "MG",
    nome_da_cidade: "FRANCISCO DUMONT",
  },
  {
    codigo_do_IGBE: 2204154,
    UF: "PI",
    nome_da_cidade: "FRANCISCO MACEDO",
  },
  {
    codigo_do_IGBE: 3516309,
    UF: "SP",
    nome_da_cidade: "FRANCISCO MORATO",
  },
  {
    codigo_do_IGBE: 3126703,
    UF: "MG",
    nome_da_cidade: "FRANCISCO SA",
  },
  {
    codigo_do_IGBE: 2204204,
    UF: "PI",
    nome_da_cidade: "FRANCISCO SANTOS",
  },
  {
    codigo_do_IGBE: 3126752,
    UF: "MG",
    nome_da_cidade: "FRANCISCOPOLIS",
  },
  {
    codigo_do_IGBE: 2304509,
    UF: "CE",
    nome_da_cidade: "FRECHEIRINHA",
  },
  {
    codigo_do_IGBE: 3126802,
    UF: "MG",
    nome_da_cidade: "FREI GASPAR",
  },
  {
    codigo_do_IGBE: 3126901,
    UF: "MG",
    nome_da_cidade: "FREI INOCENCIO",
  },
  {
    codigo_do_IGBE: 3126950,
    UF: "MG",
    nome_da_cidade: "FREI LAGONEGRO",
  },
  {
    codigo_do_IGBE: 2506202,
    UF: "PB",
    nome_da_cidade: "FREI MARTINHO",
  },
  {
    codigo_do_IGBE: 2605806,
    UF: "PE",
    nome_da_cidade: "FREI MIGUELINHO",
  },
  {
    codigo_do_IGBE: 2802304,
    UF: "SE",
    nome_da_cidade: "FREI PAULO",
  },
  {
    codigo_do_IGBE: 4205555,
    UF: "SC",
    nome_da_cidade: "FREI ROGERIO",
  },
  {
    codigo_do_IGBE: 3127008,
    UF: "MG",
    nome_da_cidade: "FRONTEIRA",
  },
  {
    codigo_do_IGBE: 3127057,
    UF: "MG",
    nome_da_cidade: "FRONTEIRA DOS VALES",
  },
  {
    codigo_do_IGBE: 2204303,
    UF: "PI",
    nome_da_cidade: "FRONTEIRAS",
  },
  {
    codigo_do_IGBE: 3127073,
    UF: "MG",
    nome_da_cidade: "FRUTA DE LEITE",
  },
  {
    codigo_do_IGBE: 3127107,
    UF: "MG",
    nome_da_cidade: "FRUTAL",
  },
  {
    codigo_do_IGBE: 3202207,
    UF: "ES",
    nome_da_cidade: "FUNDAO",
  },
  {
    codigo_do_IGBE: 3127206,
    UF: "MG",
    nome_da_cidade: "FUNILANDIA",
  },
  {
    codigo_do_IGBE: 2506251,
    UF: "PB",
    nome_da_cidade: "GADO BRAVO",
  },
  {
    codigo_do_IGBE: 3516606,
    UF: "SP",
    nome_da_cidade: "GALIA",
  },
  {
    codigo_do_IGBE: 3127305,
    UF: "MG",
    nome_da_cidade: "GALILEIA",
  },
  {
    codigo_do_IGBE: 2404101,
    UF: "RN",
    nome_da_cidade: "GALINHOS",
  },
  {
    codigo_do_IGBE: 4205605,
    UF: "SC",
    nome_da_cidade: "GALVAO",
  },
  {
    codigo_do_IGBE: 2605905,
    UF: "PE",
    nome_da_cidade: "GAMELEIRA",
  },
  {
    codigo_do_IGBE: 5208152,
    UF: "GO",
    nome_da_cidade: "GAMELEIRA DE GOIAS",
  },
  {
    codigo_do_IGBE: 3127339,
    UF: "MG",
    nome_da_cidade: "GAMELEIRAS",
  },
  {
    codigo_do_IGBE: 2911204,
    UF: "BA",
    nome_da_cidade: "GANDU",
  },
  {
    codigo_do_IGBE: 2606002,
    UF: "PE",
    nome_da_cidade: "GARANHUNS",
  },
  {
    codigo_do_IGBE: 2802403,
    UF: "SE",
    nome_da_cidade: "GARARU",
  },
  {
    codigo_do_IGBE: 3516705,
    UF: "SP",
    nome_da_cidade: "GARCA",
  },
  {
    codigo_do_IGBE: 4308607,
    UF: "RS",
    nome_da_cidade: "GARIBALDI",
  },
  {
    codigo_do_IGBE: 4308656,
    UF: "RS",
    nome_da_cidade: "GARRUCHOS",
  },
  {
    codigo_do_IGBE: 4205803,
    UF: "SC",
    nome_da_cidade: "GARUVA",
  },
  {
    codigo_do_IGBE: 4205902,
    UF: "SC",
    nome_da_cidade: "GASPAR",
  },
  {
    codigo_do_IGBE: 3516804,
    UF: "SP",
    nome_da_cidade: "GASTAO VIDIGAL",
  },
  {
    codigo_do_IGBE: 5103858,
    UF: "MT",
    nome_da_cidade: "GAUCHA DO NORTE",
  },
  {
    codigo_do_IGBE: 4308706,
    UF: "RS",
    nome_da_cidade: "GAURAMA",
  },
  {
    codigo_do_IGBE: 2911253,
    UF: "BA",
    nome_da_cidade: "GAVIAO",
  },
  {
    codigo_do_IGBE: 3516853,
    UF: "SP",
    nome_da_cidade: "GAVIAO PEIXOTO",
  },
  {
    codigo_do_IGBE: 2204352,
    UF: "PI",
    nome_da_cidade: "GEMINIANO",
  },
  {
    codigo_do_IGBE: 5103908,
    UF: "MT",
    nome_da_cidade: "GENERAL CARNEIRO",
  },
  {
    codigo_do_IGBE: 4108502,
    UF: "PR",
    nome_da_cidade: "GENERAL CARNEIRO",
  },
  {
    codigo_do_IGBE: 2802502,
    UF: "SE",
    nome_da_cidade: "GENERAL MAYNARD",
  },
  {
    codigo_do_IGBE: 3516903,
    UF: "SP",
    nome_da_cidade: "GENERAL SALGADO",
  },
  {
    codigo_do_IGBE: 2304608,
    UF: "CE",
    nome_da_cidade: "GENERAL SAMPAIO",
  },
  {
    codigo_do_IGBE: 4308854,
    UF: "RS",
    nome_da_cidade: "GENTIL",
  },
  {
    codigo_do_IGBE: 3517000,
    UF: "SP",
    nome_da_cidade: "GETULINA",
  },
  {
    codigo_do_IGBE: 4308904,
    UF: "RS",
    nome_da_cidade: "GETULIO VARGAS",
  },
  {
    codigo_do_IGBE: 2204402,
    UF: "PI",
    nome_da_cidade: "GILBUES",
  },
  {
    codigo_do_IGBE: 2702900,
    UF: "AL",
    nome_da_cidade: "GIRAU DO PONCIANO",
  },
  {
    codigo_do_IGBE: 4309001,
    UF: "RS",
    nome_da_cidade: "GIRUA",
  },
  {
    codigo_do_IGBE: 3127354,
    UF: "MG",
    nome_da_cidade: "GLAUCILANDIA",
  },
  {
    codigo_do_IGBE: 3517109,
    UF: "SP",
    nome_da_cidade: "GLICERIO",
  },
  {
    codigo_do_IGBE: 2911402,
    UF: "BA",
    nome_da_cidade: "GLORIA",
  },
  {
    codigo_do_IGBE: 2606101,
    UF: "PE",
    nome_da_cidade: "GLORIA DO GOITA",
  },
  {
    codigo_do_IGBE: 5103957,
    UF: "MT",
    nome_da_cidade: "GLORIA D'OESTE",
  },
  {
    codigo_do_IGBE: 4309050,
    UF: "RS",
    nome_da_cidade: "GLORINHA",
  },
  {
    codigo_do_IGBE: 2104305,
    UF: "MA",
    nome_da_cidade: "GODOFREDO VIANA",
  },
  {
    codigo_do_IGBE: 3127370,
    UF: "MG",
    nome_da_cidade: "GOIABEIRA",
  },
  {
    codigo_do_IGBE: 5208400,
    UF: "GO",
    nome_da_cidade: "GOIANAPOLIS",
  },
  {
    codigo_do_IGBE: 5208509,
    UF: "GO",
    nome_da_cidade: "GOIANDIRA",
  },
  {
    codigo_do_IGBE: 1503093,
    UF: "PA",
    nome_da_cidade: "GOIANESIA DO PARA",
  },
  {
    codigo_do_IGBE: 5208707,
    UF: "GO",
    nome_da_cidade: "GOIANIA",
  },
  {
    codigo_do_IGBE: 1708304,
    UF: "TO",
    nome_da_cidade: "GOIANORTE",
  },
  {
    codigo_do_IGBE: 1709005,
    UF: "TO",
    nome_da_cidade: "GOIATINS",
  },
  {
    codigo_do_IGBE: 5209101,
    UF: "GO",
    nome_da_cidade: "GOIATUBA",
  },
  {
    codigo_do_IGBE: 4108601,
    UF: "PR",
    nome_da_cidade: "GOIOERE",
  },
  {
    codigo_do_IGBE: 2104404,
    UF: "MA",
    nome_da_cidade: "GONCALVES DIAS",
  },
  {
    codigo_do_IGBE: 2911501,
    UF: "BA",
    nome_da_cidade: "GONGOGI",
  },
  {
    codigo_do_IGBE: 3127503,
    UF: "MG",
    nome_da_cidade: "GONZAGA",
  },
  {
    codigo_do_IGBE: 3127602,
    UF: "MG",
    nome_da_cidade: "GOUVEIA",
  },
  {
    codigo_do_IGBE: 5209150,
    UF: "GO",
    nome_da_cidade: "GOUVELANDIA",
  },
  {
    codigo_do_IGBE: 2104503,
    UF: "MA",
    nome_da_cidade: "GOVERNADOR ARCHER",
  },
  {
    codigo_do_IGBE: 4206009,
    UF: "SC",
    nome_da_cidade: "GOVERNADOR CELSO RAMOS",
  },
  {
    codigo_do_IGBE: 2104552,
    UF: "MA",
    nome_da_cidade: "GOVERNADOR EDISON LOBAO",
  },
  {
    codigo_do_IGBE: 1101005,
    UF: "RO",
    nome_da_cidade: "GOVERNADOR JORGE TEIXEIRA",
  },
  {
    codigo_do_IGBE: 3202256,
    UF: "ES",
    nome_da_cidade: "GOVERNADOR LINDENBERG",
  },
  {
    codigo_do_IGBE: 2104628,
    UF: "MA",
    nome_da_cidade: "GOVERNADOR LUIZ ROCHA",
  },
  {
    codigo_do_IGBE: 2911600,
    UF: "BA",
    nome_da_cidade: "GOVERNADOR MANGABEIRA",
  },
  {
    codigo_do_IGBE: 2104651,
    UF: "MA",
    nome_da_cidade: "GOVERNADOR NEWTON BELLO",
  },
  {
    codigo_do_IGBE: 2104677,
    UF: "MA",
    nome_da_cidade: "GOVERNADOR NUNES FREIRE",
  },
  {
    codigo_do_IGBE: 3127701,
    UF: "MG",
    nome_da_cidade: "GOVERNADOR VALADARES",
  },
  {
    codigo_do_IGBE: 2304657,
    UF: "CE",
    nome_da_cidade: "GRACA",
  },
  {
    codigo_do_IGBE: 2104701,
    UF: "MA",
    nome_da_cidade: "GRACA ARANHA",
  },
  {
    codigo_do_IGBE: 2802601,
    UF: "SE",
    nome_da_cidade: "GRACHO CARDOSO",
  },
  {
    codigo_do_IGBE: 2104800,
    UF: "MA",
    nome_da_cidade: "GRAJAU",
  },
  {
    codigo_do_IGBE: 4309100,
    UF: "RS",
    nome_da_cidade: "GRAMADO",
  },
  {
    codigo_do_IGBE: 4309126,
    UF: "RS",
    nome_da_cidade: "GRAMADO DOS LOUREIROS",
  },
  {
    codigo_do_IGBE: 4309159,
    UF: "RS",
    nome_da_cidade: "GRAMADO XAVIER",
  },
  {
    codigo_do_IGBE: 4108700,
    UF: "PR",
    nome_da_cidade: "GRANDES RIOS",
  },
  {
    codigo_do_IGBE: 2606309,
    UF: "PE",
    nome_da_cidade: "GRANITO",
  },
  {
    codigo_do_IGBE: 2304707,
    UF: "CE",
    nome_da_cidade: "GRANJA",
  },
  {
    codigo_do_IGBE: 2304806,
    UF: "CE",
    nome_da_cidade: "GRANJEIRO",
  },
  {
    codigo_do_IGBE: 4206108,
    UF: "SC",
    nome_da_cidade: "GRAO PARA",
  },
  {
    codigo_do_IGBE: 2606408,
    UF: "PE",
    nome_da_cidade: "GRAVATA",
  },
  {
    codigo_do_IGBE: 4206207,
    UF: "SC",
    nome_da_cidade: "GRAVATAL",
  },
  {
    codigo_do_IGBE: 2304905,
    UF: "CE",
    nome_da_cidade: "GROAIRAS",
  },
  {
    codigo_do_IGBE: 2404408,
    UF: "RN",
    nome_da_cidade: "GROSSOS",
  },
  {
    codigo_do_IGBE: 3127909,
    UF: "MG",
    nome_da_cidade: "GRUPIARA",
  },
  {
    codigo_do_IGBE: 4309258,
    UF: "RS",
    nome_da_cidade: "GUABIJU",
  },
  {
    codigo_do_IGBE: 4206306,
    UF: "SC",
    nome_da_cidade: "GUABIRUBA",
  },
  {
    codigo_do_IGBE: 3202306,
    UF: "ES",
    nome_da_cidade: "GUACUI",
  },
  {
    codigo_do_IGBE: 2204501,
    UF: "PI",
    nome_da_cidade: "GUADALUPE",
  },
  {
    codigo_do_IGBE: 4309308,
    UF: "RS",
    nome_da_cidade: "GUAIBA",
  },
  {
    codigo_do_IGBE: 3517307,
    UF: "SP",
    nome_da_cidade: "GUAIMBE",
  },
  {
    codigo_do_IGBE: 4108809,
    UF: "PR",
    nome_da_cidade: "GUAIRA",
  },
  {
    codigo_do_IGBE: 3517406,
    UF: "SP",
    nome_da_cidade: "GUAIRA",
  },
  {
    codigo_do_IGBE: 2304954,
    UF: "CE",
    nome_da_cidade: "GUAIUBA",
  },
  {
    codigo_do_IGBE: 1301654,
    UF: "AM",
    nome_da_cidade: "GUAJARA",
  },
  {
    codigo_do_IGBE: 1100106,
    UF: "RO",
    nome_da_cidade: "GUAJARA-MIRIM",
  },
  {
    codigo_do_IGBE: 2911659,
    UF: "BA",
    nome_da_cidade: "GUAJERU",
  },
  {
    codigo_do_IGBE: 2404507,
    UF: "RN",
    nome_da_cidade: "GUAMARE",
  },
  {
    codigo_do_IGBE: 4108957,
    UF: "PR",
    nome_da_cidade: "GUAMIRANGA",
  },
  {
    codigo_do_IGBE: 2911709,
    UF: "BA",
    nome_da_cidade: "GUANAMBI",
  },
  {
    codigo_do_IGBE: 3128006,
    UF: "MG",
    nome_da_cidade: "GUANHAES",
  },
  {
    codigo_do_IGBE: 3517505,
    UF: "SP",
    nome_da_cidade: "GUAPIACU",
  },
  {
    codigo_do_IGBE: 3517604,
    UF: "SP",
    nome_da_cidade: "GUAPIARA",
  },
  {
    codigo_do_IGBE: 3301850,
    UF: "RJ",
    nome_da_cidade: "GUAPIMIRIM",
  },
  {
    codigo_do_IGBE: 4109005,
    UF: "PR",
    nome_da_cidade: "GUAPIRAMA",
  },
  {
    codigo_do_IGBE: 4309407,
    UF: "RS",
    nome_da_cidade: "GUAPORE",
  },
  {
    codigo_do_IGBE: 4109104,
    UF: "PR",
    nome_da_cidade: "GUAPOREMA",
  },
  {
    codigo_do_IGBE: 3517703,
    UF: "SP",
    nome_da_cidade: "GUARA",
  },
  {
    codigo_do_IGBE: 2506301,
    UF: "PB",
    nome_da_cidade: "GUARABIRA",
  },
  {
    codigo_do_IGBE: 3517802,
    UF: "SP",
    nome_da_cidade: "GUARACAI",
  },
  {
    codigo_do_IGBE: 4109203,
    UF: "PR",
    nome_da_cidade: "GUARACI",
  },
  {
    codigo_do_IGBE: 3517901,
    UF: "SP",
    nome_da_cidade: "GUARACI",
  },
  {
    codigo_do_IGBE: 4206405,
    UF: "SC",
    nome_da_cidade: "GUARACIABA",
  },
  {
    codigo_do_IGBE: 2305001,
    UF: "CE",
    nome_da_cidade: "GUARACIABA DO NORTE",
  },
  {
    codigo_do_IGBE: 3128253,
    UF: "MG",
    nome_da_cidade: "GUARACIAMA",
  },
  {
    codigo_do_IGBE: 1709302,
    UF: "TO",
    nome_da_cidade: "GUARAI",
  },
  {
    codigo_do_IGBE: 5209291,
    UF: "GO",
    nome_da_cidade: "GUARAITA",
  },
  {
    codigo_do_IGBE: 4206504,
    UF: "SC",
    nome_da_cidade: "GUARAMIRIM",
  },
  {
    codigo_do_IGBE: 3128303,
    UF: "MG",
    nome_da_cidade: "GUARANESIA",
  },
  {
    codigo_do_IGBE: 3128402,
    UF: "MG",
    nome_da_cidade: "GUARANI",
  },
  {
    codigo_do_IGBE: 4309506,
    UF: "RS",
    nome_da_cidade: "GUARANI DAS MISSOES",
  },
  {
    codigo_do_IGBE: 3518008,
    UF: "SP",
    nome_da_cidade: "GUARANI D'OESTE",
  },
  {
    codigo_do_IGBE: 4109302,
    UF: "PR",
    nome_da_cidade: "GUARANIACU",
  },
  {
    codigo_do_IGBE: 3518107,
    UF: "SP",
    nome_da_cidade: "GUARANTA",
  },
  {
    codigo_do_IGBE: 5104104,
    UF: "MT",
    nome_da_cidade: "GUARANTA DO NORTE",
  },
  {
    codigo_do_IGBE: 3202405,
    UF: "ES",
    nome_da_cidade: "GUARAPARI",
  },
  {
    codigo_do_IGBE: 4109401,
    UF: "PR",
    nome_da_cidade: "GUARAPUAVA",
  },
  {
    codigo_do_IGBE: 4109500,
    UF: "PR",
    nome_da_cidade: "GUARAQUECABA",
  },
  {
    codigo_do_IGBE: 3128501,
    UF: "MG",
    nome_da_cidade: "GUARARA",
  },
  {
    codigo_do_IGBE: 3518305,
    UF: "SP",
    nome_da_cidade: "GUARAREMA",
  },
  {
    codigo_do_IGBE: 2911808,
    UF: "BA",
    nome_da_cidade: "GUARATINGA",
  },
  {
    codigo_do_IGBE: 3518404,
    UF: "SP",
    nome_da_cidade: "GUARATINGUETA",
  },
  {
    codigo_do_IGBE: 4109609,
    UF: "PR",
    nome_da_cidade: "GUARATUBA",
  },
  {
    codigo_do_IGBE: 3128600,
    UF: "MG",
    nome_da_cidade: "GUARDA-MOR",
  },
  {
    codigo_do_IGBE: 3518503,
    UF: "SP",
    nome_da_cidade: "GUAREI",
  },
  {
    codigo_do_IGBE: 3518602,
    UF: "SP",
    nome_da_cidade: "GUARIBA",
  },
  {
    codigo_do_IGBE: 5209457,
    UF: "GO",
    nome_da_cidade: "GUARINOS",
  },
  {
    codigo_do_IGBE: 3518701,
    UF: "SP",
    nome_da_cidade: "GUARUJA",
  },
  {
    codigo_do_IGBE: 4206603,
    UF: "SC",
    nome_da_cidade: "GUARUJA DO SUL",
  },
  {
    codigo_do_IGBE: 3518800,
    UF: "SP",
    nome_da_cidade: "GUARULHOS",
  },
  {
    codigo_do_IGBE: 4206652,
    UF: "SC",
    nome_da_cidade: "GUATAMBU",
  },
  {
    codigo_do_IGBE: 3128709,
    UF: "MG",
    nome_da_cidade: "GUAXUPE",
  },
  {
    codigo_do_IGBE: 5004106,
    UF: "MS",
    nome_da_cidade: "GUIA LOPES DA LAGUNA",
  },
  {
    codigo_do_IGBE: 3128808,
    UF: "MG",
    nome_da_cidade: "GUIDOVAL",
  },
  {
    codigo_do_IGBE: 2104909,
    UF: "MA",
    nome_da_cidade: "GUIMARAES",
  },
  {
    codigo_do_IGBE: 3128907,
    UF: "MG",
    nome_da_cidade: "GUIMARANIA",
  },
  {
    codigo_do_IGBE: 5104203,
    UF: "MT",
    nome_da_cidade: "GUIRATINGA",
  },
  {
    codigo_do_IGBE: 3129103,
    UF: "MG",
    nome_da_cidade: "GURINHATA",
  },
  {
    codigo_do_IGBE: 2506400,
    UF: "PB",
    nome_da_cidade: "GURINHEM",
  },
  {
    codigo_do_IGBE: 2506509,
    UF: "PB",
    nome_da_cidade: "GURJAO",
  },
  {
    codigo_do_IGBE: 1503101,
    UF: "PA",
    nome_da_cidade: "GURUPA",
  },
  {
    codigo_do_IGBE: 1709500,
    UF: "TO",
    nome_da_cidade: "GURUPI",
  },
  {
    codigo_do_IGBE: 3518909,
    UF: "SP",
    nome_da_cidade: "GUZOLANDIA",
  },
  {
    codigo_do_IGBE: 5209606,
    UF: "GO",
    nome_da_cidade: "HEITORAI",
  },
  {
    codigo_do_IGBE: 3129202,
    UF: "MG",
    nome_da_cidade: "HELIODORA",
  },
  {
    codigo_do_IGBE: 2911857,
    UF: "BA",
    nome_da_cidade: "HELIOPOLIS",
  },
  {
    codigo_do_IGBE: 3519006,
    UF: "SP",
    nome_da_cidade: "HERCULANDIA",
  },
  {
    codigo_do_IGBE: 4307104,
    UF: "RS",
    nome_da_cidade: "HERVAL",
  },
  {
    codigo_do_IGBE: 4309571,
    UF: "RS",
    nome_da_cidade: "HERVEIRAS",
  },
  {
    codigo_do_IGBE: 2305209,
    UF: "CE",
    nome_da_cidade: "HIDROLANDIA",
  },
  {
    codigo_do_IGBE: 5209705,
    UF: "GO",
    nome_da_cidade: "HIDROLANDIA",
  },
  {
    codigo_do_IGBE: 5209804,
    UF: "GO",
    nome_da_cidade: "HIDROLINA",
  },
  {
    codigo_do_IGBE: 3519055,
    UF: "SP",
    nome_da_cidade: "HOLAMBRA",
  },
  {
    codigo_do_IGBE: 2305233,
    UF: "CE",
    nome_da_cidade: "HORIZONTE",
  },
  {
    codigo_do_IGBE: 4309605,
    UF: "RS",
    nome_da_cidade: "HORIZONTINA",
  },
  {
    codigo_do_IGBE: 3519071,
    UF: "SP",
    nome_da_cidade: "HORTOLANDIA",
  },
  {
    codigo_do_IGBE: 2204600,
    UF: "PI",
    nome_da_cidade: "HUGO NAPOLEAO",
  },
  {
    codigo_do_IGBE: 4309654,
    UF: "RS",
    nome_da_cidade: "HULHA NEGRA",
  },
  {
    codigo_do_IGBE: 1301704,
    UF: "AM",
    nome_da_cidade: "HUMAITA",
  },
  {
    codigo_do_IGBE: 4309704,
    UF: "RS",
    nome_da_cidade: "HUMAITA",
  },
  {
    codigo_do_IGBE: 3519105,
    UF: "SP",
    nome_da_cidade: "IACANGA",
  },
  {
    codigo_do_IGBE: 5209903,
    UF: "GO",
    nome_da_cidade: "IACIARA",
  },
  {
    codigo_do_IGBE: 3519204,
    UF: "SP",
    nome_da_cidade: "IACRI",
  },
  {
    codigo_do_IGBE: 2911907,
    UF: "BA",
    nome_da_cidade: "IACU",
  },
  {
    codigo_do_IGBE: 3129301,
    UF: "MG",
    nome_da_cidade: "IAPU",
  },
  {
    codigo_do_IGBE: 2606507,
    UF: "PE",
    nome_da_cidade: "IATI",
  },
  {
    codigo_do_IGBE: 4109708,
    UF: "PR",
    nome_da_cidade: "IBAITI",
  },
  {
    codigo_do_IGBE: 4309753,
    UF: "RS",
    nome_da_cidade: "IBARAMA",
  },
  {
    codigo_do_IGBE: 2305266,
    UF: "CE",
    nome_da_cidade: "IBARETAMA",
  },
  {
    codigo_do_IGBE: 2703007,
    UF: "AL",
    nome_da_cidade: "IBATEGUARA",
  },
  {
    codigo_do_IGBE: 3202454,
    UF: "ES",
    nome_da_cidade: "IBATIBA",
  },
  {
    codigo_do_IGBE: 4109757,
    UF: "PR",
    nome_da_cidade: "IBEMA",
  },
  {
    codigo_do_IGBE: 3129400,
    UF: "MG",
    nome_da_cidade: "IBERTIOGA",
  },
  {
    codigo_do_IGBE: 3129509,
    UF: "MG",
    nome_da_cidade: "IBIA",
  },
  {
    codigo_do_IGBE: 4309803,
    UF: "RS",
    nome_da_cidade: "IBIACA",
  },
  {
    codigo_do_IGBE: 4206751,
    UF: "SC",
    nome_da_cidade: "IBIAM",
  },
  {
    codigo_do_IGBE: 2305308,
    UF: "CE",
    nome_da_cidade: "IBIAPINA",
  },
  {
    codigo_do_IGBE: 2506608,
    UF: "PB",
    nome_da_cidade: "IBIARA",
  },
  {
    codigo_do_IGBE: 4206801,
    UF: "SC",
    nome_da_cidade: "IBICARE",
  },
  {
    codigo_do_IGBE: 2912202,
    UF: "BA",
    nome_da_cidade: "IBICOARA",
  },
  {
    codigo_do_IGBE: 2912301,
    UF: "BA",
    nome_da_cidade: "IBICUI",
  },
  {
    codigo_do_IGBE: 2305332,
    UF: "CE",
    nome_da_cidade: "IBICUITINGA",
  },
  {
    codigo_do_IGBE: 2606606,
    UF: "PE",
    nome_da_cidade: "IBIMIRIM",
  },
  {
    codigo_do_IGBE: 2912509,
    UF: "BA",
    nome_da_cidade: "IBIPITANGA",
  },
  {
    codigo_do_IGBE: 2912608,
    UF: "BA",
    nome_da_cidade: "IBIQUERA",
  },
  {
    codigo_do_IGBE: 3519402,
    UF: "SP",
    nome_da_cidade: "IBIRA",
  },
  {
    codigo_do_IGBE: 3129657,
    UF: "MG",
    nome_da_cidade: "IBIRACATU",
  },
  {
    codigo_do_IGBE: 3129707,
    UF: "MG",
    nome_da_cidade: "IBIRACI",
  },
  {
    codigo_do_IGBE: 3202504,
    UF: "ES",
    nome_da_cidade: "IBIRACU",
  },
  {
    codigo_do_IGBE: 4309902,
    UF: "RS",
    nome_da_cidade: "IBIRAIARAS",
  },
  {
    codigo_do_IGBE: 2606705,
    UF: "PE",
    nome_da_cidade: "IBIRAJUBA",
  },
  {
    codigo_do_IGBE: 4206900,
    UF: "SC",
    nome_da_cidade: "IBIRAMA",
  },
  {
    codigo_do_IGBE: 2912707,
    UF: "BA",
    nome_da_cidade: "IBIRAPITANGA",
  },
  {
    codigo_do_IGBE: 2912806,
    UF: "BA",
    nome_da_cidade: "IBIRAPUA",
  },
  {
    codigo_do_IGBE: 4309951,
    UF: "RS",
    nome_da_cidade: "IBIRAPUITA",
  },
  {
    codigo_do_IGBE: 3129806,
    UF: "MG",
    nome_da_cidade: "IBIRITE",
  },
  {
    codigo_do_IGBE: 4310009,
    UF: "RS",
    nome_da_cidade: "IBIRUBA",
  },
  {
    codigo_do_IGBE: 2913002,
    UF: "BA",
    nome_da_cidade: "IBITIARA",
  },
  {
    codigo_do_IGBE: 3519600,
    UF: "SP",
    nome_da_cidade: "IBITINGA",
  },
  {
    codigo_do_IGBE: 3202553,
    UF: "ES",
    nome_da_cidade: "IBITIRAMA",
  },
  {
    codigo_do_IGBE: 2913101,
    UF: "BA",
    nome_da_cidade: "IBITITA",
  },
  {
    codigo_do_IGBE: 3129905,
    UF: "MG",
    nome_da_cidade: "IBITIURA DE MINAS",
  },
  {
    codigo_do_IGBE: 3130002,
    UF: "MG",
    nome_da_cidade: "IBITURUNA",
  },
  {
    codigo_do_IGBE: 3519709,
    UF: "SP",
    nome_da_cidade: "IBIUNA",
  },
  {
    codigo_do_IGBE: 2913200,
    UF: "BA",
    nome_da_cidade: "IBOTIRAMA",
  },
  {
    codigo_do_IGBE: 2305357,
    UF: "CE",
    nome_da_cidade: "ICAPUI",
  },
  {
    codigo_do_IGBE: 4207007,
    UF: "SC",
    nome_da_cidade: "ICARA",
  },
  {
    codigo_do_IGBE: 2105104,
    UF: "MA",
    nome_da_cidade: "ICATU",
  },
  {
    codigo_do_IGBE: 3519808,
    UF: "SP",
    nome_da_cidade: "ICEM",
  },
  {
    codigo_do_IGBE: 2913309,
    UF: "BA",
    nome_da_cidade: "ICHU",
  },
  {
    codigo_do_IGBE: 2305407,
    UF: "CE",
    nome_da_cidade: "ICO",
  },
  {
    codigo_do_IGBE: 3202603,
    UF: "ES",
    nome_da_cidade: "ICONHA",
  },
  {
    codigo_do_IGBE: 2404606,
    UF: "RN",
    nome_da_cidade: "IELMO MARINHO",
  },
  {
    codigo_do_IGBE: 3519907,
    UF: "SP",
    nome_da_cidade: "IEPE",
  },
  {
    codigo_do_IGBE: 2703106,
    UF: "AL",
    nome_da_cidade: "IGACI",
  },
  {
    codigo_do_IGBE: 2913408,
    UF: "BA",
    nome_da_cidade: "IGAPORA",
  },
  {
    codigo_do_IGBE: 3520004,
    UF: "SP",
    nome_da_cidade: "IGARACU DO TIETE",
  },
  {
    codigo_do_IGBE: 2502607,
    UF: "PB",
    nome_da_cidade: "IGARACY",
  },
  {
    codigo_do_IGBE: 3520103,
    UF: "SP",
    nome_da_cidade: "IGARAPAVA",
  },
  {
    codigo_do_IGBE: 2105203,
    UF: "MA",
    nome_da_cidade: "IGARAPE GRANDE",
  },
  {
    codigo_do_IGBE: 1503200,
    UF: "PA",
    nome_da_cidade: "IGARAPE-ACU",
  },
  {
    codigo_do_IGBE: 1503309,
    UF: "PA",
    nome_da_cidade: "IGARAPE-MIRI",
  },
  {
    codigo_do_IGBE: 2606804,
    UF: "PE",
    nome_da_cidade: "IGARASSU",
  },
  {
    codigo_do_IGBE: 3520202,
    UF: "SP",
    nome_da_cidade: "IGARATA",
  },
  {
    codigo_do_IGBE: 3130200,
    UF: "MG",
    nome_da_cidade: "IGARATINGA",
  },
  {
    codigo_do_IGBE: 2913457,
    UF: "BA",
    nome_da_cidade: "IGRAPIUNA",
  },
  {
    codigo_do_IGBE: 2703205,
    UF: "AL",
    nome_da_cidade: "IGREJA NOVA",
  },
  {
    codigo_do_IGBE: 4310108,
    UF: "RS",
    nome_da_cidade: "IGREJINHA",
  },
  {
    codigo_do_IGBE: 3301876,
    UF: "RJ",
    nome_da_cidade: "IGUABA GRANDE",
  },
  {
    codigo_do_IGBE: 2913507,
    UF: "BA",
    nome_da_cidade: "IGUAI",
  },
  {
    codigo_do_IGBE: 3520301,
    UF: "SP",
    nome_da_cidade: "IGUAPE",
  },
  {
    codigo_do_IGBE: 4110003,
    UF: "PR",
    nome_da_cidade: "IGUARACU",
  },
  {
    codigo_do_IGBE: 5004304,
    UF: "MS",
    nome_da_cidade: "IGUATEMI",
  },
  {
    codigo_do_IGBE: 2305506,
    UF: "CE",
    nome_da_cidade: "IGUATU",
  },
  {
    codigo_do_IGBE: 4110052,
    UF: "PR",
    nome_da_cidade: "IGUATU",
  },
  {
    codigo_do_IGBE: 3130408,
    UF: "MG",
    nome_da_cidade: "IJACI",
  },
  {
    codigo_do_IGBE: 4310207,
    UF: "RS",
    nome_da_cidade: "IJUI",
  },
  {
    codigo_do_IGBE: 3520426,
    UF: "SP",
    nome_da_cidade: "ILHA COMPRIDA",
  },
  {
    codigo_do_IGBE: 2802700,
    UF: "SE",
    nome_da_cidade: "ILHA DAS FLORES",
  },
  {
    codigo_do_IGBE: 2607604,
    UF: "PE",
    nome_da_cidade: "ILHA DE ITAMARACA",
  },
  {
    codigo_do_IGBE: 2204659,
    UF: "PI",
    nome_da_cidade: "ILHA GRANDE",
  },
  {
    codigo_do_IGBE: 3520400,
    UF: "SP",
    nome_da_cidade: "ILHABELA",
  },
  {
    codigo_do_IGBE: 2913606,
    UF: "BA",
    nome_da_cidade: "ILHEUS",
  },
  {
    codigo_do_IGBE: 3130507,
    UF: "MG",
    nome_da_cidade: "ILICINEA",
  },
  {
    codigo_do_IGBE: 4310306,
    UF: "RS",
    nome_da_cidade: "ILOPOLIS",
  },
  {
    codigo_do_IGBE: 2506707,
    UF: "PB",
    nome_da_cidade: "IMACULADA",
  },
  {
    codigo_do_IGBE: 4207205,
    UF: "SC",
    nome_da_cidade: "IMARUI",
  },
  {
    codigo_do_IGBE: 4110078,
    UF: "PR",
    nome_da_cidade: "IMBAU",
  },
  {
    codigo_do_IGBE: 4310330,
    UF: "RS",
    nome_da_cidade: "IMBE",
  },
  {
    codigo_do_IGBE: 3130556,
    UF: "MG",
    nome_da_cidade: "IMBE DE MINAS",
  },
  {
    codigo_do_IGBE: 4207304,
    UF: "SC",
    nome_da_cidade: "IMBITUBA",
  },
  {
    codigo_do_IGBE: 4110102,
    UF: "PR",
    nome_da_cidade: "IMBITUVA",
  },
  {
    codigo_do_IGBE: 4207403,
    UF: "SC",
    nome_da_cidade: "IMBUIA",
  },
  {
    codigo_do_IGBE: 4310363,
    UF: "RS",
    nome_da_cidade: "IMIGRANTE",
  },
  {
    codigo_do_IGBE: 2105302,
    UF: "MA",
    nome_da_cidade: "IMPERATRIZ",
  },
  {
    codigo_do_IGBE: 5209937,
    UF: "GO",
    nome_da_cidade: "INACIOLANDIA",
  },
  {
    codigo_do_IGBE: 2607000,
    UF: "PE",
    nome_da_cidade: "INAJA",
  },
  {
    codigo_do_IGBE: 3130606,
    UF: "MG",
    nome_da_cidade: "INCONFIDENTES",
  },
  {
    codigo_do_IGBE: 3130655,
    UF: "MG",
    nome_da_cidade: "INDAIABIRA",
  },
  {
    codigo_do_IGBE: 4207502,
    UF: "SC",
    nome_da_cidade: "INDAIAL",
  },
  {
    codigo_do_IGBE: 3520509,
    UF: "SP",
    nome_da_cidade: "INDAIATUBA",
  },
  {
    codigo_do_IGBE: 2305605,
    UF: "CE",
    nome_da_cidade: "INDEPENDENCIA",
  },
  {
    codigo_do_IGBE: 4310405,
    UF: "RS",
    nome_da_cidade: "INDEPENDENCIA",
  },
  {
    codigo_do_IGBE: 3520608,
    UF: "SP",
    nome_da_cidade: "INDIANA",
  },
  {
    codigo_do_IGBE: 3130705,
    UF: "MG",
    nome_da_cidade: "INDIANOPOLIS",
  },
  {
    codigo_do_IGBE: 4110409,
    UF: "PR",
    nome_da_cidade: "INDIANOPOLIS",
  },
  {
    codigo_do_IGBE: 3520707,
    UF: "SP",
    nome_da_cidade: "INDIAPORA",
  },
  {
    codigo_do_IGBE: 5209952,
    UF: "GO",
    nome_da_cidade: "INDIARA",
  },
  {
    codigo_do_IGBE: 5104500,
    UF: "MT",
    nome_da_cidade: "INDIAVAI",
  },
  {
    codigo_do_IGBE: 2506806,
    UF: "PB",
    nome_da_cidade: "INGA",
  },
  {
    codigo_do_IGBE: 2607109,
    UF: "PE",
    nome_da_cidade: "INGAZEIRA",
  },
  {
    codigo_do_IGBE: 4310413,
    UF: "RS",
    nome_da_cidade: "INHACORA",
  },
  {
    codigo_do_IGBE: 2913705,
    UF: "BA",
    nome_da_cidade: "INHAMBUPE",
  },
  {
    codigo_do_IGBE: 1503408,
    UF: "PA",
    nome_da_cidade: "INHANGAPI",
  },
  {
    codigo_do_IGBE: 2703304,
    UF: "AL",
    nome_da_cidade: "INHAPI",
  },
  {
    codigo_do_IGBE: 3130903,
    UF: "MG",
    nome_da_cidade: "INHAPIM",
  },
  {
    codigo_do_IGBE: 3131000,
    UF: "MG",
    nome_da_cidade: "INHAUMA",
  },
  {
    codigo_do_IGBE: 2204709,
    UF: "PI",
    nome_da_cidade: "INHUMA",
  },
  {
    codigo_do_IGBE: 5210000,
    UF: "GO",
    nome_da_cidade: "INHUMAS",
  },
  {
    codigo_do_IGBE: 5004403,
    UF: "MS",
    nome_da_cidade: "INOCENCIA",
  },
  {
    codigo_do_IGBE: 4207577,
    UF: "SC",
    nome_da_cidade: "IOMERE",
  },
  {
    codigo_do_IGBE: 3131158,
    UF: "MG",
    nome_da_cidade: "IPABA",
  },
  {
    codigo_do_IGBE: 5210109,
    UF: "GO",
    nome_da_cidade: "IPAMERI",
  },
  {
    codigo_do_IGBE: 3131406,
    UF: "MG",
    nome_da_cidade: "IPIACU",
  },
  {
    codigo_do_IGBE: 2913903,
    UF: "BA",
    nome_da_cidade: "IPIAU",
  },
  {
    codigo_do_IGBE: 3521150,
    UF: "SP",
    nome_da_cidade: "IPIGUA",
  },
  {
    codigo_do_IGBE: 2914000,
    UF: "BA",
    nome_da_cidade: "IPIRA",
  },
  {
    codigo_do_IGBE: 4207601,
    UF: "SC",
    nome_da_cidade: "IPIRA",
  },
  {
    codigo_do_IGBE: 4110508,
    UF: "PR",
    nome_da_cidade: "IPIRANGA",
  },
  {
    codigo_do_IGBE: 5210158,
    UF: "GO",
    nome_da_cidade: "IPIRANGA DE GOIAS",
  },
  {
    codigo_do_IGBE: 5104526,
    UF: "MT",
    nome_da_cidade: "IPIRANGA DO NORTE",
  },
  {
    codigo_do_IGBE: 4310462,
    UF: "RS",
    nome_da_cidade: "IPIRANGA DO SUL",
  },
  {
    codigo_do_IGBE: 1301803,
    UF: "AM",
    nome_da_cidade: "IPIXUNA",
  },
  {
    codigo_do_IGBE: 2607208,
    UF: "PE",
    nome_da_cidade: "IPOJUCA",
  },
  {
    codigo_do_IGBE: 5210208,
    UF: "GO",
    nome_da_cidade: "IPORA",
  },
  {
    codigo_do_IGBE: 4110607,
    UF: "PR",
    nome_da_cidade: "IPORA",
  },
  {
    codigo_do_IGBE: 4207650,
    UF: "SC",
    nome_da_cidade: "IPORA DO OESTE",
  },
  {
    codigo_do_IGBE: 3521200,
    UF: "SP",
    nome_da_cidade: "IPORANGA",
  },
  {
    codigo_do_IGBE: 2305803,
    UF: "CE",
    nome_da_cidade: "IPU",
  },
  {
    codigo_do_IGBE: 3521309,
    UF: "SP",
    nome_da_cidade: "IPUA",
  },
  {
    codigo_do_IGBE: 4207684,
    UF: "SC",
    nome_da_cidade: "IPUACU",
  },
  {
    codigo_do_IGBE: 2607307,
    UF: "PE",
    nome_da_cidade: "IPUBI",
  },
  {
    codigo_do_IGBE: 2404804,
    UF: "RN",
    nome_da_cidade: "IPUEIRA",
  },
  {
    codigo_do_IGBE: 2305902,
    UF: "CE",
    nome_da_cidade: "IPUEIRAS",
  },
  {
    codigo_do_IGBE: 1709807,
    UF: "TO",
    nome_da_cidade: "IPUEIRAS",
  },
  {
    codigo_do_IGBE: 3131505,
    UF: "MG",
    nome_da_cidade: "IPUIUNA",
  },
  {
    codigo_do_IGBE: 2914109,
    UF: "BA",
    nome_da_cidade: "IPUPIARA",
  },
  {
    codigo_do_IGBE: 2306009,
    UF: "CE",
    nome_da_cidade: "IRACEMA",
  },
  {
    codigo_do_IGBE: 1400282,
    UF: "RR",
    nome_da_cidade: "IRACEMA",
  },
  {
    codigo_do_IGBE: 3521408,
    UF: "SP",
    nome_da_cidade: "IRACEMAPOLIS",
  },
  {
    codigo_do_IGBE: 4207759,
    UF: "SC",
    nome_da_cidade: "IRACEMINHA",
  },
  {
    codigo_do_IGBE: 4310504,
    UF: "RS",
    nome_da_cidade: "IRAI",
  },
  {
    codigo_do_IGBE: 3131604,
    UF: "MG",
    nome_da_cidade: "IRAI DE MINAS",
  },
  {
    codigo_do_IGBE: 2914208,
    UF: "BA",
    nome_da_cidade: "IRAJUBA",
  },
  {
    codigo_do_IGBE: 2306108,
    UF: "CE",
    nome_da_cidade: "IRAUCUBA",
  },
  {
    codigo_do_IGBE: 2914604,
    UF: "BA",
    nome_da_cidade: "IRECE",
  },
  {
    codigo_do_IGBE: 4110805,
    UF: "PR",
    nome_da_cidade: "IRETAMA",
  },
  {
    codigo_do_IGBE: 4207908,
    UF: "SC",
    nome_da_cidade: "IRINEOPOLIS",
  },
  {
    codigo_do_IGBE: 1503507,
    UF: "PA",
    nome_da_cidade: "IRITUIA",
  },
  {
    codigo_do_IGBE: 3202652,
    UF: "ES",
    nome_da_cidade: "IRUPI",
  },
  {
    codigo_do_IGBE: 5210307,
    UF: "GO",
    nome_da_cidade: "ISRAELANDIA",
  },
  {
    codigo_do_IGBE: 4208005,
    UF: "SC",
    nome_da_cidade: "ITA",
  },
  {
    codigo_do_IGBE: 4310538,
    UF: "RS",
    nome_da_cidade: "ITAARA",
  },
  {
    codigo_do_IGBE: 2802908,
    UF: "SE",
    nome_da_cidade: "ITABAIANA",
  },
  {
    codigo_do_IGBE: 2803005,
    UF: "SE",
    nome_da_cidade: "ITABAIANINHA",
  },
  {
    codigo_do_IGBE: 2914653,
    UF: "BA",
    nome_da_cidade: "ITABELA",
  },
  {
    codigo_do_IGBE: 3521705,
    UF: "SP",
    nome_da_cidade: "ITABERA",
  },
  {
    codigo_do_IGBE: 2914703,
    UF: "BA",
    nome_da_cidade: "ITABERABA",
  },
  {
    codigo_do_IGBE: 5210406,
    UF: "GO",
    nome_da_cidade: "ITABERAI",
  },
  {
    codigo_do_IGBE: 2803104,
    UF: "SE",
    nome_da_cidade: "ITABI",
  },
  {
    codigo_do_IGBE: 3131703,
    UF: "MG",
    nome_da_cidade: "ITABIRA",
  },
  {
    codigo_do_IGBE: 3131901,
    UF: "MG",
    nome_da_cidade: "ITABIRITO",
  },
  {
    codigo_do_IGBE: 3301900,
    UF: "RJ",
    nome_da_cidade: "ITABORAI",
  },
  {
    codigo_do_IGBE: 2914802,
    UF: "BA",
    nome_da_cidade: "ITABUNA",
  },
  {
    codigo_do_IGBE: 1710508,
    UF: "TO",
    nome_da_cidade: "ITACAJA",
  },
  {
    codigo_do_IGBE: 3132107,
    UF: "MG",
    nome_da_cidade: "ITACARAMBI",
  },
  {
    codigo_do_IGBE: 2914901,
    UF: "BA",
    nome_da_cidade: "ITACARE",
  },
  {
    codigo_do_IGBE: 1301902,
    UF: "AM",
    nome_da_cidade: "ITACOATIARA",
  },
  {
    codigo_do_IGBE: 2607406,
    UF: "PE",
    nome_da_cidade: "ITACURUBA",
  },
  {
    codigo_do_IGBE: 4310553,
    UF: "RS",
    nome_da_cidade: "ITACURUBI",
  },
  {
    codigo_do_IGBE: 2915007,
    UF: "BA",
    nome_da_cidade: "ITAETE",
  },
  {
    codigo_do_IGBE: 2915106,
    UF: "BA",
    nome_da_cidade: "ITAGI",
  },
  {
    codigo_do_IGBE: 2915205,
    UF: "BA",
    nome_da_cidade: "ITAGIBA",
  },
  {
    codigo_do_IGBE: 2915304,
    UF: "BA",
    nome_da_cidade: "ITAGIMIRIM",
  },
  {
    codigo_do_IGBE: 3202702,
    UF: "ES",
    nome_da_cidade: "ITAGUACU",
  },
  {
    codigo_do_IGBE: 3302007,
    UF: "RJ",
    nome_da_cidade: "ITAGUAI",
  },
  {
    codigo_do_IGBE: 4110904,
    UF: "PR",
    nome_da_cidade: "ITAGUAJE",
  },
  {
    codigo_do_IGBE: 3132206,
    UF: "MG",
    nome_da_cidade: "ITAGUARA",
  },
  {
    codigo_do_IGBE: 5210604,
    UF: "GO",
    nome_da_cidade: "ITAGUARU",
  },
  {
    codigo_do_IGBE: 1710706,
    UF: "TO",
    nome_da_cidade: "ITAGUATINS",
  },
  {
    codigo_do_IGBE: 3521804,
    UF: "SP",
    nome_da_cidade: "ITAI",
  },
  {
    codigo_do_IGBE: 2607505,
    UF: "PE",
    nome_da_cidade: "ITAIBA",
  },
  {
    codigo_do_IGBE: 2306207,
    UF: "CE",
    nome_da_cidade: "ITAICABA",
  },
  {
    codigo_do_IGBE: 2205003,
    UF: "PI",
    nome_da_cidade: "ITAINOPOLIS",
  },
  {
    codigo_do_IGBE: 4208104,
    UF: "SC",
    nome_da_cidade: "ITAIOPOLIS",
  },
  {
    codigo_do_IGBE: 2105351,
    UF: "MA",
    nome_da_cidade: "ITAIPAVA DO GRAJAU",
  },
  {
    codigo_do_IGBE: 3132305,
    UF: "MG",
    nome_da_cidade: "ITAIPE",
  },
  {
    codigo_do_IGBE: 4110953,
    UF: "PR",
    nome_da_cidade: "ITAIPULANDIA",
  },
  {
    codigo_do_IGBE: 2306256,
    UF: "CE",
    nome_da_cidade: "ITAITINGA",
  },
  {
    codigo_do_IGBE: 1503606,
    UF: "PA",
    nome_da_cidade: "ITAITUBA",
  },
  {
    codigo_do_IGBE: 2404853,
    UF: "RN",
    nome_da_cidade: "ITAJA",
  },
  {
    codigo_do_IGBE: 3521903,
    UF: "SP",
    nome_da_cidade: "ITAJOBI",
  },
  {
    codigo_do_IGBE: 3522000,
    UF: "SP",
    nome_da_cidade: "ITAJU",
  },
  {
    codigo_do_IGBE: 2915403,
    UF: "BA",
    nome_da_cidade: "ITAJU DO COLONIA",
  },
  {
    codigo_do_IGBE: 3132404,
    UF: "MG",
    nome_da_cidade: "ITAJUBA",
  },
  {
    codigo_do_IGBE: 2915502,
    UF: "BA",
    nome_da_cidade: "ITAJUIPE",
  },
  {
    codigo_do_IGBE: 3302056,
    UF: "RJ",
    nome_da_cidade: "ITALVA",
  },
  {
    codigo_do_IGBE: 2915601,
    UF: "BA",
    nome_da_cidade: "ITAMARAJU",
  },
  {
    codigo_do_IGBE: 3132503,
    UF: "MG",
    nome_da_cidade: "ITAMARANDIBA",
  },
  {
    codigo_do_IGBE: 1301951,
    UF: "AM",
    nome_da_cidade: "ITAMARATI",
  },
  {
    codigo_do_IGBE: 2915700,
    UF: "BA",
    nome_da_cidade: "ITAMARI",
  },
  {
    codigo_do_IGBE: 3132701,
    UF: "MG",
    nome_da_cidade: "ITAMBACURI",
  },
  {
    codigo_do_IGBE: 4111001,
    UF: "PR",
    nome_da_cidade: "ITAMBARACA",
  },
  {
    codigo_do_IGBE: 2607653,
    UF: "PE",
    nome_da_cidade: "ITAMBE",
  },
  {
    codigo_do_IGBE: 4111100,
    UF: "PR",
    nome_da_cidade: "ITAMBE",
  },
  {
    codigo_do_IGBE: 3132800,
    UF: "MG",
    nome_da_cidade: "ITAMBE DO MATO DENTRO",
  },
  {
    codigo_do_IGBE: 3132909,
    UF: "MG",
    nome_da_cidade: "ITAMOGI",
  },
  {
    codigo_do_IGBE: 3133006,
    UF: "MG",
    nome_da_cidade: "ITAMONTE",
  },
  {
    codigo_do_IGBE: 2915908,
    UF: "BA",
    nome_da_cidade: "ITANAGRA",
  },
  {
    codigo_do_IGBE: 3522109,
    UF: "SP",
    nome_da_cidade: "ITANHAEM",
  },
  {
    codigo_do_IGBE: 3133105,
    UF: "MG",
    nome_da_cidade: "ITANHANDU",
  },
  {
    codigo_do_IGBE: 5104542,
    UF: "MT",
    nome_da_cidade: "ITANHANGA",
  },
  {
    codigo_do_IGBE: 2916005,
    UF: "BA",
    nome_da_cidade: "ITANHEM",
  },
  {
    codigo_do_IGBE: 3133204,
    UF: "MG",
    nome_da_cidade: "ITANHOMI",
  },
  {
    codigo_do_IGBE: 3522158,
    UF: "SP",
    nome_da_cidade: "ITAOCA",
  },
  {
    codigo_do_IGBE: 5210901,
    UF: "GO",
    nome_da_cidade: "ITAPACI",
  },
  {
    codigo_do_IGBE: 2306306,
    UF: "CE",
    nome_da_cidade: "ITAPAGE",
  },
  {
    codigo_do_IGBE: 3133402,
    UF: "MG",
    nome_da_cidade: "ITAPAGIPE",
  },
  {
    codigo_do_IGBE: 2916104,
    UF: "BA",
    nome_da_cidade: "ITAPARICA",
  },
  {
    codigo_do_IGBE: 2916203,
    UF: "BA",
    nome_da_cidade: "ITAPE",
  },
  {
    codigo_do_IGBE: 2916302,
    UF: "BA",
    nome_da_cidade: "ITAPEBI",
  },
  {
    codigo_do_IGBE: 3133501,
    UF: "MG",
    nome_da_cidade: "ITAPECERICA",
  },
  {
    codigo_do_IGBE: 3522208,
    UF: "SP",
    nome_da_cidade: "ITAPECERICA DA SERRA",
  },
  {
    codigo_do_IGBE: 4111209,
    UF: "PR",
    nome_da_cidade: "ITAPEJARA D'OESTE",
  },
  {
    codigo_do_IGBE: 4208302,
    UF: "SC",
    nome_da_cidade: "ITAPEMA",
  },
  {
    codigo_do_IGBE: 3202801,
    UF: "ES",
    nome_da_cidade: "ITAPEMIRIM",
  },
  {
    codigo_do_IGBE: 4111258,
    UF: "PR",
    nome_da_cidade: "ITAPERUCU",
  },
  {
    codigo_do_IGBE: 2607703,
    UF: "PE",
    nome_da_cidade: "ITAPETIM",
  },
  {
    codigo_do_IGBE: 2916401,
    UF: "BA",
    nome_da_cidade: "ITAPETINGA",
  },
  {
    codigo_do_IGBE: 3522307,
    UF: "SP",
    nome_da_cidade: "ITAPETININGA",
  },
  {
    codigo_do_IGBE: 3133600,
    UF: "MG",
    nome_da_cidade: "ITAPEVA",
  },
  {
    codigo_do_IGBE: 3522406,
    UF: "SP",
    nome_da_cidade: "ITAPEVA",
  },
  {
    codigo_do_IGBE: 3522505,
    UF: "SP",
    nome_da_cidade: "ITAPEVI",
  },
  {
    codigo_do_IGBE: 1710904,
    UF: "TO",
    nome_da_cidade: "ITAPIRATINS",
  },
  {
    codigo_do_IGBE: 2607752,
    UF: "PE",
    nome_da_cidade: "ITAPISSUMA",
  },
  {
    codigo_do_IGBE: 2916609,
    UF: "BA",
    nome_da_cidade: "ITAPITANGA",
  },
  {
    codigo_do_IGBE: 2306504,
    UF: "CE",
    nome_da_cidade: "ITAPIUNA",
  },
  {
    codigo_do_IGBE: 4208450,
    UF: "SC",
    nome_da_cidade: "ITAPOA",
  },
  {
    codigo_do_IGBE: 3522703,
    UF: "SP",
    nome_da_cidade: "ITAPOLIS",
  },
  {
    codigo_do_IGBE: 2507002,
    UF: "PB",
    nome_da_cidade: "ITAPORANGA",
  },
  {
    codigo_do_IGBE: 3522802,
    UF: "SP",
    nome_da_cidade: "ITAPORANGA",
  },
  {
    codigo_do_IGBE: 2803203,
    UF: "SE",
    nome_da_cidade: "ITAPORANGA D'AJUDA",
  },
  {
    codigo_do_IGBE: 2507101,
    UF: "PB",
    nome_da_cidade: "ITAPOROROCA",
  },
  {
    codigo_do_IGBE: 1101104,
    UF: "RO",
    nome_da_cidade: "ITAPUA DO OESTE",
  },
  {
    codigo_do_IGBE: 4310579,
    UF: "RS",
    nome_da_cidade: "ITAPUCA",
  },
  {
    codigo_do_IGBE: 3522901,
    UF: "SP",
    nome_da_cidade: "ITAPUI",
  },
  {
    codigo_do_IGBE: 3523008,
    UF: "SP",
    nome_da_cidade: "ITAPURA",
  },
  {
    codigo_do_IGBE: 5211206,
    UF: "GO",
    nome_da_cidade: "ITAPURANGA",
  },
  {
    codigo_do_IGBE: 3523107,
    UF: "SP",
    nome_da_cidade: "ITAQUAQUECETUBA",
  },
  {
    codigo_do_IGBE: 2916708,
    UF: "BA",
    nome_da_cidade: "ITAQUARA",
  },
  {
    codigo_do_IGBE: 4310603,
    UF: "RS",
    nome_da_cidade: "ITAQUI",
  },
  {
    codigo_do_IGBE: 2607802,
    UF: "PE",
    nome_da_cidade: "ITAQUITINGA",
  },
  {
    codigo_do_IGBE: 2916807,
    UF: "BA",
    nome_da_cidade: "ITARANTIM",
  },
  {
    codigo_do_IGBE: 3523206,
    UF: "SP",
    nome_da_cidade: "ITARARE",
  },
  {
    codigo_do_IGBE: 2306553,
    UF: "CE",
    nome_da_cidade: "ITAREMA",
  },
  {
    codigo_do_IGBE: 3523305,
    UF: "SP",
    nome_da_cidade: "ITARIRI",
  },
  {
    codigo_do_IGBE: 5211305,
    UF: "GO",
    nome_da_cidade: "ITARUMA",
  },
  {
    codigo_do_IGBE: 4310652,
    UF: "RS",
    nome_da_cidade: "ITATI",
  },
  {
    codigo_do_IGBE: 3302254,
    UF: "RJ",
    nome_da_cidade: "ITATIAIA",
  },
  {
    codigo_do_IGBE: 3133709,
    UF: "MG",
    nome_da_cidade: "ITATIAIUCU",
  },
  {
    codigo_do_IGBE: 3523404,
    UF: "SP",
    nome_da_cidade: "ITATIBA",
  },
  {
    codigo_do_IGBE: 2916856,
    UF: "BA",
    nome_da_cidade: "ITATIM",
  },
  {
    codigo_do_IGBE: 3523503,
    UF: "SP",
    nome_da_cidade: "ITATINGA",
  },
  {
    codigo_do_IGBE: 2507200,
    UF: "PB",
    nome_da_cidade: "ITATUBA",
  },
  {
    codigo_do_IGBE: 2404903,
    UF: "RN",
    nome_da_cidade: "ITAU",
  },
  {
    codigo_do_IGBE: 3133758,
    UF: "MG",
    nome_da_cidade: "ITAU DE MINAS",
  },
  {
    codigo_do_IGBE: 5104559,
    UF: "MT",
    nome_da_cidade: "ITAUBA",
  },
  {
    codigo_do_IGBE: 1600253,
    UF: "AP",
    nome_da_cidade: "ITAUBAL",
  },
  {
    codigo_do_IGBE: 5211404,
    UF: "GO",
    nome_da_cidade: "ITAUCU",
  },
  {
    codigo_do_IGBE: 2205102,
    UF: "PI",
    nome_da_cidade: "ITAUEIRA",
  },
  {
    codigo_do_IGBE: 3133808,
    UF: "MG",
    nome_da_cidade: "ITAUNA",
  },
  {
    codigo_do_IGBE: 4111308,
    UF: "PR",
    nome_da_cidade: "ITAUNA DO SUL",
  },
  {
    codigo_do_IGBE: 3133907,
    UF: "MG",
    nome_da_cidade: "ITAVERAVA",
  },
  {
    codigo_do_IGBE: 3134004,
    UF: "MG",
    nome_da_cidade: "ITINGA",
  },
  {
    codigo_do_IGBE: 5104609,
    UF: "MT",
    nome_da_cidade: "ITIQUIRA",
  },
  {
    codigo_do_IGBE: 3523602,
    UF: "SP",
    nome_da_cidade: "ITIRAPINA",
  },
  {
    codigo_do_IGBE: 3523701,
    UF: "SP",
    nome_da_cidade: "ITIRAPUA",
  },
  {
    codigo_do_IGBE: 2917003,
    UF: "BA",
    nome_da_cidade: "ITIUBA",
  },
  {
    codigo_do_IGBE: 3523800,
    UF: "SP",
    nome_da_cidade: "ITOBI",
  },
  {
    codigo_do_IGBE: 2917102,
    UF: "BA",
    nome_da_cidade: "ITORORO",
  },
  {
    codigo_do_IGBE: 4208500,
    UF: "SC",
    nome_da_cidade: "ITUPORANGA",
  },
  {
    codigo_do_IGBE: 3134400,
    UF: "MG",
    nome_da_cidade: "ITURAMA",
  },
  {
    codigo_do_IGBE: 3134509,
    UF: "MG",
    nome_da_cidade: "ITUTINGA",
  },
  {
    codigo_do_IGBE: 3524105,
    UF: "SP",
    nome_da_cidade: "ITUVERAVA",
  },
  {
    codigo_do_IGBE: 2917334,
    UF: "BA",
    nome_da_cidade: "IUIU",
  },
  {
    codigo_do_IGBE: 3203007,
    UF: "ES",
    nome_da_cidade: "IUNA",
  },
  {
    codigo_do_IGBE: 4111506,
    UF: "PR",
    nome_da_cidade: "IVAIPORA",
  },
  {
    codigo_do_IGBE: 4111555,
    UF: "PR",
    nome_da_cidade: "IVATE",
  },
  {
    codigo_do_IGBE: 4111605,
    UF: "PR",
    nome_da_cidade: "IVATUBA",
  },
  {
    codigo_do_IGBE: 5004700,
    UF: "MS",
    nome_da_cidade: "IVINHEMA",
  },
  {
    codigo_do_IGBE: 5211602,
    UF: "GO",
    nome_da_cidade: "IVOLANDIA",
  },
  {
    codigo_do_IGBE: 4310801,
    UF: "RS",
    nome_da_cidade: "IVOTI",
  },
  {
    codigo_do_IGBE: 4208609,
    UF: "SC",
    nome_da_cidade: "JABORA",
  },
  {
    codigo_do_IGBE: 2917359,
    UF: "BA",
    nome_da_cidade: "JABORANDI",
  },
  {
    codigo_do_IGBE: 3524204,
    UF: "SP",
    nome_da_cidade: "JABORANDI",
  },
  {
    codigo_do_IGBE: 4111704,
    UF: "PR",
    nome_da_cidade: "JABOTI",
  },
  {
    codigo_do_IGBE: 4310850,
    UF: "RS",
    nome_da_cidade: "JABOTICABA",
  },
  {
    codigo_do_IGBE: 3524303,
    UF: "SP",
    nome_da_cidade: "JABOTICABAL",
  },
  {
    codigo_do_IGBE: 3134608,
    UF: "MG",
    nome_da_cidade: "JABOTICATUBAS",
  },
  {
    codigo_do_IGBE: 2405009,
    UF: "RN",
    nome_da_cidade: "JACANA",
  },
  {
    codigo_do_IGBE: 2917409,
    UF: "BA",
    nome_da_cidade: "JACARACI",
  },
  {
    codigo_do_IGBE: 2507309,
    UF: "PB",
    nome_da_cidade: "JACARAU",
  },
  {
    codigo_do_IGBE: 1503754,
    UF: "PA",
    nome_da_cidade: "JACAREACANGA",
  },
  {
    codigo_do_IGBE: 3524402,
    UF: "SP",
    nome_da_cidade: "JACAREI",
  },
  {
    codigo_do_IGBE: 4111803,
    UF: "PR",
    nome_da_cidade: "JACAREZINHO",
  },
  {
    codigo_do_IGBE: 3524501,
    UF: "SP",
    nome_da_cidade: "JACI",
  },
  {
    codigo_do_IGBE: 5104807,
    UF: "MT",
    nome_da_cidade: "JACIARA",
  },
  {
    codigo_do_IGBE: 3134707,
    UF: "MG",
    nome_da_cidade: "JACINTO",
  },
  {
    codigo_do_IGBE: 2917508,
    UF: "BA",
    nome_da_cidade: "JACOBINA",
  },
  {
    codigo_do_IGBE: 2205151,
    UF: "PI",
    nome_da_cidade: "JACOBINA DO PIAUI",
  },
  {
    codigo_do_IGBE: 3134806,
    UF: "MG",
    nome_da_cidade: "JACUI",
  },
  {
    codigo_do_IGBE: 2703502,
    UF: "AL",
    nome_da_cidade: "JACUIPE",
  },
  {
    codigo_do_IGBE: 4310876,
    UF: "RS",
    nome_da_cidade: "JACUIZINHO",
  },
  {
    codigo_do_IGBE: 1503804,
    UF: "PA",
    nome_da_cidade: "JACUNDA",
  },
  {
    codigo_do_IGBE: 3524600,
    UF: "SP",
    nome_da_cidade: "JACUPIRANGA",
  },
  {
    codigo_do_IGBE: 4310900,
    UF: "RS",
    nome_da_cidade: "JACUTINGA",
  },
  {
    codigo_do_IGBE: 4111902,
    UF: "PR",
    nome_da_cidade: "JAGUAPITA",
  },
  {
    codigo_do_IGBE: 2917607,
    UF: "BA",
    nome_da_cidade: "JAGUAQUARA",
  },
  {
    codigo_do_IGBE: 3135001,
    UF: "MG",
    nome_da_cidade: "JAGUARACU",
  },
  {
    codigo_do_IGBE: 4311007,
    UF: "RS",
    nome_da_cidade: "JAGUARAO",
  },
  {
    codigo_do_IGBE: 2917706,
    UF: "BA",
    nome_da_cidade: "JAGUARARI",
  },
  {
    codigo_do_IGBE: 3203056,
    UF: "ES",
    nome_da_cidade: "JAGUARE",
  },
  {
    codigo_do_IGBE: 2306702,
    UF: "CE",
    nome_da_cidade: "JAGUARETAMA",
  },
  {
    codigo_do_IGBE: 4311106,
    UF: "RS",
    nome_da_cidade: "JAGUARI",
  },
  {
    codigo_do_IGBE: 2306801,
    UF: "CE",
    nome_da_cidade: "JAGUARIBARA",
  },
  {
    codigo_do_IGBE: 2306900,
    UF: "CE",
    nome_da_cidade: "JAGUARIBE",
  },
  {
    codigo_do_IGBE: 3524709,
    UF: "SP",
    nome_da_cidade: "JAGUARIUNA",
  },
  {
    codigo_do_IGBE: 2307007,
    UF: "CE",
    nome_da_cidade: "JAGUARUANA",
  },
  {
    codigo_do_IGBE: 4208807,
    UF: "SC",
    nome_da_cidade: "JAGUARUNA",
  },
  {
    codigo_do_IGBE: 3135050,
    UF: "MG",
    nome_da_cidade: "JAIBA",
  },
  {
    codigo_do_IGBE: 2205201,
    UF: "PI",
    nome_da_cidade: "JAICOS",
  },
  {
    codigo_do_IGBE: 3524808,
    UF: "SP",
    nome_da_cidade: "JALES",
  },
  {
    codigo_do_IGBE: 3524907,
    UF: "SP",
    nome_da_cidade: "JAMBEIRO",
  },
  {
    codigo_do_IGBE: 3135076,
    UF: "MG",
    nome_da_cidade: "JAMPRUCA",
  },
  {
    codigo_do_IGBE: 3135100,
    UF: "MG",
    nome_da_cidade: "JANAUBA",
  },
  {
    codigo_do_IGBE: 5211701,
    UF: "GO",
    nome_da_cidade: "JANDAIA",
  },
  {
    codigo_do_IGBE: 2405108,
    UF: "RN",
    nome_da_cidade: "JANDAIRA",
  },
  {
    codigo_do_IGBE: 3525003,
    UF: "SP",
    nome_da_cidade: "JANDIRA",
  },
  {
    codigo_do_IGBE: 2405207,
    UF: "RN",
    nome_da_cidade: "JANDUIS",
  },
  {
    codigo_do_IGBE: 5104906,
    UF: "MT",
    nome_da_cidade: "JANGADA",
  },
  {
    codigo_do_IGBE: 4112207,
    UF: "PR",
    nome_da_cidade: "JANIOPOLIS",
  },
  {
    codigo_do_IGBE: 3135209,
    UF: "MG",
    nome_da_cidade: "JANUARIA",
  },
  {
    codigo_do_IGBE: 2405306,
    UF: "RN",
    nome_da_cidade: "JANUARIO CICCO",
  },
  {
    codigo_do_IGBE: 3135308,
    UF: "MG",
    nome_da_cidade: "JAPARAIBA",
  },
  {
    codigo_do_IGBE: 2703601,
    UF: "AL",
    nome_da_cidade: "JAPARATINGA",
  },
  {
    codigo_do_IGBE: 2803302,
    UF: "SE",
    nome_da_cidade: "JAPARATUBA",
  },
  {
    codigo_do_IGBE: 3302270,
    UF: "RJ",
    nome_da_cidade: "JAPERI",
  },
  {
    codigo_do_IGBE: 2405405,
    UF: "RN",
    nome_da_cidade: "JAPI",
  },
  {
    codigo_do_IGBE: 2803401,
    UF: "SE",
    nome_da_cidade: "JAPOATA",
  },
  {
    codigo_do_IGBE: 3135357,
    UF: "MG",
    nome_da_cidade: "JAPONVAR",
  },
  {
    codigo_do_IGBE: 1302108,
    UF: "AM",
    nome_da_cidade: "JAPURA",
  },
  {
    codigo_do_IGBE: 5005004,
    UF: "MS",
    nome_da_cidade: "JARDIM",
  },
  {
    codigo_do_IGBE: 2405504,
    UF: "RN",
    nome_da_cidade: "JARDIM DE ANGICOS",
  },
  {
    codigo_do_IGBE: 1711506,
    UF: "TO",
    nome_da_cidade: "JAU DO TOCANTINS",
  },
  {
    codigo_do_IGBE: 5212006,
    UF: "GO",
    nome_da_cidade: "JAUPACI",
  },
  {
    codigo_do_IGBE: 5105002,
    UF: "MT",
    nome_da_cidade: "JAURU",
  },
  {
    codigo_do_IGBE: 3135407,
    UF: "MG",
    nome_da_cidade: "JECEABA",
  },
  {
    codigo_do_IGBE: 3135456,
    UF: "MG",
    nome_da_cidade: "JENIPAPO DE MINAS",
  },
  {
    codigo_do_IGBE: 2105476,
    UF: "MA",
    nome_da_cidade: "JENIPAPO DOS VIEIRAS",
  },
  {
    codigo_do_IGBE: 3135506,
    UF: "MG",
    nome_da_cidade: "JEQUERI",
  },
  {
    codigo_do_IGBE: 2703759,
    UF: "AL",
    nome_da_cidade: "JEQUIA DA PRAIA",
  },
  {
    codigo_do_IGBE: 2918001,
    UF: "BA",
    nome_da_cidade: "JEQUIE",
  },
  {
    codigo_do_IGBE: 3135605,
    UF: "MG",
    nome_da_cidade: "JEQUITAI",
  },
  {
    codigo_do_IGBE: 3135803,
    UF: "MG",
    nome_da_cidade: "JEQUITINHONHA",
  },
  {
    codigo_do_IGBE: 2918100,
    UF: "BA",
    nome_da_cidade: "JEREMOABO",
  },
  {
    codigo_do_IGBE: 2507408,
    UF: "PB",
    nome_da_cidade: "JERICO",
  },
  {
    codigo_do_IGBE: 3525409,
    UF: "SP",
    nome_da_cidade: "JERIQUARA",
  },
  {
    codigo_do_IGBE: 3203106,
    UF: "ES",
    nome_da_cidade: "JERONIMO MONTEIRO",
  },
  {
    codigo_do_IGBE: 2205300,
    UF: "PI",
    nome_da_cidade: "JERUMENHA",
  },
  {
    codigo_do_IGBE: 3135902,
    UF: "MG",
    nome_da_cidade: "JESUANIA",
  },
  {
    codigo_do_IGBE: 4112751,
    UF: "PR",
    nome_da_cidade: "JESUITAS",
  },
  {
    codigo_do_IGBE: 5212055,
    UF: "GO",
    nome_da_cidade: "JESUPOLIS",
  },
  {
    codigo_do_IGBE: 1100122,
    UF: "RO",
    nome_da_cidade: "JI-PARANA",
  },
  {
    codigo_do_IGBE: 2918209,
    UF: "BA",
    nome_da_cidade: "JIQUIRICA",
  },
  {
    codigo_do_IGBE: 2918308,
    UF: "BA",
    nome_da_cidade: "JITAUNA",
  },
  {
    codigo_do_IGBE: 4209003,
    UF: "SC",
    nome_da_cidade: "JOACABA",
  },
  {
    codigo_do_IGBE: 3136009,
    UF: "MG",
    nome_da_cidade: "JOAIMA",
  },
  {
    codigo_do_IGBE: 3136108,
    UF: "MG",
    nome_da_cidade: "JOANESIA",
  },
  {
    codigo_do_IGBE: 3525508,
    UF: "SP",
    nome_da_cidade: "JOANOPOLIS",
  },
  {
    codigo_do_IGBE: 2608107,
    UF: "PE",
    nome_da_cidade: "JOAO ALFREDO",
  },
  {
    codigo_do_IGBE: 2405801,
    UF: "RN",
    nome_da_cidade: "JOAO CAMARA",
  },
  {
    codigo_do_IGBE: 2205359,
    UF: "PI",
    nome_da_cidade: "JOAO COSTA",
  },
  {
    codigo_do_IGBE: 2405900,
    UF: "RN",
    nome_da_cidade: "JOAO DIAS",
  },
  {
    codigo_do_IGBE: 2918357,
    UF: "BA",
    nome_da_cidade: "JOAO DOURADO",
  },
  {
    codigo_do_IGBE: 2105500,
    UF: "MA",
    nome_da_cidade: "JOAO LISBOA",
  },
  {
    codigo_do_IGBE: 3136207,
    UF: "MG",
    nome_da_cidade: "JOAO MONLEVADE",
  },
  {
    codigo_do_IGBE: 3203130,
    UF: "ES",
    nome_da_cidade: "JOAO NEIVA",
  },
  {
    codigo_do_IGBE: 2507507,
    UF: "PB",
    nome_da_cidade: "JOAO PESSOA",
  },
  {
    codigo_do_IGBE: 3136306,
    UF: "MG",
    nome_da_cidade: "JOAO PINHEIRO",
  },
  {
    codigo_do_IGBE: 3525607,
    UF: "SP",
    nome_da_cidade: "JOAO RAMALHO",
  },
  {
    codigo_do_IGBE: 2703809,
    UF: "AL",
    nome_da_cidade: "JOAQUIM GOMES",
  },
  {
    codigo_do_IGBE: 2608206,
    UF: "PE",
    nome_da_cidade: "JOAQUIM NABUCO",
  },
  {
    codigo_do_IGBE: 2205409,
    UF: "PI",
    nome_da_cidade: "JOAQUIM PIRES",
  },
  {
    codigo_do_IGBE: 4112801,
    UF: "PR",
    nome_da_cidade: "JOAQUIM TAVORA",
  },
  {
    codigo_do_IGBE: 2205458,
    UF: "PI",
    nome_da_cidade: "JOCA MARQUES",
  },
  {
    codigo_do_IGBE: 4311155,
    UF: "RS",
    nome_da_cidade: "JOIA",
  },
  {
    codigo_do_IGBE: 4209102,
    UF: "SC",
    nome_da_cidade: "JOINVILLE",
  },
  {
    codigo_do_IGBE: 3136504,
    UF: "MG",
    nome_da_cidade: "JORDANIA",
  },
  {
    codigo_do_IGBE: 1200328,
    UF: "AC",
    nome_da_cidade: "JORDAO",
  },
  {
    codigo_do_IGBE: 4209151,
    UF: "SC",
    nome_da_cidade: "JOSE BOITEUX",
  },
  {
    codigo_do_IGBE: 3525706,
    UF: "SP",
    nome_da_cidade: "JOSE BONIFACIO",
  },
  {
    codigo_do_IGBE: 2406007,
    UF: "RN",
    nome_da_cidade: "JOSE DA PENHA",
  },
  {
    codigo_do_IGBE: 2205508,
    UF: "PI",
    nome_da_cidade: "JOSE DE FREITAS",
  },
  {
    codigo_do_IGBE: 3136553,
    UF: "MG",
    nome_da_cidade: "JOSE RAYDAN",
  },
  {
    codigo_do_IGBE: 2105609,
    UF: "MA",
    nome_da_cidade: "JOSELANDIA",
  },
  {
    codigo_do_IGBE: 3136579,
    UF: "MG",
    nome_da_cidade: "JOSENOPOLIS",
  },
  {
    codigo_do_IGBE: 5212105,
    UF: "GO",
    nome_da_cidade: "JOVIANIA",
  },
  {
    codigo_do_IGBE: 5105101,
    UF: "MT",
    nome_da_cidade: "JUARA",
  },
  {
    codigo_do_IGBE: 2507606,
    UF: "PB",
    nome_da_cidade: "JUAREZ TAVORA",
  },
  {
    codigo_do_IGBE: 1711803,
    UF: "TO",
    nome_da_cidade: "JUARINA",
  },
  {
    codigo_do_IGBE: 2507705,
    UF: "PB",
    nome_da_cidade: "JUAZEIRINHO",
  },
  {
    codigo_do_IGBE: 2918407,
    UF: "BA",
    nome_da_cidade: "JUAZEIRO",
  },
  {
    codigo_do_IGBE: 2205516,
    UF: "PI",
    nome_da_cidade: "JUAZEIRO DO PIAUI",
  },
  {
    codigo_do_IGBE: 2307403,
    UF: "CE",
    nome_da_cidade: "JUCAS",
  },
  {
    codigo_do_IGBE: 2608255,
    UF: "PE",
    nome_da_cidade: "JUCATI",
  },
  {
    codigo_do_IGBE: 2918456,
    UF: "BA",
    nome_da_cidade: "JUCURUCU",
  },
  {
    codigo_do_IGBE: 2406106,
    UF: "RN",
    nome_da_cidade: "JUCURUTU",
  },
  {
    codigo_do_IGBE: 5105150,
    UF: "MT",
    nome_da_cidade: "JUINA",
  },
  {
    codigo_do_IGBE: 3136702,
    UF: "MG",
    nome_da_cidade: "JUIZ DE FORA",
  },
  {
    codigo_do_IGBE: 4311205,
    UF: "RS",
    nome_da_cidade: "JULIO DE CASTILHOS",
  },
  {
    codigo_do_IGBE: 3525805,
    UF: "SP",
    nome_da_cidade: "JULIO MESQUITA",
  },
  {
    codigo_do_IGBE: 3525854,
    UF: "SP",
    nome_da_cidade: "JUMIRIM",
  },
  {
    codigo_do_IGBE: 2105658,
    UF: "MA",
    nome_da_cidade: "JUNCO DO MARANHAO",
  },
  {
    codigo_do_IGBE: 2507804,
    UF: "PB",
    nome_da_cidade: "JUNCO DO SERIDO",
  },
  {
    codigo_do_IGBE: 2703908,
    UF: "AL",
    nome_da_cidade: "JUNDIA",
  },
  {
    codigo_do_IGBE: 2406155,
    UF: "RN",
    nome_da_cidade: "JUNDIA",
  },
  {
    codigo_do_IGBE: 3525904,
    UF: "SP",
    nome_da_cidade: "JUNDIAI",
  },
  {
    codigo_do_IGBE: 2704005,
    UF: "AL",
    nome_da_cidade: "JUNQUEIRO",
  },
  {
    codigo_do_IGBE: 2608305,
    UF: "PE",
    nome_da_cidade: "JUPI",
  },
  {
    codigo_do_IGBE: 4209177,
    UF: "SC",
    nome_da_cidade: "JUPIA",
  },
  {
    codigo_do_IGBE: 3526100,
    UF: "SP",
    nome_da_cidade: "JUQUIA",
  },
  {
    codigo_do_IGBE: 3526209,
    UF: "SP",
    nome_da_cidade: "JUQUITIBA",
  },
  {
    codigo_do_IGBE: 3136801,
    UF: "MG",
    nome_da_cidade: "JURAMENTO",
  },
  {
    codigo_do_IGBE: 4112959,
    UF: "PR",
    nome_da_cidade: "JURANDA",
  },
  {
    codigo_do_IGBE: 2608404,
    UF: "PE",
    nome_da_cidade: "JUREMA",
  },
  {
    codigo_do_IGBE: 2205532,
    UF: "PI",
    nome_da_cidade: "JUREMA",
  },
  {
    codigo_do_IGBE: 2507903,
    UF: "PB",
    nome_da_cidade: "JURIPIRANGA",
  },
  {
    codigo_do_IGBE: 2508000,
    UF: "PB",
    nome_da_cidade: "JURU",
  },
  {
    codigo_do_IGBE: 1302207,
    UF: "AM",
    nome_da_cidade: "JURUA",
  },
  {
    codigo_do_IGBE: 3136900,
    UF: "MG",
    nome_da_cidade: "JURUAIA",
  },
  {
    codigo_do_IGBE: 5105176,
    UF: "MT",
    nome_da_cidade: "JURUENA",
  },
  {
    codigo_do_IGBE: 5105200,
    UF: "MT",
    nome_da_cidade: "JUSCIMEIRA",
  },
  {
    codigo_do_IGBE: 2918506,
    UF: "BA",
    nome_da_cidade: "JUSSARA",
  },
  {
    codigo_do_IGBE: 4113007,
    UF: "PR",
    nome_da_cidade: "JUSSARA",
  },
  {
    codigo_do_IGBE: 2918555,
    UF: "BA",
    nome_da_cidade: "JUSSARI",
  },
  {
    codigo_do_IGBE: 2918605,
    UF: "BA",
    nome_da_cidade: "JUSSIAPE",
  },
  {
    codigo_do_IGBE: 1302306,
    UF: "AM",
    nome_da_cidade: "JUTAI",
  },
  {
    codigo_do_IGBE: 5005152,
    UF: "MS",
    nome_da_cidade: "JUTI",
  },
  {
    codigo_do_IGBE: 3136959,
    UF: "MG",
    nome_da_cidade: "JUVENILIA",
  },
  {
    codigo_do_IGBE: 4113106,
    UF: "PR",
    nome_da_cidade: "KALORE",
  },
  {
    codigo_do_IGBE: 1302405,
    UF: "AM",
    nome_da_cidade: "LABREA",
  },
  {
    codigo_do_IGBE: 4209201,
    UF: "SC",
    nome_da_cidade: "LACERDOPOLIS",
  },
  {
    codigo_do_IGBE: 3137007,
    UF: "MG",
    nome_da_cidade: "LADAINHA",
  },
  {
    codigo_do_IGBE: 3137106,
    UF: "MG",
    nome_da_cidade: "LAGAMAR",
  },
  {
    codigo_do_IGBE: 2803500,
    UF: "SE",
    nome_da_cidade: "LAGARTO",
  },
  {
    codigo_do_IGBE: 4209300,
    UF: "SC",
    nome_da_cidade: "LAGES",
  },
  {
    codigo_do_IGBE: 2105708,
    UF: "MA",
    nome_da_cidade: "LAGO DA PEDRA",
  },
  {
    codigo_do_IGBE: 2105807,
    UF: "MA",
    nome_da_cidade: "LAGO DO JUNCO",
  },
  {
    codigo_do_IGBE: 2105948,
    UF: "MA",
    nome_da_cidade: "LAGO DOS RODRIGUES",
  },
  {
    codigo_do_IGBE: 2105906,
    UF: "MA",
    nome_da_cidade: "LAGO VERDE",
  },
  {
    codigo_do_IGBE: 2508109,
    UF: "PB",
    nome_da_cidade: "LAGOA",
  },
  {
    codigo_do_IGBE: 2205557,
    UF: "PI",
    nome_da_cidade: "LAGOA ALEGRE",
  },
  {
    codigo_do_IGBE: 4311239,
    UF: "RS",
    nome_da_cidade: "LAGOA BONITA DO SUL",
  },
  {
    codigo_do_IGBE: 1711902,
    UF: "TO",
    nome_da_cidade: "LAGOA DA CONFUSAO",
  },
  {
    codigo_do_IGBE: 3137205,
    UF: "MG",
    nome_da_cidade: "LAGOA DA PRATA",
  },
  {
    codigo_do_IGBE: 2406205,
    UF: "RN",
    nome_da_cidade: "LAGOA D'ANTA",
  },
  {
    codigo_do_IGBE: 2406304,
    UF: "RN",
    nome_da_cidade: "LAGOA DE PEDRAS",
  },
  {
    codigo_do_IGBE: 2406403,
    UF: "RN",
    nome_da_cidade: "LAGOA DE VELHOS",
  },
  {
    codigo_do_IGBE: 2205565,
    UF: "PI",
    nome_da_cidade: "LAGOA DO BARRO DO PIAUI",
  },
  {
    codigo_do_IGBE: 2608453,
    UF: "PE",
    nome_da_cidade: "LAGOA DO CARRO",
  },
  {
    codigo_do_IGBE: 2608503,
    UF: "PE",
    nome_da_cidade: "LAGOA DO ITAENGA",
  },
  {
    codigo_do_IGBE: 2105922,
    UF: "MA",
    nome_da_cidade: "LAGOA DO MATO",
  },
  {
    codigo_do_IGBE: 2205581,
    UF: "PI",
    nome_da_cidade: "LAGOA DO PIAUI",
  },
  {
    codigo_do_IGBE: 2205599,
    UF: "PI",
    nome_da_cidade: "LAGOA DO SITIO",
  },
  {
    codigo_do_IGBE: 1711951,
    UF: "TO",
    nome_da_cidade: "LAGOA DO TOCANTINS",
  },
  {
    codigo_do_IGBE: 2608701,
    UF: "PE",
    nome_da_cidade: "LAGOA DOS GATOS",
  },
  {
    codigo_do_IGBE: 3137304,
    UF: "MG",
    nome_da_cidade: "LAGOA DOS PATOS",
  },
  {
    codigo_do_IGBE: 3137403,
    UF: "MG",
    nome_da_cidade: "LAGOA DOURADA",
  },
  {
    codigo_do_IGBE: 3137502,
    UF: "MG",
    nome_da_cidade: "LAGOA FORMOSA",
  },
  {
    codigo_do_IGBE: 3137536,
    UF: "MG",
    nome_da_cidade: "LAGOA GRANDE",
  },
  {
    codigo_do_IGBE: 2608750,
    UF: "PE",
    nome_da_cidade: "LAGOA GRANDE",
  },
  {
    codigo_do_IGBE: 2105963,
    UF: "MA",
    nome_da_cidade: "LAGOA GRANDE DO MARANHAO",
  },
  {
    codigo_do_IGBE: 2406502,
    UF: "RN",
    nome_da_cidade: "LAGOA NOVA",
  },
  {
    codigo_do_IGBE: 2918753,
    UF: "BA",
    nome_da_cidade: "LAGOA REAL",
  },
  {
    codigo_do_IGBE: 2406601,
    UF: "RN",
    nome_da_cidade: "LAGOA SALGADA",
  },
  {
    codigo_do_IGBE: 2508307,
    UF: "PB",
    nome_da_cidade: "LAGOA SECA",
  },
  {
    codigo_do_IGBE: 4311254,
    UF: "RS",
    nome_da_cidade: "LAGOAO",
  },
  {
    codigo_do_IGBE: 3526308,
    UF: "SP",
    nome_da_cidade: "LAGOINHA",
  },
  {
    codigo_do_IGBE: 2205540,
    UF: "PI",
    nome_da_cidade: "LAGOINHA DO PIAUI",
  },
  {
    codigo_do_IGBE: 4209409,
    UF: "SC",
    nome_da_cidade: "LAGUNA",
  },
  {
    codigo_do_IGBE: 5005251,
    UF: "MS",
    nome_da_cidade: "LAGUNA CARAPA",
  },
  {
    codigo_do_IGBE: 2918803,
    UF: "BA",
    nome_da_cidade: "LAJE",
  },
  {
    codigo_do_IGBE: 3302304,
    UF: "RJ",
    nome_da_cidade: "LAJE DO MURIAE",
  },
  {
    codigo_do_IGBE: 4311403,
    UF: "RS",
    nome_da_cidade: "LAJEADO",
  },
  {
    codigo_do_IGBE: 1712009,
    UF: "TO",
    nome_da_cidade: "LAJEADO",
  },
  {
    codigo_do_IGBE: 4209458,
    UF: "SC",
    nome_da_cidade: "LAJEADO GRANDE",
  },
  {
    codigo_do_IGBE: 2105989,
    UF: "MA",
    nome_da_cidade: "LAJEADO NOVO",
  },
  {
    codigo_do_IGBE: 2918902,
    UF: "BA",
    nome_da_cidade: "LAJEDAO",
  },
  {
    codigo_do_IGBE: 2919009,
    UF: "BA",
    nome_da_cidade: "LAJEDINHO",
  },
  {
    codigo_do_IGBE: 2919058,
    UF: "BA",
    nome_da_cidade: "LAJEDO DO TABOCAL",
  },
  {
    codigo_do_IGBE: 2406700,
    UF: "RN",
    nome_da_cidade: "LAJES",
  },
  {
    codigo_do_IGBE: 2406809,
    UF: "RN",
    nome_da_cidade: "LAJES PINTADAS",
  },
  {
    codigo_do_IGBE: 3137700,
    UF: "MG",
    nome_da_cidade: "LAJINHA",
  },
  {
    codigo_do_IGBE: 2919108,
    UF: "BA",
    nome_da_cidade: "LAMARAO",
  },
  {
    codigo_do_IGBE: 3137809,
    UF: "MG",
    nome_da_cidade: "LAMBARI",
  },
  {
    codigo_do_IGBE: 5105234,
    UF: "MT",
    nome_da_cidade: "LAMBARI D'OESTE",
  },
  {
    codigo_do_IGBE: 3137908,
    UF: "MG",
    nome_da_cidade: "LAMIM",
  },
  {
    codigo_do_IGBE: 2205607,
    UF: "PI",
    nome_da_cidade: "LANDRI SALES",
  },
  {
    codigo_do_IGBE: 4113205,
    UF: "PR",
    nome_da_cidade: "LAPA",
  },
  {
    codigo_do_IGBE: 3138005,
    UF: "MG",
    nome_da_cidade: "LARANJAL",
  },
  {
    codigo_do_IGBE: 4113254,
    UF: "PR",
    nome_da_cidade: "LARANJAL",
  },
  {
    codigo_do_IGBE: 1600279,
    UF: "AP",
    nome_da_cidade: "LARANJAL DO JARI",
  },
  {
    codigo_do_IGBE: 3526407,
    UF: "SP",
    nome_da_cidade: "LARANJAL PAULISTA",
  },
  {
    codigo_do_IGBE: 2803609,
    UF: "SE",
    nome_da_cidade: "LARANJEIRAS",
  },
  {
    codigo_do_IGBE: 4113304,
    UF: "PR",
    nome_da_cidade: "LARANJEIRAS DO SUL",
  },
  {
    codigo_do_IGBE: 3138104,
    UF: "MG",
    nome_da_cidade: "LASSANCE",
  },
  {
    codigo_do_IGBE: 2508406,
    UF: "PB",
    nome_da_cidade: "LASTRO",
  },
  {
    codigo_do_IGBE: 4209508,
    UF: "SC",
    nome_da_cidade: "LAURENTINO",
  },
  {
    codigo_do_IGBE: 2919207,
    UF: "BA",
    nome_da_cidade: "LAURO DE FREITAS",
  },
  {
    codigo_do_IGBE: 4209607,
    UF: "SC",
    nome_da_cidade: "LAURO MULLER",
  },
  {
    codigo_do_IGBE: 1712157,
    UF: "TO",
    nome_da_cidade: "LAVANDEIRA",
  },
  {
    codigo_do_IGBE: 3138203,
    UF: "MG",
    nome_da_cidade: "LAVRAS",
  },
  {
    codigo_do_IGBE: 4311502,
    UF: "RS",
    nome_da_cidade: "LAVRAS DO SUL",
  },
  {
    codigo_do_IGBE: 3526605,
    UF: "SP",
    nome_da_cidade: "LAVRINHAS",
  },
  {
    codigo_do_IGBE: 3138302,
    UF: "MG",
    nome_da_cidade: "LEANDRO FERREIRA",
  },
  {
    codigo_do_IGBE: 4209706,
    UF: "SC",
    nome_da_cidade: "LEBON REGIS",
  },
  {
    codigo_do_IGBE: 3526704,
    UF: "SP",
    nome_da_cidade: "LEME",
  },
  {
    codigo_do_IGBE: 3138351,
    UF: "MG",
    nome_da_cidade: "LEME DO PRADO",
  },
  {
    codigo_do_IGBE: 2919306,
    UF: "BA",
    nome_da_cidade: "LENCOIS",
  },
  {
    codigo_do_IGBE: 3526803,
    UF: "SP",
    nome_da_cidade: "LENCOIS PAULISTA",
  },
  {
    codigo_do_IGBE: 4209805,
    UF: "SC",
    nome_da_cidade: "LEOBERTO LEAL",
  },
  {
    codigo_do_IGBE: 5212303,
    UF: "GO",
    nome_da_cidade: "LEOPOLDO DE BULHOES",
  },
  {
    codigo_do_IGBE: 4113403,
    UF: "PR",
    nome_da_cidade: "LEOPOLIS",
  },
  {
    codigo_do_IGBE: 4311601,
    UF: "RS",
    nome_da_cidade: "LIBERATO SALZANO",
  },
  {
    codigo_do_IGBE: 3138500,
    UF: "MG",
    nome_da_cidade: "LIBERDADE",
  },
  {
    codigo_do_IGBE: 4113429,
    UF: "PR",
    nome_da_cidade: "LIDIANOPOLIS",
  },
  {
    codigo_do_IGBE: 2106003,
    UF: "MA",
    nome_da_cidade: "LIMA CAMPOS",
  },
  {
    codigo_do_IGBE: 3138609,
    UF: "MG",
    nome_da_cidade: "LIMA DUARTE",
  },
  {
    codigo_do_IGBE: 3526902,
    UF: "SP",
    nome_da_cidade: "LIMEIRA",
  },
  {
    codigo_do_IGBE: 3138625,
    UF: "MG",
    nome_da_cidade: "LIMEIRA DO OESTE",
  },
  {
    codigo_do_IGBE: 2608909,
    UF: "PE",
    nome_da_cidade: "LIMOEIRO",
  },
  {
    codigo_do_IGBE: 2704203,
    UF: "AL",
    nome_da_cidade: "LIMOEIRO DE ANADIA",
  },
  {
    codigo_do_IGBE: 1504000,
    UF: "PA",
    nome_da_cidade: "LIMOEIRO DO AJURU",
  },
  {
    codigo_do_IGBE: 4113452,
    UF: "PR",
    nome_da_cidade: "LINDOESTE",
  },
  {
    codigo_do_IGBE: 3527009,
    UF: "SP",
    nome_da_cidade: "LINDOIA",
  },
  {
    codigo_do_IGBE: 4209854,
    UF: "SC",
    nome_da_cidade: "LINDOIA DO SUL",
  },
  {
    codigo_do_IGBE: 3137601,
    UF: "MG",
    nome_da_cidade: "LAGOA SANTA",
  },
  {
    codigo_do_IGBE: 4311643,
    UF: "RS",
    nome_da_cidade: "LINHA NOVA",
  },
  {
    codigo_do_IGBE: 3203205,
    UF: "ES",
    nome_da_cidade: "LINHARES",
  },
  {
    codigo_do_IGBE: 3527108,
    UF: "SP",
    nome_da_cidade: "LINS",
  },
  {
    codigo_do_IGBE: 1712405,
    UF: "TO",
    nome_da_cidade: "LIZARDA",
  },
  {
    codigo_do_IGBE: 4113502,
    UF: "PR",
    nome_da_cidade: "LOANDA",
  },
  {
    codigo_do_IGBE: 4113601,
    UF: "PR",
    nome_da_cidade: "LOBATO",
  },
  {
    codigo_do_IGBE: 2508554,
    UF: "PB",
    nome_da_cidade: "LOGRADOURO",
  },
  {
    codigo_do_IGBE: 4113700,
    UF: "PR",
    nome_da_cidade: "LONDRINA",
  },
  {
    codigo_do_IGBE: 3138658,
    UF: "MG",
    nome_da_cidade: "LONTRA",
  },
  {
    codigo_do_IGBE: 4209904,
    UF: "SC",
    nome_da_cidade: "LONTRAS",
  },
  {
    codigo_do_IGBE: 3527207,
    UF: "SP",
    nome_da_cidade: "LORENA",
  },
  {
    codigo_do_IGBE: 2106102,
    UF: "MA",
    nome_da_cidade: "LORETO",
  },
  {
    codigo_do_IGBE: 3527256,
    UF: "SP",
    nome_da_cidade: "LOURDES",
  },
  {
    codigo_do_IGBE: 5105259,
    UF: "MT",
    nome_da_cidade: "LUCAS DO RIO VERDE",
  },
  {
    codigo_do_IGBE: 3527405,
    UF: "SP",
    nome_da_cidade: "LUCELIA",
  },
  {
    codigo_do_IGBE: 2508604,
    UF: "PB",
    nome_da_cidade: "LUCENA",
  },
  {
    codigo_do_IGBE: 3527504,
    UF: "SP",
    nome_da_cidade: "LUCIANOPOLIS",
  },
  {
    codigo_do_IGBE: 5105309,
    UF: "MT",
    nome_da_cidade: "LUCIARA",
  },
  {
    codigo_do_IGBE: 2406908,
    UF: "RN",
    nome_da_cidade: "LUCRECIA",
  },
  {
    codigo_do_IGBE: 3527603,
    UF: "SP",
    nome_da_cidade: "LUIS ANTONIO",
  },
  {
    codigo_do_IGBE: 2205706,
    UF: "PI",
    nome_da_cidade: "LUIS CORREIA",
  },
  {
    codigo_do_IGBE: 2407005,
    UF: "RN",
    nome_da_cidade: "LUIS GOMES",
  },
  {
    codigo_do_IGBE: 3138674,
    UF: "MG",
    nome_da_cidade: "LUISBURGO",
  },
  {
    codigo_do_IGBE: 3138682,
    UF: "MG",
    nome_da_cidade: "LUISLANDIA",
  },
  {
    codigo_do_IGBE: 4210001,
    UF: "SC",
    nome_da_cidade: "LUIZ ALVES",
  },
  {
    codigo_do_IGBE: 4113734,
    UF: "PR",
    nome_da_cidade: "LUIZIANA",
  },
  {
    codigo_do_IGBE: 3527702,
    UF: "SP",
    nome_da_cidade: "LUIZIANIA",
  },
  {
    codigo_do_IGBE: 3138708,
    UF: "MG",
    nome_da_cidade: "LUMINARIAS",
  },
  {
    codigo_do_IGBE: 4113759,
    UF: "PR",
    nome_da_cidade: "LUNARDELLI",
  },
  {
    codigo_do_IGBE: 3527801,
    UF: "SP",
    nome_da_cidade: "LUPERCIO",
  },
  {
    codigo_do_IGBE: 4113809,
    UF: "PR",
    nome_da_cidade: "LUPIONOPOLIS",
  },
  {
    codigo_do_IGBE: 3527900,
    UF: "SP",
    nome_da_cidade: "LUTECIA",
  },
  {
    codigo_do_IGBE: 3138807,
    UF: "MG",
    nome_da_cidade: "LUZ",
  },
  {
    codigo_do_IGBE: 4210035,
    UF: "SC",
    nome_da_cidade: "LUZERNA",
  },
  {
    codigo_do_IGBE: 5212501,
    UF: "GO",
    nome_da_cidade: "LUZIANIA",
  },
  {
    codigo_do_IGBE: 2205805,
    UF: "PI",
    nome_da_cidade: "LUZILANDIA",
  },
  {
    codigo_do_IGBE: 1712454,
    UF: "TO",
    nome_da_cidade: "LUZINOPOLIS",
  },
  {
    codigo_do_IGBE: 2407104,
    UF: "RN",
    nome_da_cidade: "MACAIBA",
  },
  {
    codigo_do_IGBE: 2919603,
    UF: "BA",
    nome_da_cidade: "MACAJUBA",
  },
  {
    codigo_do_IGBE: 2803708,
    UF: "SE",
    nome_da_cidade: "MACAMBIRA",
  },
  {
    codigo_do_IGBE: 1600303,
    UF: "AP",
    nome_da_cidade: "MACAPA",
  },
  {
    codigo_do_IGBE: 2609006,
    UF: "PE",
    nome_da_cidade: "MACAPARANA",
  },
  {
    codigo_do_IGBE: 2919702,
    UF: "BA",
    nome_da_cidade: "MACARANI",
  },
  {
    codigo_do_IGBE: 3528007,
    UF: "SP",
    nome_da_cidade: "MACATUBA",
  },
  {
    codigo_do_IGBE: 2407203,
    UF: "RN",
    nome_da_cidade: "MACAU",
  },
  {
    codigo_do_IGBE: 3528106,
    UF: "SP",
    nome_da_cidade: "MACAUBAL",
  },
  {
    codigo_do_IGBE: 2919801,
    UF: "BA",
    nome_da_cidade: "MACAUBAS",
  },
  {
    codigo_do_IGBE: 3528205,
    UF: "SP",
    nome_da_cidade: "MACEDONIA",
  },
  {
    codigo_do_IGBE: 3138906,
    UF: "MG",
    nome_da_cidade: "MACHACALIS",
  },
  {
    codigo_do_IGBE: 1100130,
    UF: "RO",
    nome_da_cidade: "MACHADINHO D'OESTE",
  },
  {
    codigo_do_IGBE: 3139003,
    UF: "MG",
    nome_da_cidade: "MACHADO",
  },
  {
    codigo_do_IGBE: 2609105,
    UF: "PE",
    nome_da_cidade: "MACHADOS",
  },
  {
    codigo_do_IGBE: 4210050,
    UF: "SC",
    nome_da_cidade: "MACIEIRA",
  },
  {
    codigo_do_IGBE: 3302452,
    UF: "RJ",
    nome_da_cidade: "MACUCO",
  },
  {
    codigo_do_IGBE: 2919900,
    UF: "BA",
    nome_da_cidade: "MACURURE",
  },
  {
    codigo_do_IGBE: 2307635,
    UF: "CE",
    nome_da_cidade: "MADALENA",
  },
  {
    codigo_do_IGBE: 2205854,
    UF: "PI",
    nome_da_cidade: "MADEIRO",
  },
  {
    codigo_do_IGBE: 2508703,
    UF: "PB",
    nome_da_cidade: "MAE D'AGUA",
  },
  {
    codigo_do_IGBE: 1504059,
    UF: "PA",
    nome_da_cidade: "MAE DO RIO",
  },
  {
    codigo_do_IGBE: 2919959,
    UF: "BA",
    nome_da_cidade: "MAETINGA",
  },
  {
    codigo_do_IGBE: 4210100,
    UF: "SC",
    nome_da_cidade: "MAFRA",
  },
  {
    codigo_do_IGBE: 1504109,
    UF: "PA",
    nome_da_cidade: "MAGALHAES BARATA",
  },
  {
    codigo_do_IGBE: 2106300,
    UF: "MA",
    nome_da_cidade: "MAGALHAES DE ALMEIDA",
  },
  {
    codigo_do_IGBE: 3528304,
    UF: "SP",
    nome_da_cidade: "MAGDA",
  },
  {
    codigo_do_IGBE: 3302502,
    UF: "RJ",
    nome_da_cidade: "MAGE",
  },
  {
    codigo_do_IGBE: 2920007,
    UF: "BA",
    nome_da_cidade: "MAIQUINIQUE",
  },
  {
    codigo_do_IGBE: 2920106,
    UF: "BA",
    nome_da_cidade: "MAIRI",
  },
  {
    codigo_do_IGBE: 3528403,
    UF: "SP",
    nome_da_cidade: "MAIRINQUE",
  },
  {
    codigo_do_IGBE: 3528502,
    UF: "SP",
    nome_da_cidade: "MAIRIPORA",
  },
  {
    codigo_do_IGBE: 5212600,
    UF: "GO",
    nome_da_cidade: "MAIRIPOTABA",
  },
  {
    codigo_do_IGBE: 2704401,
    UF: "AL",
    nome_da_cidade: "MAJOR ISIDORO",
  },
  {
    codigo_do_IGBE: 2407252,
    UF: "RN",
    nome_da_cidade: "MAJOR SALES",
  },
  {
    codigo_do_IGBE: 4210308,
    UF: "SC",
    nome_da_cidade: "MAJOR VIEIRA",
  },
  {
    codigo_do_IGBE: 3139201,
    UF: "MG",
    nome_da_cidade: "MALACACHETA",
  },
  {
    codigo_do_IGBE: 2920205,
    UF: "BA",
    nome_da_cidade: "MALHADA",
  },
  {
    codigo_do_IGBE: 2803807,
    UF: "SE",
    nome_da_cidade: "MALHADA DOS BOIS",
  },
  {
    codigo_do_IGBE: 2803906,
    UF: "SE",
    nome_da_cidade: "MALHADOR",
  },
  {
    codigo_do_IGBE: 4113908,
    UF: "PR",
    nome_da_cidade: "MALLET",
  },
  {
    codigo_do_IGBE: 2508802,
    UF: "PB",
    nome_da_cidade: "MALTA",
  },
  {
    codigo_do_IGBE: 2508901,
    UF: "PB",
    nome_da_cidade: "MAMANGUAPE",
  },
  {
    codigo_do_IGBE: 5212709,
    UF: "GO",
    nome_da_cidade: "MAMBAI",
  },
  {
    codigo_do_IGBE: 4114005,
    UF: "PR",
    nome_da_cidade: "MAMBORE",
  },
  {
    codigo_do_IGBE: 3139250,
    UF: "MG",
    nome_da_cidade: "MAMONAS",
  },
  {
    codigo_do_IGBE: 4311734,
    UF: "RS",
    nome_da_cidade: "MAMPITUBA",
  },
  {
    codigo_do_IGBE: 2509008,
    UF: "PB",
    nome_da_cidade: "MANAIRA",
  },
  {
    codigo_do_IGBE: 1302553,
    UF: "AM",
    nome_da_cidade: "MANAQUIRI",
  },
  {
    codigo_do_IGBE: 2609154,
    UF: "PE",
    nome_da_cidade: "MANARI",
  },
  {
    codigo_do_IGBE: 1302603,
    UF: "AM",
    nome_da_cidade: "MANAUS",
  },
  {
    codigo_do_IGBE: 1200336,
    UF: "AC",
    nome_da_cidade: "MANCIO LIMA",
  },
  {
    codigo_do_IGBE: 4114104,
    UF: "PR",
    nome_da_cidade: "MANDAGUACU",
  },
  {
    codigo_do_IGBE: 4114203,
    UF: "PR",
    nome_da_cidade: "MANDAGUARI",
  },
  {
    codigo_do_IGBE: 3528601,
    UF: "SP",
    nome_da_cidade: "MANDURI",
  },
  {
    codigo_do_IGBE: 4114351,
    UF: "PR",
    nome_da_cidade: "MANFRINOPOLIS",
  },
  {
    codigo_do_IGBE: 3139300,
    UF: "MG",
    nome_da_cidade: "MANGA",
  },
  {
    codigo_do_IGBE: 3302601,
    UF: "RJ",
    nome_da_cidade: "MANGARATIBA",
  },
  {
    codigo_do_IGBE: 3139409,
    UF: "MG",
    nome_da_cidade: "MANHUACU",
  },
  {
    codigo_do_IGBE: 3139508,
    UF: "MG",
    nome_da_cidade: "MANHUMIRIM",
  },
  {
    codigo_do_IGBE: 1302702,
    UF: "AM",
    nome_da_cidade: "MANICORE",
  },
  {
    codigo_do_IGBE: 2205904,
    UF: "PI",
    nome_da_cidade: "MANOEL EMIDIO",
  },
  {
    codigo_do_IGBE: 4114500,
    UF: "PR",
    nome_da_cidade: "MANOEL RIBAS",
  },
  {
    codigo_do_IGBE: 1200344,
    UF: "AC",
    nome_da_cidade: "MANOEL URBANO",
  },
  {
    codigo_do_IGBE: 4311759,
    UF: "RS",
    nome_da_cidade: "MANOEL VIANA",
  },
  {
    codigo_do_IGBE: 2920452,
    UF: "BA",
    nome_da_cidade: "MANSIDAO",
  },
  {
    codigo_do_IGBE: 3139607,
    UF: "MG",
    nome_da_cidade: "MANTENA",
  },
  {
    codigo_do_IGBE: 3203304,
    UF: "ES",
    nome_da_cidade: "MANTENOPOLIS",
  },
  {
    codigo_do_IGBE: 4311775,
    UF: "RS",
    nome_da_cidade: "MAQUINE",
  },
  {
    codigo_do_IGBE: 2704906,
    UF: "AL",
    nome_da_cidade: "MAR VERMELHO",
  },
  {
    codigo_do_IGBE: 5212808,
    UF: "GO",
    nome_da_cidade: "MARA ROSA",
  },
  {
    codigo_do_IGBE: 1302801,
    UF: "AM",
    nome_da_cidade: "MARAA",
  },
  {
    codigo_do_IGBE: 1504208,
    UF: "PA",
    nome_da_cidade: "MARABA",
  },
  {
    codigo_do_IGBE: 3528700,
    UF: "SP",
    nome_da_cidade: "MARABA PAULISTA",
  },
  {
    codigo_do_IGBE: 2106326,
    UF: "MA",
    nome_da_cidade: "MARACACUME",
  },
  {
    codigo_do_IGBE: 3528809,
    UF: "SP",
    nome_da_cidade: "MARACAI",
  },
  {
    codigo_do_IGBE: 4210407,
    UF: "SC",
    nome_da_cidade: "MARACAJA",
  },
  {
    codigo_do_IGBE: 5005400,
    UF: "MS",
    nome_da_cidade: "MARACAJU",
  },
  {
    codigo_do_IGBE: 1504307,
    UF: "PA",
    nome_da_cidade: "MARACANA",
  },
  {
    codigo_do_IGBE: 2307650,
    UF: "CE",
    nome_da_cidade: "MARACANAU",
  },
  {
    codigo_do_IGBE: 2704500,
    UF: "AL",
    nome_da_cidade: "MARAGOGI",
  },
  {
    codigo_do_IGBE: 2920601,
    UF: "BA",
    nome_da_cidade: "MARAGOGIPE",
  },
  {
    codigo_do_IGBE: 2106359,
    UF: "MA",
    nome_da_cidade: "MARAJA DO SENA",
  },
  {
    codigo_do_IGBE: 2307700,
    UF: "CE",
    nome_da_cidade: "MARANGUAPE",
  },
  {
    codigo_do_IGBE: 2106375,
    UF: "MA",
    nome_da_cidade: "MARANHAOZINHO",
  },
  {
    codigo_do_IGBE: 1504406,
    UF: "PA",
    nome_da_cidade: "MARAPANIM",
  },
  {
    codigo_do_IGBE: 3528858,
    UF: "SP",
    nome_da_cidade: "MARAPOAMA",
  },
  {
    codigo_do_IGBE: 4311791,
    UF: "RS",
    nome_da_cidade: "MARATA",
  },
  {
    codigo_do_IGBE: 3203320,
    UF: "ES",
    nome_da_cidade: "MARATAIZES",
  },
  {
    codigo_do_IGBE: 2920700,
    UF: "BA",
    nome_da_cidade: "MARAU",
  },
  {
    codigo_do_IGBE: 4311809,
    UF: "RS",
    nome_da_cidade: "MARAU",
  },
  {
    codigo_do_IGBE: 2704609,
    UF: "AL",
    nome_da_cidade: "MARAVILHA",
  },
  {
    codigo_do_IGBE: 3139706,
    UF: "MG",
    nome_da_cidade: "MARAVILHAS",
  },
  {
    codigo_do_IGBE: 5105580,
    UF: "MT",
    nome_da_cidade: "MARCELANDIA",
  },
  {
    codigo_do_IGBE: 4311908,
    UF: "RS",
    nome_da_cidade: "MARCELINO RAMOS",
  },
  {
    codigo_do_IGBE: 2407302,
    UF: "RN",
    nome_da_cidade: "MARCELINO VIEIRA",
  },
  {
    codigo_do_IGBE: 2920809,
    UF: "BA",
    nome_da_cidade: "MARCIONILIO SOUZA",
  },
  {
    codigo_do_IGBE: 2307809,
    UF: "CE",
    nome_da_cidade: "MARCO",
  },
  {
    codigo_do_IGBE: 2205953,
    UF: "PI",
    nome_da_cidade: "MARCOLANDIA",
  },
  {
    codigo_do_IGBE: 2206001,
    UF: "PI",
    nome_da_cidade: "MARCOS PARENTE",
  },
  {
    codigo_do_IGBE: 2704708,
    UF: "AL",
    nome_da_cidade: "MARECHAL DEODORO",
  },
  {
    codigo_do_IGBE: 4114708,
    UF: "PR",
    nome_da_cidade: "MARIA HELENA",
  },
  {
    codigo_do_IGBE: 4114807,
    UF: "PR",
    nome_da_cidade: "MARIALVA",
  },
  {
    codigo_do_IGBE: 3140001,
    UF: "MG",
    nome_da_cidade: "MARIANA",
  },
  {
    codigo_do_IGBE: 4311981,
    UF: "RS",
    nome_da_cidade: "MARIANA PIMENTEL",
  },
  {
    codigo_do_IGBE: 4312005,
    UF: "RS",
    nome_da_cidade: "MARIANO MORO",
  },
  {
    codigo_do_IGBE: 3528908,
    UF: "SP",
    nome_da_cidade: "MARIAPOLIS",
  },
  {
    codigo_do_IGBE: 2704807,
    UF: "AL",
    nome_da_cidade: "MARIBONDO",
  },
  {
    codigo_do_IGBE: 3302700,
    UF: "RJ",
    nome_da_cidade: "MARICA",
  },
  {
    codigo_do_IGBE: 3140100,
    UF: "MG",
    nome_da_cidade: "MARILAC",
  },
  {
    codigo_do_IGBE: 3203353,
    UF: "ES",
    nome_da_cidade: "MARILANDIA",
  },
  {
    codigo_do_IGBE: 4115002,
    UF: "PR",
    nome_da_cidade: "MARILENA",
  },
  {
    codigo_do_IGBE: 3529005,
    UF: "SP",
    nome_da_cidade: "MARILIA",
  },
  {
    codigo_do_IGBE: 4115101,
    UF: "PR",
    nome_da_cidade: "MARILUZ",
  },
  {
    codigo_do_IGBE: 4115200,
    UF: "PR",
    nome_da_cidade: "MARINGA",
  },
  {
    codigo_do_IGBE: 3529104,
    UF: "SP",
    nome_da_cidade: "MARINOPOLIS",
  },
  {
    codigo_do_IGBE: 3140159,
    UF: "MG",
    nome_da_cidade: "MARIO CAMPOS",
  },
  {
    codigo_do_IGBE: 4115309,
    UF: "PR",
    nome_da_cidade: "MARIOPOLIS",
  },
  {
    codigo_do_IGBE: 4115358,
    UF: "PR",
    nome_da_cidade: "MARIPA",
  },
  {
    codigo_do_IGBE: 3140209,
    UF: "MG",
    nome_da_cidade: "MARIPA DE MINAS",
  },
  {
    codigo_do_IGBE: 1504422,
    UF: "PA",
    nome_da_cidade: "MARITUBA",
  },
  {
    codigo_do_IGBE: 2509156,
    UF: "PB",
    nome_da_cidade: "MARIZOPOLIS",
  },
  {
    codigo_do_IGBE: 3140308,
    UF: "MG",
    nome_da_cidade: "MARLIERIA",
  },
  {
    codigo_do_IGBE: 4115408,
    UF: "PR",
    nome_da_cidade: "MARMELEIRO",
  },
  {
    codigo_do_IGBE: 3140407,
    UF: "MG",
    nome_da_cidade: "MARMELOPOLIS",
  },
  {
    codigo_do_IGBE: 4115457,
    UF: "PR",
    nome_da_cidade: "MARQUINHO",
  },
  {
    codigo_do_IGBE: 2307908,
    UF: "CE",
    nome_da_cidade: "MARTINOPOLE",
  },
  {
    codigo_do_IGBE: 3529203,
    UF: "SP",
    nome_da_cidade: "MARTINOPOLIS",
  },
  {
    codigo_do_IGBE: 2407401,
    UF: "RN",
    nome_da_cidade: "MARTINS",
  },
  {
    codigo_do_IGBE: 3140530,
    UF: "MG",
    nome_da_cidade: "MARTINS SOARES",
  },
  {
    codigo_do_IGBE: 2804003,
    UF: "SE",
    nome_da_cidade: "MARUIM",
  },
  {
    codigo_do_IGBE: 4115507,
    UF: "PR",
    nome_da_cidade: "MARUMBI",
  },
  {
    codigo_do_IGBE: 5212907,
    UF: "GO",
    nome_da_cidade: "MARZAGAO",
  },
  {
    codigo_do_IGBE: 2920908,
    UF: "BA",
    nome_da_cidade: "MASCOTE",
  },
  {
    codigo_do_IGBE: 2308005,
    UF: "CE",
    nome_da_cidade: "MASSAPE",
  },
  {
    codigo_do_IGBE: 2206050,
    UF: "PI",
    nome_da_cidade: "MASSAPE DO PIAUI",
  },
  {
    codigo_do_IGBE: 2509206,
    UF: "PB",
    nome_da_cidade: "MASSARANDUBA",
  },
  {
    codigo_do_IGBE: 4312104,
    UF: "RS",
    nome_da_cidade: "MATA",
  },
  {
    codigo_do_IGBE: 2705002,
    UF: "AL",
    nome_da_cidade: "MATA GRANDE",
  },
  {
    codigo_do_IGBE: 2106409,
    UF: "MA",
    nome_da_cidade: "MATA ROMA",
  },
  {
    codigo_do_IGBE: 3140555,
    UF: "MG",
    nome_da_cidade: "MATA VERDE",
  },
  {
    codigo_do_IGBE: 3529302,
    UF: "SP",
    nome_da_cidade: "MATAO",
  },
  {
    codigo_do_IGBE: 2509305,
    UF: "PB",
    nome_da_cidade: "MATARACA",
  },
  {
    codigo_do_IGBE: 1712702,
    UF: "TO",
    nome_da_cidade: "MATEIROS",
  },
  {
    codigo_do_IGBE: 4115606,
    UF: "PR",
    nome_da_cidade: "MATELANDIA",
  },
  {
    codigo_do_IGBE: 3140704,
    UF: "MG",
    nome_da_cidade: "MATEUS LEME",
  },
  {
    codigo_do_IGBE: 3171501,
    UF: "MG",
    nome_da_cidade: "MATHIAS LOBATO",
  },
  {
    codigo_do_IGBE: 3140803,
    UF: "MG",
    nome_da_cidade: "MATIAS BARBOSA",
  },
  {
    codigo_do_IGBE: 3140852,
    UF: "MG",
    nome_da_cidade: "MATIAS CARDOSO",
  },
  {
    codigo_do_IGBE: 2206100,
    UF: "PI",
    nome_da_cidade: "MATIAS OLIMPIO",
  },
  {
    codigo_do_IGBE: 2921054,
    UF: "BA",
    nome_da_cidade: "MATINA",
  },
  {
    codigo_do_IGBE: 2106508,
    UF: "MA",
    nome_da_cidade: "MATINHA",
  },
  {
    codigo_do_IGBE: 2509339,
    UF: "PB",
    nome_da_cidade: "MATINHAS",
  },
  {
    codigo_do_IGBE: 4115705,
    UF: "PR",
    nome_da_cidade: "MATINHOS",
  },
  {
    codigo_do_IGBE: 2509370,
    UF: "PB",
    nome_da_cidade: "MATO GROSSO",
  },
  {
    codigo_do_IGBE: 4312153,
    UF: "RS",
    nome_da_cidade: "MATO LEITAO",
  },
  {
    codigo_do_IGBE: 4312179,
    UF: "RS",
    nome_da_cidade: "MATO QUEIMADO",
  },
  {
    codigo_do_IGBE: 4115739,
    UF: "PR",
    nome_da_cidade: "MATO RICO",
  },
  {
    codigo_do_IGBE: 3141009,
    UF: "MG",
    nome_da_cidade: "MATO VERDE",
  },
  {
    codigo_do_IGBE: 2106607,
    UF: "MA",
    nome_da_cidade: "MATOES",
  },
  {
    codigo_do_IGBE: 2106631,
    UF: "MA",
    nome_da_cidade: "MATOES DO NORTE",
  },
  {
    codigo_do_IGBE: 4210704,
    UF: "SC",
    nome_da_cidade: "MATOS COSTA",
  },
  {
    codigo_do_IGBE: 3141108,
    UF: "MG",
    nome_da_cidade: "MATOZINHOS",
  },
  {
    codigo_do_IGBE: 5212956,
    UF: "GO",
    nome_da_cidade: "MATRINCHA",
  },
  {
    codigo_do_IGBE: 5105606,
    UF: "MT",
    nome_da_cidade: "MATUPA",
  },
  {
    codigo_do_IGBE: 2509396,
    UF: "PB",
    nome_da_cidade: "MATUREIA",
  },
  {
    codigo_do_IGBE: 3141207,
    UF: "MG",
    nome_da_cidade: "MATUTINA",
  },
  {
    codigo_do_IGBE: 3529401,
    UF: "SP",
    nome_da_cidade: "MAUA",
  },
  {
    codigo_do_IGBE: 1302900,
    UF: "AM",
    nome_da_cidade: "MAUES",
  },
  {
    codigo_do_IGBE: 5213004,
    UF: "GO",
    nome_da_cidade: "MAURILANDIA",
  },
  {
    codigo_do_IGBE: 1712801,
    UF: "TO",
    nome_da_cidade: "MAURILANDIA DO TOCANTINS",
  },
  {
    codigo_do_IGBE: 2308104,
    UF: "CE",
    nome_da_cidade: "MAURITI",
  },
  {
    codigo_do_IGBE: 2407500,
    UF: "RN",
    nome_da_cidade: "MAXARANGUAPE",
  },
  {
    codigo_do_IGBE: 4312203,
    UF: "RS",
    nome_da_cidade: "MAXIMILIANO DE ALMEIDA",
  },
  {
    codigo_do_IGBE: 1600402,
    UF: "AP",
    nome_da_cidade: "MAZAGAO",
  },
  {
    codigo_do_IGBE: 3141306,
    UF: "MG",
    nome_da_cidade: "MEDEIROS",
  },
  {
    codigo_do_IGBE: 2921104,
    UF: "BA",
    nome_da_cidade: "MEDEIROS NETO",
  },
  {
    codigo_do_IGBE: 4115804,
    UF: "PR",
    nome_da_cidade: "MEDIANEIRA",
  },
  {
    codigo_do_IGBE: 1504455,
    UF: "PA",
    nome_da_cidade: "MEDICILANDIA",
  },
  {
    codigo_do_IGBE: 3141405,
    UF: "MG",
    nome_da_cidade: "MEDINA",
  },
  {
    codigo_do_IGBE: 1504505,
    UF: "PA",
    nome_da_cidade: "MELGACO",
  },
  {
    codigo_do_IGBE: 3302809,
    UF: "RJ",
    nome_da_cidade: "MENDES",
  },
  {
    codigo_do_IGBE: 3529500,
    UF: "SP",
    nome_da_cidade: "MENDONCA",
  },
  {
    codigo_do_IGBE: 4115853,
    UF: "PR",
    nome_da_cidade: "MERCEDES",
  },
  {
    codigo_do_IGBE: 3141603,
    UF: "MG",
    nome_da_cidade: "MERCES",
  },
  {
    codigo_do_IGBE: 3529609,
    UF: "SP",
    nome_da_cidade: "MERIDIANO",
  },
  {
    codigo_do_IGBE: 2308203,
    UF: "CE",
    nome_da_cidade: "MERUOCA",
  },
  {
    codigo_do_IGBE: 3529658,
    UF: "SP",
    nome_da_cidade: "MESOPOLIS",
  },
  {
    codigo_do_IGBE: 3141702,
    UF: "MG",
    nome_da_cidade: "MESQUITA",
  },
  {
    codigo_do_IGBE: 3302858,
    UF: "RJ",
    nome_da_cidade: "MESQUITA",
  },
  {
    codigo_do_IGBE: 2705200,
    UF: "AL",
    nome_da_cidade: "MESSIAS",
  },
  {
    codigo_do_IGBE: 2206209,
    UF: "PI",
    nome_da_cidade: "MIGUEL ALVES",
  },
  {
    codigo_do_IGBE: 2921203,
    UF: "BA",
    nome_da_cidade: "MIGUEL CALMON",
  },
  {
    codigo_do_IGBE: 2206308,
    UF: "PI",
    nome_da_cidade: "MIGUEL LEAO",
  },
  {
    codigo_do_IGBE: 3529708,
    UF: "SP",
    nome_da_cidade: "MIGUELOPOLIS",
  },
  {
    codigo_do_IGBE: 2921302,
    UF: "BA",
    nome_da_cidade: "MILAGRES",
  },
  {
    codigo_do_IGBE: 2308302,
    UF: "CE",
    nome_da_cidade: "MILAGRES",
  },
  {
    codigo_do_IGBE: 2106672,
    UF: "MA",
    nome_da_cidade: "MILAGRES DO MARANHAO",
  },
  {
    codigo_do_IGBE: 2308351,
    UF: "CE",
    nome_da_cidade: "MILHA",
  },
  {
    codigo_do_IGBE: 2206357,
    UF: "PI",
    nome_da_cidade: "MILTON BRANDAO",
  },
  {
    codigo_do_IGBE: 5213053,
    UF: "GO",
    nome_da_cidade: "MIMOSO DE GOIAS",
  },
  {
    codigo_do_IGBE: 3203403,
    UF: "ES",
    nome_da_cidade: "MIMOSO DO SUL",
  },
  {
    codigo_do_IGBE: 5213087,
    UF: "GO",
    nome_da_cidade: "MINACU",
  },
  {
    codigo_do_IGBE: 2705309,
    UF: "AL",
    nome_da_cidade: "MINADOR DO NEGRAO",
  },
  {
    codigo_do_IGBE: 3141801,
    UF: "MG",
    nome_da_cidade: "MINAS NOVAS",
  },
  {
    codigo_do_IGBE: 3141900,
    UF: "MG",
    nome_da_cidade: "MINDURI",
  },
  {
    codigo_do_IGBE: 5213103,
    UF: "GO",
    nome_da_cidade: "MINEIROS",
  },
  {
    codigo_do_IGBE: 1101203,
    UF: "RO",
    nome_da_cidade: "MINISTRO ANDREAZZA",
  },
  {
    codigo_do_IGBE: 3530003,
    UF: "SP",
    nome_da_cidade: "MIRA ESTRELA",
  },
  {
    codigo_do_IGBE: 3142007,
    UF: "MG",
    nome_da_cidade: "MIRABELA",
  },
  {
    codigo_do_IGBE: 3529906,
    UF: "SP",
    nome_da_cidade: "MIRACATU",
  },
  {
    codigo_do_IGBE: 3303005,
    UF: "RJ",
    nome_da_cidade: "MIRACEMA",
  },
  {
    codigo_do_IGBE: 1713205,
    UF: "TO",
    nome_da_cidade: "MIRACEMA DO TOCANTINS",
  },
  {
    codigo_do_IGBE: 2106706,
    UF: "MA",
    nome_da_cidade: "MIRADOR",
  },
  {
    codigo_do_IGBE: 4115903,
    UF: "PR",
    nome_da_cidade: "MIRADOR",
  },
  {
    codigo_do_IGBE: 3142106,
    UF: "MG",
    nome_da_cidade: "MIRADOURO",
  },
  {
    codigo_do_IGBE: 4312302,
    UF: "RS",
    nome_da_cidade: "MIRAGUAI",
  },
  {
    codigo_do_IGBE: 3142205,
    UF: "MG",
    nome_da_cidade: "MIRAI",
  },
  {
    codigo_do_IGBE: 2308377,
    UF: "CE",
    nome_da_cidade: "MIRAIMA",
  },
  {
    codigo_do_IGBE: 5005608,
    UF: "MS",
    nome_da_cidade: "MIRANDA",
  },
  {
    codigo_do_IGBE: 3530102,
    UF: "SP",
    nome_da_cidade: "MIRANDOPOLIS",
  },
  {
    codigo_do_IGBE: 2921401,
    UF: "BA",
    nome_da_cidade: "MIRANGABA",
  },
  {
    codigo_do_IGBE: 1713304,
    UF: "TO",
    nome_da_cidade: "MIRANORTE",
  },
  {
    codigo_do_IGBE: 2921450,
    UF: "BA",
    nome_da_cidade: "MIRANTE",
  },
  {
    codigo_do_IGBE: 1101302,
    UF: "RO",
    nome_da_cidade: "MIRANTE DA SERRA",
  },
  {
    codigo_do_IGBE: 3530201,
    UF: "SP",
    nome_da_cidade: "MIRANTE DO PARANAPANEMA",
  },
  {
    codigo_do_IGBE: 4116000,
    UF: "PR",
    nome_da_cidade: "MIRASELVA",
  },
  {
    codigo_do_IGBE: 3530300,
    UF: "SP",
    nome_da_cidade: "MIRASSOL",
  },
  {
    codigo_do_IGBE: 5105622,
    UF: "MT",
    nome_da_cidade: "MIRASSOL D'OESTE",
  },
  {
    codigo_do_IGBE: 3530409,
    UF: "SP",
    nome_da_cidade: "MIRASSOLANDIA",
  },
  {
    codigo_do_IGBE: 3142254,
    UF: "MG",
    nome_da_cidade: "MIRAVANIA",
  },
  {
    codigo_do_IGBE: 4210852,
    UF: "SC",
    nome_da_cidade: "MIRIM DOCE",
  },
  {
    codigo_do_IGBE: 4116059,
    UF: "PR",
    nome_da_cidade: "MISSAL",
  },
  {
    codigo_do_IGBE: 2308401,
    UF: "CE",
    nome_da_cidade: "MISSAO VELHA",
  },
  {
    codigo_do_IGBE: 3530508,
    UF: "SP",
    nome_da_cidade: "MOCOCA",
  },
  {
    codigo_do_IGBE: 4210902,
    UF: "SC",
    nome_da_cidade: "MODELO",
  },
  {
    codigo_do_IGBE: 3142304,
    UF: "MG",
    nome_da_cidade: "MOEDA",
  },
  {
    codigo_do_IGBE: 3142403,
    UF: "MG",
    nome_da_cidade: "MOEMA",
  },
  {
    codigo_do_IGBE: 2509404,
    UF: "PB",
    nome_da_cidade: "MOGEIRO",
  },
  {
    codigo_do_IGBE: 3530607,
    UF: "SP",
    nome_da_cidade: "MOGI DAS CRUZES",
  },
  {
    codigo_do_IGBE: 3530706,
    UF: "SP",
    nome_da_cidade: "MOGI GUACU",
  },
  {
    codigo_do_IGBE: 2804102,
    UF: "SE",
    nome_da_cidade: "MOITA BONITA",
  },
  {
    codigo_do_IGBE: 3530805,
    UF: "SP",
    nome_da_cidade: "MOGI MIRIM",
  },
  {
    codigo_do_IGBE: 1504703,
    UF: "PA",
    nome_da_cidade: "MOJU",
  },
  {
    codigo_do_IGBE: 2106904,
    UF: "MA",
    nome_da_cidade: "MONCAO",
  },
  {
    codigo_do_IGBE: 3531001,
    UF: "SP",
    nome_da_cidade: "MONCOES",
  },
  {
    codigo_do_IGBE: 4211009,
    UF: "SC",
    nome_da_cidade: "MONDAI",
  },
  {
    codigo_do_IGBE: 3531100,
    UF: "SP",
    nome_da_cidade: "MONGAGUA",
  },
  {
    codigo_do_IGBE: 3142502,
    UF: "MG",
    nome_da_cidade: "MONJOLOS",
  },
  {
    codigo_do_IGBE: 2206407,
    UF: "PI",
    nome_da_cidade: "MONSENHOR GIL",
  },
  {
    codigo_do_IGBE: 2206506,
    UF: "PI",
    nome_da_cidade: "MONSENHOR HIPOLITO",
  },
  {
    codigo_do_IGBE: 3142601,
    UF: "MG",
    nome_da_cidade: "MONSENHOR PAULO",
  },
  {
    codigo_do_IGBE: 2308609,
    UF: "CE",
    nome_da_cidade: "MONSENHOR TABOSA",
  },
  {
    codigo_do_IGBE: 2509503,
    UF: "PB",
    nome_da_cidade: "MONTADAS",
  },
  {
    codigo_do_IGBE: 3142700,
    UF: "MG",
    nome_da_cidade: "MONTALVANIA",
  },
  {
    codigo_do_IGBE: 3203502,
    UF: "ES",
    nome_da_cidade: "MONTANHA",
  },
  {
    codigo_do_IGBE: 1504802,
    UF: "PA",
    nome_da_cidade: "MONTE ALEGRE",
  },
  {
    codigo_do_IGBE: 2407807,
    UF: "RN",
    nome_da_cidade: "MONTE ALEGRE",
  },
  {
    codigo_do_IGBE: 5213509,
    UF: "GO",
    nome_da_cidade: "MONTE ALEGRE DE GOIAS",
  },
  {
    codigo_do_IGBE: 3142809,
    UF: "MG",
    nome_da_cidade: "MONTE ALEGRE DE MINAS",
  },
  {
    codigo_do_IGBE: 2804201,
    UF: "SE",
    nome_da_cidade: "MONTE ALEGRE DE SERGIPE",
  },
  {
    codigo_do_IGBE: 2206605,
    UF: "PI",
    nome_da_cidade: "MONTE ALEGRE DO PIAUI",
  },
  {
    codigo_do_IGBE: 3531209,
    UF: "SP",
    nome_da_cidade: "MONTE ALEGRE DO SUL",
  },
  {
    codigo_do_IGBE: 3142908,
    UF: "MG",
    nome_da_cidade: "MONTE AZUL",
  },
  {
    codigo_do_IGBE: 3531506,
    UF: "SP",
    nome_da_cidade: "MONTE AZUL PAULISTA",
  },
  {
    codigo_do_IGBE: 3143005,
    UF: "MG",
    nome_da_cidade: "MONTE BELO",
  },
  {
    codigo_do_IGBE: 4211058,
    UF: "SC",
    nome_da_cidade: "MONTE CARLO",
  },
  {
    codigo_do_IGBE: 3143104,
    UF: "MG",
    nome_da_cidade: "MONTE CARMELO",
  },
  {
    codigo_do_IGBE: 4211108,
    UF: "SC",
    nome_da_cidade: "MONTE CASTELO",
  },
  {
    codigo_do_IGBE: 3531605,
    UF: "SP",
    nome_da_cidade: "MONTE CASTELO",
  },
  {
    codigo_do_IGBE: 2407906,
    UF: "RN",
    nome_da_cidade: "MONTE DAS GAMELEIRAS",
  },
  {
    codigo_do_IGBE: 1713601,
    UF: "TO",
    nome_da_cidade: "MONTE DO CARMO",
  },
  {
    codigo_do_IGBE: 3143153,
    UF: "MG",
    nome_da_cidade: "MONTE FORMOSO",
  },
  {
    codigo_do_IGBE: 2509602,
    UF: "PB",
    nome_da_cidade: "MONTE HOREBE",
  },
  {
    codigo_do_IGBE: 3531803,
    UF: "SP",
    nome_da_cidade: "MONTE MOR",
  },
  {
    codigo_do_IGBE: 1101401,
    UF: "RO",
    nome_da_cidade: "MONTE NEGRO",
  },
  {
    codigo_do_IGBE: 2921500,
    UF: "BA",
    nome_da_cidade: "MONTE SANTO",
  },
  {
    codigo_do_IGBE: 1713700,
    UF: "TO",
    nome_da_cidade: "MONTE SANTO DO TOCANTINS",
  },
  {
    codigo_do_IGBE: 3143401,
    UF: "MG",
    nome_da_cidade: "MONTE SIAO",
  },
  {
    codigo_do_IGBE: 2509701,
    UF: "PB",
    nome_da_cidade: "MONTEIRO",
  },
  {
    codigo_do_IGBE: 2705408,
    UF: "AL",
    nome_da_cidade: "MONTEIROPOLIS",
  },
  {
    codigo_do_IGBE: 4312401,
    UF: "RS",
    nome_da_cidade: "MONTENEGRO",
  },
  {
    codigo_do_IGBE: 2107001,
    UF: "MA",
    nome_da_cidade: "MONTES ALTOS",
  },
  {
    codigo_do_IGBE: 3143302,
    UF: "MG",
    nome_da_cidade: "MONTES CLAROS",
  },
  {
    codigo_do_IGBE: 5213707,
    UF: "GO",
    nome_da_cidade: "MONTES CLAROS DE GOIAS",
  },
  {
    codigo_do_IGBE: 3143450,
    UF: "MG",
    nome_da_cidade: "MONTEZUMA",
  },
  {
    codigo_do_IGBE: 5213756,
    UF: "GO",
    nome_da_cidade: "MONTIVIDIU",
  },
  {
    codigo_do_IGBE: 5213772,
    UF: "GO",
    nome_da_cidade: "MONTIVIDIU DO NORTE",
  },
  {
    codigo_do_IGBE: 2308708,
    UF: "CE",
    nome_da_cidade: "MORADA NOVA",
  },
  {
    codigo_do_IGBE: 2308807,
    UF: "CE",
    nome_da_cidade: "MORAUJO",
  },
  {
    codigo_do_IGBE: 2614303,
    UF: "PE",
    nome_da_cidade: "MOREILANDIA",
  },
  {
    codigo_do_IGBE: 4116109,
    UF: "PR",
    nome_da_cidade: "MOREIRA SALES",
  },
  {
    codigo_do_IGBE: 2609402,
    UF: "PE",
    nome_da_cidade: "MORENO",
  },
  {
    codigo_do_IGBE: 4312427,
    UF: "RS",
    nome_da_cidade: "MORMACO",
  },
  {
    codigo_do_IGBE: 2921609,
    UF: "BA",
    nome_da_cidade: "MORPARA",
  },
  {
    codigo_do_IGBE: 4116208,
    UF: "PR",
    nome_da_cidade: "MORRETES",
  },
  {
    codigo_do_IGBE: 5213806,
    UF: "GO",
    nome_da_cidade: "MORRINHOS",
  },
  {
    codigo_do_IGBE: 4312443,
    UF: "RS",
    nome_da_cidade: "MORRINHOS DO SUL",
  },
  {
    codigo_do_IGBE: 3531902,
    UF: "SP",
    nome_da_cidade: "MORRO AGUDO",
  },
  {
    codigo_do_IGBE: 5213855,
    UF: "GO",
    nome_da_cidade: "MORRO AGUDO DE GOIAS",
  },
  {
    codigo_do_IGBE: 4211207,
    UF: "SC",
    nome_da_cidade: "MORRO DA FUMACA",
  },
  {
    codigo_do_IGBE: 3143609,
    UF: "MG",
    nome_da_cidade: "MORRO DA GARCA",
  },
  {
    codigo_do_IGBE: 2921708,
    UF: "BA",
    nome_da_cidade: "MORRO DO CHAPEU",
  },
  {
    codigo_do_IGBE: 2206670,
    UF: "PI",
    nome_da_cidade: "MORRO DO CHAPEU DO PIAUI",
  },
  {
    codigo_do_IGBE: 3143708,
    UF: "MG",
    nome_da_cidade: "MORRO DO PILAR",
  },
  {
    codigo_do_IGBE: 4211256,
    UF: "SC",
    nome_da_cidade: "MORRO GRANDE",
  },
  {
    codigo_do_IGBE: 4312476,
    UF: "RS",
    nome_da_cidade: "MORRO REUTER",
  },
  {
    codigo_do_IGBE: 2107100,
    UF: "MA",
    nome_da_cidade: "MORROS",
  },
  {
    codigo_do_IGBE: 2921807,
    UF: "BA",
    nome_da_cidade: "MORTUGABA",
  },
  {
    codigo_do_IGBE: 3532009,
    UF: "SP",
    nome_da_cidade: "MORUNGABA",
  },
  {
    codigo_do_IGBE: 5213905,
    UF: "GO",
    nome_da_cidade: "MOSSAMEDES",
  },
  {
    codigo_do_IGBE: 2408003,
    UF: "RN",
    nome_da_cidade: "MOSSORO",
  },
  {
    codigo_do_IGBE: 4312500,
    UF: "RS",
    nome_da_cidade: "MOSTARDAS",
  },
  {
    codigo_do_IGBE: 3532058,
    UF: "SP",
    nome_da_cidade: "MOTUCA",
  },
  {
    codigo_do_IGBE: 5214002,
    UF: "GO",
    nome_da_cidade: "MOZARLANDIA",
  },
  {
    codigo_do_IGBE: 1504901,
    UF: "PA",
    nome_da_cidade: "MUANA",
  },
  {
    codigo_do_IGBE: 1400308,
    UF: "RR",
    nome_da_cidade: "MUCAJAI",
  },
  {
    codigo_do_IGBE: 2309003,
    UF: "CE",
    nome_da_cidade: "MUCAMBO",
  },
  {
    codigo_do_IGBE: 4312609,
    UF: "RS",
    nome_da_cidade: "MUCUM",
  },
  {
    codigo_do_IGBE: 2922003,
    UF: "BA",
    nome_da_cidade: "MUCURI",
  },
  {
    codigo_do_IGBE: 3203601,
    UF: "ES",
    nome_da_cidade: "MUCURICI",
  },
  {
    codigo_do_IGBE: 4312625,
    UF: "RS",
    nome_da_cidade: "MULITERNO",
  },
  {
    codigo_do_IGBE: 2309102,
    UF: "CE",
    nome_da_cidade: "MULUNGU",
  },
  {
    codigo_do_IGBE: 2509800,
    UF: "PB",
    nome_da_cidade: "MULUNGU",
  },
  {
    codigo_do_IGBE: 2922052,
    UF: "BA",
    nome_da_cidade: "MULUNGU DO MORRO",
  },
  {
    codigo_do_IGBE: 2922102,
    UF: "BA",
    nome_da_cidade: "MUNDO NOVO",
  },
  {
    codigo_do_IGBE: 5214051,
    UF: "GO",
    nome_da_cidade: "MUNDO NOVO",
  },
  {
    codigo_do_IGBE: 5005681,
    UF: "MS",
    nome_da_cidade: "MUNDO NOVO",
  },
  {
    codigo_do_IGBE: 3143807,
    UF: "MG",
    nome_da_cidade: "MUNHOZ",
  },
  {
    codigo_do_IGBE: 2922201,
    UF: "BA",
    nome_da_cidade: "MUNIZ FERREIRA",
  },
  {
    codigo_do_IGBE: 3203700,
    UF: "ES",
    nome_da_cidade: "MUNIZ FREIRE",
  },
  {
    codigo_do_IGBE: 2922250,
    UF: "BA",
    nome_da_cidade: "MUQUEM DE SAO FRANCISCO",
  },
  {
    codigo_do_IGBE: 3203809,
    UF: "ES",
    nome_da_cidade: "MUQUI",
  },
  {
    codigo_do_IGBE: 3143906,
    UF: "MG",
    nome_da_cidade: "MURIAE",
  },
  {
    codigo_do_IGBE: 2804300,
    UF: "SE",
    nome_da_cidade: "MURIBECA",
  },
  {
    codigo_do_IGBE: 2705507,
    UF: "AL",
    nome_da_cidade: "MURICI",
  },
  {
    codigo_do_IGBE: 1713957,
    UF: "TO",
    nome_da_cidade: "MURICILANDIA",
  },
  {
    codigo_do_IGBE: 2922300,
    UF: "BA",
    nome_da_cidade: "MURITIBA",
  },
  {
    codigo_do_IGBE: 3532108,
    UF: "SP",
    nome_da_cidade: "MURUTINGA DO SUL",
  },
  {
    codigo_do_IGBE: 2922409,
    UF: "BA",
    nome_da_cidade: "MUTUIPE",
  },
  {
    codigo_do_IGBE: 3144003,
    UF: "MG",
    nome_da_cidade: "MUTUM",
  },
  {
    codigo_do_IGBE: 5214101,
    UF: "GO",
    nome_da_cidade: "MUTUNOPOLIS",
  },
  {
    codigo_do_IGBE: 3144201,
    UF: "MG",
    nome_da_cidade: "NACIP RAYDAN",
  },
  {
    codigo_do_IGBE: 3532157,
    UF: "SP",
    nome_da_cidade: "NANTES",
  },
  {
    codigo_do_IGBE: 3144300,
    UF: "MG",
    nome_da_cidade: "NANUQUE",
  },
  {
    codigo_do_IGBE: 4312658,
    UF: "RS",
    nome_da_cidade: "NAO-ME-TOQUE",
  },
  {
    codigo_do_IGBE: 3144359,
    UF: "MG",
    nome_da_cidade: "NAQUE",
  },
  {
    codigo_do_IGBE: 3532207,
    UF: "SP",
    nome_da_cidade: "NARANDIBA",
  },
  {
    codigo_do_IGBE: 2408102,
    UF: "RN",
    nome_da_cidade: "NATAL",
  },
  {
    codigo_do_IGBE: 3144375,
    UF: "MG",
    nome_da_cidade: "NATALANDIA",
  },
  {
    codigo_do_IGBE: 3303104,
    UF: "RJ",
    nome_da_cidade: "NATIVIDADE",
  },
  {
    codigo_do_IGBE: 1714203,
    UF: "TO",
    nome_da_cidade: "NATIVIDADE",
  },
  {
    codigo_do_IGBE: 2509909,
    UF: "PB",
    nome_da_cidade: "NATUBA",
  },
  {
    codigo_do_IGBE: 4211306,
    UF: "SC",
    nome_da_cidade: "NAVEGANTES",
  },
  {
    codigo_do_IGBE: 5005707,
    UF: "MS",
    nome_da_cidade: "NAVIRAI",
  },
  {
    codigo_do_IGBE: 2922508,
    UF: "BA",
    nome_da_cidade: "NAZARE",
  },
  {
    codigo_do_IGBE: 1714302,
    UF: "TO",
    nome_da_cidade: "NAZARE",
  },
  {
    codigo_do_IGBE: 2609501,
    UF: "PE",
    nome_da_cidade: "NAZARE DA MATA",
  },
  {
    codigo_do_IGBE: 2206704,
    UF: "PI",
    nome_da_cidade: "NAZARE DO PIAUI",
  },
  {
    codigo_do_IGBE: 3532405,
    UF: "SP",
    nome_da_cidade: "NAZARE PAULISTA",
  },
  {
    codigo_do_IGBE: 3144508,
    UF: "MG",
    nome_da_cidade: "NAZARENO",
  },
  {
    codigo_do_IGBE: 2510006,
    UF: "PB",
    nome_da_cidade: "NAZAREZINHO",
  },
  {
    codigo_do_IGBE: 2804409,
    UF: "SE",
    nome_da_cidade: "NEOPOLIS",
  },
  {
    codigo_do_IGBE: 3144607,
    UF: "MG",
    nome_da_cidade: "NEPOMUCENO",
  },
  {
    codigo_do_IGBE: 5214507,
    UF: "GO",
    nome_da_cidade: "NEROPOLIS",
  },
  {
    codigo_do_IGBE: 1303007,
    UF: "AM",
    nome_da_cidade: "NHAMUNDA",
  },
  {
    codigo_do_IGBE: 3532603,
    UF: "SP",
    nome_da_cidade: "NHANDEARA",
  },
  {
    codigo_do_IGBE: 4312674,
    UF: "RS",
    nome_da_cidade: "NICOLAU VERGUEIRO",
  },
  {
    codigo_do_IGBE: 2922607,
    UF: "BA",
    nome_da_cidade: "NILO PECANHA",
  },
  {
    codigo_do_IGBE: 3303203,
    UF: "RJ",
    nome_da_cidade: "NILOPOLIS",
  },
  {
    codigo_do_IGBE: 2107209,
    UF: "MA",
    nome_da_cidade: "NINA RODRIGUES",
  },
  {
    codigo_do_IGBE: 3144656,
    UF: "MG",
    nome_da_cidade: "NINHEIRA",
  },
  {
    codigo_do_IGBE: 5005806,
    UF: "MS",
    nome_da_cidade: "NIOAQUE",
  },
  {
    codigo_do_IGBE: 3532702,
    UF: "SP",
    nome_da_cidade: "NIPOA",
  },
  {
    codigo_do_IGBE: 2408201,
    UF: "RN",
    nome_da_cidade: "NISIA FLORESTA",
  },
  {
    codigo_do_IGBE: 3303302,
    UF: "RJ",
    nome_da_cidade: "NITEROI",
  },
  {
    codigo_do_IGBE: 5105903,
    UF: "MT",
    nome_da_cidade: "NOBRES",
  },
  {
    codigo_do_IGBE: 4312708,
    UF: "RS",
    nome_da_cidade: "NONOAI",
  },
  {
    codigo_do_IGBE: 5106000,
    UF: "MT",
    nome_da_cidade: "NORTELANDIA",
  },
  {
    codigo_do_IGBE: 2804458,
    UF: "SE",
    nome_da_cidade: "NOSSA SENHORA APARECIDA",
  },
  {
    codigo_do_IGBE: 2804508,
    UF: "SE",
    nome_da_cidade: "NOSSA SENHORA DA GLORIA",
  },
  {
    codigo_do_IGBE: 2804607,
    UF: "SE",
    nome_da_cidade: "NOSSA SENHORA DAS DORES",
  },
  {
    codigo_do_IGBE: 4116406,
    UF: "PR",
    nome_da_cidade: "NOSSA SENHORA DAS GRACAS",
  },
  {
    codigo_do_IGBE: 2804706,
    UF: "SE",
    nome_da_cidade: "NOSSA SENHORA DE LOURDES",
  },
  {
    codigo_do_IGBE: 5106109,
    UF: "MT",
    nome_da_cidade: "NOSSA SENHORA DO LIVRAMENTO",
  },
  {
    codigo_do_IGBE: 2206803,
    UF: "PI",
    nome_da_cidade: "NOSSA SENHORA DOS REMEDIOS",
  },
  {
    codigo_do_IGBE: 3532801,
    UF: "SP",
    nome_da_cidade: "NOVA ALIANCA",
  },
  {
    codigo_do_IGBE: 4312757,
    UF: "RS",
    nome_da_cidade: "NOVA ALVORADA",
  },
  {
    codigo_do_IGBE: 5006002,
    UF: "MS",
    nome_da_cidade: "NOVA ALVORADA DO SUL",
  },
  {
    codigo_do_IGBE: 5214705,
    UF: "GO",
    nome_da_cidade: "NOVA AMERICA",
  },
  {
    codigo_do_IGBE: 5006200,
    UF: "MS",
    nome_da_cidade: "NOVA ANDRADINA",
  },
  {
    codigo_do_IGBE: 4312807,
    UF: "RS",
    nome_da_cidade: "NOVA ARACA",
  },
  {
    codigo_do_IGBE: 5214804,
    UF: "GO",
    nome_da_cidade: "NOVA AURORA",
  },
  {
    codigo_do_IGBE: 4116703,
    UF: "PR",
    nome_da_cidade: "NOVA AURORA",
  },
  {
    codigo_do_IGBE: 5106158,
    UF: "MT",
    nome_da_cidade: "NOVA BANDEIRANTES",
  },
  {
    codigo_do_IGBE: 4312906,
    UF: "RS",
    nome_da_cidade: "NOVA BASSANO",
  },
  {
    codigo_do_IGBE: 3144672,
    UF: "MG",
    nome_da_cidade: "NOVA BELEM",
  },
  {
    codigo_do_IGBE: 4312955,
    UF: "RS",
    nome_da_cidade: "NOVA BOA VISTA",
  },
  {
    codigo_do_IGBE: 5106208,
    UF: "MT",
    nome_da_cidade: "NOVA BRASILANDIA",
  },
  {
    codigo_do_IGBE: 1100148,
    UF: "RO",
    nome_da_cidade: "NOVA BRASILANDIA D'OESTE",
  },
  {
    codigo_do_IGBE: 4313003,
    UF: "RS",
    nome_da_cidade: "NOVA BRESCIA",
  },
  {
    codigo_do_IGBE: 3532827,
    UF: "SP",
    nome_da_cidade: "NOVA CAMPINA",
  },
  {
    codigo_do_IGBE: 2922706,
    UF: "BA",
    nome_da_cidade: "NOVA CANAA",
  },
  {
    codigo_do_IGBE: 3532843,
    UF: "SP",
    nome_da_cidade: "NOVA CANAA PAULISTA",
  },
  {
    codigo_do_IGBE: 4313011,
    UF: "RS",
    nome_da_cidade: "NOVA CANDELARIA",
  },
  {
    codigo_do_IGBE: 4116802,
    UF: "PR",
    nome_da_cidade: "NOVA CANTU",
  },
  {
    codigo_do_IGBE: 3532868,
    UF: "SP",
    nome_da_cidade: "NOVA CASTILHO",
  },
  {
    codigo_do_IGBE: 2107258,
    UF: "MA",
    nome_da_cidade: "NOVA COLINAS",
  },
  {
    codigo_do_IGBE: 5214838,
    UF: "GO",
    nome_da_cidade: "NOVA CRIXAS",
  },
  {
    codigo_do_IGBE: 3144706,
    UF: "MG",
    nome_da_cidade: "NOVA ERA",
  },
  {
    codigo_do_IGBE: 4211405,
    UF: "SC",
    nome_da_cidade: "NOVA ERECHIM",
  },
  {
    codigo_do_IGBE: 4116901,
    UF: "PR",
    nome_da_cidade: "NOVA ESPERANCA",
  },
  {
    codigo_do_IGBE: 1504950,
    UF: "PA",
    nome_da_cidade: "NOVA ESPERANCA DO PIRIA",
  },
  {
    codigo_do_IGBE: 4116950,
    UF: "PR",
    nome_da_cidade: "NOVA ESPERANCA DO SUDOESTE",
  },
  {
    codigo_do_IGBE: 3532900,
    UF: "SP",
    nome_da_cidade: "NOVA EUROPA",
  },
  {
    codigo_do_IGBE: 2922730,
    UF: "BA",
    nome_da_cidade: "NOVA FATIMA",
  },
  {
    codigo_do_IGBE: 4117008,
    UF: "PR",
    nome_da_cidade: "NOVA FATIMA",
  },
  {
    codigo_do_IGBE: 2510105,
    UF: "PB",
    nome_da_cidade: "NOVA FLORESTA",
  },
  {
    codigo_do_IGBE: 5214861,
    UF: "GO",
    nome_da_cidade: "NOVA GLORIA",
  },
  {
    codigo_do_IGBE: 3533007,
    UF: "SP",
    nome_da_cidade: "NOVA GRANADA",
  },
  {
    codigo_do_IGBE: 5108808,
    UF: "MT",
    nome_da_cidade: "NOVA GUARITA",
  },
  {
    codigo_do_IGBE: 3533106,
    UF: "SP",
    nome_da_cidade: "NOVA GUATAPORANGA",
  },
  {
    codigo_do_IGBE: 4313060,
    UF: "RS",
    nome_da_cidade: "NOVA HARTZ",
  },
  {
    codigo_do_IGBE: 2922755,
    UF: "BA",
    nome_da_cidade: "NOVA IBIA",
  },
  {
    codigo_do_IGBE: 3303500,
    UF: "RJ",
    nome_da_cidade: "NOVA IGUACU",
  },
  {
    codigo_do_IGBE: 5214879,
    UF: "GO",
    nome_da_cidade: "NOVA IGUACU DE GOIAS",
  },
  {
    codigo_do_IGBE: 3533205,
    UF: "SP",
    nome_da_cidade: "NOVA INDEPENDENCIA",
  },
  {
    codigo_do_IGBE: 2107308,
    UF: "MA",
    nome_da_cidade: "NOVA IORQUE",
  },
  {
    codigo_do_IGBE: 1504976,
    UF: "PA",
    nome_da_cidade: "NOVA IPIXUNA",
  },
  {
    codigo_do_IGBE: 4211454,
    UF: "SC",
    nome_da_cidade: "NOVA ITABERABA",
  },
  {
    codigo_do_IGBE: 2922805,
    UF: "BA",
    nome_da_cidade: "NOVA ITARANA",
  },
  {
    codigo_do_IGBE: 5106182,
    UF: "MT",
    nome_da_cidade: "NOVA LACERDA",
  },
  {
    codigo_do_IGBE: 3144805,
    UF: "MG",
    nome_da_cidade: "NOVA LIMA",
  },
  {
    codigo_do_IGBE: 3533304,
    UF: "SP",
    nome_da_cidade: "NOVA LUZITANIA",
  },
  {
    codigo_do_IGBE: 1100338,
    UF: "RO",
    nome_da_cidade: "NOVA MAMORE",
  },
  {
    codigo_do_IGBE: 5108857,
    UF: "MT",
    nome_da_cidade: "NOVA MARILANDIA",
  },
  {
    codigo_do_IGBE: 5108907,
    UF: "MT",
    nome_da_cidade: "NOVA MARINGA",
  },
  {
    codigo_do_IGBE: 3144904,
    UF: "MG",
    nome_da_cidade: "NOVA MODICA",
  },
  {
    codigo_do_IGBE: 5108956,
    UF: "MT",
    nome_da_cidade: "NOVA MONTE VERDE",
  },
  {
    codigo_do_IGBE: 5106224,
    UF: "MT",
    nome_da_cidade: "NOVA MUTUM",
  },
  {
    codigo_do_IGBE: 5106174,
    UF: "MT",
    nome_da_cidade: "NOVA NAZARE",
  },
  {
    codigo_do_IGBE: 3533403,
    UF: "SP",
    nome_da_cidade: "NOVA ODESSA",
  },
  {
    codigo_do_IGBE: 5106232,
    UF: "MT",
    nome_da_cidade: "NOVA OLIMPIA",
  },
  {
    codigo_do_IGBE: 4117206,
    UF: "PR",
    nome_da_cidade: "NOVA OLIMPIA",
  },
  {
    codigo_do_IGBE: 2309201,
    UF: "CE",
    nome_da_cidade: "NOVA OLINDA",
  },
  {
    codigo_do_IGBE: 2510204,
    UF: "PB",
    nome_da_cidade: "NOVA OLINDA",
  },
  {
    codigo_do_IGBE: 1714880,
    UF: "TO",
    nome_da_cidade: "NOVA OLINDA",
  },
  {
    codigo_do_IGBE: 4313086,
    UF: "RS",
    nome_da_cidade: "NOVA PADUA",
  },
  {
    codigo_do_IGBE: 4313102,
    UF: "RS",
    nome_da_cidade: "NOVA PALMA",
  },
  {
    codigo_do_IGBE: 2510303,
    UF: "PB",
    nome_da_cidade: "NOVA PALMEIRA",
  },
  {
    codigo_do_IGBE: 4313201,
    UF: "RS",
    nome_da_cidade: "NOVA PETROPOLIS",
  },
  {
    codigo_do_IGBE: 3145000,
    UF: "MG",
    nome_da_cidade: "NOVA PONTE",
  },
  {
    codigo_do_IGBE: 4313300,
    UF: "RS",
    nome_da_cidade: "NOVA PRATA",
  },
  {
    codigo_do_IGBE: 4117255,
    UF: "PR",
    nome_da_cidade: "NOVA PRATA DO IGUACU",
  },
  {
    codigo_do_IGBE: 4313334,
    UF: "RS",
    nome_da_cidade: "NOVA RAMADA",
  },
  {
    codigo_do_IGBE: 2922854,
    UF: "BA",
    nome_da_cidade: "NOVA REDENCAO",
  },
  {
    codigo_do_IGBE: 3145109,
    UF: "MG",
    nome_da_cidade: "NOVA RESENDE",
  },
  {
    codigo_do_IGBE: 5214903,
    UF: "GO",
    nome_da_cidade: "NOVA ROMA",
  },
  {
    codigo_do_IGBE: 4313359,
    UF: "RS",
    nome_da_cidade: "NOVA ROMA DO SUL",
  },
  {
    codigo_do_IGBE: 2309300,
    UF: "CE",
    nome_da_cidade: "NOVA RUSSAS",
  },
  {
    codigo_do_IGBE: 4117214,
    UF: "PR",
    nome_da_cidade: "NOVA SANTA BARBARA",
  },
  {
    codigo_do_IGBE: 5106190,
    UF: "MT",
    nome_da_cidade: "NOVA SANTA HELENA",
  },
  {
    codigo_do_IGBE: 2207959,
    UF: "PI",
    nome_da_cidade: "NOVA SANTA RITA",
  },
  {
    codigo_do_IGBE: 4313375,
    UF: "RS",
    nome_da_cidade: "NOVA SANTA RITA",
  },
  {
    codigo_do_IGBE: 4117222,
    UF: "PR",
    nome_da_cidade: "NOVA SANTA ROSA",
  },
  {
    codigo_do_IGBE: 3145208,
    UF: "MG",
    nome_da_cidade: "NOVA SERRANA",
  },
  {
    codigo_do_IGBE: 2922904,
    UF: "BA",
    nome_da_cidade: "NOVA SOURE",
  },
  {
    codigo_do_IGBE: 4117271,
    UF: "PR",
    nome_da_cidade: "NOVA TEBAS",
  },
  {
    codigo_do_IGBE: 1505007,
    UF: "PA",
    nome_da_cidade: "NOVA TIMBOTEUA",
  },
  {
    codigo_do_IGBE: 4211504,
    UF: "SC",
    nome_da_cidade: "NOVA TRENTO",
  },
  {
    codigo_do_IGBE: 5106240,
    UF: "MT",
    nome_da_cidade: "NOVA UBIRATA",
  },
  {
    codigo_do_IGBE: 3136603,
    UF: "MG",
    nome_da_cidade: "NOVA UNIAO",
  },
  {
    codigo_do_IGBE: 1101435,
    UF: "RO",
    nome_da_cidade: "NOVA UNIAO",
  },
  {
    codigo_do_IGBE: 3203908,
    UF: "ES",
    nome_da_cidade: "NOVA VENECIA",
  },
  {
    codigo_do_IGBE: 5215009,
    UF: "GO",
    nome_da_cidade: "NOVA VENEZA",
  },
  {
    codigo_do_IGBE: 2923001,
    UF: "BA",
    nome_da_cidade: "NOVA VICOSA",
  },
  {
    codigo_do_IGBE: 3533254,
    UF: "SP",
    nome_da_cidade: "NOVAIS",
  },
  {
    codigo_do_IGBE: 1715101,
    UF: "TO",
    nome_da_cidade: "NOVO ACORDO",
  },
  {
    codigo_do_IGBE: 1303205,
    UF: "AM",
    nome_da_cidade: "NOVO AIRAO",
  },
  {
    codigo_do_IGBE: 1715150,
    UF: "TO",
    nome_da_cidade: "NOVO ALEGRE",
  },
  {
    codigo_do_IGBE: 1303304,
    UF: "AM",
    nome_da_cidade: "NOVO ARIPUANA",
  },
  {
    codigo_do_IGBE: 4313490,
    UF: "RS",
    nome_da_cidade: "NOVO BARREIRO",
  },
  {
    codigo_do_IGBE: 5215207,
    UF: "GO",
    nome_da_cidade: "NOVO BRASIL",
  },
  {
    codigo_do_IGBE: 4313391,
    UF: "RS",
    nome_da_cidade: "NOVO CABRAIS",
  },
  {
    codigo_do_IGBE: 5215231,
    UF: "GO",
    nome_da_cidade: "NOVO GAMA",
  },
  {
    codigo_do_IGBE: 4313409,
    UF: "RS",
    nome_da_cidade: "NOVO HAMBURGO",
  },
  {
    codigo_do_IGBE: 2923035,
    UF: "BA",
    nome_da_cidade: "NOVO HORIZONTE",
  },
  {
    codigo_do_IGBE: 4211652,
    UF: "SC",
    nome_da_cidade: "NOVO HORIZONTE",
  },
  {
    codigo_do_IGBE: 5106273,
    UF: "MT",
    nome_da_cidade: "NOVO HORIZONTE DO NORTE",
  },
  {
    codigo_do_IGBE: 1100502,
    UF: "RO",
    nome_da_cidade: "NOVO HORIZONTE DO OESTE",
  },
  {
    codigo_do_IGBE: 5006259,
    UF: "MS",
    nome_da_cidade: "NOVO HORIZONTE DO SUL",
  },
  {
    codigo_do_IGBE: 4117297,
    UF: "PR",
    nome_da_cidade: "NOVO ITACOLOMI",
  },
  {
    codigo_do_IGBE: 1715259,
    UF: "TO",
    nome_da_cidade: "NOVO JARDIM",
  },
  {
    codigo_do_IGBE: 2705606,
    UF: "AL",
    nome_da_cidade: "NOVO LINO",
  },
  {
    codigo_do_IGBE: 4313425,
    UF: "RS",
    nome_da_cidade: "NOVO MACHADO",
  },
  {
    codigo_do_IGBE: 5106265,
    UF: "MT",
    nome_da_cidade: "NOVO MUNDO",
  },
  {
    codigo_do_IGBE: 2309409,
    UF: "CE",
    nome_da_cidade: "NOVO ORIENTE",
  },
  {
    codigo_do_IGBE: 2206902,
    UF: "PI",
    nome_da_cidade: "NOVO ORIENTE DO PIAUI",
  },
  {
    codigo_do_IGBE: 5215256,
    UF: "GO",
    nome_da_cidade: "NOVO PLANALTO",
  },
  {
    codigo_do_IGBE: 1505031,
    UF: "PA",
    nome_da_cidade: "NOVO PROGRESSO",
  },
  {
    codigo_do_IGBE: 1505064,
    UF: "PA",
    nome_da_cidade: "NOVO REPARTIMENTO",
  },
  {
    codigo_do_IGBE: 5106315,
    UF: "MT",
    nome_da_cidade: "NOVO SANTO ANTONIO",
  },
  {
    codigo_do_IGBE: 5106281,
    UF: "MT",
    nome_da_cidade: "NOVO SAO JOAQUIM",
  },
  {
    codigo_do_IGBE: 4313441,
    UF: "RS",
    nome_da_cidade: "NOVO TIRADENTES",
  },
  {
    codigo_do_IGBE: 2923050,
    UF: "BA",
    nome_da_cidade: "NOVO TRIUNFO",
  },
  {
    codigo_do_IGBE: 4313466,
    UF: "RS",
    nome_da_cidade: "NOVO XINGU",
  },
  {
    codigo_do_IGBE: 3145372,
    UF: "MG",
    nome_da_cidade: "NOVORIZONTE",
  },
  {
    codigo_do_IGBE: 3533601,
    UF: "SP",
    nome_da_cidade: "NUPORANGA",
  },
  {
    codigo_do_IGBE: 1505106,
    UF: "PA",
    nome_da_cidade: "OBIDOS",
  },
  {
    codigo_do_IGBE: 2309458,
    UF: "CE",
    nome_da_cidade: "OCARA",
  },
  {
    codigo_do_IGBE: 3533700,
    UF: "SP",
    nome_da_cidade: "OCAUCU",
  },
  {
    codigo_do_IGBE: 2207009,
    UF: "PI",
    nome_da_cidade: "OEIRAS",
  },
  {
    codigo_do_IGBE: 3533809,
    UF: "SP",
    nome_da_cidade: "OLEO",
  },
  {
    codigo_do_IGBE: 2510402,
    UF: "PB",
    nome_da_cidade: "OLHO D'AGUA",
  },
  {
    codigo_do_IGBE: 2107407,
    UF: "MA",
    nome_da_cidade: "OLHO D'AGUA DAS CUNHAS",
  },
  {
    codigo_do_IGBE: 2705705,
    UF: "AL",
    nome_da_cidade: "OLHO D'AGUA DAS FLORES",
  },
  {
    codigo_do_IGBE: 2705804,
    UF: "AL",
    nome_da_cidade: "OLHO D'AGUA DO CASADO",
  },
  {
    codigo_do_IGBE: 2207108,
    UF: "PI",
    nome_da_cidade: "OLHO D'AGUA DO PIAUI",
  },
  {
    codigo_do_IGBE: 2705903,
    UF: "AL",
    nome_da_cidade: "OLHO D'AGUA GRANDE",
  },
  {
    codigo_do_IGBE: 3145455,
    UF: "MG",
    nome_da_cidade: "OLHOS-D'AGUA",
  },
  {
    codigo_do_IGBE: 3533908,
    UF: "SP",
    nome_da_cidade: "OLIMPIA",
  },
  {
    codigo_do_IGBE: 2609600,
    UF: "PE",
    nome_da_cidade: "OLINDA",
  },
  {
    codigo_do_IGBE: 2107456,
    UF: "MA",
    nome_da_cidade: "OLINDA NOVA DO MARANHAO",
  },
  {
    codigo_do_IGBE: 2923100,
    UF: "BA",
    nome_da_cidade: "OLINDINA",
  },
  {
    codigo_do_IGBE: 2510501,
    UF: "PB",
    nome_da_cidade: "OLIVEDOS",
  },
  {
    codigo_do_IGBE: 3145604,
    UF: "MG",
    nome_da_cidade: "OLIVEIRA",
  },
  {
    codigo_do_IGBE: 1715507,
    UF: "TO",
    nome_da_cidade: "OLIVEIRA DE FATIMA",
  },
  {
    codigo_do_IGBE: 2923209,
    UF: "BA",
    nome_da_cidade: "OLIVEIRA DOS BREJINHOS",
  },
  {
    codigo_do_IGBE: 3145703,
    UF: "MG",
    nome_da_cidade: "OLIVEIRA FORTES",
  },
  {
    codigo_do_IGBE: 2706000,
    UF: "AL",
    nome_da_cidade: "OLIVENCA",
  },
  {
    codigo_do_IGBE: 3145802,
    UF: "MG",
    nome_da_cidade: "ONCA DE PITANGUI",
  },
  {
    codigo_do_IGBE: 3534005,
    UF: "SP",
    nome_da_cidade: "ONDA VERDE",
  },
  {
    codigo_do_IGBE: 3145851,
    UF: "MG",
    nome_da_cidade: "ORATORIOS",
  },
  {
    codigo_do_IGBE: 3534104,
    UF: "SP",
    nome_da_cidade: "ORIENTE",
  },
  {
    codigo_do_IGBE: 3534203,
    UF: "SP",
    nome_da_cidade: "ORINDIUVA",
  },
  {
    codigo_do_IGBE: 1505304,
    UF: "PA",
    nome_da_cidade: "ORIXIMINA",
  },
  {
    codigo_do_IGBE: 3534302,
    UF: "SP",
    nome_da_cidade: "ORLANDIA",
  },
  {
    codigo_do_IGBE: 4211702,
    UF: "SC",
    nome_da_cidade: "ORLEANS",
  },
  {
    codigo_do_IGBE: 2609709,
    UF: "PE",
    nome_da_cidade: "OROBO",
  },
  {
    codigo_do_IGBE: 2609808,
    UF: "PE",
    nome_da_cidade: "OROCO",
  },
  {
    codigo_do_IGBE: 2309508,
    UF: "CE",
    nome_da_cidade: "OROS",
  },
  {
    codigo_do_IGBE: 4117305,
    UF: "PR",
    nome_da_cidade: "ORTIGUEIRA",
  },
  {
    codigo_do_IGBE: 3534401,
    UF: "SP",
    nome_da_cidade: "OSASCO",
  },
  {
    codigo_do_IGBE: 3534500,
    UF: "SP",
    nome_da_cidade: "OSCAR BRESSANE",
  },
  {
    codigo_do_IGBE: 4313508,
    UF: "RS",
    nome_da_cidade: "OSORIO",
  },
  {
    codigo_do_IGBE: 3534609,
    UF: "SP",
    nome_da_cidade: "OSVALDO CRUZ",
  },
  {
    codigo_do_IGBE: 1505403,
    UF: "PA",
    nome_da_cidade: "OUREM",
  },
  {
    codigo_do_IGBE: 2923308,
    UF: "BA",
    nome_da_cidade: "OURICANGAS",
  },
  {
    codigo_do_IGBE: 1505437,
    UF: "PA",
    nome_da_cidade: "OURILANDIA DO NORTE",
  },
  {
    codigo_do_IGBE: 3534708,
    UF: "SP",
    nome_da_cidade: "OURINHOS",
  },
  {
    codigo_do_IGBE: 4117404,
    UF: "PR",
    nome_da_cidade: "OURIZONA",
  },
  {
    codigo_do_IGBE: 4211801,
    UF: "SC",
    nome_da_cidade: "OURO",
  },
  {
    codigo_do_IGBE: 2706109,
    UF: "AL",
    nome_da_cidade: "OURO BRANCO",
  },
  {
    codigo_do_IGBE: 3145901,
    UF: "MG",
    nome_da_cidade: "OURO BRANCO",
  },
  {
    codigo_do_IGBE: 2408508,
    UF: "RN",
    nome_da_cidade: "OURO BRANCO",
  },
  {
    codigo_do_IGBE: 3146008,
    UF: "MG",
    nome_da_cidade: "OURO FINO",
  },
  {
    codigo_do_IGBE: 3146107,
    UF: "MG",
    nome_da_cidade: "OURO PRETO",
  },
  {
    codigo_do_IGBE: 1100155,
    UF: "RO",
    nome_da_cidade: "OURO PRETO DO OESTE",
  },
  {
    codigo_do_IGBE: 2510600,
    UF: "PB",
    nome_da_cidade: "OURO VELHO",
  },
  {
    codigo_do_IGBE: 4211850,
    UF: "SC",
    nome_da_cidade: "OURO VERDE",
  },
  {
    codigo_do_IGBE: 3146206,
    UF: "MG",
    nome_da_cidade: "OURO VERDE DE MINAS",
  },
  {
    codigo_do_IGBE: 4117453,
    UF: "PR",
    nome_da_cidade: "OURO VERDE DO OESTE",
  },
  {
    codigo_do_IGBE: 3534757,
    UF: "SP",
    nome_da_cidade: "OUROESTE",
  },
  {
    codigo_do_IGBE: 2923357,
    UF: "BA",
    nome_da_cidade: "OUROLANDIA",
  },
  {
    codigo_do_IGBE: 5215504,
    UF: "GO",
    nome_da_cidade: "OUVIDOR",
  },
  {
    codigo_do_IGBE: 3534906,
    UF: "SP",
    nome_da_cidade: "PACAEMBU",
  },
  {
    codigo_do_IGBE: 1505486,
    UF: "PA",
    nome_da_cidade: "PACAJA",
  },
  {
    codigo_do_IGBE: 2309607,
    UF: "CE",
    nome_da_cidade: "PACAJUS",
  },
  {
    codigo_do_IGBE: 1400456,
    UF: "RR",
    nome_da_cidade: "PACARAIMA",
  },
  {
    codigo_do_IGBE: 2309706,
    UF: "CE",
    nome_da_cidade: "PACATUBA",
  },
  {
    codigo_do_IGBE: 2804904,
    UF: "SE",
    nome_da_cidade: "PACATUBA",
  },
  {
    codigo_do_IGBE: 2309805,
    UF: "CE",
    nome_da_cidade: "PACOTI",
  },
  {
    codigo_do_IGBE: 2309904,
    UF: "CE",
    nome_da_cidade: "PACUJA",
  },
  {
    codigo_do_IGBE: 3146255,
    UF: "MG",
    nome_da_cidade: "PADRE CARVALHO",
  },
  {
    codigo_do_IGBE: 2207207,
    UF: "PI",
    nome_da_cidade: "PADRE MARCOS",
  },
  {
    codigo_do_IGBE: 3146305,
    UF: "MG",
    nome_da_cidade: "PADRE PARAISO",
  },
  {
    codigo_do_IGBE: 2207306,
    UF: "PI",
    nome_da_cidade: "PAES LANDIM",
  },
  {
    codigo_do_IGBE: 3146552,
    UF: "MG",
    nome_da_cidade: "PAI PEDRO",
  },
  {
    codigo_do_IGBE: 4211876,
    UF: "SC",
    nome_da_cidade: "PAIAL",
  },
  {
    codigo_do_IGBE: 4117503,
    UF: "PR",
    nome_da_cidade: "PAICANDU",
  },
  {
    codigo_do_IGBE: 4313607,
    UF: "RS",
    nome_da_cidade: "PAIM FILHO",
  },
  {
    codigo_do_IGBE: 3146404,
    UF: "MG",
    nome_da_cidade: "PAINEIRAS",
  },
  {
    codigo_do_IGBE: 4211892,
    UF: "SC",
    nome_da_cidade: "PAINEL",
  },
  {
    codigo_do_IGBE: 3146503,
    UF: "MG",
    nome_da_cidade: "PAINS",
  },
  {
    codigo_do_IGBE: 2207355,
    UF: "PI",
    nome_da_cidade: "PAJEU DO PIAUI",
  },
  {
    codigo_do_IGBE: 2706208,
    UF: "AL",
    nome_da_cidade: "PALESTINA",
  },
  {
    codigo_do_IGBE: 3535002,
    UF: "SP",
    nome_da_cidade: "PALESTINA",
  },
  {
    codigo_do_IGBE: 1505494,
    UF: "PA",
    nome_da_cidade: "PALESTINA DO PARA",
  },
  {
    codigo_do_IGBE: 2310001,
    UF: "CE",
    nome_da_cidade: "PALHANO",
  },
  {
    codigo_do_IGBE: 4211900,
    UF: "SC",
    nome_da_cidade: "PALHOCA",
  },
  {
    codigo_do_IGBE: 3146701,
    UF: "MG",
    nome_da_cidade: "PALMA",
  },
  {
    codigo_do_IGBE: 4212007,
    UF: "SC",
    nome_da_cidade: "PALMA SOLA",
  },
  {
    codigo_do_IGBE: 2310100,
    UF: "CE",
    nome_da_cidade: "PALMACIA",
  },
  {
    codigo_do_IGBE: 2610004,
    UF: "PE",
    nome_da_cidade: "PALMARES",
  },
  {
    codigo_do_IGBE: 3535101,
    UF: "SP",
    nome_da_cidade: "PALMARES PAULISTA",
  },
  {
    codigo_do_IGBE: 4117602,
    UF: "PR",
    nome_da_cidade: "PALMAS",
  },
  {
    codigo_do_IGBE: 1721000,
    UF: "TO",
    nome_da_cidade: "PALMAS",
  },
  {
    codigo_do_IGBE: 2923407,
    UF: "BA",
    nome_da_cidade: "PALMAS DE MONTE ALTO",
  },
  {
    codigo_do_IGBE: 4117701,
    UF: "PR",
    nome_da_cidade: "PALMEIRA",
  },
  {
    codigo_do_IGBE: 4212056,
    UF: "SC",
    nome_da_cidade: "PALMEIRA",
  },
  {
    codigo_do_IGBE: 2207405,
    UF: "PI",
    nome_da_cidade: "PALMEIRA DO PIAUI",
  },
  {
    codigo_do_IGBE: 3535200,
    UF: "SP",
    nome_da_cidade: "PALMEIRA D'OESTE",
  },
  {
    codigo_do_IGBE: 2706307,
    UF: "AL",
    nome_da_cidade: "PALMEIRA DOS INDIOS",
  },
  {
    codigo_do_IGBE: 2207504,
    UF: "PI",
    nome_da_cidade: "PALMEIRAIS",
  },
  {
    codigo_do_IGBE: 2107605,
    UF: "MA",
    nome_da_cidade: "PALMEIRANDIA",
  },
  {
    codigo_do_IGBE: 1715705,
    UF: "TO",
    nome_da_cidade: "PALMEIRANTE",
  },
  {
    codigo_do_IGBE: 2923506,
    UF: "BA",
    nome_da_cidade: "PALMEIRAS",
  },
  {
    codigo_do_IGBE: 1713809,
    UF: "TO",
    nome_da_cidade: "PALMEIRAS DO TOCANTINS",
  },
  {
    codigo_do_IGBE: 2610103,
    UF: "PE",
    nome_da_cidade: "PALMEIRINA",
  },
  {
    codigo_do_IGBE: 1715754,
    UF: "TO",
    nome_da_cidade: "PALMEIROPOLIS",
  },
  {
    codigo_do_IGBE: 5215801,
    UF: "GO",
    nome_da_cidade: "PALMELO",
  },
  {
    codigo_do_IGBE: 5215900,
    UF: "GO",
    nome_da_cidade: "PALMINOPOLIS",
  },
  {
    codigo_do_IGBE: 4117800,
    UF: "PR",
    nome_da_cidade: "PALMITAL",
  },
  {
    codigo_do_IGBE: 3535309,
    UF: "SP",
    nome_da_cidade: "PALMITAL",
  },
  {
    codigo_do_IGBE: 4313805,
    UF: "RS",
    nome_da_cidade: "PALMITINHO",
  },
  {
    codigo_do_IGBE: 4212106,
    UF: "SC",
    nome_da_cidade: "PALMITOS",
  },
  {
    codigo_do_IGBE: 4117909,
    UF: "PR",
    nome_da_cidade: "PALOTINA",
  },
  {
    codigo_do_IGBE: 5216007,
    UF: "GO",
    nome_da_cidade: "PANAMA",
  },
  {
    codigo_do_IGBE: 4313904,
    UF: "RS",
    nome_da_cidade: "PANAMBI",
  },
  {
    codigo_do_IGBE: 3204005,
    UF: "ES",
    nome_da_cidade: "PANCAS",
  },
  {
    codigo_do_IGBE: 2610202,
    UF: "PE",
    nome_da_cidade: "PANELAS",
  },
  {
    codigo_do_IGBE: 4313953,
    UF: "RS",
    nome_da_cidade: "PANTANO GRANDE",
  },
  {
    codigo_do_IGBE: 2706406,
    UF: "AL",
    nome_da_cidade: "PAO DE ACUCAR",
  },
  {
    codigo_do_IGBE: 3146909,
    UF: "MG",
    nome_da_cidade: "PAPAGAIOS",
  },
  {
    codigo_do_IGBE: 4212205,
    UF: "SC",
    nome_da_cidade: "PAPANDUVA",
  },
  {
    codigo_do_IGBE: 2207553,
    UF: "PI",
    nome_da_cidade: "PAQUETA",
  },
  {
    codigo_do_IGBE: 3303609,
    UF: "RJ",
    nome_da_cidade: "PARACAMBI",
  },
  {
    codigo_do_IGBE: 3147006,
    UF: "MG",
    nome_da_cidade: "PARACATU",
  },
  {
    codigo_do_IGBE: 2310209,
    UF: "CE",
    nome_da_cidade: "PARACURU",
  },
  {
    codigo_do_IGBE: 1505502,
    UF: "PA",
    nome_da_cidade: "PARAGOMINAS",
  },
  {
    codigo_do_IGBE: 3147204,
    UF: "MG",
    nome_da_cidade: "PARAGUACU",
  },
  {
    codigo_do_IGBE: 4314001,
    UF: "RS",
    nome_da_cidade: "PARAI",
  },
  {
    codigo_do_IGBE: 3303708,
    UF: "RJ",
    nome_da_cidade: "PARAIBA DO SUL",
  },
  {
    codigo_do_IGBE: 2107704,
    UF: "MA",
    nome_da_cidade: "PARAIBANO",
  },
  {
    codigo_do_IGBE: 3535606,
    UF: "SP",
    nome_da_cidade: "PARAIBUNA",
  },
  {
    codigo_do_IGBE: 2310258,
    UF: "CE",
    nome_da_cidade: "PARAIPABA",
  },
  {
    codigo_do_IGBE: 4212239,
    UF: "SC",
    nome_da_cidade: "PARAISO",
  },
  {
    codigo_do_IGBE: 3535705,
    UF: "SP",
    nome_da_cidade: "PARAISO",
  },
  {
    codigo_do_IGBE: 4118006,
    UF: "PR",
    nome_da_cidade: "PARAISO DO NORTE",
  },
  {
    codigo_do_IGBE: 4314027,
    UF: "RS",
    nome_da_cidade: "PARAISO DO SUL",
  },
  {
    codigo_do_IGBE: 2310308,
    UF: "CE",
    nome_da_cidade: "PARAMBU",
  },
  {
    codigo_do_IGBE: 2310407,
    UF: "CE",
    nome_da_cidade: "PARAMOTI",
  },
  {
    codigo_do_IGBE: 2408607,
    UF: "RN",
    nome_da_cidade: "PARANA",
  },
  {
    codigo_do_IGBE: 1716208,
    UF: "TO",
    nome_da_cidade: "PARANA",
  },
  {
    codigo_do_IGBE: 4118105,
    UF: "PR",
    nome_da_cidade: "PARANACITY",
  },
  {
    codigo_do_IGBE: 4118204,
    UF: "PR",
    nome_da_cidade: "PARANAGUA",
  },
  {
    codigo_do_IGBE: 5006309,
    UF: "MS",
    nome_da_cidade: "PARANAIBA",
  },
  {
    codigo_do_IGBE: 5216304,
    UF: "GO",
    nome_da_cidade: "PARANAIGUARA",
  },
  {
    codigo_do_IGBE: 5106299,
    UF: "MT",
    nome_da_cidade: "PARANAITA",
  },
  {
    codigo_do_IGBE: 3535804,
    UF: "SP",
    nome_da_cidade: "PARANAPANEMA",
  },
  {
    codigo_do_IGBE: 4118303,
    UF: "PR",
    nome_da_cidade: "PARANAPOEMA",
  },
  {
    codigo_do_IGBE: 2610301,
    UF: "PE",
    nome_da_cidade: "PARANATAMA",
  },
  {
    codigo_do_IGBE: 4118402,
    UF: "PR",
    nome_da_cidade: "PARANAVAI",
  },
  {
    codigo_do_IGBE: 5006358,
    UF: "MS",
    nome_da_cidade: "PARANHOS",
  },
  {
    codigo_do_IGBE: 3147402,
    UF: "MG",
    nome_da_cidade: "PARAOPEBA",
  },
  {
    codigo_do_IGBE: 3536000,
    UF: "SP",
    nome_da_cidade: "PARAPUA",
  },
  {
    codigo_do_IGBE: 2510659,
    UF: "PB",
    nome_da_cidade: "PARARI",
  },
  {
    codigo_do_IGBE: 3303807,
    UF: "RJ",
    nome_da_cidade: "PARATI",
  },
  {
    codigo_do_IGBE: 2923704,
    UF: "BA",
    nome_da_cidade: "PARATINGA",
  },
  {
    codigo_do_IGBE: 2408706,
    UF: "RN",
    nome_da_cidade: "PARAU",
  },
  {
    codigo_do_IGBE: 1505536,
    UF: "PA",
    nome_da_cidade: "PARAUAPEBAS",
  },
  {
    codigo_do_IGBE: 5216403,
    UF: "GO",
    nome_da_cidade: "PARAUNA",
  },
  {
    codigo_do_IGBE: 3536109,
    UF: "SP",
    nome_da_cidade: "PARDINHO",
  },
  {
    codigo_do_IGBE: 1101450,
    UF: "RO",
    nome_da_cidade: "PARECIS",
  },
  {
    codigo_do_IGBE: 2408904,
    UF: "RN",
    nome_da_cidade: "PARELHAS",
  },
  {
    codigo_do_IGBE: 2706422,
    UF: "AL",
    nome_da_cidade: "PARICONHA",
  },
  {
    codigo_do_IGBE: 1303403,
    UF: "AM",
    nome_da_cidade: "PARINTINS",
  },
  {
    codigo_do_IGBE: 2923803,
    UF: "BA",
    nome_da_cidade: "PARIPIRANGA",
  },
  {
    codigo_do_IGBE: 2706448,
    UF: "AL",
    nome_da_cidade: "PARIPUEIRA",
  },
  {
    codigo_do_IGBE: 3536208,
    UF: "SP",
    nome_da_cidade: "PARIQUERA-ACU",
  },
  {
    codigo_do_IGBE: 3536257,
    UF: "SP",
    nome_da_cidade: "PARISI",
  },
  {
    codigo_do_IGBE: 2207603,
    UF: "PI",
    nome_da_cidade: "PARNAGUA",
  },
  {
    codigo_do_IGBE: 2207702,
    UF: "PI",
    nome_da_cidade: "PARNAIBA",
  },
  {
    codigo_do_IGBE: 2610400,
    UF: "PE",
    nome_da_cidade: "PARNAMIRIM",
  },
  {
    codigo_do_IGBE: 4314050,
    UF: "RS",
    nome_da_cidade: "PAROBE",
  },
  {
    codigo_do_IGBE: 2409100,
    UF: "RN",
    nome_da_cidade: "PASSA E FICA",
  },
  {
    codigo_do_IGBE: 3147600,
    UF: "MG",
    nome_da_cidade: "PASSA QUATRO",
  },
  {
    codigo_do_IGBE: 4314068,
    UF: "RS",
    nome_da_cidade: "PASSA SETE",
  },
  {
    codigo_do_IGBE: 3147709,
    UF: "MG",
    nome_da_cidade: "PASSA TEMPO",
  },
  {
    codigo_do_IGBE: 3147501,
    UF: "MG",
    nome_da_cidade: "PASSABEM",
  },
  {
    codigo_do_IGBE: 2510709,
    UF: "PB",
    nome_da_cidade: "PASSAGEM",
  },
  {
    codigo_do_IGBE: 2409209,
    UF: "RN",
    nome_da_cidade: "PASSAGEM",
  },
  {
    codigo_do_IGBE: 2107902,
    UF: "MA",
    nome_da_cidade: "PASSAGEM FRANCA",
  },
  {
    codigo_do_IGBE: 3147808,
    UF: "MG",
    nome_da_cidade: "PASSA-VINTE",
  },
  {
    codigo_do_IGBE: 2610509,
    UF: "PE",
    nome_da_cidade: "PASSIRA",
  },
  {
    codigo_do_IGBE: 4212254,
    UF: "SC",
    nome_da_cidade: "PASSO DE TORRES",
  },
  {
    codigo_do_IGBE: 4314076,
    UF: "RS",
    nome_da_cidade: "PASSO DO SOBRADO",
  },
  {
    codigo_do_IGBE: 4314100,
    UF: "RS",
    nome_da_cidade: "PASSO FUNDO",
  },
  {
    codigo_do_IGBE: 3147907,
    UF: "MG",
    nome_da_cidade: "PASSOS",
  },
  {
    codigo_do_IGBE: 4212270,
    UF: "SC",
    nome_da_cidade: "PASSOS MAIA",
  },
  {
    codigo_do_IGBE: 2108009,
    UF: "MA",
    nome_da_cidade: "PASTOS BONS",
  },
  {
    codigo_do_IGBE: 3147956,
    UF: "MG",
    nome_da_cidade: "PATIS",
  },
  {
    codigo_do_IGBE: 4118451,
    UF: "PR",
    nome_da_cidade: "PATO BRAGADO",
  },
  {
    codigo_do_IGBE: 4118501,
    UF: "PR",
    nome_da_cidade: "PATO BRANCO",
  },
  {
    codigo_do_IGBE: 2510808,
    UF: "PB",
    nome_da_cidade: "PATOS",
  },
  {
    codigo_do_IGBE: 3148004,
    UF: "MG",
    nome_da_cidade: "PATOS DE MINAS",
  },
  {
    codigo_do_IGBE: 2207777,
    UF: "PI",
    nome_da_cidade: "PATOS DO PIAUI",
  },
  {
    codigo_do_IGBE: 3148103,
    UF: "MG",
    nome_da_cidade: "PATROCINIO",
  },
  {
    codigo_do_IGBE: 2409308,
    UF: "RN",
    nome_da_cidade: "PATU",
  },
  {
    codigo_do_IGBE: 3303856,
    UF: "RJ",
    nome_da_cidade: "PATY DO ALFERES",
  },
  {
    codigo_do_IGBE: 2923902,
    UF: "BA",
    nome_da_cidade: "PAU BRASIL",
  },
  {
    codigo_do_IGBE: 1505551,
    UF: "PA",
    nome_da_cidade: "PAU D'ARCO",
  },
  {
    codigo_do_IGBE: 1716307,
    UF: "TO",
    nome_da_cidade: "PAU D'ARCO",
  },
  {
    codigo_do_IGBE: 2207793,
    UF: "PI",
    nome_da_cidade: "PAU D'ARCO DO PIAUI",
  },
  {
    codigo_do_IGBE: 2409407,
    UF: "RN",
    nome_da_cidade: "PAU DOS FERROS",
  },
  {
    codigo_do_IGBE: 2610608,
    UF: "PE",
    nome_da_cidade: "PAUDALHO",
  },
  {
    codigo_do_IGBE: 1303502,
    UF: "AM",
    nome_da_cidade: "PAUINI",
  },
  {
    codigo_do_IGBE: 3148301,
    UF: "MG",
    nome_da_cidade: "PAULA CANDIDO",
  },
  {
    codigo_do_IGBE: 4118600,
    UF: "PR",
    nome_da_cidade: "PAULA FREITAS",
  },
  {
    codigo_do_IGBE: 3536406,
    UF: "SP",
    nome_da_cidade: "PAULICEIA",
  },
  {
    codigo_do_IGBE: 3536505,
    UF: "SP",
    nome_da_cidade: "PAULINIA",
  },
  {
    codigo_do_IGBE: 2108058,
    UF: "MA",
    nome_da_cidade: "PAULINO NEVES",
  },
  {
    codigo_do_IGBE: 2510907,
    UF: "PB",
    nome_da_cidade: "PAULISTA",
  },
  {
    codigo_do_IGBE: 2610707,
    UF: "PE",
    nome_da_cidade: "PAULISTA",
  },
  {
    codigo_do_IGBE: 3148400,
    UF: "MG",
    nome_da_cidade: "PAULISTAS",
  },
  {
    codigo_do_IGBE: 2924009,
    UF: "BA",
    nome_da_cidade: "PAULO AFONSO",
  },
  {
    codigo_do_IGBE: 4314134,
    UF: "RS",
    nome_da_cidade: "PAULO BENTO",
  },
  {
    codigo_do_IGBE: 3536604,
    UF: "SP",
    nome_da_cidade: "PAULO DE FARIA",
  },
  {
    codigo_do_IGBE: 4118709,
    UF: "PR",
    nome_da_cidade: "PAULO FRONTIN",
  },
  {
    codigo_do_IGBE: 2706604,
    UF: "AL",
    nome_da_cidade: "PAULO JACINTO",
  },
  {
    codigo_do_IGBE: 4212304,
    UF: "SC",
    nome_da_cidade: "PAULO LOPES",
  },
  {
    codigo_do_IGBE: 2108108,
    UF: "MA",
    nome_da_cidade: "PAULO RAMOS",
  },
  {
    codigo_do_IGBE: 3148509,
    UF: "MG",
    nome_da_cidade: "PAVAO",
  },
  {
    codigo_do_IGBE: 4314159,
    UF: "RS",
    nome_da_cidade: "PAVERAMA",
  },
  {
    codigo_do_IGBE: 2207850,
    UF: "PI",
    nome_da_cidade: "PAVUSSU",
  },
  {
    codigo_do_IGBE: 4118808,
    UF: "PR",
    nome_da_cidade: "PEABIRU",
  },
  {
    codigo_do_IGBE: 3536703,
    UF: "SP",
    nome_da_cidade: "PEDERNEIRAS",
  },
  {
    codigo_do_IGBE: 2610806,
    UF: "PE",
    nome_da_cidade: "PEDRA",
  },
  {
    codigo_do_IGBE: 3148707,
    UF: "MG",
    nome_da_cidade: "PEDRA AZUL",
  },
  {
    codigo_do_IGBE: 3536802,
    UF: "SP",
    nome_da_cidade: "PEDRA BELA",
  },
  {
    codigo_do_IGBE: 3148756,
    UF: "MG",
    nome_da_cidade: "PEDRA BONITA",
  },
  {
    codigo_do_IGBE: 2310506,
    UF: "CE",
    nome_da_cidade: "PEDRA BRANCA",
  },
  {
    codigo_do_IGBE: 2511004,
    UF: "PB",
    nome_da_cidade: "PEDRA BRANCA",
  },
  {
    codigo_do_IGBE: 3148806,
    UF: "MG",
    nome_da_cidade: "PEDRA DO ANTA",
  },
  {
    codigo_do_IGBE: 3148905,
    UF: "MG",
    nome_da_cidade: "PEDRA DO INDAIA",
  },
  {
    codigo_do_IGBE: 3149002,
    UF: "MG",
    nome_da_cidade: "PEDRA DOURADA",
  },
  {
    codigo_do_IGBE: 2409506,
    UF: "RN",
    nome_da_cidade: "PEDRA GRANDE",
  },
  {
    codigo_do_IGBE: 2805000,
    UF: "SE",
    nome_da_cidade: "PEDRA MOLE",
  },
  {
    codigo_do_IGBE: 5106372,
    UF: "MT",
    nome_da_cidade: "PEDRA PRETA",
  },
  {
    codigo_do_IGBE: 2409605,
    UF: "RN",
    nome_da_cidade: "PEDRA PRETA",
  },
  {
    codigo_do_IGBE: 3149101,
    UF: "MG",
    nome_da_cidade: "PEDRALVA",
  },
  {
    codigo_do_IGBE: 3536901,
    UF: "SP",
    nome_da_cidade: "PEDRANOPOLIS",
  },
  {
    codigo_do_IGBE: 2924108,
    UF: "BA",
    nome_da_cidade: "PEDRAO",
  },
  {
    codigo_do_IGBE: 4314175,
    UF: "RS",
    nome_da_cidade: "PEDRAS ALTAS",
  },
  {
    codigo_do_IGBE: 2511202,
    UF: "PB",
    nome_da_cidade: "PEDRAS DE FOGO",
  },
  {
    codigo_do_IGBE: 3149150,
    UF: "MG",
    nome_da_cidade: "PEDRAS DE MARIA DA CRUZ",
  },
  {
    codigo_do_IGBE: 4212403,
    UF: "SC",
    nome_da_cidade: "PEDRAS GRANDES",
  },
  {
    codigo_do_IGBE: 3537008,
    UF: "SP",
    nome_da_cidade: "PEDREGULHO",
  },
  {
    codigo_do_IGBE: 3537107,
    UF: "SP",
    nome_da_cidade: "PEDREIRA",
  },
  {
    codigo_do_IGBE: 2108207,
    UF: "MA",
    nome_da_cidade: "PEDREIRAS",
  },
  {
    codigo_do_IGBE: 2805109,
    UF: "SE",
    nome_da_cidade: "PEDRINHAS",
  },
  {
    codigo_do_IGBE: 3149200,
    UF: "MG",
    nome_da_cidade: "PEDRINOPOLIS",
  },
  {
    codigo_do_IGBE: 2924207,
    UF: "BA",
    nome_da_cidade: "PEDRO ALEXANDRE",
  },
  {
    codigo_do_IGBE: 2409704,
    UF: "RN",
    nome_da_cidade: "PEDRO AVELINO",
  },
  {
    codigo_do_IGBE: 3204054,
    UF: "ES",
    nome_da_cidade: "PEDRO CANARIO",
  },
  {
    codigo_do_IGBE: 3537206,
    UF: "SP",
    nome_da_cidade: "PEDRO DE TOLEDO",
  },
  {
    codigo_do_IGBE: 2108256,
    UF: "MA",
    nome_da_cidade: "PEDRO DO ROSARIO",
  },
  {
    codigo_do_IGBE: 5006408,
    UF: "MS",
    nome_da_cidade: "PEDRO GOMES",
  },
  {
    codigo_do_IGBE: 2207900,
    UF: "PI",
    nome_da_cidade: "PEDRO II",
  },
  {
    codigo_do_IGBE: 2207934,
    UF: "PI",
    nome_da_cidade: "PEDRO LAURENTINO",
  },
  {
    codigo_do_IGBE: 3149309,
    UF: "MG",
    nome_da_cidade: "PEDRO LEOPOLDO",
  },
  {
    codigo_do_IGBE: 4314209,
    UF: "RS",
    nome_da_cidade: "PEDRO OSORIO",
  },
  {
    codigo_do_IGBE: 2512721,
    UF: "PB",
    nome_da_cidade: "PEDRO REGIS",
  },
  {
    codigo_do_IGBE: 2409803,
    UF: "RN",
    nome_da_cidade: "PEDRO VELHO",
  },
  {
    codigo_do_IGBE: 1505601,
    UF: "PA",
    nome_da_cidade: "PEIXE-BOI",
  },
  {
    codigo_do_IGBE: 5106422,
    UF: "MT",
    nome_da_cidade: "PEIXOTO DE AZEVEDO",
  },
  {
    codigo_do_IGBE: 4314308,
    UF: "RS",
    nome_da_cidade: "PEJUCARA",
  },
  {
    codigo_do_IGBE: 4314407,
    UF: "RS",
    nome_da_cidade: "PELOTAS",
  },
  {
    codigo_do_IGBE: 2310605,
    UF: "CE",
    nome_da_cidade: "PENAFORTE",
  },
  {
    codigo_do_IGBE: 3537305,
    UF: "SP",
    nome_da_cidade: "PENAPOLIS",
  },
  {
    codigo_do_IGBE: 2409902,
    UF: "RN",
    nome_da_cidade: "PENDENCIAS",
  },
  {
    codigo_do_IGBE: 2706703,
    UF: "AL",
    nome_da_cidade: "PENEDO",
  },
  {
    codigo_do_IGBE: 4212502,
    UF: "SC",
    nome_da_cidade: "PENHA",
  },
  {
    codigo_do_IGBE: 2310704,
    UF: "CE",
    nome_da_cidade: "PENTECOSTE",
  },
  {
    codigo_do_IGBE: 3149507,
    UF: "MG",
    nome_da_cidade: "PEQUERI",
  },
  {
    codigo_do_IGBE: 3149606,
    UF: "MG",
    nome_da_cidade: "PEQUI",
  },
  {
    codigo_do_IGBE: 3149705,
    UF: "MG",
    nome_da_cidade: "PERDIGAO",
  },
  {
    codigo_do_IGBE: 3149804,
    UF: "MG",
    nome_da_cidade: "PERDIZES",
  },
  {
    codigo_do_IGBE: 3537404,
    UF: "SP",
    nome_da_cidade: "PEREIRA BARRETO",
  },
  {
    codigo_do_IGBE: 3537503,
    UF: "SP",
    nome_da_cidade: "PEREIRAS",
  },
  {
    codigo_do_IGBE: 2310803,
    UF: "CE",
    nome_da_cidade: "PEREIRO",
  },
  {
    codigo_do_IGBE: 2108405,
    UF: "MA",
    nome_da_cidade: "PERI MIRIM",
  },
  {
    codigo_do_IGBE: 3149952,
    UF: "MG",
    nome_da_cidade: "PERIQUITO",
  },
  {
    codigo_do_IGBE: 4212601,
    UF: "SC",
    nome_da_cidade: "PERITIBA",
  },
  {
    codigo_do_IGBE: 2108454,
    UF: "MA",
    nome_da_cidade: "PERITORO",
  },
  {
    codigo_do_IGBE: 4118857,
    UF: "PR",
    nome_da_cidade: "PEROBAL",
  },
  {
    codigo_do_IGBE: 4118907,
    UF: "PR",
    nome_da_cidade: "PEROLA",
  },
  {
    codigo_do_IGBE: 5216452,
    UF: "GO",
    nome_da_cidade: "PEROLANDIA",
  },
  {
    codigo_do_IGBE: 3537602,
    UF: "SP",
    nome_da_cidade: "PERUIBE",
  },
  {
    codigo_do_IGBE: 2610905,
    UF: "PE",
    nome_da_cidade: "PESQUEIRA",
  },
  {
    codigo_do_IGBE: 4212700,
    UF: "SC",
    nome_da_cidade: "PETROLANDIA",
  },
  {
    codigo_do_IGBE: 2611101,
    UF: "PE",
    nome_da_cidade: "PETROLINA",
  },
  {
    codigo_do_IGBE: 3303906,
    UF: "RJ",
    nome_da_cidade: "PETROPOLIS",
  },
  {
    codigo_do_IGBE: 2706802,
    UF: "AL",
    nome_da_cidade: "PIACABUCU",
  },
  {
    codigo_do_IGBE: 3537701,
    UF: "SP",
    nome_da_cidade: "PIACATU",
  },
  {
    codigo_do_IGBE: 2511301,
    UF: "PB",
    nome_da_cidade: "PIANCO",
  },
  {
    codigo_do_IGBE: 2924306,
    UF: "BA",
    nome_da_cidade: "PIATA",
  },
  {
    codigo_do_IGBE: 3150109,
    UF: "MG",
    nome_da_cidade: "PIAU",
  },
  {
    codigo_do_IGBE: 4314423,
    UF: "RS",
    nome_da_cidade: "PICADA CAFE",
  },
  {
    codigo_do_IGBE: 2208007,
    UF: "PI",
    nome_da_cidade: "PICOS",
  },
  {
    codigo_do_IGBE: 2511400,
    UF: "PB",
    nome_da_cidade: "PICUI",
  },
  {
    codigo_do_IGBE: 3537800,
    UF: "SP",
    nome_da_cidade: "PIEDADE",
  },
  {
    codigo_do_IGBE: 3150158,
    UF: "MG",
    nome_da_cidade: "PIEDADE DE CARATINGA",
  },
  {
    codigo_do_IGBE: 2707008,
    UF: "AL",
    nome_da_cidade: "PINDOBA",
  },
  {
    codigo_do_IGBE: 2924603,
    UF: "BA",
    nome_da_cidade: "PINDOBACU",
  },
  {
    codigo_do_IGBE: 3538105,
    UF: "SP",
    nome_da_cidade: "PINDORAMA",
  },
  {
    codigo_do_IGBE: 2310852,
    UF: "CE",
    nome_da_cidade: "PINDORETAMA",
  },
  {
    codigo_do_IGBE: 3150539,
    UF: "MG",
    nome_da_cidade: "PINGO-D'AGUA",
  },
  {
    codigo_do_IGBE: 4119152,
    UF: "PR",
    nome_da_cidade: "PINHAIS",
  },
  {
    codigo_do_IGBE: 4314456,
    UF: "RS",
    nome_da_cidade: "PINHAL",
  },
  {
    codigo_do_IGBE: 4314464,
    UF: "RS",
    nome_da_cidade: "PINHAL DA SERRA",
  },
  {
    codigo_do_IGBE: 4119251,
    UF: "PR",
    nome_da_cidade: "PINHAL DE SAO BENTO",
  },
  {
    codigo_do_IGBE: 4314472,
    UF: "RS",
    nome_da_cidade: "PINHAL GRANDE",
  },
  {
    codigo_do_IGBE: 4119202,
    UF: "PR",
    nome_da_cidade: "PINHALAO",
  },
  {
    codigo_do_IGBE: 4212908,
    UF: "SC",
    nome_da_cidade: "PINHALZINHO",
  },
  {
    codigo_do_IGBE: 3538204,
    UF: "SP",
    nome_da_cidade: "PINHALZINHO",
  },
  {
    codigo_do_IGBE: 4119301,
    UF: "PR",
    nome_da_cidade: "PINHAO",
  },
  {
    codigo_do_IGBE: 2805208,
    UF: "SE",
    nome_da_cidade: "PINHAO",
  },
  {
    codigo_do_IGBE: 3303955,
    UF: "RJ",
    nome_da_cidade: "PINHEIRAL",
  },
  {
    codigo_do_IGBE: 4314498,
    UF: "RS",
    nome_da_cidade: "PINHEIRINHO DO VALE",
  },
  {
    codigo_do_IGBE: 2108603,
    UF: "MA",
    nome_da_cidade: "PINHEIRO",
  },
  {
    codigo_do_IGBE: 4213005,
    UF: "SC",
    nome_da_cidade: "PINHEIRO PRETO",
  },
  {
    codigo_do_IGBE: 3204104,
    UF: "ES",
    nome_da_cidade: "PINHEIROS",
  },
  {
    codigo_do_IGBE: 2924652,
    UF: "BA",
    nome_da_cidade: "PINTADAS",
  },
  {
    codigo_do_IGBE: 4314548,
    UF: "RS",
    nome_da_cidade: "PINTO BANDEIRA",
  },
  {
    codigo_do_IGBE: 3150570,
    UF: "MG",
    nome_da_cidade: "PINTOPOLIS",
  },
  {
    codigo_do_IGBE: 2208205,
    UF: "PI",
    nome_da_cidade: "PIO IX",
  },
  {
    codigo_do_IGBE: 2108702,
    UF: "MA",
    nome_da_cidade: "PIO XII",
  },
  {
    codigo_do_IGBE: 3538303,
    UF: "SP",
    nome_da_cidade: "PIQUEROBI",
  },
  {
    codigo_do_IGBE: 2310902,
    UF: "CE",
    nome_da_cidade: "PIQUET CARNEIRO",
  },
  {
    codigo_do_IGBE: 3538501,
    UF: "SP",
    nome_da_cidade: "PIQUETE",
  },
  {
    codigo_do_IGBE: 3538600,
    UF: "SP",
    nome_da_cidade: "PIRACAIA",
  },
  {
    codigo_do_IGBE: 5217104,
    UF: "GO",
    nome_da_cidade: "PIRACANJUBA",
  },
  {
    codigo_do_IGBE: 3150604,
    UF: "MG",
    nome_da_cidade: "PIRACEMA",
  },
  {
    codigo_do_IGBE: 3538709,
    UF: "SP",
    nome_da_cidade: "PIRACICABA",
  },
  {
    codigo_do_IGBE: 2208304,
    UF: "PI",
    nome_da_cidade: "PIRACURUCA",
  },
  {
    codigo_do_IGBE: 3304003,
    UF: "RJ",
    nome_da_cidade: "PIRAI",
  },
  {
    codigo_do_IGBE: 4119400,
    UF: "PR",
    nome_da_cidade: "PIRAI DO SUL",
  },
  {
    codigo_do_IGBE: 3538808,
    UF: "SP",
    nome_da_cidade: "PIRAJU",
  },
  {
    codigo_do_IGBE: 3150703,
    UF: "MG",
    nome_da_cidade: "PIRAJUBA",
  },
  {
    codigo_do_IGBE: 3538907,
    UF: "SP",
    nome_da_cidade: "PIRAJUI",
  },
  {
    codigo_do_IGBE: 2805307,
    UF: "SE",
    nome_da_cidade: "PIRAMBU",
  },
  {
    codigo_do_IGBE: 3150802,
    UF: "MG",
    nome_da_cidade: "PIRANGA",
  },
  {
    codigo_do_IGBE: 3539004,
    UF: "SP",
    nome_da_cidade: "PIRANGI",
  },
  {
    codigo_do_IGBE: 3150901,
    UF: "MG",
    nome_da_cidade: "PIRANGUCU",
  },
  {
    codigo_do_IGBE: 3151008,
    UF: "MG",
    nome_da_cidade: "PIRANGUINHO",
  },
  {
    codigo_do_IGBE: 2707107,
    UF: "AL",
    nome_da_cidade: "PIRANHAS",
  },
  {
    codigo_do_IGBE: 5217203,
    UF: "GO",
    nome_da_cidade: "PIRANHAS",
  },
  {
    codigo_do_IGBE: 2108801,
    UF: "MA",
    nome_da_cidade: "PIRAPEMAS",
  },
  {
    codigo_do_IGBE: 3151107,
    UF: "MG",
    nome_da_cidade: "PIRAPETINGA",
  },
  {
    codigo_do_IGBE: 4314555,
    UF: "RS",
    nome_da_cidade: "PIRAPO",
  },
  {
    codigo_do_IGBE: 3151206,
    UF: "MG",
    nome_da_cidade: "PIRAPORA",
  },
  {
    codigo_do_IGBE: 3539202,
    UF: "SP",
    nome_da_cidade: "PIRAPOZINHO",
  },
  {
    codigo_do_IGBE: 4119509,
    UF: "PR",
    nome_da_cidade: "PIRAQUARA",
  },
  {
    codigo_do_IGBE: 1717206,
    UF: "TO",
    nome_da_cidade: "PIRAQUE",
  },
  {
    codigo_do_IGBE: 3539301,
    UF: "SP",
    nome_da_cidade: "PIRASSUNUNGA",
  },
  {
    codigo_do_IGBE: 4314605,
    UF: "RS",
    nome_da_cidade: "PIRATINI",
  },
  {
    codigo_do_IGBE: 3539400,
    UF: "SP",
    nome_da_cidade: "PIRATININGA",
  },
  {
    codigo_do_IGBE: 4213104,
    UF: "SC",
    nome_da_cidade: "PIRATUBA",
  },
  {
    codigo_do_IGBE: 3151305,
    UF: "MG",
    nome_da_cidade: "PIRAUBA",
  },
  {
    codigo_do_IGBE: 5217302,
    UF: "GO",
    nome_da_cidade: "PIRENOPOLIS",
  },
  {
    codigo_do_IGBE: 5217401,
    UF: "GO",
    nome_da_cidade: "PIRES DO RIO",
  },
  {
    codigo_do_IGBE: 2310951,
    UF: "CE",
    nome_da_cidade: "PIRES FERREIRA",
  },
  {
    codigo_do_IGBE: 2924702,
    UF: "BA",
    nome_da_cidade: "PIRIPA",
  },
  {
    codigo_do_IGBE: 2208403,
    UF: "PI",
    nome_da_cidade: "PIRIPIRI",
  },
  {
    codigo_do_IGBE: 2924801,
    UF: "BA",
    nome_da_cidade: "PIRITIBA",
  },
  {
    codigo_do_IGBE: 2511806,
    UF: "PB",
    nome_da_cidade: "PIRPIRITUBA",
  },
  {
    codigo_do_IGBE: 4119608,
    UF: "PR",
    nome_da_cidade: "PITANGA",
  },
  {
    codigo_do_IGBE: 3539509,
    UF: "SP",
    nome_da_cidade: "PITANGUEIRAS",
  },
  {
    codigo_do_IGBE: 3151404,
    UF: "MG",
    nome_da_cidade: "PITANGUI",
  },
  {
    codigo_do_IGBE: 2511905,
    UF: "PB",
    nome_da_cidade: "PITIMBU",
  },
  {
    codigo_do_IGBE: 1717503,
    UF: "TO",
    nome_da_cidade: "PIUM",
  },
  {
    codigo_do_IGBE: 3204203,
    UF: "ES",
    nome_da_cidade: "PIUMA",
  },
  {
    codigo_do_IGBE: 3151503,
    UF: "MG",
    nome_da_cidade: "PIUMHI",
  },
  {
    codigo_do_IGBE: 1505650,
    UF: "PA",
    nome_da_cidade: "PLACAS",
  },
  {
    codigo_do_IGBE: 1200385,
    UF: "AC",
    nome_da_cidade: "PLACIDO DE CASTRO",
  },
  {
    codigo_do_IGBE: 5217609,
    UF: "GO",
    nome_da_cidade: "PLANALTINA",
  },
  {
    codigo_do_IGBE: 4119707,
    UF: "PR",
    nome_da_cidade: "PLANALTINA DO PARANA",
  },
  {
    codigo_do_IGBE: 2924900,
    UF: "BA",
    nome_da_cidade: "PLANALTINO",
  },
  {
    codigo_do_IGBE: 2925006,
    UF: "BA",
    nome_da_cidade: "PLANALTO",
  },
  {
    codigo_do_IGBE: 4314704,
    UF: "RS",
    nome_da_cidade: "PLANALTO",
  },
  {
    codigo_do_IGBE: 3539608,
    UF: "SP",
    nome_da_cidade: "PLANALTO",
  },
  {
    codigo_do_IGBE: 4213153,
    UF: "SC",
    nome_da_cidade: "PLANALTO ALEGRE",
  },
  {
    codigo_do_IGBE: 5106455,
    UF: "MT",
    nome_da_cidade: "PLANALTO DA SERRA",
  },
  {
    codigo_do_IGBE: 3151602,
    UF: "MG",
    nome_da_cidade: "PLANURA",
  },
  {
    codigo_do_IGBE: 3539707,
    UF: "SP",
    nome_da_cidade: "PLATINA",
  },
  {
    codigo_do_IGBE: 3539806,
    UF: "SP",
    nome_da_cidade: "POA",
  },
  {
    codigo_do_IGBE: 2410108,
    UF: "RN",
    nome_da_cidade: "POCO BRANCO",
  },
  {
    codigo_do_IGBE: 2512036,
    UF: "PB",
    nome_da_cidade: "POCO DANTAS",
  },
  {
    codigo_do_IGBE: 2707206,
    UF: "AL",
    nome_da_cidade: "POCO DAS TRINCHEIRAS",
  },
  {
    codigo_do_IGBE: 2512077,
    UF: "PB",
    nome_da_cidade: "POCO DE JOSE DE MOURA",
  },
  {
    codigo_do_IGBE: 3151701,
    UF: "MG",
    nome_da_cidade: "POCO FUNDO",
  },
  {
    codigo_do_IGBE: 2805406,
    UF: "SE",
    nome_da_cidade: "POCO REDONDO",
  },
  {
    codigo_do_IGBE: 2805505,
    UF: "SE",
    nome_da_cidade: "POCO VERDE",
  },
  {
    codigo_do_IGBE: 2925105,
    UF: "BA",
    nome_da_cidade: "POCOES",
  },
  {
    codigo_do_IGBE: 5106505,
    UF: "MT",
    nome_da_cidade: "POCONE",
  },
  {
    codigo_do_IGBE: 3151800,
    UF: "MG",
    nome_da_cidade: "POCOS DE CALDAS",
  },
  {
    codigo_do_IGBE: 3151909,
    UF: "MG",
    nome_da_cidade: "POCRANE",
  },
  {
    codigo_do_IGBE: 2925204,
    UF: "BA",
    nome_da_cidade: "POJUCA",
  },
  {
    codigo_do_IGBE: 3539905,
    UF: "SP",
    nome_da_cidade: "POLONI",
  },
  {
    codigo_do_IGBE: 2512101,
    UF: "PB",
    nome_da_cidade: "POMBAL",
  },
  {
    codigo_do_IGBE: 2611309,
    UF: "PE",
    nome_da_cidade: "POMBOS",
  },
  {
    codigo_do_IGBE: 4213203,
    UF: "SC",
    nome_da_cidade: "POMERODE",
  },
  {
    codigo_do_IGBE: 3540002,
    UF: "SP",
    nome_da_cidade: "POMPEIA",
  },
  {
    codigo_do_IGBE: 3152006,
    UF: "MG",
    nome_da_cidade: "POMPEU",
  },
  {
    codigo_do_IGBE: 1505700,
    UF: "PA",
    nome_da_cidade: "PONTA DE PEDRAS",
  },
  {
    codigo_do_IGBE: 4119905,
    UF: "PR",
    nome_da_cidade: "PONTA GROSSA",
  },
  {
    codigo_do_IGBE: 5006606,
    UF: "MS",
    nome_da_cidade: "PONTA PORA",
  },
  {
    codigo_do_IGBE: 3540200,
    UF: "SP",
    nome_da_cidade: "PONTAL",
  },
  {
    codigo_do_IGBE: 5106653,
    UF: "MT",
    nome_da_cidade: "PONTAL DO ARAGUAIA",
  },
  {
    codigo_do_IGBE: 4119954,
    UF: "PR",
    nome_da_cidade: "PONTAL DO PARANA",
  },
  {
    codigo_do_IGBE: 5217708,
    UF: "GO",
    nome_da_cidade: "PONTALINA",
  },
  {
    codigo_do_IGBE: 3540259,
    UF: "SP",
    nome_da_cidade: "PONTALINDA",
  },
  {
    codigo_do_IGBE: 4314779,
    UF: "RS",
    nome_da_cidade: "PONTAO",
  },
  {
    codigo_do_IGBE: 4213302,
    UF: "SC",
    nome_da_cidade: "PONTE ALTA",
  },
  {
    codigo_do_IGBE: 4213351,
    UF: "SC",
    nome_da_cidade: "PONTE ALTA DO NORTE",
  },
  {
    codigo_do_IGBE: 1717909,
    UF: "TO",
    nome_da_cidade: "PONTE ALTA DO TOCANTINS",
  },
  {
    codigo_do_IGBE: 5106703,
    UF: "MT",
    nome_da_cidade: "PONTE BRANCA",
  },
  {
    codigo_do_IGBE: 3152105,
    UF: "MG",
    nome_da_cidade: "PONTE NOVA",
  },
  {
    codigo_do_IGBE: 4314787,
    UF: "RS",
    nome_da_cidade: "PONTE PRETA",
  },
  {
    codigo_do_IGBE: 4213401,
    UF: "SC",
    nome_da_cidade: "PONTE SERRADA",
  },
  {
    codigo_do_IGBE: 5106752,
    UF: "MT",
    nome_da_cidade: "PONTES E LACERDA",
  },
  {
    codigo_do_IGBE: 3540309,
    UF: "SP",
    nome_da_cidade: "PONTES GESTAL",
  },
  {
    codigo_do_IGBE: 3152131,
    UF: "MG",
    nome_da_cidade: "PONTO CHIQUE",
  },
  {
    codigo_do_IGBE: 3152170,
    UF: "MG",
    nome_da_cidade: "PONTO DOS VOLANTES",
  },
  {
    codigo_do_IGBE: 2925253,
    UF: "BA",
    nome_da_cidade: "PONTO NOVO",
  },
  {
    codigo_do_IGBE: 3540408,
    UF: "SP",
    nome_da_cidade: "POPULINA",
  },
  {
    codigo_do_IGBE: 2311009,
    UF: "CE",
    nome_da_cidade: "PORANGA",
  },
  {
    codigo_do_IGBE: 3540507,
    UF: "SP",
    nome_da_cidade: "PORANGABA",
  },
  {
    codigo_do_IGBE: 5218003,
    UF: "GO",
    nome_da_cidade: "PORANGATU",
  },
  {
    codigo_do_IGBE: 3304102,
    UF: "RJ",
    nome_da_cidade: "PORCIUNCULA",
  },
  {
    codigo_do_IGBE: 4120002,
    UF: "PR",
    nome_da_cidade: "PORECATU",
  },
  {
    codigo_do_IGBE: 4314803,
    UF: "RS",
    nome_da_cidade: "PORTAO",
  },
  {
    codigo_do_IGBE: 2311108,
    UF: "CE",
    nome_da_cidade: "PORTEIRAS",
  },
  {
    codigo_do_IGBE: 3152204,
    UF: "MG",
    nome_da_cidade: "PORTEIRINHA",
  },
  {
    codigo_do_IGBE: 1505809,
    UF: "PA",
    nome_da_cidade: "PORTEL",
  },
  {
    codigo_do_IGBE: 5218102,
    UF: "GO",
    nome_da_cidade: "PORTELANDIA",
  },
  {
    codigo_do_IGBE: 2208502,
    UF: "PI",
    nome_da_cidade: "PORTO",
  },
  {
    codigo_do_IGBE: 1200807,
    UF: "AC",
    nome_da_cidade: "PORTO ACRE",
  },
  {
    codigo_do_IGBE: 4314902,
    UF: "RS",
    nome_da_cidade: "PORTO ALEGRE",
  },
  {
    codigo_do_IGBE: 2208551,
    UF: "PI",
    nome_da_cidade: "PORTO ALEGRE DO PIAUI",
  },
  {
    codigo_do_IGBE: 4120101,
    UF: "PR",
    nome_da_cidade: "PORTO AMAZONAS",
  },
  {
    codigo_do_IGBE: 4120150,
    UF: "PR",
    nome_da_cidade: "PORTO BARREIRO",
  },
  {
    codigo_do_IGBE: 4213500,
    UF: "SC",
    nome_da_cidade: "PORTO BELO",
  },
  {
    codigo_do_IGBE: 2707305,
    UF: "AL",
    nome_da_cidade: "PORTO CALVO",
  },
  {
    codigo_do_IGBE: 2805604,
    UF: "SE",
    nome_da_cidade: "PORTO DA FOLHA",
  },
  {
    codigo_do_IGBE: 1505908,
    UF: "PA",
    nome_da_cidade: "PORTO DE MOZ",
  },
  {
    codigo_do_IGBE: 2707404,
    UF: "AL",
    nome_da_cidade: "PORTO DE PEDRAS",
  },
  {
    codigo_do_IGBE: 2410256,
    UF: "RN",
    nome_da_cidade: "PORTO DO MANGUE",
  },
  {
    codigo_do_IGBE: 5106802,
    UF: "MT",
    nome_da_cidade: "PORTO DOS GAUCHOS",
  },
  {
    codigo_do_IGBE: 5106828,
    UF: "MT",
    nome_da_cidade: "PORTO ESPERIDIAO",
  },
  {
    codigo_do_IGBE: 5106851,
    UF: "MT",
    nome_da_cidade: "PORTO ESTRELA",
  },
  {
    codigo_do_IGBE: 3540606,
    UF: "SP",
    nome_da_cidade: "PORTO FELIZ",
  },
  {
    codigo_do_IGBE: 3540705,
    UF: "SP",
    nome_da_cidade: "PORTO FERREIRA",
  },
  {
    codigo_do_IGBE: 3152303,
    UF: "MG",
    nome_da_cidade: "PORTO FIRME",
  },
  {
    codigo_do_IGBE: 2109007,
    UF: "MA",
    nome_da_cidade: "PORTO FRANCO",
  },
  {
    codigo_do_IGBE: 1600535,
    UF: "AP",
    nome_da_cidade: "PORTO GRANDE",
  },
  {
    codigo_do_IGBE: 4315008,
    UF: "RS",
    nome_da_cidade: "PORTO LUCENA",
  },
  {
    codigo_do_IGBE: 5006903,
    UF: "MS",
    nome_da_cidade: "PORTO MURTINHO",
  },
  {
    codigo_do_IGBE: 3304110,
    UF: "RJ",
    nome_da_cidade: "PORTO REAL",
  },
  {
    codigo_do_IGBE: 2707503,
    UF: "AL",
    nome_da_cidade: "PORTO REAL DO COLEGIO",
  },
  {
    codigo_do_IGBE: 4120200,
    UF: "PR",
    nome_da_cidade: "PORTO RICO",
  },
  {
    codigo_do_IGBE: 2109056,
    UF: "MA",
    nome_da_cidade: "PORTO RICO DO MARANHAO",
  },
  {
    codigo_do_IGBE: 2925303,
    UF: "BA",
    nome_da_cidade: "PORTO SEGURO",
  },
  {
    codigo_do_IGBE: 4213609,
    UF: "SC",
    nome_da_cidade: "PORTO UNIAO",
  },
  {
    codigo_do_IGBE: 1100205,
    UF: "RO",
    nome_da_cidade: "PORTO VELHO",
  },
  {
    codigo_do_IGBE: 4315073,
    UF: "RS",
    nome_da_cidade: "PORTO VERA CRUZ",
  },
  {
    codigo_do_IGBE: 1200393,
    UF: "AC",
    nome_da_cidade: "PORTO WALTER",
  },
  {
    codigo_do_IGBE: 4315107,
    UF: "RS",
    nome_da_cidade: "PORTO XAVIER",
  },
  {
    codigo_do_IGBE: 5218300,
    UF: "GO",
    nome_da_cidade: "POSSE",
  },
  {
    codigo_do_IGBE: 3152402,
    UF: "MG",
    nome_da_cidade: "POTE",
  },
  {
    codigo_do_IGBE: 2311207,
    UF: "CE",
    nome_da_cidade: "POTENGI",
  },
  {
    codigo_do_IGBE: 3540754,
    UF: "SP",
    nome_da_cidade: "POTIM",
  },
  {
    codigo_do_IGBE: 2925402,
    UF: "BA",
    nome_da_cidade: "POTIRAGUA",
  },
  {
    codigo_do_IGBE: 3540804,
    UF: "SP",
    nome_da_cidade: "POTIRENDABA",
  },
  {
    codigo_do_IGBE: 3152501,
    UF: "MG",
    nome_da_cidade: "POUSO ALEGRE",
  },
  {
    codigo_do_IGBE: 3152600,
    UF: "MG",
    nome_da_cidade: "POUSO ALTO",
  },
  {
    codigo_do_IGBE: 4315131,
    UF: "RS",
    nome_da_cidade: "POUSO NOVO",
  },
  {
    codigo_do_IGBE: 4213708,
    UF: "SC",
    nome_da_cidade: "POUSO REDONDO",
  },
  {
    codigo_do_IGBE: 5107008,
    UF: "MT",
    nome_da_cidade: "POXOREO",
  },
  {
    codigo_do_IGBE: 3540853,
    UF: "SP",
    nome_da_cidade: "PRACINHA",
  },
  {
    codigo_do_IGBE: 1600550,
    UF: "AP",
    nome_da_cidade: "PRACUUBA",
  },
  {
    codigo_do_IGBE: 4120333,
    UF: "PR",
    nome_da_cidade: "PRADO FERREIRA",
  },
  {
    codigo_do_IGBE: 3540903,
    UF: "SP",
    nome_da_cidade: "PRADOPOLIS",
  },
  {
    codigo_do_IGBE: 3152709,
    UF: "MG",
    nome_da_cidade: "PRADOS",
  },
  {
    codigo_do_IGBE: 4213807,
    UF: "SC",
    nome_da_cidade: "PRAIA GRANDE",
  },
  {
    codigo_do_IGBE: 3541000,
    UF: "SP",
    nome_da_cidade: "PRAIA GRANDE",
  },
  {
    codigo_do_IGBE: 1506005,
    UF: "PA",
    nome_da_cidade: "PRAINHA",
  },
  {
    codigo_do_IGBE: 4120358,
    UF: "PR",
    nome_da_cidade: "PRANCHITA",
  },
  {
    codigo_do_IGBE: 3152808,
    UF: "MG",
    nome_da_cidade: "PRATA",
  },
  {
    codigo_do_IGBE: 2512200,
    UF: "PB",
    nome_da_cidade: "PRATA",
  },
  {
    codigo_do_IGBE: 3541059,
    UF: "SP",
    nome_da_cidade: "PRATANIA",
  },
  {
    codigo_do_IGBE: 3152907,
    UF: "MG",
    nome_da_cidade: "PRATAPOLIS",
  },
  {
    codigo_do_IGBE: 3541109,
    UF: "SP",
    nome_da_cidade: "PRESIDENTE ALVES",
  },
  {
    codigo_do_IGBE: 3153103,
    UF: "MG",
    nome_da_cidade: "PRESIDENTE BERNARDES",
  },
  {
    codigo_do_IGBE: 3541208,
    UF: "SP",
    nome_da_cidade: "PRESIDENTE BERNARDES",
  },
  {
    codigo_do_IGBE: 4213906,
    UF: "SC",
    nome_da_cidade: "PRESIDENTE CASTELLO BRANCO",
  },
  {
    codigo_do_IGBE: 4120408,
    UF: "PR",
    nome_da_cidade: "PRESIDENTE CASTELO BRANCO",
  },
  {
    codigo_do_IGBE: 2925600,
    UF: "BA",
    nome_da_cidade: "PRESIDENTE DUTRA",
  },
  {
    codigo_do_IGBE: 2109106,
    UF: "MA",
    nome_da_cidade: "PRESIDENTE DUTRA",
  },
  {
    codigo_do_IGBE: 3541307,
    UF: "SP",
    nome_da_cidade: "PRESIDENTE EPITACIO",
  },
  {
    codigo_do_IGBE: 1303536,
    UF: "AM",
    nome_da_cidade: "PRESIDENTE FIGUEIREDO",
  },
  {
    codigo_do_IGBE: 4214003,
    UF: "SC",
    nome_da_cidade: "PRESIDENTE GETULIO",
  },
  {
    codigo_do_IGBE: 2109205,
    UF: "MA",
    nome_da_cidade: "PRESIDENTE JUSCELINO",
  },
  {
    codigo_do_IGBE: 3153202,
    UF: "MG",
    nome_da_cidade: "PRESIDENTE JUSCELINO",
  },
  {
    codigo_do_IGBE: 2410306,
    UF: "RN",
    nome_da_cidade: "PRESIDENTE JUSCELINO",
  },
  {
    codigo_do_IGBE: 3204302,
    UF: "ES",
    nome_da_cidade: "PRESIDENTE KENNEDY",
  },
  {
    codigo_do_IGBE: 1718402,
    UF: "TO",
    nome_da_cidade: "PRESIDENTE KENNEDY",
  },
  {
    codigo_do_IGBE: 4315149,
    UF: "RS",
    nome_da_cidade: "PRESIDENTE LUCENA",
  },
  {
    codigo_do_IGBE: 2109239,
    UF: "MA",
    nome_da_cidade: "PRESIDENTE MEDICI",
  },
  {
    codigo_do_IGBE: 1100254,
    UF: "RO",
    nome_da_cidade: "PRESIDENTE MEDICI",
  },
  {
    codigo_do_IGBE: 4214102,
    UF: "SC",
    nome_da_cidade: "PRESIDENTE NEREU",
  },
  {
    codigo_do_IGBE: 3153400,
    UF: "MG",
    nome_da_cidade: "PRESIDENTE OLEGARIO",
  },
  {
    codigo_do_IGBE: 3541406,
    UF: "SP",
    nome_da_cidade: "PRESIDENTE PRUDENTE",
  },
  {
    codigo_do_IGBE: 2109304,
    UF: "MA",
    nome_da_cidade: "PRESIDENTE VARGAS",
  },
  {
    codigo_do_IGBE: 3541505,
    UF: "SP",
    nome_da_cidade: "PRESIDENTE VENCESLAU",
  },
  {
    codigo_do_IGBE: 1506104,
    UF: "PA",
    nome_da_cidade: "PRIMAVERA",
  },
  {
    codigo_do_IGBE: 2611408,
    UF: "PE",
    nome_da_cidade: "PRIMAVERA",
  },
  {
    codigo_do_IGBE: 5107040,
    UF: "MT",
    nome_da_cidade: "PRIMAVERA DO LESTE",
  },
  {
    codigo_do_IGBE: 2109403,
    UF: "MA",
    nome_da_cidade: "PRIMEIRA CRUZ",
  },
  {
    codigo_do_IGBE: 4120507,
    UF: "PR",
    nome_da_cidade: "PRIMEIRO DE MAIO",
  },
  {
    codigo_do_IGBE: 4214151,
    UF: "SC",
    nome_da_cidade: "PRINCESA",
  },
  {
    codigo_do_IGBE: 2512309,
    UF: "PB",
    nome_da_cidade: "PRINCESA ISABEL",
  },
  {
    codigo_do_IGBE: 5218391,
    UF: "GO",
    nome_da_cidade: "PROFESSOR JAMIL",
  },
  {
    codigo_do_IGBE: 4315156,
    UF: "RS",
    nome_da_cidade: "PROGRESSO",
  },
  {
    codigo_do_IGBE: 3541604,
    UF: "SP",
    nome_da_cidade: "PROMISSAO",
  },
  {
    codigo_do_IGBE: 2805703,
    UF: "SE",
    nome_da_cidade: "PROPRIA",
  },
  {
    codigo_do_IGBE: 4315172,
    UF: "RS",
    nome_da_cidade: "PROTASIO ALVES",
  },
  {
    codigo_do_IGBE: 3153608,
    UF: "MG",
    nome_da_cidade: "PRUDENTE DE MORAIS",
  },
  {
    codigo_do_IGBE: 1718451,
    UF: "TO",
    nome_da_cidade: "PUGMIL",
  },
  {
    codigo_do_IGBE: 2410405,
    UF: "RN",
    nome_da_cidade: "PUREZA",
  },
  {
    codigo_do_IGBE: 4315206,
    UF: "RS",
    nome_da_cidade: "PUTINGA",
  },
  {
    codigo_do_IGBE: 2512408,
    UF: "PB",
    nome_da_cidade: "PUXINANA",
  },
  {
    codigo_do_IGBE: 3541653,
    UF: "SP",
    nome_da_cidade: "QUADRA",
  },
  {
    codigo_do_IGBE: 4315305,
    UF: "RS",
    nome_da_cidade: "QUARAI",
  },
  {
    codigo_do_IGBE: 3153707,
    UF: "MG",
    nome_da_cidade: "QUARTEL GERAL",
  },
  {
    codigo_do_IGBE: 4120655,
    UF: "PR",
    nome_da_cidade: "QUARTO CENTENARIO",
  },
  {
    codigo_do_IGBE: 3541703,
    UF: "SP",
    nome_da_cidade: "QUATA",
  },
  {
    codigo_do_IGBE: 4120705,
    UF: "PR",
    nome_da_cidade: "QUATIGUA",
  },
  {
    codigo_do_IGBE: 1506112,
    UF: "PA",
    nome_da_cidade: "QUATIPURU",
  },
  {
    codigo_do_IGBE: 3304128,
    UF: "RJ",
    nome_da_cidade: "QUATIS",
  },
  {
    codigo_do_IGBE: 4315313,
    UF: "RS",
    nome_da_cidade: "QUATRO IRMAOS",
  },
  {
    codigo_do_IGBE: 2707602,
    UF: "AL",
    nome_da_cidade: "QUEBRANGULO",
  },
  {
    codigo_do_IGBE: 4120903,
    UF: "PR",
    nome_da_cidade: "QUEDAS DO IGUACU",
  },
  {
    codigo_do_IGBE: 2208650,
    UF: "PI",
    nome_da_cidade: "QUEIMADA NOVA",
  },
  {
    codigo_do_IGBE: 2925808,
    UF: "BA",
    nome_da_cidade: "QUEIMADAS",
  },
  {
    codigo_do_IGBE: 2512507,
    UF: "PB",
    nome_da_cidade: "QUEIMADAS",
  },
  {
    codigo_do_IGBE: 3304144,
    UF: "RJ",
    nome_da_cidade: "QUEIMADOS",
  },
  {
    codigo_do_IGBE: 3541802,
    UF: "SP",
    nome_da_cidade: "QUEIROZ",
  },
  {
    codigo_do_IGBE: 3541901,
    UF: "SP",
    nome_da_cidade: "QUELUZ",
  },
  {
    codigo_do_IGBE: 3153806,
    UF: "MG",
    nome_da_cidade: "QUELUZITO",
  },
  {
    codigo_do_IGBE: 5107065,
    UF: "MT",
    nome_da_cidade: "QUERENCIA",
  },
  {
    codigo_do_IGBE: 4121000,
    UF: "PR",
    nome_da_cidade: "QUERENCIA DO NORTE",
  },
  {
    codigo_do_IGBE: 4315321,
    UF: "RS",
    nome_da_cidade: "QUEVEDOS",
  },
  {
    codigo_do_IGBE: 2925907,
    UF: "BA",
    nome_da_cidade: "QUIJINGUE",
  },
  {
    codigo_do_IGBE: 4214201,
    UF: "SC",
    nome_da_cidade: "QUILOMBO",
  },
  {
    codigo_do_IGBE: 3542008,
    UF: "SP",
    nome_da_cidade: "QUINTANA",
  },
  {
    codigo_do_IGBE: 2611507,
    UF: "PE",
    nome_da_cidade: "QUIPAPA",
  },
  {
    codigo_do_IGBE: 3304151,
    UF: "RJ",
    nome_da_cidade: "QUISSAMA",
  },
  {
    codigo_do_IGBE: 4121208,
    UF: "PR",
    nome_da_cidade: "QUITANDINHA",
  },
  {
    codigo_do_IGBE: 2311264,
    UF: "CE",
    nome_da_cidade: "QUITERIANOPOLIS",
  },
  {
    codigo_do_IGBE: 2512606,
    UF: "PB",
    nome_da_cidade: "QUIXABA",
  },
  {
    codigo_do_IGBE: 2611533,
    UF: "PE",
    nome_da_cidade: "QUIXABA",
  },
  {
    codigo_do_IGBE: 2925931,
    UF: "BA",
    nome_da_cidade: "QUIXABEIRA",
  },
  {
    codigo_do_IGBE: 2311306,
    UF: "CE",
    nome_da_cidade: "QUIXADA",
  },
  {
    codigo_do_IGBE: 2311405,
    UF: "CE",
    nome_da_cidade: "QUIXERAMOBIM",
  },
  {
    codigo_do_IGBE: 2311504,
    UF: "CE",
    nome_da_cidade: "QUIXERE",
  },
  {
    codigo_do_IGBE: 2410603,
    UF: "RN",
    nome_da_cidade: "RAFAEL GODEIRO",
  },
  {
    codigo_do_IGBE: 2925956,
    UF: "BA",
    nome_da_cidade: "RAFAEL JAMBEIRO",
  },
  {
    codigo_do_IGBE: 3542107,
    UF: "SP",
    nome_da_cidade: "RAFARD",
  },
  {
    codigo_do_IGBE: 4121257,
    UF: "PR",
    nome_da_cidade: "RAMILANDIA",
  },
  {
    codigo_do_IGBE: 3542206,
    UF: "SP",
    nome_da_cidade: "RANCHARIA",
  },
  {
    codigo_do_IGBE: 4121307,
    UF: "PR",
    nome_da_cidade: "RANCHO ALEGRE",
  },
  {
    codigo_do_IGBE: 4214300,
    UF: "SC",
    nome_da_cidade: "RANCHO QUEIMADO",
  },
  {
    codigo_do_IGBE: 2109452,
    UF: "MA",
    nome_da_cidade: "RAPOSA",
  },
  {
    codigo_do_IGBE: 3153905,
    UF: "MG",
    nome_da_cidade: "RAPOSOS",
  },
  {
    codigo_do_IGBE: 3154002,
    UF: "MG",
    nome_da_cidade: "RAUL SOARES",
  },
  {
    codigo_do_IGBE: 4121406,
    UF: "PR",
    nome_da_cidade: "REALEZA",
  },
  {
    codigo_do_IGBE: 4121505,
    UF: "PR",
    nome_da_cidade: "REBOUCAS",
  },
  {
    codigo_do_IGBE: 2611606,
    UF: "PE",
    nome_da_cidade: "RECIFE",
  },
  {
    codigo_do_IGBE: 3154101,
    UF: "MG",
    nome_da_cidade: "RECREIO",
  },
  {
    codigo_do_IGBE: 2311603,
    UF: "CE",
    nome_da_cidade: "REDENCAO",
  },
  {
    codigo_do_IGBE: 1506138,
    UF: "PA",
    nome_da_cidade: "REDENCAO",
  },
  {
    codigo_do_IGBE: 3542305,
    UF: "SP",
    nome_da_cidade: "REDENCAO DA SERRA",
  },
  {
    codigo_do_IGBE: 2208700,
    UF: "PI",
    nome_da_cidade: "REDENCAO DO GURGUEIA",
  },
  {
    codigo_do_IGBE: 4315404,
    UF: "RS",
    nome_da_cidade: "REDENTORA",
  },
  {
    codigo_do_IGBE: 3154150,
    UF: "MG",
    nome_da_cidade: "REDUTO",
  },
  {
    codigo_do_IGBE: 2208809,
    UF: "PI",
    nome_da_cidade: "REGENERACAO",
  },
  {
    codigo_do_IGBE: 3542404,
    UF: "SP",
    nome_da_cidade: "REGENTE FEIJO",
  },
  {
    codigo_do_IGBE: 3542503,
    UF: "SP",
    nome_da_cidade: "REGINOPOLIS",
  },
  {
    codigo_do_IGBE: 3542602,
    UF: "SP",
    nome_da_cidade: "REGISTRO",
  },
  {
    codigo_do_IGBE: 4315453,
    UF: "RS",
    nome_da_cidade: "RELVADO",
  },
  {
    codigo_do_IGBE: 2926004,
    UF: "BA",
    nome_da_cidade: "REMANSO",
  },
  {
    codigo_do_IGBE: 4121604,
    UF: "PR",
    nome_da_cidade: "RENASCENCA",
  },
  {
    codigo_do_IGBE: 2311702,
    UF: "CE",
    nome_da_cidade: "RERIUTABA",
  },
  {
    codigo_do_IGBE: 3154200,
    UF: "MG",
    nome_da_cidade: "RESENDE COSTA",
  },
  {
    codigo_do_IGBE: 4121703,
    UF: "PR",
    nome_da_cidade: "RESERVA",
  },
  {
    codigo_do_IGBE: 5107156,
    UF: "MT",
    nome_da_cidade: "RESERVA DO CABACAL",
  },
  {
    codigo_do_IGBE: 4121752,
    UF: "PR",
    nome_da_cidade: "RESERVA DO IGUACU",
  },
  {
    codigo_do_IGBE: 3154309,
    UF: "MG",
    nome_da_cidade: "RESPLENDOR",
  },
  {
    codigo_do_IGBE: 3154408,
    UF: "MG",
    nome_da_cidade: "RESSAQUINHA",
  },
  {
    codigo_do_IGBE: 3542701,
    UF: "SP",
    nome_da_cidade: "RESTINGA",
  },
  {
    codigo_do_IGBE: 2926103,
    UF: "BA",
    nome_da_cidade: "RETIROLANDIA",
  },
  {
    codigo_do_IGBE: 2109502,
    UF: "MA",
    nome_da_cidade: "RIACHAO",
  },
  {
    codigo_do_IGBE: 2512747,
    UF: "PB",
    nome_da_cidade: "RIACHAO",
  },
  {
    codigo_do_IGBE: 2512754,
    UF: "PB",
    nome_da_cidade: "RIACHAO DO BACAMARTE",
  },
  {
    codigo_do_IGBE: 2805802,
    UF: "SE",
    nome_da_cidade: "RIACHAO DO DANTAS",
  },
  {
    codigo_do_IGBE: 2926301,
    UF: "BA",
    nome_da_cidade: "RIACHAO DO JACUIPE",
  },
  {
    codigo_do_IGBE: 2512762,
    UF: "PB",
    nome_da_cidade: "RIACHAO DO POCO",
  },
  {
    codigo_do_IGBE: 3154457,
    UF: "MG",
    nome_da_cidade: "RIACHINHO",
  },
  {
    codigo_do_IGBE: 1718550,
    UF: "TO",
    nome_da_cidade: "RIACHINHO",
  },
  {
    codigo_do_IGBE: 2410702,
    UF: "RN",
    nome_da_cidade: "RIACHO DA CRUZ",
  },
  {
    codigo_do_IGBE: 2611705,
    UF: "PE",
    nome_da_cidade: "RIACHO DAS ALMAS",
  },
  {
    codigo_do_IGBE: 2926400,
    UF: "BA",
    nome_da_cidade: "RIACHO DE SANTANA",
  },
  {
    codigo_do_IGBE: 2410801,
    UF: "RN",
    nome_da_cidade: "RIACHO DE SANTANA",
  },
  {
    codigo_do_IGBE: 2512804,
    UF: "PB",
    nome_da_cidade: "RIACHO DOS CAVALOS",
  },
  {
    codigo_do_IGBE: 3154507,
    UF: "MG",
    nome_da_cidade: "RIACHO DOS MACHADOS",
  },
  {
    codigo_do_IGBE: 2208858,
    UF: "PI",
    nome_da_cidade: "RIACHO FRIO",
  },
  {
    codigo_do_IGBE: 2410900,
    UF: "RN",
    nome_da_cidade: "RIACHUELO",
  },
  {
    codigo_do_IGBE: 5218607,
    UF: "GO",
    nome_da_cidade: "RIALMA",
  },
  {
    codigo_do_IGBE: 5218706,
    UF: "GO",
    nome_da_cidade: "RIANAPOLIS",
  },
  {
    codigo_do_IGBE: 2109551,
    UF: "MA",
    nome_da_cidade: "RIBAMAR FIQUENE",
  },
  {
    codigo_do_IGBE: 5007109,
    UF: "MS",
    nome_da_cidade: "RIBAS DO RIO PARDO",
  },
  {
    codigo_do_IGBE: 3542800,
    UF: "SP",
    nome_da_cidade: "RIBEIRA",
  },
  {
    codigo_do_IGBE: 2926509,
    UF: "BA",
    nome_da_cidade: "RIBEIRA DO AMPARO",
  },
  {
    codigo_do_IGBE: 2208874,
    UF: "PI",
    nome_da_cidade: "RIBEIRA DO PIAUI",
  },
  {
    codigo_do_IGBE: 2926608,
    UF: "BA",
    nome_da_cidade: "RIBEIRA DO POMBAL",
  },
  {
    codigo_do_IGBE: 2611804,
    UF: "PE",
    nome_da_cidade: "RIBEIRAO",
  },
  {
    codigo_do_IGBE: 3542909,
    UF: "SP",
    nome_da_cidade: "RIBEIRAO BONITO",
  },
  {
    codigo_do_IGBE: 5107180,
    UF: "MT",
    nome_da_cidade: "RIBEIRAO CASCALHEIRA",
  },
  {
    codigo_do_IGBE: 4121802,
    UF: "PR",
    nome_da_cidade: "RIBEIRAO CLARO",
  },
  {
    codigo_do_IGBE: 3543105,
    UF: "SP",
    nome_da_cidade: "RIBEIRAO CORRENTE",
  },
  {
    codigo_do_IGBE: 3154606,
    UF: "MG",
    nome_da_cidade: "RIBEIRAO DAS NEVES",
  },
  {
    codigo_do_IGBE: 4121901,
    UF: "PR",
    nome_da_cidade: "RIBEIRAO DO PINHAL",
  },
  {
    codigo_do_IGBE: 3543204,
    UF: "SP",
    nome_da_cidade: "RIBEIRAO DO SUL",
  },
  {
    codigo_do_IGBE: 3543238,
    UF: "SP",
    nome_da_cidade: "RIBEIRAO DOS INDIOS",
  },
  {
    codigo_do_IGBE: 3543253,
    UF: "SP",
    nome_da_cidade: "RIBEIRAO GRANDE",
  },
  {
    codigo_do_IGBE: 3543303,
    UF: "SP",
    nome_da_cidade: "RIBEIRAO PIRES",
  },
  {
    codigo_do_IGBE: 3543402,
    UF: "SP",
    nome_da_cidade: "RIBEIRAO PRETO",
  },
  {
    codigo_do_IGBE: 5107198,
    UF: "MT",
    nome_da_cidade: "RIBEIRAOZINHO",
  },
  {
    codigo_do_IGBE: 2208908,
    UF: "PI",
    nome_da_cidade: "RIBEIRO GONCALVES",
  },
  {
    codigo_do_IGBE: 2806008,
    UF: "SE",
    nome_da_cidade: "RIBEIROPOLIS",
  },
  {
    codigo_do_IGBE: 3543600,
    UF: "SP",
    nome_da_cidade: "RIFAINA",
  },
  {
    codigo_do_IGBE: 3543709,
    UF: "SP",
    nome_da_cidade: "RINCAO",
  },
  {
    codigo_do_IGBE: 3543808,
    UF: "SP",
    nome_da_cidade: "RINOPOLIS",
  },
  {
    codigo_do_IGBE: 4122008,
    UF: "PR",
    nome_da_cidade: "RIO AZUL",
  },
  {
    codigo_do_IGBE: 3204351,
    UF: "ES",
    nome_da_cidade: "RIO BANANAL",
  },
  {
    codigo_do_IGBE: 4122107,
    UF: "PR",
    nome_da_cidade: "RIO BOM",
  },
  {
    codigo_do_IGBE: 3304300,
    UF: "RJ",
    nome_da_cidade: "RIO BONITO",
  },
  {
    codigo_do_IGBE: 1200401,
    UF: "AC",
    nome_da_cidade: "RIO BRANCO",
  },
  {
    codigo_do_IGBE: 5107206,
    UF: "MT",
    nome_da_cidade: "RIO BRANCO",
  },
  {
    codigo_do_IGBE: 4122172,
    UF: "PR",
    nome_da_cidade: "RIO BRANCO DO IVAI",
  },
  {
    codigo_do_IGBE: 4122206,
    UF: "PR",
    nome_da_cidade: "RIO BRANCO DO SUL",
  },
  {
    codigo_do_IGBE: 5007208,
    UF: "MS",
    nome_da_cidade: "RIO BRILHANTE",
  },
  {
    codigo_do_IGBE: 3154903,
    UF: "MG",
    nome_da_cidade: "RIO CASCA",
  },
  {
    codigo_do_IGBE: 3304409,
    UF: "RJ",
    nome_da_cidade: "RIO CLARO",
  },
  {
    codigo_do_IGBE: 3543907,
    UF: "SP",
    nome_da_cidade: "RIO CLARO",
  },
  {
    codigo_do_IGBE: 1100262,
    UF: "RO",
    nome_da_cidade: "RIO CRESPO",
  },
  {
    codigo_do_IGBE: 1718659,
    UF: "TO",
    nome_da_cidade: "RIO DA CONCEICAO",
  },
  {
    codigo_do_IGBE: 3304508,
    UF: "RJ",
    nome_da_cidade: "RIO DAS FLORES",
  },
  {
    codigo_do_IGBE: 3304524,
    UF: "RJ",
    nome_da_cidade: "RIO DAS OSTRAS",
  },
  {
    codigo_do_IGBE: 3544004,
    UF: "SP",
    nome_da_cidade: "RIO DAS PEDRAS",
  },
  {
    codigo_do_IGBE: 3304557,
    UF: "RJ",
    nome_da_cidade: "RIO DE JANEIRO",
  },
  {
    codigo_do_IGBE: 2926806,
    UF: "BA",
    nome_da_cidade: "RIO DO ANTONIO",
  },
  {
    codigo_do_IGBE: 4214508,
    UF: "SC",
    nome_da_cidade: "RIO DO CAMPO",
  },
  {
    codigo_do_IGBE: 2408953,
    UF: "RN",
    nome_da_cidade: "RIO DO FOGO",
  },
  {
    codigo_do_IGBE: 4214607,
    UF: "SC",
    nome_da_cidade: "RIO DO OESTE",
  },
  {
    codigo_do_IGBE: 2926905,
    UF: "BA",
    nome_da_cidade: "RIO DO PIRES",
  },
  {
    codigo_do_IGBE: 3155108,
    UF: "MG",
    nome_da_cidade: "RIO DO PRADO",
  },
  {
    codigo_do_IGBE: 4214805,
    UF: "SC",
    nome_da_cidade: "RIO DO SUL",
  },
  {
    codigo_do_IGBE: 3155009,
    UF: "MG",
    nome_da_cidade: "RIO DOCE",
  },
  {
    codigo_do_IGBE: 1718709,
    UF: "TO",
    nome_da_cidade: "RIO DOS BOIS",
  },
  {
    codigo_do_IGBE: 4214706,
    UF: "SC",
    nome_da_cidade: "RIO DOS CEDROS",
  },
  {
    codigo_do_IGBE: 3155207,
    UF: "MG",
    nome_da_cidade: "RIO ESPERA",
  },
  {
    codigo_do_IGBE: 2611903,
    UF: "PE",
    nome_da_cidade: "RIO FORMOSO",
  },
  {
    codigo_do_IGBE: 4214904,
    UF: "SC",
    nome_da_cidade: "RIO FORTUNA",
  },
  {
    codigo_do_IGBE: 4315602,
    UF: "RS",
    nome_da_cidade: "RIO GRANDE",
  },
  {
    codigo_do_IGBE: 2209005,
    UF: "PI",
    nome_da_cidade: "RIO GRANDE DO PIAUI",
  },
  {
    codigo_do_IGBE: 2707701,
    UF: "AL",
    nome_da_cidade: "RIO LARGO",
  },
  {
    codigo_do_IGBE: 3155306,
    UF: "MG",
    nome_da_cidade: "RIO MANSO",
  },
  {
    codigo_do_IGBE: 1506161,
    UF: "PA",
    nome_da_cidade: "RIO MARIA",
  },
  {
    codigo_do_IGBE: 4215000,
    UF: "SC",
    nome_da_cidade: "RIO NEGRINHO",
  },
  {
    codigo_do_IGBE: 5007307,
    UF: "MS",
    nome_da_cidade: "RIO NEGRO",
  },
  {
    codigo_do_IGBE: 4122305,
    UF: "PR",
    nome_da_cidade: "RIO NEGRO",
  },
  {
    codigo_do_IGBE: 3155405,
    UF: "MG",
    nome_da_cidade: "RIO NOVO",
  },
  {
    codigo_do_IGBE: 3204401,
    UF: "ES",
    nome_da_cidade: "RIO NOVO DO SUL",
  },
  {
    codigo_do_IGBE: 3155504,
    UF: "MG",
    nome_da_cidade: "RIO PARANAIBA",
  },
  {
    codigo_do_IGBE: 4315701,
    UF: "RS",
    nome_da_cidade: "RIO PARDO",
  },
  {
    codigo_do_IGBE: 3155801,
    UF: "MG",
    nome_da_cidade: "RIO POMBA",
  },
  {
    codigo_do_IGBE: 3155900,
    UF: "MG",
    nome_da_cidade: "RIO PRETO",
  },
  {
    codigo_do_IGBE: 1303569,
    UF: "AM",
    nome_da_cidade: "RIO PRETO DA EVA",
  },
  {
    codigo_do_IGBE: 5218789,
    UF: "GO",
    nome_da_cidade: "RIO QUENTE",
  },
  {
    codigo_do_IGBE: 2927002,
    UF: "BA",
    nome_da_cidade: "RIO REAL",
  },
  {
    codigo_do_IGBE: 4215059,
    UF: "SC",
    nome_da_cidade: "RIO RUFINO",
  },
  {
    codigo_do_IGBE: 2512903,
    UF: "PB",
    nome_da_cidade: "RIO TINTO",
  },
  {
    codigo_do_IGBE: 5218805,
    UF: "GO",
    nome_da_cidade: "RIO VERDE",
  },
  {
    codigo_do_IGBE: 3156007,
    UF: "MG",
    nome_da_cidade: "RIO VERMELHO",
  },
  {
    codigo_do_IGBE: 3544202,
    UF: "SP",
    nome_da_cidade: "RIOLANDIA",
  },
  {
    codigo_do_IGBE: 4315750,
    UF: "RS",
    nome_da_cidade: "RIOZINHO",
  },
  {
    codigo_do_IGBE: 4215075,
    UF: "SC",
    nome_da_cidade: "RIQUEZA",
  },
  {
    codigo_do_IGBE: 3156106,
    UF: "MG",
    nome_da_cidade: "RITAPOLIS",
  },
  {
    codigo_do_IGBE: 3543501,
    UF: "SP",
    nome_da_cidade: "RIVERSUL",
  },
  {
    codigo_do_IGBE: 5007505,
    UF: "MS",
    nome_da_cidade: "ROCHEDO",
  },
  {
    codigo_do_IGBE: 3156205,
    UF: "MG",
    nome_da_cidade: "ROCHEDO DE MINAS",
  },
  {
    codigo_do_IGBE: 4215109,
    UF: "SC",
    nome_da_cidade: "RODEIO",
  },
  {
    codigo_do_IGBE: 4315909,
    UF: "RS",
    nome_da_cidade: "RODEIO BONITO",
  },
  {
    codigo_do_IGBE: 3156304,
    UF: "MG",
    nome_da_cidade: "RODEIRO",
  },
  {
    codigo_do_IGBE: 2927101,
    UF: "BA",
    nome_da_cidade: "RODELAS",
  },
  {
    codigo_do_IGBE: 2411007,
    UF: "RN",
    nome_da_cidade: "RODOLFO FERNANDES",
  },
  {
    codigo_do_IGBE: 1200427,
    UF: "AC",
    nome_da_cidade: "RODRIGUES ALVES",
  },
  {
    codigo_do_IGBE: 4315958,
    UF: "RS",
    nome_da_cidade: "ROLADOR",
  },
  {
    codigo_do_IGBE: 4122404,
    UF: "PR",
    nome_da_cidade: "ROLANDIA",
  },
  {
    codigo_do_IGBE: 3156403,
    UF: "MG",
    nome_da_cidade: "ROMARIA",
  },
  {
    codigo_do_IGBE: 4215208,
    UF: "SC",
    nome_da_cidade: "ROMELANDIA",
  },
  {
    codigo_do_IGBE: 4122503,
    UF: "PR",
    nome_da_cidade: "RONCADOR",
  },
  {
    codigo_do_IGBE: 4316105,
    UF: "RS",
    nome_da_cidade: "RONDA ALTA",
  },
  {
    codigo_do_IGBE: 4316204,
    UF: "RS",
    nome_da_cidade: "RONDINHA",
  },
  {
    codigo_do_IGBE: 5107578,
    UF: "MT",
    nome_da_cidade: "RONDOLANDIA",
  },
  {
    codigo_do_IGBE: 4122602,
    UF: "PR",
    nome_da_cidade: "RONDON",
  },
  {
    codigo_do_IGBE: 1506187,
    UF: "PA",
    nome_da_cidade: "RONDON DO PARA",
  },
  {
    codigo_do_IGBE: 5107602,
    UF: "MT",
    nome_da_cidade: "RONDONOPOLIS",
  },
  {
    codigo_do_IGBE: 4316303,
    UF: "RS",
    nome_da_cidade: "ROQUE GONZALES",
  },
  {
    codigo_do_IGBE: 1400472,
    UF: "RR",
    nome_da_cidade: "RORAINOPOLIS",
  },
  {
    codigo_do_IGBE: 3544251,
    UF: "SP",
    nome_da_cidade: "ROSANA",
  },
  {
    codigo_do_IGBE: 2806107,
    UF: "SE",
    nome_da_cidade: "ROSARIO DO CATETE",
  },
  {
    codigo_do_IGBE: 4122651,
    UF: "PR",
    nome_da_cidade: "ROSARIO DO IVAI",
  },
  {
    codigo_do_IGBE: 4316402,
    UF: "RS",
    nome_da_cidade: "ROSARIO DO SUL",
  },
  {
    codigo_do_IGBE: 5107701,
    UF: "MT",
    nome_da_cidade: "ROSARIO OESTE",
  },
  {
    codigo_do_IGBE: 3544301,
    UF: "SP",
    nome_da_cidade: "ROSEIRA",
  },
  {
    codigo_do_IGBE: 2707800,
    UF: "AL",
    nome_da_cidade: "ROTEIRO",
  },
  {
    codigo_do_IGBE: 3156502,
    UF: "MG",
    nome_da_cidade: "RUBELITA",
  },
  {
    codigo_do_IGBE: 3544400,
    UF: "SP",
    nome_da_cidade: "RUBIACEA",
  },
  {
    codigo_do_IGBE: 5218904,
    UF: "GO",
    nome_da_cidade: "RUBIATABA",
  },
  {
    codigo_do_IGBE: 3156601,
    UF: "MG",
    nome_da_cidade: "RUBIM",
  },
  {
    codigo_do_IGBE: 3544509,
    UF: "SP",
    nome_da_cidade: "RUBINEIA",
  },
  {
    codigo_do_IGBE: 1506195,
    UF: "PA",
    nome_da_cidade: "RUROPOLIS",
  },
  {
    codigo_do_IGBE: 2927200,
    UF: "BA",
    nome_da_cidade: "RUY BARBOSA",
  },
  {
    codigo_do_IGBE: 2411106,
    UF: "RN",
    nome_da_cidade: "RUY BARBOSA",
  },
  {
    codigo_do_IGBE: 3544608,
    UF: "SP",
    nome_da_cidade: "SABINO",
  },
  {
    codigo_do_IGBE: 3156809,
    UF: "MG",
    nome_da_cidade: "SABINOPOLIS",
  },
  {
    codigo_do_IGBE: 2311900,
    UF: "CE",
    nome_da_cidade: "SABOEIRO",
  },
  {
    codigo_do_IGBE: 3156908,
    UF: "MG",
    nome_da_cidade: "SACRAMENTO",
  },
  {
    codigo_do_IGBE: 4316428,
    UF: "RS",
    nome_da_cidade: "SAGRADA FAMILIA",
  },
  {
    codigo_do_IGBE: 3544707,
    UF: "SP",
    nome_da_cidade: "SAGRES",
  },
  {
    codigo_do_IGBE: 2612000,
    UF: "PE",
    nome_da_cidade: "SAIRE",
  },
  {
    codigo_do_IGBE: 4316436,
    UF: "RS",
    nome_da_cidade: "SALDANHA MARINHO",
  },
  {
    codigo_do_IGBE: 3544806,
    UF: "SP",
    nome_da_cidade: "SALES",
  },
  {
    codigo_do_IGBE: 3545001,
    UF: "SP",
    nome_da_cidade: "SALESOPOLIS",
  },
  {
    codigo_do_IGBE: 4215307,
    UF: "SC",
    nome_da_cidade: "SALETE",
  },
  {
    codigo_do_IGBE: 2612109,
    UF: "PE",
    nome_da_cidade: "SALGADINHO",
  },
  {
    codigo_do_IGBE: 2806206,
    UF: "SE",
    nome_da_cidade: "SALGADO",
  },
  {
    codigo_do_IGBE: 2513109,
    UF: "PB",
    nome_da_cidade: "SALGADO DE SAO FELIX",
  },
  {
    codigo_do_IGBE: 4122800,
    UF: "PR",
    nome_da_cidade: "SALGADO FILHO",
  },
  {
    codigo_do_IGBE: 2612208,
    UF: "PE",
    nome_da_cidade: "SALGUEIRO",
  },
  {
    codigo_do_IGBE: 3157005,
    UF: "MG",
    nome_da_cidade: "SALINAS",
  },
  {
    codigo_do_IGBE: 2927309,
    UF: "BA",
    nome_da_cidade: "SALINAS DA MARGARIDA",
  },
  {
    codigo_do_IGBE: 1506203,
    UF: "PA",
    nome_da_cidade: "SALINOPOLIS",
  },
  {
    codigo_do_IGBE: 2311959,
    UF: "CE",
    nome_da_cidade: "SALITRE",
  },
  {
    codigo_do_IGBE: 3545100,
    UF: "SP",
    nome_da_cidade: "SALMOURAO",
  },
  {
    codigo_do_IGBE: 2612307,
    UF: "PE",
    nome_da_cidade: "SALOA",
  },
  {
    codigo_do_IGBE: 4215356,
    UF: "SC",
    nome_da_cidade: "SALTINHO",
  },
  {
    codigo_do_IGBE: 3545209,
    UF: "SP",
    nome_da_cidade: "SALTO",
  },
  {
    codigo_do_IGBE: 3545308,
    UF: "SP",
    nome_da_cidade: "SALTO DE PIRAPORA",
  },
  {
    codigo_do_IGBE: 5107750,
    UF: "MT",
    nome_da_cidade: "SALTO DO CEU",
  },
  {
    codigo_do_IGBE: 4122909,
    UF: "PR",
    nome_da_cidade: "SALTO DO ITARARE",
  },
  {
    codigo_do_IGBE: 4123006,
    UF: "PR",
    nome_da_cidade: "SALTO DO LONTRA",
  },
  {
    codigo_do_IGBE: 3545407,
    UF: "SP",
    nome_da_cidade: "SALTO GRANDE",
  },
  {
    codigo_do_IGBE: 4215406,
    UF: "SC",
    nome_da_cidade: "SALTO VELOSO",
  },
  {
    codigo_do_IGBE: 2927408,
    UF: "BA",
    nome_da_cidade: "SALVADOR",
  },
  {
    codigo_do_IGBE: 4316477,
    UF: "RS",
    nome_da_cidade: "SALVADOR DAS MISSOES",
  },
  {
    codigo_do_IGBE: 1506302,
    UF: "PA",
    nome_da_cidade: "SALVATERRA",
  },
  {
    codigo_do_IGBE: 2109700,
    UF: "MA",
    nome_da_cidade: "SAMBAIBA",
  },
  {
    codigo_do_IGBE: 1718808,
    UF: "TO",
    nome_da_cidade: "SAMPAIO",
  },
  {
    codigo_do_IGBE: 4316600,
    UF: "RS",
    nome_da_cidade: "SANANDUVA",
  },
  {
    codigo_do_IGBE: 1718840,
    UF: "TO",
    nome_da_cidade: "SANDOLANDIA",
  },
  {
    codigo_do_IGBE: 3545506,
    UF: "SP",
    nome_da_cidade: "SANDOVALINA",
  },
  {
    codigo_do_IGBE: 4215455,
    UF: "SC",
    nome_da_cidade: "SANGAO",
  },
  {
    codigo_do_IGBE: 2612406,
    UF: "PE",
    nome_da_cidade: "SANHARO",
  },
  {
    codigo_do_IGBE: 3545605,
    UF: "SP",
    nome_da_cidade: "SANTA ADELIA",
  },
  {
    codigo_do_IGBE: 4123105,
    UF: "PR",
    nome_da_cidade: "SANTA AMELIA",
  },
  {
    codigo_do_IGBE: 2927507,
    UF: "BA",
    nome_da_cidade: "SANTA BARBARA",
  },
  {
    codigo_do_IGBE: 3157203,
    UF: "MG",
    nome_da_cidade: "SANTA BARBARA",
  },
  {
    codigo_do_IGBE: 3157252,
    UF: "MG",
    nome_da_cidade: "SANTA BARBARA DO LESTE",
  },
  {
    codigo_do_IGBE: 3157278,
    UF: "MG",
    nome_da_cidade: "SANTA BARBARA DO MONTE VERDE",
  },
  {
    codigo_do_IGBE: 1506351,
    UF: "PA",
    nome_da_cidade: "SANTA BARBARA DO PARA",
  },
  {
    codigo_do_IGBE: 4316709,
    UF: "RS",
    nome_da_cidade: "SANTA BARBARA DO SUL",
  },
  {
    codigo_do_IGBE: 3157302,
    UF: "MG",
    nome_da_cidade: "SANTA BARBARA DO TUGURIO",
  },
  {
    codigo_do_IGBE: 3545803,
    UF: "SP",
    nome_da_cidade: "SANTA BARBARA D'OESTE",
  },
  {
    codigo_do_IGBE: 3546009,
    UF: "SP",
    nome_da_cidade: "SANTA BRANCA",
  },
  {
    codigo_do_IGBE: 2927606,
    UF: "BA",
    nome_da_cidade: "SANTA BRIGIDA",
  },
  {
    codigo_do_IGBE: 5107248,
    UF: "MT",
    nome_da_cidade: "SANTA CARMEM",
  },
  {
    codigo_do_IGBE: 2513158,
    UF: "PB",
    nome_da_cidade: "SANTA CECILIA",
  },
  {
    codigo_do_IGBE: 4215505,
    UF: "SC",
    nome_da_cidade: "SANTA CECILIA",
  },
  {
    codigo_do_IGBE: 4123204,
    UF: "PR",
    nome_da_cidade: "SANTA CECILIA DO PAVAO",
  },
  {
    codigo_do_IGBE: 4316733,
    UF: "RS",
    nome_da_cidade: "SANTA CECILIA DO SUL",
  },
  {
    codigo_do_IGBE: 4316758,
    UF: "RS",
    nome_da_cidade: "SANTA CLARA DO SUL",
  },
  {
    codigo_do_IGBE: 3546108,
    UF: "SP",
    nome_da_cidade: "SANTA CLARA D'OESTE",
  },
  {
    codigo_do_IGBE: 2513208,
    UF: "PB",
    nome_da_cidade: "SANTA CRUZ",
  },
  {
    codigo_do_IGBE: 2612455,
    UF: "PE",
    nome_da_cidade: "SANTA CRUZ",
  },
  {
    codigo_do_IGBE: 2411205,
    UF: "RN",
    nome_da_cidade: "SANTA CRUZ",
  },
  {
    codigo_do_IGBE: 3546207,
    UF: "SP",
    nome_da_cidade: "SANTA CRUZ DA CONCEICAO",
  },
  {
    codigo_do_IGBE: 3546256,
    UF: "SP",
    nome_da_cidade: "SANTA CRUZ DA ESPERANCA",
  },
  {
    codigo_do_IGBE: 2927804,
    UF: "BA",
    nome_da_cidade: "SANTA CRUZ DA VITORIA",
  },
  {
    codigo_do_IGBE: 5219209,
    UF: "GO",
    nome_da_cidade: "SANTA CRUZ DE GOIAS",
  },
  {
    codigo_do_IGBE: 3157336,
    UF: "MG",
    nome_da_cidade: "SANTA CRUZ DE MINAS",
  },
  {
    codigo_do_IGBE: 4123303,
    UF: "PR",
    nome_da_cidade: "SANTA CRUZ DE MONTE CASTELO",
  },
  {
    codigo_do_IGBE: 3157377,
    UF: "MG",
    nome_da_cidade: "SANTA CRUZ DE SALINAS",
  },
  {
    codigo_do_IGBE: 1506401,
    UF: "PA",
    nome_da_cidade: "SANTA CRUZ DO ARARI",
  },
  {
    codigo_do_IGBE: 3157401,
    UF: "MG",
    nome_da_cidade: "SANTA CRUZ DO ESCALVADO",
  },
  {
    codigo_do_IGBE: 2209104,
    UF: "PI",
    nome_da_cidade: "SANTA CRUZ DO PIAUI",
  },
  {
    codigo_do_IGBE: 3546405,
    UF: "SP",
    nome_da_cidade: "SANTA CRUZ DO RIO PARDO",
  },
  {
    codigo_do_IGBE: 4316808,
    UF: "RS",
    nome_da_cidade: "SANTA CRUZ DO SUL",
  },
  {
    codigo_do_IGBE: 5107743,
    UF: "MT",
    nome_da_cidade: "SANTA CRUZ DO XINGU",
  },
  {
    codigo_do_IGBE: 2209153,
    UF: "PI",
    nome_da_cidade: "SANTA CRUZ DOS MILAGRES",
  },
  {
    codigo_do_IGBE: 3157500,
    UF: "MG",
    nome_da_cidade: "SANTA EFIGENIA DE MINAS",
  },
  {
    codigo_do_IGBE: 3546504,
    UF: "SP",
    nome_da_cidade: "SANTA ERNESTINA",
  },
  {
    codigo_do_IGBE: 4123402,
    UF: "PR",
    nome_da_cidade: "SANTA FE",
  },
  {
    codigo_do_IGBE: 5219258,
    UF: "GO",
    nome_da_cidade: "SANTA FE DE GOIAS",
  },
  {
    codigo_do_IGBE: 3157609,
    UF: "MG",
    nome_da_cidade: "SANTA FE DE MINAS",
  },
  {
    codigo_do_IGBE: 1718865,
    UF: "TO",
    nome_da_cidade: "SANTA FE DO ARAGUAIA",
  },
  {
    codigo_do_IGBE: 3546603,
    UF: "SP",
    nome_da_cidade: "SANTA FE DO SUL",
  },
  {
    codigo_do_IGBE: 2612554,
    UF: "PE",
    nome_da_cidade: "SANTA FILOMENA",
  },
  {
    codigo_do_IGBE: 2209203,
    UF: "PI",
    nome_da_cidade: "SANTA FILOMENA",
  },
  {
    codigo_do_IGBE: 2109759,
    UF: "MA",
    nome_da_cidade: "SANTA FILOMENA DO MARANHAO",
  },
  {
    codigo_do_IGBE: 3546702,
    UF: "SP",
    nome_da_cidade: "SANTA GERTRUDES",
  },
  {
    codigo_do_IGBE: 2109809,
    UF: "MA",
    nome_da_cidade: "SANTA HELENA",
  },
  {
    codigo_do_IGBE: 3156700,
    UF: "MG",
    nome_da_cidade: "SABARA",
  },
  {
    codigo_do_IGBE: 2513307,
    UF: "PB",
    nome_da_cidade: "SANTA HELENA",
  },
  {
    codigo_do_IGBE: 4123501,
    UF: "PR",
    nome_da_cidade: "SANTA HELENA",
  },
  {
    codigo_do_IGBE: 3157658,
    UF: "MG",
    nome_da_cidade: "SANTA HELENA DE MINAS",
  },
  {
    codigo_do_IGBE: 2927903,
    UF: "BA",
    nome_da_cidade: "SANTA INES",
  },
  {
    codigo_do_IGBE: 2109908,
    UF: "MA",
    nome_da_cidade: "SANTA INES",
  },
  {
    codigo_do_IGBE: 2513356,
    UF: "PB",
    nome_da_cidade: "SANTA INES",
  },
  {
    codigo_do_IGBE: 4123600,
    UF: "PR",
    nome_da_cidade: "SANTA INES",
  },
  {
    codigo_do_IGBE: 5219357,
    UF: "GO",
    nome_da_cidade: "SANTA ISABEL",
  },
  {
    codigo_do_IGBE: 3546801,
    UF: "SP",
    nome_da_cidade: "SANTA ISABEL",
  },
  {
    codigo_do_IGBE: 4123709,
    UF: "PR",
    nome_da_cidade: "SANTA ISABEL DO IVAI",
  },
  {
    codigo_do_IGBE: 1506500,
    UF: "PA",
    nome_da_cidade: "SANTA ISABEL DO PARA",
  },
  {
    codigo_do_IGBE: 4123808,
    UF: "PR",
    nome_da_cidade: "SANTA IZABEL DO OESTE",
  },
  {
    codigo_do_IGBE: 3157708,
    UF: "MG",
    nome_da_cidade: "SANTA JULIANA",
  },
  {
    codigo_do_IGBE: 3204500,
    UF: "ES",
    nome_da_cidade: "SANTA LEOPOLDINA",
  },
  {
    codigo_do_IGBE: 4123824,
    UF: "PR",
    nome_da_cidade: "SANTA LUCIA",
  },
  {
    codigo_do_IGBE: 3546900,
    UF: "SP",
    nome_da_cidade: "SANTA LUCIA",
  },
  {
    codigo_do_IGBE: 2209302,
    UF: "PI",
    nome_da_cidade: "SANTA LUZ",
  },
  {
    codigo_do_IGBE: 2707909,
    UF: "AL",
    nome_da_cidade: "SANTA LUZIA DO NORTE",
  },
  {
    codigo_do_IGBE: 1506559,
    UF: "PA",
    nome_da_cidade: "SANTA LUZIA DO PARA",
  },
  {
    codigo_do_IGBE: 2110039,
    UF: "MA",
    nome_da_cidade: "SANTA LUZIA DO PARUA",
  },
  {
    codigo_do_IGBE: 1100296,
    UF: "RO",
    nome_da_cidade: "SANTA LUZIA D'OESTE",
  },
  {
    codigo_do_IGBE: 3157906,
    UF: "MG",
    nome_da_cidade: "SANTA MARGARIDA",
  },
  {
    codigo_do_IGBE: 4316972,
    UF: "RS",
    nome_da_cidade: "SANTA MARGARIDA DO SUL",
  },
  {
    codigo_do_IGBE: 2409332,
    UF: "RN",
    nome_da_cidade: "SANTA MARIA",
  },
  {
    codigo_do_IGBE: 4316907,
    UF: "RS",
    nome_da_cidade: "SANTA MARIA",
  },
  {
    codigo_do_IGBE: 3547007,
    UF: "SP",
    nome_da_cidade: "SANTA MARIA DA SERRA",
  },
  {
    codigo_do_IGBE: 2928109,
    UF: "BA",
    nome_da_cidade: "SANTA MARIA DA VITORIA",
  },
  {
    codigo_do_IGBE: 1506583,
    UF: "PA",
    nome_da_cidade: "SANTA MARIA DAS BARREIRAS",
  },
  {
    codigo_do_IGBE: 3158003,
    UF: "MG",
    nome_da_cidade: "SANTA MARIA DE ITABIRA",
  },
  {
    codigo_do_IGBE: 3204559,
    UF: "ES",
    nome_da_cidade: "SANTA MARIA DE JETIBA",
  },
  {
    codigo_do_IGBE: 2612703,
    UF: "PE",
    nome_da_cidade: "SANTA MARIA DO CAMBUCA",
  },
  {
    codigo_do_IGBE: 4316956,
    UF: "RS",
    nome_da_cidade: "SANTA MARIA DO HERVAL",
  },
  {
    codigo_do_IGBE: 4123857,
    UF: "PR",
    nome_da_cidade: "SANTA MARIA DO OESTE",
  },
  {
    codigo_do_IGBE: 1506609,
    UF: "PA",
    nome_da_cidade: "SANTA MARIA DO PARA",
  },
  {
    codigo_do_IGBE: 3158201,
    UF: "MG",
    nome_da_cidade: "SANTA MARIA DO SUACUI",
  },
  {
    codigo_do_IGBE: 1718881,
    UF: "TO",
    nome_da_cidade: "SANTA MARIA DO TOCANTINS",
  },
  {
    codigo_do_IGBE: 3304607,
    UF: "RJ",
    nome_da_cidade: "SANTA MARIA MADALENA",
  },
  {
    codigo_do_IGBE: 4123907,
    UF: "PR",
    nome_da_cidade: "SANTA MARIANA",
  },
  {
    codigo_do_IGBE: 3547106,
    UF: "SP",
    nome_da_cidade: "SANTA MERCEDES",
  },
  {
    codigo_do_IGBE: 4123956,
    UF: "PR",
    nome_da_cidade: "SANTA MONICA",
  },
  {
    codigo_do_IGBE: 2110104,
    UF: "MA",
    nome_da_cidade: "SANTA QUITERIA DO MARANHAO",
  },
  {
    codigo_do_IGBE: 2110203,
    UF: "MA",
    nome_da_cidade: "SANTA RITA",
  },
  {
    codigo_do_IGBE: 2513703,
    UF: "PB",
    nome_da_cidade: "SANTA RITA",
  },
  {
    codigo_do_IGBE: 3159209,
    UF: "MG",
    nome_da_cidade: "SANTA RITA DE CALDAS",
  },
  {
    codigo_do_IGBE: 2928406,
    UF: "BA",
    nome_da_cidade: "SANTA RITA DE CASSIA",
  },
  {
    codigo_do_IGBE: 3159407,
    UF: "MG",
    nome_da_cidade: "SANTA RITA DE IBITIPOCA",
  },
  {
    codigo_do_IGBE: 3159357,
    UF: "MG",
    nome_da_cidade: "SANTA RITA DE MINAS",
  },
  {
    codigo_do_IGBE: 5219407,
    UF: "GO",
    nome_da_cidade: "SANTA RITA DO ARAGUAIA",
  },
  {
    codigo_do_IGBE: 3159506,
    UF: "MG",
    nome_da_cidade: "SANTA RITA DO ITUETO",
  },
  {
    codigo_do_IGBE: 5219456,
    UF: "GO",
    nome_da_cidade: "SANTA RITA DO NOVO DESTINO",
  },
  {
    codigo_do_IGBE: 5007554,
    UF: "MS",
    nome_da_cidade: "SANTA RITA DO PARDO",
  },
  {
    codigo_do_IGBE: 3159605,
    UF: "MG",
    nome_da_cidade: "SANTA RITA DO SAPUCAI",
  },
  {
    codigo_do_IGBE: 1718899,
    UF: "TO",
    nome_da_cidade: "SANTA RITA DO TOCANTINS",
  },
  {
    codigo_do_IGBE: 5107768,
    UF: "MT",
    nome_da_cidade: "SANTA RITA DO TRIVELATO",
  },
  {
    codigo_do_IGBE: 3547403,
    UF: "SP",
    nome_da_cidade: "SANTA RITA D'OESTE",
  },
  {
    codigo_do_IGBE: 4317202,
    UF: "RS",
    nome_da_cidade: "SANTA ROSA",
  },
  {
    codigo_do_IGBE: 5219506,
    UF: "GO",
    nome_da_cidade: "SANTA ROSA DE GOIAS",
  },
  {
    codigo_do_IGBE: 4215604,
    UF: "SC",
    nome_da_cidade: "SANTA ROSA DE LIMA",
  },
  {
    codigo_do_IGBE: 2806503,
    UF: "SE",
    nome_da_cidade: "SANTA ROSA DE LIMA",
  },
  {
    codigo_do_IGBE: 3547601,
    UF: "SP",
    nome_da_cidade: "SANTA ROSA DE VITERBO",
  },
  {
    codigo_do_IGBE: 2209377,
    UF: "PI",
    nome_da_cidade: "SANTA ROSA DO PIAUI",
  },
  {
    codigo_do_IGBE: 1200435,
    UF: "AC",
    nome_da_cidade: "SANTA ROSA DO PURUS",
  },
  {
    codigo_do_IGBE: 4215653,
    UF: "SC",
    nome_da_cidade: "SANTA ROSA DO SUL",
  },
  {
    codigo_do_IGBE: 3547650,
    UF: "SP",
    nome_da_cidade: "SANTA SALETE",
  },
  {
    codigo_do_IGBE: 3204609,
    UF: "ES",
    nome_da_cidade: "SANTA TERESA",
  },
  {
    codigo_do_IGBE: 2928505,
    UF: "BA",
    nome_da_cidade: "SANTA TERESINHA",
  },
  {
    codigo_do_IGBE: 2513802,
    UF: "PB",
    nome_da_cidade: "SANTA TERESINHA",
  },
  {
    codigo_do_IGBE: 4317251,
    UF: "RS",
    nome_da_cidade: "SANTA TEREZA",
  },
  {
    codigo_do_IGBE: 4124020,
    UF: "PR",
    nome_da_cidade: "SANTA TEREZA DO OESTE",
  },
  {
    codigo_do_IGBE: 1719004,
    UF: "TO",
    nome_da_cidade: "SANTA TEREZA DO TOCANTINS",
  },
  {
    codigo_do_IGBE: 5107776,
    UF: "MT",
    nome_da_cidade: "SANTA TEREZINHA",
  },
  {
    codigo_do_IGBE: 2612802,
    UF: "PE",
    nome_da_cidade: "SANTA TEREZINHA",
  },
  {
    codigo_do_IGBE: 4215679,
    UF: "SC",
    nome_da_cidade: "SANTA TEREZINHA",
  },
  {
    codigo_do_IGBE: 5219704,
    UF: "GO",
    nome_da_cidade: "SANTA TEREZINHA DE GOIAS",
  },
  {
    codigo_do_IGBE: 4124053,
    UF: "PR",
    nome_da_cidade: "SANTA TEREZINHA DE ITAIPU",
  },
  {
    codigo_do_IGBE: 4215687,
    UF: "SC",
    nome_da_cidade: "SANTA TEREZINHA DO PROGRESSO",
  },
  {
    codigo_do_IGBE: 1720002,
    UF: "TO",
    nome_da_cidade: "SANTA TEREZINHA DO TOCANTINS",
  },
  {
    codigo_do_IGBE: 3159803,
    UF: "MG",
    nome_da_cidade: "SANTA VITORIA",
  },
  {
    codigo_do_IGBE: 2928000,
    UF: "BA",
    nome_da_cidade: "SANTALUZ",
  },
  {
    codigo_do_IGBE: 1600600,
    UF: "AP",
    nome_da_cidade: "SANTANA",
  },
  {
    codigo_do_IGBE: 2928208,
    UF: "BA",
    nome_da_cidade: "SANTANA",
  },
  {
    codigo_do_IGBE: 4317004,
    UF: "RS",
    nome_da_cidade: "SANTANA DA BOA VISTA",
  },
  {
    codigo_do_IGBE: 3158300,
    UF: "MG",
    nome_da_cidade: "SANTANA DA VARGEM",
  },
  {
    codigo_do_IGBE: 3158409,
    UF: "MG",
    nome_da_cidade: "SANTANA DE CATAGUASES",
  },
  {
    codigo_do_IGBE: 2513505,
    UF: "PB",
    nome_da_cidade: "SANTANA DE MANGUEIRA",
  },
  {
    codigo_do_IGBE: 3547304,
    UF: "SP",
    nome_da_cidade: "SANTANA DE PARNAIBA",
  },
  {
    codigo_do_IGBE: 3158508,
    UF: "MG",
    nome_da_cidade: "SANTANA DE PIRAPAMA",
  },
  {
    codigo_do_IGBE: 2312007,
    UF: "CE",
    nome_da_cidade: "SANTANA DO ACARAU",
  },
  {
    codigo_do_IGBE: 1506708,
    UF: "PA",
    nome_da_cidade: "SANTANA DO ARAGUAIA",
  },
  {
    codigo_do_IGBE: 2312106,
    UF: "CE",
    nome_da_cidade: "SANTANA DO CARIRI",
  },
  {
    codigo_do_IGBE: 3158607,
    UF: "MG",
    nome_da_cidade: "SANTANA DO DESERTO",
  },
  {
    codigo_do_IGBE: 3158706,
    UF: "MG",
    nome_da_cidade: "SANTANA DO GARAMBEU",
  },
  {
    codigo_do_IGBE: 2708006,
    UF: "AL",
    nome_da_cidade: "SANTANA DO IPANEMA",
  },
  {
    codigo_do_IGBE: 4124004,
    UF: "PR",
    nome_da_cidade: "SANTANA DO ITARARE",
  },
  {
    codigo_do_IGBE: 3158805,
    UF: "MG",
    nome_da_cidade: "SANTANA DO JACARE",
  },
  {
    codigo_do_IGBE: 3158904,
    UF: "MG",
    nome_da_cidade: "SANTANA DO MANHUACU",
  },
  {
    codigo_do_IGBE: 2110237,
    UF: "MA",
    nome_da_cidade: "SANTANA DO MARANHAO",
  },
  {
    codigo_do_IGBE: 2411403,
    UF: "RN",
    nome_da_cidade: "SANTANA DO MATOS",
  },
  {
    codigo_do_IGBE: 2708105,
    UF: "AL",
    nome_da_cidade: "SANTANA DO MUNDAU",
  },
  {
    codigo_do_IGBE: 2209351,
    UF: "PI",
    nome_da_cidade: "SANTANA DO PIAUI",
  },
  {
    codigo_do_IGBE: 3159001,
    UF: "MG",
    nome_da_cidade: "SANTANA DO RIACHO",
  },
  {
    codigo_do_IGBE: 2806404,
    UF: "SE",
    nome_da_cidade: "SANTANA DO SAO FRANCISCO",
  },
  {
    codigo_do_IGBE: 2411429,
    UF: "RN",
    nome_da_cidade: "SANTANA DO SERIDO",
  },
  {
    codigo_do_IGBE: 2513604,
    UF: "PB",
    nome_da_cidade: "SANTANA DOS GARROTES",
  },
  {
    codigo_do_IGBE: 3159100,
    UF: "MG",
    nome_da_cidade: "SANTANA DOS MONTES",
  },
  {
    codigo_do_IGBE: 2928307,
    UF: "BA",
    nome_da_cidade: "SANTANOPOLIS",
  },
  {
    codigo_do_IGBE: 1506807,
    UF: "PA",
    nome_da_cidade: "SANTAREM",
  },
  {
    codigo_do_IGBE: 1506906,
    UF: "PA",
    nome_da_cidade: "SANTAREM NOVO",
  },
  {
    codigo_do_IGBE: 4317400,
    UF: "RS",
    nome_da_cidade: "SANTIAGO",
  },
  {
    codigo_do_IGBE: 4215695,
    UF: "SC",
    nome_da_cidade: "SANTIAGO DO SUL",
  },
  {
    codigo_do_IGBE: 5107263,
    UF: "MT",
    nome_da_cidade: "SANTO AFONSO",
  },
  {
    codigo_do_IGBE: 2928604,
    UF: "BA",
    nome_da_cidade: "SANTO AMARO",
  },
  {
    codigo_do_IGBE: 2806602,
    UF: "SE",
    nome_da_cidade: "SANTO AMARO DAS BROTAS",
  },
  {
    codigo_do_IGBE: 2110278,
    UF: "MA",
    nome_da_cidade: "SANTO AMARO DO MARANHAO",
  },
  {
    codigo_do_IGBE: 3547700,
    UF: "SP",
    nome_da_cidade: "SANTO ANASTACIO",
  },
  {
    codigo_do_IGBE: 2513851,
    UF: "PB",
    nome_da_cidade: "SANTO ANDRE",
  },
  {
    codigo_do_IGBE: 3547809,
    UF: "SP",
    nome_da_cidade: "SANTO ANDRE",
  },
  {
    codigo_do_IGBE: 4317509,
    UF: "RS",
    nome_da_cidade: "SANTO ANGELO",
  },
  {
    codigo_do_IGBE: 2411502,
    UF: "RN",
    nome_da_cidade: "SANTO ANTONIO",
  },
  {
    codigo_do_IGBE: 3547908,
    UF: "SP",
    nome_da_cidade: "SANTO ANTONIO DA ALEGRIA",
  },
  {
    codigo_do_IGBE: 5219712,
    UF: "GO",
    nome_da_cidade: "SANTO ANTONIO DA BARRA",
  },
  {
    codigo_do_IGBE: 4317608,
    UF: "RS",
    nome_da_cidade: "SANTO ANTONIO DA PATRULHA",
  },
  {
    codigo_do_IGBE: 4317707,
    UF: "RS",
    nome_da_cidade: "SANTO ANTONIO DAS MISSOES",
  },
  {
    codigo_do_IGBE: 5219738,
    UF: "GO",
    nome_da_cidade: "SANTO ANTONIO DE GOIAS",
  },
  {
    codigo_do_IGBE: 3157807,
    UF: "MG",
    nome_da_cidade: "SANTA LUZIA",
  },
  {
    codigo_do_IGBE: 3304706,
    UF: "RJ",
    nome_da_cidade: "SANTO ANTONIO DE PADUA",
  },
  {
    codigo_do_IGBE: 3548005,
    UF: "SP",
    nome_da_cidade: "SANTO ANTONIO DE POSSE",
  },
  {
    codigo_do_IGBE: 3159902,
    UF: "MG",
    nome_da_cidade: "SANTO ANTONIO DO AMPARO",
  },
  {
    codigo_do_IGBE: 3548054,
    UF: "SP",
    nome_da_cidade: "SANTO ANTONIO DO ARACANGUA",
  },
  {
    codigo_do_IGBE: 3160009,
    UF: "MG",
    nome_da_cidade: "SANTO ANTONIO DO AVENTUREIRO",
  },
  {
    codigo_do_IGBE: 4124202,
    UF: "PR",
    nome_da_cidade: "SANTO ANTONIO DO CAIUA",
  },
  {
    codigo_do_IGBE: 5219753,
    UF: "GO",
    nome_da_cidade: "SANTO ANTONIO DO DESCOBERTO",
  },
  {
    codigo_do_IGBE: 3160108,
    UF: "MG",
    nome_da_cidade: "SANTO ANTONIO DO GRAMA",
  },
  {
    codigo_do_IGBE: 1303700,
    UF: "AM",
    nome_da_cidade: "SANTO ANTONIO DO ICA",
  },
  {
    codigo_do_IGBE: 3160207,
    UF: "MG",
    nome_da_cidade: "SANTO ANTONIO DO ITAMBE",
  },
  {
    codigo_do_IGBE: 3160306,
    UF: "MG",
    nome_da_cidade: "SANTO ANTONIO DO JACINTO",
  },
  {
    codigo_do_IGBE: 3548104,
    UF: "SP",
    nome_da_cidade: "SANTO ANTONIO DO JARDIM",
  },
  {
    codigo_do_IGBE: 5107792,
    UF: "MT",
    nome_da_cidade: "SANTO ANTONIO DO LESTE",
  },
  {
    codigo_do_IGBE: 5107800,
    UF: "MT",
    nome_da_cidade: "SANTO ANTONIO DO LEVERGER",
  },
  {
    codigo_do_IGBE: 4317558,
    UF: "RS",
    nome_da_cidade: "SANTO ANTONIO DO PALMA",
  },
  {
    codigo_do_IGBE: 3548203,
    UF: "SP",
    nome_da_cidade: "SANTO ANTONIO DO PINHAL",
  },
  {
    codigo_do_IGBE: 4317756,
    UF: "RS",
    nome_da_cidade: "SANTO ANTONIO DO PLANALTO",
  },
  {
    codigo_do_IGBE: 3160454,
    UF: "MG",
    nome_da_cidade: "SANTO ANTONIO DO RETIRO",
  },
  {
    codigo_do_IGBE: 3160504,
    UF: "MG",
    nome_da_cidade: "SANTO ANTONIO DO RIO ABAIXO",
  },
  {
    codigo_do_IGBE: 4124400,
    UF: "PR",
    nome_da_cidade: "SANTO ANTONIO DO SUDOESTE",
  },
  {
    codigo_do_IGBE: 1507003,
    UF: "PA",
    nome_da_cidade: "SANTO ANTONIO DO TAUA",
  },
  {
    codigo_do_IGBE: 2110302,
    UF: "MA",
    nome_da_cidade: "SANTO ANTONIO DOS LOPES",
  },
  {
    codigo_do_IGBE: 2209450,
    UF: "PI",
    nome_da_cidade: "SANTO ANTONIO DOS MILAGRES",
  },
  {
    codigo_do_IGBE: 4317806,
    UF: "RS",
    nome_da_cidade: "SANTO AUGUSTO",
  },
  {
    codigo_do_IGBE: 4317905,
    UF: "RS",
    nome_da_cidade: "SANTO CRISTO",
  },
  {
    codigo_do_IGBE: 2928802,
    UF: "BA",
    nome_da_cidade: "SANTO ESTEVAO",
  },
  {
    codigo_do_IGBE: 3548302,
    UF: "SP",
    nome_da_cidade: "SANTO EXPEDITO",
  },
  {
    codigo_do_IGBE: 4317954,
    UF: "RS",
    nome_da_cidade: "SANTO EXPEDITO DO SUL",
  },
  {
    codigo_do_IGBE: 3160603,
    UF: "MG",
    nome_da_cidade: "SANTO HIPOLITO",
  },
  {
    codigo_do_IGBE: 4124509,
    UF: "PR",
    nome_da_cidade: "SANTO INACIO",
  },
  {
    codigo_do_IGBE: 3548500,
    UF: "SP",
    nome_da_cidade: "SANTOS",
  },
  {
    codigo_do_IGBE: 3160702,
    UF: "MG",
    nome_da_cidade: "SANTOS DUMONT",
  },
  {
    codigo_do_IGBE: 2312304,
    UF: "CE",
    nome_da_cidade: "SAO BENEDITO",
  },
  {
    codigo_do_IGBE: 2110401,
    UF: "MA",
    nome_da_cidade: "SAO BENEDITO DO RIO PRETO",
  },
  {
    codigo_do_IGBE: 2612901,
    UF: "PE",
    nome_da_cidade: "SAO BENEDITO DO SUL",
  },
  {
    codigo_do_IGBE: 2513927,
    UF: "PB",
    nome_da_cidade: "SAO BENTINHO",
  },
  {
    codigo_do_IGBE: 2110500,
    UF: "MA",
    nome_da_cidade: "SAO BENTO",
  },
  {
    codigo_do_IGBE: 2513901,
    UF: "PB",
    nome_da_cidade: "SAO BENTO",
  },
  {
    codigo_do_IGBE: 3160801,
    UF: "MG",
    nome_da_cidade: "SAO BENTO ABADE",
  },
  {
    codigo_do_IGBE: 2411601,
    UF: "RN",
    nome_da_cidade: "SAO BENTO DO NORTE",
  },
  {
    codigo_do_IGBE: 3548609,
    UF: "SP",
    nome_da_cidade: "SAO BENTO DO SAPUCAI",
  },
  {
    codigo_do_IGBE: 4215802,
    UF: "SC",
    nome_da_cidade: "SAO BENTO DO SUL",
  },
  {
    codigo_do_IGBE: 1720101,
    UF: "TO",
    nome_da_cidade: "SAO BENTO DO TOCANTINS",
  },
  {
    codigo_do_IGBE: 2411700,
    UF: "RN",
    nome_da_cidade: "SAO BENTO DO TRAIRI",
  },
  {
    codigo_do_IGBE: 2613008,
    UF: "PE",
    nome_da_cidade: "SAO BENTO DO UNA",
  },
  {
    codigo_do_IGBE: 4215752,
    UF: "SC",
    nome_da_cidade: "SAO BERNARDINO",
  },
  {
    codigo_do_IGBE: 4215901,
    UF: "SC",
    nome_da_cidade: "SAO BONIFACIO",
  },
  {
    codigo_do_IGBE: 4318002,
    UF: "RS",
    nome_da_cidade: "SAO BORJA",
  },
  {
    codigo_do_IGBE: 2708204,
    UF: "AL",
    nome_da_cidade: "SAO BRAS",
  },
  {
    codigo_do_IGBE: 1507102,
    UF: "PA",
    nome_da_cidade: "SAO CAETANO DE ODIVELAS",
  },
  {
    codigo_do_IGBE: 3548807,
    UF: "SP",
    nome_da_cidade: "SAO CAETANO DO SUL",
  },
  {
    codigo_do_IGBE: 2613107,
    UF: "PE",
    nome_da_cidade: "SAO CAITANO",
  },
  {
    codigo_do_IGBE: 3548906,
    UF: "SP",
    nome_da_cidade: "SAO CARLOS",
  },
  {
    codigo_do_IGBE: 4124608,
    UF: "PR",
    nome_da_cidade: "SAO CARLOS DO IVAI",
  },
  {
    codigo_do_IGBE: 2806701,
    UF: "SE",
    nome_da_cidade: "SAO CRISTOVAO",
  },
  {
    codigo_do_IGBE: 4216057,
    UF: "SC",
    nome_da_cidade: "SAO CRISTOVAO DO SUL",
  },
  {
    codigo_do_IGBE: 2928901,
    UF: "BA",
    nome_da_cidade: "SAO DESIDERIO",
  },
  {
    codigo_do_IGBE: 2928950,
    UF: "BA",
    nome_da_cidade: "SAO DOMINGOS",
  },
  {
    codigo_do_IGBE: 5219803,
    UF: "GO",
    nome_da_cidade: "SAO DOMINGOS",
  },
  {
    codigo_do_IGBE: 4216107,
    UF: "SC",
    nome_da_cidade: "SAO DOMINGOS",
  },
  {
    codigo_do_IGBE: 2806800,
    UF: "SE",
    nome_da_cidade: "SAO DOMINGOS",
  },
  {
    codigo_do_IGBE: 1507151,
    UF: "PA",
    nome_da_cidade: "SAO DOMINGOS DO ARAGUAIA",
  },
  {
    codigo_do_IGBE: 2110658,
    UF: "MA",
    nome_da_cidade: "SAO DOMINGOS DO AZEITAO",
  },
  {
    codigo_do_IGBE: 1507201,
    UF: "PA",
    nome_da_cidade: "SAO DOMINGOS DO CAPIM",
  },
  {
    codigo_do_IGBE: 2513943,
    UF: "PB",
    nome_da_cidade: "SAO DOMINGOS DO CARIRI",
  },
  {
    codigo_do_IGBE: 2110708,
    UF: "MA",
    nome_da_cidade: "SAO DOMINGOS DO MARANHAO",
  },
  {
    codigo_do_IGBE: 3204658,
    UF: "ES",
    nome_da_cidade: "SAO DOMINGOS DO NORTE",
  },
  {
    codigo_do_IGBE: 3161007,
    UF: "MG",
    nome_da_cidade: "SAO DOMINGOS DO PRATA",
  },
  {
    codigo_do_IGBE: 4318051,
    UF: "RS",
    nome_da_cidade: "SAO DOMINGOS DO SUL",
  },
  {
    codigo_do_IGBE: 2929107,
    UF: "BA",
    nome_da_cidade: "SAO FELIPE",
  },
  {
    codigo_do_IGBE: 1101484,
    UF: "RO",
    nome_da_cidade: "SAO FELIPE D'OESTE",
  },
  {
    codigo_do_IGBE: 2929008,
    UF: "BA",
    nome_da_cidade: "SAO FELIX",
  },
  {
    codigo_do_IGBE: 2110807,
    UF: "MA",
    nome_da_cidade: "SAO FELIX DE BALSAS",
  },
  {
    codigo_do_IGBE: 3161056,
    UF: "MG",
    nome_da_cidade: "SAO FELIX DE MINAS",
  },
  {
    codigo_do_IGBE: 2929057,
    UF: "BA",
    nome_da_cidade: "SAO FELIX DO CORIBE",
  },
  {
    codigo_do_IGBE: 2209609,
    UF: "PI",
    nome_da_cidade: "SAO FELIX DO PIAUI",
  },
  {
    codigo_do_IGBE: 1507300,
    UF: "PA",
    nome_da_cidade: "SAO FELIX DO XINGU",
  },
  {
    codigo_do_IGBE: 2411809,
    UF: "RN",
    nome_da_cidade: "SAO FERNANDO",
  },
  {
    codigo_do_IGBE: 3304805,
    UF: "RJ",
    nome_da_cidade: "SAO FIDELIS",
  },
  {
    codigo_do_IGBE: 3161106,
    UF: "MG",
    nome_da_cidade: "SAO FRANCISCO",
  },
  {
    codigo_do_IGBE: 2513984,
    UF: "PB",
    nome_da_cidade: "SAO FRANCISCO",
  },
  {
    codigo_do_IGBE: 2806909,
    UF: "SE",
    nome_da_cidade: "SAO FRANCISCO",
  },
  {
    codigo_do_IGBE: 3549003,
    UF: "SP",
    nome_da_cidade: "SAO FRANCISCO",
  },
  {
    codigo_do_IGBE: 2209658,
    UF: "PI",
    nome_da_cidade: "SAO FRANCISCO DE ASSIS DO PIAUI",
  },
  {
    codigo_do_IGBE: 5219902,
    UF: "GO",
    nome_da_cidade: "SAO FRANCISCO DE GOIAS",
  },
  {
    codigo_do_IGBE: 3304755,
    UF: "RJ",
    nome_da_cidade: "SAO FRANCISCO DE ITABAPOANA",
  },
  {
    codigo_do_IGBE: 3161205,
    UF: "MG",
    nome_da_cidade: "SAO FRANCISCO DE PAULA",
  },
  {
    codigo_do_IGBE: 4318200,
    UF: "RS",
    nome_da_cidade: "SAO FRANCISCO DE PAULA",
  },
  {
    codigo_do_IGBE: 2110856,
    UF: "MA",
    nome_da_cidade: "SAO FRANCISCO DO BREJAO",
  },
  {
    codigo_do_IGBE: 2929206,
    UF: "BA",
    nome_da_cidade: "SAO FRANCISCO DO CONDE",
  },
  {
    codigo_do_IGBE: 3161403,
    UF: "MG",
    nome_da_cidade: "SAO FRANCISCO DO GLORIA",
  },
  {
    codigo_do_IGBE: 1101492,
    UF: "RO",
    nome_da_cidade: "SAO FRANCISCO DO GUAPORE",
  },
  {
    codigo_do_IGBE: 2110906,
    UF: "MA",
    nome_da_cidade: "SAO FRANCISCO DO MARANHAO",
  },
  {
    codigo_do_IGBE: 2411908,
    UF: "RN",
    nome_da_cidade: "SAO FRANCISCO DO OESTE",
  },
  {
    codigo_do_IGBE: 1507409,
    UF: "PA",
    nome_da_cidade: "SAO FRANCISCO DO PARA",
  },
  {
    codigo_do_IGBE: 2209708,
    UF: "PI",
    nome_da_cidade: "SAO FRANCISCO DO PIAUI",
  },
  {
    codigo_do_IGBE: 4216206,
    UF: "SC",
    nome_da_cidade: "SAO FRANCISCO DO SUL",
  },
  {
    codigo_do_IGBE: 2929255,
    UF: "BA",
    nome_da_cidade: "SAO GABRIEL",
  },
  {
    codigo_do_IGBE: 4318309,
    UF: "RS",
    nome_da_cidade: "SAO GABRIEL",
  },
  {
    codigo_do_IGBE: 1303809,
    UF: "AM",
    nome_da_cidade: "SAO GABRIEL DA CACHOEIRA",
  },
  {
    codigo_do_IGBE: 3204708,
    UF: "ES",
    nome_da_cidade: "SAO GABRIEL DA PALHA",
  },
  {
    codigo_do_IGBE: 5007695,
    UF: "MS",
    nome_da_cidade: "SAO GABRIEL DO OESTE",
  },
  {
    codigo_do_IGBE: 3161502,
    UF: "MG",
    nome_da_cidade: "SAO GERALDO",
  },
  {
    codigo_do_IGBE: 3161601,
    UF: "MG",
    nome_da_cidade: "SAO GERALDO DA PIEDADE",
  },
  {
    codigo_do_IGBE: 3161650,
    UF: "MG",
    nome_da_cidade: "SAO GERALDO DO BAIXIO",
  },
  {
    codigo_do_IGBE: 3304904,
    UF: "RJ",
    nome_da_cidade: "SAO GONCALO",
  },
  {
    codigo_do_IGBE: 3161700,
    UF: "MG",
    nome_da_cidade: "SAO GONCALO DO ABAETE",
  },
  {
    codigo_do_IGBE: 2312403,
    UF: "CE",
    nome_da_cidade: "SAO GONCALO DO AMARANTE",
  },
  {
    codigo_do_IGBE: 2209757,
    UF: "PI",
    nome_da_cidade: "SAO GONCALO DO GURGUEIA",
  },
  {
    codigo_do_IGBE: 3161809,
    UF: "MG",
    nome_da_cidade: "SAO GONCALO DO PARA",
  },
  {
    codigo_do_IGBE: 2209807,
    UF: "PI",
    nome_da_cidade: "SAO GONCALO DO PIAUI",
  },
  {
    codigo_do_IGBE: 3161908,
    UF: "MG",
    nome_da_cidade: "SAO GONCALO DO RIO ABAIXO",
  },
  {
    codigo_do_IGBE: 3125507,
    UF: "MG",
    nome_da_cidade: "SAO GONCALO DO RIO PRETO",
  },
  {
    codigo_do_IGBE: 3162005,
    UF: "MG",
    nome_da_cidade: "SAO GONCALO DO SAPUCAI",
  },
  {
    codigo_do_IGBE: 2929305,
    UF: "BA",
    nome_da_cidade: "SAO GONCALO DOS CAMPOS",
  },
  {
    codigo_do_IGBE: 3162104,
    UF: "MG",
    nome_da_cidade: "SAO GOTARDO",
  },
  {
    codigo_do_IGBE: 4318408,
    UF: "RS",
    nome_da_cidade: "SAO JERONIMO",
  },
  {
    codigo_do_IGBE: 4124707,
    UF: "PR",
    nome_da_cidade: "SAO JERONIMO DA SERRA",
  },
  {
    codigo_do_IGBE: 2613206,
    UF: "PE",
    nome_da_cidade: "SAO JOAO",
  },
  {
    codigo_do_IGBE: 4124806,
    UF: "PR",
    nome_da_cidade: "SAO JOAO",
  },
  {
    codigo_do_IGBE: 2111003,
    UF: "MA",
    nome_da_cidade: "SAO JOAO BATISTA",
  },
  {
    codigo_do_IGBE: 3162203,
    UF: "MG",
    nome_da_cidade: "SAO JOAO BATISTA DO GLORIA",
  },
  {
    codigo_do_IGBE: 3305000,
    UF: "RJ",
    nome_da_cidade: "SAO JOAO DA BARRA",
  },
  {
    codigo_do_IGBE: 3549102,
    UF: "SP",
    nome_da_cidade: "SAO JOAO DA BOA VISTA",
  },
  {
    codigo_do_IGBE: 2209856,
    UF: "PI",
    nome_da_cidade: "SAO JOAO DA CANABRAVA",
  },
  {
    codigo_do_IGBE: 2209872,
    UF: "PI",
    nome_da_cidade: "SAO JOAO DA FRONTEIRA",
  },
  {
    codigo_do_IGBE: 3162252,
    UF: "MG",
    nome_da_cidade: "SAO JOAO DA LAGOA",
  },
  {
    codigo_do_IGBE: 3162302,
    UF: "MG",
    nome_da_cidade: "SAO JOAO DA MATA",
  },
  {
    codigo_do_IGBE: 5220058,
    UF: "GO",
    nome_da_cidade: "SAO JOAO DA PARAUNA",
  },
  {
    codigo_do_IGBE: 1507466,
    UF: "PA",
    nome_da_cidade: "SAO JOAO DA PONTA",
  },
  {
    codigo_do_IGBE: 3162401,
    UF: "MG",
    nome_da_cidade: "SAO JOAO DA PONTE",
  },
  {
    codigo_do_IGBE: 2209906,
    UF: "PI",
    nome_da_cidade: "SAO JOAO DA SERRA",
  },
  {
    codigo_do_IGBE: 4318424,
    UF: "RS",
    nome_da_cidade: "SAO JOAO DA URTIGA",
  },
  {
    codigo_do_IGBE: 3549201,
    UF: "SP",
    nome_da_cidade: "SAO JOAO DAS DUAS PONTES",
  },
  {
    codigo_do_IGBE: 3162450,
    UF: "MG",
    nome_da_cidade: "SAO JOAO DAS MISSOES",
  },
  {
    codigo_do_IGBE: 3549250,
    UF: "SP",
    nome_da_cidade: "SAO JOAO DE IRACEMA",
  },
  {
    codigo_do_IGBE: 3305109,
    UF: "RJ",
    nome_da_cidade: "SAO JOAO DE MERITI",
  },
  {
    codigo_do_IGBE: 1507474,
    UF: "PA",
    nome_da_cidade: "SAO JOAO DE PIRABAS",
  },
  {
    codigo_do_IGBE: 3162500,
    UF: "MG",
    nome_da_cidade: "SAO JOAO DEL REI",
  },
  {
    codigo_do_IGBE: 1507508,
    UF: "PA",
    nome_da_cidade: "SAO JOAO DO ARAGUAIA",
  },
  {
    codigo_do_IGBE: 2209971,
    UF: "PI",
    nome_da_cidade: "SAO JOAO DO ARRAIAL",
  },
  {
    codigo_do_IGBE: 4124905,
    UF: "PR",
    nome_da_cidade: "SAO JOAO DO CAIUA",
  },
  {
    codigo_do_IGBE: 2514008,
    UF: "PB",
    nome_da_cidade: "SAO JOAO DO CARIRI",
  },
  {
    codigo_do_IGBE: 2111029,
    UF: "MA",
    nome_da_cidade: "SAO JOAO DO CARU",
  },
  {
    codigo_do_IGBE: 4216354,
    UF: "SC",
    nome_da_cidade: "SAO JOAO DO ITAPERIU",
  },
  {
    codigo_do_IGBE: 4125001,
    UF: "PR",
    nome_da_cidade: "SAO JOAO DO IVAI",
  },
  {
    codigo_do_IGBE: 3162559,
    UF: "MG",
    nome_da_cidade: "SAO JOAO DO MANHUACU",
  },
  {
    codigo_do_IGBE: 4216255,
    UF: "SC",
    nome_da_cidade: "SAO JOAO DO OESTE",
  },
  {
    codigo_do_IGBE: 3162609,
    UF: "MG",
    nome_da_cidade: "SAO JOAO DO ORIENTE",
  },
  {
    codigo_do_IGBE: 3162658,
    UF: "MG",
    nome_da_cidade: "SAO JOAO DO PACUI",
  },
  {
    codigo_do_IGBE: 2111052,
    UF: "MA",
    nome_da_cidade: "SAO JOAO DO PARAISO",
  },
  {
    codigo_do_IGBE: 3162708,
    UF: "MG",
    nome_da_cidade: "SAO JOAO DO PARAISO",
  },
  {
    codigo_do_IGBE: 3549300,
    UF: "SP",
    nome_da_cidade: "SAO JOAO DO PAU D'ALHO",
  },
  {
    codigo_do_IGBE: 2210003,
    UF: "PI",
    nome_da_cidade: "SAO JOAO DO PIAUI",
  },
  {
    codigo_do_IGBE: 4318432,
    UF: "RS",
    nome_da_cidade: "SAO JOAO DO POLESINE",
  },
  {
    codigo_do_IGBE: 2500700,
    UF: "PB",
    nome_da_cidade: "SAO JOAO DO RIO DO PEIXE",
  },
  {
    codigo_do_IGBE: 2412104,
    UF: "RN",
    nome_da_cidade: "SAO JOAO DO SABUGI",
  },
  {
    codigo_do_IGBE: 2111078,
    UF: "MA",
    nome_da_cidade: "SAO JOAO DO SOTER",
  },
  {
    codigo_do_IGBE: 4216404,
    UF: "SC",
    nome_da_cidade: "SAO JOAO DO SUL",
  },
  {
    codigo_do_IGBE: 2514107,
    UF: "PB",
    nome_da_cidade: "SAO JOAO DO TIGRE",
  },
  {
    codigo_do_IGBE: 4125100,
    UF: "PR",
    nome_da_cidade: "SAO JOAO DO TRIUNFO",
  },
  {
    codigo_do_IGBE: 3162807,
    UF: "MG",
    nome_da_cidade: "SAO JOAO EVANGELISTA",
  },
  {
    codigo_do_IGBE: 3162906,
    UF: "MG",
    nome_da_cidade: "SAO JOAO NEPOMUCENO",
  },
  {
    codigo_do_IGBE: 4216503,
    UF: "SC",
    nome_da_cidade: "SAO JOAQUIM",
  },
  {
    codigo_do_IGBE: 2613305,
    UF: "PE",
    nome_da_cidade: "SAO JOAQUIM DO MONTE",
  },
  {
    codigo_do_IGBE: 4318440,
    UF: "RS",
    nome_da_cidade: "SAO JORGE",
  },
  {
    codigo_do_IGBE: 4125308,
    UF: "PR",
    nome_da_cidade: "SAO JORGE DO IVAI",
  },
  {
    codigo_do_IGBE: 4125357,
    UF: "PR",
    nome_da_cidade: "SAO JORGE DO PATROCINIO",
  },
  {
    codigo_do_IGBE: 4125209,
    UF: "PR",
    nome_da_cidade: "SAO JORGE D'OESTE",
  },
  {
    codigo_do_IGBE: 4216602,
    UF: "SC",
    nome_da_cidade: "SAO JOSE",
  },
  {
    codigo_do_IGBE: 3162948,
    UF: "MG",
    nome_da_cidade: "SAO JOSE DA BARRA",
  },
  {
    codigo_do_IGBE: 3549508,
    UF: "SP",
    nome_da_cidade: "SAO JOSE DA BELA VISTA",
  },
  {
    codigo_do_IGBE: 4125407,
    UF: "PR",
    nome_da_cidade: "SAO JOSE DA BOA VISTA",
  },
  {
    codigo_do_IGBE: 2613404,
    UF: "PE",
    nome_da_cidade: "SAO JOSE DA COROA GRANDE",
  },
  {
    codigo_do_IGBE: 2514206,
    UF: "PB",
    nome_da_cidade: "SAO JOSE DA LAGOA TAPADA",
  },
  {
    codigo_do_IGBE: 2708303,
    UF: "AL",
    nome_da_cidade: "SAO JOSE DA LAJE",
  },
  {
    codigo_do_IGBE: 3162955,
    UF: "MG",
    nome_da_cidade: "SAO JOSE DA LAPA",
  },
  {
    codigo_do_IGBE: 3163102,
    UF: "MG",
    nome_da_cidade: "SAO JOSE DA VARGINHA",
  },
  {
    codigo_do_IGBE: 2929354,
    UF: "BA",
    nome_da_cidade: "SAO JOSE DA VITORIA",
  },
  {
    codigo_do_IGBE: 4318457,
    UF: "RS",
    nome_da_cidade: "SAO JOSE DAS MISSOES",
  },
  {
    codigo_do_IGBE: 4125456,
    UF: "PR",
    nome_da_cidade: "SAO JOSE DAS PALMEIRAS",
  },
  {
    codigo_do_IGBE: 2514305,
    UF: "PB",
    nome_da_cidade: "SAO JOSE DE CAIANA",
  },
  {
    codigo_do_IGBE: 2514404,
    UF: "PB",
    nome_da_cidade: "SAO JOSE DE ESPINHARAS",
  },
  {
    codigo_do_IGBE: 2412203,
    UF: "RN",
    nome_da_cidade: "SAO JOSE DE MIPIBU",
  },
  {
    codigo_do_IGBE: 2514503,
    UF: "PB",
    nome_da_cidade: "SAO JOSE DE PIRANHAS",
  },
  {
    codigo_do_IGBE: 2514552,
    UF: "PB",
    nome_da_cidade: "SAO JOSE DE PRINCESA",
  },
  {
    codigo_do_IGBE: 2111201,
    UF: "MA",
    nome_da_cidade: "SAO JOSE DE RIBAMAR",
  },
  {
    codigo_do_IGBE: 3305133,
    UF: "RJ",
    nome_da_cidade: "SAO JOSE DE UBA",
  },
  {
    codigo_do_IGBE: 3163201,
    UF: "MG",
    nome_da_cidade: "SAO JOSE DO ALEGRE",
  },
  {
    codigo_do_IGBE: 3549607,
    UF: "SP",
    nome_da_cidade: "SAO JOSE DO BARREIRO",
  },
  {
    codigo_do_IGBE: 2514602,
    UF: "PB",
    nome_da_cidade: "SAO JOSE DO BONFIM",
  },
  {
    codigo_do_IGBE: 3204807,
    UF: "ES",
    nome_da_cidade: "SAO JOSE DO CALCADO",
  },
  {
    codigo_do_IGBE: 2412302,
    UF: "RN",
    nome_da_cidade: "SAO JOSE DO CAMPESTRE",
  },
  {
    codigo_do_IGBE: 4216701,
    UF: "SC",
    nome_da_cidade: "SAO JOSE DO CEDRO",
  },
  {
    codigo_do_IGBE: 4216800,
    UF: "SC",
    nome_da_cidade: "SAO JOSE DO CERRITO",
  },
  {
    codigo_do_IGBE: 3163300,
    UF: "MG",
    nome_da_cidade: "SAO JOSE DO DIVINO",
  },
  {
    codigo_do_IGBE: 2210052,
    UF: "PI",
    nome_da_cidade: "SAO JOSE DO DIVINO",
  },
  {
    codigo_do_IGBE: 2613602,
    UF: "PE",
    nome_da_cidade: "SAO JOSE DO EGITO",
  },
  {
    codigo_do_IGBE: 3163409,
    UF: "MG",
    nome_da_cidade: "SAO JOSE DO GOIABAL",
  },
  {
    codigo_do_IGBE: 4318465,
    UF: "RS",
    nome_da_cidade: "SAO JOSE DO HERVAL",
  },
  {
    codigo_do_IGBE: 4318481,
    UF: "RS",
    nome_da_cidade: "SAO JOSE DO HORTENCIO",
  },
  {
    codigo_do_IGBE: 4318499,
    UF: "RS",
    nome_da_cidade: "SAO JOSE DO INHACORA",
  },
  {
    codigo_do_IGBE: 2929370,
    UF: "BA",
    nome_da_cidade: "SAO JOSE DO JACUIPE",
  },
  {
    codigo_do_IGBE: 3163607,
    UF: "MG",
    nome_da_cidade: "SAO JOSE DO MANTIMENTO",
  },
  {
    codigo_do_IGBE: 4318507,
    UF: "RS",
    nome_da_cidade: "SAO JOSE DO NORTE",
  },
  {
    codigo_do_IGBE: 4318606,
    UF: "RS",
    nome_da_cidade: "SAO JOSE DO OURO",
  },
  {
    codigo_do_IGBE: 2210102,
    UF: "PI",
    nome_da_cidade: "SAO JOSE DO PEIXE",
  },
  {
    codigo_do_IGBE: 5107297,
    UF: "MT",
    nome_da_cidade: "SAO JOSE DO POVO",
  },
  {
    codigo_do_IGBE: 5107305,
    UF: "MT",
    nome_da_cidade: "SAO JOSE DO RIO CLARO",
  },
  {
    codigo_do_IGBE: 3549706,
    UF: "SP",
    nome_da_cidade: "SAO JOSE DO RIO PARDO",
  },
  {
    codigo_do_IGBE: 3549805,
    UF: "SP",
    nome_da_cidade: "SAO JOSE DO RIO PRETO",
  },
  {
    codigo_do_IGBE: 2514701,
    UF: "PB",
    nome_da_cidade: "SAO JOSE DO SABUGI",
  },
  {
    codigo_do_IGBE: 2412401,
    UF: "RN",
    nome_da_cidade: "SAO JOSE DO SERIDO",
  },
  {
    codigo_do_IGBE: 4318614,
    UF: "RS",
    nome_da_cidade: "SAO JOSE DO SUL",
  },
  {
    codigo_do_IGBE: 5107354,
    UF: "MT",
    nome_da_cidade: "SAO JOSE DO XINGU",
  },
  {
    codigo_do_IGBE: 4318622,
    UF: "RS",
    nome_da_cidade: "SAO JOSE DOS AUSENTES",
  },
  {
    codigo_do_IGBE: 2111250,
    UF: "MA",
    nome_da_cidade: "SAO JOSE DOS BASILIOS",
  },
  {
    codigo_do_IGBE: 3549904,
    UF: "SP",
    nome_da_cidade: "SAO JOSE DOS CAMPOS",
  },
  {
    codigo_do_IGBE: 2514800,
    UF: "PB",
    nome_da_cidade: "SAO JOSE DOS CORDEIROS",
  },
  {
    codigo_do_IGBE: 4125506,
    UF: "PR",
    nome_da_cidade: "SAO JOSE DOS PINHAIS",
  },
  {
    codigo_do_IGBE: 2514453,
    UF: "PB",
    nome_da_cidade: "SAO JOSE DOS RAMOS",
  },
  {
    codigo_do_IGBE: 2210300,
    UF: "PI",
    nome_da_cidade: "SAO JULIAO",
  },
  {
    codigo_do_IGBE: 4318705,
    UF: "RS",
    nome_da_cidade: "SAO LEOPOLDO",
  },
  {
    codigo_do_IGBE: 3163706,
    UF: "MG",
    nome_da_cidade: "SAO LOURENCO",
  },
  {
    codigo_do_IGBE: 2613701,
    UF: "PE",
    nome_da_cidade: "SAO LOURENCO DA MATA",
  },
  {
    codigo_do_IGBE: 3549953,
    UF: "SP",
    nome_da_cidade: "SAO LOURENCO DA SERRA",
  },
  {
    codigo_do_IGBE: 4216909,
    UF: "SC",
    nome_da_cidade: "SAO LOURENCO DO OESTE",
  },
  {
    codigo_do_IGBE: 2210359,
    UF: "PI",
    nome_da_cidade: "SAO LOURENCO DO PIAUI",
  },
  {
    codigo_do_IGBE: 4318804,
    UF: "RS",
    nome_da_cidade: "SAO LOURENCO DO SUL",
  },
  {
    codigo_do_IGBE: 4217006,
    UF: "SC",
    nome_da_cidade: "SAO LUDGERO",
  },
  {
    codigo_do_IGBE: 2312601,
    UF: "CE",
    nome_da_cidade: "SAO LUIS DO CURU",
  },
  {
    codigo_do_IGBE: 3550001,
    UF: "SP",
    nome_da_cidade: "SAO LUIS DO PARAITINGA",
  },
  {
    codigo_do_IGBE: 2210375,
    UF: "PI",
    nome_da_cidade: "SAO LUIS DO PIAUI",
  },
  {
    codigo_do_IGBE: 2708501,
    UF: "AL",
    nome_da_cidade: "SAO LUIS DO QUITUNDE",
  },
  {
    codigo_do_IGBE: 2111409,
    UF: "MA",
    nome_da_cidade: "SAO LUIS GONZAGA DO MARANHAO",
  },
  {
    codigo_do_IGBE: 5220157,
    UF: "GO",
    nome_da_cidade: "SAO LUIZ DO NORTE",
  },
  {
    codigo_do_IGBE: 4318903,
    UF: "RS",
    nome_da_cidade: "SAO LUIZ GONZAGA",
  },
  {
    codigo_do_IGBE: 2514909,
    UF: "PB",
    nome_da_cidade: "SAO MAMEDE",
  },
  {
    codigo_do_IGBE: 3550100,
    UF: "SP",
    nome_da_cidade: "SAO MANUEL",
  },
  {
    codigo_do_IGBE: 4319000,
    UF: "RS",
    nome_da_cidade: "SAO MARCOS",
  },
  {
    codigo_do_IGBE: 4319109,
    UF: "RS",
    nome_da_cidade: "SAO MARTINHO",
  },
  {
    codigo_do_IGBE: 4217105,
    UF: "SC",
    nome_da_cidade: "SAO MARTINHO",
  },
  {
    codigo_do_IGBE: 4319125,
    UF: "RS",
    nome_da_cidade: "SAO MARTINHO DA SERRA",
  },
  {
    codigo_do_IGBE: 3204906,
    UF: "ES",
    nome_da_cidade: "SAO MATEUS",
  },
  {
    codigo_do_IGBE: 2111508,
    UF: "MA",
    nome_da_cidade: "SAO MATEUS DO MARANHAO",
  },
  {
    codigo_do_IGBE: 2111300,
    UF: "MA",
    nome_da_cidade: "SAO LUIS",
  },
  {
    codigo_do_IGBE: 2412500,
    UF: "RN",
    nome_da_cidade: "SAO MIGUEL",
  },
  {
    codigo_do_IGBE: 2210383,
    UF: "PI",
    nome_da_cidade: "SAO MIGUEL DA BAIXA GRANDE",
  },
  {
    codigo_do_IGBE: 4217154,
    UF: "SC",
    nome_da_cidade: "SAO MIGUEL DA BOA VISTA",
  },
  {
    codigo_do_IGBE: 2929404,
    UF: "BA",
    nome_da_cidade: "SAO MIGUEL DAS MATAS",
  },
  {
    codigo_do_IGBE: 4319158,
    UF: "RS",
    nome_da_cidade: "SAO MIGUEL DAS MISSOES",
  },
  {
    codigo_do_IGBE: 2515005,
    UF: "PB",
    nome_da_cidade: "SAO MIGUEL DE TAIPU",
  },
  {
    codigo_do_IGBE: 2807006,
    UF: "SE",
    nome_da_cidade: "SAO MIGUEL DO ALEIXO",
  },
  {
    codigo_do_IGBE: 3163805,
    UF: "MG",
    nome_da_cidade: "SAO MIGUEL DO ANTA",
  },
  {
    codigo_do_IGBE: 5220207,
    UF: "GO",
    nome_da_cidade: "SAO MIGUEL DO ARAGUAIA",
  },
  {
    codigo_do_IGBE: 2210391,
    UF: "PI",
    nome_da_cidade: "SAO MIGUEL DO FIDALGO",
  },
  {
    codigo_do_IGBE: 2412559,
    UF: "RN",
    nome_da_cidade: "SAO MIGUEL DO GOSTOSO",
  },
  {
    codigo_do_IGBE: 1507607,
    UF: "PA",
    nome_da_cidade: "SAO MIGUEL DO GUAMA",
  },
  {
    codigo_do_IGBE: 4125704,
    UF: "PR",
    nome_da_cidade: "SAO MIGUEL DO IGUACU",
  },
  {
    codigo_do_IGBE: 4217204,
    UF: "SC",
    nome_da_cidade: "SAO MIGUEL DO OESTE",
  },
  {
    codigo_do_IGBE: 2210409,
    UF: "PI",
    nome_da_cidade: "SAO MIGUEL DO TAPUIO",
  },
  {
    codigo_do_IGBE: 1720200,
    UF: "TO",
    nome_da_cidade: "SAO MIGUEL DO TOCANTINS",
  },
  {
    codigo_do_IGBE: 2708600,
    UF: "AL",
    nome_da_cidade: "SAO MIGUEL DOS CAMPOS",
  },
  {
    codigo_do_IGBE: 2708709,
    UF: "AL",
    nome_da_cidade: "SAO MIGUEL DOS MILAGRES",
  },
  {
    codigo_do_IGBE: 4319208,
    UF: "RS",
    nome_da_cidade: "SAO NICOLAU",
  },
  {
    codigo_do_IGBE: 5220280,
    UF: "GO",
    nome_da_cidade: "SAO PATRICIO",
  },
  {
    codigo_do_IGBE: 3550308,
    UF: "SP",
    nome_da_cidade: "SAO PAULO",
  },
  {
    codigo_do_IGBE: 4319307,
    UF: "RS",
    nome_da_cidade: "SAO PAULO DAS MISSOES",
  },
  {
    codigo_do_IGBE: 1303908,
    UF: "AM",
    nome_da_cidade: "SAO PAULO DE OLIVENCA",
  },
  {
    codigo_do_IGBE: 2412609,
    UF: "RN",
    nome_da_cidade: "SAO PAULO DO POTENGI",
  },
  {
    codigo_do_IGBE: 2412708,
    UF: "RN",
    nome_da_cidade: "SAO PEDRO",
  },
  {
    codigo_do_IGBE: 3550407,
    UF: "SP",
    nome_da_cidade: "SAO PEDRO",
  },
  {
    codigo_do_IGBE: 2111532,
    UF: "MA",
    nome_da_cidade: "SAO PEDRO DA AGUA BRANCA",
  },
  {
    codigo_do_IGBE: 3305208,
    UF: "RJ",
    nome_da_cidade: "SAO PEDRO DA ALDEIA",
  },
  {
    codigo_do_IGBE: 5107404,
    UF: "MT",
    nome_da_cidade: "SAO PEDRO DA CIPA",
  },
  {
    codigo_do_IGBE: 4319356,
    UF: "RS",
    nome_da_cidade: "SAO PEDRO DA SERRA",
  },
  {
    codigo_do_IGBE: 4319372,
    UF: "RS",
    nome_da_cidade: "SAO PEDRO DO BUTIA",
  },
  {
    codigo_do_IGBE: 4125753,
    UF: "PR",
    nome_da_cidade: "SAO PEDRO DO IGUACU",
  },
  {
    codigo_do_IGBE: 4125803,
    UF: "PR",
    nome_da_cidade: "SAO PEDRO DO IVAI",
  },
  {
    codigo_do_IGBE: 4125902,
    UF: "PR",
    nome_da_cidade: "SAO PEDRO DO PARANA",
  },
  {
    codigo_do_IGBE: 3164100,
    UF: "MG",
    nome_da_cidade: "SAO PEDRO DO SUACUI",
  },
  {
    codigo_do_IGBE: 4319406,
    UF: "RS",
    nome_da_cidade: "SAO PEDRO DO SUL",
  },
  {
    codigo_do_IGBE: 3550506,
    UF: "SP",
    nome_da_cidade: "SAO PEDRO DO TURVO",
  },
  {
    codigo_do_IGBE: 2111573,
    UF: "MA",
    nome_da_cidade: "SAO PEDRO DOS CRENTES",
  },
  {
    codigo_do_IGBE: 3164001,
    UF: "MG",
    nome_da_cidade: "SAO PEDRO DOS FERROS",
  },
  {
    codigo_do_IGBE: 2412807,
    UF: "RN",
    nome_da_cidade: "SAO RAFAEL",
  },
  {
    codigo_do_IGBE: 2111607,
    UF: "MA",
    nome_da_cidade: "SAO RAIMUNDO DAS MANGABEIRAS",
  },
  {
    codigo_do_IGBE: 2111631,
    UF: "MA",
    nome_da_cidade: "SAO RAIMUNDO DO DOCA BEZERRA",
  },
  {
    codigo_do_IGBE: 2210607,
    UF: "PI",
    nome_da_cidade: "SAO RAIMUNDO NONATO",
  },
  {
    codigo_do_IGBE: 2111672,
    UF: "MA",
    nome_da_cidade: "SAO ROBERTO",
  },
  {
    codigo_do_IGBE: 3164209,
    UF: "MG",
    nome_da_cidade: "SAO ROMAO",
  },
  {
    codigo_do_IGBE: 3550605,
    UF: "SP",
    nome_da_cidade: "SAO ROQUE",
  },
  {
    codigo_do_IGBE: 3204955,
    UF: "ES",
    nome_da_cidade: "SAO ROQUE DO CANAA",
  },
  {
    codigo_do_IGBE: 2708808,
    UF: "AL",
    nome_da_cidade: "SAO SEBASTIAO",
  },
  {
    codigo_do_IGBE: 3550704,
    UF: "SP",
    nome_da_cidade: "SAO SEBASTIAO",
  },
  {
    codigo_do_IGBE: 4126009,
    UF: "PR",
    nome_da_cidade: "SAO SEBASTIAO DA AMOREIRA",
  },
  {
    codigo_do_IGBE: 3164407,
    UF: "MG",
    nome_da_cidade: "SAO SEBASTIAO DA BELA VISTA",
  },
  {
    codigo_do_IGBE: 1507706,
    UF: "PA",
    nome_da_cidade: "SAO SEBASTIAO DA BOA VISTA",
  },
  {
    codigo_do_IGBE: 3550803,
    UF: "SP",
    nome_da_cidade: "SAO SEBASTIAO DA GRAMA",
  },
  {
    codigo_do_IGBE: 3164431,
    UF: "MG",
    nome_da_cidade: "SAO SEBASTIAO DA VARGEM ALEGRE",
  },
  {
    codigo_do_IGBE: 2515104,
    UF: "PB",
    nome_da_cidade: "SAO SEBASTIAO DE LAGOA DE ROCA",
  },
  {
    codigo_do_IGBE: 3305307,
    UF: "RJ",
    nome_da_cidade: "SAO SEBASTIAO DO ALTO",
  },
  {
    codigo_do_IGBE: 3164472,
    UF: "MG",
    nome_da_cidade: "SAO SEBASTIAO DO ANTA",
  },
  {
    codigo_do_IGBE: 4319505,
    UF: "RS",
    nome_da_cidade: "SAO SEBASTIAO DO CAI",
  },
  {
    codigo_do_IGBE: 3164605,
    UF: "MG",
    nome_da_cidade: "SAO SEBASTIAO DO OESTE",
  },
  {
    codigo_do_IGBE: 3164704,
    UF: "MG",
    nome_da_cidade: "SAO SEBASTIAO DO PARAISO",
  },
  {
    codigo_do_IGBE: 3164803,
    UF: "MG",
    nome_da_cidade: "SAO SEBASTIAO DO RIO PRETO",
  },
  {
    codigo_do_IGBE: 3164902,
    UF: "MG",
    nome_da_cidade: "SAO SEBASTIAO DO RIO VERDE",
  },
  {
    codigo_do_IGBE: 1720309,
    UF: "TO",
    nome_da_cidade: "SAO SEBASTIAO DO TOCANTINS",
  },
  {
    codigo_do_IGBE: 1303957,
    UF: "AM",
    nome_da_cidade: "SAO SEBASTIAO DO UATUMA",
  },
  {
    codigo_do_IGBE: 2515203,
    UF: "PB",
    nome_da_cidade: "SAO SEBASTIAO DO UMBUZEIRO",
  },
  {
    codigo_do_IGBE: 4319604,
    UF: "RS",
    nome_da_cidade: "SAO SEPE",
  },
  {
    codigo_do_IGBE: 5220405,
    UF: "GO",
    nome_da_cidade: "SAO SIMAO",
  },
  {
    codigo_do_IGBE: 3550902,
    UF: "SP",
    nome_da_cidade: "SAO SIMAO",
  },
  {
    codigo_do_IGBE: 3165206,
    UF: "MG",
    nome_da_cidade: "SAO THOME DAS LETRAS",
  },
  {
    codigo_do_IGBE: 3165008,
    UF: "MG",
    nome_da_cidade: "SAO TIAGO",
  },
  {
    codigo_do_IGBE: 3165107,
    UF: "MG",
    nome_da_cidade: "SAO TOMAS DE AQUINO",
  },
  {
    codigo_do_IGBE: 4126108,
    UF: "PR",
    nome_da_cidade: "SAO TOME",
  },
  {
    codigo_do_IGBE: 2412906,
    UF: "RN",
    nome_da_cidade: "SAO TOME",
  },
  {
    codigo_do_IGBE: 4319703,
    UF: "RS",
    nome_da_cidade: "SAO VALENTIM",
  },
  {
    codigo_do_IGBE: 4319711,
    UF: "RS",
    nome_da_cidade: "SAO VALENTIM DO SUL",
  },
  {
    codigo_do_IGBE: 4319737,
    UF: "RS",
    nome_da_cidade: "SAO VALERIO DO SUL",
  },
  {
    codigo_do_IGBE: 4319752,
    UF: "RS",
    nome_da_cidade: "SAO VENDELINO",
  },
  {
    codigo_do_IGBE: 2413003,
    UF: "RN",
    nome_da_cidade: "SAO VICENTE",
  },
  {
    codigo_do_IGBE: 3551009,
    UF: "SP",
    nome_da_cidade: "SAO VICENTE",
  },
  {
    codigo_do_IGBE: 4319802,
    UF: "RS",
    nome_da_cidade: "SAO VICENTE DO SUL",
  },
  {
    codigo_do_IGBE: 2111706,
    UF: "MA",
    nome_da_cidade: "SAO VICENTE FERRER",
  },
  {
    codigo_do_IGBE: 2613800,
    UF: "PE",
    nome_da_cidade: "SAO VICENTE FERRER",
  },
  {
    codigo_do_IGBE: 2515302,
    UF: "PB",
    nome_da_cidade: "SAPE",
  },
  {
    codigo_do_IGBE: 2929602,
    UF: "BA",
    nome_da_cidade: "SAPEACU",
  },
  {
    codigo_do_IGBE: 5107875,
    UF: "MT",
    nome_da_cidade: "SAPEZAL",
  },
  {
    codigo_do_IGBE: 4319901,
    UF: "RS",
    nome_da_cidade: "SAPIRANGA",
  },
  {
    codigo_do_IGBE: 4126207,
    UF: "PR",
    nome_da_cidade: "SAPOPEMA",
  },
  {
    codigo_do_IGBE: 1507755,
    UF: "PA",
    nome_da_cidade: "SAPUCAIA",
  },
  {
    codigo_do_IGBE: 3305406,
    UF: "RJ",
    nome_da_cidade: "SAPUCAIA",
  },
  {
    codigo_do_IGBE: 4320008,
    UF: "RS",
    nome_da_cidade: "SAPUCAIA DO SUL",
  },
  {
    codigo_do_IGBE: 3165404,
    UF: "MG",
    nome_da_cidade: "SAPUCAI-MIRIM",
  },
  {
    codigo_do_IGBE: 3305505,
    UF: "RJ",
    nome_da_cidade: "SAQUAREMA",
  },
  {
    codigo_do_IGBE: 4126256,
    UF: "PR",
    nome_da_cidade: "SARANDI",
  },
  {
    codigo_do_IGBE: 3551108,
    UF: "SP",
    nome_da_cidade: "SARAPUI",
  },
  {
    codigo_do_IGBE: 3551207,
    UF: "SP",
    nome_da_cidade: "SARUTAIA",
  },
  {
    codigo_do_IGBE: 3165537,
    UF: "MG",
    nome_da_cidade: "SARZEDO",
  },
  {
    codigo_do_IGBE: 2929701,
    UF: "BA",
    nome_da_cidade: "SATIRO DIAS",
  },
  {
    codigo_do_IGBE: 2708907,
    UF: "AL",
    nome_da_cidade: "SATUBA",
  },
  {
    codigo_do_IGBE: 2111722,
    UF: "MA",
    nome_da_cidade: "SATUBINHA",
  },
  {
    codigo_do_IGBE: 2929750,
    UF: "BA",
    nome_da_cidade: "SAUBARA",
  },
  {
    codigo_do_IGBE: 4126272,
    UF: "PR",
    nome_da_cidade: "SAUDADE DO IGUACU",
  },
  {
    codigo_do_IGBE: 4217303,
    UF: "SC",
    nome_da_cidade: "SAUDADES",
  },
  {
    codigo_do_IGBE: 2929800,
    UF: "BA",
    nome_da_cidade: "SAUDE",
  },
  {
    codigo_do_IGBE: 4217402,
    UF: "SC",
    nome_da_cidade: "SCHROEDER",
  },
  {
    codigo_do_IGBE: 4217501,
    UF: "SC",
    nome_da_cidade: "SEARA",
  },
  {
    codigo_do_IGBE: 2210623,
    UF: "PI",
    nome_da_cidade: "SEBASTIAO BARROS",
  },
  {
    codigo_do_IGBE: 2930006,
    UF: "BA",
    nome_da_cidade: "SEBASTIAO LARANJEIRAS",
  },
  {
    codigo_do_IGBE: 2210631,
    UF: "PI",
    nome_da_cidade: "SEBASTIAO LEAL",
  },
  {
    codigo_do_IGBE: 4320206,
    UF: "RS",
    nome_da_cidade: "SEBERI",
  },
  {
    codigo_do_IGBE: 4320230,
    UF: "RS",
    nome_da_cidade: "SEDE NOVA",
  },
  {
    codigo_do_IGBE: 4320263,
    UF: "RS",
    nome_da_cidade: "SEGREDO",
  },
  {
    codigo_do_IGBE: 4320305,
    UF: "RS",
    nome_da_cidade: "SELBACH",
  },
  {
    codigo_do_IGBE: 5007802,
    UF: "MS",
    nome_da_cidade: "SELVIRIA",
  },
  {
    codigo_do_IGBE: 3165560,
    UF: "MG",
    nome_da_cidade: "SEM-PEIXE",
  },
  {
    codigo_do_IGBE: 2111748,
    UF: "MA",
    nome_da_cidade: "SENADOR ALEXANDRE COSTA",
  },
  {
    codigo_do_IGBE: 3165578,
    UF: "MG",
    nome_da_cidade: "SENADOR AMARAL",
  },
  {
    codigo_do_IGBE: 5220454,
    UF: "GO",
    nome_da_cidade: "SENADOR CANEDO",
  },
  {
    codigo_do_IGBE: 3165602,
    UF: "MG",
    nome_da_cidade: "SENADOR CORTES",
  },
  {
    codigo_do_IGBE: 3165701,
    UF: "MG",
    nome_da_cidade: "SENADOR FIRMINO",
  },
  {
    codigo_do_IGBE: 2413201,
    UF: "RN",
    nome_da_cidade: "SENADOR GEORGINO AVELINO",
  },
  {
    codigo_do_IGBE: 1200450,
    UF: "AC",
    nome_da_cidade: "SENADOR GUIOMARD",
  },
  {
    codigo_do_IGBE: 3165800,
    UF: "MG",
    nome_da_cidade: "SENADOR JOSE BENTO",
  },
  {
    codigo_do_IGBE: 1507805,
    UF: "PA",
    nome_da_cidade: "SENADOR JOSE PORFIRIO",
  },
  {
    codigo_do_IGBE: 2111763,
    UF: "MA",
    nome_da_cidade: "SENADOR LA ROCQUE",
  },
  {
    codigo_do_IGBE: 2312700,
    UF: "CE",
    nome_da_cidade: "SENADOR POMPEU",
  },
  {
    codigo_do_IGBE: 2708956,
    UF: "AL",
    nome_da_cidade: "SENADOR RUI PALMEIRA",
  },
  {
    codigo_do_IGBE: 2312809,
    UF: "CE",
    nome_da_cidade: "SENADOR SA",
  },
  {
    codigo_do_IGBE: 4126306,
    UF: "PR",
    nome_da_cidade: "SENGES",
  },
  {
    codigo_do_IGBE: 2930105,
    UF: "BA",
    nome_da_cidade: "SENHOR DO BONFIM",
  },
  {
    codigo_do_IGBE: 3166105,
    UF: "MG",
    nome_da_cidade: "SENHORA DO PORTO",
  },
  {
    codigo_do_IGBE: 3166204,
    UF: "MG",
    nome_da_cidade: "SENHORA DOS REMEDIOS",
  },
  {
    codigo_do_IGBE: 4320354,
    UF: "RS",
    nome_da_cidade: "SENTINELA DO SUL",
  },
  {
    codigo_do_IGBE: 2930204,
    UF: "BA",
    nome_da_cidade: "SENTO SE",
  },
  {
    codigo_do_IGBE: 4320404,
    UF: "RS",
    nome_da_cidade: "SERAFINA CORREA",
  },
  {
    codigo_do_IGBE: 3166303,
    UF: "MG",
    nome_da_cidade: "SERICITA",
  },
  {
    codigo_do_IGBE: 2515401,
    UF: "PB",
    nome_da_cidade: "SERIDO",
  },
  {
    codigo_do_IGBE: 1101500,
    UF: "RO",
    nome_da_cidade: "SERINGUEIRAS",
  },
  {
    codigo_do_IGBE: 4320453,
    UF: "RS",
    nome_da_cidade: "SERIO",
  },
  {
    codigo_do_IGBE: 3166402,
    UF: "MG",
    nome_da_cidade: "SERITINGA",
  },
  {
    codigo_do_IGBE: 3305554,
    UF: "RJ",
    nome_da_cidade: "SEROPEDICA",
  },
  {
    codigo_do_IGBE: 3205002,
    UF: "ES",
    nome_da_cidade: "SERRA",
  },
  {
    codigo_do_IGBE: 4217550,
    UF: "SC",
    nome_da_cidade: "SERRA ALTA",
  },
  {
    codigo_do_IGBE: 3166501,
    UF: "MG",
    nome_da_cidade: "SERRA AZUL DE MINAS",
  },
  {
    codigo_do_IGBE: 2515500,
    UF: "PB",
    nome_da_cidade: "SERRA BRANCA",
  },
  {
    codigo_do_IGBE: 3166600,
    UF: "MG",
    nome_da_cidade: "SERRA DA SAUDADE",
  },
  {
    codigo_do_IGBE: 2413300,
    UF: "RN",
    nome_da_cidade: "SERRA DE SAO BENTO",
  },
  {
    codigo_do_IGBE: 2413359,
    UF: "RN",
    nome_da_cidade: "SERRA DO MEL",
  },
  {
    codigo_do_IGBE: 1600055,
    UF: "AP",
    nome_da_cidade: "SERRA DO NAVIO",
  },
  {
    codigo_do_IGBE: 2930154,
    UF: "BA",
    nome_da_cidade: "SERRA DO RAMALHO",
  },
  {
    codigo_do_IGBE: 3166709,
    UF: "MG",
    nome_da_cidade: "SERRA DOS AIMORES",
  },
  {
    codigo_do_IGBE: 2930303,
    UF: "BA",
    nome_da_cidade: "SERRA DOURADA",
  },
  {
    codigo_do_IGBE: 2515708,
    UF: "PB",
    nome_da_cidade: "SERRA GRANDE",
  },
  {
    codigo_do_IGBE: 3551603,
    UF: "SP",
    nome_da_cidade: "SERRA NEGRA",
  },
  {
    codigo_do_IGBE: 2413409,
    UF: "RN",
    nome_da_cidade: "SERRA NEGRA DO NORTE",
  },
  {
    codigo_do_IGBE: 5107883,
    UF: "MT",
    nome_da_cidade: "SERRA NOVA DOURADA",
  },
  {
    codigo_do_IGBE: 2930402,
    UF: "BA",
    nome_da_cidade: "SERRA PRETA",
  },
  {
    codigo_do_IGBE: 2515807,
    UF: "PB",
    nome_da_cidade: "SERRA REDONDA",
  },
  {
    codigo_do_IGBE: 3551504,
    UF: "SP",
    nome_da_cidade: "SERRANA",
  },
  {
    codigo_do_IGBE: 3166907,
    UF: "MG",
    nome_da_cidade: "SERRANIA",
  },
  {
    codigo_do_IGBE: 3551702,
    UF: "SP",
    nome_da_cidade: "SERTAOZINHO",
  },
  {
    codigo_do_IGBE: 3551801,
    UF: "SP",
    nome_da_cidade: "SETE BARRAS",
  },
  {
    codigo_do_IGBE: 3167202,
    UF: "MG",
    nome_da_cidade: "SETE LAGOAS",
  },
  {
    codigo_do_IGBE: 5007703,
    UF: "MS",
    nome_da_cidade: "SETE QUEDAS",
  },
  {
    codigo_do_IGBE: 3165552,
    UF: "MG",
    nome_da_cidade: "SETUBINHA",
  },
  {
    codigo_do_IGBE: 4320602,
    UF: "RS",
    nome_da_cidade: "SEVERIANO DE ALMEIDA",
  },
  {
    codigo_do_IGBE: 2413607,
    UF: "RN",
    nome_da_cidade: "SEVERIANO MELO",
  },
  {
    codigo_do_IGBE: 3551900,
    UF: "SP",
    nome_da_cidade: "SEVERINIA",
  },
  {
    codigo_do_IGBE: 4217600,
    UF: "SC",
    nome_da_cidade: "SIDEROPOLIS",
  },
  {
    codigo_do_IGBE: 5007901,
    UF: "MS",
    nome_da_cidade: "SIDROLANDIA",
  },
  {
    codigo_do_IGBE: 2210656,
    UF: "PI",
    nome_da_cidade: "SIGEFREDO PACHECO",
  },
  {
    codigo_do_IGBE: 3305604,
    UF: "RJ",
    nome_da_cidade: "SILVA JARDIM",
  },
  {
    codigo_do_IGBE: 5220603,
    UF: "GO",
    nome_da_cidade: "SILVANIA",
  },
  {
    codigo_do_IGBE: 1720655,
    UF: "TO",
    nome_da_cidade: "SILVANOPOLIS",
  },
  {
    codigo_do_IGBE: 4320651,
    UF: "RS",
    nome_da_cidade: "SILVEIRA MARTINS",
  },
  {
    codigo_do_IGBE: 3167301,
    UF: "MG",
    nome_da_cidade: "SILVEIRANIA",
  },
  {
    codigo_do_IGBE: 3552007,
    UF: "SP",
    nome_da_cidade: "SILVEIRAS",
  },
  {
    codigo_do_IGBE: 3167400,
    UF: "MG",
    nome_da_cidade: "SILVIANOPOLIS",
  },
  {
    codigo_do_IGBE: 2807105,
    UF: "SE",
    nome_da_cidade: "SIMAO DIAS",
  },
  {
    codigo_do_IGBE: 3167509,
    UF: "MG",
    nome_da_cidade: "SIMAO PEREIRA",
  },
  {
    codigo_do_IGBE: 2210706,
    UF: "PI",
    nome_da_cidade: "SIMOES",
  },
  {
    codigo_do_IGBE: 2930709,
    UF: "BA",
    nome_da_cidade: "SIMOES FILHO",
  },
  {
    codigo_do_IGBE: 5220686,
    UF: "GO",
    nome_da_cidade: "SIMOLANDIA",
  },
  {
    codigo_do_IGBE: 3167608,
    UF: "MG",
    nome_da_cidade: "SIMONESIA",
  },
  {
    codigo_do_IGBE: 2210805,
    UF: "PI",
    nome_da_cidade: "SIMPLICIO MENDES",
  },
  {
    codigo_do_IGBE: 4320677,
    UF: "RS",
    nome_da_cidade: "SINIMBU",
  },
  {
    codigo_do_IGBE: 5107909,
    UF: "MT",
    nome_da_cidade: "SINOP",
  },
  {
    codigo_do_IGBE: 2614204,
    UF: "PE",
    nome_da_cidade: "SIRINHAEM",
  },
  {
    codigo_do_IGBE: 2807204,
    UF: "SE",
    nome_da_cidade: "SIRIRI",
  },
  {
    codigo_do_IGBE: 5220702,
    UF: "GO",
    nome_da_cidade: "SITIO D'ABADIA",
  },
  {
    codigo_do_IGBE: 2930758,
    UF: "BA",
    nome_da_cidade: "SITIO DO MATO",
  },
  {
    codigo_do_IGBE: 2930766,
    UF: "BA",
    nome_da_cidade: "SITIO DO QUINTO",
  },
  {
    codigo_do_IGBE: 2111805,
    UF: "MA",
    nome_da_cidade: "SITIO NOVO",
  },
  {
    codigo_do_IGBE: 2413706,
    UF: "RN",
    nome_da_cidade: "SITIO NOVO",
  },
  {
    codigo_do_IGBE: 1720804,
    UF: "TO",
    nome_da_cidade: "SITIO NOVO DO TOCANTINS",
  },
  {
    codigo_do_IGBE: 2515971,
    UF: "PB",
    nome_da_cidade: "SOBRADO",
  },
  {
    codigo_do_IGBE: 2312908,
    UF: "CE",
    nome_da_cidade: "SOBRAL",
  },
  {
    codigo_do_IGBE: 3167707,
    UF: "MG",
    nome_da_cidade: "SOBRALIA",
  },
  {
    codigo_do_IGBE: 3552106,
    UF: "SP",
    nome_da_cidade: "SOCORRO",
  },
  {
    codigo_do_IGBE: 2210904,
    UF: "PI",
    nome_da_cidade: "SOCORRO DO PIAUI",
  },
  {
    codigo_do_IGBE: 2516003,
    UF: "PB",
    nome_da_cidade: "SOLANEA",
  },
  {
    codigo_do_IGBE: 2516102,
    UF: "PB",
    nome_da_cidade: "SOLEDADE",
  },
  {
    codigo_do_IGBE: 3167806,
    UF: "MG",
    nome_da_cidade: "SOLEDADE DE MINAS",
  },
  {
    codigo_do_IGBE: 2614402,
    UF: "PE",
    nome_da_cidade: "SOLIDAO",
  },
  {
    codigo_do_IGBE: 2313005,
    UF: "CE",
    nome_da_cidade: "SOLONOPOLE",
  },
  {
    codigo_do_IGBE: 4217709,
    UF: "SC",
    nome_da_cidade: "SOMBRIO",
  },
  {
    codigo_do_IGBE: 5007935,
    UF: "MS",
    nome_da_cidade: "SONORA",
  },
  {
    codigo_do_IGBE: 3205010,
    UF: "ES",
    nome_da_cidade: "SOORETAMA",
  },
  {
    codigo_do_IGBE: 3552205,
    UF: "SP",
    nome_da_cidade: "SOROCABA",
  },
  {
    codigo_do_IGBE: 5107925,
    UF: "MT",
    nome_da_cidade: "SORRISO",
  },
  {
    codigo_do_IGBE: 2516151,
    UF: "PB",
    nome_da_cidade: "SOSSEGO",
  },
  {
    codigo_do_IGBE: 1507904,
    UF: "PA",
    nome_da_cidade: "SOURE",
  },
  {
    codigo_do_IGBE: 2516201,
    UF: "PB",
    nome_da_cidade: "SOUSA",
  },
  {
    codigo_do_IGBE: 2930808,
    UF: "BA",
    nome_da_cidade: "SOUTO SOARES",
  },
  {
    codigo_do_IGBE: 2111904,
    UF: "MA",
    nome_da_cidade: "SUCUPIRA DO NORTE",
  },
  {
    codigo_do_IGBE: 2111953,
    UF: "MA",
    nome_da_cidade: "SUCUPIRA DO RIACHAO",
  },
  {
    codigo_do_IGBE: 3552304,
    UF: "SP",
    nome_da_cidade: "SUD MENNUCCI",
  },
  {
    codigo_do_IGBE: 4217758,
    UF: "SC",
    nome_da_cidade: "SUL BRASIL",
  },
  {
    codigo_do_IGBE: 4126652,
    UF: "PR",
    nome_da_cidade: "SULINA",
  },
  {
    codigo_do_IGBE: 3552403,
    UF: "SP",
    nome_da_cidade: "SUMARE",
  },
  {
    codigo_do_IGBE: 2516300,
    UF: "PB",
    nome_da_cidade: "SUME",
  },
  {
    codigo_do_IGBE: 3305703,
    UF: "RJ",
    nome_da_cidade: "SUMIDOURO",
  },
  {
    codigo_do_IGBE: 2614501,
    UF: "PE",
    nome_da_cidade: "SURUBIM",
  },
  {
    codigo_do_IGBE: 2210938,
    UF: "PI",
    nome_da_cidade: "SUSSUAPARA",
  },
  {
    codigo_do_IGBE: 3552551,
    UF: "SP",
    nome_da_cidade: "SUZANAPOLIS",
  },
  {
    codigo_do_IGBE: 3552502,
    UF: "SP",
    nome_da_cidade: "SUZANO",
  },
  {
    codigo_do_IGBE: 5107941,
    UF: "MT",
    nome_da_cidade: "TABAPORA",
  },
  {
    codigo_do_IGBE: 3552601,
    UF: "SP",
    nome_da_cidade: "TABAPUA",
  },
  {
    codigo_do_IGBE: 1304062,
    UF: "AM",
    nome_da_cidade: "TABATINGA",
  },
  {
    codigo_do_IGBE: 3552700,
    UF: "SP",
    nome_da_cidade: "TABATINGA",
  },
  {
    codigo_do_IGBE: 2614600,
    UF: "PE",
    nome_da_cidade: "TABIRA",
  },
  {
    codigo_do_IGBE: 3552809,
    UF: "SP",
    nome_da_cidade: "TABOAO DA SERRA",
  },
  {
    codigo_do_IGBE: 2930907,
    UF: "BA",
    nome_da_cidade: "TABOCAS DO BREJO VELHO",
  },
  {
    codigo_do_IGBE: 2413805,
    UF: "RN",
    nome_da_cidade: "TABOLEIRO GRANDE",
  },
  {
    codigo_do_IGBE: 3167905,
    UF: "MG",
    nome_da_cidade: "TABULEIRO",
  },
  {
    codigo_do_IGBE: 2313104,
    UF: "CE",
    nome_da_cidade: "TABULEIRO DO NORTE",
  },
  {
    codigo_do_IGBE: 2614709,
    UF: "PE",
    nome_da_cidade: "TACAIMBO",
  },
  {
    codigo_do_IGBE: 2614808,
    UF: "PE",
    nome_da_cidade: "TACARATU",
  },
  {
    codigo_do_IGBE: 5007950,
    UF: "MS",
    nome_da_cidade: "TACURU",
  },
  {
    codigo_do_IGBE: 3553005,
    UF: "SP",
    nome_da_cidade: "TAGUAI",
  },
  {
    codigo_do_IGBE: 1720903,
    UF: "TO",
    nome_da_cidade: "TAGUATINGA",
  },
  {
    codigo_do_IGBE: 3553104,
    UF: "SP",
    nome_da_cidade: "TAIACU",
  },
  {
    codigo_do_IGBE: 1507953,
    UF: "PA",
    nome_da_cidade: "TAILANDIA",
  },
  {
    codigo_do_IGBE: 4217808,
    UF: "SC",
    nome_da_cidade: "TAIO",
  },
  {
    codigo_do_IGBE: 3168002,
    UF: "MG",
    nome_da_cidade: "TAIOBEIRAS",
  },
  {
    codigo_do_IGBE: 1720937,
    UF: "TO",
    nome_da_cidade: "TAIPAS DO TOCANTINS",
  },
  {
    codigo_do_IGBE: 2413904,
    UF: "RN",
    nome_da_cidade: "TAIPU",
  },
  {
    codigo_do_IGBE: 3553203,
    UF: "SP",
    nome_da_cidade: "TAIUVA",
  },
  {
    codigo_do_IGBE: 1720978,
    UF: "TO",
    nome_da_cidade: "TALISMA",
  },
  {
    codigo_do_IGBE: 2614857,
    UF: "PE",
    nome_da_cidade: "TAMANDARE",
  },
  {
    codigo_do_IGBE: 3553302,
    UF: "SP",
    nome_da_cidade: "TAMBAU",
  },
  {
    codigo_do_IGBE: 4126702,
    UF: "PR",
    nome_da_cidade: "TAMBOARA",
  },
  {
    codigo_do_IGBE: 2313203,
    UF: "CE",
    nome_da_cidade: "TAMBORIL",
  },
  {
    codigo_do_IGBE: 2210953,
    UF: "PI",
    nome_da_cidade: "TAMBORIL DO PIAUI",
  },
  {
    codigo_do_IGBE: 3553401,
    UF: "SP",
    nome_da_cidade: "TANABI",
  },
  {
    codigo_do_IGBE: 2414001,
    UF: "RN",
    nome_da_cidade: "TANGARA",
  },
  {
    codigo_do_IGBE: 4217907,
    UF: "SC",
    nome_da_cidade: "TANGARA",
  },
  {
    codigo_do_IGBE: 2930774,
    UF: "BA",
    nome_da_cidade: "SOBRADINHO",
  },
  {
    codigo_do_IGBE: 3305752,
    UF: "RJ",
    nome_da_cidade: "TANGUA",
  },
  {
    codigo_do_IGBE: 2931004,
    UF: "BA",
    nome_da_cidade: "TANHACU",
  },
  {
    codigo_do_IGBE: 2210979,
    UF: "PI",
    nome_da_cidade: "TANQUE DO PIAUI",
  },
  {
    codigo_do_IGBE: 2931053,
    UF: "BA",
    nome_da_cidade: "TANQUE NOVO",
  },
  {
    codigo_do_IGBE: 2931103,
    UF: "BA",
    nome_da_cidade: "TANQUINHO",
  },
  {
    codigo_do_IGBE: 3168051,
    UF: "MG",
    nome_da_cidade: "TAPARUBA",
  },
  {
    codigo_do_IGBE: 1304104,
    UF: "AM",
    nome_da_cidade: "TAPAUA",
  },
  {
    codigo_do_IGBE: 4126801,
    UF: "PR",
    nome_da_cidade: "TAPEJARA",
  },
  {
    codigo_do_IGBE: 4320909,
    UF: "RS",
    nome_da_cidade: "TAPEJARA",
  },
  {
    codigo_do_IGBE: 4321006,
    UF: "RS",
    nome_da_cidade: "TAPERA",
  },
  {
    codigo_do_IGBE: 2931202,
    UF: "BA",
    nome_da_cidade: "TAPEROA",
  },
  {
    codigo_do_IGBE: 4321105,
    UF: "RS",
    nome_da_cidade: "TAPES",
  },
  {
    codigo_do_IGBE: 4126900,
    UF: "PR",
    nome_da_cidade: "TAPIRA",
  },
  {
    codigo_do_IGBE: 3168200,
    UF: "MG",
    nome_da_cidade: "TAPIRAI",
  },
  {
    codigo_do_IGBE: 3553500,
    UF: "SP",
    nome_da_cidade: "TAPIRAI",
  },
  {
    codigo_do_IGBE: 2931301,
    UF: "BA",
    nome_da_cidade: "TAPIRAMUTA",
  },
  {
    codigo_do_IGBE: 3553609,
    UF: "SP",
    nome_da_cidade: "TAPIRATIBA",
  },
  {
    codigo_do_IGBE: 5108006,
    UF: "MT",
    nome_da_cidade: "TAPURAH",
  },
  {
    codigo_do_IGBE: 3168309,
    UF: "MG",
    nome_da_cidade: "TAQUARACU DE MINAS",
  },
  {
    codigo_do_IGBE: 3553658,
    UF: "SP",
    nome_da_cidade: "TAQUARAL",
  },
  {
    codigo_do_IGBE: 5221007,
    UF: "GO",
    nome_da_cidade: "TAQUARAL DE GOIAS",
  },
  {
    codigo_do_IGBE: 2709103,
    UF: "AL",
    nome_da_cidade: "TAQUARANA",
  },
  {
    codigo_do_IGBE: 4321303,
    UF: "RS",
    nome_da_cidade: "TAQUARI",
  },
  {
    codigo_do_IGBE: 2615003,
    UF: "PE",
    nome_da_cidade: "TAQUARITINGA DO NORTE",
  },
  {
    codigo_do_IGBE: 3553807,
    UF: "SP",
    nome_da_cidade: "TAQUARITUBA",
  },
  {
    codigo_do_IGBE: 3553856,
    UF: "SP",
    nome_da_cidade: "TAQUARIVAI",
  },
  {
    codigo_do_IGBE: 4321329,
    UF: "RS",
    nome_da_cidade: "TAQUARUCU DO SUL",
  },
  {
    codigo_do_IGBE: 5007976,
    UF: "MS",
    nome_da_cidade: "TAQUARUSSU",
  },
  {
    codigo_do_IGBE: 3553906,
    UF: "SP",
    nome_da_cidade: "TARABAI",
  },
  {
    codigo_do_IGBE: 2313252,
    UF: "CE",
    nome_da_cidade: "TARRAFAS",
  },
  {
    codigo_do_IGBE: 1600709,
    UF: "AP",
    nome_da_cidade: "TARTARUGALZINHO",
  },
  {
    codigo_do_IGBE: 3553955,
    UF: "SP",
    nome_da_cidade: "TARUMA",
  },
  {
    codigo_do_IGBE: 3168408,
    UF: "MG",
    nome_da_cidade: "TARUMIRIM",
  },
  {
    codigo_do_IGBE: 3554003,
    UF: "SP",
    nome_da_cidade: "TATUI",
  },
  {
    codigo_do_IGBE: 2313302,
    UF: "CE",
    nome_da_cidade: "TAUA",
  },
  {
    codigo_do_IGBE: 3554102,
    UF: "SP",
    nome_da_cidade: "TAUBATE",
  },
  {
    codigo_do_IGBE: 2516607,
    UF: "PB",
    nome_da_cidade: "TAVARES",
  },
  {
    codigo_do_IGBE: 4321352,
    UF: "RS",
    nome_da_cidade: "TAVARES",
  },
  {
    codigo_do_IGBE: 1304203,
    UF: "AM",
    nome_da_cidade: "TEFE",
  },
  {
    codigo_do_IGBE: 2516706,
    UF: "PB",
    nome_da_cidade: "TEIXEIRA",
  },
  {
    codigo_do_IGBE: 4127007,
    UF: "PR",
    nome_da_cidade: "TEIXEIRA SOARES",
  },
  {
    codigo_do_IGBE: 3168507,
    UF: "MG",
    nome_da_cidade: "TEIXEIRAS",
  },
  {
    codigo_do_IGBE: 1101559,
    UF: "RO",
    nome_da_cidade: "TEIXEIROPOLIS",
  },
  {
    codigo_do_IGBE: 2313351,
    UF: "CE",
    nome_da_cidade: "TEJUCUOCA",
  },
  {
    codigo_do_IGBE: 3554201,
    UF: "SP",
    nome_da_cidade: "TEJUPA",
  },
  {
    codigo_do_IGBE: 4127106,
    UF: "PR",
    nome_da_cidade: "TELEMACO BORBA",
  },
  {
    codigo_do_IGBE: 2807303,
    UF: "SE",
    nome_da_cidade: "TELHA",
  },
  {
    codigo_do_IGBE: 2414159,
    UF: "RN",
    nome_da_cidade: "TENENTE LAURENTINO CRUZ",
  },
  {
    codigo_do_IGBE: 4321402,
    UF: "RS",
    nome_da_cidade: "TENENTE PORTELA",
  },
  {
    codigo_do_IGBE: 2516755,
    UF: "PB",
    nome_da_cidade: "TENORIO",
  },
  {
    codigo_do_IGBE: 2931400,
    UF: "BA",
    nome_da_cidade: "TEODORO SAMPAIO",
  },
  {
    codigo_do_IGBE: 2931509,
    UF: "BA",
    nome_da_cidade: "TEOFILANDIA",
  },
  {
    codigo_do_IGBE: 3168606,
    UF: "MG",
    nome_da_cidade: "TEOFILO OTONI",
  },
  {
    codigo_do_IGBE: 2931608,
    UF: "BA",
    nome_da_cidade: "TEOLANDIA",
  },
  {
    codigo_do_IGBE: 2709152,
    UF: "AL",
    nome_da_cidade: "TEOTONIO VILELA",
  },
  {
    codigo_do_IGBE: 5008008,
    UF: "MS",
    nome_da_cidade: "TERENOS",
  },
  {
    codigo_do_IGBE: 2211001,
    UF: "PI",
    nome_da_cidade: "TERESINA",
  },
  {
    codigo_do_IGBE: 5221080,
    UF: "GO",
    nome_da_cidade: "TERESINA DE GOIAS",
  },
  {
    codigo_do_IGBE: 3305802,
    UF: "RJ",
    nome_da_cidade: "TERESOPOLIS",
  },
  {
    codigo_do_IGBE: 2615102,
    UF: "PE",
    nome_da_cidade: "TEREZINHA",
  },
  {
    codigo_do_IGBE: 5221197,
    UF: "GO",
    nome_da_cidade: "TEREZOPOLIS DE GOIAS",
  },
  {
    codigo_do_IGBE: 1507961,
    UF: "PA",
    nome_da_cidade: "TERRA ALTA",
  },
  {
    codigo_do_IGBE: 4127205,
    UF: "PR",
    nome_da_cidade: "TERRA BOA",
  },
  {
    codigo_do_IGBE: 2931707,
    UF: "BA",
    nome_da_cidade: "TERRA NOVA",
  },
  {
    codigo_do_IGBE: 2615201,
    UF: "PE",
    nome_da_cidade: "TERRA NOVA",
  },
  {
    codigo_do_IGBE: 4127304,
    UF: "PR",
    nome_da_cidade: "TERRA RICA",
  },
  {
    codigo_do_IGBE: 4127403,
    UF: "PR",
    nome_da_cidade: "TERRA ROXA",
  },
  {
    codigo_do_IGBE: 3554409,
    UF: "SP",
    nome_da_cidade: "TERRA ROXA",
  },
  {
    codigo_do_IGBE: 1507979,
    UF: "PA",
    nome_da_cidade: "TERRA SANTA",
  },
  {
    codigo_do_IGBE: 5108105,
    UF: "MT",
    nome_da_cidade: "TESOURO",
  },
  {
    codigo_do_IGBE: 4321451,
    UF: "RS",
    nome_da_cidade: "TEUTONIA",
  },
  {
    codigo_do_IGBE: 1101609,
    UF: "RO",
    nome_da_cidade: "THEOBROMA",
  },
  {
    codigo_do_IGBE: 2313401,
    UF: "CE",
    nome_da_cidade: "TIANGUA",
  },
  {
    codigo_do_IGBE: 4127502,
    UF: "PR",
    nome_da_cidade: "TIBAGI",
  },
  {
    codigo_do_IGBE: 2411056,
    UF: "RN",
    nome_da_cidade: "TIBAU",
  },
  {
    codigo_do_IGBE: 2414209,
    UF: "RN",
    nome_da_cidade: "TIBAU DO SUL",
  },
  {
    codigo_do_IGBE: 3554508,
    UF: "SP",
    nome_da_cidade: "TIETE",
  },
  {
    codigo_do_IGBE: 4218004,
    UF: "SC",
    nome_da_cidade: "TIJUCAS",
  },
  {
    codigo_do_IGBE: 2615300,
    UF: "PE",
    nome_da_cidade: "TIMBAUBA",
  },
  {
    codigo_do_IGBE: 2414308,
    UF: "RN",
    nome_da_cidade: "TIMBAUBA DOS BATISTAS",
  },
  {
    codigo_do_IGBE: 4218103,
    UF: "SC",
    nome_da_cidade: "TIMBE DO SUL",
  },
  {
    codigo_do_IGBE: 2112100,
    UF: "MA",
    nome_da_cidade: "TIMBIRAS",
  },
  {
    codigo_do_IGBE: 4218202,
    UF: "SC",
    nome_da_cidade: "TIMBO",
  },
  {
    codigo_do_IGBE: 4218251,
    UF: "SC",
    nome_da_cidade: "TIMBO GRANDE",
  },
  {
    codigo_do_IGBE: 3554607,
    UF: "SP",
    nome_da_cidade: "TIMBURI",
  },
  {
    codigo_do_IGBE: 2112209,
    UF: "MA",
    nome_da_cidade: "TIMON",
  },
  {
    codigo_do_IGBE: 3168705,
    UF: "MG",
    nome_da_cidade: "TIMOTEO",
  },
  {
    codigo_do_IGBE: 4321469,
    UF: "RS",
    nome_da_cidade: "TIO HUGO",
  },
  {
    codigo_do_IGBE: 3168804,
    UF: "MG",
    nome_da_cidade: "TIRADENTES",
  },
  {
    codigo_do_IGBE: 3168903,
    UF: "MG",
    nome_da_cidade: "TIROS",
  },
  {
    codigo_do_IGBE: 1721109,
    UF: "TO",
    nome_da_cidade: "TOCANTINIA",
  },
  {
    codigo_do_IGBE: 1721208,
    UF: "TO",
    nome_da_cidade: "TOCANTINOPOLIS",
  },
  {
    codigo_do_IGBE: 3169000,
    UF: "MG",
    nome_da_cidade: "TOCANTINS",
  },
  {
    codigo_do_IGBE: 3169059,
    UF: "MG",
    nome_da_cidade: "TOCOS DO MOJI",
  },
  {
    codigo_do_IGBE: 3169109,
    UF: "MG",
    nome_da_cidade: "TOLEDO",
  },
  {
    codigo_do_IGBE: 4127700,
    UF: "PR",
    nome_da_cidade: "TOLEDO",
  },
  {
    codigo_do_IGBE: 2807501,
    UF: "SE",
    nome_da_cidade: "TOMAR DO GERU",
  },
  {
    codigo_do_IGBE: 4127809,
    UF: "PR",
    nome_da_cidade: "TOMAZINA",
  },
  {
    codigo_do_IGBE: 3169208,
    UF: "MG",
    nome_da_cidade: "TOMBOS",
  },
  {
    codigo_do_IGBE: 1508001,
    UF: "PA",
    nome_da_cidade: "TOME-ACU",
  },
  {
    codigo_do_IGBE: 1304237,
    UF: "AM",
    nome_da_cidade: "TONANTINS",
  },
  {
    codigo_do_IGBE: 2615409,
    UF: "PE",
    nome_da_cidade: "TORITAMA",
  },
  {
    codigo_do_IGBE: 5108204,
    UF: "MT",
    nome_da_cidade: "TORIXOREU",
  },
  {
    codigo_do_IGBE: 4321501,
    UF: "RS",
    nome_da_cidade: "TORRES",
  },
  {
    codigo_do_IGBE: 3554706,
    UF: "SP",
    nome_da_cidade: "TORRINHA",
  },
  {
    codigo_do_IGBE: 2414407,
    UF: "RN",
    nome_da_cidade: "TOUROS",
  },
  {
    codigo_do_IGBE: 3554755,
    UF: "SP",
    nome_da_cidade: "TRABIJU",
  },
  {
    codigo_do_IGBE: 1508035,
    UF: "PA",
    nome_da_cidade: "TRACUATEUA",
  },
  {
    codigo_do_IGBE: 2615508,
    UF: "PE",
    nome_da_cidade: "TRACUNHAEM",
  },
  {
    codigo_do_IGBE: 2709202,
    UF: "AL",
    nome_da_cidade: "TRAIPU",
  },
  {
    codigo_do_IGBE: 1508050,
    UF: "PA",
    nome_da_cidade: "TRAIRAO",
  },
  {
    codigo_do_IGBE: 2313500,
    UF: "CE",
    nome_da_cidade: "TRAIRI",
  },
  {
    codigo_do_IGBE: 3305901,
    UF: "RJ",
    nome_da_cidade: "TRAJANO DE MORAIS",
  },
  {
    codigo_do_IGBE: 4321600,
    UF: "RS",
    nome_da_cidade: "TRAMANDAI",
  },
  {
    codigo_do_IGBE: 4321626,
    UF: "RS",
    nome_da_cidade: "TRAVESSEIRO",
  },
  {
    codigo_do_IGBE: 3554805,
    UF: "SP",
    nome_da_cidade: "TREMEMBE",
  },
  {
    codigo_do_IGBE: 4321634,
    UF: "RS",
    nome_da_cidade: "TRES ARROIOS",
  },
  {
    codigo_do_IGBE: 4218301,
    UF: "SC",
    nome_da_cidade: "TRES BARRAS",
  },
  {
    codigo_do_IGBE: 4321667,
    UF: "RS",
    nome_da_cidade: "TRES CACHOEIRAS",
  },
  {
    codigo_do_IGBE: 3169307,
    UF: "MG",
    nome_da_cidade: "TRES CORACOES",
  },
  {
    codigo_do_IGBE: 4321709,
    UF: "RS",
    nome_da_cidade: "TRES COROAS",
  },
  {
    codigo_do_IGBE: 4321808,
    UF: "RS",
    nome_da_cidade: "TRES DE MAIO",
  },
  {
    codigo_do_IGBE: 4321832,
    UF: "RS",
    nome_da_cidade: "TRES FORQUILHAS",
  },
  {
    codigo_do_IGBE: 3554904,
    UF: "SP",
    nome_da_cidade: "TRES FRONTEIRAS",
  },
  {
    codigo_do_IGBE: 5008305,
    UF: "MS",
    nome_da_cidade: "TRES LAGOAS",
  },
  {
    codigo_do_IGBE: 3169356,
    UF: "MG",
    nome_da_cidade: "TRES MARIAS",
  },
  {
    codigo_do_IGBE: 4321907,
    UF: "RS",
    nome_da_cidade: "TRES PASSOS",
  },
  {
    codigo_do_IGBE: 3169406,
    UF: "MG",
    nome_da_cidade: "TRES PONTAS",
  },
  {
    codigo_do_IGBE: 5221304,
    UF: "GO",
    nome_da_cidade: "TRES RANCHOS",
  },
  {
    codigo_do_IGBE: 3306008,
    UF: "RJ",
    nome_da_cidade: "TRES RIOS",
  },
  {
    codigo_do_IGBE: 4218350,
    UF: "SC",
    nome_da_cidade: "TREVISO",
  },
  {
    codigo_do_IGBE: 4218509,
    UF: "SC",
    nome_da_cidade: "TREZE TILIAS",
  },
  {
    codigo_do_IGBE: 5221403,
    UF: "GO",
    nome_da_cidade: "TRINDADE",
  },
  {
    codigo_do_IGBE: 2615607,
    UF: "PE",
    nome_da_cidade: "TRINDADE",
  },
  {
    codigo_do_IGBE: 4321956,
    UF: "RS",
    nome_da_cidade: "TRINDADE DO SUL",
  },
  {
    codigo_do_IGBE: 2516805,
    UF: "PB",
    nome_da_cidade: "TRIUNFO",
  },
  {
    codigo_do_IGBE: 2615706,
    UF: "PE",
    nome_da_cidade: "TRIUNFO",
  },
  {
    codigo_do_IGBE: 4322004,
    UF: "RS",
    nome_da_cidade: "TRIUNFO",
  },
  {
    codigo_do_IGBE: 2414456,
    UF: "RN",
    nome_da_cidade: "TRIUNFO POTIGUAR",
  },
  {
    codigo_do_IGBE: 2931905,
    UF: "BA",
    nome_da_cidade: "TUCANO",
  },
  {
    codigo_do_IGBE: 1508084,
    UF: "PA",
    nome_da_cidade: "TUCUMA",
  },
  {
    codigo_do_IGBE: 4322103,
    UF: "RS",
    nome_da_cidade: "TUCUNDUVA",
  },
  {
    codigo_do_IGBE: 1508100,
    UF: "PA",
    nome_da_cidade: "TUCURUI",
  },
  {
    codigo_do_IGBE: 2112274,
    UF: "MA",
    nome_da_cidade: "TUFILANDIA",
  },
  {
    codigo_do_IGBE: 3554953,
    UF: "SP",
    nome_da_cidade: "TUIUTI",
  },
  {
    codigo_do_IGBE: 3169505,
    UF: "MG",
    nome_da_cidade: "TUMIRITINGA",
  },
  {
    codigo_do_IGBE: 4218756,
    UF: "SC",
    nome_da_cidade: "TUNAPOLIS",
  },
  {
    codigo_do_IGBE: 4127882,
    UF: "PR",
    nome_da_cidade: "TUNAS DO PARANA",
  },
  {
    codigo_do_IGBE: 4127908,
    UF: "PR",
    nome_da_cidade: "TUNEIRAS DO OESTE",
  },
  {
    codigo_do_IGBE: 2112308,
    UF: "MA",
    nome_da_cidade: "TUNTUM",
  },
  {
    codigo_do_IGBE: 3555000,
    UF: "SP",
    nome_da_cidade: "TUPA",
  },
  {
    codigo_do_IGBE: 3169604,
    UF: "MG",
    nome_da_cidade: "TUPACIGUARA",
  },
  {
    codigo_do_IGBE: 2615805,
    UF: "PE",
    nome_da_cidade: "TUPANATINGA",
  },
  {
    codigo_do_IGBE: 4322202,
    UF: "RS",
    nome_da_cidade: "TUPANCIRETA",
  },
  {
    codigo_do_IGBE: 4322251,
    UF: "RS",
    nome_da_cidade: "TUPANDI",
  },
  {
    codigo_do_IGBE: 4322301,
    UF: "RS",
    nome_da_cidade: "TUPARENDI",
  },
  {
    codigo_do_IGBE: 2615904,
    UF: "PE",
    nome_da_cidade: "TUPARETAMA",
  },
  {
    codigo_do_IGBE: 4127957,
    UF: "PR",
    nome_da_cidade: "TUPASSI",
  },
  {
    codigo_do_IGBE: 1721257,
    UF: "TO",
    nome_da_cidade: "TUPIRAMA",
  },
  {
    codigo_do_IGBE: 1721307,
    UF: "TO",
    nome_da_cidade: "TUPIRATINS",
  },
  {
    codigo_do_IGBE: 2112407,
    UF: "MA",
    nome_da_cidade: "TURIACU",
  },
  {
    codigo_do_IGBE: 2112456,
    UF: "MA",
    nome_da_cidade: "TURILANDIA",
  },
  {
    codigo_do_IGBE: 3555208,
    UF: "SP",
    nome_da_cidade: "TURIUBA",
  },
  {
    codigo_do_IGBE: 3169703,
    UF: "MG",
    nome_da_cidade: "TURMALINA",
  },
  {
    codigo_do_IGBE: 3555307,
    UF: "SP",
    nome_da_cidade: "TURMALINA",
  },
  {
    codigo_do_IGBE: 4322327,
    UF: "RS",
    nome_da_cidade: "TURUCU",
  },
  {
    codigo_do_IGBE: 2313559,
    UF: "CE",
    nome_da_cidade: "TURURU",
  },
  {
    codigo_do_IGBE: 5221502,
    UF: "GO",
    nome_da_cidade: "TURVANIA",
  },
  {
    codigo_do_IGBE: 4127965,
    UF: "PR",
    nome_da_cidade: "TURVO",
  },
  {
    codigo_do_IGBE: 4218806,
    UF: "SC",
    nome_da_cidade: "TURVO",
  },
  {
    codigo_do_IGBE: 3169802,
    UF: "MG",
    nome_da_cidade: "TURVOLANDIA",
  },
  {
    codigo_do_IGBE: 1304260,
    UF: "AM",
    nome_da_cidade: "UARINI",
  },
  {
    codigo_do_IGBE: 2932002,
    UF: "BA",
    nome_da_cidade: "UAUA",
  },
  {
    codigo_do_IGBE: 3169901,
    UF: "MG",
    nome_da_cidade: "UBA",
  },
  {
    codigo_do_IGBE: 3170404,
    UF: "MG",
    nome_da_cidade: "UNAI",
  },
  {
    codigo_do_IGBE: 2932101,
    UF: "BA",
    nome_da_cidade: "UBAIRA",
  },
  {
    codigo_do_IGBE: 2932200,
    UF: "BA",
    nome_da_cidade: "UBAITABA",
  },
  {
    codigo_do_IGBE: 2313609,
    UF: "CE",
    nome_da_cidade: "UBAJARA",
  },
  {
    codigo_do_IGBE: 3170057,
    UF: "MG",
    nome_da_cidade: "UBAPORANGA",
  },
  {
    codigo_do_IGBE: 3555356,
    UF: "SP",
    nome_da_cidade: "UBARANA",
  },
  {
    codigo_do_IGBE: 3555406,
    UF: "SP",
    nome_da_cidade: "UBATUBA",
  },
  {
    codigo_do_IGBE: 3170107,
    UF: "MG",
    nome_da_cidade: "UBERABA",
  },
  {
    codigo_do_IGBE: 3555505,
    UF: "SP",
    nome_da_cidade: "UBIRAJARA",
  },
  {
    codigo_do_IGBE: 4128005,
    UF: "PR",
    nome_da_cidade: "UBIRATA",
  },
  {
    codigo_do_IGBE: 4322343,
    UF: "RS",
    nome_da_cidade: "UBIRETAMA",
  },
  {
    codigo_do_IGBE: 3555604,
    UF: "SP",
    nome_da_cidade: "UCHOA",
  },
  {
    codigo_do_IGBE: 2932408,
    UF: "BA",
    nome_da_cidade: "UIBAI",
  },
  {
    codigo_do_IGBE: 1400704,
    UF: "RR",
    nome_da_cidade: "UIRAMUTA",
  },
  {
    codigo_do_IGBE: 5221577,
    UF: "GO",
    nome_da_cidade: "UIRAPURU",
  },
  {
    codigo_do_IGBE: 2516904,
    UF: "PB",
    nome_da_cidade: "UIRAUNA",
  },
  {
    codigo_do_IGBE: 1508126,
    UF: "PA",
    nome_da_cidade: "ULIANOPOLIS",
  },
  {
    codigo_do_IGBE: 2313708,
    UF: "CE",
    nome_da_cidade: "UMARI",
  },
  {
    codigo_do_IGBE: 2807600,
    UF: "SE",
    nome_da_cidade: "UMBAUBA",
  },
  {
    codigo_do_IGBE: 3170305,
    UF: "MG",
    nome_da_cidade: "UMBURATIBA",
  },
  {
    codigo_do_IGBE: 2517001,
    UF: "PB",
    nome_da_cidade: "UMBUZEIRO",
  },
  {
    codigo_do_IGBE: 2313757,
    UF: "CE",
    nome_da_cidade: "UMIRIM",
  },
  {
    codigo_do_IGBE: 4128104,
    UF: "PR",
    nome_da_cidade: "UMUARAMA",
  },
  {
    codigo_do_IGBE: 2932507,
    UF: "BA",
    nome_da_cidade: "UNA",
  },
  {
    codigo_do_IGBE: 2211100,
    UF: "PI",
    nome_da_cidade: "UNIAO",
  },
  {
    codigo_do_IGBE: 4322350,
    UF: "RS",
    nome_da_cidade: "UNIAO DA SERRA",
  },
  {
    codigo_do_IGBE: 4128203,
    UF: "PR",
    nome_da_cidade: "UNIAO DA VITORIA",
  },
  {
    codigo_do_IGBE: 3170438,
    UF: "MG",
    nome_da_cidade: "UNIAO DE MINAS",
  },
  {
    codigo_do_IGBE: 5108303,
    UF: "MT",
    nome_da_cidade: "UNIAO DO SUL",
  },
  {
    codigo_do_IGBE: 3555703,
    UF: "SP",
    nome_da_cidade: "UNIAO PAULISTA",
  },
  {
    codigo_do_IGBE: 4128302,
    UF: "PR",
    nome_da_cidade: "UNIFLOR",
  },
  {
    codigo_do_IGBE: 4322376,
    UF: "RS",
    nome_da_cidade: "UNISTALDA",
  },
  {
    codigo_do_IGBE: 2414605,
    UF: "RN",
    nome_da_cidade: "UPANEMA",
  },
  {
    codigo_do_IGBE: 4128401,
    UF: "PR",
    nome_da_cidade: "URAI",
  },
  {
    codigo_do_IGBE: 2932606,
    UF: "BA",
    nome_da_cidade: "URANDI",
  },
  {
    codigo_do_IGBE: 3555802,
    UF: "SP",
    nome_da_cidade: "URANIA",
  },
  {
    codigo_do_IGBE: 2112605,
    UF: "MA",
    nome_da_cidade: "URBANO SANTOS",
  },
  {
    codigo_do_IGBE: 3555901,
    UF: "SP",
    nome_da_cidade: "URU",
  },
  {
    codigo_do_IGBE: 5221601,
    UF: "GO",
    nome_da_cidade: "URUACU",
  },
  {
    codigo_do_IGBE: 5221700,
    UF: "GO",
    nome_da_cidade: "URUANA",
  },
  {
    codigo_do_IGBE: 3170479,
    UF: "MG",
    nome_da_cidade: "URUANA DE MINAS",
  },
  {
    codigo_do_IGBE: 1508159,
    UF: "PA",
    nome_da_cidade: "URUARA",
  },
  {
    codigo_do_IGBE: 4218905,
    UF: "SC",
    nome_da_cidade: "URUBICI",
  },
  {
    codigo_do_IGBE: 2313807,
    UF: "CE",
    nome_da_cidade: "URUBURETAMA",
  },
  {
    codigo_do_IGBE: 2932705,
    UF: "BA",
    nome_da_cidade: "URUCUCA",
  },
  {
    codigo_do_IGBE: 2211209,
    UF: "PI",
    nome_da_cidade: "URUCUI",
  },
  {
    codigo_do_IGBE: 3170529,
    UF: "MG",
    nome_da_cidade: "URUCUIA",
  },
  {
    codigo_do_IGBE: 1304401,
    UF: "AM",
    nome_da_cidade: "URUCURITUBA",
  },
  {
    codigo_do_IGBE: 4322400,
    UF: "RS",
    nome_da_cidade: "URUGUAIANA",
  },
  {
    codigo_do_IGBE: 2313906,
    UF: "CE",
    nome_da_cidade: "URUOCA",
  },
  {
    codigo_do_IGBE: 1101708,
    UF: "RO",
    nome_da_cidade: "URUPA",
  },
  {
    codigo_do_IGBE: 4218954,
    UF: "SC",
    nome_da_cidade: "URUPEMA",
  },
  {
    codigo_do_IGBE: 3556008,
    UF: "SP",
    nome_da_cidade: "URUPES",
  },
  {
    codigo_do_IGBE: 4219002,
    UF: "SC",
    nome_da_cidade: "URUSSANGA",
  },
  {
    codigo_do_IGBE: 5221809,
    UF: "GO",
    nome_da_cidade: "URUTAI",
  },
  {
    codigo_do_IGBE: 4322509,
    UF: "RS",
    nome_da_cidade: "VACARIA",
  },
  {
    codigo_do_IGBE: 1101757,
    UF: "RO",
    nome_da_cidade: "VALE DO ANARI",
  },
  {
    codigo_do_IGBE: 1101807,
    UF: "RO",
    nome_da_cidade: "VALE DO PARAISO",
  },
  {
    codigo_do_IGBE: 4322533,
    UF: "RS",
    nome_da_cidade: "VALE DO SOL",
  },
  {
    codigo_do_IGBE: 4322541,
    UF: "RS",
    nome_da_cidade: "VALE REAL",
  },
  {
    codigo_do_IGBE: 4322525,
    UF: "RS",
    nome_da_cidade: "VALE VERDE",
  },
  {
    codigo_do_IGBE: 2932903,
    UF: "BA",
    nome_da_cidade: "VALENCA",
  },
  {
    codigo_do_IGBE: 3306107,
    UF: "RJ",
    nome_da_cidade: "VALENCA",
  },
  {
    codigo_do_IGBE: 2211308,
    UF: "PI",
    nome_da_cidade: "VALENCA DO PIAUI",
  },
  {
    codigo_do_IGBE: 2933000,
    UF: "BA",
    nome_da_cidade: "VALENTE",
  },
  {
    codigo_do_IGBE: 3556206,
    UF: "SP",
    nome_da_cidade: "VALINHOS",
  },
  {
    codigo_do_IGBE: 3556305,
    UF: "SP",
    nome_da_cidade: "VALPARAISO",
  },
  {
    codigo_do_IGBE: 5221858,
    UF: "GO",
    nome_da_cidade: "VALPARAISO DE GOIAS",
  },
  {
    codigo_do_IGBE: 4322558,
    UF: "RS",
    nome_da_cidade: "VANINI",
  },
  {
    codigo_do_IGBE: 4219101,
    UF: "SC",
    nome_da_cidade: "VARGEAO",
  },
  {
    codigo_do_IGBE: 3556354,
    UF: "SP",
    nome_da_cidade: "VARGEM",
  },
  {
    codigo_do_IGBE: 3170578,
    UF: "MG",
    nome_da_cidade: "VARGEM ALEGRE",
  },
  {
    codigo_do_IGBE: 3205036,
    UF: "ES",
    nome_da_cidade: "VARGEM ALTA",
  },
  {
    codigo_do_IGBE: 3170602,
    UF: "MG",
    nome_da_cidade: "VARGEM BONITA",
  },
  {
    codigo_do_IGBE: 4219176,
    UF: "SC",
    nome_da_cidade: "VARGEM BONITA",
  },
  {
    codigo_do_IGBE: 2112704,
    UF: "MA",
    nome_da_cidade: "VARGEM GRANDE",
  },
  {
    codigo_do_IGBE: 3556404,
    UF: "SP",
    nome_da_cidade: "VARGEM GRANDE DO SUL",
  },
  {
    codigo_do_IGBE: 3556453,
    UF: "SP",
    nome_da_cidade: "VARGEM GRANDE PAULISTA",
  },
  {
    codigo_do_IGBE: 3170701,
    UF: "MG",
    nome_da_cidade: "VARGINHA",
  },
  {
    codigo_do_IGBE: 5221908,
    UF: "GO",
    nome_da_cidade: "VARJAO",
  },
  {
    codigo_do_IGBE: 2313955,
    UF: "CE",
    nome_da_cidade: "VARJOTA",
  },
  {
    codigo_do_IGBE: 3306156,
    UF: "RJ",
    nome_da_cidade: "VARRE-SAI",
  },
  {
    codigo_do_IGBE: 2517100,
    UF: "PB",
    nome_da_cidade: "VARZEA",
  },
  {
    codigo_do_IGBE: 2414704,
    UF: "RN",
    nome_da_cidade: "VARZEA",
  },
  {
    codigo_do_IGBE: 2314003,
    UF: "CE",
    nome_da_cidade: "VARZEA ALEGRE",
  },
  {
    codigo_do_IGBE: 2211357,
    UF: "PI",
    nome_da_cidade: "VARZEA BRANCA",
  },
  {
    codigo_do_IGBE: 3170800,
    UF: "MG",
    nome_da_cidade: "VARZEA DA PALMA",
  },
  {
    codigo_do_IGBE: 2933059,
    UF: "BA",
    nome_da_cidade: "VARZEA DA ROCA",
  },
  {
    codigo_do_IGBE: 2933109,
    UF: "BA",
    nome_da_cidade: "VARZEA DO POCO",
  },
  {
    codigo_do_IGBE: 5108402,
    UF: "MT",
    nome_da_cidade: "VARZEA GRANDE",
  },
  {
    codigo_do_IGBE: 2211407,
    UF: "PI",
    nome_da_cidade: "VARZEA GRANDE",
  },
  {
    codigo_do_IGBE: 2933158,
    UF: "BA",
    nome_da_cidade: "VARZEA NOVA",
  },
  {
    codigo_do_IGBE: 2933174,
    UF: "BA",
    nome_da_cidade: "VARZEDO",
  },
  {
    codigo_do_IGBE: 3170909,
    UF: "MG",
    nome_da_cidade: "VARZELANDIA",
  },
  {
    codigo_do_IGBE: 3306206,
    UF: "RJ",
    nome_da_cidade: "VASSOURAS",
  },
  {
    codigo_do_IGBE: 2414753,
    UF: "RN",
    nome_da_cidade: "VENHA-VER",
  },
  {
    codigo_do_IGBE: 4128534,
    UF: "PR",
    nome_da_cidade: "VENTANIA",
  },
  {
    codigo_do_IGBE: 2616001,
    UF: "PE",
    nome_da_cidade: "VENTUROSA",
  },
  {
    codigo_do_IGBE: 5108501,
    UF: "MT",
    nome_da_cidade: "VERA",
  },
  {
    codigo_do_IGBE: 2933208,
    UF: "BA",
    nome_da_cidade: "VERA CRUZ",
  },
  {
    codigo_do_IGBE: 2414803,
    UF: "RN",
    nome_da_cidade: "VERA CRUZ",
  },
  {
    codigo_do_IGBE: 4322707,
    UF: "RS",
    nome_da_cidade: "VERA CRUZ",
  },
  {
    codigo_do_IGBE: 3556602,
    UF: "SP",
    nome_da_cidade: "VERA CRUZ",
  },
  {
    codigo_do_IGBE: 2100055,
    UF: "MA",
    nome_da_cidade: "ACAILANDIA",
  },
  {
    codigo_do_IGBE: 3100500,
    UF: "MG",
    nome_da_cidade: "ACUCENA",
  },
  {
    codigo_do_IGBE: 2200103,
    UF: "PI",
    nome_da_cidade: "AGRICOLANDIA",
  },
  {
    codigo_do_IGBE: 2100154,
    UF: "MA",
    nome_da_cidade: "AGUA DOCE DO MARANHAO",
  },
  {
    codigo_do_IGBE: 2600609,
    UF: "PE",
    nome_da_cidade: "ALAGOINHA",
  },
  {
    codigo_do_IGBE: 4300406,
    UF: "RS",
    nome_da_cidade: "ALEGRETE",
  },
  {
    codigo_do_IGBE: 4200705,
    UF: "SC",
    nome_da_cidade: "ALFREDO WAGNER",
  },
  {
    codigo_do_IGBE: 5100250,
    UF: "MT",
    nome_da_cidade: "ALTA FLORESTA",
  },
  {
    codigo_do_IGBE: 2100436,
    UF: "MA",
    nome_da_cidade: "ALTO ALEGRE DO MARANHAO",
  },
  {
    codigo_do_IGBE: 5100359,
    UF: "MT",
    nome_da_cidade: "ALTO BOA VISTA",
  },
  {
    codigo_do_IGBE: 2400703,
    UF: "RN",
    nome_da_cidade: "ALTO DO RODRIGUES",
  },
  {
    codigo_do_IGBE: 4100509,
    UF: "PR",
    nome_da_cidade: "ALTONIA",
  },
  {
    codigo_do_IGBE: 3102407,
    UF: "MG",
    nome_da_cidade: "ALVORADA DE MINAS",
  },
  {
    codigo_do_IGBE: 2100600,
    UF: "MA",
    nome_da_cidade: "AMARANTE DO MARANHAO",
  },
  {
    codigo_do_IGBE: 2800100,
    UF: "SE",
    nome_da_cidade: "AMPARO DE SAO FRANCISCO",
  },
  {
    codigo_do_IGBE: 4101101,
    UF: "PR",
    nome_da_cidade: "ANDIRA",
  },
  {
    codigo_do_IGBE: 2200608,
    UF: "PI",
    nome_da_cidade: "ANGICAL DO PIAUI",
  },
  {
    codigo_do_IGBE: 4101200,
    UF: "PR",
    nome_da_cidade: "ANTONINA",
  },
  {
    codigo_do_IGBE: 4300802,
    UF: "RS",
    nome_da_cidade: "ANTONIO PRADO",
  },
  {
    codigo_do_IGBE: 2616100,
    UF: "PE",
    nome_da_cidade: "VERDEJANTE",
  },
  {
    codigo_do_IGBE: 3171030,
    UF: "MG",
    nome_da_cidade: "VERDELANDIA",
  },
  {
    codigo_do_IGBE: 4128609,
    UF: "PR",
    nome_da_cidade: "VERE",
  },
  {
    codigo_do_IGBE: 2933257,
    UF: "BA",
    nome_da_cidade: "VEREDA",
  },
  {
    codigo_do_IGBE: 3171105,
    UF: "MG",
    nome_da_cidade: "VERISSIMO",
  },
  {
    codigo_do_IGBE: 2616183,
    UF: "PE",
    nome_da_cidade: "VERTENTE DO LERIO",
  },
  {
    codigo_do_IGBE: 2616209,
    UF: "PE",
    nome_da_cidade: "VERTENTES",
  },
  {
    codigo_do_IGBE: 3171204,
    UF: "MG",
    nome_da_cidade: "VESPASIANO",
  },
  {
    codigo_do_IGBE: 4322855,
    UF: "RS",
    nome_da_cidade: "VESPASIANO CORREA",
  },
  {
    codigo_do_IGBE: 4322905,
    UF: "RS",
    nome_da_cidade: "VIADUTOS",
  },
  {
    codigo_do_IGBE: 4323002,
    UF: "RS",
    nome_da_cidade: "VIAMAO",
  },
  {
    codigo_do_IGBE: 3205101,
    UF: "ES",
    nome_da_cidade: "VIANA",
  },
  {
    codigo_do_IGBE: 2112803,
    UF: "MA",
    nome_da_cidade: "VIANA",
  },
  {
    codigo_do_IGBE: 5222005,
    UF: "GO",
    nome_da_cidade: "VIANOPOLIS",
  },
  {
    codigo_do_IGBE: 2616308,
    UF: "PE",
    nome_da_cidade: "VICENCIA",
  },
  {
    codigo_do_IGBE: 5008404,
    UF: "MS",
    nome_da_cidade: "VICENTINA",
  },
  {
    codigo_do_IGBE: 5222054,
    UF: "GO",
    nome_da_cidade: "VICENTINOPOLIS",
  },
  {
    codigo_do_IGBE: 2709400,
    UF: "AL",
    nome_da_cidade: "VICOSA",
  },
  {
    codigo_do_IGBE: 3171303,
    UF: "MG",
    nome_da_cidade: "VICOSA",
  },
  {
    codigo_do_IGBE: 2314102,
    UF: "CE",
    nome_da_cidade: "VICOSA DO CEARA",
  },
  {
    codigo_do_IGBE: 4323200,
    UF: "RS",
    nome_da_cidade: "VICTOR GRAEFF",
  },
  {
    codigo_do_IGBE: 4219200,
    UF: "SC",
    nome_da_cidade: "VIDAL RAMOS",
  },
  {
    codigo_do_IGBE: 4219309,
    UF: "SC",
    nome_da_cidade: "VIDEIRA",
  },
  {
    codigo_do_IGBE: 3171402,
    UF: "MG",
    nome_da_cidade: "VIEIRAS",
  },
  {
    codigo_do_IGBE: 2517209,
    UF: "PB",
    nome_da_cidade: "VIEIROPOLIS",
  },
  {
    codigo_do_IGBE: 1508209,
    UF: "PA",
    nome_da_cidade: "VIGIA",
  },
  {
    codigo_do_IGBE: 5222203,
    UF: "GO",
    nome_da_cidade: "VILA BOA",
  },
  {
    codigo_do_IGBE: 2415008,
    UF: "RN",
    nome_da_cidade: "VILA FLOR",
  },
  {
    codigo_do_IGBE: 4323309,
    UF: "RS",
    nome_da_cidade: "VILA FLORES",
  },
  {
    codigo_do_IGBE: 4323358,
    UF: "RS",
    nome_da_cidade: "VILA LANGARO",
  },
  {
    codigo_do_IGBE: 4323408,
    UF: "RS",
    nome_da_cidade: "VILA MARIA",
  },
  {
    codigo_do_IGBE: 4323457,
    UF: "RS",
    nome_da_cidade: "VILA NOVA DO SUL",
  },
  {
    codigo_do_IGBE: 2112852,
    UF: "MA",
    nome_da_cidade: "VILA NOVA DOS MARTIRIOS",
  },
  {
    codigo_do_IGBE: 3205150,
    UF: "ES",
    nome_da_cidade: "VILA PAVAO",
  },
  {
    codigo_do_IGBE: 5222302,
    UF: "GO",
    nome_da_cidade: "VILA PROPICIO",
  },
  {
    codigo_do_IGBE: 3205176,
    UF: "ES",
    nome_da_cidade: "VILA VALERIO",
  },
  {
    codigo_do_IGBE: 3205200,
    UF: "ES",
    nome_da_cidade: "VILA VELHA",
  },
  {
    codigo_do_IGBE: 1100304,
    UF: "RO",
    nome_da_cidade: "VILHENA",
  },
  {
    codigo_do_IGBE: 3556701,
    UF: "SP",
    nome_da_cidade: "VINHEDO",
  },
  {
    codigo_do_IGBE: 3171600,
    UF: "MG",
    nome_da_cidade: "VIRGEM DA LAPA",
  },
  {
    codigo_do_IGBE: 3171709,
    UF: "MG",
    nome_da_cidade: "VIRGINIA",
  },
  {
    codigo_do_IGBE: 3171808,
    UF: "MG",
    nome_da_cidade: "VIRGINOPOLIS",
  },
  {
    codigo_do_IGBE: 3171907,
    UF: "MG",
    nome_da_cidade: "VIRGOLANDIA",
  },
  {
    codigo_do_IGBE: 4128658,
    UF: "PR",
    nome_da_cidade: "VIRMOND",
  },
  {
    codigo_do_IGBE: 1508308,
    UF: "PA",
    nome_da_cidade: "VISEU",
  },
  {
    codigo_do_IGBE: 4323507,
    UF: "RS",
    nome_da_cidade: "VISTA ALEGRE",
  },
  {
    codigo_do_IGBE: 3556909,
    UF: "SP",
    nome_da_cidade: "VISTA ALEGRE DO ALTO",
  },
  {
    codigo_do_IGBE: 4323606,
    UF: "RS",
    nome_da_cidade: "VISTA ALEGRE DO PRATA",
  },
  {
    codigo_do_IGBE: 4323705,
    UF: "RS",
    nome_da_cidade: "VISTA GAUCHA",
  },
  {
    codigo_do_IGBE: 2505501,
    UF: "PB",
    nome_da_cidade: "VISTA SERRANA",
  },
  {
    codigo_do_IGBE: 4219358,
    UF: "SC",
    nome_da_cidade: "VITOR MEIRELES",
  },
  {
    codigo_do_IGBE: 3556958,
    UF: "SP",
    nome_da_cidade: "VITORIA BRASIL",
  },
  {
    codigo_do_IGBE: 2933307,
    UF: "BA",
    nome_da_cidade: "VITORIA DA CONQUISTA",
  },
  {
    codigo_do_IGBE: 4323754,
    UF: "RS",
    nome_da_cidade: "VITORIA DAS MISSOES",
  },
  {
    codigo_do_IGBE: 1600808,
    UF: "AP",
    nome_da_cidade: "VITORIA DO JARI",
  },
  {
    codigo_do_IGBE: 2112902,
    UF: "MA",
    nome_da_cidade: "VITORIA DO MEARIM",
  },
  {
    codigo_do_IGBE: 1508357,
    UF: "PA",
    nome_da_cidade: "VITORIA DO XINGU",
  },
  {
    codigo_do_IGBE: 4128708,
    UF: "PR",
    nome_da_cidade: "VITORINO",
  },
  {
    codigo_do_IGBE: 3172103,
    UF: "MG",
    nome_da_cidade: "VOLTA GRANDE",
  },
  {
    codigo_do_IGBE: 3306305,
    UF: "RJ",
    nome_da_cidade: "VOLTA REDONDA",
  },
  {
    codigo_do_IGBE: 3557006,
    UF: "SP",
    nome_da_cidade: "VOTORANTIM",
  },
  {
    codigo_do_IGBE: 3557105,
    UF: "SP",
    nome_da_cidade: "VOTUPORANGA",
  },
  {
    codigo_do_IGBE: 2933406,
    UF: "BA",
    nome_da_cidade: "WAGNER",
  },
  {
    codigo_do_IGBE: 2211704,
    UF: "PI",
    nome_da_cidade: "WALL FERRAZ",
  },
  {
    codigo_do_IGBE: 1722081,
    UF: "TO",
    nome_da_cidade: "WANDERLANDIA",
  },
  {
    codigo_do_IGBE: 2933455,
    UF: "BA",
    nome_da_cidade: "WANDERLEY",
  },
  {
    codigo_do_IGBE: 3172202,
    UF: "MG",
    nome_da_cidade: "WENCESLAU BRAZ",
  },
  {
    codigo_do_IGBE: 4128500,
    UF: "PR",
    nome_da_cidade: "WENCESLAU BRAZ",
  },
  {
    codigo_do_IGBE: 2933505,
    UF: "BA",
    nome_da_cidade: "WENCESLAU GUIMARAES",
  },
  {
    codigo_do_IGBE: 4323770,
    UF: "RS",
    nome_da_cidade: "WESTFALIA",
  },
  {
    codigo_do_IGBE: 4219408,
    UF: "SC",
    nome_da_cidade: "WITMARSUM",
  },
  {
    codigo_do_IGBE: 1722107,
    UF: "TO",
    nome_da_cidade: "XAMBIOA",
  },
  {
    codigo_do_IGBE: 4128807,
    UF: "PR",
    nome_da_cidade: "XAMBRE",
  },
  {
    codigo_do_IGBE: 1200708,
    UF: "AC",
    nome_da_cidade: "XAPURI",
  },
  {
    codigo_do_IGBE: 4219606,
    UF: "SC",
    nome_da_cidade: "XAVANTINA",
  },
  {
    codigo_do_IGBE: 4219705,
    UF: "SC",
    nome_da_cidade: "XAXIM",
  },
  {
    codigo_do_IGBE: 2616506,
    UF: "PE",
    nome_da_cidade: "XEXEU",
  },
  {
    codigo_do_IGBE: 1508407,
    UF: "PA",
    nome_da_cidade: "XINGUARA",
  },
  {
    codigo_do_IGBE: 2933604,
    UF: "BA",
    nome_da_cidade: "XIQUE-XIQUE",
  },
  {
    codigo_do_IGBE: 2517407,
    UF: "PB",
    nome_da_cidade: "ZABELE",
  },
  {
    codigo_do_IGBE: 3557154,
    UF: "SP",
    nome_da_cidade: "ZACARIAS",
  },
  {
    codigo_do_IGBE: 2114007,
    UF: "MA",
    nome_da_cidade: "ZE DOCA",
  },
  {
    codigo_do_IGBE: 4219853,
    UF: "SC",
    nome_da_cidade: "ZORTEA",
  },
  {
    codigo_do_IGBE: 2301109,
    UF: "CE",
    nome_da_cidade: "ARACATI",
  },
  {
    codigo_do_IGBE: 1701309,
    UF: "TO",
    nome_da_cidade: "ARAGOMINAS",
  },
  {
    codigo_do_IGBE: 2902203,
    UF: "BA",
    nome_da_cidade: "ARAMARI",
  },
  {
    codigo_do_IGBE: 3103801,
    UF: "MG",
    nome_da_cidade: "ARAPUA",
  },
  {
    codigo_do_IGBE: 3300209,
    UF: "RJ",
    nome_da_cidade: "ARARUAMA",
  },
  {
    codigo_do_IGBE: 3104205,
    UF: "MG",
    nome_da_cidade: "ARCOS",
  },
  {
    codigo_do_IGBE: 5202353,
    UF: "GO",
    nome_da_cidade: "ARENOPOLIS",
  },
  {
    codigo_do_IGBE: 2501302,
    UF: "PB",
    nome_da_cidade: "AROEIRAS",
  },
  {
    codigo_do_IGBE: 4201604,
    UF: "SC",
    nome_da_cidade: "ARROIO TRINTA",
  },
  {
    codigo_do_IGBE: 3104601,
    UF: "MG",
    nome_da_cidade: "ASTOLFO DUTRA",
  },
  {
    codigo_do_IGBE: 1702901,
    UF: "TO",
    nome_da_cidade: "AXIXA DO TOCANTINS",
  },
  {
    codigo_do_IGBE: 2101301,
    UF: "MA",
    nome_da_cidade: "BACURI",
  },
  {
    codigo_do_IGBE: 2101350,
    UF: "MA",
    nome_da_cidade: "BACURITUBA",
  },
  {
    codigo_do_IGBE: 2201150,
    UF: "PI",
    nome_da_cidade: "BAIXA GRANDE DO RIBEIRO",
  },
  {
    codigo_do_IGBE: 3105301,
    UF: "MG",
    nome_da_cidade: "BANDEIRA DO SUL",
  },
  {
    codigo_do_IGBE: 3105509,
    UF: "MG",
    nome_da_cidade: "BARAO DE MONTE ALTO",
  },
  {
    codigo_do_IGBE: 2902807,
    UF: "BA",
    nome_da_cidade: "BARRA DA ESTIVA",
  },
  {
    codigo_do_IGBE: 4102703,
    UF: "PR",
    nome_da_cidade: "BARRA DO JACARE",
  },
  {
    codigo_do_IGBE: 2301950,
    UF: "CE",
    nome_da_cidade: "BARREIRA",
  },
  {
    codigo_do_IGBE: 2903276,
    UF: "BA",
    nome_da_cidade: "BARROCAS",
  },
  {
    codigo_do_IGBE: 3506003,
    UF: "SP",
    nome_da_cidade: "BAURU",
  },
  {
    codigo_do_IGBE: 2101772,
    UF: "MA",
    nome_da_cidade: "BELA VISTA DO MARANHAO",
  },
  {
    codigo_do_IGBE: 1501451,
    UF: "PA",
    nome_da_cidade: "BELTERRA",
  },
  {
    codigo_do_IGBE: 3106804,
    UF: "MG",
    nome_da_cidade: "BIAS FORTES",
  },
  {
    codigo_do_IGBE: 3107109,
    UF: "MG",
    nome_da_cidade: "BOA ESPERANCA",
  },
  {
    codigo_do_IGBE: 2101970,
    UF: "MA",
    nome_da_cidade: "BOA VISTA DO GURUPI",
  },
  {
    codigo_do_IGBE: 3507001,
    UF: "SP",
    nome_da_cidade: "BOITUVA",
  },
  {
    codigo_do_IGBE: 4202537,
    UF: "SC",
    nome_da_cidade: "BOM JESUS",
  },
  {
    codigo_do_IGBE: 3300605,
    UF: "RJ",
    nome_da_cidade: "BOM JESUS DO ITABAPOANA",
  },
  {
    codigo_do_IGBE: 3507159,
    UF: "SP",
    nome_da_cidade: "BOM SUCESSO DE ITARARE",
  },
  {
    codigo_do_IGBE: 3507308,
    UF: "SP",
    nome_da_cidade: "BORACEIA",
  },
  {
    codigo_do_IGBE: 4202701,
    UF: "SC",
    nome_da_cidade: "BOTUVERA",
  },
  {
    codigo_do_IGBE: 4302600,
    UF: "RS",
    nome_da_cidade: "BRAGA",
  },
  {
    codigo_do_IGBE: 3507605,
    UF: "SP",
    nome_da_cidade: "BRAGANCA PAULISTA",
  },
  {
    codigo_do_IGBE: 2602407,
    UF: "PE",
    nome_da_cidade: "BREJAO",
  },
  {
    codigo_do_IGBE: 2502904,
    UF: "PB",
    nome_da_cidade: "BREJO DOS SANTOS",
  },
  {
    codigo_do_IGBE: 3109105,
    UF: "MG",
    nome_da_cidade: "BUENO BRANDAO",
  },
  {
    codigo_do_IGBE: 5203939,
    UF: "GO",
    nome_da_cidade: "BURITI DE GOIAS",
  },
  {
    codigo_do_IGBE: 2503100,
    UF: "PB",
    nome_da_cidade: "CABACEIRAS",
  },
  {
    codigo_do_IGBE: 4203006,
    UF: "SC",
    nome_da_cidade: "CACADOR",
  },
  {
    codigo_do_IGBE: 3201209,
    UF: "ES",
    nome_da_cidade: "CACHOEIRO DE ITAPEMIRIM",
  },
  {
    codigo_do_IGBE: 4103479,
    UF: "PR",
    nome_da_cidade: "CAFEZAL DO SUL",
  },
  {
    codigo_do_IGBE: 3509205,
    UF: "SP",
    nome_da_cidade: "CAJAMAR",
  },
  {
    codigo_do_IGBE: 2202083,
    UF: "PI",
    nome_da_cidade: "CAJUEIRO DA PRAIA",
  },
  {
    codigo_do_IGBE: 2503902,
    UF: "PB",
    nome_da_cidade: "CAMALAU",
  },
  {
    codigo_do_IGBE: 3110608,
    UF: "MG",
    nome_da_cidade: "CAMBUI",
  },
  {
    codigo_do_IGBE: 2102556,
    UF: "MA",
    nome_da_cidade: "CAMPESTRE DO MARANHAO",
  },
  {
    codigo_do_IGBE: 5204805,
    UF: "GO",
    nome_da_cidade: "CAMPO ALEGRE DE GOIAS",
  },
  {
    codigo_do_IGBE: 2202133,
    UF: "PI",
    nome_da_cidade: "CAMPO GRANDE DO PIAUI",
  },
  {
    codigo_do_IGBE: 3301009,
    UF: "RJ",
    nome_da_cidade: "CAMPOS DOS GOYTACAZES",
  },
  {
    codigo_do_IGBE: 2202251,
    UF: "PI",
    nome_da_cidade: "CANAVIEIRA",
  },
  {
    codigo_do_IGBE: 3510104,
    UF: "SP",
    nome_da_cidade: "CANDIDO RODRIGUES",
  },
  {
    codigo_do_IGBE: 4205407,
    UF: "SC",
    nome_da_cidade: "FLORIANOPOLIS",
  },
  {
    codigo_do_IGBE: 3112059,
    UF: "MG",
    nome_da_cidade: "CANTAGALO",
  },
  {
    codigo_do_IGBE: 3112604,
    UF: "MG",
    nome_da_cidade: "CAPINOPOLIS",
  },
  {
    codigo_do_IGBE: 4104600,
    UF: "PR",
    nome_da_cidade: "CAPITAO LEONIDAS MARQUES",
  },
  {
    codigo_do_IGBE: 3510609,
    UF: "SP",
    nome_da_cidade: "CARAPICUIBA",
  },
  {
    codigo_do_IGBE: 3301157,
    UF: "RJ",
    nome_da_cidade: "CARDOSO MOREIRA",
  },
  {
    codigo_do_IGBE: 4304804,
    UF: "RS",
    nome_da_cidade: "CARLOS BARBOSA",
  },
  {
    codigo_do_IGBE: 2402402,
    UF: "RN",
    nome_da_cidade: "CARNAUBA DOS DANTAS",
  },
  {
    codigo_do_IGBE: 3114709,
    UF: "MG",
    nome_da_cidade: "CARVALHOPOLIS",
  },
  {
    codigo_do_IGBE: 3510906,
    UF: "SP",
    nome_da_cidade: "CASSIA DOS COQUEIROS",
  },
  {
    codigo_do_IGBE: 3115359,
    UF: "MG",
    nome_da_cidade: "CATAS ALTAS",
  },
  {
    codigo_do_IGBE: 3115409,
    UF: "MG",
    nome_da_cidade: "CATAS ALTAS DA NORUEGA",
  },
  {
    codigo_do_IGBE: 4204103,
    UF: "SC",
    nome_da_cidade: "CAXAMBU DO SUL",
  },
  {
    codigo_do_IGBE: 3115706,
    UF: "MG",
    nome_da_cidade: "CENTRAL DE MINAS",
  },
  {
    codigo_do_IGBE: 4305173,
    UF: "RS",
    nome_da_cidade: "CERRO GRANDE DO SUL",
  },
  {
    codigo_do_IGBE: 1100924,
    UF: "RO",
    nome_da_cidade: "CHUPINGUAIA",
  },
  {
    codigo_do_IGBE: 3116506,
    UF: "MG",
    nome_da_cidade: "CLARO DOS POCOES",
  },
  {
    codigo_do_IGBE: 2103406,
    UF: "MA",
    nome_da_cidade: "COELHO NETO",
  },
  {
    codigo_do_IGBE: 5205521,
    UF: "GO",
    nome_da_cidade: "COLINAS DO SUL",
  },
  {
    codigo_do_IGBE: 3300951,
    UF: "RJ",
    nome_da_cidade: "COMENDADOR LEVY GASPARIAN",
  },
  {
    codigo_do_IGBE: 1502707,
    UF: "PA",
    nome_da_cidade: "CONCEICAO DO ARAGUAIA",
  },
  {
    codigo_do_IGBE: 1502756,
    UF: "PA",
    nome_da_cidade: "CONCORDIA DO PARA",
  },
  {
    codigo_do_IGBE: 5103361,
    UF: "MT",
    nome_da_cidade: "CONQUISTA D'OESTE",
  },
  {
    codigo_do_IGBE: 2908903,
    UF: "BA",
    nome_da_cidade: "CORACAO DE MARIA",
  },
  {
    codigo_do_IGBE: 3119302,
    UF: "MG",
    nome_da_cidade: "COROMANDEL",
  },
  {
    codigo_do_IGBE: 4204459,
    UF: "SC",
    nome_da_cidade: "CORONEL MARTINS",
  },
  {
    codigo_do_IGBE: 5003207,
    UF: "MS",
    nome_da_cidade: "CORUMBA",
  },
  {
    codigo_do_IGBE: 3513009,
    UF: "SP",
    nome_da_cidade: "COTIA",
  },
  {
    codigo_do_IGBE: 4204608,
    UF: "SC",
    nome_da_cidade: "CRICIUMA",
  },
  {
    codigo_do_IGBE: 2203008,
    UF: "PI",
    nome_da_cidade: "CRISTALANDIA DO PIAUI",
  },
  {
    codigo_do_IGBE: 3513405,
    UF: "SP",
    nome_da_cidade: "CRUZEIRO",
  },
  {
    codigo_do_IGBE: 4106704,
    UF: "PR",
    nome_da_cidade: "CRUZEIRO DO SUL",
  },
  {
    codigo_do_IGBE: 4204707,
    UF: "SC",
    nome_da_cidade: "CUNHA PORA",
  },
  {
    codigo_do_IGBE: 2505279,
    UF: "PB",
    nome_da_cidade: "CURRAL DE CIMA",
  },
  {
    codigo_do_IGBE: 2910008,
    UF: "BA",
    nome_da_cidade: "DARIO MEIRA",
  },
  {
    codigo_do_IGBE: 2304269,
    UF: "CE",
    nome_da_cidade: "DEPUTADO IRAPUAN PINHEIRO",
  },
  {
    codigo_do_IGBE: 4306379,
    UF: "RS",
    nome_da_cidade: "DILERMANDO DE AGUIAR",
  },
  {
    codigo_do_IGBE: 3201803,
    UF: "ES",
    nome_da_cidade: "DIVINO DE SAO LOURENCO",
  },
  {
    codigo_do_IGBE: 3122470,
    UF: "MG",
    nome_da_cidade: "DOM BOSCO",
  },
  {
    codigo_do_IGBE: 4306551,
    UF: "RS",
    nome_da_cidade: "DOM PEDRO DE ALCANTARA",
  },
  {
    codigo_do_IGBE: 5003702,
    UF: "MS",
    nome_da_cidade: "DOURADOS",
  },
  {
    codigo_do_IGBE: 4306759,
    UF: "RS",
    nome_da_cidade: "DOUTOR RICARDO",
  },
  {
    codigo_do_IGBE: 2103901,
    UF: "MA",
    nome_da_cidade: "DUQUE BACELAR",
  },
  {
    codigo_do_IGBE: 2203602,
    UF: "PI",
    nome_da_cidade: "ELISEU MARTINS",
  },
  {
    codigo_do_IGBE: 4107504,
    UF: "PR",
    nome_da_cidade: "ENGENHEIRO BELTRAO",
  },
  {
    codigo_do_IGBE: 4307005,
    UF: "RS",
    nome_da_cidade: "ERECHIM",
  },
  {
    codigo_do_IGBE: 2506004,
    UF: "PB",
    nome_da_cidade: "ESPERANCA",
  },
  {
    codigo_do_IGBE: 3124807,
    UF: "MG",
    nome_da_cidade: "ESTRELA DO SUL",
  },
  {
    codigo_do_IGBE: 3125309,
    UF: "MG",
    nome_da_cidade: "FARIA LEMOS",
  },
  {
    codigo_do_IGBE: 4308003,
    UF: "RS",
    nome_da_cidade: "FAXINAL DO SOTURNO",
  },
  {
    codigo_do_IGBE: 4308102,
    UF: "RS",
    nome_da_cidade: "FELIZ",
  },
  {
    codigo_do_IGBE: 2605459,
    UF: "PE",
    nome_da_cidade: "FERNANDO DE NORONHA",
  },
  {
    codigo_do_IGBE: 3515509,
    UF: "SP",
    nome_da_cidade: "FERNANDOPOLIS",
  },
  {
    codigo_do_IGBE: 4107850,
    UF: "PR",
    nome_da_cidade: "FLOR DA SERRA DO SUL",
  },
  {
    codigo_do_IGBE: 2203859,
    UF: "PI",
    nome_da_cidade: "FLORESTA DO PIAUI",
  },
  {
    codigo_do_IGBE: 2104099,
    UF: "MA",
    nome_da_cidade: "FORMOSA DA SERRA NEGRA",
  },
  {
    codigo_do_IGBE: 3516408,
    UF: "SP",
    nome_da_cidade: "FRANCO DA ROCHA",
  },
  {
    codigo_do_IGBE: 2404002,
    UF: "RN",
    nome_da_cidade: "FRUTUOSO GOMES",
  },
  {
    codigo_do_IGBE: 4205704,
    UF: "SC",
    nome_da_cidade: "GAROPABA",
  },
  {
    codigo_do_IGBE: 4308805,
    UF: "RS",
    nome_da_cidade: "GENERAL CAMARA",
  },
  {
    codigo_do_IGBE: 5004007,
    UF: "MS",
    nome_da_cidade: "GLORIA DE DOURADOS",
  },
  {
    codigo_do_IGBE: 4108551,
    UF: "PR",
    nome_da_cidade: "GODOY MOREIRA",
  },
  {
    codigo_do_IGBE: 4108650,
    UF: "PR",
    nome_da_cidade: "GOIOXIM",
  },
  {
    codigo_do_IGBE: 2404309,
    UF: "RN",
    nome_da_cidade: "GOVERNADOR DIX-SEPT ROSADO",
  },
  {
    codigo_do_IGBE: 3127800,
    UF: "MG",
    nome_da_cidade: "GRAO MOGOL",
  },
  {
    codigo_do_IGBE: 3517208,
    UF: "SP",
    nome_da_cidade: "GUAICARA",
  },
  {
    codigo_do_IGBE: 3128105,
    UF: "MG",
    nome_da_cidade: "GUAPE",
  },
  {
    codigo_do_IGBE: 3128204,
    UF: "MG",
    nome_da_cidade: "GUARACIABA",
  },
  {
    codigo_do_IGBE: 2204550,
    UF: "PI",
    nome_da_cidade: "GUARIBAS",
  },
  {
    codigo_do_IGBE: 3129004,
    UF: "MG",
    nome_da_cidade: "GUIRICEMA",
  },
  {
    codigo_do_IGBE: 4206702,
    UF: "SC",
    nome_da_cidade: "HERVAL D'OESTE",
  },
  {
    codigo_do_IGBE: 2105005,
    UF: "MA",
    nome_da_cidade: "HUMBERTO DE CAMPOS",
  },
  {
    codigo_do_IGBE: 3519253,
    UF: "SP",
    nome_da_cidade: "IARAS",
  },
  {
    codigo_do_IGBE: 3129608,
    UF: "MG",
    nome_da_cidade: "IBIAI",
  },
  {
    codigo_do_IGBE: 4109807,
    UF: "PR",
    nome_da_cidade: "IBIPORA",
  },
  {
    codigo_do_IGBE: 2912905,
    UF: "BA",
    nome_da_cidade: "IBIRATAIA",
  },
  {
    codigo_do_IGBE: 3130051,
    UF: "MG",
    nome_da_cidade: "ICARAI DE MINAS",
  },
  {
    codigo_do_IGBE: 2105153,
    UF: "MA",
    nome_da_cidade: "IGARAPE DO MEIO",
  },
  {
    codigo_do_IGBE: 3130309,
    UF: "MG",
    nome_da_cidade: "IGUATAMA",
  },
  {
    codigo_do_IGBE: 4207106,
    UF: "SC",
    nome_da_cidade: "ILHOTA",
  },
  {
    codigo_do_IGBE: 4110201,
    UF: "PR",
    nome_da_cidade: "INACIO MARTINS",
  },
  {
    codigo_do_IGBE: 2802809,
    UF: "SE",
    nome_da_cidade: "INDIAROBA",
  },
  {
    codigo_do_IGBE: 3131109,
    UF: "MG",
    nome_da_cidade: "INIMUTABA",
  },
  {
    codigo_do_IGBE: 3131208,
    UF: "MG",
    nome_da_cidade: "IPANEMA",
  },
  {
    codigo_do_IGBE: 2305654,
    UF: "CE",
    nome_da_cidade: "IPAPORANGA",
  },
  {
    codigo_do_IGBE: 3131307,
    UF: "MG",
    nome_da_cidade: "IPATINGA",
  },
  {
    codigo_do_IGBE: 2305704,
    UF: "CE",
    nome_da_cidade: "IPAUMIRIM",
  },
  {
    codigo_do_IGBE: 3520905,
    UF: "SP",
    nome_da_cidade: "IPAUSSU",
  },
  {
    codigo_do_IGBE: 4310439,
    UF: "RS",
    nome_da_cidade: "IPE",
  },
  {
    codigo_do_IGBE: 2913804,
    UF: "BA",
    nome_da_cidade: "IPECAETA",
  },
  {
    codigo_do_IGBE: 3521002,
    UF: "SP",
    nome_da_cidade: "IPERO",
  },
  {
    codigo_do_IGBE: 3521101,
    UF: "SP",
    nome_da_cidade: "IPEUNA",
  },
  {
    codigo_do_IGBE: 2204808,
    UF: "PI",
    nome_da_cidade: "IPIRANGA DO PIAUI",
  },
  {
    codigo_do_IGBE: 4207700,
    UF: "SC",
    nome_da_cidade: "IPUMIRIM",
  },
  {
    codigo_do_IGBE: 3521507,
    UF: "SP",
    nome_da_cidade: "IRAPUA",
  },
  {
    codigo_do_IGBE: 3131802,
    UF: "MG",
    nome_da_cidade: "ITABIRINHA DE MANTENA",
  },
  {
    codigo_do_IGBE: 5210562,
    UF: "GO",
    nome_da_cidade: "ITAGUARI",
  },
  {
    codigo_do_IGBE: 5210802,
    UF: "GO",
    nome_da_cidade: "ITAJA",
  },
  {
    codigo_do_IGBE: 3132602,
    UF: "MG",
    nome_da_cidade: "ITAMARATI DE MINAS",
  },
  {
    codigo_do_IGBE: 3133303,
    UF: "MG",
    nome_da_cidade: "ITAOBIM",
  },
  {
    codigo_do_IGBE: 2105401,
    UF: "MA",
    nome_da_cidade: "ITAPECURU MIRIM",
  },
  {
    codigo_do_IGBE: 2916500,
    UF: "BA",
    nome_da_cidade: "ITAPICURU",
  },
  {
    codigo_do_IGBE: 2306405,
    UF: "CE",
    nome_da_cidade: "ITAPIPOCA",
  },
  {
    codigo_do_IGBE: 3522604,
    UF: "SP",
    nome_da_cidade: "ITAPIRA",
  },
  {
    codigo_do_IGBE: 1302009,
    UF: "AM",
    nome_da_cidade: "ITAPIRANGA",
  },
  {
    codigo_do_IGBE: 4208401,
    UF: "SC",
    nome_da_cidade: "ITAPIRANGA",
  },
  {
    codigo_do_IGBE: 5211008,
    UF: "GO",
    nome_da_cidade: "ITAPIRAPUA",
  },
  {
    codigo_do_IGBE: 3522653,
    UF: "SP",
    nome_da_cidade: "ITAPIRAPUA PAULISTA",
  },
  {
    codigo_do_IGBE: 3202900,
    UF: "ES",
    nome_da_cidade: "ITARANA",
  },
  {
    codigo_do_IGBE: 2306603,
    UF: "CE",
    nome_da_cidade: "ITATIRA",
  },
  {
    codigo_do_IGBE: 2105427,
    UF: "MA",
    nome_da_cidade: "ITINGA DO MARANHAO",
  },
  {
    codigo_do_IGBE: 1503705,
    UF: "PA",
    nome_da_cidade: "ITUPIRANGA",
  },
  {
    codigo_do_IGBE: 4310751,
    UF: "RS",
    nome_da_cidade: "IVORA",
  },
  {
    codigo_do_IGBE: 2703403,
    UF: "AL",
    nome_da_cidade: "JACARE DOS HOMENS",
  },
  {
    codigo_do_IGBE: 3134905,
    UF: "MG",
    nome_da_cidade: "JACUTINGA",
  },
  {
    codigo_do_IGBE: 2917805,
    UF: "BA",
    nome_da_cidade: "JAGUARIPE",
  },
  {
    codigo_do_IGBE: 4112108,
    UF: "PR",
    nome_da_cidade: "JANDAIA DO SUL",
  },
  {
    codigo_do_IGBE: 5004809,
    UF: "MS",
    nome_da_cidade: "JAPORA",
  },
  {
    codigo_do_IGBE: 4112405,
    UF: "PR",
    nome_da_cidade: "JAPURA",
  },
  {
    codigo_do_IGBE: 2607950,
    UF: "PE",
    nome_da_cidade: "JAQUEIRA",
  },
  {
    codigo_do_IGBE: 4311122,
    UF: "RS",
    nome_da_cidade: "JAQUIRANA",
  },
  {
    codigo_do_IGBE: 5211800,
    UF: "GO",
    nome_da_cidade: "JARAGUA",
  },
  {
    codigo_do_IGBE: 5004908,
    UF: "MS",
    nome_da_cidade: "JARAGUARI",
  },
  {
    codigo_do_IGBE: 2703700,
    UF: "AL",
    nome_da_cidade: "JARAMATAIA",
  },
  {
    codigo_do_IGBE: 2307106,
    UF: "CE",
    nome_da_cidade: "JARDIM",
  },
  {
    codigo_do_IGBE: 4112504,
    UF: "PR",
    nome_da_cidade: "JARDIM ALEGRE",
  },
  {
    codigo_do_IGBE: 3135704,
    UF: "MG",
    nome_da_cidade: "JEQUITIBA",
  },
  {
    codigo_do_IGBE: 2307254,
    UF: "CE",
    nome_da_cidade: "JIJOCA DE JERICOACOARA",
  },
  {
    codigo_do_IGBE: 3136405,
    UF: "MG",
    nome_da_cidade: "JOAQUIM FELICIO",
  },
  {
    codigo_do_IGBE: 3136520,
    UF: "MG",
    nome_da_cidade: "JOSE GONCALVES DE MINAS",
  },
  {
    codigo_do_IGBE: 2205524,
    UF: "PI",
    nome_da_cidade: "JULIO BORGES",
  },
  {
    codigo_do_IGBE: 3526001,
    UF: "SP",
    nome_da_cidade: "JUNQUEIROPOLIS",
  },
  {
    codigo_do_IGBE: 5212204,
    UF: "GO",
    nome_da_cidade: "JUSSARA",
  },
  {
    codigo_do_IGBE: 2918704,
    UF: "BA",
    nome_da_cidade: "LAFAIETE COUTINHO",
  },
  {
    codigo_do_IGBE: 2508208,
    UF: "PB",
    nome_da_cidade: "LAGOA DE DENTRO",
  },
  {
    codigo_do_IGBE: 2205573,
    UF: "PI",
    nome_da_cidade: "LAGOA DE SAO FRANCISCO",
  },
  {
    codigo_do_IGBE: 2608602,
    UF: "PE",
    nome_da_cidade: "LAGOA DO OURO",
  },
  {
    codigo_do_IGBE: 4311304,
    UF: "RS",
    nome_da_cidade: "LAGOA VERMELHA",
  },
  {
    codigo_do_IGBE: 2608800,
    UF: "PE",
    nome_da_cidade: "LAJEDO",
  },
  {
    codigo_do_IGBE: 3203163,
    UF: "ES",
    nome_da_cidade: "LARANJA DA TERRA",
  },
  {
    codigo_do_IGBE: 2307502,
    UF: "CE",
    nome_da_cidade: "LAVRAS DA MANGABEIRA",
  },
  {
    codigo_do_IGBE: 2919405,
    UF: "BA",
    nome_da_cidade: "LICINIO DE ALMEIDA",
  },
  {
    codigo_do_IGBE: 2508505,
    UF: "PB",
    nome_da_cidade: "LIVRAMENTO",
  },
  {
    codigo_do_IGBE: 3527306,
    UF: "SP",
    nome_da_cidade: "LOUVEIRA",
  },
  {
    codigo_do_IGBE: 2919553,
    UF: "BA",
    nome_da_cidade: "LUIS EDUARDO MAGALHAES",
  },
  {
    codigo_do_IGBE: 4311718,
    UF: "RS",
    nome_da_cidade: "MACAMBARA",
  },
  {
    codigo_do_IGBE: 2704302,
    UF: "AL",
    nome_da_cidade: "MACEIO",
  },
  {
    codigo_do_IGBE: 2919926,
    UF: "BA",
    nome_da_cidade: "MADRE DE DEUS",
  },
  {
    codigo_do_IGBE: 1302504,
    UF: "AM",
    nome_da_cidade: "MANACAPURU",
  },
  {
    codigo_do_IGBE: 4114401,
    UF: "PR",
    nome_da_cidade: "MANGUEIRINHA",
  },
  {
    codigo_do_IGBE: 3139805,
    UF: "MG",
    nome_da_cidade: "MAR DE ESPANHA",
  },
  {
    codigo_do_IGBE: 2609204,
    UF: "PE",
    nome_da_cidade: "MARAIAL",
  },
  {
    codigo_do_IGBE: 2509057,
    UF: "PB",
    nome_da_cidade: "MARCACAO",
  },
  {
    codigo_do_IGBE: 1200351,
    UF: "AC",
    nome_da_cidade: "MARECHAL THAUMATURGO",
  },
  {
    codigo_do_IGBE: 4114906,
    UF: "PR",
    nome_da_cidade: "MARILANDIA DO SUL",
  },
  {
    codigo_do_IGBE: 4312054,
    UF: "RS",
    nome_da_cidade: "MARQUES DE SOUZA",
  },
  {
    codigo_do_IGBE: 4210605,
    UF: "SC",
    nome_da_cidade: "MASSARANDUBA",
  },
  {
    codigo_do_IGBE: 3140605,
    UF: "MG",
    nome_da_cidade: "MATERLANDIA",
  },
  {
    codigo_do_IGBE: 4312138,
    UF: "RS",
    nome_da_cidade: "MATO CASTELHANO",
  },
  {
    codigo_do_IGBE: 4115754,
    UF: "PR",
    nome_da_cidade: "MAUA DA SERRA",
  },
  {
    codigo_do_IGBE: 3141504,
    UF: "MG",
    nome_da_cidade: "MENDES PIMENTEL",
  },
  {
    codigo_do_IGBE: 3302908,
    UF: "RJ",
    nome_da_cidade: "MIGUEL PEREIRA",
  },
  {
    codigo_do_IGBE: 2106755,
    UF: "MA",
    nome_da_cidade: "MIRANDA DO NORTE",
  },
  {
    codigo_do_IGBE: 1504604,
    UF: "PA",
    nome_da_cidade: "MOCAJUBA",
  },
  {
    codigo_do_IGBE: 3530904,
    UF: "SP",
    nome_da_cidade: "MOMBUCA",
  },
  {
    codigo_do_IGBE: 2407708,
    UF: "RN",
    nome_da_cidade: "MONTANHAS",
  },
  {
    codigo_do_IGBE: 4312377,
    UF: "RS",
    nome_da_cidade: "MONTE ALEGRE DOS CAMPOS",
  },
  {
    codigo_do_IGBE: 3531308,
    UF: "SP",
    nome_da_cidade: "MONTE ALTO",
  },
  {
    codigo_do_IGBE: 3531407,
    UF: "SP",
    nome_da_cidade: "MONTE APRAZIVEL",
  },
  {
    codigo_do_IGBE: 3143203,
    UF: "MG",
    nome_da_cidade: "MONTE SANTO DE MINAS",
  },
  {
    codigo_do_IGBE: 3143500,
    UF: "MG",
    nome_da_cidade: "MORADA NOVA DE MINAS",
  },
  {
    codigo_do_IGBE: 2206654,
    UF: "PI",
    nome_da_cidade: "MORRO CABECA NO TEMPO",
  },
  {
    codigo_do_IGBE: 2921906,
    UF: "BA",
    nome_da_cidade: "MUCUGE",
  },
  {
    codigo_do_IGBE: 4116307,
    UF: "PR",
    nome_da_cidade: "MUNHOZ DE MELO",
  },
  {
    codigo_do_IGBE: 3144102,
    UF: "MG",
    nome_da_cidade: "MUZAMBINHO",
  },
  {
    codigo_do_IGBE: 3532306,
    UF: "SP",
    nome_da_cidade: "NATIVIDADE DA SERRA",
  },
  {
    codigo_do_IGBE: 1400407,
    UF: "RR",
    nome_da_cidade: "NORMANDIA",
  },
  {
    codigo_do_IGBE: 2804805,
    UF: "SE",
    nome_da_cidade: "NOSSA SENHORA DO SOCORRO",
  },
  {
    codigo_do_IGBE: 4116505,
    UF: "PR",
    nome_da_cidade: "NOVA ALIANCA DO IVAI",
  },
  {
    codigo_do_IGBE: 4116604,
    UF: "PR",
    nome_da_cidade: "NOVA AMERICA DA COLINA",
  },
  {
    codigo_do_IGBE: 2408300,
    UF: "RN",
    nome_da_cidade: "NOVA CRUZ",
  },
  {
    codigo_do_IGBE: 3303401,
    UF: "RJ",
    nome_da_cidade: "NOVA FRIBURGO",
  },
  {
    codigo_do_IGBE: 4117057,
    UF: "PR",
    nome_da_cidade: "NOVA LARANJEIRAS",
  },
  {
    codigo_do_IGBE: 2107357,
    UF: "MA",
    nome_da_cidade: "NOVA OLINDA DO MARANHAO",
  },
  {
    codigo_do_IGBE: 1715002,
    UF: "TO",
    nome_da_cidade: "NOVA ROSALANDIA",
  },
  {
    codigo_do_IGBE: 4211603,
    UF: "SC",
    nome_da_cidade: "NOVA VENEZA",
  },
  {
    codigo_do_IGBE: 3145307,
    UF: "MG",
    nome_da_cidade: "NOVO CRUZEIRO",
  },
  {
    codigo_do_IGBE: 3145356,
    UF: "MG",
    nome_da_cidade: "NOVO ORIENTE DE MINAS",
  },
  {
    codigo_do_IGBE: 1505205,
    UF: "PA",
    nome_da_cidade: "OEIRAS DO PARA",
  },
  {
    codigo_do_IGBE: 3145406,
    UF: "MG",
    nome_da_cidade: "OLARIA",
  },
  {
    codigo_do_IGBE: 3145877,
    UF: "MG",
    nome_da_cidade: "ORIZANIA",
  },
  {
    codigo_do_IGBE: 4211751,
    UF: "SC",
    nome_da_cidade: "OTACILIO COSTA",
  },
  {
    codigo_do_IGBE: 3534807,
    UF: "SP",
    nome_da_cidade: "OURO VERDE",
  },
  {
    codigo_do_IGBE: 2107506,
    UF: "MA",
    nome_da_cidade: "PACO DO LUMIAR",
  },
  {
    codigo_do_IGBE: 3146602,
    UF: "MG",
    nome_da_cidade: "PAIVA",
  },
  {
    codigo_do_IGBE: 4313656,
    UF: "RS",
    nome_da_cidade: "PALMARES DO SUL",
  },
  {
    codigo_do_IGBE: 5215702,
    UF: "GO",
    nome_da_cidade: "PALMEIRAS DE GOIAS",
  },
  {
    codigo_do_IGBE: 3535408,
    UF: "SP",
    nome_da_cidade: "PANORAMA",
  },
  {
    codigo_do_IGBE: 3535507,
    UF: "SP",
    nome_da_cidade: "PARAGUACU PAULISTA",
  },
  {
    codigo_do_IGBE: 3147303,
    UF: "MG",
    nome_da_cidade: "PARAISOPOLIS",
  },
  {
    codigo_do_IGBE: 2923605,
    UF: "BA",
    nome_da_cidade: "PARAMIRIM",
  },
  {
    codigo_do_IGBE: 5106307,
    UF: "MT",
    nome_da_cidade: "PARANATINGA",
  },
  {
    codigo_do_IGBE: 4314035,
    UF: "RS",
    nome_da_cidade: "PARECI NOVO",
  },
  {
    codigo_do_IGBE: 2107803,
    UF: "MA",
    nome_da_cidade: "PARNARAMA",
  },
  {
    codigo_do_IGBE: 3536307,
    UF: "SP",
    nome_da_cidade: "PATROCINIO PAULISTA",
  },
  {
    codigo_do_IGBE: 2207801,
    UF: "PI",
    nome_da_cidade: "PAULISTANA",
  },
  {
    codigo_do_IGBE: 2924058,
    UF: "BA",
    nome_da_cidade: "PE DE SERRA",
  },
  {
    codigo_do_IGBE: 1600154,
    UF: "AP",
    nome_da_cidade: "PEDRA BRANCA DO AMAPARI",
  },
  {
    codigo_do_IGBE: 3537156,
    UF: "SP",
    nome_da_cidade: "PEDRINHAS PAULISTA",
  },
  {
    codigo_do_IGBE: 3149408,
    UF: "MG",
    nome_da_cidade: "PEDRO TEIXEIRA",
  },
  {
    codigo_do_IGBE: 2108306,
    UF: "MA",
    nome_da_cidade: "PENALVA",
  },
  {
    codigo_do_IGBE: 3149903,
    UF: "MG",
    nome_da_cidade: "PERDOES",
  },
  {
    codigo_do_IGBE: 3150000,
    UF: "MG",
    nome_da_cidade: "PESCADOR",
  },
  {
    codigo_do_IGBE: 1505635,
    UF: "PA",
    nome_da_cidade: "PICARRA",
  },
  {
    codigo_do_IGBE: 5216908,
    UF: "GO",
    nome_da_cidade: "PILAR DE GOIAS",
  },
  {
    codigo_do_IGBE: 1717008,
    UF: "TO",
    nome_da_cidade: "PINDORAMA DO TOCANTINS",
  },
  {
    codigo_do_IGBE: 4314506,
    UF: "RS",
    nome_da_cidade: "PINHEIRO MACHADO",
  },
  {
    codigo_do_IGBE: 2924678,
    UF: "BA",
    nome_da_cidade: "PIRAI DO NORTE",
  },
  {
    codigo_do_IGBE: 3539103,
    UF: "SP",
    nome_da_cidade: "PIRAPORA DO BOM JESUS",
  },
  {
    codigo_do_IGBE: 4119657,
    UF: "PR",
    nome_da_cidade: "PITANGUEIRAS",
  },
  {
    codigo_do_IGBE: 4119806,
    UF: "PR",
    nome_da_cidade: "PLANALTO",
  },
  {
    codigo_do_IGBE: 2611200,
    UF: "PE",
    nome_da_cidade: "POCAO",
  },
  {
    codigo_do_IGBE: 2512002,
    UF: "PB",
    nome_da_cidade: "POCINHOS",
  },
  {
    codigo_do_IGBE: 4314753,
    UF: "RS",
    nome_da_cidade: "POCO DAS ANTAS",
  },
  {
    codigo_do_IGBE: 3540101,
    UF: "SP",
    nome_da_cidade: "PONGAI",
  },
  {
    codigo_do_IGBE: 1717800,
    UF: "TO",
    nome_da_cidade: "PONTE ALTA DO BOM JESUS",
  },
  {
    codigo_do_IGBE: 2410207,
    UF: "RN",
    nome_da_cidade: "PORTALEGRE",
  },
  {
    codigo_do_IGBE: 5106778,
    UF: "MT",
    nome_da_cidade: "PORTO ALEGRE DO NORTE",
  },
  {
    codigo_do_IGBE: 4315057,
    UF: "RS",
    nome_da_cidade: "PORTO MAUA",
  },
  {
    codigo_do_IGBE: 4120309,
    UF: "PR",
    nome_da_cidade: "PORTO VITORIA",
  },
  {
    codigo_do_IGBE: 2925501,
    UF: "BA",
    nome_da_cidade: "PRADO",
  },
  {
    codigo_do_IGBE: 2208601,
    UF: "PI",
    nome_da_cidade: "PRATA DO PIAUI",
  },
  {
    codigo_do_IGBE: 2925709,
    UF: "BA",
    nome_da_cidade: "PRESIDENTE JANIO QUADROS",
  },
  {
    codigo_do_IGBE: 2109270,
    UF: "MA",
    nome_da_cidade: "PRESIDENTE SARNEY",
  },
  {
    codigo_do_IGBE: 4120606,
    UF: "PR",
    nome_da_cidade: "PRUDENTOPOLIS",
  },
  {
    codigo_do_IGBE: 4120853,
    UF: "PR",
    nome_da_cidade: "QUATRO PONTES",
  },
  {
    codigo_do_IGBE: 4121109,
    UF: "PR",
    nome_da_cidade: "QUINTA DO SOL",
  },
  {
    codigo_do_IGBE: 2410504,
    UF: "RN",
    nome_da_cidade: "RAFAEL FERNANDES",
  },
  {
    codigo_do_IGBE: 1718501,
    UF: "TO",
    nome_da_cidade: "RECURSOLANDIA",
  },
  {
    codigo_do_IGBE: 3304201,
    UF: "RJ",
    nome_da_cidade: "RESENDE",
  },
  {
    codigo_do_IGBE: 2926202,
    UF: "BA",
    nome_da_cidade: "RIACHAO DAS NEVES",
  },
  {
    codigo_do_IGBE: 2805901,
    UF: "SE",
    nome_da_cidade: "RIACHUELO",
  },
  {
    codigo_do_IGBE: 3543006,
    UF: "SP",
    nome_da_cidade: "RIBEIRAO BRANCO",
  },
  {
    codigo_do_IGBE: 3154705,
    UF: "MG",
    nome_da_cidade: "RIBEIRAO VERMELHO",
  },
  {
    codigo_do_IGBE: 4122156,
    UF: "PR",
    nome_da_cidade: "RIO BONITO DO IGUACU",
  },
  {
    codigo_do_IGBE: 2926707,
    UF: "BA",
    nome_da_cidade: "RIO DE CONTAS",
  },
  {
    codigo_do_IGBE: 3544103,
    UF: "SP",
    nome_da_cidade: "RIO GRANDE DA SERRA",
  },
  {
    codigo_do_IGBE: 3155702,
    UF: "MG",
    nome_da_cidade: "RIO PIRACICABA",
  },
  {
    codigo_do_IGBE: 4315800,
    UF: "RS",
    nome_da_cidade: "ROCA SALES",
  },
  {
    codigo_do_IGBE: 1100288,
    UF: "RO",
    nome_da_cidade: "ROLIM DE MOURA",
  },
  {
    codigo_do_IGBE: 3156452,
    UF: "MG",
    nome_da_cidade: "ROSARIO DA LIMEIRA",
  },
  {
    codigo_do_IGBE: 4122701,
    UF: "PR",
    nome_da_cidade: "SABAUDIA",
  },
  {
    codigo_do_IGBE: 2513000,
    UF: "PB",
    nome_da_cidade: "SALGADINHO",
  },
  {
    codigo_do_IGBE: 3545159,
    UF: "SP",
    nome_da_cidade: "SALTINHO",
  },
  {
    codigo_do_IGBE: 4316501,
    UF: "RS",
    nome_da_cidade: "SALVADOR DO SUL",
  },
  {
    codigo_do_IGBE: 5219100,
    UF: "GO",
    nome_da_cidade: "SANTA BARBARA DE GOIAS",
  },
  {
    codigo_do_IGBE: 2927705,
    UF: "BA",
    nome_da_cidade: "SANTA CRUZ CABRALIA",
  },
  {
    codigo_do_IGBE: 2612505,
    UF: "PE",
    nome_da_cidade: "SANTA CRUZ DO CAPIBARIBE",
  },
  {
    codigo_do_IGBE: 4215554,
    UF: "SC",
    nome_da_cidade: "SANTA HELENA",
  },
  {
    codigo_do_IGBE: 1303601,
    UF: "AM",
    nome_da_cidade: "SANTA ISABEL DO RIO NEGRO",
  },
  {
    codigo_do_IGBE: 2612604,
    UF: "PE",
    nome_da_cidade: "SANTA MARIA DA BOA VISTA",
  },
  {
    codigo_do_IGBE: 2312205,
    UF: "CE",
    nome_da_cidade: "SANTA QUITERIA",
  },
  {
    codigo_do_IGBE: 3547502,
    UF: "SP",
    nome_da_cidade: "SANTA RITA DO PASSA QUATRO",
  },
  {
    codigo_do_IGBE: 3159704,
    UF: "MG",
    nome_da_cidade: "SANTA ROSA DA SERRA",
  },
  {
    codigo_do_IGBE: 3547205,
    UF: "SP",
    nome_da_cidade: "SANTANA DA PONTE PENSA",
  },
  {
    codigo_do_IGBE: 3158953,
    UF: "MG",
    nome_da_cidade: "SANTANA DO PARAISO",
  },
  {
    codigo_do_IGBE: 4215703,
    UF: "SC",
    nome_da_cidade: "SANTO AMARO DA IMPERATRIZ",
  },
  {
    codigo_do_IGBE: 2209401,
    UF: "PI",
    nome_da_cidade: "SANTO ANTONIO DE LISBOA",
  },
  {
    codigo_do_IGBE: 4124301,
    UF: "PR",
    nome_da_cidade: "SANTO ANTONIO DO PARAISO",
  },
  {
    codigo_do_IGBE: 3548401,
    UF: "SP",
    nome_da_cidade: "SANTOPOLIS DO AGUAPEI",
  },
  {
    codigo_do_IGBE: 3548708,
    UF: "SP",
    nome_da_cidade: "SAO BERNARDO DO CAMPO",
  },
  {
    codigo_do_IGBE: 3160900,
    UF: "MG",
    nome_da_cidade: "SAO BRAS DO SUACUI",
  },
  {
    codigo_do_IGBE: 3160959,
    UF: "MG",
    nome_da_cidade: "SAO DOMINGOS DAS DORES",
  },
  {
    codigo_do_IGBE: 5107859,
    UF: "MT",
    nome_da_cidade: "SAO FELIX DO ARAGUAIA",
  },
  {
    codigo_do_IGBE: 3161304,
    UF: "MG",
    nome_da_cidade: "SAO FRANCISCO DE SALES",
  },
  {
    codigo_do_IGBE: 1507458,
    UF: "PA",
    nome_da_cidade: "SAO GERALDO DO ARAGUAIA",
  },
  {
    codigo_do_IGBE: 4216305,
    UF: "SC",
    nome_da_cidade: "SAO JOAO BATISTA",
  },
  {
    codigo_do_IGBE: 5220009,
    UF: "GO",
    nome_da_cidade: "SAO JOAO D'ALIANCA",
  },
  {
    codigo_do_IGBE: 2312502,
    UF: "CE",
    nome_da_cidade: "SAO JOAO DO JAGUARIBE",
  },
  {
    codigo_do_IGBE: 2111102,
    UF: "MA",
    nome_da_cidade: "SAO JOAO DOS PATOS",
  },
  {
    codigo_do_IGBE: 3549409,
    UF: "SP",
    nome_da_cidade: "SAO JOAQUIM DA BARRA",
  },
  {
    codigo_do_IGBE: 3163003,
    UF: "MG",
    nome_da_cidade: "SAO JOSE DA SAFIRA",
  },
  {
    codigo_do_IGBE: 3163508,
    UF: "MG",
    nome_da_cidade: "SAO JOSE DO JACURI",
  },
  {
    codigo_do_IGBE: 3305158,
    UF: "RJ",
    nome_da_cidade: "SAO JOSE DO VALE DO RIO PRETO",
  },
  {
    codigo_do_IGBE: 5220108,
    UF: "GO",
    nome_da_cidade: "SAO LUIS DE MONTES BELOS",
  },
  {
    codigo_do_IGBE: 3550209,
    UF: "SP",
    nome_da_cidade: "SAO MIGUEL ARCANJO",
  },
  {
    codigo_do_IGBE: 5220264,
    UF: "GO",
    nome_da_cidade: "SAO MIGUEL DO PASSA QUATRO",
  },
  {
    codigo_do_IGBE: 3163904,
    UF: "MG",
    nome_da_cidade: "SAO PEDRO DA UNIAO",
  },
  {
    codigo_do_IGBE: 4319364,
    UF: "RS",
    nome_da_cidade: "SAO PEDRO DAS MISSOES",
  },
  {
    codigo_do_IGBE: 2210508,
    UF: "PI",
    nome_da_cidade: "SAO PEDRO DO PIAUI",
  },
  {
    codigo_do_IGBE: 1720259,
    UF: "TO",
    nome_da_cidade: "SAO SALVADOR DO TOCANTINS",
  },
  {
    codigo_do_IGBE: 2929503,
    UF: "BA",
    nome_da_cidade: "SAO SEBASTIAO DO PASSE",
  },
  {
    codigo_do_IGBE: 3165305,
    UF: "MG",
    nome_da_cidade: "SAO VICENTE DE MINAS",
  },
  {
    codigo_do_IGBE: 4320107,
    UF: "RS",
    nome_da_cidade: "SARANDI",
  },
  {
    codigo_do_IGBE: 2929909,
    UF: "BA",
    nome_da_cidade: "SEABRA",
  },
  {
    codigo_do_IGBE: 1200500,
    UF: "AC",
    nome_da_cidade: "SENA MADUREIRA",
  },
  {
    codigo_do_IGBE: 3551405,
    UF: "SP",
    nome_da_cidade: "SERRA AZUL",
  },
  {
    codigo_do_IGBE: 3166808,
    UF: "MG",
    nome_da_cidade: "SERRA DO SALITRE",
  },
  {
    codigo_do_IGBE: 3166956,
    UF: "MG",
    nome_da_cidade: "SERRANOPOLIS DE MINAS",
  },
  {
    codigo_do_IGBE: 4320578,
    UF: "RS",
    nome_da_cidade: "SETE DE SETEMBRO",
  },
  {
    codigo_do_IGBE: 1304005,
    UF: "AM",
    nome_da_cidade: "SILVES",
  },
  {
    codigo_do_IGBE: 4126603,
    UF: "PR",
    nome_da_cidade: "SIQUEIRA CAMPOS",
  },
  {
    codigo_do_IGBE: 4320800,
    UF: "RS",
    nome_da_cidade: "SOLEDADE",
  },
  {
    codigo_do_IGBE: 1720853,
    UF: "TO",
    nome_da_cidade: "SUCUPIRA",
  },
  {
    codigo_do_IGBE: 4320859,
    UF: "RS",
    nome_da_cidade: "TABAI",
  },
  {
    codigo_do_IGBE: 3552908,
    UF: "SP",
    nome_da_cidade: "TACIBA",
  },
  {
    codigo_do_IGBE: 4126678,
    UF: "PR",
    nome_da_cidade: "TAMARANA",
  },
  {
    codigo_do_IGBE: 2709004,
    UF: "AL",
    nome_da_cidade: "TANQUE D'ARCA",
  },
  {
    codigo_do_IGBE: 3168101,
    UF: "MG",
    nome_da_cidade: "TAPIRA",
  },
  {
    codigo_do_IGBE: 4321204,
    UF: "RS",
    nome_da_cidade: "TAQUARA",
  },
  {
    codigo_do_IGBE: 1200609,
    UF: "AC",
    nome_da_cidade: "TARAUACA",
  },
  {
    codigo_do_IGBE: 2931350,
    UF: "BA",
    nome_da_cidade: "TEIXEIRA DE FREITAS",
  },
  {
    codigo_do_IGBE: 5108055,
    UF: "MT",
    nome_da_cidade: "TERRA NOVA DO NORTE",
  },
  {
    codigo_do_IGBE: 4127601,
    UF: "PR",
    nome_da_cidade: "TIJUCAS DO SUL",
  },
  {
    codigo_do_IGBE: 2807402,
    UF: "SE",
    nome_da_cidade: "TOBIAS BARRETO",
  },
  {
    codigo_do_IGBE: 3554656,
    UF: "SP",
    nome_da_cidade: "TORRE DE PEDRA",
  },
  {
    codigo_do_IGBE: 4127858,
    UF: "PR",
    nome_da_cidade: "TRES BARRAS DO PARANA",
  },
  {
    codigo_do_IGBE: 3106200,
    UF: "MG",
    nome_da_cidade: "BELO HORIZONTE",
  },
  {
    codigo_do_IGBE: 5200050,
    UF: "GO",
    nome_da_cidade: "ABADIA DE GOIAS",
  },
  {
    codigo_do_IGBE: 4200051,
    UF: "SC",
    nome_da_cidade: "ABDON BATISTA",
  },
  {
    codigo_do_IGBE: 1200013,
    UF: "AC",
    nome_da_cidade: "ACRELANDIA",
  },
  {
    codigo_do_IGBE: 2600302,
    UF: "PE",
    nome_da_cidade: "AGRESTINA",
  },
  {
    codigo_do_IGBE: 3200169,
    UF: "ES",
    nome_da_cidade: "AGUA DOCE DO NORTE",
  },
  {
    codigo_do_IGBE: 3500550,
    UF: "SP",
    nome_da_cidade: "AGUAS DE SANTA BARBARA",
  },
  {
    codigo_do_IGBE: 5200258,
    UF: "GO",
    nome_da_cidade: "AGUAS LINDAS DE GOIAS",
  },
  {
    codigo_do_IGBE: 3200136,
    UF: "ES",
    nome_da_cidade: "AGUIA BRANCA",
  },
  {
    codigo_do_IGBE: 2500205,
    UF: "PB",
    nome_da_cidade: "AGUIAR",
  },
  {
    codigo_do_IGBE: 1700301,
    UF: "TO",
    nome_da_cidade: "AGUIARNOPOLIS",
  },
  {
    codigo_do_IGBE: 3101102,
    UF: "MG",
    nome_da_cidade: "AIMORES",
  },
  {
    codigo_do_IGBE: 2900603,
    UF: "BA",
    nome_da_cidade: "AIQUARA",
  },
  {
    codigo_do_IGBE: 2300408,
    UF: "CE",
    nome_da_cidade: "AIUABA",
  },
  {
    codigo_do_IGBE: 3101201,
    UF: "MG",
    nome_da_cidade: "AIURUOCA",
  },
  {
    codigo_do_IGBE: 4300208,
    UF: "RS",
    nome_da_cidade: "AJURICABA",
  },
  {
    codigo_do_IGBE: 3101300,
    UF: "MG",
    nome_da_cidade: "ALAGOA",
  },
  {
    codigo_do_IGBE: 2500304,
    UF: "PB",
    nome_da_cidade: "ALAGOA GRANDE",
  },
  {
    codigo_do_IGBE: 2500403,
    UF: "PB",
    nome_da_cidade: "ALAGOA NOVA",
  },
  {
    codigo_do_IGBE: 2500502,
    UF: "PB",
    nome_da_cidade: "ALAGOINHA",
  },
  {
    codigo_do_IGBE: 4100707,
    UF: "PR",
    nome_da_cidade: "ALTO PIQUIRI",
  },
  {
    codigo_do_IGBE: 3102100,
    UF: "MG",
    nome_da_cidade: "ALTO RIO DOCE",
  },
  {
    codigo_do_IGBE: 2200459,
    UF: "PI",
    nome_da_cidade: "ALVORADA DO GURGUEIA",
  },
  {
    codigo_do_IGBE: 2901106,
    UF: "BA",
    nome_da_cidade: "AMELIA RODRIGUES",
  },
  {
    codigo_do_IGBE: 1500701,
    UF: "PA",
    nome_da_cidade: "ANAJAS",
  },
  {
    codigo_do_IGBE: 2901304,
    UF: "BA",
    nome_da_cidade: "ANDARAI",
  },
  {
    codigo_do_IGBE: 2400802,
    UF: "RN",
    nome_da_cidade: "ANGICOS",
  },
  {
    codigo_do_IGBE: 2901601,
    UF: "BA",
    nome_da_cidade: "ANTAS",
  },
  {
    codigo_do_IGBE: 3103108,
    UF: "MG",
    nome_da_cidade: "ANTONIO PRADO DE MINAS",
  },
  {
    codigo_do_IGBE: 5201504,
    UF: "GO",
    nome_da_cidade: "APORE",
  },
  {
    codigo_do_IGBE: 2300903,
    UF: "CE",
    nome_da_cidade: "APUIARES",
  },
  {
    codigo_do_IGBE: 2902054,
    UF: "BA",
    nome_da_cidade: "ARACAS",
  },
  {
    codigo_do_IGBE: 2301208,
    UF: "CE",
    nome_da_cidade: "ARACOIABA",
  },
  {
    codigo_do_IGBE: 2100873,
    UF: "MA",
    nome_da_cidade: "ARAGUANA",
  },
  {
    codigo_do_IGBE: 3503000,
    UF: "SP",
    nome_da_cidade: "ARAMINA",
  },
  {
    codigo_do_IGBE: 4101655,
    UF: "PR",
    nome_da_cidade: "ARAPUA",
  },
  {
    codigo_do_IGBE: 4101705,
    UF: "PR",
    nome_da_cidade: "ARARUNA",
  },
  {
    codigo_do_IGBE: 3300225,
    UF: "RJ",
    nome_da_cidade: "AREAL",
  },
  {
    codigo_do_IGBE: 3104502,
    UF: "MG",
    nome_da_cidade: "ARINOS",
  },
  {
    codigo_do_IGBE: 3300258,
    UF: "RJ",
    nome_da_cidade: "ARRAIAL DO CABO",
  },
  {
    codigo_do_IGBE: 3504008,
    UF: "SP",
    nome_da_cidade: "ASSIS",
  },
  {
    codigo_do_IGBE: 3200706,
    UF: "ES",
    nome_da_cidade: "ATILIO VIVACQUA",
  },
  {
    codigo_do_IGBE: 2902401,
    UF: "BA",
    nome_da_cidade: "AURELINO LEAL",
  },
  {
    codigo_do_IGBE: 3504503,
    UF: "SP",
    nome_da_cidade: "AVARE",
  },
  {
    codigo_do_IGBE: 2501401,
    UF: "PB",
    nome_da_cidade: "BAIA DA TRAICAO",
  },
  {
    codigo_do_IGBE: 4212809,
    UF: "SC",
    nome_da_cidade: "BALNEARIO PICARRAS",
  },
  {
    codigo_do_IGBE: 1703057,
    UF: "TO",
    nome_da_cidade: "BANDEIRANTES DO TOCANTINS",
  },
  {
    codigo_do_IGBE: 2101509,
    UF: "MA",
    nome_da_cidade: "BARAO DE GRAJAU",
  },
  {
    codigo_do_IGBE: 3105608,
    UF: "MG",
    nome_da_cidade: "BARBACENA",
  },
  {
    codigo_do_IGBE: 2201176,
    UF: "PI",
    nome_da_cidade: "BARRA D'ALCANTARA",
  },
  {
    codigo_do_IGBE: 1703073,
    UF: "TO",
    nome_da_cidade: "BARRA DO OURO",
  },
  {
    codigo_do_IGBE: 2201309,
    UF: "PI",
    nome_da_cidade: "BARREIRAS DO PIAUI",
  },
  {
    codigo_do_IGBE: 3505807,
    UF: "SP",
    nome_da_cidade: "BASTOS",
  },
  {
    codigo_do_IGBE: 4102752,
    UF: "PR",
    nome_da_cidade: "BELA VISTA DA CAROBA",
  },
  {
    codigo_do_IGBE: 2502003,
    UF: "PB",
    nome_da_cidade: "BELEM DO BREJO DO CRUZ",
  },
  {
    codigo_do_IGBE: 4302055,
    UF: "RS",
    nome_da_cidade: "BENJAMIN CONSTANT DO SUL",
  },
  {
    codigo_do_IGBE: 3106655,
    UF: "MG",
    nome_da_cidade: "BERIZAL",
  },
  {
    codigo_do_IGBE: 3106903,
    UF: "MG",
    nome_da_cidade: "BICAS",
  },
  {
    codigo_do_IGBE: 4103024,
    UF: "PR",
    nome_da_cidade: "BOA ESPERANCA DO IGUACU",
  },
  {
    codigo_do_IGBE: 1300680,
    UF: "AM",
    nome_da_cidade: "BOA VISTA DO RAMOS",
  },
  {
    codigo_do_IGBE: 4302253,
    UF: "RS",
    nome_da_cidade: "BOA VISTA DO SUL",
  },
  {
    codigo_do_IGBE: 2602209,
    UF: "PE",
    nome_da_cidade: "BOM JARDIM",
  },
  {
    codigo_do_IGBE: 2903953,
    UF: "BA",
    nome_da_cidade: "BOM JESUS DA SERRA",
  },
  {
    codigo_do_IGBE: 2201919,
    UF: "PI",
    nome_da_cidade: "BOM PRINCIPIO DO PIAUI",
  },
  {
    codigo_do_IGBE: 3108255,
    UF: "MG",
    nome_da_cidade: "BONITO DE MINAS",
  },
  {
    codigo_do_IGBE: 4302501,
    UF: "RS",
    nome_da_cidade: "BOSSOROCA",
  },
  {
    codigo_do_IGBE: 5002308,
    UF: "MS",
    nome_da_cidade: "BRASILANDIA",
  },
  {
    codigo_do_IGBE: 2602506,
    UF: "PE",
    nome_da_cidade: "BREJINHO",
  },
  {
    codigo_do_IGBE: 1501758,
    UF: "PA",
    nome_da_cidade: "BREJO GRANDE DO ARAGUAIA",
  },
  {
    codigo_do_IGBE: 1200138,
    UF: "AC",
    nome_da_cidade: "BUJARI",
  },
  {
    codigo_do_IGBE: 5203962,
    UF: "GO",
    nome_da_cidade: "BURITINOPOLIS",
  },
  {
    codigo_do_IGBE: 3109303,
    UF: "MG",
    nome_da_cidade: "BURITIS",
  },
  {
    codigo_do_IGBE: 2904803,
    UF: "BA",
    nome_da_cidade: "CAATIBA",
  },
  {
    codigo_do_IGBE: 4302808,
    UF: "RS",
    nome_da_cidade: "CACAPAVA DO SUL",
  },
  {
    codigo_do_IGBE: 3102704,
    UF: "MG",
    nome_da_cidade: "CACHOEIRA DE PAJEU",
  },
  {
    codigo_do_IGBE: 5204250,
    UF: "GO",
    nome_da_cidade: "CACHOEIRA DOURADA",
  },
  {
    codigo_do_IGBE: 3109907,
    UF: "MG",
    nome_da_cidade: "CAETANOPOLIS",
  },
  {
    codigo_do_IGBE: 4303301,
    UF: "RS",
    nome_da_cidade: "CAIBATE",
  },
  {
    codigo_do_IGBE: 2503704,
    UF: "PB",
    nome_da_cidade: "CAJAZEIRAS",
  },
  {
    codigo_do_IGBE: 2905503,
    UF: "BA",
    nome_da_cidade: "CALDEIRAO GRANDE",
  },
  {
    codigo_do_IGBE: 4303608,
    UF: "RS",
    nome_da_cidade: "CAMBARA DO SUL",
  },
  {
    codigo_do_IGBE: 4303673,
    UF: "RS",
    nome_da_cidade: "CAMPESTRE DA SERRA",
  },
  {
    codigo_do_IGBE: 2905909,
    UF: "BA",
    nome_da_cidade: "CAMPO ALEGRE DE LOURDES",
  },
  {
    codigo_do_IGBE: 4203501,
    UF: "SC",
    nome_da_cidade: "CAMPO ERE",
  },
  {
    codigo_do_IGBE: 4218707,
    UF: "SC",
    nome_da_cidade: "TUBARAO",
  },
  {
    codigo_do_IGBE: 4322152,
    UF: "RS",
    nome_da_cidade: "TUNAS",
  },
  {
    codigo_do_IGBE: 3555109,
    UF: "SP",
    nome_da_cidade: "TUPI PAULISTA",
  },
  {
    codigo_do_IGBE: 2112506,
    UF: "MA",
    nome_da_cidade: "TUTOIA",
  },
  {
    codigo_do_IGBE: 3170206,
    UF: "MG",
    nome_da_cidade: "UBERLANDIA",
  },
  {
    codigo_do_IGBE: 2932457,
    UF: "BA",
    nome_da_cidade: "UMBURANAS",
  },
  {
    codigo_do_IGBE: 2709301,
    UF: "AL",
    nome_da_cidade: "UNIAO DOS PALMARES",
  },
  {
    codigo_do_IGBE: 3170503,
    UF: "MG",
    nome_da_cidade: "URUCANIA",
  },
  {
    codigo_do_IGBE: 2932804,
    UF: "BA",
    nome_da_cidade: "UTINGA",
  },
  {
    codigo_do_IGBE: 3556107,
    UF: "SP",
    nome_da_cidade: "VALENTIM GENTIL",
  },
  {
    codigo_do_IGBE: 3556503,
    UF: "SP",
    nome_da_cidade: "VARZEA PAULISTA",
  },
  {
    codigo_do_IGBE: 3171006,
    UF: "MG",
    nome_da_cidade: "VAZANTE",
  },
  {
    codigo_do_IGBE: 4322608,
    UF: "RS",
    nome_da_cidade: "VENANCIO AIRES",
  },
  {
    codigo_do_IGBE: 3171071,
    UF: "MG",
    nome_da_cidade: "VEREDINHA",
  },
  {
    codigo_do_IGBE: 4323101,
    UF: "RS",
    nome_da_cidade: "VICENTE DUTRA",
  },
  {
    codigo_do_IGBE: 5105507,
    UF: "MT",
    nome_da_cidade: "VILA BELA DA SANTISSIMA TRINDADE",
  },
  {
    codigo_do_IGBE: 3172004,
    UF: "MG",
    nome_da_cidade: "VISCONDE DO RIO BRANCO",
  },
  {
    codigo_do_IGBE: 2113009,
    UF: "MA",
    nome_da_cidade: "VITORINO FREIRE",
  },
  {
    codigo_do_IGBE: 4323804,
    UF: "RS",
    nome_da_cidade: "XANGRI-LA",
  },
  {
    codigo_do_IGBE: 5103403,
    UF: "MT",
    nome_da_cidade: "CUIABA",
  },
  {
    codigo_do_IGBE: 3111408,
    UF: "MG",
    nome_da_cidade: "CAMPO FLORIDO",
  },
  {
    codigo_do_IGBE: 1100700,
    UF: "RO",
    nome_da_cidade: "CAMPO NOVO DE RONDONIA",
  },
  {
    codigo_do_IGBE: 5102694,
    UF: "MT",
    nome_da_cidade: "CANABRAVA DO NORTE",
  },
  {
    codigo_do_IGBE: 2906501,
    UF: "BA",
    nome_da_cidade: "CANDEIAS",
  },
  {
    codigo_do_IGBE: 2801207,
    UF: "SE",
    nome_da_cidade: "CANINDE DE SAO FRANCISCO",
  },
  {
    codigo_do_IGBE: 4304622,
    UF: "RS",
    nome_da_cidade: "CAPAO BONITO DO SUL",
  },
  {
    codigo_do_IGBE: 4304630,
    UF: "RS",
    nome_da_cidade: "CAPAO DA CANOA",
  },
  {
    codigo_do_IGBE: 2102754,
    UF: "MA",
    nome_da_cidade: "CAPINZAL DO NORTE",
  },
  {
    codigo_do_IGBE: 1400209,
    UF: "RR",
    nome_da_cidade: "CARACARAI",
  },
  {
    codigo_do_IGBE: 2504074,
    UF: "PB",
    nome_da_cidade: "CARAUBAS",
  },
  {
    codigo_do_IGBE: 2202554,
    UF: "PI",
    nome_da_cidade: "CARIDADE DO PIAUI",
  },
  {
    codigo_do_IGBE: 3113909,
    UF: "MG",
    nome_da_cidade: "CARMO DA CACHOEIRA",
  },
  {
    codigo_do_IGBE: 3114006,
    UF: "MG",
    nome_da_cidade: "CARMO DA MATA",
  },
  {
    codigo_do_IGBE: 2603926,
    UF: "PE",
    nome_da_cidade: "CARNAUBEIRA DA PENHA",
  },
  {
    codigo_do_IGBE: 3115003,
    UF: "MG",
    nome_da_cidade: "CASCALHO RICO",
  },
  {
    codigo_do_IGBE: 2202604,
    UF: "PI",
    nome_da_cidade: "CASTELO DO PIAUI",
  },
  {
    codigo_do_IGBE: 2504306,
    UF: "PB",
    nome_da_cidade: "CATOLE DO ROCHA",
  },
  {
    codigo_do_IGBE: 5205208,
    UF: "GO",
    nome_da_cidade: "CATURAI",
  },
  {
    codigo_do_IGBE: 2103158,
    UF: "MA",
    nome_da_cidade: "CENTRO DO GUILHERME",
  },
  {
    codigo_do_IGBE: 2604403,
    UF: "PE",
    nome_da_cidade: "CHA DE ALEGRIA",
  },
  {
    codigo_do_IGBE: 5103007,
    UF: "MT",
    nome_da_cidade: "CHAPADA DOS GUIMARAES",
  },
  {
    codigo_do_IGBE: 3511706,
    UF: "SP",
    nome_da_cidade: "CHARQUEADA",
  },
  {
    codigo_do_IGBE: 4305447,
    UF: "RS",
    nome_da_cidade: "CHUVISCA",
  },
  {
    codigo_do_IGBE: 3511904,
    UF: "SP",
    nome_da_cidade: "CLEMENTINA",
  },
  {
    codigo_do_IGBE: 5103106,
    UF: "MT",
    nome_da_cidade: "COCALINHO",
  },
  {
    codigo_do_IGBE: 2702009,
    UF: "AL",
    nome_da_cidade: "COITE DO NOIA",
  },
  {
    codigo_do_IGBE: 2702108,
    UF: "AL",
    nome_da_cidade: "COLONIA LEOPOLDINA",
  },
  {
    codigo_do_IGBE: 1100064,
    UF: "RO",
    nome_da_cidade: "COLORADO DO OESTE",
  },
  {
    codigo_do_IGBE: 3116803,
    UF: "MG",
    nome_da_cidade: "COLUNA",
  },
  {
    codigo_do_IGBE: 1705557,
    UF: "TO",
    nome_da_cidade: "COMBINADO",
  },
  {
    codigo_do_IGBE: 3116902,
    UF: "MG",
    nome_da_cidade: "COMENDADOR GOMES",
  },
  {
    codigo_do_IGBE: 3117009,
    UF: "MG",
    nome_da_cidade: "COMERCINHO",
  },
  {
    codigo_do_IGBE: 5103304,
    UF: "MT",
    nome_da_cidade: "COMODORO",
  },
  {
    codigo_do_IGBE: 2504405,
    UF: "PB",
    nome_da_cidade: "CONCEICAO",
  },
  {
    codigo_do_IGBE: 3117108,
    UF: "MG",
    nome_da_cidade: "CONCEICAO DA APARECIDA",
  },
  {
    codigo_do_IGBE: 3201605,
    UF: "ES",
    nome_da_cidade: "CONCEICAO DA BARRA",
  },
  {
    codigo_do_IGBE: 3115201,
    UF: "MG",
    nome_da_cidade: "CONCEICAO DA BARRA DE MINAS",
  },
  {
    codigo_do_IGBE: 2908200,
    UF: "BA",
    nome_da_cidade: "CONCEICAO DA FEIRA",
  },
  {
    codigo_do_IGBE: 3117306,
    UF: "MG",
    nome_da_cidade: "CONCEICAO DAS ALAGOAS",
  },
  {
    codigo_do_IGBE: 3117207,
    UF: "MG",
    nome_da_cidade: "CONCEICAO DAS PEDRAS",
  },
  {
    codigo_do_IGBE: 3117405,
    UF: "MG",
    nome_da_cidade: "CONCEICAO DE IPANEMA",
  },
  {
    codigo_do_IGBE: 3301405,
    UF: "RJ",
    nome_da_cidade: "CONCEICAO DE MACABU",
  },
  {
    codigo_do_IGBE: 2908309,
    UF: "BA",
    nome_da_cidade: "CONCEICAO DO ALMEIDA",
  },
  {
    codigo_do_IGBE: 3513108,
    UF: "SP",
    nome_da_cidade: "CRAVINHOS",
  },
  {
    codigo_do_IGBE: 3120201,
    UF: "MG",
    nome_da_cidade: "CRISTAIS",
  },
  {
    codigo_do_IGBE: 2203107,
    UF: "PI",
    nome_da_cidade: "CRISTINO CASTRO",
  },
  {
    codigo_do_IGBE: 2504900,
    UF: "PB",
    nome_da_cidade: "CRUZ DO ESPIRITO SANTO",
  },
  {
    codigo_do_IGBE: 3120706,
    UF: "MG",
    nome_da_cidade: "CRUZEIRO DA FORTALEZA",
  },
  {
    codigo_do_IGBE: 3120839,
    UF: "MG",
    nome_da_cidade: "CUPARAQUE",
  },
  {
    codigo_do_IGBE: 2203255,
    UF: "PI",
    nome_da_cidade: "CURRALINHOS",
  },
  {
    codigo_do_IGBE: 4306304,
    UF: "RS",
    nome_da_cidade: "DAVID CANABARRO",
  },
  {
    codigo_do_IGBE: 3121407,
    UF: "MG",
    nome_da_cidade: "DESTERRO DE ENTRE RIOS",
  },
  {
    codigo_do_IGBE: 2203354,
    UF: "PI",
    nome_da_cidade: "DIRCEU ARCOVERDE",
  },
  {
    codigo_do_IGBE: 3514106,
    UF: "SP",
    nome_da_cidade: "DOIS CORREGOS",
  },
  {
    codigo_do_IGBE: 4306502,
    UF: "RS",
    nome_da_cidade: "DOM FELICIANO",
  },
  {
    codigo_do_IGBE: 4306700,
    UF: "RS",
    nome_da_cidade: "DONA FRANCISCA",
  },
  {
    codigo_do_IGBE: 3123502,
    UF: "MG",
    nome_da_cidade: "DOURADOQUARA",
  },
  {
    codigo_do_IGBE: 3301702,
    UF: "RJ",
    nome_da_cidade: "DUQUE DE CAXIAS",
  },
  {
    codigo_do_IGBE: 2910305,
    UF: "BA",
    nome_da_cidade: "ELISIO MEDRADO",
  },
  {
    codigo_do_IGBE: 3123809,
    UF: "MG",
    nome_da_cidade: "ENGENHEIRO NAVARRO",
  },
  {
    codigo_do_IGBE: 2900504,
    UF: "BA",
    nome_da_cidade: "ERICO CARDOSO",
  },
  {
    codigo_do_IGBE: 4107546,
    UF: "PR",
    nome_da_cidade: "ESPIGAO ALTO DO IGUACU",
  },
  {
    codigo_do_IGBE: 3124401,
    UF: "MG",
    nome_da_cidade: "ESPIRITO SANTO DO DOURADO",
  },
  {
    codigo_do_IGBE: 2702553,
    UF: "AL",
    nome_da_cidade: "ESTRELA DE ALAGOAS",
  },
  {
    codigo_do_IGBE: 5207501,
    UF: "GO",
    nome_da_cidade: "ESTRELA DO NORTE",
  },
  {
    codigo_do_IGBE: 4205308,
    UF: "SC",
    nome_da_cidade: "FAXINAL DOS GUEDES",
  },
  {
    codigo_do_IGBE: 2702702,
    UF: "AL",
    nome_da_cidade: "FELIZ DESERTO",
  },
  {
    codigo_do_IGBE: 2104081,
    UF: "MA",
    nome_da_cidade: "FERNANDO FALCAO",
  },
  {
    codigo_do_IGBE: 2910909,
    UF: "BA",
    nome_da_cidade: "FIRMINO ALVES",
  },
  {
    codigo_do_IGBE: 1503044,
    UF: "PA",
    nome_da_cidade: "FLORESTA DO ARAGUAIA",
  },
  {
    codigo_do_IGBE: 4205431,
    UF: "SC",
    nome_da_cidade: "FORMOSA DO SUL",
  },
  {
    codigo_do_IGBE: 4108304,
    UF: "PR",
    nome_da_cidade: "FOZ DO IGUACU",
  },
  {
    codigo_do_IGBE: 4108452,
    UF: "PR",
    nome_da_cidade: "FOZ DO JORDAO",
  },
  {
    codigo_do_IGBE: 4308508,
    UF: "RS",
    nome_da_cidade: "FREDERICO WESTPHALEN",
  },
  {
    codigo_do_IGBE: 3516507,
    UF: "SP",
    nome_da_cidade: "GABRIEL MONTEIRO",
  },
  {
    codigo_do_IGBE: 1503077,
    UF: "PA",
    nome_da_cidade: "GARRAFAO DO NORTE",
  },
  {
    codigo_do_IGBE: 2911303,
    UF: "BA",
    nome_da_cidade: "GENTIO DO OURO",
  },
  {
    codigo_do_IGBE: 5208608,
    UF: "GO",
    nome_da_cidade: "GOIANESIA",
  },
  {
    codigo_do_IGBE: 2404200,
    UF: "RN",
    nome_da_cidade: "GOIANINHA",
  },
  {
    codigo_do_IGBE: 5208806,
    UF: "GO",
    nome_da_cidade: "GOIANIRA",
  },
  {
    codigo_do_IGBE: 3127404,
    UF: "MG",
    nome_da_cidade: "GONCALVES",
  },
  {
    codigo_do_IGBE: 2104602,
    UF: "MA",
    nome_da_cidade: "GOVERNADOR EUGENIO BARROS",
  },
  {
    codigo_do_IGBE: 4309209,
    UF: "RS",
    nome_da_cidade: "GRAVATAI",
  },
  {
    codigo_do_IGBE: 4108908,
    UF: "PR",
    nome_da_cidade: "GUAIRACA",
  },
  {
    codigo_do_IGBE: 5209200,
    UF: "GO",
    nome_da_cidade: "GUAPO",
  },
  {
    codigo_do_IGBE: 2305100,
    UF: "CE",
    nome_da_cidade: "GUARAMIRANGA",
  },
  {
    codigo_do_IGBE: 5209408,
    UF: "GO",
    nome_da_cidade: "GUARANI DE GOIAS",
  },
  {
    codigo_do_IGBE: 3518206,
    UF: "SP",
    nome_da_cidade: "GUARARAPES",
  },
  {
    codigo_do_IGBE: 3518859,
    UF: "SP",
    nome_da_cidade: "GUATAPARA",
  },
  {
    codigo_do_IGBE: 4309555,
    UF: "RS",
    nome_da_cidade: "HARMONIA",
  },
  {
    codigo_do_IGBE: 4109658,
    UF: "PR",
    nome_da_cidade: "HONORIO SERPA",
  },
  {
    codigo_do_IGBE: 3519303,
    UF: "SP",
    nome_da_cidade: "IBATE",
  },
  {
    codigo_do_IGBE: 2912004,
    UF: "BA",
    nome_da_cidade: "IBIASSUCE",
  },
  {
    codigo_do_IGBE: 2912103,
    UF: "BA",
    nome_da_cidade: "IBICARAI",
  },
  {
    codigo_do_IGBE: 2912400,
    UF: "BA",
    nome_da_cidade: "IBIPEBA",
  },
  {
    codigo_do_IGBE: 3519501,
    UF: "SP",
    nome_da_cidade: "IBIRAREMA",
  },
  {
    codigo_do_IGBE: 4109906,
    UF: "PR",
    nome_da_cidade: "ICARAIMA",
  },
  {
    codigo_do_IGBE: 3130101,
    UF: "MG",
    nome_da_cidade: "IGARAPE",
  },
  {
    codigo_do_IGBE: 2606903,
    UF: "PE",
    nome_da_cidade: "IGUARACI",
  },
  {
    codigo_do_IGBE: 3520442,
    UF: "SP",
    nome_da_cidade: "ILHA SOLTEIRA",
  },
  {
    codigo_do_IGBE: 4110300,
    UF: "PR",
    nome_da_cidade: "INAJA",
  },
  {
    codigo_do_IGBE: 3130804,
    UF: "MG",
    nome_da_cidade: "INGAI",
  },
  {
    codigo_do_IGBE: 3520806,
    UF: "SP",
    nome_da_cidade: "INUBIA PAULISTA",
  },
  {
    codigo_do_IGBE: 2404705,
    UF: "RN",
    nome_da_cidade: "IPANGUACU",
  },
  {
    codigo_do_IGBE: 1503457,
    UF: "PA",
    nome_da_cidade: "IPIXUNA DO PARA",
  },
  {
    codigo_do_IGBE: 4110656,
    UF: "PR",
    nome_da_cidade: "IRACEMA DO OESTE",
  },
  {
    codigo_do_IGBE: 2914307,
    UF: "BA",
    nome_da_cidade: "IRAMAIA",
  },
  {
    codigo_do_IGBE: 1301852,
    UF: "AM",
    nome_da_cidade: "IRANDUBA",
  },
  {
    codigo_do_IGBE: 4207809,
    UF: "SC",
    nome_da_cidade: "IRANI",
  },
  {
    codigo_do_IGBE: 3521606,
    UF: "SP",
    nome_da_cidade: "IRAPURU",
  },
  {
    codigo_do_IGBE: 2914406,
    UF: "BA",
    nome_da_cidade: "IRAQUARA",
  },
  {
    codigo_do_IGBE: 2914505,
    UF: "BA",
    nome_da_cidade: "IRARA",
  },
  {
    codigo_do_IGBE: 4110706,
    UF: "PR",
    nome_da_cidade: "IRATI",
  },
  {
    codigo_do_IGBE: 4207858,
    UF: "SC",
    nome_da_cidade: "IRATI",
  },
  {
    codigo_do_IGBE: 2204907,
    UF: "PI",
    nome_da_cidade: "ISAIAS COELHO",
  },
  {
    codigo_do_IGBE: 2506905,
    UF: "PB",
    nome_da_cidade: "ITABAIANA",
  },
  {
    codigo_do_IGBE: 3132008,
    UF: "MG",
    nome_da_cidade: "ITACAMBIRA",
  },
  {
    codigo_do_IGBE: 2915353,
    UF: "BA",
    nome_da_cidade: "ITAGUACU DA BAHIA",
  },
  {
    codigo_do_IGBE: 4208203,
    UF: "SC",
    nome_da_cidade: "ITAJAI",
  },
  {
    codigo_do_IGBE: 2915809,
    UF: "BA",
    nome_da_cidade: "ITAMBE",
  },
  {
    codigo_do_IGBE: 3302106,
    UF: "RJ",
    nome_da_cidade: "ITAOCARA",
  },
  {
    codigo_do_IGBE: 3302205,
    UF: "RJ",
    nome_da_cidade: "ITAPERUNA",
  },
  {
    codigo_do_IGBE: 5004502,
    UF: "MS",
    nome_da_cidade: "ITAPORA",
  },
  {
    codigo_do_IGBE: 1711100,
    UF: "TO",
    nome_da_cidade: "ITAPORA DO TOCANTINS",
  },
  {
    codigo_do_IGBE: 5004601,
    UF: "MS",
    nome_da_cidade: "ITAQUIRAI",
  },
  {
    codigo_do_IGBE: 4310702,
    UF: "RS",
    nome_da_cidade: "ITATIBA DO SUL",
  },
  {
    codigo_do_IGBE: 2916906,
    UF: "BA",
    nome_da_cidade: "ITIRUCU",
  },
  {
    codigo_do_IGBE: 3523909,
    UF: "SP",
    nome_da_cidade: "ITU",
  },
  {
    codigo_do_IGBE: 2917201,
    UF: "BA",
    nome_da_cidade: "ITUACU",
  },
  {
    codigo_do_IGBE: 2917300,
    UF: "BA",
    nome_da_cidade: "ITUBERA",
  },
  {
    codigo_do_IGBE: 3134103,
    UF: "MG",
    nome_da_cidade: "ITUETA",
  },
  {
    codigo_do_IGBE: 3134202,
    UF: "MG",
    nome_da_cidade: "ITUIUTABA",
  },
  {
    codigo_do_IGBE: 5211503,
    UF: "GO",
    nome_da_cidade: "ITUMBIARA",
  },
  {
    codigo_do_IGBE: 3134301,
    UF: "MG",
    nome_da_cidade: "ITUMIRIM",
  },
  {
    codigo_do_IGBE: 3524006,
    UF: "SP",
    nome_da_cidade: "ITUPEVA",
  },
  {
    codigo_do_IGBE: 4111407,
    UF: "PR",
    nome_da_cidade: "IVAI",
  },
  {
    codigo_do_IGBE: 2607901,
    UF: "PE",
    nome_da_cidade: "JABOATAO DOS GUARARAPES",
  },
  {
    codigo_do_IGBE: 4208708,
    UF: "SC",
    nome_da_cidade: "JACINTO MACHADO",
  },
  {
    codigo_do_IGBE: 4112009,
    UF: "PR",
    nome_da_cidade: "JAGUARIAIVA",
  },
  {
    codigo_do_IGBE: 2917904,
    UF: "BA",
    nome_da_cidade: "JANDAIRA",
  },
  {
    codigo_do_IGBE: 4112306,
    UF: "PR",
    nome_da_cidade: "JAPIRA",
  },
  {
    codigo_do_IGBE: 4208906,
    UF: "SC",
    nome_da_cidade: "JARAGUA DO SUL",
  },
  {
    codigo_do_IGBE: 2405603,
    UF: "RN",
    nome_da_cidade: "JARDIM DE PIRANHAS",
  },
  {
    codigo_do_IGBE: 2205250,
    UF: "PI",
    nome_da_cidade: "JARDIM DO MULATO",
  },
  {
    codigo_do_IGBE: 2405702,
    UF: "RN",
    nome_da_cidade: "JARDIM DO SERIDO",
  },
  {
    codigo_do_IGBE: 4112603,
    UF: "PR",
    nome_da_cidade: "JARDIM OLINDA",
  },
  {
    codigo_do_IGBE: 4208955,
    UF: "SC",
    nome_da_cidade: "JARDINOPOLIS",
  },
  {
    codigo_do_IGBE: 3525102,
    UF: "SP",
    nome_da_cidade: "JARDINOPOLIS",
  },
  {
    codigo_do_IGBE: 4311130,
    UF: "RS",
    nome_da_cidade: "JARI",
  },
  {
    codigo_do_IGBE: 3525201,
    UF: "SP",
    nome_da_cidade: "JARINU",
  },
  {
    codigo_do_IGBE: 1100114,
    UF: "RO",
    nome_da_cidade: "JARU",
  },
  {
    codigo_do_IGBE: 5211909,
    UF: "GO",
    nome_da_cidade: "JATAI",
  },
  {
    codigo_do_IGBE: 4112702,
    UF: "PR",
    nome_da_cidade: "JATAIZINHO",
  },
  {
    codigo_do_IGBE: 2608008,
    UF: "PE",
    nome_da_cidade: "JATAUBA",
  },
  {
    codigo_do_IGBE: 5005103,
    UF: "MS",
    nome_da_cidade: "JATEI",
  },
  {
    codigo_do_IGBE: 2307205,
    UF: "CE",
    nome_da_cidade: "JATI",
  },
  {
    codigo_do_IGBE: 2105450,
    UF: "MA",
    nome_da_cidade: "JATOBA",
  },
  {
    codigo_do_IGBE: 2608057,
    UF: "PE",
    nome_da_cidade: "JATOBA",
  },
  {
    codigo_do_IGBE: 2205276,
    UF: "PI",
    nome_da_cidade: "JATOBA DO PIAUI",
  },
  {
    codigo_do_IGBE: 3525300,
    UF: "SP",
    nome_da_cidade: "JAU",
  },
  {
    codigo_do_IGBE: 3136652,
    UF: "MG",
    nome_da_cidade: "JUATUBA",
  },
  {
    codigo_do_IGBE: 2307304,
    UF: "CE",
    nome_da_cidade: "JUAZEIRO DO NORTE",
  },
  {
    codigo_do_IGBE: 4112900,
    UF: "PR",
    nome_da_cidade: "JUNDIAI DO SUL",
  },
  {
    codigo_do_IGBE: 1503903,
    UF: "PA",
    nome_da_cidade: "JURUTI",
  },
  {
    codigo_do_IGBE: 5005202,
    UF: "MS",
    nome_da_cidade: "LADARIO",
  },
  {
    codigo_do_IGBE: 2704104,
    UF: "AL",
    nome_da_cidade: "LAGOA DA CANOA",
  },
  {
    codigo_do_IGBE: 4311270,
    UF: "RS",
    nome_da_cidade: "LAGOA DOS TRES CANTOS",
  },
  {
    codigo_do_IGBE: 4311429,
    UF: "RS",
    nome_da_cidade: "LAJEADO DO BUGRE",
  },
  {
    codigo_do_IGBE: 2919157,
    UF: "BA",
    nome_da_cidade: "LAPAO",
  },
  {
    codigo_do_IGBE: 3526506,
    UF: "SP",
    nome_da_cidade: "LAVINIA",
  },
  {
    codigo_do_IGBE: 3138401,
    UF: "MG",
    nome_da_cidade: "LEOPOLDINA",
  },
  {
    codigo_do_IGBE: 2307601,
    UF: "CE",
    nome_da_cidade: "LIMOEIRO DO NORTE",
  },
  {
    codigo_do_IGBE: 4311627,
    UF: "RS",
    nome_da_cidade: "LINDOLFO COLLOR",
  },
  {
    codigo_do_IGBE: 2919504,
    UF: "BA",
    nome_da_cidade: "LIVRAMENTO DE NOSSA SENHORA",
  },
  {
    codigo_do_IGBE: 2106201,
    UF: "MA",
    nome_da_cidade: "LUIS DOMINGUES",
  },
  {
    codigo_do_IGBE: 3302403,
    UF: "RJ",
    nome_da_cidade: "MACAE",
  },
  {
    codigo_do_IGBE: 4311700,
    UF: "RS",
    nome_da_cidade: "MACHADINHO",
  },
  {
    codigo_do_IGBE: 3139102,
    UF: "MG",
    nome_da_cidade: "MADRE DE DEUS DE MINAS",
  },
  {
    codigo_do_IGBE: 4210209,
    UF: "SC",
    nome_da_cidade: "MAJOR GERCINO",
  },
  {
    codigo_do_IGBE: 2920304,
    UF: "BA",
    nome_da_cidade: "MALHADA DE PEDRAS",
  },
  {
    codigo_do_IGBE: 4114302,
    UF: "PR",
    nome_da_cidade: "MANDIRITUBA",
  },
  {
    codigo_do_IGBE: 2920403,
    UF: "BA",
    nome_da_cidade: "MANOEL VITORINO",
  },
  {
    codigo_do_IGBE: 2920502,
    UF: "BA",
    nome_da_cidade: "MARACAS",
  },
  {
    codigo_do_IGBE: 4210506,
    UF: "SC",
    nome_da_cidade: "MARAVILHA",
  },
  {
    codigo_do_IGBE: 4114609,
    UF: "PR",
    nome_da_cidade: "MARECHAL CANDIDO RONDON",
  },
  {
    codigo_do_IGBE: 3203346,
    UF: "ES",
    nome_da_cidade: "MARECHAL FLORIANO",
  },
  {
    codigo_do_IGBE: 4210555,
    UF: "SC",
    nome_da_cidade: "MAREMA",
  },
  {
    codigo_do_IGBE: 2509107,
    UF: "PB",
    nome_da_cidade: "MARI",
  },
  {
    codigo_do_IGBE: 3139904,
    UF: "MG",
    nome_da_cidade: "MARIA DA FE",
  },
  {
    codigo_do_IGBE: 1712504,
    UF: "TO",
    nome_da_cidade: "MARIANOPOLIS DO TOCANTINS",
  },
  {
    codigo_do_IGBE: 3140506,
    UF: "MG",
    nome_da_cidade: "MARTINHO CAMPOS",
  },
  {
    codigo_do_IGBE: 2921005,
    UF: "BA",
    nome_da_cidade: "MATA DE SAO JOAO",
  },
  {
    codigo_do_IGBE: 3140902,
    UF: "MG",
    nome_da_cidade: "MATIPO",
  },
  {
    codigo_do_IGBE: 2705101,
    UF: "AL",
    nome_da_cidade: "MATRIZ DE CAMARAGIBE",
  },
  {
    codigo_do_IGBE: 4210803,
    UF: "SC",
    nome_da_cidade: "MELEIRO",
  },
  {
    codigo_do_IGBE: 2407609,
    UF: "RN",
    nome_da_cidade: "MESSIAS TARGINO",
  },
  {
    codigo_do_IGBE: 4312252,
    UF: "RS",
    nome_da_cidade: "MINAS DO LEAO",
  },
  {
    codigo_do_IGBE: 3529807,
    UF: "SP",
    nome_da_cidade: "MINEIROS DO TIETE",
  },
  {
    codigo_do_IGBE: 2609303,
    UF: "PE",
    nome_da_cidade: "MIRANDIBA",
  },
  {
    codigo_do_IGBE: 2106805,
    UF: "MA",
    nome_da_cidade: "MIRINZAL",
  },
  {
    codigo_do_IGBE: 5213400,
    UF: "GO",
    nome_da_cidade: "MOIPORA",
  },
  {
    codigo_do_IGBE: 2308500,
    UF: "CE",
    nome_da_cidade: "MOMBACA",
  },
  {
    codigo_do_IGBE: 4312351,
    UF: "RS",
    nome_da_cidade: "MONTAURI",
  },
  {
    codigo_do_IGBE: 4312385,
    UF: "RS",
    nome_da_cidade: "MONTE BELO DO SUL",
  },
  {
    codigo_do_IGBE: 3531704,
    UF: "SP",
    nome_da_cidade: "MONTEIRO LOBATO",
  },
  {
    codigo_do_IGBE: 2308906,
    UF: "CE",
    nome_da_cidade: "MORRINHOS",
  },
  {
    codigo_do_IGBE: 4312450,
    UF: "RS",
    nome_da_cidade: "MORRO REDONDO",
  },
  {
    codigo_do_IGBE: 4312617,
    UF: "RS",
    nome_da_cidade: "MUITOS CAPOES",
  },
  {
    codigo_do_IGBE: 2206696,
    UF: "PI",
    nome_da_cidade: "MURICI DOS PORTELAS",
  },
  {
    codigo_do_IGBE: 3144409,
    UF: "MG",
    nome_da_cidade: "NATERCIA",
  },
  {
    codigo_do_IGBE: 5214408,
    UF: "GO",
    nome_da_cidade: "NAZARIO",
  },
  {
    codigo_do_IGBE: 3532504,
    UF: "SP",
    nome_da_cidade: "NEVES PAULISTA",
  },
  {
    codigo_do_IGBE: 5214606,
    UF: "GO",
    nome_da_cidade: "NIQUELANDIA",
  },
  {
    codigo_do_IGBE: 2922656,
    UF: "BA",
    nome_da_cidade: "NORDESTINA",
  },
  {
    codigo_do_IGBE: 2206753,
    UF: "PI",
    nome_da_cidade: "NOSSA SENHORA DE NAZARE",
  },
  {
    codigo_do_IGBE: 5106216,
    UF: "MT",
    nome_da_cidade: "NOVA CANAA DO NORTE",
  },
  {
    codigo_do_IGBE: 4313037,
    UF: "RS",
    nome_da_cidade: "NOVA ESPERANCA DO SUL",
  },
  {
    codigo_do_IGBE: 4117107,
    UF: "PR",
    nome_da_cidade: "NOVA LONDRINA",
  },
  {
    codigo_do_IGBE: 1303106,
    UF: "AM",
    nome_da_cidade: "NOVA OLINDA DO NORTE",
  },
  {
    codigo_do_IGBE: 3145059,
    UF: "MG",
    nome_da_cidade: "NOVA PORTEIRINHA",
  },
  {
    codigo_do_IGBE: 5106257,
    UF: "MT",
    nome_da_cidade: "NOVA XAVANTINA",
  },
  {
    codigo_do_IGBE: 3533502,
    UF: "SP",
    nome_da_cidade: "NOVO HORIZONTE",
  },
  {
    codigo_do_IGBE: 2206951,
    UF: "PI",
    nome_da_cidade: "NOVO SANTO ANTONIO",
  },
  {
    codigo_do_IGBE: 1600501,
    UF: "AP",
    nome_da_cidade: "OIAPOQUE",
  },
  {
    codigo_do_IGBE: 2408409,
    UF: "RN",
    nome_da_cidade: "OLHO-D'AGUA DO BORGES",
  },
  {
    codigo_do_IGBE: 3145505,
    UF: "MG",
    nome_da_cidade: "OLIMPIO NORONHA",
  },
  {
    codigo_do_IGBE: 5215306,
    UF: "GO",
    nome_da_cidade: "ORIZONA",
  },
  {
    codigo_do_IGBE: 2609907,
    UF: "PE",
    nome_da_cidade: "OURICURI",
  },
  {
    codigo_do_IGBE: 5215405,
    UF: "GO",
    nome_da_cidade: "OURO VERDE DE GOIAS",
  },
  {
    codigo_do_IGBE: 5215603,
    UF: "GO",
    nome_da_cidade: "PADRE BERNARDO",
  },
  {
    codigo_do_IGBE: 5215652,
    UF: "GO",
    nome_da_cidade: "PALESTINA DE GOIAS",
  },
  {
    codigo_do_IGBE: 4313706,
    UF: "RS",
    nome_da_cidade: "PALMEIRA DAS MISSOES",
  },
  {
    codigo_do_IGBE: 3146750,
    UF: "MG",
    nome_da_cidade: "PALMOPOLIS",
  },
  {
    codigo_do_IGBE: 3147105,
    UF: "MG",
    nome_da_cidade: "PARA DE MINAS",
  },
  {
    codigo_do_IGBE: 1716109,
    UF: "TO",
    nome_da_cidade: "PARAISO DO TOCANTINS",
  },
  {
    codigo_do_IGBE: 3535903,
    UF: "SP",
    nome_da_cidade: "PARANAPUA",
  },
  {
    codigo_do_IGBE: 2408805,
    UF: "RN",
    nome_da_cidade: "PARAZINHO",
  },
  {
    codigo_do_IGBE: 2403251,
    UF: "RN",
    nome_da_cidade: "PARNAMIRIM",
  },
  {
    codigo_do_IGBE: 2207751,
    UF: "PI",
    nome_da_cidade: "PASSAGEM FRANCA DO PIAUI",
  },
  {
    codigo_do_IGBE: 2706505,
    UF: "AL",
    nome_da_cidade: "PASSO DE CAMARAGIBE",
  },
  {
    codigo_do_IGBE: 3148202,
    UF: "MG",
    nome_da_cidade: "PATROCINIO DO MURIAE",
  },
  {
    codigo_do_IGBE: 3536570,
    UF: "SP",
    nome_da_cidade: "PAULISTANIA",
  },
  {
    codigo_do_IGBE: 3148608,
    UF: "MG",
    nome_da_cidade: "PECANHA",
  },
  {
    codigo_do_IGBE: 2511103,
    UF: "PB",
    nome_da_cidade: "PEDRA LAVRADA",
  },
  {
    codigo_do_IGBE: 1716505,
    UF: "TO",
    nome_da_cidade: "PEDRO AFONSO",
  },
  {
    codigo_do_IGBE: 1716604,
    UF: "TO",
    nome_da_cidade: "PEIXE",
  },
  {
    codigo_do_IGBE: 1716653,
    UF: "TO",
    nome_da_cidade: "PEQUIZEIRO",
  },
  {
    codigo_do_IGBE: 4119004,
    UF: "PR",
    nome_da_cidade: "PEROLA D'OESTE",
  },
  {
    codigo_do_IGBE: 2611002,
    UF: "PE",
    nome_da_cidade: "PETROLANDIA",
  },
  {
    codigo_do_IGBE: 5216809,
    UF: "GO",
    nome_da_cidade: "PETROLINA DE GOIAS",
  },
  {
    codigo_do_IGBE: 3150208,
    UF: "MG",
    nome_da_cidade: "PIEDADE DE PONTE NOVA",
  },
  {
    codigo_do_IGBE: 3150307,
    UF: "MG",
    nome_da_cidade: "PIEDADE DO RIO GRANDE",
  },
  {
    codigo_do_IGBE: 3150406,
    UF: "MG",
    nome_da_cidade: "PIEDADE DOS GERAIS",
  },
  {
    codigo_do_IGBE: 4119103,
    UF: "PR",
    nome_da_cidade: "PIEN",
  },
  {
    codigo_do_IGBE: 2924405,
    UF: "BA",
    nome_da_cidade: "PILAO ARCADO",
  },
  {
    codigo_do_IGBE: 2706901,
    UF: "AL",
    nome_da_cidade: "PILAR",
  },
  {
    codigo_do_IGBE: 2511509,
    UF: "PB",
    nome_da_cidade: "PILAR",
  },
  {
    codigo_do_IGBE: 3537909,
    UF: "SP",
    nome_da_cidade: "PILAR DO SUL",
  },
  {
    codigo_do_IGBE: 2511608,
    UF: "PB",
    nome_da_cidade: "PILOES",
  },
  {
    codigo_do_IGBE: 2410009,
    UF: "RN",
    nome_da_cidade: "PILOES",
  },
  {
    codigo_do_IGBE: 2511707,
    UF: "PB",
    nome_da_cidade: "PILOEZINHOS",
  },
  {
    codigo_do_IGBE: 3150505,
    UF: "MG",
    nome_da_cidade: "PIMENTA",
  },
  {
    codigo_do_IGBE: 1100189,
    UF: "RO",
    nome_da_cidade: "PIMENTA BUENO",
  },
  {
    codigo_do_IGBE: 2208106,
    UF: "PI",
    nome_da_cidade: "PIMENTEIRAS",
  },
  {
    codigo_do_IGBE: 1101468,
    UF: "RO",
    nome_da_cidade: "PIMENTEIRAS DO OESTE",
  },
  {
    codigo_do_IGBE: 2924504,
    UF: "BA",
    nome_da_cidade: "PINDAI",
  },
  {
    codigo_do_IGBE: 3538006,
    UF: "SP",
    nome_da_cidade: "PINDAMONHANGABA",
  },
  {
    codigo_do_IGBE: 2108504,
    UF: "MA",
    nome_da_cidade: "PINDARE-MIRIM",
  },
  {
    codigo_do_IGBE: 2108900,
    UF: "MA",
    nome_da_cidade: "POCAO DE PEDRAS",
  },
  {
    codigo_do_IGBE: 3204252,
    UF: "ES",
    nome_da_cidade: "PONTO BELO",
  },
  {
    codigo_do_IGBE: 5218052,
    UF: "GO",
    nome_da_cidade: "PORTEIRAO",
  },
  {
    codigo_do_IGBE: 1718006,
    UF: "TO",
    nome_da_cidade: "PORTO ALEGRE DO TOCANTINS",
  },
  {
    codigo_do_IGBE: 1718204,
    UF: "TO",
    nome_da_cidade: "PORTO NACIONAL",
  },
  {
    codigo_do_IGBE: 2311231,
    UF: "CE",
    nome_da_cidade: "POTIRETAMA",
  },
  {
    codigo_do_IGBE: 1718303,
    UF: "TO",
    nome_da_cidade: "PRAIA NORTE",
  },
  {
    codigo_do_IGBE: 3153004,
    UF: "MG",
    nome_da_cidade: "PRATINHA",
  },
  {
    codigo_do_IGBE: 3153301,
    UF: "MG",
    nome_da_cidade: "PRESIDENTE KUBITSCHEK",
  },
  {
    codigo_do_IGBE: 2925758,
    UF: "BA",
    nome_da_cidade: "PRESIDENTE TANCREDO NEVES",
  },
  {
    codigo_do_IGBE: 1101476,
    UF: "RO",
    nome_da_cidade: "PRIMAVERA DE RONDONIA",
  },
  {
    codigo_do_IGBE: 4120804,
    UF: "PR",
    nome_da_cidade: "QUATRO BARRAS",
  },
  {
    codigo_do_IGBE: 4315354,
    UF: "RS",
    nome_da_cidade: "QUINZE DE NOVEMBRO",
  },
  {
    codigo_do_IGBE: 5218508,
    UF: "GO",
    nome_da_cidade: "QUIRINOPOLIS",
  },
  {
    codigo_do_IGBE: 2311355,
    UF: "CE",
    nome_da_cidade: "QUIXELO",
  },
  {
    codigo_do_IGBE: 4121356,
    UF: "PR",
    nome_da_cidade: "RANCHO ALEGRE D'OESTE",
  },
  {
    codigo_do_IGBE: 2512705,
    UF: "PB",
    nome_da_cidade: "REMIGIO",
  },
  {
    codigo_do_IGBE: 4315503,
    UF: "RS",
    nome_da_cidade: "RESTINGA SECA",
  },
  {
    codigo_do_IGBE: 2512788,
    UF: "PB",
    nome_da_cidade: "RIACHO DE SANTO ANTONIO",
  },
  {
    codigo_do_IGBE: 2926657,
    UF: "BA",
    nome_da_cidade: "RIBEIRAO DO LARGO",
  },
  {
    codigo_do_IGBE: 3154804,
    UF: "MG",
    nome_da_cidade: "RIO ACIMA",
  },
  {
    codigo_do_IGBE: 4214409,
    UF: "SC",
    nome_da_cidade: "RIO DAS ANTAS",
  },
  {
    codigo_do_IGBE: 4315552,
    UF: "RS",
    nome_da_cidade: "RIO DOS INDIOS",
  },
  {
    codigo_do_IGBE: 3155603,
    UF: "MG",
    nome_da_cidade: "RIO PARDO DE MINAS",
  },
  {
    codigo_do_IGBE: 1718758,
    UF: "TO",
    nome_da_cidade: "RIO SONO",
  },
  {
    codigo_do_IGBE: 5007406,
    UF: "MS",
    nome_da_cidade: "RIO VERDE DE MATO GROSSO",
  },
  {
    codigo_do_IGBE: 4316006,
    UF: "RS",
    nome_da_cidade: "ROLANTE",
  },
  {
    codigo_do_IGBE: 2109601,
    UF: "MA",
    nome_da_cidade: "ROSARIO",
  },
  {
    codigo_do_IGBE: 2311801,
    UF: "CE",
    nome_da_cidade: "RUSSAS",
  },
  {
    codigo_do_IGBE: 3544905,
    UF: "SP",
    nome_da_cidade: "SALES OLIVEIRA",
  },
  {
    codigo_do_IGBE: 3157104,
    UF: "MG",
    nome_da_cidade: "SALTO DA DIVISA",
  },
  {
    codigo_do_IGBE: 4316451,
    UF: "RS",
    nome_da_cidade: "SALTO DO JACUI",
  },
  {
    codigo_do_IGBE: 5219001,
    UF: "GO",
    nome_da_cidade: "SANCLERLANDIA",
  },
  {
    codigo_do_IGBE: 3545704,
    UF: "SP",
    nome_da_cidade: "SANTA ALBERTINA",
  },
  {
    codigo_do_IGBE: 2612471,
    UF: "PE",
    nome_da_cidade: "SANTA CRUZ DA BAIXA VERDE",
  },
  {
    codigo_do_IGBE: 3546306,
    UF: "SP",
    nome_da_cidade: "SANTA CRUZ DAS PALMEIRAS",
  },
  {
    codigo_do_IGBE: 5219308,
    UF: "GO",
    nome_da_cidade: "SANTA HELENA DE GOIAS",
  },
  {
    codigo_do_IGBE: 2806305,
    UF: "SE",
    nome_da_cidade: "SANTA LUZIA DO ITANHY",
  },
  {
    codigo_do_IGBE: 3158102,
    UF: "MG",
    nome_da_cidade: "SANTA MARIA DO SALTO",
  },
  {
    codigo_do_IGBE: 3159308,
    UF: "MG",
    nome_da_cidade: "SANTA RITA DE JACUTINGA",
  },
  {
    codigo_do_IGBE: 1718907,
    UF: "TO",
    nome_da_cidade: "SANTA ROSA DO TOCANTINS",
  },
  {
    codigo_do_IGBE: 5219605,
    UF: "GO",
    nome_da_cidade: "SANTA TEREZA DE GOIAS",
  },
  {
    codigo_do_IGBE: 4317301,
    UF: "RS",
    nome_da_cidade: "SANTA VITORIA DO PALMAR",
  },
  {
    codigo_do_IGBE: 4317103,
    UF: "RS",
    nome_da_cidade: "SANTANA DO LIVRAMENTO",
  },
  {
    codigo_do_IGBE: 2513653,
    UF: "PB",
    nome_da_cidade: "SANTAREM",
  },
  {
    codigo_do_IGBE: 4124103,
    UF: "PR",
    nome_da_cidade: "SANTO ANTONIO DA PLATINA",
  },
  {
    codigo_do_IGBE: 2928703,
    UF: "BA",
    nome_da_cidade: "SANTO ANTONIO DE JESUS",
  },
  {
    codigo_do_IGBE: 3160405,
    UF: "MG",
    nome_da_cidade: "SANTO ANTONIO DO MONTE",
  },
  {
    codigo_do_IGBE: 2209500,
    UF: "PI",
    nome_da_cidade: "SANTO INACIO DO PIAUI",
  },
  {
    codigo_do_IGBE: 2209559,
    UF: "PI",
    nome_da_cidade: "SAO BRAZ DO PIAUI",
  },
  {
    codigo_do_IGBE: 2513968,
    UF: "PB",
    nome_da_cidade: "SAO DOMINGOS DE POMBAL",
  },
  {
    codigo_do_IGBE: 1720150,
    UF: "TO",
    nome_da_cidade: "SAO FELIX DO TOCANTINS",
  },
  {
    codigo_do_IGBE: 4318101,
    UF: "RS",
    nome_da_cidade: "SAO FRANCISCO DE ASSIS",
  },
  {
    codigo_do_IGBE: 2412005,
    UF: "RN",
    nome_da_cidade: "SAO GONCALO DO AMARANTE",
  },
  {
    codigo_do_IGBE: 1400506,
    UF: "RR",
    nome_da_cidade: "SAO JOAO DA BALIZA",
  },
  {
    codigo_do_IGBE: 2209955,
    UF: "PI",
    nome_da_cidade: "SAO JOAO DA VARJOTA",
  },
  {
    codigo_do_IGBE: 3162575,
    UF: "MG",
    nome_da_cidade: "SAO JOAO DO MANTENINHA",
  },
  {
    codigo_do_IGBE: 3162922,
    UF: "MG",
    nome_da_cidade: "SAO JOAQUIM DE BICAS",
  },
  {
    codigo_do_IGBE: 2708402,
    UF: "AL",
    nome_da_cidade: "SAO JOSE DA TAPERA",
  },
  {
    codigo_do_IGBE: 2613503,
    UF: "PE",
    nome_da_cidade: "SAO JOSE DO BELMONTE",
  },
  {
    codigo_do_IGBE: 2514651,
    UF: "PB",
    nome_da_cidade: "SAO JOSE DO BREJO DO CRUZ",
  },
  {
    codigo_do_IGBE: 2210201,
    UF: "PI",
    nome_da_cidade: "SAO JOSE DO PIAUI",
  },
  {
    codigo_do_IGBE: 5107107,
    UF: "MT",
    nome_da_cidade: "SAO JOSE DOS QUATRO MARCOS",
  },
  {
    codigo_do_IGBE: 4125555,
    UF: "PR",
    nome_da_cidade: "SAO MANOEL DO PARANA",
  },
  {
    codigo_do_IGBE: 4125605,
    UF: "PR",
    nome_da_cidade: "SAO MATEUS DO SUL",
  },
  {
    codigo_do_IGBE: 1100320,
    UF: "RO",
    nome_da_cidade: "SAO MIGUEL DO GUAPORE",
  },
  {
    codigo_do_IGBE: 4217253,
    UF: "SC",
    nome_da_cidade: "SAO PEDRO DE ALCANTARA",
  },
  {
    codigo_do_IGBE: 3164308,
    UF: "MG",
    nome_da_cidade: "SAO ROQUE DE MINAS",
  },
  {
    codigo_do_IGBE: 3164506,
    UF: "MG",
    nome_da_cidade: "SAO SEBASTIAO DO MARANHAO",
  },
  {
    codigo_do_IGBE: 1720499,
    UF: "TO",
    nome_da_cidade: "SAO VALERIO DA NATIVIDADE",
  },
  {
    codigo_do_IGBE: 3165503,
    UF: "MG",
    nome_da_cidade: "SARDOA",
  },
  {
    codigo_do_IGBE: 3551306,
    UF: "SP",
    nome_da_cidade: "SEBASTIANOPOLIS DO SUL",
  },
  {
    codigo_do_IGBE: 2413102,
    UF: "RN",
    nome_da_cidade: "SENADOR ELOI DE SOUZA",
  },
  {
    codigo_do_IGBE: 3165909,
    UF: "MG",
    nome_da_cidade: "SENADOR MODESTINO GONCALVES",
  },
  {
    codigo_do_IGBE: 4320321,
    UF: "RS",
    nome_da_cidade: "SENADOR SALGADO FILHO",
  },
  {
    codigo_do_IGBE: 3166006,
    UF: "MG",
    nome_da_cidade: "SENHORA DE OLIVEIRA",
  },
  {
    codigo_do_IGBE: 2515609,
    UF: "PB",
    nome_da_cidade: "SERRA DA RAIZ",
  },
  {
    codigo_do_IGBE: 2613909,
    UF: "PE",
    nome_da_cidade: "SERRA TALHADA",
  },
  {
    codigo_do_IGBE: 2111789,
    UF: "MA",
    nome_da_cidade: "SERRANO DO MARANHAO",
  },
  {
    codigo_do_IGBE: 5220504,
    UF: "GO",
    nome_da_cidade: "SERRANOPOLIS",
  },
  {
    codigo_do_IGBE: 4126355,
    UF: "PR",
    nome_da_cidade: "SERRANOPOLIS DO IGUACU",
  },
  {
    codigo_do_IGBE: 3167004,
    UF: "MG",
    nome_da_cidade: "SERRANOS",
  },
  {
    codigo_do_IGBE: 2515906,
    UF: "PB",
    nome_da_cidade: "SERRARIA",
  },
  {
    codigo_do_IGBE: 2930501,
    UF: "BA",
    nome_da_cidade: "SERRINHA",
  },
  {
    codigo_do_IGBE: 2413508,
    UF: "RN",
    nome_da_cidade: "SERRINHA",
  },
  {
    codigo_do_IGBE: 2413557,
    UF: "RN",
    nome_da_cidade: "SERRINHA DOS PINTOS",
  },
  {
    codigo_do_IGBE: 2614006,
    UF: "PE",
    nome_da_cidade: "SERRITA",
  },
  {
    codigo_do_IGBE: 3167103,
    UF: "MG",
    nome_da_cidade: "SERRO",
  },
  {
    codigo_do_IGBE: 2930600,
    UF: "BA",
    nome_da_cidade: "SERROLANDIA",
  },
  {
    codigo_do_IGBE: 4126405,
    UF: "PR",
    nome_da_cidade: "SERTANEJA",
  },
  {
    codigo_do_IGBE: 2614105,
    UF: "PE",
    nome_da_cidade: "SERTANIA",
  },
  {
    codigo_do_IGBE: 4126504,
    UF: "PR",
    nome_da_cidade: "SERTANOPOLIS",
  },
  {
    codigo_do_IGBE: 4320503,
    UF: "RS",
    nome_da_cidade: "SERTAO",
  },
  {
    codigo_do_IGBE: 4320552,
    UF: "RS",
    nome_da_cidade: "SERTAO SANTANA",
  },
  {
    codigo_do_IGBE: 5107958,
    UF: "MT",
    nome_da_cidade: "TANGARA DA SERRA",
  },
  {
    codigo_do_IGBE: 2516508,
    UF: "PB",
    nome_da_cidade: "TAPEROA",
  },
  {
    codigo_do_IGBE: 3553708,
    UF: "SP",
    nome_da_cidade: "TAQUARITINGA",
  },
  {
    codigo_do_IGBE: 2112001,
    UF: "MA",
    nome_da_cidade: "TASSO FRAGOSO",
  },
  {
    codigo_do_IGBE: 2414100,
    UF: "RN",
    nome_da_cidade: "TENENTE ANANIAS",
  },
  {
    codigo_do_IGBE: 3554300,
    UF: "SP",
    nome_da_cidade: "TEODORO SAMPAIO",
  },
  {
    codigo_do_IGBE: 4321436,
    UF: "RS",
    nome_da_cidade: "TERRA DE AREIA",
  },
  {
    codigo_do_IGBE: 4217956,
    UF: "SC",
    nome_da_cidade: "TIGRINHOS",
  },
  {
    codigo_do_IGBE: 4321477,
    UF: "RS",
    nome_da_cidade: "TIRADENTES DO SUL",
  },
  {
    codigo_do_IGBE: 4321493,
    UF: "RS",
    nome_da_cidade: "TOROPI",
  },
  {
    codigo_do_IGBE: 2931806,
    UF: "BA",
    nome_da_cidade: "TREMEDAL",
  },
  {
    codigo_do_IGBE: 4321857,
    UF: "RS",
    nome_da_cidade: "TRES PALMEIRAS",
  },
  {
    codigo_do_IGBE: 4218400,
    UF: "SC",
    nome_da_cidade: "TREZE DE MAIO",
  },
  {
    codigo_do_IGBE: 2112233,
    UF: "MA",
    nome_da_cidade: "TRIZIDELA DO VALE",
  },
  {
    codigo_do_IGBE: 5221452,
    UF: "GO",
    nome_da_cidade: "TROMBAS",
  },
  {
    codigo_do_IGBE: 4218608,
    UF: "SC",
    nome_da_cidade: "TROMBUDO CENTRAL",
  },
  {
    codigo_do_IGBE: 4322186,
    UF: "RS",
    nome_da_cidade: "TUPANCI DO SUL",
  },
  {
    codigo_do_IGBE: 5221551,
    UF: "GO",
    nome_da_cidade: "TURVELANDIA",
  },
  {
    codigo_do_IGBE: 2932309,
    UF: "BA",
    nome_da_cidade: "UBATA",
  },
  {
    codigo_do_IGBE: 2414506,
    UF: "RN",
    nome_da_cidade: "UMARIZAL",
  },
  {
    codigo_do_IGBE: 4218855,
    UF: "SC",
    nome_da_cidade: "UNIAO DO OESTE",
  },
  {
    codigo_do_IGBE: 1304302,
    UF: "AM",
    nome_da_cidade: "URUCARA",
  },
  {
    codigo_do_IGBE: 5108352,
    UF: "MT",
    nome_da_cidade: "VALE DE SAO DOMINGOS",
  },
  {
    codigo_do_IGBE: 4219150,
    UF: "SC",
    nome_da_cidade: "VARGEM",
  },
  {
    codigo_do_IGBE: 3170651,
    UF: "MG",
    nome_da_cidade: "VARGEM GRANDE DO RIO PARDO",
  },
  {
    codigo_do_IGBE: 3170750,
    UF: "MG",
    nome_da_cidade: "VARJAO DE MINAS",
  },
  {
    codigo_do_IGBE: 3205069,
    UF: "ES",
    nome_da_cidade: "VENDA NOVA DO IMIGRANTE",
  },
  {
    codigo_do_IGBE: 4128559,
    UF: "PR",
    nome_da_cidade: "VERA CRUZ DO OESTE",
  },
  {
    codigo_do_IGBE: 2211506,
    UF: "PI",
    nome_da_cidade: "VERA MENDES",
  },
  {
    codigo_do_IGBE: 4322806,
    UF: "RS",
    nome_da_cidade: "VERANOPOLIS",
  },
  {
    codigo_do_IGBE: 3171154,
    UF: "MG",
    nome_da_cidade: "VERMELHO NOVO",
  },
  {
    codigo_do_IGBE: 2414902,
    UF: "RN",
    nome_da_cidade: "VICOSA",
  },
  {
    codigo_do_IGBE: 2211605,
    UF: "PI",
    nome_da_cidade: "VILA NOVA DO PIAUI",
  },
  {
    codigo_do_IGBE: 3556800,
    UF: "SP",
    nome_da_cidade: "VIRADOURO",
  },
  {
    codigo_do_IGBE: 2616407,
    UF: "PE",
    nome_da_cidade: "VITORIA DE SANTO ANTAO",
  },
  {
    codigo_do_IGBE: 4219507,
    UF: "SC",
    nome_da_cidade: "XANXERE",
  },
  {
    codigo_do_IGBE: 4320701,
    UF: "RS",
    nome_da_cidade: "SOBRADINHO",
  },
  {
    codigo_do_IGBE: 1400605,
    UF: "RR",
    nome_da_cidade: "SAO LUIZ",
  },
  {
    codigo_do_IGBE: 3100609,
    UF: "MG",
    nome_da_cidade: "AGUA BOA",
  },
  {
    codigo_do_IGBE: 4203808,
    UF: "SC",
    nome_da_cidade: "CANOINHAS",
  },
  {
    codigo_do_IGBE: 2403509,
    UF: "RN",
    nome_da_cidade: "ESPIRITO SANTO",
  },
  {
    codigo_do_IGBE: 3127388,
    UF: "MG",
    nome_da_cidade: "GOIANA",
  },
  {
    codigo_do_IGBE: 2606200,
    UF: "PE",
    nome_da_cidade: "GOIANA",
  },
  {
    codigo_do_IGBE: 5208905,
    UF: "GO",
    nome_da_cidade: "GOIAS",
  },
  {
    codigo_do_IGBE: 2928059,
    UF: "BA",
    nome_da_cidade: "SANTA LUZIA",
  },
  {
    codigo_do_IGBE: 2110005,
    UF: "MA",
    nome_da_cidade: "SANTA LUZIA",
  },
  {
    codigo_do_IGBE: 2513406,
    UF: "PB",
    nome_da_cidade: "SANTA LUZIA",
  },
  {
    codigo_do_IGBE: 2110609,
    UF: "MA",
    nome_da_cidade: "SAO BERNARDO",
  },
  {
    codigo_do_IGBE: 4216008,
    UF: "SC",
    nome_da_cidade: "SAO CARLOS",
  },
  {
    codigo_do_IGBE: 2515930,
    UF: "PB",
    nome_da_cidade: "SERTAOZINHO",
  },
  {
    codigo_do_IGBE: 3170008,
    UF: "MG",
    nome_da_cidade: "UBAI",
  },
  {
    codigo_do_IGBE: 5108600,
    UF: "MT",
    nome_da_cidade: "VILA RICA",
  },
];
