import {
  TableContainer,
  Table,
  TableBody,
  TableFooter,
  TableRow,
  TablePagination,
  makeStyles,
  TableCell,
  Chip,
  Button,
  Grid,
  Popover,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import GenericTableHead from "components/table/GenericTableHead";
import { formatCurrency } from "utils/formatCurrency";
import React, { ReactNode, useEffect } from "react";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import ExportCsv from "components/ExportCsv";
import dayjs from "dayjs";
import { PDFDownloadLink, pdf } from "@react-pdf/renderer";
import Pdf, { OrientationType } from "./Pdf";
import { useDispatch } from "react-redux";
import style from "./style.module.css";
import { dataXlsx } from "pages/Reports/AppointmentsByStatus/AppointmentStatusResultsTable/DataExport";
import * as XLSX from "xlsx";

interface ITableDefault {
  pagination?: string;
  data: any[];
  children?: ReactNode;
  table: ITable[];
  fileNameCsv: string;
  dataCsv?: any[];
  titlePdf?: string;
  exportTools?: boolean;
  functionDispatch?: any;
  pagePaginationBack?: number;
  countPaginationBack?: number;
  activePdf?: boolean;
  pdfOrientation?: OrientationType;
  pdfHeaderFontSize?: number;
  pdfBodyFontSize?: number;
  scrollTable?: string;
  styleHeader?: any;
  functionXlsx?: () => void;
  cellNowrap?: boolean;
  titleSection?: string;
  titleColor?: string;
  paginationHeader?: boolean;
}
interface ITable {
  decimal?: number;
  type?: string;
  key: string;
  id: string;
  alignment: string;
  label: string;
  typeData?: string;
  formatDate?: string;
  width?: string;
  style?: any;
  whiteSpace?: string;
  titleSection?: string;
  titleColor?: string;
}

const useStyles = makeStyles({
  spacer: {
    flex: "0",
  },
});

const TableDefault = ({
  dataCsv,
  table,
  pagination = "front",
  data,
  fileNameCsv = "DADOS",
  children,
  titlePdf,
  activePdf = false,
  exportTools = true,
  functionDispatch,
  pagePaginationBack,
  countPaginationBack,
  pdfOrientation,
  pdfHeaderFontSize,
  pdfBodyFontSize,
  scrollTable = "normal",
  styleHeader = {},
  functionXlsx,
  cellNowrap = false,
  titleSection,
  titleColor,
  paginationHeader = false
}: ITableDefault) => {
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [search, setSearch] = React.useState<null | string>(null);
  const [anchorExport, setAnchorExport] = React.useState(null);
  const [instanceExcel, setInstanceExcel] = React.useState<boolean>(false);
  const dispatch = useDispatch();

  const open = Boolean(anchorExport);
  const id = open ? "simple-popover" : undefined;

  const classes = useStyles();

  function handleChangePage(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
    if (functionDispatch) {
      dispatch(functionDispatch({ page: page, limit: rowsPerPage }));
    }
  }

  function headerCsv() {
    return table.map((e) => {
      return { label: e.label, key: e.key, decimal: e.decimal, type: e.type || e.typeData };
    });
  }

  function typeData(
    cell: ITable,
    row: Record<string, string | number | boolean>
  ) {
    const splitKey = cell.key.split(".");

    let value: any = row;
    for (const key of splitKey) {
      if (value.hasOwnProperty(key)) {
        value = value[key];
      } else {
        value = null;
        break;
      }
    }

    const data = value ?? "";
    if (cell.typeData) {
      if (
        cell.typeData === "date" &&
        (typeof data === "string" || typeof data === "number")
      ) {
        if (cell.formatDate) {
          return (
            <TableCell
              align={cell.alignment as "left" | "right" | "center"}
              style={{ textTransform: "uppercase" }}
            >
              {data ? dayjs(data).format(cell.formatDate) : "-"}
            </TableCell>
          );
        }
        return (
          <TableCell
            align={cell.alignment as "left" | "right" | "center"}
            style={{ textTransform: "uppercase" }}
          >
            {data ? dayjs(data).format("DD/MM/YYYY HH:mm") : "-"}
          </TableCell>
        );
      }
      if (cell.typeData === "money") {
        return (
          <TableCell
            align={cell.alignment as "left" | "right" | "center"}
            style={{ textTransform: "uppercase" }}
          >
            <Chip label={formatCurrency(Number(data))} />
          </TableCell>
        );
      }
      if (cell.typeData === "percentage") {
        return (
          <TableCell
            align={cell.alignment as "left" | "right" | "center"}
            style={{ textTransform: "uppercase" }}
          >
            <Chip label={`${(Number(data) * 100).toFixed(2)}%`} />
          </TableCell>
        );
      }
    }

    let renderData = data;

    //Verify flags
    let isCombo = false;

    if (data.includes("-- COMBO")) {
      isCombo = true;
      renderData = data.replace('-- COMBO', '');
    }

    return (
      <TableCell
        align={cell.alignment as "left" | "right" | "center"}
        style={{ textTransform: "uppercase", ...cell.style }}
      >
        {!(typeof renderData === "string") ? renderData : cellNowrap ? renderData : renderData.split(' ').map((str: string, index: number) => (
          <React.Fragment key={index}>
            {str}
            <br />
          </React.Fragment>
        ))}

        { isCombo && (<Chip label={`COMBO`} color="primary" />) }

      </TableCell>
    );
  }

  function dataFilter() {
    let dataClone = [...data];
    let pageCurrent = page;
    if (search) {
      dataClone = data.filter((e) => {
        let values = Object.values(e);
        return values.some((el) => {
          if (typeof el == "string" || typeof el == "number")
            return el.toString().toUpperCase().includes(search.toUpperCase());
          return false;
        });
      });
      pageCurrent = 0;
    }
    if (rowsPerPage > 0) {
      return dataClone.slice(
        pageCurrent * rowsPerPage,
        pageCurrent * rowsPerPage + rowsPerPage
      );
    } else {
      return dataClone;
    }
  }
  
  function paginationActive() {
    if (pagination === "front") {
      return (
        <>
          <TableBody>
            {dataFilter().map(
              (row: Record<string, string | number | boolean>) => {
                return (
                  <TableRow>
                    {table.map((cell: ITable) => {
                      return typeData(cell, row);
                    })}
                  </TableRow>
                );
              }
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[
                  10,
                  25,
                  50,
                  100,
                  1000,
                  { label: "Todos", value: -1 },
                ]}
                colSpan={4}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                labelRowsPerPage="Itens por página"
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                classes={{ spacer: classes.spacer }}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} de ${count}`
                }
              />
            </TableRow>
          </TableFooter>
        </>
      );
    } else if (pagination === "back") {
      return (
        <>
          <TableBody>
            {dataFilter().map(
              (row: Record<string, string | number | boolean>) => {
                return (
                  <TableRow>
                    {table.map((cell: ITable) => {
                      return typeData(cell, row);
                    })}
                  </TableRow>
                );
              }
            )}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100, 1000]}
                colSpan={11}
                count={countPaginationBack ? countPaginationBack : 0}
                rowsPerPage={rowsPerPage}
                page={pagePaginationBack ? pagePaginationBack - 1 : page}
                onChangePage={handleChangePageBack}
                labelRowsPerPage="Itens por página"
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                classes={{ spacer: classes.spacer }}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} de ${count}`
                }
              />
            </TableRow>
          </TableFooter>
        </>
      );
    }
  }

  function handleChangePageBack(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) {
    if (newPage < (pagePaginationBack ? pagePaginationBack : page)) {
      dispatch(
        functionDispatch(
          (pagePaginationBack ? pagePaginationBack : page) - 1,
          rowsPerPage
        )
      );
      return;
    }
    dispatch(
      functionDispatch(
        (pagePaginationBack ? pagePaginationBack : page) + 1,
        rowsPerPage
      )
    );
  }

  function headCellsTable() {
    return table.map((item) => ({
      id: item.id,
      alignment: item.alignment,
      label: item.label,
      width: item.width ? item.width : "auto"
      // whiteSpace: item.whiteSpace || "normal"
    }));
  }

  async function exportXlsx(data: any, header: any) {
    setInstanceExcel(true)
    const wb = XLSX.utils.book_new();
    const worksheetGeneral = XLSX.utils.aoa_to_sheet([]);
    const dataWS = dataXlsx(data, header)
    XLSX.utils.sheet_add_aoa(worksheetGeneral, dataWS, {
      origin: "A1",
    });
    XLSX.utils.book_append_sheet(
      wb,
      worksheetGeneral,
      fileNameCsv
    );
    await XLSX.writeFile(wb, `${fileNameCsv}.xlsx`);
    setInstanceExcel(false)
  }

  const handleClick = (event: any) => {
    setAnchorExport(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorExport(null);
  };

  const [instancePdf, setInstancePdf] = React.useState<boolean>(false)

  async function downloadPDF() {
    return pdf(<Pdf
      table={table}
      data={data}
      title={titlePdf}
      orientation={pdfOrientation}
      headerFontSize={pdfHeaderFontSize}
      bodyFontSize={pdfBodyFontSize}
    />)
      .toBlob()
      .then((blob) => {
        setInstancePdf(true)
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${fileNameCsv}.pdf`;
        link.click();
        setInstancePdf(false)
      });

  }

  return (
    <>
      <Grid style={{ padding: "1rem 1rem" }} container justify="space-between" alignItems="center">
        <Grid item>
          <Typography variant="body1">
            <strong style={{ color: titleColor ? titleColor : "initial" }}>{titleSection}</strong>
          </Typography>
        </Grid>

        <Grid item>
          <Grid container alignItems="center" spacing={2}>
            {paginationHeader && <Grid item>
              <TablePagination
                rowsPerPageOptions={[
                  10,
                  25,
                  50,
                  100,
                  1000,
                  { label: "Todos", value: -1 },
                ]}
                colSpan={4}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                labelRowsPerPage="Itens por página"
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                classes={{ spacer: classes.spacer }}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} de ${count}`
                }
              />
            </Grid>}
            {pagination === "front" && (
              <Grid item>
                <div className={style.containerSearch}>
                  <input
                    placeholder="Pesquisar..."
                    value={search ? search : ""}
                    onChange={(e) => setSearch(e.target.value)}
                    required={true}
                    className={style.inputSearch}
                    name="text"
                    type="text"
                  />
                  <div className={style.icon}>
                    <svg
                      viewBox="0 0 512 512"
                      className={style.ionicon}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <title>Search</title>
                      <path
                        stroke-width="32"
                        stroke-miterlimit="10"
                        stroke="#fff"
                        fill="none"
                        d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z"
                      ></path>
                      <path
                        d="M338.29 338.29L448 448"
                        stroke-width="32"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke="#fff"
                        fill="none"
                      ></path>
                    </svg>
                  </div>
                </div>
              </Grid>
            )}
            {exportTools && pagination === "front" && (
              <Grid item>
                <Button
                  aria-describedby={id}
                  size="medium"
                  variant="outlined"
                  onClick={handleClick}
                  style={{
                    backgroundColor: "#00a1a9",
                    color: "#fff",
                    border: "none",
                  }}
                >
                  Exportar
                </Button>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorExport}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "5px",
                      gap: "10px",
                      width: "150px",
                    }}>

                    {!(functionXlsx) ?
                      <Button
                        variant="outlined"
                        size="small"
                        style={{
                          width: "100%",
                          height: "100%",
                          backgroundColor: "#00a1a9",
                          color: "#fff",
                          border: "none",
                        }}
                        onClick={() => exportXlsx(dataCsv ? dataCsv : data, headerCsv())}
                      >Excel</Button>
                      : <Button
                        variant="outlined"
                        size="small"
                        style={{
                          width: "100%",
                          height: "100%",
                          backgroundColor: "#00a1a9",
                          color: "#fff",
                          border: "none",
                        }}
                        onClick={() => functionXlsx()}
                      >Excel</Button>
                    }
                    {
                      activePdf && <Button
                        variant="outlined"
                        size="small"
                        style={{
                          backgroundColor: "#00a1a9",
                          color: "#fff",
                          border: "none",
                        }}
                        onClick={
                          () => {
                            setInstancePdf(true)
                            downloadPDF().finally(() => setInstancePdf(false))
                          }
                        }
                      >
                        {instancePdf ? "carregando" : "Pdf"}
                      </Button>}
                  </Grid>
                </Popover>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <TableContainer style={{ margin: "0.2rem 0", height: "500px" }}>
        <Table size="small">
          <GenericTableHead  headCells={headCellsTable()} style={styleHeader} />
          {children ? children : paginationActive()}
        </Table>
      </TableContainer>
    </>
  )
};

export default TableDefault;
