import React from "react";
import { TableRow, TableCell, TableHead } from "@material-ui/core";

interface IHeadCell {
  id: string;
  alignment: string;
  label: string;
  whiteSpace?: string;
  width?: string;
}

interface IProps {
  headCells: IHeadCell[];
  style?: any;
}

const GenericTableHead: React.FC<IProps> = ({ headCells, style = {} }) => {
  return (
    <TableHead style={{...style}}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            style={{ fontWeight: "bold" , whiteSpace: headCell.whiteSpace === "nowrap" ? headCell.whiteSpace : "normal", minWidth: headCell.width ? headCell.width : "auto"}}
            key={headCell.id}
            align={headCell.alignment as "left" | "right"}
          >
            {headCell.label.charAt(0).toUpperCase() + headCell.label.slice(1)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default GenericTableHead;
