import {
  IPatientWallet,
  IPatientSummaryWallet,
} from "./../../../models/Patients/index";
import { IFilter } from "./../../../models/shared/index";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "store/index";
import { toast } from "react-toastify";
import toastOptions from "utils/toastOptions";
import api from "utils/API";
import { queryStringFromFilterArray } from "utils/network";
import { history } from "utils/history";
import { IPatient, IPatientForm } from "./../../../models/Patients";
import { initialState as initialValues } from "pages/patients/Form/data";
import { setcurrentPreAppointment } from "../MedicalRecordReducer";

interface IInitialState {
  isFetchingPatients: boolean;
  isFetchingPatientsReports: boolean;
  isFetchingAllPatients: boolean;
  isFetchingPatientsPost: boolean;
  isFetchingPatientWallet: boolean;
  isCreatingPatient: boolean;
  isDeletingPatient: boolean;
  isTransferBetweenPatients: boolean;
  patients: IPatient[] | null;
  reports: IPatient[] | null;
  currentPatient: IPatient;
  total: number;
  page: number;
  pageToNormalItemsWaitingSchedule: number;
  pageToVirtualWalletTable: number;
  pageToUnfinishedServicesTable: number;
  filterArray: IFilter[];
  patientWallerFilterArray: IFilter[];
  patientWallet: IPatientWallet | null;
  patientWalletUnfinshed: IPatientWallet | null;
  patientSummaryWallet: IPatientSummaryWallet[] | null;
}

const initialState: IInitialState = {
  isFetchingPatients: false,
  isFetchingPatientsReports: false,
  isFetchingPatientsPost: false,
  isFetchingPatientWallet: false,
  isCreatingPatient: false,
  isDeletingPatient: false,
  isTransferBetweenPatients: false,
  patients: null,
  reports: null,
  // @ts-ignore
  currentPatient: initialValues,
  total: 0,
  page: 0,
  pageToNormalItemsWaitingSchedule: 1,
  pageToVirtualWalletTable: 1,
  pageToUnfinishedServicesTable: 1,
  filterArray: [
    { key: "email", value: null },
    { key: "telefone", value: null },
    { key: "cargo", value: null },
    { key: "nomepaciente", value: [] },
    { key: "cpf", value: [] },
    { key: "telefonecelular", value: [] },
    { key: "sexo", value: null },
    { key: "month", value: null },
    { key: "recurrency", value: null },
    { key: "idcanalorigem", value: null },
    { key: "idcanalatendimento", value: null },
    { key: "iditemservico", value: null },
    { key: "idcategoriaservico", value: null },
    { key: "idprofissional", value: null },
    { key: "start_date", value: null },
    { key: "end_date", value: null },
    { key: "rg", value: [] },
    { key: "tipodata",  value: null}
  ],
  patientWallerFilterArray: [
    { key: "page", value: 1 },
    { key: "limit", value: 6 },
    { key: "agendamento", value: false },
  ],
  patientWallet: null,
  patientWalletUnfinshed: null,
  patientSummaryWallet: null,
};

const patientsSlice = createSlice({
  name: "patientsSlice",
  initialState,
  reducers: {
    setisFetchingPatientsPost: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingPatientsPost = payload;
    },
    setisFetchingPatients: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingPatients = payload;
    },
    setisFetchingPatientsReports: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingPatientsReports = payload;
    },
    setIsFetchingAllPatients: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingAllPatients = payload;
    },
    setisFetchingPatientWallet: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingPatientWallet = payload;
    },
    setIsCreatingPatient: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreatingPatient = payload;
    },
    setisDeletingPatient: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeletingPatient = payload;
    },
    setIsTransferBetweenPatients: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isTransferBetweenPatients = payload;
    },
    setPatients: (
      state,
      {
        payload: { patients, total, page },
      }: PayloadAction<{ patients: IPatient[]; total: number; page: number }>
    ) => {
      state.patients = patients;
      state.total = total;
      state.page = page;
    },
    setPatientWallet: (state, { payload }: PayloadAction<IPatientWallet>) => {
      state.patientWallet = payload;
      // state.patientWallerFilterArray = state.patientWallerFilterArray.map(filter => {
      //   if (filter.key === 'page') {
      //     filter.value = payload.page;
      //   }
      //   return filter
      // })
    },
    setPatientSummaryWallet: (
      state,
      { payload }: PayloadAction<IPatientSummaryWallet[]>
    ) => {
      state.patientSummaryWallet = payload;
    },
    setPatientWalletUnfinished: (
      state,
      { payload }: PayloadAction<IPatientWallet>
    ) => {
      state.patientWalletUnfinshed = payload;
    },
    setCurrentPatient: (state, { payload }: PayloadAction<IPatient>) => {
      state.currentPatient = payload;
    },
    clearCurrentPatient: (state) => {
      state.currentPatient = initialValues as IPatient;
    },
    updateFilter: (state, { payload }: PayloadAction<IFilter>) => {
      const index = state.filterArray.findIndex(
        (item) => item.key === payload.key
      );
      state.filterArray[index].value = payload.value;
    },
    updatePatientWalletFilterArray: (
      state,
      { payload: { key, value } }: PayloadAction<IFilter>
    ) => {
      state.patientWallerFilterArray = state.patientWallerFilterArray.map(
        (filter) => {
          if (filter.key === key) {
            filter.value = value;
          }
          return filter;
        }
      );
    },
    setPageToNormalItemsWaitingSchedule: (
      state,
      { payload }: PayloadAction<number>
    ) => {
      state.pageToNormalItemsWaitingSchedule = payload;
    },
    setPageToVirtualWalletTable: (
      state,
      { payload }: PayloadAction<number>
    ) => {
      state.pageToVirtualWalletTable = payload;
    },
    setPageToUnfinishedServicesTable: (
      state,
      { payload }: PayloadAction<number>
    ) => {
      state.pageToUnfinishedServicesTable = payload;
    },
  },
});

export const fetchPatients =
  ({ page = 1, limit = 6 }: { page?: number; limit?: number }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingAllPatients } = patientsSlice.actions;
    dispatch(setIsFetchingAllPatients(true));
    try {
      const state = getState();
      const { filterArray } = state.patients;
      const queryParameters = queryStringFromFilterArray(filterArray);
      const pageAndLimit =
        queryParameters.length === 0
          ? `?page=${page}&limit=${limit}`
          : `&page=${page}&limit=${limit}`;
      const response = await api.get(
        `/api/patients${queryParameters}${pageAndLimit}`
      );
      dispatch(patientsSlice.actions.setPatients(response.data.data));
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setIsFetchingAllPatients(false));
    }
  };

export const fetchPatientsReports =
  ({ page = 1, limit = 6 }: { page?: number; limit?: number }): AppThunk =>
  async (dispatch, getState) => {
    const { setisFetchingPatientsReports } = patientsSlice.actions;
    dispatch(setisFetchingPatientsReports(true));
    try {
      const state = getState();
      const { filterArray } = state.patients;
      const queryParameters = queryStringFromFilterArray(filterArray);
      const pageAndLimit =
        queryParameters.length === 0
          ? `?page=${page}&limit=${limit}`
          : `&page=${page}&limit=${limit}`;
      const response = await api.get(
        `/api/patients/reports${queryParameters}${pageAndLimit}`
      );
      dispatch(patientsSlice.actions.setPatients(response.data.data));
      dispatch(setisFetchingPatientsReports(false));
    } catch (error: any) {
      dispatch(setisFetchingPatientsReports(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchPatientById =
  ({ patientId, cb }: { patientId: string; cb?: Function }): AppThunk =>
  async (dispatch) => {
    const { setisFetchingPatients, setCurrentPatient } = patientsSlice.actions;
    dispatch(setisFetchingPatients(true));
    try {
      const response = await api.get(`/api/patients/${patientId}`);
      dispatch(setCurrentPatient(response.data.data));
      dispatch(setisFetchingPatients(false));
      if (cb) {
        cb(response.data.data);
      }
    } catch (error: any) {
      dispatch(setisFetchingPatients(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchPatientsByName =
  ({ patientName }: { patientName: string }): AppThunk =>
  async (dispatch) => {
    const { setisFetchingPatients, setPatients } = patientsSlice.actions;
    dispatch(setisFetchingPatients(true));
    try {
      if (patientName) {
        const response = await api.get(
          `/api/patients-search?search=${patientName}&verify_ativo=true`
        );
        dispatch(setPatients(response.data.data));
      }
      dispatch(setisFetchingPatients(false));
    } catch (error: any) {
      dispatch(setisFetchingPatients(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const checkIfPatientExists =
  ({
    searchText,
    textType,
    formData,
    cb,
  }: {
    searchText: string;
    textType: string;
    formData: any;
    cb: Function;
  }): AppThunk =>
  async (dispatch) => {
    const { setisFetchingPatients } = patientsSlice.actions;
    dispatch(setisFetchingPatients(true));
    try {
      if (searchText) {
        const response = await api.get(
          `/api/patients-search?search=${searchText}`
        );
        if (response.data.data.patients.length > 0) {
          cb(true, response.data.data.patients, textType);
        } else {
          cb(false, [formData]);
        }
      }
      dispatch(setisFetchingPatients(false));
    } catch (error: any) {
      dispatch(setisFetchingPatients(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const newCheckIfPatientExists =
  ({
    date,
    textDate,
    name,
    textName,
    number,
    textNumber,
    formData,
    cb,
  }: {
    date: string;
    textDate: string;
    name: string;
    textName: string;
    number: string;
    textNumber: string;
    formData: any;
    cb: Function;
  }): AppThunk =>
  async (dispatch) => {
    const { setisFetchingPatients } = patientsSlice.actions;
    dispatch(setisFetchingPatients(true));
    try {
      if (textName) {
        const response = await api.get(
          `/api/patients-new-search?name=${name}&date=${date}&number=${number}`
        );
        if (response.data.data.patients.length > 0) {
          cb(true, response.data.data.patients, textName);
        } else {
          cb(false, [formData]);
        }
      }
      dispatch(setisFetchingPatients(false));
    } catch (error: any) {
      dispatch(setisFetchingPatients(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const createPatient =
  (patient: IPatientForm, cb?: any): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingPatient } = patientsSlice.actions;
    dispatch(setIsCreatingPatient(true));
    try {
      const response = await api.post(`/api/patients`, patient);
      dispatch(setIsCreatingPatient(false));
      toast.success("Paciente cadastrado", toastOptions);
      if (cb) {
        cb(response.data.data);
      } else {
        history.replace("/patients/list");
      }
    } catch (error: any) {
      dispatch(setIsCreatingPatient(false));
      console.log("error", error.response);
      if (error.response.data.error.status === 409) {
        cb(error.response.data?.data);
        toast.info(error.response.data?.error?.message, toastOptions);
      } else {
        if (error.response) {
          toast.error(error.response.data?.error?.message, toastOptions);
        } else {
          console.log(error.message);
        }
      }
    }
  };
export const updatePatient =
  ({
    patient,
    patientId,
    cb,
  }: {
    patient: IPatientForm;
    patientId: string | number;
    cb?: () => void;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingPatient } = patientsSlice.actions;
    dispatch(setIsCreatingPatient(true));
    try {
      const response = await api.put(`/api/patients/${patientId}`, patient);
      dispatch(setIsCreatingPatient(false));
      toast.success("Paciente atualizado", toastOptions);
      if (cb) {
        cb();
      } else {
        history.replace(`/patients/form/${response.data.data.idpaciente}`);
      }
      dispatch(fetchPatientById({ patientId: response.data.data.idpaciente }));
    } catch (error: any) {
      dispatch(setIsCreatingPatient(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const updatePatientWithoutRedirect =
  ({
    patient,
    patientId,
    disableToast,
    cb,
  }: {
    patient: IPatientForm;
    patientId: string | number;
    disableToast?: boolean;
    cb?: Function;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingPatient } = patientsSlice.actions;
    dispatch(setIsCreatingPatient(true));
    try {
      await api.put(`/api/patients/${patientId}`, patient);
      dispatch(setIsCreatingPatient(false));
      if (disableToast) {
      } else {
        toast.success("Dados atualizados", {
          ...toastOptions,
          toastId: "data-updated-patient",
        });
      }

      if (cb) {
        cb();
      }
    } catch (error: any) {
      dispatch(setIsCreatingPatient(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, {
          ...toastOptions,
          toastId: "data-updated-patient",
        });
      } else {
        console.log(error.message);
      }
    }
  };

export const updateMedicalData =
  ({
    patientId,
    peso = "",
    altura = "",
    nomesocial = "",
    disableToast,
    cb,
  }: {
    patientId: string | number;
    peso: string | number | undefined;
    altura: string | number | undefined;
    nomesocial?: string;
    disableToast?: boolean;
    cb?: () => void;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingPatient } = patientsSlice.actions;
    dispatch(setIsCreatingPatient(true));
    try {
      const body = {};

      if (altura && altura !== "") {
        //@ts-ignore
        body.altura = altura;
      }

      if (peso && peso !== "") {
        //@ts-ignore
        body.peso = peso;
      }

      if (nomesocial && nomesocial !== "") {
        //@ts-ignore
        body.nomesocial = nomesocial;
      }

      const response = await api.put(`/api/patients/${patientId}`, body);
      dispatch(setIsCreatingPatient(false));
      if (disableToast) {
      } else {
        toast.success("Paciente atualizado", {
          ...toastOptions,
          toastId: "patient-updated",
        });
      }
      if (cb) {
        cb();
      } else {
        history.replace(`/patients/form/${response.data.data.idpaciente}`);
      }
      dispatch(fetchPatientById({ patientId: response.data.data.idpaciente }));
    } catch (error: any) {
      dispatch(setIsCreatingPatient(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, {
          ...toastOptions,
          toastId: "patient-updated",
        });
      } else {
        console.log(error.message);
      }
    }
  };

export const deletePatient =
  (patientId: string, isDisabling: boolean): AppThunk =>
  async (dispatch) => {
    const { setisDeletingPatient } = patientsSlice.actions;
    dispatch(setisDeletingPatient(true));
    var url = `/api/patients/${patientId}`;
    var data = { ativo: !isDisabling };
    try {
      await api.put(url, data);
      dispatch(setisDeletingPatient(false));
      toast.success(
        `Paciente ${isDisabling ? `desativado` : `ativado`} com sucesso`,
        toastOptions
      );
      dispatch(fetchPatients({}));
    } catch (error: any) {
      dispatch(setisDeletingPatient(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchPatientWalletByPatientId =
  ({
    patientId,
    page = 1,
    limit = 6,
    agendamento = true,
  }: {
    patientId: string;
    page: number;
    limit: number;
    agendamento: boolean;
  }): AppThunk =>
  async (dispatch) => {
    const { setisFetchingPatientWallet, setPatientWallet } =
      patientsSlice.actions;
    dispatch(setisFetchingPatientWallet(true));
    try {
      const response = await api.get(
        `/api/patients/${patientId}/wallet?page=${page}&limit=${limit}&agendamento=${agendamento}`
      );
      dispatch(setPatientWallet(response.data.data));
      dispatch(setisFetchingPatientWallet(false));
    } catch (error: any) {
      dispatch(setisFetchingPatientWallet(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchPatientSettledItemsSummary =
  ({ patientId }: { patientId: string }): AppThunk =>
  async (dispatch) => {
    const { setisFetchingPatientWallet, setPatientSummaryWallet } =
      patientsSlice.actions;
    dispatch(setisFetchingPatientWallet(true));
    try {
      const response = await api.get(
        `/api/patients/${patientId}/wallet/paid-items-summary`
      );
      dispatch(setPatientSummaryWallet(response.data.data));
      dispatch(setisFetchingPatientWallet(false));
    } catch (error: any) {
      dispatch(setisFetchingPatientWallet(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const clearData = (): AppThunk => async (dispatch) => {
  const { setPatients } = patientsSlice.actions;
  dispatch(setPatients({ patients: [], page: 0, total: 0 }));
};

export const fetchPatientWalletUnfinishedServices =
  ({
    patientId,
    page = 1,
    limit = 6,
  }: {
    patientId: string;
    page?: number;
    limit?: number;
  }): AppThunk =>
  async (dispatch) => {
    const { setisFetchingPatientWallet, setPatientWalletUnfinished } =
      patientsSlice.actions;

    dispatch(setisFetchingPatientWallet(true));
    try {
      const response = await api.get(
        `/api/patients/${patientId}/wallet/unfinished?page=${page}&limit=${limit}`
      );
      dispatch(setPatientWalletUnfinished(response.data.data));
      dispatch(setisFetchingPatientWallet(false));
    } catch (error: any) {
      dispatch(setisFetchingPatientWallet(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const transferBetweenPatients =
  ({
    patientData,
    patientId,
    callback,
  }: {
    patientData: {
      email: string;
      password: string;
      idpaciente: string;
      transfer_value: number;
    };
    patientId: string;
    callback?: Function;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsTransferBetweenPatients } = patientsSlice.actions;
    dispatch(setIsTransferBetweenPatients(true));

    try {
      await api.patch(`/api/patients/${patientId}/transfer`, patientData);
      toast.success("Valor transferido com sucesso", toastOptions);

      if (callback) {
        callback();
      }
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setIsTransferBetweenPatients(false));
    }
  };

export const syncPatientHeightAndWeight =
  ({
    height,
    weight,
    idpaciente,
    idmedicalrecord,
    cb,
  }: {
    height: string | number;
    weight: string | number;
    idmedicalrecord: string;
    idpaciente?: string;
    cb?: () => void;
  }): AppThunk =>
  async (dispatch) => {
    const { setCurrentPatient, setisFetchingPatients } = patientsSlice.actions;
    try {
      await api.put(`/api/patients/${idpaciente}`, {
        altura: height,
        peso: weight,
      });

      const preAppointmentResponse = await api.put(
        `/api/pre-appointment/${idmedicalrecord}`,
        {
          altura: height,
          peso: weight,
          idprontuariomedico: idmedicalrecord,
        }
      );

      dispatch(setcurrentPreAppointment(preAppointmentResponse.data.data));

      dispatch(setisFetchingPatients(true));

      const patientResponse = await api.get(`/api/patients/${idpaciente}`);

      dispatch(setCurrentPatient(patientResponse.data.data));
      dispatch(setisFetchingPatients(false));

      if (cb) {
        cb();
      }
    } catch (error: any) {
      dispatch(setisFetchingPatients(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const {
  updateFilter: updatePatientFilter,
  updatePatientWalletFilterArray,
  setPageToNormalItemsWaitingSchedule,
  setPageToVirtualWalletTable,
  setPageToUnfinishedServicesTable,
  setCurrentPatient,
  clearCurrentPatient,
} = patientsSlice.actions;

export default patientsSlice.reducer;
