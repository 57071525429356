import { lightTheme } from "themes/light";
import { darkTheme } from "themes/dark";
import { Theme } from "@material-ui/core";

const themeMap: { [key: string]: Theme } = {
  lightTheme,
  darkTheme,
};

export const getThemeByName = (themeName: string): Theme => {
  return themeMap[themeName];
};
