import React from "react";

import * as S from "./styles";
import { Grid } from "@material-ui/core";

import SigninForm from "./components/Form";
import SigninBanner from "./components/SigninBanner";
import logo from "assets/main_logo.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const SigninPage = () => {
  const match = useMediaQuery("(max-width:1050px)");

  return (
    <S.AuthPageContainer>
      <Grid container style={{ height: "100%", padding: 0 }}>
        <Grid item xs={12} md={8} sm={6}>
          <SigninBanner />
        </Grid>
        <Grid item xs={12} md={4} sm={6}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <div
              style={{
                background: `url(${logo}) no-repeat center center`,
                backgroundSize: "contain",
                height: `${match ? '80px' : '200px'}`,
                marginBottom: `${match ? '0px': '20px'}`,
                width: "85%",
              }}
            ></div>
            <SigninForm />
          </div>
        </Grid>
      </Grid>
    </S.AuthPageContainer>
  );
};

export default SigninPage;
