import { ICheckinRule } from "store/reducers/AppointmentWebSetup";
import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  url: Yup.string().required("Campo obrigatório"),
  idclinica: Yup.string().required("Campo obrigatório"),
});

export const initialState: ICheckinRule = {
  url: "",
  idclinica: "",
};
