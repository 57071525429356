import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  Grid,
  IconButton,
  LinearProgress,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { FullWidthContainer } from "components/layout/base";
import { Formik, Form as FormikForm } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { ExpandMore } from "@material-ui/icons";
import dayjs from "dayjs";
import { getFullAge } from "utils/getFullAge";
import Divider from "@material-ui/core/Divider";

// @ts-ignore
import { FormikTextField, FormikSwitchField } from "formik-material-fields";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import { Close } from "@material-ui/icons";
import { SizedBox } from "components/layout/utils";
import { useDispatch, useSelector } from "react-redux";
import { rootState } from "store";
import {
  createPreAppointment,
  fetchMedicalRecordsByPatientId,
  fetchPreAppointment,
  updatePreAppointment,
  updateStatusPreAppointment,
  updateStatusPreAppointmentStart,
} from "store/reducers/MedicalRecordReducer";
import { IPatientMedicalReportAnamneseOftalmo } from "../data";
import { initialState } from "../data";
import { IMedicalRecord, MedicalRecordStatusEnum } from "models/MedicalRecord";
import { fetchMedicalRecordsPreApps } from "store/reducers/ReleaseMedicalRecordsReducer";
import useDebounce from "hooks/useDebouce";
import { syncPatientHeightAndWeight } from "store/reducers/PatientsReducer";
// import BasicInfo from "pages/MedicalRecord/components/PatientMedicalRecord/components/BasicInfo";

interface IProps {
  idMedicalRecord: string;
  patient?: string;
  onClose: Function;
  open: boolean;
}

const PreAppointmentModal: React.FC<IProps> = ({
  onClose,
  idMedicalRecord,
  open,
  patient,
}: IProps) => {
  const [initialValues, setInitialValues] = useState(initialState);
  const dispatch = useDispatch();
  const match = useMediaQuery("(max-width: 1300px)");
  const {
    medicalRecords: {
      isCreatingMedicalRecord,
      currentPreAppointment,
      isFetchingMedicalRecord,
      selectedMedicalRecord,
    },
    users: { signedInUser },
  } = useSelector((state: rootState) => state);
  const [timerId, setTimerId] = useState<number | null>(null);
  const formikValues = useRef<{
    values: IPatientMedicalReportAnamneseOftalmo;
  } | null>(null);
  const [patientBirthday, setPatientBirthday] = useState(
    initialValues.datanascimento
  );
  // const [weight, setWeight] = useState<string | undefined>("");
  // const [height, setHeight] = useState<string | undefined>("");
  // const [patientName, setPatientName] = useState<string>("");
  const [patientGender, setPatientGender] = useState<string>("");
  const [formTimeChange, setFormTimeChange] =
    useState<IPatientMedicalReportAnamneseOftalmo | null>(null);

  //@ts-ignore
  const debouncedFormikChange = useDebounce(formTimeChange, 800);

  // const setUpdateDataInterval = () => {
  //   if (timerId) {
  //     clearTimeout(timerId);
  //   }

  //   var _tid = setTimeout(() => {
  //     //@ts-ignore
  //     if (formikValues.current && formikValues.current.values) {
  //       //@ts-ignore
  //       syncData(formikValues.current.values);
  //     }
  //   }, 800);

  //   setTimerId(_tid);
  // };

  const setUpdateDataInterval = () => {
    if (formikValues && formikValues.current && formikValues.current.values) {
      setFormTimeChange(formikValues.current.values);
    }
  };

  useEffect(() => {
    //@ts-ignore
    if (formikValues && formikValues.current && formikValues.current.values) {
      syncData(formikValues.current.values);
    }
  }, [debouncedFormikChange]);

  const syncData = (values: IPatientMedicalReportAnamneseOftalmo) => {
    const newValues: IPatientMedicalReportAnamneseOftalmo = {
      ...values,
      datanascimento: patientBirthday,
    };
    if (currentPreAppointment) {
      delete newValues.peso;
      delete newValues.altura;

      dispatch(
        updatePreAppointment({
          medicalRecordId: idMedicalRecord,
          data: newValues,
          cb: () => {
            dispatch(
              fetchPreAppointment({
                medicalRecordId: idMedicalRecord,
              })
            );
          },
        })
      );

      if (
        values.peso !== currentPreAppointment.peso ||
        values.altura !== currentPreAppointment.altura
      ) {
        dispatch(
          syncPatientHeightAndWeight({
            weight: values.peso ? values.peso : 0,
            height: values.altura ? values.altura : 0,
            idmedicalrecord: idMedicalRecord,
            idpaciente: currentPreAppointment.idpaciente,
          })
        );
      }
    } else {
      dispatch(
        createPreAppointment({
          medicalRecordId: idMedicalRecord,
          data: newValues,
          cb: () => {
            dispatch(
              fetchPreAppointment({
                medicalRecordId: idMedicalRecord,
              })
            );
          },
        })
      );
    }

    // setTimeout(() => {
    //   dispatch(fetchMedicalRecordsPreApps({ page: 1, limit: 6 }));
    // }, 1500);
  };

  function updateStatusPreAppointmentBtn() {
    if (currentPreAppointment) {
      dispatch(
        updateStatusPreAppointment({
          medicalRecordId: idMedicalRecord,
          status: "finalizado",
          cb: () => {
            onClose();
          },
        })
      );
    }
  }

  const onSubmit = (values: IPatientMedicalReportAnamneseOftalmo) => {
    const newValues: IPatientMedicalReportAnamneseOftalmo = {
      ...values,
      datanascimento: patientBirthday,
    };
    if (currentPreAppointment) {
      dispatch(
        updatePreAppointment({
          medicalRecordId: idMedicalRecord,
          data: newValues,
          cb: () => {
            onClose();
          },
        })
      );
    } else {
      dispatch(
        createPreAppointment({
          medicalRecordId: idMedicalRecord,
          data: values,
          cb: () => {
            onClose();
          },
        })
      );
    }
  };

  useEffect(() => {
    if (idMedicalRecord) {
      dispatch(
        fetchPreAppointment({
          medicalRecordId: idMedicalRecord,
          cb: (data: IPatientMedicalReportAnamneseOftalmo) => {
            if (data === null && idMedicalRecord) {
              dispatch(
                createPreAppointment({
                  medicalRecordId: idMedicalRecord,
                  cb: () => {
                    dispatch(
                      fetchPreAppointment({
                        medicalRecordId: idMedicalRecord,
                      })
                    );
                  },
                })
              );
            }
          },
        })
      );
    }
  }, [idMedicalRecord, dispatch]);

  useEffect(() => {
    if (currentPreAppointment) {
      setInitialValues(currentPreAppointment);
      setPatientBirthday(currentPreAppointment.datanascimento);
    }
  }, [dispatch, currentPreAppointment, idMedicalRecord]);

  // useEffect(() => {
  //   setInitialValues(initialState);
  // }, []);

  // To use in case is needed
  function isDisabledByFinishingTime(): boolean {
    if (
      selectedMedicalRecord &&
      (selectedMedicalRecord.status === MedicalRecordStatusEnum.ABERTO ||
        selectedMedicalRecord.status === MedicalRecordStatusEnum.AGUARDANDO ||
        (selectedMedicalRecord?.status === MedicalRecordStatusEnum.FECHADO &&
          new Date().getTime() -
            new Date(selectedMedicalRecord.datafinalizado).getTime() <
            2 * 24 * 60 * 60 * 1000))
    ) {
      return false;
    } else {
      return true;
    }
  }

  return (
    <Dialog open={open} fullScreen>
      <AppBar>
        <Grid container>
          <Toolbar
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Grid
              item
              container
              style={{ justifyContent: "flex-start", alignItems: "center" }}
            >
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => onClose()}
              >
                <Close />
              </IconButton>
              <SizedBox width="10px" />
              {!match && (
                <Typography variant="h6" style={{ textTransform: "uppercase" }}>
                  Dados da Pré-consulta - {patient}
                </Typography>
              )}
            </Grid>

            <Button
              variant="contained"
              color="primary"
              style={{
                maxHeight: "40px",
                width: "300px",
                fontWeight: "regular",
              }}
              disabled={
                currentPreAppointment?.status_triagem === true &&
                currentPreAppointment?.status === "finalizado"
              }
              onClick={() => {
                updateStatusPreAppointmentBtn();
              }}
            >
              {currentPreAppointment &&
              currentPreAppointment.status &&
              currentPreAppointment.status.toLowerCase() === "finalizado"
                ? "Pré-consulta Finalizada"
                : "Finalizar Pré-consulta"}
            </Button>
          </Toolbar>
        </Grid>
      </AppBar>
      <FullWidthContainer style={{ marginTop: 64 }}>
        {isFetchingMedicalRecord ? (
          <LinearProgress color="primary" />
        ) : (
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            enableReinitialize
            //@ts-ignore
            innerRef={formikValues}
          >
            {({ errors, setFieldValue, isValid, ...props }) => (
              <FormikForm
                onBlur={() => {
                  setUpdateDataInterval();
                }}
              >
                <Grid container spacing={2}>
                  <Grid item lg={12}>
                    <Typography variant="h6">Dados da Pré-Consulta</Typography>
                  </Grid>

                  <Grid item lg={12}>
                    <Card>
                      <CardContent>
                        <Grid container>
                          {/* BASIC INFOS TITLE */}
                          <Grid container>
                            <Typography
                              variant="h6"
                              style={{
                                fontWeight: "bold",
                                textTransform: "uppercase",
                                marginBottom: "0.5rem",
                              }}
                            >
                              INFORMAÇÕES BÁSICAS
                            </Typography>
                          </Grid>

                          <Typography
                            variant="h6"
                            style={{
                              marginBottom: "1rem",
                              fontSize: 16,
                            }}
                          >
                            As informações abaixo serão salvas automaticamente
                          </Typography>
                        </Grid>

                        {/* DIVIDER */}
                        <Grid item xs={12} style={{ margin: ".5rem 0" }}>
                          <Divider />
                        </Grid>

                        {initialValues && (
                          <Grid container spacing={2}>
                            {/* NOME E SEXO */}
                            <Grid
                              container
                              spacing={2}
                              style={{ margin: ".5rem 0" }}
                            >
                              <Grid item xs={12} md={6}>
                                <Typography
                                  variant="body1"
                                  style={{
                                    margin: "1rem 1rem 0rem",
                                    fontSize: "17px",
                                    // textTransform: "uppercase",
                                    color: "#00A1AA",
                                  }}
                                >
                                  Paciente
                                </Typography>
                                <FormikTextField
                                  fullWidth
                                  style={{ padding: "10px" }}
                                  variant="outlined"
                                  value={initialValues.nomepaciente?.toUpperCase()}
                                  name="nomepaciente"
                                />
                              </Grid>

                              <Grid item xs={12} md={6}>
                                <Typography
                                  variant="body1"
                                  style={{
                                    margin: "1rem 1rem 0rem",
                                    fontSize: "17px",
                                    // textTransform: "uppercase",
                                    color: "#00A1AA",
                                  }}
                                >
                                  Data de nascimento
                                </Typography>
                                <KeyboardDatePicker
                                  id="date-picker-dialog"
                                  format="DD/MM/YYYY"
                                  value={patientBirthday}
                                  name="datanascimento"
                                  onChange={(
                                    date: MaterialUiPickersDate,
                                    _?: string | null | undefined
                                  ) => {
                                    setPatientBirthday(
                                      String(date?.format("YYYY-MM-DD"))
                                    );
                                  }}
                                  inputVariant="outlined"
                                  style={{
                                    width: "100%",
                                    padding: "10px",
                                  }}
                                />
                              </Grid>

                              {/* <Grid item xs={12} md={6}>
                          <Typography
                            variant="body1"
                            style={{
                              margin: "2rem 1rem 0rem",
                              fontSize: "15px",
                              textTransform: "uppercase",
                              color: "#00A1AA",
                            }}
                          >
                            Sexo
                          </Typography>
                          <Select
                            variant="outlined"
                            labelId="select-gender"
                            value="selecione"
                            name="sexo"
                            fullWidth
                          >
                            <MenuItem value="Masculino">Masculino</MenuItem>
                            <MenuItem value="Feminino">Feminino</MenuItem>
                          </Select>
                        </Grid> */}
                            </Grid>

                            {/* DATA DE NASCIMENTO E IDADE */}
                            {/* <Grid container spacing={2} style={{ margin: ".5rem 0" }}>
                        <Grid item xs={12} md={6}>
                          <Typography
                              variant="body1"
                              style={{
                                margin: "0rem 1rem 0rem",
                                fontSize: "15px",
                                textTransform: "uppercase",
                                color: "#00A1AA",
                              }}
                            >
                              Data de nascimento
                          </Typography>
                          <KeyboardDatePicker
                            id="date-picker-dialog"
                            format="DD/MM/YYYY"
                            value={dayjs(initialValues.datanascimento).format("YYYY-MM-DD")}
                            name="datanascimento"
                            onChange={(
                              date: MaterialUiPickersDate,
                              _?: string | null | undefined
                            ) => {
                              setPatientBirthday(
                                String(date?.format("YYYY-MM-DD"))
                              );
                            }}
                            inputVariant="outlined"
                            style={{
                              width: "100%",
                              padding: "10px"
                            }}
                          />
                        </Grid> */}

                            {/* <Grid item xs={12} md={6}>
                          <TextField
                            variant="outlined"
                            style={{ width: "100%" }}
                            label="Idade"
                            value={String(getFullAge(patientBirthday))}
                            disabled
                          />
                        </Grid> */}
                            {/* </Grid> */}

                            {/* ALTURA E PESO */}
                            <Grid
                              container
                              spacing={2}
                              style={{ margin: ".5rem 0" }}
                            >
                              {/* <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            name="altura"
                            label="Altura"
                            error={!!errors.altura}
                            helperText={!!errors.altura && errors.altura}
                            value={height}
                          />
                        </Grid> */}
                              <Grid item xs={12} md={6}>
                                <Typography
                                  variant="body1"
                                  style={{
                                    margin: "0rem 1rem 0rem",
                                    fontSize: "17px",
                                    // textTransform: "uppercase",
                                    color: "#00A1AA",
                                  }}
                                >
                                  Altura
                                </Typography>
                                <FormikTextField
                                  fullWidth
                                  style={{ padding: "10px" }}
                                  variant="outlined"
                                  name="altura"
                                  type="number"
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <Typography
                                  variant="body1"
                                  style={{
                                    margin: "0rem 1rem 0rem",
                                    fontSize: "17px",
                                    // textTransform: "uppercase",
                                    color: "#00A1AA",
                                  }}
                                >
                                  Peso
                                </Typography>
                                <FormikTextField
                                  fullWidth
                                  style={{ padding: "10px" }}
                                  variant="outlined"
                                  name="peso"
                                  type="number"
                                />
                              </Grid>
                              {/* <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            name="peso"
                            label="Peso"
                            error={!!errors.peso}
                            helperText={!!errors.peso && errors.peso}
                            value={weight}
                          />
                        </Grid> */}
                            </Grid>
                          </Grid>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item lg={12}>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography
                          variant="body1"
                          style={{
                            fontSize: "13px",
                            fontWeight: "800",
                            textTransform: "uppercase",
                            color: "#00A1AA",
                          }}
                        >
                          Acuidade Visual
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Card>
                              <CardContent>
                                <Grid
                                  container
                                  spacing={1}
                                  direction={match ? "column" : "row"}
                                >
                                  <Grid
                                    item
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Typography style={{ fontWeight: "700" }}>
                                      OD
                                    </Typography>
                                  </Grid>
                                  <Grid item lg={3}>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        margin: "1rem 1rem 0rem",
                                        fontSize: "15px",
                                      }}
                                    >
                                      S/C:
                                    </Typography>
                                    <FormikTextField
                                      fullWidth
                                      variant="outlined"
                                      name="acuidadevisual_od_sc"
                                    />
                                  </Grid>
                                  <Grid item lg={3}>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        margin: "1rem 1rem 0rem",
                                        fontSize: "15px",
                                      }}
                                    >
                                      C/C:
                                    </Typography>
                                    <FormikTextField
                                      fullWidth
                                      variant="outlined"
                                      name="acuidadevisual_od_cc"
                                    />
                                  </Grid>
                                  <Grid item lg={3}>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        margin: "1rem 1rem 0rem",
                                        fontSize: "15px",
                                      }}
                                    >
                                      C/C Lente:
                                    </Typography>
                                    <FormikTextField
                                      fullWidth
                                      variant="outlined"
                                      name="acuidadevisual_od_cc_lente"
                                    />
                                  </Grid>
                                </Grid>
                                <Grid
                                  container
                                  style={{ marginTop: match ? 24 : "auto" }}
                                  spacing={1}
                                  direction={match ? "column" : "row"}
                                >
                                  <Grid
                                    item
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Typography style={{ fontWeight: "700" }}>
                                      OE
                                    </Typography>
                                  </Grid>
                                  <Grid item lg={3}>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        margin: "1rem 1rem 0rem",
                                        fontSize: "15px",
                                      }}
                                    >
                                      S/C:
                                    </Typography>
                                    <FormikTextField
                                      fullWidth
                                      variant="outlined"
                                      name="acuidadevisual_oe_sc"
                                    />
                                  </Grid>
                                  <Grid item lg={3}>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        margin: "1rem 1rem 0rem",
                                        fontSize: "15px",
                                      }}
                                    >
                                      C/C:
                                    </Typography>
                                    <FormikTextField
                                      fullWidth
                                      variant="outlined"
                                      name="acuidadevisual_oe_cc"
                                    />
                                  </Grid>
                                  <Grid item lg={3}>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        margin: "1rem 1rem 0rem",
                                        fontSize: "15px",
                                      }}
                                    >
                                      C/C Lente:
                                    </Typography>
                                    <FormikTextField
                                      fullWidth
                                      variant="outlined"
                                      name="acuidadevisual_oe_cc_lente"
                                    />
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography
                          variant="body1"
                          style={{
                            fontSize: "13px",
                            fontWeight: "800",
                            textTransform: "uppercase",
                            color: "#00A1AA",
                          }}
                        >
                          Óculos em uso
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Card>
                              <CardContent>
                                <Grid
                                  container
                                  spacing={1}
                                  direction={match ? "column" : "row"}
                                >
                                  <Grid
                                    item
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Typography style={{ fontWeight: "700" }}>
                                      OD
                                    </Typography>
                                  </Grid>
                                  <Grid item lg={2}>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        margin: "1rem 1rem 0rem",
                                        fontSize: "15px",
                                      }}
                                    >
                                      Esf:
                                    </Typography>
                                    <FormikTextField
                                      fullWidth
                                      variant="outlined"
                                      name="oculosemuso_od_esf"
                                    />
                                  </Grid>
                                  <Grid item lg={2}>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        margin: "1rem 1rem 0rem",
                                        fontSize: "15px",
                                      }}
                                    >
                                      Cil:
                                    </Typography>
                                    <FormikTextField
                                      fullWidth
                                      variant="outlined"
                                      name="oculosemuso_od_cil"
                                    />
                                  </Grid>
                                  <Grid item lg={2}>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        margin: "1rem 1rem 0rem",
                                        fontSize: "15px",
                                      }}
                                    >
                                      Eixo:
                                    </Typography>
                                    <FormikTextField
                                      fullWidth
                                      variant="outlined"
                                      name="oculosemuso_od_eixo"
                                    />
                                  </Grid>
                                  <Grid item lg={3}>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        margin: "1rem 1rem 0rem",
                                        fontSize: "15px",
                                      }}
                                    >
                                      Adição:
                                    </Typography>
                                    <FormikTextField
                                      fullWidth
                                      variant="outlined"
                                      name="oculosemuso_adicao"
                                    />
                                  </Grid>
                                </Grid>
                                <Grid
                                  container
                                  style={{ marginTop: match ? 24 : "auto" }}
                                  spacing={1}
                                  direction={match ? "column" : "row"}
                                >
                                  <Grid
                                    item
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Typography style={{ fontWeight: "700" }}>
                                      OE
                                    </Typography>
                                  </Grid>
                                  <Grid item lg={2}>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        margin: "1rem 1rem 0rem",
                                        fontSize: "15px",
                                      }}
                                    >
                                      Esf:
                                    </Typography>
                                    <FormikTextField
                                      fullWidth
                                      variant="outlined"
                                      name="oculosemuso_oe_esf"
                                    />
                                  </Grid>
                                  <Grid item lg={2}>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        margin: "1rem 1rem 0rem",
                                        fontSize: "15px",
                                      }}
                                    >
                                      Cil:
                                    </Typography>
                                    <FormikTextField
                                      fullWidth
                                      variant="outlined"
                                      name="oculosemuso_oe_cil"
                                    />
                                  </Grid>
                                  <Grid item lg={2}>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        margin: "1rem 1rem 0rem",
                                        fontSize: "15px",
                                      }}
                                    >
                                      Eixo:
                                    </Typography>
                                    <FormikTextField
                                      fullWidth
                                      variant="outlined"
                                      name="oculosemuso_oe_eixo"
                                    />
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography
                          variant="body1"
                          style={{
                            fontSize: "13px",
                            fontWeight: "800",
                            textTransform: "uppercase",
                            color: "#00A1AA",
                          }}
                        >
                          Tonometria
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Card>
                              <CardContent>
                                <Grid container spacing={1}>
                                  <Grid
                                    item
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Typography style={{ fontWeight: "700" }}>
                                      OD
                                    </Typography>
                                  </Grid>
                                  <Grid item lg={6}>
                                    <FormikTextField
                                      fullWidth
                                      variant="outlined"
                                      name="tonometria_od"
                                      placeholder="mmHG"
                                    />
                                  </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                  <Grid
                                    item
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Typography style={{ fontWeight: "700" }}>
                                      OE
                                    </Typography>
                                  </Grid>
                                  <Grid item lg={6}>
                                    <FormikTextField
                                      fullWidth
                                      variant="outlined"
                                      name="tonometria_oe"
                                      placeholder="mmHG"
                                    />
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography
                          variant="body1"
                          style={{
                            fontSize: "13px",
                            fontWeight: "800",
                            textTransform: "uppercase",
                            color: "#00A1AA",
                          }}
                        >
                          AUTO-REFRAÇÃO DINÂMICA
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Card>
                              <CardContent>
                                <Grid
                                  container
                                  direction={match ? "column" : "row"}
                                  spacing={1}
                                >
                                  <Grid
                                    item
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Typography style={{ fontWeight: "700" }}>
                                      OD
                                    </Typography>
                                  </Grid>
                                  <Grid item lg={2}>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        margin: "1rem 1rem 0rem",
                                        fontSize: "15px",
                                      }}
                                    >
                                      Esf:
                                    </Typography>
                                    <FormikTextField
                                      fullWidth
                                      variant="outlined"
                                      name="autorefração_dinamica_od_esf"
                                    />
                                  </Grid>
                                  <Grid item lg={2}>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        margin: "1rem 1rem 0rem",
                                        fontSize: "15px",
                                      }}
                                    >
                                      Cil:
                                    </Typography>
                                    <FormikTextField
                                      fullWidth
                                      variant="outlined"
                                      name="autorefração_dinamica_od_cil"
                                    />
                                  </Grid>
                                  <Grid item lg={2}>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        margin: "1rem 1rem 0rem",
                                        fontSize: "15px",
                                      }}
                                    >
                                      Eixo:
                                    </Typography>
                                    <FormikTextField
                                      fullWidth
                                      variant="outlined"
                                      name="autorefração_dinamica_od_eixo"
                                    />
                                  </Grid>
                                  <Grid item lg={2}>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        margin: "1rem 1rem 0rem",
                                        fontSize: "15px",
                                      }}
                                    >
                                      AV:
                                    </Typography>
                                    <FormikTextField
                                      fullWidth
                                      variant="outlined"
                                      name="autorefração_dinamica_od_av"
                                    />
                                  </Grid>
                                </Grid>
                                <Grid
                                  container
                                  direction={match ? "column" : "row"}
                                  style={{ marginTop: match ? 24 : "auto" }}
                                  spacing={1}
                                >
                                  <Grid
                                    item
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Typography style={{ fontWeight: "700" }}>
                                      OE
                                    </Typography>
                                  </Grid>
                                  <Grid item lg={2}>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        margin: "1rem 1rem 0rem",
                                        fontSize: "15px",
                                      }}
                                    >
                                      Esf:
                                    </Typography>
                                    <FormikTextField
                                      fullWidth
                                      variant="outlined"
                                      name="autorefração_dinamica_oe_esf"
                                    />
                                  </Grid>
                                  <Grid item lg={2}>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        margin: "1rem 1rem 0rem",
                                        fontSize: "15px",
                                      }}
                                    >
                                      Cil:
                                    </Typography>
                                    <FormikTextField
                                      fullWidth
                                      variant="outlined"
                                      name="autorefração_dinamica_oe_cil"
                                    />
                                  </Grid>
                                  <Grid item lg={2}>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        margin: "1rem 1rem 0rem",
                                        fontSize: "15px",
                                      }}
                                    >
                                      Eixo:
                                    </Typography>
                                    <FormikTextField
                                      fullWidth
                                      variant="outlined"
                                      name="autorefração_dinamica_oe_eixo"
                                    />
                                  </Grid>
                                  <Grid item lg={2}>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        margin: "1rem 1rem 0rem",
                                        fontSize: "15px",
                                      }}
                                    >
                                      AV:
                                    </Typography>
                                    <FormikTextField
                                      fullWidth
                                      variant="outlined"
                                      name="autorefração_dinamica_oe_av"
                                    />
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography
                          variant="body1"
                          style={{
                            fontSize: "13px",
                            fontWeight: "800",
                            textTransform: "uppercase",
                            color: "#00A1AA",
                          }}
                        >
                          AUTO-REFRAÇÃO ESTÁTICA
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Card>
                              <CardContent>
                                <Grid
                                  container
                                  direction={match ? "column" : "row"}
                                  spacing={1}
                                >
                                  <Grid
                                    item
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Typography style={{ fontWeight: "700" }}>
                                      OD
                                    </Typography>
                                  </Grid>
                                  <Grid item lg={2}>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        margin: "1rem 1rem 0rem",
                                        fontSize: "15px",
                                      }}
                                    >
                                      Esf:
                                    </Typography>
                                    <FormikTextField
                                      fullWidth
                                      variant="outlined"
                                      name="autorefração_estatica_od_esf"
                                    />
                                  </Grid>
                                  <Grid item lg={2}>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        margin: "1rem 1rem 0rem",
                                        fontSize: "15px",
                                      }}
                                    >
                                      Cil:
                                    </Typography>
                                    <FormikTextField
                                      fullWidth
                                      variant="outlined"
                                      name="autorefração_estatica_od_cil"
                                    />
                                  </Grid>
                                  <Grid item lg={2}>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        margin: "1rem 1rem 0rem",
                                        fontSize: "15px",
                                      }}
                                    >
                                      Eixo:
                                    </Typography>
                                    <FormikTextField
                                      fullWidth
                                      variant="outlined"
                                      name="autorefração_estatica_od_eixo"
                                    />
                                  </Grid>
                                  <Grid item lg={2}>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        margin: "1rem 1rem 0rem",
                                        fontSize: "15px",
                                      }}
                                    >
                                      AV:
                                    </Typography>
                                    <FormikTextField
                                      fullWidth
                                      variant="outlined"
                                      name="autorefração_estatica_od_av"
                                    />
                                  </Grid>
                                </Grid>
                                <Grid
                                  container
                                  direction={match ? "column" : "row"}
                                  style={{ marginTop: match ? 24 : "auto" }}
                                  spacing={1}
                                >
                                  <Grid
                                    item
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Typography style={{ fontWeight: "700" }}>
                                      OE
                                    </Typography>
                                  </Grid>
                                  <Grid item lg={2}>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        margin: "1rem 1rem 0rem",
                                        fontSize: "15px",
                                      }}
                                    >
                                      Esf:
                                    </Typography>
                                    <FormikTextField
                                      fullWidth
                                      variant="outlined"
                                      name="autorefração_estatica_oe_esf"
                                    />
                                  </Grid>
                                  <Grid item lg={2}>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        margin: "1rem 1rem 0rem",
                                        fontSize: "15px",
                                      }}
                                    >
                                      Cil:
                                    </Typography>
                                    <FormikTextField
                                      fullWidth
                                      variant="outlined"
                                      name="autorefração_estatica_oe_cil"
                                    />
                                  </Grid>
                                  <Grid item lg={2}>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        margin: "1rem 1rem 0rem",
                                        fontSize: "15px",
                                      }}
                                    >
                                      Eixo:
                                    </Typography>
                                    <FormikTextField
                                      fullWidth
                                      variant="outlined"
                                      name="autorefração_estatica_oe_eixo"
                                    />
                                  </Grid>
                                  <Grid item lg={2}>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        margin: "1rem 1rem 0rem",
                                        fontSize: "15px",
                                      }}
                                    >
                                      AV:
                                    </Typography>
                                    <FormikTextField
                                      fullWidth
                                      variant="outlined"
                                      name="autorefração_estatica_oe_av"
                                    />
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography
                          variant="body1"
                          style={{
                            fontSize: "13px",
                            fontWeight: "800",
                            textTransform: "uppercase",
                            color: "#00A1AA",
                          }}
                        >
                          CERATOMETRIA
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Card>
                              <CardContent>
                                <Grid
                                  container
                                  direction={match ? "column" : "row"}
                                  spacing={1}
                                >
                                  <Grid
                                    item
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Typography style={{ fontWeight: "700" }}>
                                      OD
                                    </Typography>
                                  </Grid>
                                  <Grid item lg={2}>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        margin: "1rem 1rem 0rem",
                                        fontSize: "15px",
                                      }}
                                    >
                                      K1:
                                    </Typography>
                                    <FormikTextField
                                      fullWidth
                                      variant="outlined"
                                      name="ceratometria_od_k1"
                                    />
                                  </Grid>
                                  <Grid item lg={2}>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        margin: "1rem 1rem 0rem",
                                        fontSize: "15px",
                                      }}
                                    >
                                      K2:
                                    </Typography>
                                    <FormikTextField
                                      fullWidth
                                      variant="outlined"
                                      name="ceratometria_od_k2"
                                    />
                                  </Grid>
                                  <Grid item lg={2}>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        margin: "1rem 1rem 0rem",
                                        fontSize: "15px",
                                      }}
                                    >
                                      KM:
                                    </Typography>
                                    <FormikTextField
                                      fullWidth
                                      variant="outlined"
                                      name="ceratometria_od_km"
                                    />
                                  </Grid>
                                  <Grid item lg={2}>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        margin: "1rem 1rem 0rem",
                                        fontSize: "15px",
                                      }}
                                    >
                                      Eixo:
                                    </Typography>
                                    <FormikTextField
                                      fullWidth
                                      variant="outlined"
                                      name="ceratometria_od_eixo"
                                    />
                                  </Grid>
                                </Grid>
                                <Grid
                                  container
                                  direction={match ? "column" : "row"}
                                  style={{ marginTop: match ? 24 : "auto" }}
                                  spacing={1}
                                >
                                  <Grid
                                    item
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Typography style={{ fontWeight: "700" }}>
                                      OE
                                    </Typography>
                                  </Grid>
                                  <Grid item lg={2}>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        margin: "1rem 1rem 0rem",
                                        fontSize: "15px",
                                      }}
                                    >
                                      K1:
                                    </Typography>
                                    <FormikTextField
                                      fullWidth
                                      variant="outlined"
                                      name="ceratometria_oe_k1"
                                    />
                                  </Grid>
                                  <Grid item lg={2}>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        margin: "1rem 1rem 0rem",
                                        fontSize: "15px",
                                      }}
                                    >
                                      K2:
                                    </Typography>
                                    <FormikTextField
                                      fullWidth
                                      variant="outlined"
                                      name="ceratometria_oe_k2"
                                    />
                                  </Grid>
                                  <Grid item lg={2}>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        margin: "1rem 1rem 0rem",
                                        fontSize: "15px",
                                      }}
                                    >
                                      KM:
                                    </Typography>
                                    <FormikTextField
                                      fullWidth
                                      variant="outlined"
                                      name="ceratometria_oe_km"
                                    />
                                  </Grid>
                                  <Grid item lg={2}>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        margin: "1rem 1rem 0rem",
                                        fontSize: "15px",
                                      }}
                                    >
                                      Eixo:
                                    </Typography>
                                    <FormikTextField
                                      fullWidth
                                      variant="outlined"
                                      name="ceratometria_oe_eixo"
                                    />
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography
                          variant="body1"
                          style={{
                            fontSize: "13px",
                            fontWeight: "800",
                            textTransform: "uppercase",
                            color: "#00A1AA",
                          }}
                        >
                          Antecedentes familiares
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container>
                          <Grid item xs={12} md={4}>
                            <FormikSwitchField
                              style={{ margin: "1rem 1rem 0rem" }}
                              name="tabagismo"
                              color="primary"
                              label="Tabagismo"
                            />
                            <Grid
                              style={{
                                display: `${
                                  props.values.tabagismo ? "initial" : "none"
                                }`,
                              }}
                            >
                              <FormikTextField
                                fullWidth
                                style={{ padding: "10px" }}
                                variant="outlined"
                                name="tabagismo_detalhe"
                                type="text"
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormikSwitchField
                              style={{ margin: "1rem 1rem 0rem" }}
                              name="glaucoma"
                              color="primary"
                              label="Glaucoma"
                            />
                            <Grid
                              style={{
                                display: `${
                                  props.values.glaucoma ? "initial" : "none"
                                }`,
                              }}
                            >
                              <FormikTextField
                                fullWidth
                                style={{ padding: "10px" }}
                                variant="outlined"
                                name="glaucoma_detalhe"
                                type="text"
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormikSwitchField
                              style={{ margin: "1rem 1rem 0rem" }}
                              name="colesterol_alto"
                              color="primary"
                              label="Colesterol alto"
                            />
                            <Grid
                              style={{
                                display: `${
                                  props.values.colesterol_alto
                                    ? "initial"
                                    : "none"
                                }`,
                              }}
                            >
                              <FormikTextField
                                fullWidth
                                style={{ padding: "10px" }}
                                variant="outlined"
                                name="colesterol_detalhe"
                                type="text"
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormikSwitchField
                              style={{ margin: "1rem 1rem 0rem" }}
                              name="cardiopatia"
                              color="primary"
                              label="Cardiopatia"
                            />
                            <Grid
                              style={{
                                display: `${
                                  props.values.cardiopatia ? "initial" : "none"
                                }`,
                              }}
                            >
                              <FormikTextField
                                fullWidth
                                style={{ padding: "10px" }}
                                variant="outlined"
                                name="cardiopatia_detalhe"
                                type="text"
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormikSwitchField
                              style={{ margin: "1rem 1rem 0rem" }}
                              name="dmri"
                              color="primary"
                              label="DMRI"
                            />
                            <Grid
                              style={{
                                display: `${
                                  props.values.dmri ? "initial" : "none"
                                }`,
                              }}
                            >
                              <FormikTextField
                                fullWidth
                                style={{ padding: "10px" }}
                                variant="outlined"
                                name="dmri_detalhe"
                                type="text"
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormikSwitchField
                              style={{ margin: "1rem 1rem 0rem" }}
                              name="ceratocone"
                              color="primary"
                              label="Ceratocone"
                            />
                            <Grid
                              style={{
                                display: `${
                                  props.values.ceratocone ? "initial" : "none"
                                }`,
                              }}
                            >
                              <FormikTextField
                                fullWidth
                                style={{ padding: "10px" }}
                                variant="outlined"
                                name="ceratocone_detalhe"
                                type="text"
                              />
                            </Grid>
                          </Grid>

                          <Typography
                            variant="body1"
                            style={{
                              margin: "1rem 1rem 0rem",
                              fontSize: "15px",
                              fontWeight: "800",
                              color: "#00A1AA",
                            }}
                          >
                            Outros Antecedentes:
                          </Typography>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <FormikTextField
                                style={{ padding: "10px" }}
                                fullWidth
                                variant="outlined"
                                name="outros_antecedentes"
                              />
                            </Grid>
                          </Grid>

                          <Typography
                            variant="body1"
                            style={{
                              margin: "1rem 1rem 0rem",
                              fontSize: "15px",
                              fontWeight: "800",
                              color: "#00A1AA",
                            }}
                          >
                            Medicações em uso:
                          </Typography>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <FormikTextField
                                fullWidth
                                style={{ padding: "10px" }}
                                variant="outlined"
                                name="medicamentos_em_uso"
                              />
                            </Grid>
                          </Grid>

                          <Typography
                            variant="body1"
                            style={{
                              margin: "1rem 1rem 0rem",
                              fontSize: "15px",
                              fontWeight: "800",
                              color: "#00A1AA",
                            }}
                          >
                            Cirurgias prévias:
                          </Typography>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <FormikTextField
                                fullWidth
                                style={{ padding: "10px" }}
                                variant="outlined"
                                name="cirurgias_previas"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>

                  <Grid item xs={12}>
                    <Card
                      variant="outlined"
                      style={{
                        height: "100%",
                      }}
                    >
                      <CardContent>
                        <Typography
                          variant="body1"
                          style={{
                            margin: "1rem 1rem 0rem",
                            fontSize: "15px",
                            fontWeight: "800",
                            color: "#00A1AA",
                          }}
                        >
                          Observações
                        </Typography>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <FormikTextField
                              fullWidth
                              variant="outlined"
                              name="observacoes"
                              multiline
                              rows={4}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  style={{
                    marginTop: 24,
                    marginBottom: 48,
                    justifyContent: "flex-end",
                  }}
                >
                  {currentPreAppointment?.status_triagem === true &&
                    currentPreAppointment?.status === "finalizado" && (
                      <Typography
                        variant="body1"
                        style={{
                          margin: "1rem 1rem 0rem",
                          fontSize: "15px",
                          color: "red",
                        }}
                      >
                        Pré-consulta finallizada
                      </Typography>
                    )}
                  {currentPreAppointment?.status_triagem === false && (
                    <Typography
                      variant="body1"
                      style={{
                        margin: "1rem 1rem 0rem",
                        fontSize: "15px",
                        color: "red",
                      }}
                    >
                      Realize a triagem novamente
                    </Typography>
                  )}
                  <Grid item>
                    {/* <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={isCreatingMedicalRecord}
                  >
                    {currentPreAppointment
                      ? `Atualizar pré-consulta`
                      : `Finalizar pré-consulta`}
                  </Button> */}

                    {/* <Button
                      disabled={
                        currentPreAppointment?.status_triagem === true &&
                        currentPreAppointment?.status === "finalizado"
                      }
                      onClick={() => {
                        updateStatusPreAppointmentBtn();
                      }}
                      color="primary"
                      variant="contained"
                    >
                      Finalizar
                    </Button> */}
                  </Grid>
                </Grid>
              </FormikForm>
            )}
          </Formik>
        )}
      </FullWidthContainer>
    </Dialog>
  );
};

export default PreAppointmentModal;
