import React from "react";
import { TableCell, TableRow } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { v4 as uuidv4 } from "uuid";

interface IProps {
  numberOfColumns: number;
}

const SkeletonTable = ({ numberOfColumns }: IProps) => {
  const mockArray = [1, 2, 3, 4, 5, 6, 7];
  const newArray = new Array(numberOfColumns).fill(0);

  return (
    <>
      {mockArray.map((i) => (
        <TableRow
          key={uuidv4()}
          style={{ alignItems: "center", justifyContent: "space-between" }}
        >
          {newArray.map((i) => (
            <TableCell key={uuidv4()}>
              <Skeleton variant="text" />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default SkeletonTable;
