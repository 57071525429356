import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { IFilter } from "models/shared/index";
import { toast } from "react-toastify";
import { AppThunk } from "store/index";
import api from "utils/API";
import { queryStringFromFilterArray } from "utils/network";
import toastOptions from "utils/toastOptions";
import { ICombos } from "models/Combos";

interface IInitialState {
  isFetchingReport: boolean;
  combosFilterArray: IFilter[];
  combos: ICombos | null;
}

const initialState: IInitialState = {
  isFetchingReport: false,
  combosFilterArray: [
    { key: "idclinica", value: [] },
    { key: "start_date", value: null },
    { key: "end_date", value: null },
    { key: "idcolaborador", value: [] },
    { key: "idcombo", value: [] },
    { key: "poragendamento", value: false },
  ],
  combos : null,
};

const reportsReducer = createSlice({
  name: "ReportsComboReducer",
  initialState,
  reducers: {
    setIsFetchingReport: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingReport = payload;
    },
    clearFilter: (state, { payload }: PayloadAction<IFilter[]>) => {
      state.combosFilterArray = payload;
    },
    setCombos: (
      state,
      {
        payload: { combos },
      }: PayloadAction<{
        combos: any;
      }>
    ) => {
      state.combos = combos;
    },
    updateCombosFilterArray: (
      state,
      { payload: { key, value } }: PayloadAction<IFilter>
    ) => {
        state.combosFilterArray = state.combosFilterArray.map(
        (filter) => {
          if (filter.key === key) {
            filter.value = value;
            state.combos = null
          }
          return filter;
      });
    },
  },
});

export const clearCombos = (): AppThunk => async (dispatch, getState) => {
  const { clearFilter, setCombos } = reportsReducer.actions;
  dispatch(
    clearFilter([
      { key: "idclinica", value: [] },
      { key: "start_date", value: null },
      { key: "end_date", value: null },
      { key: "idcolaborador", value: [] },
      { key: "idcombo", value: [] },
      { key: "poragendamento", value: false },
    ])
  );
  dispatch(
    setCombos({
      combos: {
        combosResponse: {
          summary: {
            main_summary: {
              amount_billed: 0,
              cost: 0,
              margin: 0,
              qt_combos: 0,
            },
            summary_data: [],
          },
          collaborators_combos: {
            collaborators_summary: {
              amount_billed: 0,
              cost: 0,
              margin: 0,
              qt_collaborators: 0,
              qt_combos: 0,
              qt_patients: {
                new_patients: 0,
                recurrent_patients: 0,
                total_patients: 0,
              },
            },
            collaborators_data: [],
          },
          patients_combos: [],
        },
      },
    })
  );
};

export const fetchCombos = (): AppThunk => async (dispatch, getState) => {
  const { setIsFetchingReport, setCombos } = reportsReducer.actions;
  const state = getState();
  const { combosFilterArray } = state.reportsCombos;
  dispatch(setIsFetchingReport(true));

  const queryParameters = queryStringFromFilterArray(combosFilterArray);
  try {
    const response = await api.get(
      `api/reports/combosreport${queryParameters}`
    );
    dispatch(setCombos({ combos: response.data.data.combos }));
    dispatch(setIsFetchingReport(false));
  } catch (error: AxiosError | any) {
    dispatch(setIsFetchingReport(false));
    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);
    } else {
      console.log(error.message);
    }
  }
};

export const { setIsFetchingReport, updateCombosFilterArray } =
  reportsReducer.actions;

export default reportsReducer.reducer;
