import { IFilter } from "models/shared/index";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, rootState } from "store/index";
import { toast } from "react-toastify";
import toastOptions from "utils/toastOptions";
import api, { apiNf } from "utils/API";
import { history } from "utils/history";
import { queryStringFromFilterArray } from "utils/network";
import {
  IServiceOrder,
  IServiceOrderForm,
  IServiceOrderList,
} from "models/ServiceOrder";
import dayjs from "dayjs";
import { IServiceOrderPatientForm } from "models/ServiceOrder/ServiceOrderPatient";
import { IServiceOrderServiceItem } from "models/ServiceOrder/ServiceOrderServiceItem";
import {
  finalizeReceivedPaymentNFSe,
  initializeReceivedPaymentNFSe,
} from "../ReportsReducer";
import { IClinicNfse } from "models/Clinics";
import { cityOptions } from "utils/cityOptions";
import { ICheckinObject } from "pages/ServiceOrders/Components/ServiceOrderAccordion/CheckinSemAgendamento";
import { useSelector } from "react-redux";

export interface ISelectOption {
  label: string;
  value: string;
}

interface IInitialState {
  isFetchingServiceOrder: boolean;
  isFetchingSingleServiceOrder: boolean;
  isFetchingNFServiceOrder: boolean;
  isProcessingNfseDialog: boolean;
  isCreatingOrUpdatingServiceOrder: boolean;
  isDeletingServiceOrder: boolean;
  isCancelingOrderItem: boolean;
  isCheckingPermissionServiceOrder: boolean;
  customerQuotePatientToFilterFor: string | null;
  isFlowSchedule: boolean;
  currentServiceOrder: IServiceOrder | null;
  isUnderAge: boolean;
  isFinancialResponsible: boolean;
  isFinishingServiceOrder: boolean;
  serviceOrders: IServiceOrderList[] | null;
  serviceOrdersFilterArray: IFilter[];
  page: number;
  total: number;
  isUpdatingServiceOrderPatient: boolean;
  selectedItemsToCheckin: IServiceOrderServiceItem[];
  selectedItemsToRemoveCheckin: IServiceOrderServiceItem[];
  isOpenPrintDialog: boolean;
  itemsToShow: {
    nome: string;
    categoriaservico: {
      nome: string;
    };
  }[];
  professionalToCheckinWith: ISelectOption;
  isItemsDialogOpen: boolean;
  modalForwardingGuide: boolean;
  osToClose: IServiceOrderList[] | null;
  modalForwardingGuideSchedule: boolean;
  itemModalForwardingGuideSchedule: ICheckinObject | null;
  itemModalForwardingGuideScheduledServices: IServiceOrder | null;
}

const initialState: IInitialState = {
  isFetchingServiceOrder: false,
  isFetchingSingleServiceOrder: false,
  isProcessingNfseDialog: false,
  isFetchingNFServiceOrder: false,
  isCreatingOrUpdatingServiceOrder: false,
  isDeletingServiceOrder: false,
  isCancelingOrderItem: false,
  isCheckingPermissionServiceOrder: false,
  customerQuotePatientToFilterFor: null,
  currentServiceOrder: null,
  isUnderAge: false,
  isFinancialResponsible: true,
  isFinishingServiceOrder: false,
  serviceOrders: null,
  serviceOrdersFilterArray: [
    // { key: "limit", value: 6 },
    { key: "status", value: null },
    { key: "nomepaciente", value: [] },
    { key: "nomeusuario", value: [] },
    { key: "codigo", value: [] },
    { key: "start_date", value: dayjs(new Date()).format("YYYY-MM-DD") },
    { key: "end_date", value: dayjs(new Date()).format("YYYY-MM-DD") },
    { key: "by_payment_date", value: false },
    // { key: "page", value: 1 },
  ],
  page: 0,
  total: 0,
  isUpdatingServiceOrderPatient: false,
  selectedItemsToCheckin: [],
  selectedItemsToRemoveCheckin: [],
  isOpenPrintDialog: false,
  modalForwardingGuide: false,
  itemsToShow: [
    {
      nome: "",
      categoriaservico: {
        nome: "",
      },
    },
  ],
  professionalToCheckinWith: {
    label: "",
    value: "",
  },
  isItemsDialogOpen: false,
  osToClose: null,
  isFlowSchedule: false,
  modalForwardingGuideSchedule: false,
  itemModalForwardingGuideSchedule: null,
  itemModalForwardingGuideScheduledServices: null,
};

const serviceOrdersReducer = createSlice({
  name: "serviceOrdersReducer",
  initialState,
  reducers: {
    setModalForwardingGuideSchedule: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.modalForwardingGuideSchedule = payload;
    },
    setItemModalForwardingGuideSchedule: (
      state,
      { payload }: PayloadAction<ICheckinObject>
    ) => {
      state.itemModalForwardingGuideSchedule = payload;
    },
    setItemModalForwardingGuideScheduleScheduledServices: (
      state,
      { payload }: PayloadAction<IServiceOrder>
    ) => {
      state.itemModalForwardingGuideScheduledServices = payload;
    },
    setIsFlowSchedule: (state, { payload }: PayloadAction<boolean>) => {
      state.isFlowSchedule = payload;
    },
    setModalForwardingGuide: (state, { payload }: PayloadAction<boolean>) => {
      state.modalForwardingGuide = payload;
    },
    setIsFetchingServiceOrder: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingServiceOrder = payload;
    },
    setIsFetchingSingleServiceOrder: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingSingleServiceOrder = payload;
    },
    setIsProcessingNfseDialog: (state, { payload }: PayloadAction<boolean>) => {
      state.isProcessingNfseDialog = payload;
    },
    setIsFetchingNFServiceOrder: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingNFServiceOrder = payload;
    },
    setIsDeletingServiceOrder: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeletingServiceOrder = payload;
    },
    setIsCancelingOrderItem: (state, { payload }: PayloadAction<boolean>) => {
      state.isCancelingOrderItem = payload;
    },
    setIsCreatingOrUpdatingServiceOrder: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isCreatingOrUpdatingServiceOrder = payload;
    },
    setIsCheckingPermissionServiceOrder: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isCheckingPermissionServiceOrder = payload;
    },
    setCustomerQuotePatientToFilterFor: (
      state,
      { payload }: PayloadAction<string | null>
    ) => {
      state.customerQuotePatientToFilterFor = payload;
    },
    setCurrentServiceOrder: (
      state,
      { payload }: PayloadAction<IServiceOrder | null>
    ) => {
      state.currentServiceOrder = payload;
      if (payload) {
        if (
          payload.serviceOrder.paciente &&
          payload.serviceOrder.paciente.datanascimento
        ) {
          const datanascimento = payload.serviceOrder.paciente.datanascimento;
          const today = dayjs();
          const age = today.diff(dayjs(datanascimento).toDate(), "year", true);
          state.isUnderAge = age < 18;
        }
      }
    },
    setIsUnderAge: (state, { payload }: PayloadAction<boolean>) => {
      state.isUnderAge = payload;
    },
    setIsFinancialResponsible: (state, { payload }: PayloadAction<boolean>) => {
      state.isFinancialResponsible = payload;
    },
    setIsFinishingServiceOrder: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFinishingServiceOrder = payload;
    },
    setServiceOrders: (
      state,
      {
        payload: { service_orders, page, total },
      }: PayloadAction<{
        service_orders: IServiceOrderList[];
        total: number;
        page: number;
      }>
    ) => {
      state.total = total;
      state.page = page;
      state.serviceOrders = service_orders;
    },
    updateServiceOrdersFilterArray: (
      state,
      { payload: { key, value } }: PayloadAction<IFilter>
    ) => {
      state.serviceOrdersFilterArray = state.serviceOrdersFilterArray.map(
        (filter) => {
          if (filter.key === key) {
            filter.value = value;
          }
          return filter;
        }
      );
    },
    onUpdateServiceOrderPatientStart: (state) => {
      state.isUpdatingServiceOrderPatient = true;
    },
    onUpdateServiceOrderPatientSuccess: (state) => {
      state.isUpdatingServiceOrderPatient = false;
    },
    setSelectedItemsToCheckin: (
      state,
      { payload }: PayloadAction<IServiceOrderServiceItem[]>
    ) => {
      state.selectedItemsToCheckin = payload;
    },
    setSelectedItemsToRemoveCheckin: (
      state,
      { payload }: PayloadAction<IServiceOrderServiceItem[]>
    ) => {
      state.selectedItemsToRemoveCheckin = payload;
    },
    setIsOpenPrintDialog: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpenPrintDialog = payload;
    },
    setItemsToShow: (state, { payload }: PayloadAction<any>) => {
      state.itemsToShow = payload;
    },
    setProfessionalToCheckinWith: (
      state,
      { payload }: PayloadAction<ISelectOption>
    ) => {
      state.professionalToCheckinWith = payload;
    },
    setIsItemsDialogOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isItemsDialogOpen = payload;
    },
    setOsToClose: (
      state,
      { payload }: PayloadAction<IServiceOrderList[] | null>
    ) => {
      state.osToClose = payload;
    },
    clearServiceOrdersFilterArray: (
      state,
      { payload }: PayloadAction<IFilter[]>
    ) => {
      state.serviceOrdersFilterArray = payload;
    },
  },
});

export const resetCurrentServiceOrder =
  (): AppThunk => async (dispatch, getState) => {
    const { setCurrentServiceOrder } = serviceOrdersReducer.actions;
    dispatch(setCurrentServiceOrder(null));
  };

export const fetchServiceOrders =
  ({ page = 1, limit = 6 }: { page?: number; limit?: number }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingServiceOrder, setServiceOrders } =
      serviceOrdersReducer.actions;
    dispatch(setIsFetchingServiceOrder(true));
    const state = getState();
    const { serviceOrdersFilterArray } = state.serviceOrders;
    const queryParameters = queryStringFromFilterArray(
      serviceOrdersFilterArray
    );
    const pageAndLimit =
      queryParameters.length === 0
        ? `?page=${page}&limit=${limit}`
        : `&page=${page}&limit=${limit}`;
    try {
      const response = await api.get(
        `/api/serviceorders${queryParameters}${pageAndLimit}`
      );
      dispatch(setServiceOrders(response.data.data));
      dispatch(setIsFetchingServiceOrder(false));
    } catch (error: any) {
      dispatch(setIsFetchingServiceOrder(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchServiceOrdersByOsCode =
  ({ oscodes, cb }: { oscodes: string; cb?: () => void }): AppThunk =>
  async (dispatch) => {
    const { setIsFetchingServiceOrder, setOsToClose } =
      serviceOrdersReducer.actions;

    try {
      dispatch(setIsFetchingServiceOrder(true));

      const response = await api.get(
        "/api/serviceorders/code?oscodes=" + oscodes
      );

      dispatch(setOsToClose(response.data.data));
      dispatch(setIsFetchingServiceOrder(false));
      if (cb) {
        cb();
      }
    } catch (error: any) {
      dispatch(setIsFetchingServiceOrder(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchServiceOrderById =
  (idordemdeservico: string | null, callback?: Function): AppThunk =>
  async (dispatch) => {
    const { setIsFetchingSingleServiceOrder, setCurrentServiceOrder } =
      serviceOrdersReducer.actions;
    dispatch(setIsFetchingSingleServiceOrder(true));
    try {
      if (!idordemdeservico) {
        toast.error("Identificador de OS não disponível. Tente novamente.", toastOptions);
        dispatch(setIsFetchingSingleServiceOrder(false));
        return;
      }

      const response = await api.get(`/api/serviceorders/${idordemdeservico}`);
      dispatch(setCurrentServiceOrder(response.data.data));

      if (callback && response.data.data.serviceOrder.status === "quitado") {
        callback();
      }
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setIsFetchingSingleServiceOrder(false));
    }
  };

export const fetchServiceOrderByIdGuide =
  (idordemdeservico: string | null, callback?: Function): AppThunk =>
  async (dispatch) => {
    const { setCurrentServiceOrder } =
      serviceOrdersReducer.actions;
    try {
      const response = await api.get(`/api/serviceorders/${idordemdeservico}`);
      dispatch(setCurrentServiceOrder(response.data.data));

      if (callback && response.data.data.serviceOrder.status === "quitado") {
        callback();
      }
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } 
  };

export const forwardServiceOrder =
  ({
    idordemdeservico,
    callback,
  }: {
    idordemdeservico: string;
    callback?: Function;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsFinishingServiceOrder } = serviceOrdersReducer.actions;
    dispatch(setIsFinishingServiceOrder(true));
    try {
      await api.patch(`/api/service-order/${idordemdeservico}/forward`);
      dispatch(setIsFinishingServiceOrder(false));
      dispatch(fetchServiceOrderById(idordemdeservico));
      if (callback) {
        callback();
      }
    } catch (error: any) {
      dispatch(setIsFinishingServiceOrder(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const handleGenerateNFServiceOrder =
  ({
    data,
    clinicanfse,
    idusuario,
    cb,
  }: {
    data: IServiceOrder;
    idusuario: string;
    clinicanfse?: IClinicNfse;
    cb?: () => void;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsFetchingNFServiceOrder, setIsProcessingNfseDialog } =
      serviceOrdersReducer.actions;

    const id = toast.loading("A nota fiscal está sendo gerada... Aguarde!");
    let isError = false;
    dispatch(setIsFetchingNFServiceOrder(true));
    if (!clinicanfse) {
      dispatch(setIsFetchingNFServiceOrder(false));
      return toast.info(
        "CONFIGURAÇÃO DE NOTA FISCAL NÃO REALIZADA PARA A CLÍNICA NOME"
      );
    }

    if (data.serviceOrder.paciente === undefined) {
      dispatch(setIsFetchingNFServiceOrder(false));

      return toast.error(
        "O paciente não foi informado para a geração da Nota Fiscal"
      );
    }

    const cidade = data.serviceOrder.paciente.nomecidade
      ? data.serviceOrder.paciente.nomecidade
          .toUpperCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
      : data.serviceOrder.clinica?.cidade
          .toUpperCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");

    const codigoCidade = cityOptions.find(
      (city) => city.nome_da_cidade === cidade
    );

    if (!codigoCidade) {
      setIsProcessingNfseDialog(false);
      return toast.info("Endereço não encontrado. Verifique e tente novamente");
    }

    const desc_servicos = data.serviceOrder.paciente.cpfresponsavel
      ? `Procedimentos médicos paciente (${data.serviceOrder.paciente.nomepaciente}) - Solidário financeiro (habilitado): ${data.serviceOrder.paciente.nomeresponsavel}`
      : `Procedimentos médicos paciente (${data.serviceOrder.paciente.nomepaciente})`;

    const endereco =
      data.serviceOrder.paciente.logradouro && data.serviceOrder.paciente.numero
        ? `${data.serviceOrder.paciente.logradouro}, ${data.serviceOrder.paciente.numero}`
        : `${data.serviceOrder.clinica?.logradouro}, ${data.serviceOrder.clinica?.numero}`;
    const bairro = data.serviceOrder.paciente.bairro
      ? data.serviceOrder.paciente.bairro
      : data.serviceOrder.clinica?.bairro;
    const estado = data.serviceOrder.paciente.siglaestado
      ? data.serviceOrder.paciente.siglaestado
      : data.serviceOrder.clinica?.estado;
    const cep = data.serviceOrder.paciente.cep
      ? data.serviceOrder.paciente.cep
      : data.serviceOrder.clinica?.cep;

    dispatch(
      initializeReceivedPaymentNFSe({
        idordemdeservico: data.serviceOrder.idordemdeservico,
        status: "encaminhado",
        idusuario,
        emitidaos: true,
      })
    );
    dispatch(setIsProcessingNfseDialog(true));

    try {
      const res = await apiNf.post("/api/nfse", {
        pf_pj: "cpf",
        nome: data.serviceOrder.paciente.nomeresponsavel
          ? data.serviceOrder.paciente.nomeresponsavel
          : data.serviceOrder.paciente.nomepaciente,
        email: data.serviceOrder.paciente.email,
        cpf: data.serviceOrder.paciente.cpfresponsavel
          ? data.serviceOrder.paciente.cpfresponsavel
          : data.serviceOrder.paciente.cpf,
        endereco: "Rua Jary, 740/502A",
        bairro: "Passo da Areia",
        cidade: "8801", // codigoCidade.codigo_do_IGBE,
        estado: "RS",
        cep: "91350170",
        desc_servicos,
        valor_servicos: data.serviceOrder.valoratual
          .toString()
          .replaceAll(".", ","),
        idclinica_sistema: clinicanfse.idclinica,
        ...clinicanfse,
      });

      if (!res.data) {
        toast.update(id, {
          render: "Não foi possível gerar a Nota Fiscal. Tente novamente!",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
      }

      if (res.data.data.status === "sucesso") {
        dispatch(
          finalizeReceivedPaymentNFSe({
            idordemdeservico: data.serviceOrder.idordemdeservico,
            link_nfse_2: res.data.data.link_2_via,
            link_nfse_xml: res.data.data.link_xml,
          })
        );
        toast.update(id, {
          render: "A Nota Fiscal foi gerada com sucesso!",
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
      } else {
        toast.update(id, {
          render: res.data.data.cod_verificacao || "Erro ao gerar Nota Fiscal",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        dispatch(setIsProcessingNfseDialog(false));
        dispatch(
          initializeReceivedPaymentNFSe({
            idordemdeservico: data.serviceOrder.idordemdeservico,
            status: "pendente",
            idusuario,
            emitidaos: true,
          })
        );
      }
      dispatch(setIsFetchingNFServiceOrder(false));
      dispatch(setIsProcessingNfseDialog(false));
      if (cb) cb();
    } catch (error: any) {
      dispatch(setIsFetchingNFServiceOrder(false));
      dispatch(setIsProcessingNfseDialog(false));
      dispatch(
        initializeReceivedPaymentNFSe({
          idordemdeservico: data.serviceOrder.idordemdeservico,
          status: "pendente",
          idusuario,
          emitidaos: true,
        })
      );
      if (error.response) {
        toast.update(id, {
          render: error.response.data?.error?.message,
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
      } else {
        console.log(error.message);
      }
    }
  };

export const finishServiceOrder =
  ({
    idordemdeservico,
    callback,
  }: {
    idordemdeservico: string;
    callback?: Function;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsFinishingServiceOrder } = serviceOrdersReducer.actions;

    dispatch(setIsFinishingServiceOrder(true));
    try {
      await api.patch(`/api/service-order/${idordemdeservico}/pay`);
      toast.success("Ordem de serviço finalizada", toastOptions);
      dispatch(setIsFinishingServiceOrder(false));
      dispatch(fetchServiceOrderById(idordemdeservico));
      if (callback) {
        callback();
      }
    } catch (error: any) {
      dispatch(setIsFinishingServiceOrder(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
      return error;
    }
  };

export const revertPaymentFromServiceOrder =
  ({
    idordemdeservico,
    email,
    password,
    motivo,
    callback,
  }: {
    idordemdeservico: string;
    email: string;
    password: string;
    motivo: string;
    callback?: Function;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsFinishingServiceOrder } = serviceOrdersReducer.actions;

    dispatch(setIsFinishingServiceOrder(true));
    try {
      await api.delete(`/api/service-order/${idordemdeservico}/pay`, {
        data: { email, password, motivo },
      });
      toast.success("Status quitado revertido com sucesso.", toastOptions);
      dispatch(setIsFinishingServiceOrder(false));
      dispatch(fetchServiceOrderById(idordemdeservico));
      if (callback) {
        callback();
      }
    } catch (error: any) {
      dispatch(setIsFinishingServiceOrder(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
      return error;
    }
  };

export const createServiceOrder =
  (payload: IServiceOrderForm, cb?: Function): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingOrUpdatingServiceOrder } =
      serviceOrdersReducer.actions;
    dispatch(setIsCreatingOrUpdatingServiceOrder(true));
    try {
      const result = await api.post("/api/serviceorders", payload);
      toast.success("Ordem de serviço cadastrada com sucesso", toastOptions);
      dispatch(
        setServiceOrders({
          service_orders: [],
          total: 0,
          page: 0,
        })
      );

      if (cb) {
        cb(result.data.data.idordemdeservico);
      } else {
        setTimeout(() => {
          history.replace("/service-orders");
        }, 1000);
      }
      dispatch(setIsCreatingOrUpdatingServiceOrder(false));
    } catch (error: any) {
      dispatch(setIsCreatingOrUpdatingServiceOrder(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };
export const clearListServiceOrder = (): AppThunk => (dispatch) => {
  const { clearServiceOrdersFilterArray } = serviceOrdersReducer.actions;
  dispatch(
    clearServiceOrdersFilterArray([
      { key: "status", value: null },
      { key: "nomepaciente", value: [] },
      { key: "nomeusuario", value: [] },
      { key: "codigo", value: [] },
      { key: "start_date", value: dayjs(new Date()).format("YYYY-MM-DD") },
      { key: "end_date", value: dayjs(new Date()).format("YYYY-MM-DD") },
      { key: "by_payment_date", value: false },
    ])
  );
};

export const updateServiceOrder =
  ({
    serviceorder_id,
    data,
  }: {
    serviceorder_id: string;
    data: any;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsFetchingServiceOrder } = serviceOrdersReducer.actions;
    dispatch(setIsFetchingServiceOrder(true));
    try {
      await api.put(`/api/serviceorders/${serviceorder_id}`, data);
      toast.success("Ordem de serviço editada com sucesso", toastOptions);
      dispatch(fetchServiceOrderById(serviceorder_id));
      dispatch(setIsFetchingServiceOrder(false));
    } catch (error: any) {
      dispatch(setIsFetchingServiceOrder(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const linkCustomerQuoteToServiceOrder =
  ({
    customerQuoteId,
    serviceOrderId,
    callback,
  }: {
    customerQuoteId: string;
    serviceOrderId: string;
    callback?: Function;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingOrUpdatingServiceOrder } =
      serviceOrdersReducer.actions;
    dispatch(setIsCreatingOrUpdatingServiceOrder(true));
    try {
      await api.get(
        `/api/customerquotes-into-order/${customerQuoteId}/${serviceOrderId}`
      );
      dispatch(setIsCreatingOrUpdatingServiceOrder(false));
      toast.success("Orçamento vinculado", toastOptions);
      dispatch(fetchServiceOrderById(serviceOrderId));
      if (callback) {
        callback();
      }
    } catch (error: any) {
      dispatch(setIsCreatingOrUpdatingServiceOrder(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const LinkServicePackageToServiceOrder =
  ({
    servicePackageId,
    serviceOrderId,
    callback,
  }: {
    servicePackageId: string;
    serviceOrderId: string;
    callback?: Function;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingOrUpdatingServiceOrder } =
      serviceOrdersReducer.actions;
    dispatch(setIsCreatingOrUpdatingServiceOrder(true));
    try {
      await api.get(
        `/api/combos-into-order/${servicePackageId}/${serviceOrderId}`
      );
      toast.success("Combo vinculado", toastOptions);
      dispatch(fetchServiceOrderById(serviceOrderId));
      if (callback) {
        callback();
      }
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setIsCreatingOrUpdatingServiceOrder(false));
    }
  };

export const addItemToServiceOrder =
  ({
    iditemservico,
    idcategoria,
    //idprofissional,
    serviceOrderId,
    callback,
  }: {
    iditemservico: string;
    idcategoria: string;
    //idprofissional: string;
    serviceOrderId: string;
    callback?: Function;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingOrUpdatingServiceOrder } =
      serviceOrdersReducer.actions;
    dispatch(setIsCreatingOrUpdatingServiceOrder(true));
    const quantidade = 1;
    try {
      await api.post(`/api/service-order/${serviceOrderId}/item/add`, {
        iditemservico,
        idcategoria,
        //idprofissional,
        quantidade,
      });
      dispatch(setIsCreatingOrUpdatingServiceOrder(false));
      toast.success("Item adicionado", toastOptions);
      dispatch(fetchServiceOrderById(serviceOrderId));
      if (callback) {
        callback();
      }
    } catch (error: any) {
      dispatch(setIsCreatingOrUpdatingServiceOrder(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const removeItemFromServiceOrder =
  ({
    serviceItemId,
    serviceOrderId,
    callback,
  }: {
    serviceItemId: string;
    serviceOrderId: string;
    callback?: Function;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingOrUpdatingServiceOrder } =
      serviceOrdersReducer.actions;
    dispatch(setIsCreatingOrUpdatingServiceOrder(true));
    try {
      await api.delete(
        `/api/service-order/${serviceOrderId}/item/${serviceItemId}/remove`
      );
      dispatch(setIsCreatingOrUpdatingServiceOrder(false));
      toast.success("Item removido", toastOptions);
      dispatch(fetchServiceOrderById(serviceOrderId));
      if (callback) {
        callback();
      }
    } catch (error: any) {
      dispatch(setIsCreatingOrUpdatingServiceOrder(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const addPatientBalanceToServiceOrder =
  (
    {
      serviceOrderId,
      valorsaldopaciente,
    }: {
      serviceOrderId: string;
      valorsaldopaciente: Number;
    },
    callback?: Function
  ): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingOrUpdatingServiceOrder } =
      serviceOrdersReducer.actions;
    dispatch(setIsCreatingOrUpdatingServiceOrder(true));
    try {
      const data: any = {
        valorsaldopaciente,
      };
      await api.patch(
        `/api/service-order/${serviceOrderId}/patient-balance`,
        data
      );
      dispatch(setIsCreatingOrUpdatingServiceOrder(false));
      toast.success("Saldo do paciente adicionado", toastOptions);
      dispatch(fetchServiceOrderById(serviceOrderId));
      if (callback) {
        callback();
      }
    } catch (error: any) {
      dispatch(setIsCreatingOrUpdatingServiceOrder(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const addPaymentMethodToServiceOrder =
  (
    {
      paymentMethodId,
      formapagamento,
      valor,
      troco,
      numeroparcelas,
      serviceOrderId,
      documento,
      credito,
      bandeira,
      cartao,
    }: {
      paymentMethodId: string;
      formapagamento: string;
      valor: number;
      troco: number;
      numeroparcelas: number;
      serviceOrderId: string;
      documento: string;
      credito: boolean;
      bandeira: string;
      cartao: boolean;
    },
    callback?: Function
  ): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingOrUpdatingServiceOrder } =
      serviceOrdersReducer.actions;
    dispatch(setIsCreatingOrUpdatingServiceOrder(true));
    try {
      const data: any = {
        idmetodopagamento: paymentMethodId,
        formapagamento,
        valor,
        troco,
        numeroparcelas,
        documento,
        credito,
        bandeira,
        cartao,
      };
      await api.patch(
        `/api/service-order/${serviceOrderId}/payment-method`,
        data
      );
      toast.success("Método adicionado", toastOptions);
      dispatch(fetchServiceOrderById(serviceOrderId));
      if (callback) {
        callback();
      }
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setIsCreatingOrUpdatingServiceOrder(false));
    }
  };

export const removePaymentMethodFromServiceOrder =
  ({
    paymentMethodId,
    serviceOrderId,
    document,
    callback,
  }: {
    paymentMethodId: string;
    serviceOrderId: string;
    document: string;
    callback?: Function;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingOrUpdatingServiceOrder } =
      serviceOrdersReducer.actions;
    dispatch(setIsCreatingOrUpdatingServiceOrder(true));
    try {
      await api.delete(
        `/api/service-order/${serviceOrderId}/payment-method/${paymentMethodId}`,
        {
          data: {
            document,
          },
        }
      );
      dispatch(setIsCreatingOrUpdatingServiceOrder(false));
      toast.success("Método removido", toastOptions);
      dispatch(fetchServiceOrderById(serviceOrderId));
      if (callback) {
        callback();
      }
    } catch (error: any) {
      dispatch(setIsCreatingOrUpdatingServiceOrder(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const cancelServiceOrder =
  ({
    serviceOrderId,
    email,
    password,
    motivocancelamento,
    valorsaldopaciente,
    callback,
  }: {
    serviceOrderId: string;
    email: string;
    password: string;
    motivocancelamento: string;
    valorsaldopaciente?: number;
    callback?: Function;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsDeletingServiceOrder } = serviceOrdersReducer.actions;
    dispatch(setIsDeletingServiceOrder(true));
    try {
      await api.delete(`/api/serviceorders/${serviceOrderId}`, {
        data: { email, password, motivocancelamento, valorsaldopaciente },
      });
      dispatch(setIsDeletingServiceOrder(false));
      toast.success("Ordem de serviço cancelada", toastOptions);
      if (callback) {
        callback();
      }
    } catch (error: any) {
      dispatch(setIsDeletingServiceOrder(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const cancelOrderItem =
  ({
    orderItemIds,
    email,
    password,
    motivocancelamento,
    atribuirsaldo,
    callback,
  }: {
    orderItemIds: string | string[];
    email: string;
    password: string;
    motivocancelamento: string;
    atribuirsaldo: boolean;
    callback?: Function;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsCancelingOrderItem } = serviceOrdersReducer.actions;

    dispatch(setIsCancelingOrderItem(true));

    try {
      await api.delete(`/api/service-order/item/cancel`, {
        data: {
          email,
          password,
          motivocancelamento,
          orderItemIds,
          atribuirsaldo,
        },
      });

      toast.success("Item da ordem cancelado", toastOptions);

      if (callback) {
        callback();
      }
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setIsCancelingOrderItem(false));
    }
  };

export const approveSettleServiceOrder =
  ({
    serviceOrderId,
    email,
    password,
    motivo,
    callback,
  }: {
    serviceOrderId: string;
    email: string;
    password: string;
    motivo?: string;
    callback?: Function;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsCheckingPermissionServiceOrder } =
      serviceOrdersReducer.actions;
    dispatch(setIsCheckingPermissionServiceOrder(true));
    try {
      await api.post(`/api/serviceorders/${serviceOrderId}/settler-approval`, {
        email,
        password,
        motivo,
      });

      dispatch(setIsCheckingPermissionServiceOrder(false));
      toast.success("Permissão concedida", toastOptions);
      if (callback) {
        callback();
      }
    } catch (error: any) {
      dispatch(setIsCheckingPermissionServiceOrder(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setIsCheckingPermissionServiceOrder(true));
    }
  };

export const updateServiceOrderPatient =
  ({
    patientData,
    patientId,
    callback,
  }: {
    patientData: IServiceOrderPatientForm;
    patientId: string;
    callback?: Function;
  }): AppThunk =>
  async (dispatch) => {
    const {
      onUpdateServiceOrderPatientStart,
      onUpdateServiceOrderPatientSuccess,
    } = serviceOrdersReducer.actions;

    dispatch(onUpdateServiceOrderPatientStart());
    try {
      await api.put(`/api/patients/${patientId}`, patientData);
      dispatch(onUpdateServiceOrderPatientSuccess());
      toast.success("Usuário atualizado", toastOptions);
      if (callback) {
        callback();
      }
    } catch (error: any) {
      dispatch(onUpdateServiceOrderPatientSuccess());
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const {
  updateServiceOrdersFilterArray,
  setIsUnderAge,
  setIsFinancialResponsible,
  setCustomerQuotePatientToFilterFor,
  setServiceOrders,
  setSelectedItemsToCheckin,
  setSelectedItemsToRemoveCheckin,
  setIsOpenPrintDialog,
  setItemsToShow,
  setProfessionalToCheckinWith,
  setIsItemsDialogOpen,
  setIsProcessingNfseDialog,
  setOsToClose,
  setModalForwardingGuide,
  setIsFlowSchedule,
  setItemModalForwardingGuideSchedule,
  setModalForwardingGuideSchedule,
  setItemModalForwardingGuideScheduleScheduledServices,
} = serviceOrdersReducer.actions;

export default serviceOrdersReducer.reducer;
