import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { IFilter } from "models/shared";
import { toast } from "react-toastify";
import { AppThunk } from "store";
import api from "utils/API";
import { queryStringFromFilterArray } from "utils/network";
import toastOptions from "utils/toastOptions";
import {
  ReleaseMedicalRecords,
  ReleaseMedicalRecordsReducerState,
  ReleaseMedicalRecordsResponse,
} from "./ReleaseMedicalRecords.types";

const initialState: ReleaseMedicalRecordsReducerState = {
  releaseMedicalRecords: [],
  isFetchingReleaseMedicalRecords: false,
  page: 1,
  total: 0,
  limit: 6,
  filterArray: [
    { key: "nomepaciente", value: null },
    {
      key: "start_date",
      value: dayjs(new Date()).format("YYYY-MM-DD").toString(),
    },
    {
      key: "end_date",
      value: dayjs(new Date()).format("YYYY-MM-DD").toString(),
    },
    { key: "idprofissional", value: null },
    { key: "idclinica", value: null },
    { key: "preconsulta", value: null },
  ],
  updateReleaseMedicalRecordsFecthIntervalId: -1,
};

const releaseMedicalRecordsSlice = createSlice({
  name: "releaseMedicalRecords",
  initialState,
  reducers: {
    setReleaseMedicalRecords: (
      state,
      { payload }: PayloadAction<ReleaseMedicalRecords[]>
    ) => {
      state.releaseMedicalRecords = payload;
    },
    setIsFetchingReleaseMedicalRecords: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingReleaseMedicalRecords = payload;
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setTotal: (state, { payload }: PayloadAction<number>) => {
      state.total = payload;
    },
    setLimit: (state, { payload }: PayloadAction<number>) => {
      state.limit = payload;
    },
    updateFilter: (state, { payload }: PayloadAction<IFilter>) => {
      const index = state.filterArray.findIndex(
        (item) => item.key === payload.key
      );
      if (index === -1) {
        state.filterArray.push({ key: payload.key, value: payload.value });
      } else {
        state.filterArray[index].value = payload.value;
      }
    },
    setUpdateReleaseMedicalRecordsFecthIntervalId: (
      state,
      { payload }: PayloadAction<number>
    ) => {
      state.updateReleaseMedicalRecordsFecthIntervalId = payload;
    },
  },
});

export const fetchReleaseMedicalRecords =
  ({ page = 1, limit = 6 }: { page?: number; limit?: number }): AppThunk =>
  async (dispatch, getState) => {
    const {
      setReleaseMedicalRecords,
      setIsFetchingReleaseMedicalRecords,
      setPage,
      setTotal,
    } = releaseMedicalRecordsSlice.actions;
    const state = getState();
    const { filterArray } = state.releaseMedicalRecords;
    const queryParameters = queryStringFromFilterArray(filterArray);
    const pageAndLimit =
      queryParameters.length === 0
        ? `?page=${page}&limit=${limit}`
        : `&page=${page}&limit=${limit}`;

    dispatch(setIsFetchingReleaseMedicalRecords(true));

    try {
      const { data }: ReleaseMedicalRecordsResponse = await api.get(
        `/api/professional/office/patients${queryParameters}${pageAndLimit}`
      );

      dispatch(setReleaseMedicalRecords(data.data.attendanceNumber));
      dispatch(setPage(data.data.page));
      dispatch(setTotal(data.data.total));
    } catch (error: any) {
      console.log(error);
      if (error.response) {
        toast.error(error.response.data.message, toastOptions);
      }
    } finally {
      dispatch(setIsFetchingReleaseMedicalRecords(false));
    }
  };

export const fetchMedicalRecordsPreApps =
  ({ page = 1, limit = 6 }: { page?: number; limit?: number }): AppThunk =>
  async (dispatch, getState) => {
    const {
      setReleaseMedicalRecords,
      setIsFetchingReleaseMedicalRecords,
      setPage,
      setTotal,
    } = releaseMedicalRecordsSlice.actions;
    const state = getState();
    const { filterArray } = state.releaseMedicalRecords;
    const queryParameters = queryStringFromFilterArray(filterArray);
    const pageAndLimit =
      queryParameters.length === 0
        ? `?page=${page}&limit=${limit}`
        : `&page=${page}&limit=${limit}`;

    dispatch(setIsFetchingReleaseMedicalRecords(true));

    try {
      const { data }: ReleaseMedicalRecordsResponse = await api.get(
        `/api/professional/office/patients-pre-appointments${queryParameters}${pageAndLimit}`
      );

      dispatch(setReleaseMedicalRecords(data.data.attendanceNumber));
      dispatch(setPage(data.data.page));
      dispatch(setTotal(data.data.total));
    } catch (error: any) {
      console.log(error);
      if (error.response) {
        toast.error(error.response.data.message, toastOptions);
      }
    } finally {
      dispatch(setIsFetchingReleaseMedicalRecords(false));
    }
  };

export const fetchReleaseMedicalRecordsWithoutLoading =
  ({ page = 1, limit = 6 }: { page?: number; limit?: number }): AppThunk =>
  async (dispatch, getState) => {
    const { setReleaseMedicalRecords, setPage, setTotal } =
      releaseMedicalRecordsSlice.actions;
    const state = getState();
    const { filterArray } = state.releaseMedicalRecords;
    const queryParameters = queryStringFromFilterArray(filterArray);
    const pageAndLimit =
      queryParameters.length === 0
        ? `?page=${page}&limit=${limit}`
        : `&page=${page}&limit=${limit}`;

    try {
      const { data }: ReleaseMedicalRecordsResponse = await api.get(
        `api/professional/patient-history${queryParameters}${pageAndLimit}`
      );
      dispatch(setReleaseMedicalRecords(data.data.attendanceNumber));
      dispatch(setPage(data.data.page));
      dispatch(setTotal(data.data.total));
    } catch (error: any) {
      console.log(error);
      if (error.response) {
        toast.error(error.response.data.message, toastOptions);
      }
    }
  };

export const clearUpdateReleaseMedicalRecordsFecthInterval =
  (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const { updateReleaseMedicalRecordsFecthIntervalId } =
      state.releaseMedicalRecords;

    if (updateReleaseMedicalRecordsFecthIntervalId) {
      clearInterval(updateReleaseMedicalRecordsFecthIntervalId);
    }
  };

export const startUpdateReleaseMedicalRecordsFecthInterval =
  ({ page, limit }: { page: number; limit: number }): AppThunk =>
  async (dispatch, getState) => {
    const { setUpdateReleaseMedicalRecordsFecthIntervalId } =
      releaseMedicalRecordsSlice.actions;

    const state = getState();
    const { updateReleaseMedicalRecordsFecthIntervalId } =
      state.releaseMedicalRecords;
    if (updateReleaseMedicalRecordsFecthIntervalId) {
      clearInterval(updateReleaseMedicalRecordsFecthIntervalId);
    }

    var _tid = setInterval(() => {
      const location = window.location.href.split("/");
      const inSchedulePage = location[location.length - 1].includes("sala");

      if (inSchedulePage) {
        dispatch(fetchReleaseMedicalRecordsWithoutLoading({ page, limit }));
      } else {
        dispatch(clearUpdateReleaseMedicalRecordsFecthInterval());
      }
    }, 10000);

    dispatch(setUpdateReleaseMedicalRecordsFecthIntervalId(_tid));
  };

export const { setLimit, setPage, updateFilter } =
  releaseMedicalRecordsSlice.actions;

export default releaseMedicalRecordsSlice.reducer;
