import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import dayjs from "dayjs";
import { formatCurrency } from "utils/formatCurrency";
import Header from "components/Pdf/header";

export interface ITable {
  key: string;
  id: string;
  alignment: string;
  label: string;
  typeData?: string;
  formatDate?: string;
  style?: any;
}

export type OrientationType = "portrait" | "landscape";

interface IProps {
  table: ITable[];
  data: any[];
  title?: string;
  orientation?: OrientationType;
  headerFontSize?: number;
  bodyFontSize?: number;
}
Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});
Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});
const style = StyleSheet.create({
  body: {
    paddingBottom: 35,
    paddingTop: 20,
    paddingHorizontal: 35,
  },
  table: {
    display: "flex",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#e5e5e5",
    marginBottom: 20,
    fontFamily: "Open Sans",
    fontSize: 10,
    boxShadow: "0px 0px 5px #ccc",
  },
  tableHeader: {
    backgroundColor: "#00A1A9",
    color: "#ffffff",
    fontSize: 9,
    fontWeight: "bold",
    borderBottomWidth: 1,
    borderBottomColor: "#e5e5e5",
    paddingVertical: 4,
    paddingHorizontal: 1,
    margin: 0,
  },
  tableRow: {
    flexDirection: "row",
    backgroundColor: "#ffffff",
    display: "flex",
    alignItems: "center",
  },
  tableRowOdd: {
    backgroundColor: "#f5f5f5",
  },
  tableColHeader: {
    width: "20%",
    borderRightWidth: 1,
    borderRightColor: "#e5e5e5",
    textAlign: "center",
  },
  tableCol: {
    width: "20%",
    borderRightWidth: 1,
    borderRightColor: "#e5e5e5",
    paddingVertical: 4,
    paddingHorizontal: 1,
    textAlign: "center",
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 20,
    color: "#008080",
  },
  footer: {
    position: "absolute",
    bottom: 5,
    right: 5,
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "right",
  },
});
const Pdf = ({
  table,
  data,
  title,
  headerFontSize = 12,
  bodyFontSize = 10,
  orientation = "portrait",
}: IProps) => {
  function typeData(
    cell: ITable,
    row: Record<string, string | number | boolean>
  ) {
    const splitKey = cell.key.split(".");

    let value: any = row;
    for (const key of splitKey) {
      if (value.hasOwnProperty(key)) {
        value = value[key];
      } else {
        value = null;
        break;
      }
    }

    const data = value ?? "";
    if (cell.typeData) {
      if (
        cell.typeData === "date" &&
        (typeof data === "string" || typeof data === "number")
      ) {
        if (cell.formatDate) {
          return (
            <Text style={style.tableCol}>
              {data ? dayjs(data).format(cell.formatDate) : "-"}
            </Text>
          );
        }
        return (
          <Text style={style.tableCol}>
            {dayjs(data).format("DD/MM/YYYY HH:mm")}
          </Text>
        );
      }
      if (cell.typeData === "money") {
        return (
          <Text style={style.tableCol}>{formatCurrency(Number(data))}</Text>
        );
      }
      if (cell.typeData === "percentage") {
        return (
          <Text style={style.tableCol}>{`${(Number(data) * 100).toFixed(
            2
          )}%`}</Text>
        );
      }
    }
    return <Text style={[style.tableCol, { ...cell.style }]}>{data}</Text>;
  }

  style.tableHeader.fontSize = headerFontSize;
  style.table.fontSize = bodyFontSize;

  return (
    <Document>
      <Page size="A4" orientation={orientation} style={style.body}>
        <Header></Header>
        <Text style={style.title}>{title ? title : ""}</Text>
        <View style={style.table}>
          <View style={[style.tableRow, style.tableHeader]} fixed>
            {table.map((el) => {
              return (
                <Text style={[style.tableColHeader, { ...el.style }]}>
                  {el.label}
                </Text>
              );
            })}
          </View>

          {data.map(
            (row: Record<string, string | number | boolean>, index: number) => {
              return (
                <View
                  style={{
                    ...style.tableRow,
                    backgroundColor: `${index % 2 ? "#f5f5f5" : "#fff"}`,
                  }}
                >
                  {table.map((cell: ITable) => {
                    return typeData(cell, row);
                  })}
                </View>
              );
            }
          )}
        </View>
        <Text
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          style={style.footer}
          fixed
        />
      </Page>
    </Document>
  );
};

export default Pdf;
