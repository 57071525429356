import React from "react";
import { FullWidthContainer } from "components/layout/base";
import { Typography } from "@material-ui/core";

import * as S from "./styles";

const SigninBanner = () => {
  return (
    <FullWidthContainer noPadding>
      <S.BannerWrapper>
        <S.WelcomeWrapper className="wrapper welcome-wrapper">
          <Typography className="subtitle" variant="h4">
            Bem-vindo(a) ao
          </Typography>
          <Typography className="title" variant="h1">
            Painel
          </Typography>
          <Typography className="title" variant="h1">
            Administrativo
          </Typography>
        </S.WelcomeWrapper>

        <S.LogoWrapper className="wrapper">
          <Typography></Typography>
        </S.LogoWrapper>
      </S.BannerWrapper>
    </FullWidthContainer>
  );
};

export default SigninBanner;
