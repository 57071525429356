import styled from "styled-components";
import backgroundImg from "assets/signin_background.svg";

export const BannerWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: url(${backgroundImg}) no-repeat center center;
  background-size: cover;

  .wrapper {
    margin-left: 60px;
  }

  .welcome-wrapper {
    margin-top: 100px;
  }

  @media only screen and (max-width: 480px) {
    .subtitle {
      font-size: 18px;
    }
    .title {
      font-size: 48px;
    }
    .wrapper {
      margin-left: 30px;
    }
  }
`;

export const WelcomeWrapper = styled.div`
  .title,
  .subtitle {
    color: #fff;
  }
  .title {
    font-weight: bold;
  }
`;

export const LogoWrapper = styled.div`
  margin-top: auto;
`;
