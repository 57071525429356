import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "store/index";
import { toast } from "react-toastify";
import toastOptions from "utils/toastOptions";
import api from "utils/API";
import { ITransaction, ITransactionForm } from "models/Transaction";
import { history } from "utils/history";
import { IFilter } from "models/shared";
import { queryStringFromFilterArray } from "utils/network";
import { fetchProfessionalsReports } from "../ProfessionalsReportsReducer";

interface IInitialState {
  isFetchingTransactions: boolean;
  isCreatingOrUpdatingTransaction: boolean;
  isDeletingTransaction: boolean;
  transactions: null | ITransaction[];
  page: number;
  total: number;
  currentTransaction: null | ITransaction;
  transactionsFilterArray: IFilter[];
}

const initialState: IInitialState = {
  isFetchingTransactions: false,
  isCreatingOrUpdatingTransaction: false,
  isDeletingTransaction: false,
  transactions: null,
  page: 0,
  total: 0,
  currentTransaction: null,
  transactionsFilterArray: [
    { key: "operacao", value: null },
    { key: "idusuario", value: null },
    { key: "efetivado", value: null },
  ],
};

const transactionsSlice = createSlice({
  name: "transactionsSlice",
  initialState,
  reducers: {
    setIsCreatingOrUpdatingTransaction: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isCreatingOrUpdatingTransaction = payload;
    },
    setIsFetchingTransactions: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingTransactions = payload;
    },
    setIsDeletingTransactions: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeletingTransaction = payload;
    },
    setTransactions: (
      state,
      {
        payload: { transactions, page, total },
      }: PayloadAction<{
        transactions: ITransaction[];
        page: number;
        total: number;
      }>
    ) => {
      state.page = page;
      state.transactions = transactions;
      state.total = total;
    },
    setCurrentTransaction: (
      state,
      { payload }: PayloadAction<ITransaction>
    ) => {
      state.currentTransaction = payload;
    },
    clearCurrentTransaction: (state) => {
      state.currentTransaction = null;
    },
    updateTransactionsFilter: (state, { payload }: PayloadAction<IFilter>) => {
      const index = state.transactionsFilterArray.findIndex(
        (item) => item.key === payload.key
      );
      state.transactionsFilterArray[index].value = payload.value;
    },
  },
});

export const fetchTransactions =
  ({ page = 1, limit = 6 }: { page?: number; limit?: number }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingTransactions, setTransactions } =
      transactionsSlice.actions;
    try {
      const state = getState();
      const { transactionsFilterArray } = state.transactions;
      const queryParameters = queryStringFromFilterArray(
        transactionsFilterArray
      );
      const pageAndLimit =
        queryParameters.length === 0
          ? `?page=${page}&limit=${limit}`
          : `&page=${page}&limit=${limit}`;
      dispatch(setIsFetchingTransactions(true));
      const response = await api.get(
        `/api/transactions${queryParameters}${pageAndLimit}`
      );
      dispatch(setTransactions(response.data.data));
      dispatch(setIsFetchingTransactions(false));
    } catch (error: any) {
      dispatch(setIsFetchingTransactions(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchTransactionById =
  (transactionId: string): AppThunk =>
  async (dispatch) => {
    const { setIsFetchingTransactions, setCurrentTransaction } =
      transactionsSlice.actions;
    try {
      dispatch(setIsFetchingTransactions(true));
      const response = await api.get(`/api/transactions/${transactionId}`);
      dispatch(setCurrentTransaction(response.data.data));
      dispatch(setIsFetchingTransactions(false));
    } catch (error: any) {
      dispatch(setIsFetchingTransactions(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const createTransaction =
  (transaction: ITransactionForm): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingOrUpdatingTransaction } = transactionsSlice.actions;
    dispatch(setIsCreatingOrUpdatingTransaction(true));
    try {
      await api.post(`/api/transactions`, transaction);
      dispatch(setIsCreatingOrUpdatingTransaction(false));
      history.goBack();
      toast.success("Transação cadastrada", toastOptions);
    } catch (error: any) {
      dispatch(setIsCreatingOrUpdatingTransaction(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const updateTransaction =
  ({
    transactionId,
    transactionData,
  }: {
    transactionData: ITransactionForm;
    transactionId: string;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingOrUpdatingTransaction } = transactionsSlice.actions;
    dispatch(setIsCreatingOrUpdatingTransaction(true));
    try {
      await api.put(`/api/transactions/${transactionId}`, transactionData);
      dispatch(setIsCreatingOrUpdatingTransaction(false));
      history.goBack();
      toast.success("Transação atualizada", toastOptions);
    } catch (error: any) {
      dispatch(setIsCreatingOrUpdatingTransaction(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const updateTransactionPaymentClearing =
  ({
    transactionId,
    efetivado,
    callback,
  }: {
    efetivado: boolean;
    transactionId: string;
    callback?: Function;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingOrUpdatingTransaction } = transactionsSlice.actions;
    dispatch(setIsCreatingOrUpdatingTransaction(true));
    try {
      await api.put(`/api/transactions/${transactionId}`, {
        efetivado: efetivado,
      });
      dispatch(setIsCreatingOrUpdatingTransaction(false));
      // history.replace("/financeiro/transacoes");
      toast.success("Pagamento compensado", toastOptions);
      if (callback) callback();
    } catch (error: any) {
      dispatch(setIsCreatingOrUpdatingTransaction(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const clearMultipleTransactions =
  ({
    transactionsToBeCleared,
    datapagamento,
    accountOption,
    idmetodopagamento,
    discountValue,
    additionValue,
    docNumber,
    callback,
  }: {
    transactionsToBeCleared: string[];
    datapagamento?: Date;
    accountOption?: string;
    idmetodopagamento?: string;
    discountValue?: number;
    additionValue?: number;
    docNumber?: string;
    callback?: Function;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingOrUpdatingTransaction } = transactionsSlice.actions;
    dispatch(setIsCreatingOrUpdatingTransaction(true));
    try {
      await api.post(`/api/transactions/effectuate`, {
        idtransacoes: transactionsToBeCleared,
        datapagamento,
        accountOption,
        idmetodopagamento,
        discountValue,
        additionValue,
        docNumber,
      });
      dispatch(setIsCreatingOrUpdatingTransaction(false));
      toast.success("Pagamentos compensados", toastOptions);
      if (callback) callback();
    } catch (error: any) {
      dispatch(setIsCreatingOrUpdatingTransaction(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const clearMultipleHealthPlanTransactions =
  ({
    transactionsToBeCleared,
    datapagamento,
    accountOption,
    idmetodopagamento,
    discountValue,
    additionValue,
    docNumber,
    callback,
  }: {
    transactionsToBeCleared: string[];
    datapagamento?: Date;
    accountOption?: string;
    idmetodopagamento?: string;
    discountValue?: number;
    additionValue?: number;
    docNumber?: string;
    callback?: Function;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingOrUpdatingTransaction } = transactionsSlice.actions;
    dispatch(setIsCreatingOrUpdatingTransaction(true));
    try {
      await api.post(`/api/transactions/effectuate-health-plan`, {
        idtransacoes: transactionsToBeCleared,
        datapagamento,
        accountOption,
        idmetodopagamento,
        discountValue,
        additionValue,
        docNumber,
      });
      dispatch(setIsCreatingOrUpdatingTransaction(false));
      toast.success("Faturamentos compensados", toastOptions);
      if (callback) callback();
    } catch (error: any) {
      dispatch(setIsCreatingOrUpdatingTransaction(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const reprogrammingToReceiveTransaction =
  ({
    transactionsToBeCleared,
    datapagamento,
    accountOption,
    idmetodopagamento,
    differenceValue,
    docNumber,
    callback,
  }: {
    transactionsToBeCleared: string[];
    datapagamento?: Date;
    accountOption?: string;
    idmetodopagamento?: string;
    differenceValue?: number;
    docNumber?: string;
    callback?: Function;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingOrUpdatingTransaction } = transactionsSlice.actions;
    dispatch(setIsCreatingOrUpdatingTransaction(true));
    try {
      await api.post(`/api/transactions/effectuate/billstoreceive`, {
        idtransacoes: transactionsToBeCleared,
        datapagamento,
        accountOption,
        idmetodopagamento,
        differenceValue,
        docNumber,
      });
      dispatch(setIsCreatingOrUpdatingTransaction(false));
      toast.success("Faturamentos reprogramados", toastOptions);
      if (callback) callback();
    } catch (error: any) {
      dispatch(setIsCreatingOrUpdatingTransaction(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const reprogrammingTransaction =
  ({
    idtransacoes,
    datapagamento,
    accountOption,
    idmetodopagamento,
    docNumber,
  }: {
    idtransacoes: string[];
    datapagamento: Date;
    accountOption: string;
    idmetodopagamento: string;
    docNumber: string;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingOrUpdatingTransaction } = transactionsSlice.actions;
    dispatch(setIsCreatingOrUpdatingTransaction(true));
    try {
      await api.post(`/api/transactions/effectuate/billstopay`, {
        idtransacoes,
        datapagamento,
        accountOption,
        idmetodopagamento,
        docNumber,
      });
      toast.success("Pagamentos reprogramados", toastOptions);
      dispatch(fetchProfessionalsReports({ limit: 9999 }));
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setIsCreatingOrUpdatingTransaction(false));
    }
  };

export const extortMultipleTransactions =
  ({
    transactionsToBeExtorted,
    callback,
  }: {
    transactionsToBeExtorted: string[];
    callback?: Function;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingOrUpdatingTransaction } = transactionsSlice.actions;
    dispatch(setIsCreatingOrUpdatingTransaction(true));
    try {
      await api.post(`/api/transaction/reverse`, {
        idtransacoes: transactionsToBeExtorted,
      });
      dispatch(setIsCreatingOrUpdatingTransaction(false));
      toast.success("Pagamentos estornados", toastOptions);
      if (callback) callback();
    } catch (error: any) {
      dispatch(setIsCreatingOrUpdatingTransaction(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const generateTransactionsGuideBatch =
  ({
    healthPlanGuides,
    callback,
  }: {
    healthPlanGuides: {
      health_plan_category_id: string;
      consultation_guide_ids: string[];
      sp_sadt_guide_ids: string[];
    }[],
    callback?: Function;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingOrUpdatingTransaction } = transactionsSlice.actions;

    dispatch(setIsCreatingOrUpdatingTransaction(true));

    try {
      await api.post(`/api/healthplans/guidebatch`, { healthPlanGuides });
      
      toast.success("Lote de guias gerado", toastOptions);

      if (callback) callback();
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setIsCreatingOrUpdatingTransaction(false));
    }
  };

export const removeTransactionsGuideBatch =
  ({
    healthPlanGuides,
    callback,
  }: {
    healthPlanGuides: {
      consultation_guide_ids: string[];
      sp_sadt_guide_ids: string[];
    },
    callback?: Function;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingOrUpdatingTransaction } = transactionsSlice.actions;

    dispatch(setIsCreatingOrUpdatingTransaction(true));

    try {
      await api.delete(`/api/healthplans/guidebatch/removeguides`, { data: healthPlanGuides });
      
      toast.success("Guias removidas do lote", toastOptions);

      if (callback) callback();
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setIsCreatingOrUpdatingTransaction(false));
    }
  };

// export const updateTransactionStatus = ({
//   newCashDeskStatus,
//   transactionId,
// }: {
//   newCashDeskStatus: boolean;
//   transactionId: string;
// }): AppThunk => async (dispatch) => {
//   const { setIsCreatingOrUpdatingTransaction } = transactionsSlice.actions;
//   dispatch(setIsCreatingOrUpdatingTransaction(true));
//   try {
//     await api.put(`/api/transactions/${transactionId}`, {
//       ativo: newCashDeskStatus,
//     });
//     dispatch(setIsCreatingOrUpdatingTransaction(false));
//     dispatch(fetchTransactions({}));
//     toast.success(newCashDeskStatus ? "Caixa aberto" : "Caixa fechado", toastOptions);
//   } catch (error: any) {
//     dispatch(setIsCreatingOrUpdatingTransaction(false));
//     if (error.response) {
//       toast.error(error.response.data?.error?.message, toastOptions);
//     } else {
//       console.log(error.message);
//     }
//   }
// };

export const deleteTransaction =
  ({
    transactionId,
    callback,
  }: {
    transactionId: string;
    callback?: Function;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsDeletingTransactions } = transactionsSlice.actions;
    dispatch(setIsDeletingTransactions(true));
    try {
      await api.delete(`/api/transactions/${transactionId}`);
      dispatch(setIsDeletingTransactions(false));
      dispatch(fetchTransactions({}));
      toast.success("Transação excluida", toastOptions);
      if (callback) callback();
    } catch (error: any) {
      dispatch(setIsDeletingTransactions(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const { updateTransactionsFilter } = transactionsSlice.actions;

export default transactionsSlice.reducer;
