import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICounter } from "models/Counter";
import { IServicePassword } from "models/ServicePassword";
import { IWindowSet } from "models/WindowSet";
import { toast } from "react-toastify";
import { AppThunk } from "store";
import api from "utils/API";
import toastOptions from "utils/toastOptions";
import { ICallPatient } from "./types";

const initialState: ICallPatient = {
  isModalOpen: false,
  isOrder: true,
  ticketOffice: null,
  windowSet: null,
  tickets: [],
  currentServicePassword: null,
  lastPassword: [],
  servicePasswords: null,
  serviceAbsentPasswords: null,
  isOpen: false,
  isUpdating: false,
  isLoading: false,
};

const callPatientSlice = createSlice({
  name: "callPatient",
  initialState,
  reducers: {
    setLastPassword: (state, { payload }: PayloadAction<string[]> ) => {
      state.lastPassword = payload;
    },
    setIsModalOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isModalOpen = payload;
    },
    setIsOrder: (state, { payload }: PayloadAction<boolean>) => {
      state.isOrder = payload;
    },
    setTicketOffice: (state, { payload }: PayloadAction<ICounter | null>) => {
      state.ticketOffice = payload;
    },
    setWindowSet: (state, { payload }: PayloadAction<IWindowSet | null>) => {
      state.windowSet = payload;
    },
    setCurrentServicePassword: (
      state,
      { payload }: PayloadAction<IServicePassword | null>
    ) => {
      state.currentServicePassword = payload;
    },
    setServicePasswords: (
      state,
      { payload }: PayloadAction<IServicePassword[] | null>
    ) => {
      state.servicePasswords = payload;
    },
    setServiceAbsentPasswords: (
      state,
      { payload }: PayloadAction<IServicePassword[] | null>
    ) => {
      state.serviceAbsentPasswords = payload;
    },
    setIsOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpen = payload;
    },
    setIsUpdating: (state, { payload }: PayloadAction<boolean>) => {
      state.isUpdating = payload;
    },
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setTickets: (
      state,
      { payload }: PayloadAction<{ ticket: string; serviceType: string }[]>
    ) => {
      state.tickets = payload;
    },
  },
});

export const openCounter =
  ({
    isOpen,
    counterId,
    cb,
    idusuario
  }: {
    isOpen: boolean;
    counterId: string | number;
    cb?: () => void;
    idusuario: string;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsOpen, setIsUpdating } = callPatientSlice.actions;
    dispatch(setIsUpdating(true));

    try {
      await api.put(`/api/counter-open/${counterId}`, {
        isOpen,
        idusuario: idusuario
      });

      if (isOpen) {
        toast.success("Guichê aberto", toastOptions);
      }
      if (!isOpen) {
        toast.success("Guichê fechado", toastOptions);
      }
      dispatch(setIsOpen(true));
      dispatch(setIsUpdating(false));
      if (cb) cb();
    } catch (error: any) {
      dispatch(setIsOpen(false));
      dispatch(setIsUpdating(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };
export const handleNextPassword =
  ({
    idconjuntoguiche,
    counterId,
    callType,
    cb,
  }: {
    idconjuntoguiche?: string;
    counterId?: string;
    callType?: string;
    cb?: () => void;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsLoading, setCurrentServicePassword, setIsOrder } =
      callPatientSlice.actions;
    dispatch(setIsLoading(true));

    try {
      const res = await api.get(
        `/api/users-patients/nextservicepassword?idconjuntoguiche=${idconjuntoguiche}&idguiche=${counterId}&tipochamada=${callType}`
      );
      dispatch(setCurrentServicePassword(res.data.data));
      toast.success("Senha chamada", toastOptions);
      dispatch(setIsOrder(true));
      dispatch(setIsLoading(false));
      if (cb) cb();
    } catch (error: any) {
      dispatch(setIsLoading(false));
      dispatch(setCurrentServicePassword(null));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };
export const handleTryNextPassword =
  ({
    idsenhaatendimento,
    idconjuntoguiche,
    counterId,
    cb,
  }: {
    idsenhaatendimento?: string;
    idconjuntoguiche?: string;
    counterId?: string;
    cb?: () => void;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsLoading, setCurrentServicePassword } =
      callPatientSlice.actions;
    dispatch(setIsLoading(true));

    try {
      const res = await api.get(
        `/api/users-patients/trycallservicepassword?idconjuntoguiche=${idconjuntoguiche}&idguiche=${counterId}&idsenhaatendimento=${idsenhaatendimento}`
      );
      dispatch(setCurrentServicePassword(res.data.data));
      toast.success("Senha chamada novamente", toastOptions);

      dispatch(setIsLoading(false));
      if (cb) cb();
    } catch (error: any) {
      dispatch(setIsLoading(false));
      dispatch(setCurrentServicePassword(null));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };
export const handleCallAbsentPassword =
  ({
    idsenhaatendimento,
    idguiche,
    idconjuntoguiche,
    cb,
  }: {
    idsenhaatendimento?: string;
    idguiche?: string;
    idconjuntoguiche?: string;
    cb?: () => void;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsLoading, setCurrentServicePassword } =
      callPatientSlice.actions;
    dispatch(setIsLoading(true));

    try {
      const res = await api.get(
        `/api/users-patients/passwordsbyIds?idsenhaatendimento=${idsenhaatendimento}&idguiche=${idguiche}&idconjuntoguiche=${idconjuntoguiche}`
      );
      dispatch(setCurrentServicePassword(res.data.data));
      toast.success("Senha chamada", toastOptions);

      dispatch(setIsLoading(false));
      if (cb) cb();
    } catch (error: any) {
      dispatch(setIsLoading(false));
      dispatch(setCurrentServicePassword(null));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const handleGetAllPasswords =
  ({
    idconjuntoguiche,
    isOrder,
    cb,
  }: {
    cb?: () => void;
    idconjuntoguiche?: string;
    isOrder: boolean;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsLoading, setServicePasswords, setIsOrder } =
      callPatientSlice.actions;
    dispatch(setIsLoading(true));

    try {
      const res = await api.get(
        `/api/users-patients/servicepassword?idconjuntoguiche=${idconjuntoguiche}&order=${isOrder}`
      );

      dispatch(setServicePasswords(res.data.data));
      if (res.data.data.length === 0) {
        dispatch(setIsOrder(false));
      }
      dispatch(setIsLoading(false));
      if (cb) cb();
    } catch (error: any) {
      dispatch(setIsLoading(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const handleGetAllPasswordsAbsent =
  ({
    cb,
    idconjuntoguiche,
  }: {
    cb?: () => void;
    idconjuntoguiche?: string;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsLoading, setServiceAbsentPasswords } =
      callPatientSlice.actions;
    dispatch(setIsLoading(true));

    try {
      const res = await api.get(
        `/api/users-patients/absentpassword?idconjuntoguiche=${idconjuntoguiche}`
      );

      dispatch(setServiceAbsentPasswords(res.data.data));
      dispatch(setIsLoading(false));
      if (cb) cb();
    } catch (error: any) {
      dispatch(setIsLoading(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };
export const handleUpdatePassword =
  ({
    servicePasswordId,
    status,
    cb,
  }: {
    servicePasswordId?: string;
    status?: string; // atendido | ausentado
    cb?: () => void;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsLoading, setCurrentServicePassword } =
      callPatientSlice.actions;
    dispatch(setIsLoading(true));

    try {
      await api.put(`/api/users-patients/servicepassword`, {
        idsenhaatendimento: servicePasswordId,
        status,
      });
      if (status === "atendido") {
        toast.success("Senha atendida", toastOptions);
      } else if (status === "ausentado") {
        toast.success("Senha ausentada", toastOptions);
      }
      dispatch(setCurrentServicePassword(null));
      dispatch(setIsLoading(false));
      if (cb) cb();
    } catch (error: any) {
      dispatch(setIsLoading(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const {
  setIsModalOpen,
  setTicketOffice,
  setTickets,
  setWindowSet,
  setIsOpen,
  setIsUpdating,
  setLastPassword
} = callPatientSlice.actions;

export default callPatientSlice.reducer;
