import React from "react";
import { Grid, Card, CardContent, Button } from "@material-ui/core";
import { Formik, Form as FormikForm } from "formik";
import { validationSchema, initialState, IInitialState } from "./data";
import * as S from "./styles";

// @ts-ignore
import { FormikTextField } from "formik-material-fields";
import { useDispatch } from "react-redux";
import { doSignin } from "store/reducers/AuthReducer";

const SigninForm = () => {
  const dispatch = useDispatch();

  const handleSubmit = (values: IInitialState) => {
    const { email, password } = values;
    dispatch(doSignin({ email, password }));
  };
  return (
    <S.FormWrapper>
      <Grid container justify="center" alignItems="center">
        <Card>
          <CardContent>
            <Formik
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
              initialValues={initialState}
            >
              {() => (
                <FormikForm>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormikTextField name="email" label="Email" fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikTextField
                        name="password"
                        label="Senha"
                        fullWidth
                        type="password"
                      />
                    </Grid>
                    <Grid item xs={12}  className="btn-submit" >
                      <Button variant="contained"type="submit" color="primary">
                        Entrar
                      </Button>
                    </Grid>
                  </Grid>
                </FormikForm>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Grid>
    </S.FormWrapper>
    // </FullWidthContainer>
  );
};

export default SigninForm;
