import React, { useEffect, useRef, useState } from "react";

import {
  Button,
  Card,
  CardContent,
  Chip,
  FormHelperText,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import { FormBaseContainer, FullWidthContainer } from "components/layout/base";
import TitleBar from "components/TitleBar";
import { Formik, Form as FormikForm } from "formik";

import { Edit as EditIcon } from "@material-ui/icons";

import { SizedBox } from "components/layout/utils";

import GenericTableHead from "components/table/GenericTableHead";
import { v4 as uuidv4 } from "uuid";
// @ts-ignore
import { FormikSwitchField, FormikTextField } from "formik-material-fields";
import useDebounce from "hooks/useDebouce";
import QRCode from "react-qr-code";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { rootState } from "store";
import {
  fetchAppointmentWebSetup,
  IAppointmentWebSetupForm,
  ICheckinRule,
  IInstallment,
  ISmsRule,
  SmsType,
  updateAppointmentWebSetup,
} from "store/reducers/AppointmentWebSetup";
import { fetchClinics } from "store/reducers/ClinicsReducer";
import { AddAppointmentCheckinRoleModal } from "./components/AddAppointmentCheckinRoleModal";
import { AddAppointmentInstallmentRoleModal } from "./components/AddAppointmentInstallmentRoleModal";
import { AddAppointmentSmsRoleModal } from "./components/AddAppointmentSmsRoleModal";
import { initialState } from "./data";

const tableCells = [
  { id: uuidv4(), alignment: "left", label: "Grupo de serviço" },
  { id: uuidv4(), alignment: "left", label: "Método de pagamento" },
  { id: uuidv4(), alignment: "left", label: "Forma de pagamento" },
  { id: uuidv4(), alignment: "left", label: "Parcela mínima" },
  { id: uuidv4(), alignment: "left", label: "Número de parcelas" },
  { id: uuidv4(), alignment: "right", label: "Ações" },
];

export const AppointmentWebSetup = () => {
  const { isCreating, isFetching, currentAppointmentWebSetup } = useSelector(
    (state: rootState) => state.appointmentWebSetup
  );
  const { clinics } = useSelector((state: rootState) => state.clinics);

  const [formTimeChange, setFormTimeChange] = useState(null);
  const formikValues = useRef();

  const [initialValues, setInitialValues] = useState(initialState);
  const [paymentInstallment, setPaymentInstallment] =
    useState<IInstallment | null>(null);
  const [checkinRole, setCheckinRole] = useState<ICheckinRule | null>(null);
  const [smsRule, setSmsRule] = useState<ISmsRule | null>(null);

  const [openModalCreateRole, setOpenModalCreateRole] = useState(false);
  const [openModalCreateRoleCheckin, setOpenModalCreateRoleCheckin] =
    useState(false);
  const [openModalCreateRoleSms, setOpenModalCreateRoleSms] =
    useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const debouncedFormikChange = useDebounce(formTimeChange, 1000);

  const handleSubmit = (setup: IAppointmentWebSetupForm) => {
    if (currentAppointmentWebSetup) {
      dispatch(
        updateAppointmentWebSetup({
          appointmentWebSetupForm: setup,
        })
      );
    }
  };

  const handleCancel = () => {
    history.replace("/");
  };

  useEffect(() => {
    if (currentAppointmentWebSetup) {
      setInitialValues({
        convenio: currentAppointmentWebSetup.modules.convenio,
        combo: currentAppointmentWebSetup.modules.combo,
        pagamentoonline: currentAppointmentWebSetup.modules.pagamentoonline,
        checkin: currentAppointmentWebSetup.modules.checkin,
        sms: currentAppointmentWebSetup.modules.sms,
        parcelamento: currentAppointmentWebSetup.modules.parcelamento,
        cor_primaria: currentAppointmentWebSetup.palette.primary,
        cor_secundaria: currentAppointmentWebSetup.palette.secondary,
        cor_terciaria: currentAppointmentWebSetup.palette.tertiary,
        cor_background: currentAppointmentWebSetup.palette.background,
        texto_titulo: currentAppointmentWebSetup.configs.home.title,
        texto_subtitulo: currentAppointmentWebSetup.configs.home.subtitle,
        texto_secao_agendamento:
          currentAppointmentWebSetup.configs.home.text_appointment_card,
        text_botao_redirecionar_agendamento:
          currentAppointmentWebSetup.configs.home.text_appointment_button,
        url_imagem_fundo:
          currentAppointmentWebSetup.configs.home.url_background_image,
        texto_footer: currentAppointmentWebSetup.configs.general.text_footer,
        url_logo: currentAppointmentWebSetup.configs.general.url_logo,
        url_site_empresa: currentAppointmentWebSetup.configs.general.url_site,
        text_card_agendamento:
          currentAppointmentWebSetup.configs.appointment.text_appointment_card,
        url_imagem_card_agendamento:
          currentAppointmentWebSetup.configs.appointment.url_image_card,
      });
    }
  }, [dispatch, currentAppointmentWebSetup]);

  useEffect(() => {
    dispatch(fetchAppointmentWebSetup());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      fetchClinics({
        limit: 100000,
        page: 1,
      })
    );
  }, [dispatch]);

  const setUpdateDataInterval = () => {
    //@ts-ignore
    setFormTimeChange(formikValues.current.values);
    return;
  };

  const downloadQR = () => {
    const svg = document.getElementById("QRCode");
    if (svg) {
      const svgData = new XMLSerializer().serializeToString(svg);
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      if (ctx) {
        const img = new Image();
        img.onload = () => {
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          const pngFile = canvas.toDataURL("image/png");
          const downloadLink = document.createElement("a");
          downloadLink.download = "QRCode";
          downloadLink.href = `${pngFile}`;
          downloadLink.click();
        };
        img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
      }
    }
  };

  useEffect(() => {
    //@ts-ignore
    handleSubmit(formikValues?.current?.values);
  }, [debouncedFormikChange]);

  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <TitleBar title="Configurações para o Agendamento Online" />
      </Grid>
      <Grid item xs={12}>
        <FullWidthContainer>
          {currentAppointmentWebSetup && (
            <Grid container justify="center">
              <FormBaseContainer>
                <Card>
                  <CardContent>
                    <Formik
                      onSubmit={handleSubmit}
                      initialValues={initialValues}
                      enableReinitialize
                      //@ts-ignore
                      innerRef={formikValues}
                    >
                      {({ errors, setFieldValue, values }) => (
                        <FormikForm
                          onChange={() => {
                            setUpdateDataInterval();
                          }}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                              <Typography
                                style={{
                                  fontWeight: 600,
                                }}
                              >
                                CONFIGURAÇÕES DA TELA INICIAL
                              </Typography>
                            </Grid>

                            <Grid item xs={12} md={12}>
                              <FormikTextField
                                fullWidth
                                variant="outlined"
                                name="texto_titulo"
                                label="Título"
                                placeholder="Texto exibido na tela inicial"
                              />
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <FormikTextField
                                fullWidth
                                variant="outlined"
                                name="texto_subtitulo"
                                label="Subtítulo"
                                placeholder="Texto exibido logo abaixo do título"
                              />
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <FormikTextField
                                fullWidth
                                variant="outlined"
                                name="texto_secao_agendamento"
                                label="Título da seção de agendamento"
                                placeholder="Texto exibido na seção de agendamento (acima dos filtros)"
                              />
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <FormikTextField
                                fullWidth
                                variant="outlined"
                                name="text_botao_redirecionar_agendamento"
                                label="Título do botão de agendamento"
                                placeholder="Texto exibido no botão de agendamento seção de agendamento"
                              />
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <FormikTextField
                                fullWidth
                                variant="outlined"
                                name="url_imagem_fundo"
                                label="URL da imagem de fundo"
                              />
                              <FormHelperText>
                                Recomendação: Utilize uma imagem com tamanho de
                                1080x720
                              </FormHelperText>
                            </Grid>
                          </Grid>

                          <Grid
                            container
                            spacing={2}
                            style={{
                              marginTop: 16,
                            }}
                          >
                            <Grid item xs={12} md={12}>
                              <Typography
                                style={{
                                  fontWeight: 600,
                                }}
                              >
                                Configurações gerais
                              </Typography>
                            </Grid>

                            <Grid item xs={12} md={12}>
                              <FormikTextField
                                fullWidth
                                variant="outlined"
                                name="url_logo"
                                label="URL da logo"
                                placeholder="Somente a URL da imagem"
                              />
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <FormikTextField
                                fullWidth
                                variant="outlined"
                                name="texto_footer"
                                label="Texto do footer"
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            spacing={2}
                            style={{
                              marginTop: 16,
                            }}
                          >
                            <Grid item xs={12} md={12}>
                              <Typography
                                style={{
                                  fontWeight: 600,
                                }}
                              >
                                MÓDULOS DE AGENDAMENTO
                              </Typography>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <FormikSwitchField
                                color="primary"
                                name="convenio"
                                label="Convênio"
                              />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <FormikSwitchField
                                color="primary"
                                name="combo"
                                label="Combo"
                              />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <FormikSwitchField
                                color="primary"
                                name="pagamentoonline"
                                label="Pagamento Online"
                              />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <FormikSwitchField
                                color="primary"
                                name="parcelamento"
                                label="Possui parcelamento?"
                              />
                            </Grid>
                          </Grid>

                          {currentAppointmentWebSetup &&
                            currentAppointmentWebSetup.modules
                              .pagamentoonline && (
                              <Grid
                                container
                                spacing={2}
                                style={{
                                  marginTop: 24,
                                  marginBottom: 48,
                                }}
                              >
                                <Grid item xs={12} md={9}>
                                  <Typography
                                    style={{
                                      fontWeight: 600,
                                    }}
                                  >
                                    REGRAS DE PARCELAMENTO (PAGAMENTO
                                    ANTECIPADO)
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={() => setOpenModalCreateRole(true)}
                                  >
                                    Adicionar regra
                                  </Button>
                                </Grid>
                                {currentAppointmentWebSetup &&
                                currentAppointmentWebSetup.modules
                                  .regrasparcelamento &&
                                currentAppointmentWebSetup.modules
                                  .regrasparcelamento.length > 0 ? (
                                  <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    style={{
                                      marginTop: 16,
                                    }}
                                  >
                                    <Paper>
                                      <Table size="small">
                                        <GenericTableHead
                                          headCells={tableCells}
                                        />
                                        <TableBody>
                                          {currentAppointmentWebSetup.modules.regrasparcelamento.map(
                                            (row) => (
                                              <TableRow
                                                key={row.idmetodopagamento}
                                              >
                                                <TableCell>
                                                  {row.gruposervico.nome.toUpperCase() ||
                                                    "Não cadastrado"}
                                                </TableCell>
                                                <TableCell>
                                                  {row.metodopagamento.nome.toUpperCase() ||
                                                    "Não cadastrado"}
                                                </TableCell>
                                                <TableCell>
                                                  {(
                                                    <Chip
                                                      label={
                                                        row.metodopagamento
                                                          .formapagamento
                                                      }
                                                    />
                                                  ) || "Não cadastrado"}
                                                </TableCell>
                                                <TableCell>1</TableCell>
                                                <TableCell>
                                                  {(row.metodopagamento
                                                    .taxas_credito &&
                                                    row.metodopagamento
                                                      .taxas_credito.length) ||
                                                    1}
                                                </TableCell>

                                                <TableCell align="right">
                                                  <Grid
                                                    container
                                                    justify="flex-end"
                                                    alignItems="center"
                                                    spacing={1}
                                                  >
                                                    <Grid item>
                                                      <IconButton
                                                        onClick={() => {
                                                          setPaymentInstallment(
                                                            row
                                                          );
                                                          setOpenModalCreateRole(
                                                            true
                                                          );
                                                        }}
                                                        component="span"
                                                      >
                                                        <EditIcon />
                                                      </IconButton>
                                                    </Grid>
                                                    {/* <Grid item>
                                                      <IconButton
                                                        onClick={() => {}}
                                                        component="span"
                                                      >
                                                        <DeleteForeverIcon />
                                                      </IconButton>
                                                    </Grid> */}
                                                  </Grid>
                                                </TableCell>
                                              </TableRow>
                                            )
                                          )}
                                        </TableBody>
                                      </Table>
                                    </Paper>
                                  </Grid>
                                ) : (
                                  <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    style={{
                                      marginTop: 16,
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        fontWeight: 500,
                                        textAlign: "center",
                                      }}
                                    >
                                      Você ainda não cadastrou nenhuma regra de
                                      parcelamento!
                                    </Typography>
                                  </Grid>
                                )}
                              </Grid>
                            )}

                          <Grid
                            container
                            spacing={2}
                            style={{
                              marginTop: 16,
                            }}
                          >
                            <Grid item xs={12} md={12}>
                              <Typography
                                style={{
                                  fontWeight: 600,
                                }}
                              >
                                CONFIGURAÇÃO DE CHECK-IN
                              </Typography>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <FormikSwitchField
                                color="primary"
                                name="checkin"
                                label="Habilitar checkin?"
                              />
                            </Grid>
                          </Grid>
                          {currentAppointmentWebSetup &&
                            currentAppointmentWebSetup.modules.checkin && (
                              <Grid
                                container
                                spacing={2}
                                style={{
                                  marginTop: 24,
                                  marginBottom: 48,
                                }}
                              >
                                <Grid item xs={12} md={8}>
                                  <Typography
                                    style={{
                                      fontWeight: 600,
                                    }}
                                  >
                                    QR CODE
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={() =>
                                      setOpenModalCreateRoleCheckin(true)
                                    }
                                  >
                                    Adicionar QRCODE
                                  </Button>
                                </Grid>
                                {currentAppointmentWebSetup &&
                                currentAppointmentWebSetup.modules
                                  .regrascheckin &&
                                currentAppointmentWebSetup.modules.regrascheckin
                                  .length > 0 ? (
                                  <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    style={{
                                      marginTop: 16,
                                    }}
                                  >
                                    <Grid container spacing={2}>
                                      {currentAppointmentWebSetup.modules.regrascheckin.map(
                                        (checkin) =>
                                          clinics && (
                                            <Grid item xs={12} md={6}>
                                              <Card>
                                                <CardContent>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <Grid item xs={6} md={6}>
                                                      {checkin.idclinica
                                                        ? clinics.find(
                                                            (clinic) =>
                                                              clinic.idclinica ===
                                                              checkin.idclinica
                                                          )?.nome
                                                        : "Nenhuma clínica encontrada"}
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      xs={6}
                                                      md={6}
                                                      style={{
                                                        display: "flex",
                                                        justifyContent: "end",
                                                      }}
                                                    >
                                                      <IconButton
                                                        onClick={() => {
                                                          setCheckinRole(
                                                            checkin
                                                          );
                                                          setOpenModalCreateRoleCheckin(
                                                            true
                                                          );
                                                        }}
                                                        component="span"
                                                      >
                                                        <EditIcon />
                                                      </IconButton>
                                                    </Grid>
                                                    <Grid item xs={12} md={12}>
                                                      <QRCode
                                                        // size={256}
                                                        id="QRCode"
                                                        style={{
                                                          height: "auto",
                                                          maxWidth: "100%",
                                                          width: "100%",
                                                        }}
                                                        value={`${checkin.url}/paciente/dashboard`}
                                                        viewBox={`0 0 256 256`}
                                                      />
                                                      <Button
                                                        color="primary"
                                                        fullWidth
                                                        variant="outlined"
                                                        size="small"
                                                        style={{
                                                          marginTop: 4,
                                                        }}
                                                        onClick={() =>
                                                          downloadQR()
                                                        }
                                                      >
                                                        Baixar imagem
                                                      </Button>
                                                    </Grid>
                                                  </Grid>
                                                </CardContent>
                                              </Card>
                                            </Grid>
                                          )
                                      )}
                                    </Grid>
                                  </Grid>
                                ) : (
                                  <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    style={{
                                      marginTop: 16,
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        fontWeight: 500,
                                        textAlign: "center",
                                      }}
                                    >
                                      Nenhum QR Code configurado
                                    </Typography>
                                  </Grid>
                                )}
                              </Grid>
                            )}

                          <Grid
                            container
                            spacing={2}
                            style={{
                              marginTop: 16,
                            }}
                          >
                            <Grid item xs={12} md={12}>
                              <Typography
                                style={{
                                  fontWeight: 600,
                                }}
                              >
                                CONFIGURAÇÃO DE SMS
                              </Typography>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <FormikSwitchField
                                color="primary"
                                name="sms"
                                label="Habilitar envio de SMS?"
                              />
                            </Grid>
                          </Grid>

                          {currentAppointmentWebSetup &&
                            currentAppointmentWebSetup.modules.sms && (
                              <Grid
                                container
                                spacing={2}
                                style={{
                                  marginTop: 24,
                                  marginBottom: 48,
                                }}
                              >
                                <Grid item xs={12} md={8}>
                                  <Typography
                                    style={{
                                      fontWeight: 600,
                                    }}
                                  >
                                    MENSAGENS 
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={() =>
                                      setOpenModalCreateRoleSms(true)
                                    }
                                  >
                                    Adicionar Mensagem
                                  </Button>
                                </Grid>
                                {currentAppointmentWebSetup &&
                                currentAppointmentWebSetup.modules
                                  .regrassms &&
                                currentAppointmentWebSetup.modules.regrassms
                                  .length > 0 ? (
                                  <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    style={{
                                      marginTop: 16,
                                    }}
                                  >
                                    <Grid container spacing={2}>
                                      {currentAppointmentWebSetup.modules.regrassms.map(
                                        (sms) =>
                                          clinics && (
                                            <Grid item xs={12} md={6}>
                                              <Card>
                                                <CardContent>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <Grid item xs={6} md={6}>
                                                      {sms.idclinica
                                                        ? clinics.find(
                                                            (clinic) =>
                                                              clinic.idclinica ===
                                                              sms.idclinica
                                                          )?.nome
                                                        : "Nenhuma clínica encontrada"}
                                                        <Chip style={{
                                                          marginTop: 8
                                                        }} label={sms.type === SmsType.CONFIRMATION ? 'Confirmação' : 'Lembrete'}  />
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      xs={6}
                                                      md={6}
                                                      style={{
                                                        display: "flex",
                                                        justifyContent: "end",
                                                      }}
                                                    >
                                                      <IconButton
                                                        onClick={() => {
                                                          setSmsRule(
                                                            sms
                                                          );
                                                          setOpenModalCreateRoleSms(true)
                                                        }}
                                                        component="span"
                                                      >
                                                        <EditIcon />
                                                      </IconButton>
                                                    </Grid>
                                                  </Grid>
                                                </CardContent>
                                              </Card>
                                            </Grid>
                                          )
                                      )}
                                    </Grid>
                                  </Grid>
                                ) : (
                                  <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    style={{
                                      marginTop: 16,
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        fontWeight: 500,
                                        textAlign: "center",
                                      }}
                                    >
                                      Nenhuma mensagem configurada
                                    </Typography>
                                  </Grid>
                                )}
                              </Grid>
                            )}

                          <Grid
                            container
                            spacing={2}
                            style={{
                              marginTop: 16,
                            }}
                          >
                            <Grid item xs={12} md={12}>
                              <Typography
                                style={{
                                  fontWeight: 600,
                                }}
                              >
                                OUTRAS CUSTOMIZAÇÕES (TELA DE AGENDAMENTO)
                              </Typography>
                            </Grid>

                            <Grid item xs={12} md={6}>
                              <FormikTextField
                                fullWidth
                                variant="outlined"
                                name="text_card_agendamento"
                                label="Título do card de agendamento"
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormikTextField
                                fullWidth
                                variant="outlined"
                                name="url_imagem_card_agendamento"
                                label="URL da imagem do card de agendamento"
                              />
                            </Grid>
                          </Grid>
                          <SizedBox height="50px" />
                          <Grid container justify="flex-end" spacing={2}>
                            <Grid item>
                              <Button onClick={handleCancel} color="primary">
                                Cancelar
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                disabled={isCreating || isFetching}
                              >
                                Salvar
                              </Button>
                            </Grid>
                          </Grid>
                        </FormikForm>
                      )}
                    </Formik>
                  </CardContent>
                </Card>
              </FormBaseContainer>
            </Grid>
          )}
        </FullWidthContainer>
      </Grid>

      {openModalCreateRole && (
        <AddAppointmentInstallmentRoleModal
          onClose={() => {
            setOpenModalCreateRole(false);
            setPaymentInstallment(null);
          }}
          open={
            openModalCreateRole &&
            currentAppointmentWebSetup &&
            currentAppointmentWebSetup.modules.pagamentoonline
              ? true
              : false
          }
          paymentInstallment={paymentInstallment}
        />
      )}
      {openModalCreateRoleCheckin && (
        <AddAppointmentCheckinRoleModal
          onClose={() => {
            setOpenModalCreateRoleCheckin(false);
            setCheckinRole(null);
          }}
          open={
            openModalCreateRoleCheckin &&
            currentAppointmentWebSetup &&
            currentAppointmentWebSetup.modules.checkin
              ? true
              : false
          }
          checkin={checkinRole}
        />
      )}
      {openModalCreateRoleSms && (
        <AddAppointmentSmsRoleModal
          onClose={() => {
            setOpenModalCreateRoleSms(false);
            setSmsRule(null);
          }}
          open={
            openModalCreateRoleSms &&
            currentAppointmentWebSetup &&
            currentAppointmentWebSetup.modules.sms
              ? true
              : false
          }
          smsRule={smsRule}
        />
      )}
    </Grid>
  );
};
