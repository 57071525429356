import { IServiceGroup, IServiceGroupForm } from "models/services/groups/index";
import { IFilter } from "models/shared/index";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, AppDispatch } from "store/index";
import { toast } from "react-toastify";
import toastOptions from "utils/toastOptions";
import api from "utils/API";
import { history } from "utils/history";
import { queryStringFromFilterArray } from "utils/network";

interface IInitialState {
  isFetchingServiceGroups: boolean;
  isCreatingServiceGroup: boolean;
  isDeletingServiceGroup: boolean;
  serviceGroups: IServiceGroup[] | null;
  currentServiceGroup: IServiceGroup | null;
  total: number;
  page: number;
  filterArray: IFilter[];
}

const initialState: IInitialState = {
  isFetchingServiceGroups: false,
  isCreatingServiceGroup: false,
  isDeletingServiceGroup: false,
  serviceGroups: null,
  currentServiceGroup: null,
  total: 0,
  page: 0,
  filterArray: [
    {key: "nome", value: []}
  ],
};

const serviceGroupsSlice = createSlice({
  name: "serviceGroupsSlice",
  initialState,
  reducers: {
    setIsFetchingServiceGroups: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingServiceGroups = payload;
    },
    setisCreatingServiceGroup: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreatingServiceGroup = payload;
    },
    setisDeletingServiceGroup: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeletingServiceGroup = payload;
    },
    setServiceGroups: (
      state,
      {
        payload: { service_groups, total, page },
      }: PayloadAction<{
        service_groups: IServiceGroup[];
        total: number;
        page: number;
      }>
    ) => {
      state.serviceGroups = service_groups;
      state.total = total;
      state.page = page;
    },
    setCurrentServiceGroup: (
      state,
      { payload }: PayloadAction<IServiceGroup>
    ) => {
      state.currentServiceGroup = payload;
    },
    updateFilter: (state, { payload }: PayloadAction<IFilter>) => {
      const index = state.filterArray.findIndex(
        (item) => item.key === payload.key
      );
      state.filterArray[index].value = payload.value;
    },
  },
});

export const fetchServiceGroups = ({
  page = 1,
  limit = 6,
  forceAll
}: {
  page?: number;
  limit?: number;
  forceAll?: boolean;
}): AppThunk => async (dispatch: AppDispatch, getState) => {
  const { setIsFetchingServiceGroups } = serviceGroupsSlice.actions;
  dispatch(setIsFetchingServiceGroups(true));
  try {
    const state = getState();
    const { filterArray } = state.serviceGroups;
    const queryParameters = queryStringFromFilterArray(filterArray);
    const pageAndLimit =
      queryParameters.length === 0
        ? `?page=${page}&limit=${limit}`
        : `&page=${page}&limit=${limit}`;
    console.log("forcaAll");
    console.log(forceAll);
    const availableQuery = (forceAll ? `&forceAll=${forceAll}` : "");
    const response = await api.get(
      `/api/servicegroups${queryParameters}${pageAndLimit}${availableQuery}`
    );
    dispatch(serviceGroupsSlice.actions.setServiceGroups(response.data.data));
    dispatch(setIsFetchingServiceGroups(false));
  } catch (error) {
    dispatch(setIsFetchingServiceGroups(false));
    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);
    } else {
      console.log(error.message);
    }
  }
};

export const fetchServiceGroupById = (
  serviceGroupId: string
): AppThunk => async (dispatch: AppDispatch) => {
  const {
    setIsFetchingServiceGroups,
    setCurrentServiceGroup,
  } = serviceGroupsSlice.actions;
  dispatch(setIsFetchingServiceGroups(true));
  try {
    const response = await api.get(`/api/servicegroups/${serviceGroupId}`);
    dispatch(setCurrentServiceGroup(response.data.data));
    dispatch(setIsFetchingServiceGroups(false));
  } catch (error) {
    dispatch(setIsFetchingServiceGroups(false));
    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);
    } else {
      console.log(error.message);
    }
  }
};

export const createServiceGroup = (user: IServiceGroupForm): AppThunk => async (
  dispatch: AppDispatch
) => {
  const { setisCreatingServiceGroup } = serviceGroupsSlice.actions;
  dispatch(setisCreatingServiceGroup(true));
  try {
    await api.post(`/api/servicegroups`, user);
    dispatch(setisCreatingServiceGroup(false));
    history.replace("/services/groups");
    toast.success("Grupo de serviço cadastrado", toastOptions);
  } catch (error) {
    dispatch(setisCreatingServiceGroup(false));
    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);
    } else {
      console.log(error.message);
    }
  }
};

export const updateServiceGroup = ({
  serviceGroup,
  serviceGroupId,
  cb
}: {
  serviceGroup: IServiceGroupForm;
  serviceGroupId: string | number;
  cb?: () => void;
}): AppThunk => async (dispatch: AppDispatch) => {
  const { setisCreatingServiceGroup } = serviceGroupsSlice.actions;
  dispatch(setisCreatingServiceGroup(true));
  try {
    await api.put(`/api/servicegroups/${serviceGroupId}`, serviceGroup);
    dispatch(setisCreatingServiceGroup(false));
    toast.success("Grupo de serviço atualizado", toastOptions);
    history.replace("/services/groups");
    if (cb) cb();
  } catch (error) {
    dispatch(setisCreatingServiceGroup(false));
    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);
    } else {
      console.log(error.message);
    }
  }
};

export const deleteServiceGroup = (serviceGroupId: string): AppThunk => async (
  dispatch: AppDispatch
) => {
  const { setisDeletingServiceGroup } = serviceGroupsSlice.actions;
  dispatch(setisDeletingServiceGroup(true));
  try {
    await api.delete(`/api/servicegroups/${serviceGroupId}`);
    dispatch(setisDeletingServiceGroup(false));
    toast.success("Grupo de serviço excluido com sucesso", toastOptions);
    dispatch(fetchServiceGroups({}));
  } catch (error) {
    dispatch(setisDeletingServiceGroup(false));
    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);
    } else {
      console.log(error.message);
    }
  }
};

export const {
  updateFilter: updateServiceGroupsFilter,
} = serviceGroupsSlice.actions

export default serviceGroupsSlice.reducer;
