import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Campo obrigatório")
    .email("Insira um email no formato email@provedor.com"),
  password: Yup.string()
    .required("Campo obrigatório")
    .min(6, "Mínimo 6 caracteres"),
});

export interface IInitialState {
  email: string;
  password: string;
}

export const initialState: IInitialState = {
  email: "",
  password: "",
};
