import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Chip,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { ISelectOption } from "components/CallPatient/CallPatient.types";
import { FormBaseContainer } from "components/layout/base";
import { Formik, Form as FormikForm } from "formik";
// @ts-ignore
import { FormikTextField } from "formik-material-fields";
import { useDispatch, useSelector } from "react-redux";
import { rootState } from "store";
import { ISmsRule, MINUTES_NOTIFY, SmsType, createSmsRolesAppointmentWebSetup } from "store/reducers/AppointmentWebSetup";
import { fetchServiceGroups } from "store/reducers/ServiceGroupsReducer";
import { initialState, validationSchema } from "./data";
// @ts-ignore
import { Close } from "@material-ui/icons";
import { fetchClinics } from "store/reducers/ClinicsReducer";

interface IProps {
  open: boolean;
  onClose: Function;
  smsRule: ISmsRule | null;
}

const greetings = [
  "Olá",
  "Oi",
];

const minutesOptions = [
  {
    label: "1 hora antes",
    value: MINUTES_NOTIFY.ONE_HOUR,
  },
  {
    label: "2 hora antes",
    value: MINUTES_NOTIFY.TWO_HOUR,
  },
  {
    label: "1 dia antes",
    value: MINUTES_NOTIFY.ONE_DAY,
  },
  {
    label: "2 dias antes",
    value: MINUTES_NOTIFY.TWO_DAY,
  },
]

export const AddAppointmentSmsRoleModal = ({
  onClose,
  open,
  smsRule,
}: IProps) => {
  const [initialValues, setInitialValues] = useState(initialState);
  const [clinicOptions, setClinicOptions] = useState<ISelectOption[]>([]);
  const [selectedClinic, setSelectedClinic] = useState<ISelectOption>({
    label: "",
    value: "",
  });
  const [selectedType, setSelectedType] = useState<ISelectOption>({
    label: "",
    value: "",
  });
  const [selectedMinutes, setSelectedMinutes] = useState<ISelectOption>({
    label: "",
    value: "",
  });

  const dispatch = useDispatch();

  const { isFetchingClinics, clinics } = useSelector(
    (state: rootState) => state.clinics
  );

  const { currentAppointmentWebSetup, isCreatingRole } = useSelector(
    (state: rootState) => state.appointmentWebSetup
  );

  const handleChangeClinic = (
    event: any,
    newValue: ISelectOption,
    setFieldValue: Function
  ) => {
    setSelectedClinic(newValue);
    if (newValue) {
      setFieldValue("idclinica", newValue.value);
    } else {
      setFieldValue("idclinica", "");
    }
  };
  const handleChangeType = (
    event: any,
    newValue: ISelectOption,
    setFieldValue: Function
  ) => {
    setSelectedType(newValue);
    if (newValue) {
      setFieldValue("type", newValue.value);
    } else {
      setFieldValue("type", "");
    }
  };
  const handleChangeMinutes = (
    event: any,
    newValue: ISelectOption,
    setFieldValue: Function
  ) => {
    setSelectedMinutes(newValue);
    if (newValue) {
      setFieldValue("minutes", newValue.value);
    } else {
      setFieldValue("minutes", "");
    }
  };

  useEffect(() => {
    if (smsRule) {
      const clinic = clinics
        ? clinics.find((clin) => clin.idclinica === smsRule.idclinica)
        : null;
      if (clinic) {
        setSelectedClinic({
          label: clinic.nome.toUpperCase(),
          value: clinic.idclinica,
        });
      }
      const minutes = minutesOptions.find(min => min.value === smsRule.minutes)
      if(minutes) {
        setSelectedMinutes({
          label: minutes.label,
          value: smsRule.minutes
        })
      }
      setSelectedType({
        label: smsRule.type === SmsType.CONFIRMATION ? 'Confirmação' : 'Lembrete',
        value: smsRule.type
      })

      setInitialValues({
        value: smsRule.value,
        idclinica: smsRule.idclinica,
        type: smsRule.type,
        greeting: smsRule.greeting,
        minutes: smsRule.minutes
      });
    }
  }, [smsRule, clinics]);

  const handleSubmit = (data: ISmsRule) => {
    dispatch(
      createSmsRolesAppointmentWebSetup({
        data: data,
      })
    );
    onClose();
  };

  useEffect(() => {
    setInitialValues(initialState);    
    setSelectedMinutes({
      label: '1 dia antes',
      value: MINUTES_NOTIFY.ONE_DAY
    })
    setSelectedType({
      label:'Confirmação',
      value: SmsType.CONFIRMATION
    })
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      fetchClinics({
        limit: 100000,
        page: 1,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      fetchServiceGroups({
        limit: 100000,
        forceAll: true,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (clinics) {
      let clinicssEnabled = clinics.filter(
        (clinic) => clinic.agendamentoonline
      );

      setClinicOptions(
        clinicssEnabled.map(({ idclinica, nome }) => ({
          label: nome.toUpperCase(),
          value: idclinica,
        }))
      );
    }
  }, [clinics, currentAppointmentWebSetup]);

  return (
    <Dialog open={open} onClose={() => onClose()} fullWidth maxWidth="lg">
      <DialogTitle>
        Configurar mensagem
        <IconButton
          onClick={() => onClose()}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <FormBaseContainer>
          <Formik
            onSubmit={(values) => handleSubmit(values)}
            validationSchema={validationSchema}
            initialValues={initialValues}
            enableReinitialize
          >
            {({ errors, setFieldValue, values }) => (
              <FormikForm>
                <Grid
                  container
                  spacing={2}
                  style={{
                    marginTop: 2,
                    marginBottom: 48,
                  }}
                >
                  <Grid item lg={12}>
                    <Alert color="info">
                      Para configurar as mensagens, selecione a clínica que
                      utilizará o serviço de SMS, selecione o tipo de mensagem e a sua mensagem!
                    </Alert>
                  </Grid>
                  <Grid item lg={5}>
                    <Autocomplete
                      name="idclinica"
                      getOptionLabel={(option: ISelectOption) => option.label}
                      options={clinicOptions}
                      disabled={isFetchingClinics || smsRule ? true : false}
                      value={selectedClinic}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      getOptionSelected={(
                        option: ISelectOption,
                        value: ISelectOption
                      ) => {
                        return value.value === option.value;
                      }}
                      // @ts-ignore
                      onChange={(event: any, newValue: ISelectOption) => {
                        handleChangeClinic(event, newValue, setFieldValue);
                      }}
                      fullWidth
                      renderInput={(params: any) => (
                        <TextField
                          error={errors.idclinica}
                          helperText={errors.idclinica}
                          name="idclinica"
                          style={{ minWidth: 200 }}
                          variant="outlined"
                          placeholder="Selecione uma clínica..."
                          {...params}
                          label="Clínica"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item lg={4}>
                    <Autocomplete
                      name="type"
                      getOptionLabel={(option: ISelectOption) => option.label}
                      options={[
                        {
                          label: "Confirmação",
                          value: SmsType.CONFIRMATION,
                        },
                        {
                          label: "Lembrete",
                          value: SmsType.REMEMBER,
                        },
                      ]}
                      disabled={smsRule ? true : false}
                      value={selectedType}
                      loading={isFetchingClinics}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      getOptionSelected={(
                        option: ISelectOption,
                        value: ISelectOption
                      ) => {
                        return value.value === option.value;
                      }}
                      // @ts-ignore
                      onChange={(event: any, newValue: ISelectOption) => {
                        handleChangeType(event, newValue, setFieldValue);
                      }}
                      fullWidth
                      renderInput={(params: any) => (
                        <TextField
                          error={errors.type}
                          helperText={errors.type}
                          name="type"
                          style={{ minWidth: 200 }}
                          variant="outlined"
                          placeholder="Selecione um tipo de mensagem"
                          label="Tipo da mensagem"
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item lg={3}>
                    <Autocomplete
                      name="minutes"
                      getOptionLabel={(option: ISelectOption) => option.label}
                      options={minutesOptions}
                      value={selectedMinutes}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      getOptionSelected={(
                        option: ISelectOption,
                        value: ISelectOption
                      ) => {
                        return value.value === option.value;
                      }}
                      // @ts-ignore
                      onChange={(event: any, newValue: ISelectOption) => {
                        handleChangeMinutes(event, newValue, setFieldValue);
                      }}
                      fullWidth
                      renderInput={(params: any) => (
                        <TextField
                          error={errors.minutes}
                          helperText={errors.minutes}
                          name="minutes"
                          variant="outlined"
                          label="Tempo para notificação"
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item lg={12}>
                    <FormikTextField
                      name="value"
                      variant="outlined"
                      placeholder="Digite o texto da mensagem"
                      label="Mensagem"
                      multiline={true}
                      rows="3"
                      fullWidth
                    />
                    <Typography mt={2}>Saudação:</Typography>
                    <Stack direction="row" spacing={2}>
                      {greetings.map(greeting => (
                        <Chip color={greeting === values.greeting ? "primary" : "default"} label={greeting} onClick={() => setFieldValue('greeting',greeting)} />
                      ))}
                    </Stack>
                  </Grid>

                  {selectedClinic && values.value && (
                    <Grid item lg={12}>
                      <Grid
                        container
                        spacing={2}
                        style={{
                          marginTop: 2,
                        }}
                      >
                        {values.type === SmsType.CONFIRMATION && (
                          <Grid item lg={12}>
                            <Alert color="info">
                              <AlertTitle>Exemplo da mensagem de confirmação</AlertTitle>
                             {values.greeting}, [Nome do Paciente], {values.value} [Link de confirmação]
                            </Alert>
                          </Grid>
                        )}
                        {values.type === SmsType.REMEMBER && (
                          <Grid item lg={12}>
                            <Alert color="info">
                              <AlertTitle>Exemplo da mensagem de lembrete</AlertTitle>
                              {values.greeting}, [Nome do Paciente], {values.value} [Nome do profissional] em [Data do agendamento]
                            </Alert>
                          </Grid>
                        )}

                        <Grid
                          item
                          lg={12}
                          style={{
                            marginTop: 16,
                          }}
                        >
                          <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            fullWidth
                            size="large"
                            disabled={isCreatingRole || !selectedClinic}
                          >
                            {smsRule
                              ? `Atualizar mensagem`
                              : `Cadastrar mensagem`}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </FormikForm>
            )}
          </Formik>
        </FormBaseContainer>
      </DialogContent>
    </Dialog>
  );
};
