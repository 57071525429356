import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "store/index";
import { toast } from "react-toastify";
import toastOptions from "utils/toastOptions";
import api from "utils/API";
import { queryStringFromFilterArray } from "utils/network";
import { IBrandCard, IPaymentMethod } from "models/PaymentMethod";
import { IFilter } from "models/shared";
import { history } from "utils/history";

interface IInitialState {
  isFetchingPaymentMethods: boolean;
  isFetchingPaymentMethod: boolean;
  isCreatingOrUpdatingPaymentMethod: boolean;
  isDeletingPaymentMethod: boolean;
  paymentMethodsToServiceOrder: IPaymentMethod[];
  paymentMethods: IPaymentMethod[] | null;
  paymentMethodsFilterArray: IFilter[];
  currentPaymentMethod: IPaymentMethod | null;
  isFetchingBrandCards: boolean;
  brandCardsFilterArray: IFilter[];
  brandCards: IBrandCard[];
  page: number;
  limit: number;
  total: number;
}

const initialState: IInitialState = {
  isFetchingPaymentMethods: false,
  isFetchingPaymentMethod: false,
  isCreatingOrUpdatingPaymentMethod: false,
  isDeletingPaymentMethod: false,
  currentPaymentMethod: null,
  paymentMethodsToServiceOrder: [],
  paymentMethods: [],
  paymentMethodsFilterArray: [
    // { key: "page", value: 1 },
    // { key: "limit", value: 6 },
    { key: "nome", value: null },
    { key: "recebimentoantecipado", value: null },
    { key: "ativo", value: null },
    { key: "formapagamento", value: null },
    { key: "bandeira", value: null },
    { key: "idmaquinapagamento", value: null },
    { key: "idclinica", value: null },
  ],
  isFetchingBrandCards: false,
  brandCards: [],
  brandCardsFilterArray: [
    { key: "formapagamento", value: null },
    { key: "idmaquinapagamento", value: null },
    { key: "idclinica", value: null },
  ],
  page: 1,
  limit: 6,
  total: 0,
};

const paymentMethodsSlice = createSlice({
  name: "paymentMethodsSlice",
  initialState,
  reducers: {
    setisFetchingPaymentMethods: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingPaymentMethods = payload;
    },
    setIsFetchingBrandCards: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingPaymentMethods = payload;
    },
    setisFetchingPaymentMethod: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingPaymentMethod = payload;
    },
    setIsCreatingOrUpdatingPaymentMethod: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isCreatingOrUpdatingPaymentMethod = payload;
    },
    setIsDeletingPaymentMethod: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isDeletingPaymentMethod = payload;
    },
    setBrandCards: (
      state,
      {
        payload: { brand_cards },
      }: PayloadAction<{
        brand_cards: IBrandCard[];
      }>
    ) => {
      state.brandCards = brand_cards;
    },
    setPaymentMethods: (
      state,
      {
        payload: { payment_methods, total, page },
      }: PayloadAction<{
        payment_methods: IPaymentMethod[]| null;
        total: number;
        page: number;
      }>
    ) => {
      state.paymentMethods = payment_methods;
      state.total = total;
      state.page = page;
    },
    setPaymentMethodsToServiceOrder: (
      state,
      {
        payload: { payment_methods },
      }: PayloadAction<{
        payment_methods: IPaymentMethod[];
      }>
    ) => {
      state.paymentMethodsToServiceOrder = payment_methods;
    },
    setCurrentPaymentMethod: (
      state,
      { payload }: PayloadAction<IPaymentMethod | null>
    ) => {
      state.currentPaymentMethod = payload;
    },
    updatePaymentMethodsFilterArray: (
      state,
      { payload: { key, value } }: PayloadAction<IFilter>
    ) => {
      state.paymentMethodsFilterArray = state.paymentMethodsFilterArray.map(
        (filter) => {
          if (filter.key === key) {
            filter.value = value;
          }
          return filter;
        }
      );
    },
    resetPaymentMethodsFilterArray: (state) => {
      const hasFilterFilled = state.paymentMethodsFilterArray.some(filter => 
        filter.value && filter.value.length > 0
      )

      if (hasFilterFilled) {
        state.paymentMethodsFilterArray = [
          { key: "nome", value: null },
          { key: "recebimentoantecipado", value: null },
          { key: "ativo", value: null },
          { key: "formapagamento", value: null },
          { key: "bandeira", value: null },
          { key: "idmaquinapagamento", value: null },
          { key: "idclinica", value: null },
        ]; 
      }
    },
    updateBrandCardsFilterArray: (
      state,
      { payload: { key, value } }: PayloadAction<IFilter>
    ) => {
      state.brandCardsFilterArray = state.brandCardsFilterArray.map(
        (filter) => {
          if (filter.key === key) {
            filter.value = value;
          }
          return filter;
        }
      );
    },
    resetBrandCardsFilterArray: (state) => {
      const hasFilterFilled = state.brandCardsFilterArray.some(filter => 
        filter.value && filter.value.length > 0
      )

      if (hasFilterFilled) {
        state.brandCardsFilterArray = [
          { key: "formapagamento", value: null },
          { key: "idmaquinapagamento", value: null },
          { key: "idclinica", value: null },
        ];
      }
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setLimit: (state, { payload }: PayloadAction<number>) => {
      state.limit = payload;
    },
    setTotal: (state, { payload }: PayloadAction<number>) => {
      state.total = payload;
    },
  },
});

export const fetchBrandCards = (): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingBrandCards, setBrandCards } =
      paymentMethodsSlice.actions;
    
    dispatch(setIsFetchingBrandCards(true));

    try {
      const state = getState();
      const { brandCardsFilterArray } = state.paymentMethods;
      const queryParameters = queryStringFromFilterArray(
        brandCardsFilterArray
      );

      const response = await api.get(
        `/api/paymentmethods/brandcards${queryParameters}`
      );

      dispatch(setBrandCards(response.data.data));
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setIsFetchingBrandCards(false));
    }
  };

export const fetchAllPaymentMethods =
  ({
    page = 1,
    limit = 6,
    list_all_methods,
    cb,
  }: {
    page?: number;
    limit?: number;
    list_all_methods?: boolean;
    cb?: any;
  }): AppThunk =>
  async (dispatch, getState) => {
    const { setisFetchingPaymentMethods, setPaymentMethodsToServiceOrder, setPaymentMethods } =
      paymentMethodsSlice.actions;
    dispatch(setisFetchingPaymentMethods(true));
    try {
      const state = getState();
      const { paymentMethodsFilterArray } = state.paymentMethods;
      const queryParameters = queryStringFromFilterArray(
        paymentMethodsFilterArray
      );
      const pageAndLimit =
        queryParameters.length === 0
          ? `?page=${page}&limit=${limit}`
          : `&page=${page}&limit=${limit}`;

      const listAll = list_all_methods ? `&list_all_methods=true` : '';

      const response = await api.get(
        `/api/paymentmethods${queryParameters}${pageAndLimit}${listAll}`
      );

      dispatch(setPaymentMethodsToServiceOrder(response.data.data));
      dispatch(setPaymentMethods(response.data.data));
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setisFetchingPaymentMethods(false));
    }
  };

export const fetchPaymentMethods =
  ({
    page = 1,
    limit = 6,
    list_all_methods,
    cb,
  }: {
    page?: number;
    limit?: number;
    list_all_methods?: boolean;
    cb?: any;
  }): AppThunk =>
  async (dispatch, getState) => {
    const { setisFetchingPaymentMethods, setPaymentMethods } =
      paymentMethodsSlice.actions;
    dispatch(setisFetchingPaymentMethods(true));
    try {
      const state = getState();
      const { paymentMethodsFilterArray } = state.paymentMethods;
      const queryParameters = queryStringFromFilterArray(
        paymentMethodsFilterArray
      );
      const pageAndLimit =
        queryParameters.length === 0
          ? `?page=${page}&limit=${limit}`
          : `&page=${page}&limit=${limit}`;

      const listAll = list_all_methods ? `&list_all_methods=true` : '';

      const response = await api.get(
        `/api/paymentmethods${queryParameters}${pageAndLimit}${listAll}`
      );
      dispatch(setPaymentMethods(response.data.data));
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setisFetchingPaymentMethods(false));
    }
  };

export const fetchPaymentMethodById =
  (paymentMethodId: string): AppThunk =>
  async (dispatch) => {
    const { setisFetchingPaymentMethod, setCurrentPaymentMethod } =
      paymentMethodsSlice.actions;
    dispatch(setisFetchingPaymentMethod(true));
    try {
      const response = await api.get(`/api/paymentmethods/${paymentMethodId}`);
      dispatch(setCurrentPaymentMethod(response.data.data));
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setisFetchingPaymentMethod(false));
    }
  };

export const createPaymentMethod =
  (paymentMethod: IPaymentMethod): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingOrUpdatingPaymentMethod } =
      paymentMethodsSlice.actions;
    dispatch(setIsCreatingOrUpdatingPaymentMethod(true));
    try {
      const response = await api.post(`/api/paymentmethods`, paymentMethod);
      toast.success("Método de pagamento cadastrado", toastOptions);

      if (response.data.data.idmetodopagamento) {
        const idmetodopagamento = response.data.data.idmetodopagamento;

        history.replace(`/financeiro/metodos-pagamento/form/${idmetodopagamento}`);
      }
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setIsCreatingOrUpdatingPaymentMethod(false));
    }
  };

export const updatePaymentMethod =
  ({
    paymentMethod,
    paymentMethodId,
    redirect
  }: {
    paymentMethod: IPaymentMethod;
    paymentMethodId: string;
    redirect?: boolean;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingOrUpdatingPaymentMethod } =
      paymentMethodsSlice.actions;
    dispatch(setIsCreatingOrUpdatingPaymentMethod(true));
    try {
      await api.put(`/api/paymentmethods/${paymentMethodId}`, paymentMethod);

      if (!redirect) {
        dispatch(fetchPaymentMethodById(paymentMethodId));
        dispatch(
          fetchPaymentMethods({ list_all_methods: true })
        );
      }

      if (redirect) {
        history.replace("/financeiro/metodos-pagamento")
      }

      toast.success("Método de pagamento atualizado", toastOptions);
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setIsCreatingOrUpdatingPaymentMethod(false));
    }
  };

export const deletePaymentMethod =
  (paymentMethodId: string): AppThunk =>
  async (dispatch, getState) => {
    const { setIsDeletingPaymentMethod } = paymentMethodsSlice.actions;
    const { page, limit } = getState().paymentMethods;
    dispatch(setIsDeletingPaymentMethod(true));
    try {
      await api.delete(`/api/paymentmethods/${paymentMethodId}`);
      dispatch(fetchPaymentMethods({ page, limit }));
      toast.success("Método de pagamento excluído", toastOptions);
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setIsDeletingPaymentMethod(false));
    }
  };

export const {
  resetBrandCardsFilterArray,
  resetPaymentMethodsFilterArray,
  updateBrandCardsFilterArray,
  updatePaymentMethodsFilterArray,
  setCurrentPaymentMethod,
  setPaymentMethods,
  setPage,
  setLimit,
} = paymentMethodsSlice.actions;

export default paymentMethodsSlice.reducer;
