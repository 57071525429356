import {
  AnyAction,
  createSlice,
  PayloadAction,
  ThunkAction,
} from "@reduxjs/toolkit";
import {
  ISchedule,
  IScheduleForm,
  IScheduleFormUpdate,
  IScheduleExceptionForm,
  IScheduleException,
  ICronograma,
  IScheduleRestriction,
  IScheduleHistory,
} from "models/Schedule";
import { IFilter } from "models/shared";
import { ISelectOption } from "pages/SchedulePage";
import { AppDispatch, AppThunk, rootState } from "store";
import { toast } from "react-toastify";
import toastOptions from "utils/toastOptions";
import api from "utils/API";
import { history } from "utils/history";
import { queryStringFromFilterArray } from "utils/network";
import { fetchAppointmentsByClinicId } from "../AppointmentsReducer";

interface IInitialState {
  currentPatient: boolean;
  isFetchingSchedules: boolean;
  isFetchingSchedulesAndExceptions: boolean;
  isFetchingSchedulesRestrictions: boolean;
  isCreatingSchedule: boolean;
  isDeletingSchedule: boolean;
  isFetchingScheduleHistory: boolean;
  isCreatingScheduleHistory: boolean;
  scheduleHistory: IScheduleHistory[];
  schedules: ISchedule[] | null;
  scheduleExceptions: IScheduleException[];
  scheduleRestrictions: IScheduleRestriction[];
  currentSchedule: ISchedule | null;
  currentScheduleRestriction: IScheduleRestriction | null;
  total: number;
  page: number;
  limit: number;
  filterArray: IFilter[];
}

const initialState: IInitialState = {
  scheduleExceptions: [],
  scheduleRestrictions: [],
  scheduleHistory: [],
  currentPatient: false,
  isFetchingSchedules: false,
  isFetchingSchedulesAndExceptions: false,
  isFetchingSchedulesRestrictions: false,
  isCreatingSchedule: false,
  isDeletingSchedule: false,
  isFetchingScheduleHistory: false,
  isCreatingScheduleHistory: false,
  schedules: null,
  currentSchedule: null,
  currentScheduleRestriction: null,
  total: 0,
  page: 0,
  limit: 6,
  filterArray: [],
};

const scheduleSlice = createSlice({
  name: "scheduleSlice",
  initialState,
  reducers: {
    setScheduleExceptions: (
      state,
      { payload }: PayloadAction<IScheduleException[]>
    ) => {
      state.scheduleExceptions = payload;
    },
    setScheduleRestrictions: (
      state,
      {
        payload: { schedules_restrictions },
      }: PayloadAction<{
        schedules_restrictions: IScheduleRestriction[];
      }>
    ) => {
      state.scheduleRestrictions = schedules_restrictions;
    },
    setCurrentScheduleRestriction: (
      state,
      { payload }: PayloadAction<IScheduleRestriction>
    ) => {
      state.currentScheduleRestriction = payload;
    },
    setCurrentPatient: (state, { payload }: PayloadAction<boolean>) => {
      state.currentPatient = payload;
    },
    setIsFetchingSchedules: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingSchedules = payload;
    },
    setIsFetchingSchedulesAndExceptions: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingSchedulesAndExceptions = payload;
    },
    setIsFetchingSchedulesRestrictions: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingSchedulesRestrictions = payload;
    },
    setIsCreatingSchedule: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreatingSchedule = payload;
    },
    setisDeletingSchedule: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeletingSchedule = payload;
    },
    setIsFetchingScheduleHistory: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingScheduleHistory = payload;
    },
    setIsCreatingScheduleHistory: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isCreatingScheduleHistory = payload;
    },
    setScheduleHistory: (
      state,
      { payload }: PayloadAction<IScheduleHistory[]>
    ) => {
      state.scheduleHistory = payload;
    },
    setSchedules: (
      state,
      {
        payload: { schedules, total, page },
      }: PayloadAction<{
        schedules: ISchedule[];
        total: number;
        page: number;
      }>
    ) => {
      state.schedules = schedules;
      state.total = total;
      state.page = page;
    },
    setCurrentSchedule: (state, { payload }: PayloadAction<ISchedule>) => {
      state.currentSchedule = payload;
    },
    updateFilter: (state, { payload }: PayloadAction<IFilter>) => {
      const index = state.filterArray.findIndex(
        (item) => item.key === payload.key
      );
      state.filterArray[index].value = payload.value;
    },
    setLimit: (state, { payload }: PayloadAction<number>) => {
      state.limit = payload;
    },
  },
});

export const fetchSchedules =
  ({
    page = 1,
    limit = 6,
    professionalId,
  }: {
    page?: number;
    limit?: number;
    professionalId: number | string;
    // clinicId: number | string;
  }): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { setIsFetchingSchedules, setSchedules } = scheduleSlice.actions;
    dispatch(setIsFetchingSchedules(true));
    try {
      const state = getState();
      const { filterArray } = state.schedule;
      const queryParameters = queryStringFromFilterArray(filterArray);
      const response = await api.get(
        `/api/schedules?page=${page}&limit=${limit}&idprofissional=${professionalId}${queryParameters}`
      );
      dispatch(setSchedules(response.data.data));
      dispatch(setIsFetchingSchedules(false));
    } catch (error) {
      dispatch(setIsFetchingSchedules(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchSchedulesExceptions =
  (professionalId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { setIsFetchingSchedules, setScheduleExceptions } =
      scheduleSlice.actions;
    dispatch(setIsFetchingSchedules(true));
    try {
      const response = await api.get(
        `/api/scheduleexceptions?idprofissional=${professionalId}&page=1&limit=9999`
      );
      // console.log(response.data.data.schedules)
      dispatch(setScheduleExceptions(response.data.data.schedules));
      dispatch(setIsFetchingSchedules(false));
    } catch (error) {
      dispatch(setIsFetchingSchedules(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchSchedulesById =
  (scheduleId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { setIsFetchingSchedules, setCurrentSchedule } =
      scheduleSlice.actions;
    dispatch(setIsFetchingSchedules(true));
    try {
      const response = await api.get(`/api/schedules/${scheduleId}`);
      dispatch(setCurrentSchedule(response.data.data));
      dispatch(setIsFetchingSchedules(false));
    } catch (error) {
      dispatch(setIsFetchingSchedules(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchSchedulesAndExceptions =
  ({
    page = 1,
    limit = 6,
    professionalId,
  }: {
    page?: number;
    limit?: number;
    professionalId: number | string;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { setIsFetchingSchedulesAndExceptions, setSchedules } =
      scheduleSlice.actions;
    dispatch(setIsFetchingSchedulesAndExceptions(true));
    try {
      const state = getState();
      const { filterArray } = state.schedule;
      const queryParameters = queryStringFromFilterArray(filterArray);
      const response = await api.get(
        `/api/schedules/list-all?page=${page}&limit=${limit}&idprofissional=${professionalId}${queryParameters}`
      );
      dispatch(setSchedules(response.data.data));
      dispatch(setIsFetchingSchedulesAndExceptions(false));
    } catch (error) {
      dispatch(setIsFetchingSchedulesAndExceptions(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchSchedulesRestrictionsByServiceItem =
  (
    scheduleId: string,
    serviceItemId: string
  ): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const {
      setIsFetchingSchedulesRestrictions,
      setCurrentScheduleRestriction,
    } = scheduleSlice.actions;
    dispatch(setIsFetchingSchedulesRestrictions(true));

    try {
      const response = await api.get(
        `/api/schedule-restrictions?idagenda=${scheduleId}&iditemservico=${serviceItemId}`
      );

      if (response.data.data.schedule_restrictions.length > 0) {
        dispatch(
          setCurrentScheduleRestriction(
            response.data.data.schedule_restrictions[0]
          )
        );
      }

      dispatch(setIsFetchingSchedulesRestrictions(false));
    } catch (error) {
      dispatch(setIsFetchingSchedulesRestrictions(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchSchedulesRestrictions =
  (scheduleId: string): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsFetchingSchedulesRestrictions, setScheduleRestrictions } =
      scheduleSlice.actions;
    dispatch(setIsFetchingSchedulesRestrictions(true));

    try {
      const response = await api.get(
        `/api/schedule-restrictions?idagenda=${scheduleId}`
      );

      dispatch(
        setScheduleRestrictions({
          schedules_restrictions: response.data.data.schedule_restrictions,
        })
      );
      dispatch(setIsFetchingSchedulesRestrictions(false));
    } catch (error) {
      dispatch(setIsFetchingSchedulesRestrictions(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const createScheduleRestriction =
  ({
    scheduleId,
    serviceItemId,
    atendimentospermitidos,
    professionalId,
    idconvenio,
    limiteconvenios,
  }: {
    scheduleId: string;
    serviceItemId: string;
    atendimentospermitidos?: number;
    professionalId: string;
    idconvenio?: string;
    limiteconvenios?: number;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setisDeletingSchedule } = scheduleSlice.actions;
    dispatch(setisDeletingSchedule(true));
    try {
      await api.post(`/api/schedule-restrictions`, {
        idagenda: scheduleId,
        iditemservico: serviceItemId,
        atendimentospermitidos,
        idconvenio,
        limiteconvenios,
      });
      dispatch(setisDeletingSchedule(false));
      toast.success("Restrição criada com sucesso", toastOptions);

      dispatch(fetchSchedulesById(scheduleId));
    } catch (error: any) {
      dispatch(setisDeletingSchedule(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const updateScheduleRestriction =
  ({
    restrictionId,
    atendimentospermitidos,
    limiteconvenios,
    professionalId,
  }: {
    restrictionId: string;
    atendimentospermitidos?: number;
    limiteconvenios?: number;
    professionalId: string;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsCreatingSchedule } = scheduleSlice.actions;

    dispatch(setIsCreatingSchedule(true));

    try {
      await api.put(`/api/schedule-restrictions/${restrictionId}`, {
        atendimentospermitidos,
        limiteconvenios,
      });

      dispatch(setIsCreatingSchedule(false));
      toast.success("Restrição atualizada com sucesso", toastOptions);

      // dispatch(fetchSchedulesAndExceptions({ professionalId }));
    } catch (error: any) {
      dispatch(setIsCreatingSchedule(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const removeScheduleRestriction =
  ({
    restrictionId,
    scheduleId,
  }: {
    restrictionId: string;
    scheduleId: string;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsCreatingSchedule } = scheduleSlice.actions;

    dispatch(setIsCreatingSchedule(true));

    try {
      await api.delete(`/api/schedule-restrictions/${restrictionId}`);

      dispatch(setIsCreatingSchedule(false));
      toast.success("Restrição removida com sucesso", toastOptions);

      dispatch(fetchSchedulesById(scheduleId));
    } catch (error: any) {
      dispatch(setIsCreatingSchedule(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const createScheduleException =
  ({
    schedule,
    professionalId,
    isAvaliable,
    cb,
  }: {
    isAvaliable: Boolean;
    schedule: IScheduleExceptionForm;
    professionalId: string;
    cb: () => void;
  }): AppThunk =>
  async (dispatch: AppDispatch) => {
    let dataToSend: any;
    console.log(schedule);
    if (!isAvaliable) {
      dataToSend = {
        dataexcecaoinicio: schedule.dataexcecaoinicio,
        dataexcecaofim: schedule.dataexcecaofim,
        idclinica: schedule.idclinica,
        idprofissional: schedule.idprofissional,
        titulo: schedule.titulo,
        type_vacancy: schedule.type_vacancy,
      };
    } else {
      dataToSend = {
        dataexcecaoinicio: schedule.dataexcecaoinicio,
        dataexcecaofim: schedule.dataexcecaofim,
        cronograma: [
          {
            horarios: schedule.cronograma[0].horarios,
            diasdasemana: schedule.cronograma[0].diasdasemana,
            limitepacientes: schedule.cronograma[0].limitepacientes,
          },
        ],
        duracaoagendamentos: schedule.duracaoagendamentos,
        idclinica: schedule.idclinica,
        idprofissional: schedule.idprofissional,
        itensservico: schedule.itensservico,
        titulo: schedule.titulo,
        type_vacancy: schedule.type_vacancy,
      };
    }
    const { setIsCreatingSchedule } = scheduleSlice.actions;
    dispatch(setIsCreatingSchedule(true));
    try {
      await api.post(`/api/scheduleexceptions`, dataToSend);
      dispatch(setIsCreatingSchedule(false));
      // history.replace(`/users/${professionalId}/schedules`);
      cb();
      toast.success("Exceção de Agenda cadastrada", toastOptions);
    } catch (error) {
      dispatch(setIsCreatingSchedule(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const createSchedule =
  ({
    schedule,
    professionalId,
    cb,
  }: // clinicId,
  // departmentId,
  {
    schedule: IScheduleForm;
    professionalId: string;
    cb: () => void;
  }): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { setIsCreatingSchedule } = scheduleSlice.actions;
    dispatch(setIsCreatingSchedule(true));
    try {
      await api.post(`/api/schedules`, schedule);
      dispatch(setIsCreatingSchedule(false));
      // history.replace(`/users/${professionalId}/schedules`);
      cb();
      toast.success("Agenda cadastrada", toastOptions);
    } catch (error) {
      dispatch(setIsCreatingSchedule(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const updateScheduleException =
  ({
    schedule,
    scheduleId,
    professionalId,
    isAvaliable,
    cb,
  }: {
    isAvaliable: boolean;
    schedule: IScheduleExceptionForm;
    scheduleId: string;
    professionalId: string;
    cb: () => void;
  }): AppThunk =>
  async (dispatch: AppDispatch) => {
    let dataToSend: any;
    if (!isAvaliable) {
      dataToSend = {
        cronograma: [],
        itensservico: [],
        dataexcecaoinicio: schedule.dataexcecaoinicio,
        dataexcecaofim: schedule.dataexcecaofim,
        idclinica: schedule.idclinica,
        idprofissional: schedule.idprofissional,
        titulo: schedule.titulo,
        type_vacancy: schedule.type_vacancy,
      };
    } else {
      dataToSend = {
        dataexcecaoinicio: schedule.dataexcecaoinicio,
        dataexcecaofim: schedule.dataexcecaofim,
        cronograma: [
          {
            horarios: schedule.cronograma[0].horarios,
            diasdasemana: schedule.cronograma[0].diasdasemana,
            limitepacientes: schedule.cronograma[0].limitepacientes,
          },
        ],
        duracaoagendamentos: schedule.duracaoagendamentos,
        idclinica: schedule.idclinica,
        idprofissional: schedule.idprofissional,
        itensservico: schedule.itensservico,
        titulo: schedule.titulo,
        type_vacancy: schedule.type_vacancy,
      };
    }
    const { setIsCreatingSchedule } = scheduleSlice.actions;
    dispatch(setIsCreatingSchedule(true));
    try {
      await api.put(`/api/scheduleexceptions/${scheduleId}`, dataToSend);
      dispatch(setIsCreatingSchedule(false));
      toast.success("Exceção de Agenda atualizada", toastOptions);
      // history.replace(`/users/${professionalId}/schedules`);
      cb();
    } catch (error) {
      dispatch(setIsCreatingSchedule(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const updateSchedule =
  ({
    schedule,
    scheduleId,
    professionalId,
    cb,
  }: {
    schedule: IScheduleFormUpdate;
    scheduleId: string;
    professionalId: string;
    cb: () => void;
  }): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { setIsCreatingSchedule } = scheduleSlice.actions;
    dispatch(setIsCreatingSchedule(true));
    try {
      await api.put(`/api/schedules/${scheduleId}`, schedule);
      dispatch(setIsCreatingSchedule(false));
      toast.success("Agenda atualizada", toastOptions);
      // history.replace(`/users/${professionalId}/schedules`);
      cb();
    } catch (error) {
      dispatch(setIsCreatingSchedule(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const remakeSchedule =
  ({
    idconsulta,
    idagenda,
    idprofissional,
    data,
    forcado,
    retornoforcado,
    email,
    password,
    observacao,
    hasPermission,
    checkin,
    cb,
    forceAppointmentReturnCallback,
  }: {
    idconsulta: string;
    idagenda: string;
    idprofissional?: string;
    data: string;
    forcado?: boolean;
    retornoforcado?: boolean;
    email?: string;
    password?: string;
    observacao?: string;
    hasPermission?: boolean;
    checkin?: boolean;
    cb: () => void;
    forceAppointmentReturnCallback?: (
      isForced: boolean,
      errorMessage: string
    ) => void;
  }): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      await api.post(`/api/appointments/${idconsulta}/reschedule`, {
        data,
        idagenda,
        idprofissional,
        forcado,
        retornoforcado,
        email,
        password,
        observacao,
        hasPermission,
        checkin,
      });

      if (checkin) {
        toast.success(
          "Reagendamento realizado, tentativa de check-in realizada. Para confirmar revise o agendamento.",
          toastOptions
        );
      } else {
        toast.success("Reagendamento realizado", toastOptions);
      }

      dispatch(fetchAppointmentsByClinicId());
      if (cb) {
        cb();
      }
    } catch (error) {
      if (error.response) {
        if (
          forceAppointmentReturnCallback &&
          (error.response.data?.error?.message.includes(
            "Retorno fora do prazo"
          ) ||
            error.response.data?.error?.message.includes(
              "Email e senha necessários"
            ))
        ) {
          forceAppointmentReturnCallback(
            true,
            error.response.data?.error?.message
          );
        } else {
          toast.error(error.response.data?.error?.message, toastOptions);
        }
      } else {
        console.log(error.message);
      }
    }
  };

export const deleteSchedule =
  ({
    scheduleId,
    professionalId,
    isDisabling,
    cronograma,
  }: {
    scheduleId: string;
    professionalId: string;
    isDisabling: boolean;
    cronograma?: ICronograma[];
  }): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { setisDeletingSchedule } = scheduleSlice.actions;
    dispatch(setisDeletingSchedule(true));
    var url = `/api/schedules/${scheduleId}`;
    var data = { ativo: !isDisabling, cronograma: cronograma };
    try {
      await api.put(url, data);
      dispatch(setisDeletingSchedule(false));
      toast.success(
        `Agenda ${isDisabling ? `desativado` : `ativado`} com sucesso`,
        toastOptions
      );
      dispatch(fetchSchedules({ professionalId }));
    } catch (error) {
      dispatch(setisDeletingSchedule(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const deleteScheduleException =
  ({
    scheduleId,
    professionalId,
    isDisabling,
  }: {
    scheduleId: string;
    professionalId: string;
    isDisabling: boolean;
  }): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { setisDeletingSchedule } = scheduleSlice.actions;
    dispatch(setisDeletingSchedule(true));
    var url = `/api/scheduleexceptions/${scheduleId}`;
    // var data = { ativo: !isDisabling }
    try {
      await api.delete(url);
      dispatch(setisDeletingSchedule(false));
      toast.success(
        `Agenda ${isDisabling ? `desativada` : `ativada`} com sucesso`,
        toastOptions
      );
      // toast.success(`Agenda removida com sucesso`, toastOptions);
      dispatch(fetchSchedulesExceptions(professionalId));
    } catch (error) {
      dispatch(setisDeletingSchedule(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchScheduleHistory =
  ({ scheduleId }: { scheduleId?: string }): AppThunk =>
  async (dispatch) => {
    const { setIsFetchingScheduleHistory, setScheduleHistory } =
      scheduleSlice.actions;
    dispatch(setIsFetchingScheduleHistory(true));

    try {
      if (scheduleId) {
        const response = await api.get(
          `/api/schedule-changelogs?idagenda=${scheduleId}`
        );

        dispatch(setScheduleHistory(response.data.data as IScheduleHistory[]));
      }
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setIsFetchingScheduleHistory(false));
    }
  };

export const createScheduleHistory =
  ({
    scheduleId,
    descricao,
    observacao,
    disableToast,
    callback,
  }: {
    scheduleId: string;
    descricao: string;
    observacao?: string;
    disableToast?: boolean;
    callback?: Function;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingScheduleHistory } = scheduleSlice.actions;
    dispatch(setIsCreatingScheduleHistory(true));

    try {
      await api.post(`/api/schedule-changelogs`, {
        idagenda: scheduleId,
        descricao: descricao,
        observacao: observacao,
      });

      if (!disableToast) {
        toast.success("Atividade adicionada ao histórico", toastOptions);
      }

      if (callback) callback();
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setIsCreatingScheduleHistory(false));
    }
  };

export const { setCurrentPatient, setLimit, setCurrentScheduleRestriction } =
  scheduleSlice.actions;

export default scheduleSlice.reducer;
