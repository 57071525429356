import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IServicePackage,
  IServicePackageForm,
  IServicePackageServiceItem,
} from "models/ServicePackages";
import { IFilter } from "models/shared/index";
import { toast } from "react-toastify";
import { AppThunk } from "store/index";
import api from "utils/API";
import { history } from "utils/history";
import { queryStringFromFilterArray } from "utils/network";
import toastOptions from "utils/toastOptions";

interface IInitialState {
  isFetchingServicePackages: boolean;
  isCreatingServicePackages: boolean;
  isDeletingServicePackages: boolean;
  isLinkingServiceItems: boolean;
  isUnlinkingServiceItems: boolean;
  isSchedulingServicePackages: boolean;
  servicePackages: IServicePackage[] | null;
  servicePackagesToBeScheduled: IServicePackageServiceItem[];
  isServicePackageDialogOpen: boolean;
  currentServicePackage: IServicePackage | null;
  total: number;
  page: number;
  filterArray: IFilter[];
  servicePackageServiceItems: IServicePackageServiceItem[] | null;
  isFetchingServicePacakgeServiceItems: boolean;
  servicePackageServiceItemsTotal: number;
  servicePackageServiceItemsPage: number;
}

const initialState: IInitialState = {
  isFetchingServicePackages: false,
  isCreatingServicePackages: false,
  isDeletingServicePackages: false,
  isLinkingServiceItems: false,
  isUnlinkingServiceItems: false,
  isSchedulingServicePackages: false,
  servicePackages: null,
  servicePackagesToBeScheduled: [],
  isServicePackageDialogOpen: false,
  currentServicePackage: null,
  total: 0,
  page: 0,
  filterArray: [
    { key: "titulo", value: [] },
    { key: "ativo", value: null },
  ],
  servicePackageServiceItems: null,
  isFetchingServicePacakgeServiceItems: false,
  servicePackageServiceItemsPage: 0,
  servicePackageServiceItemsTotal: 0,
};

const servicePackagesSlice = createSlice({
  name: "servicePackagesSlice",
  initialState,
  reducers: {
    setIsFetchingServicePackages: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingServicePackages = payload;
    },
    setisCreatingServicePackages: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isCreatingServicePackages = payload;
    },
    setisDeletingServicePackages: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isDeletingServicePackages = payload;
    },
    setIsSchedulingServicePackages: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isSchedulingServicePackages = payload;
    },
    setServicePackages: (
      state,
      {
        payload: { combos, total, page },
      }: PayloadAction<{
        combos: IServicePackage[];
        total: number;
        page: number;
      }>
    ) => {
      state.servicePackages = combos;
      state.total = total;
      state.page = page;
    },
    setServicePackageToBeScheduled: (
      state,
      {
        payload: { servicePackages },
      }: PayloadAction<{
        servicePackages: IServicePackageServiceItem[];
      }>
    ) => {
      const verifiedServicePackages: IServicePackageServiceItem[] = [];
      servicePackages.forEach((servicePackage) => {
        if (servicePackage.ativo) {
          verifiedServicePackages.push(servicePackage);
        }
      });
      state.servicePackagesToBeScheduled = verifiedServicePackages;
    },
    clearServicePackagesToBeScheduled: (
      state,
      { payload }: PayloadAction<any[]>
    ) => {
      state.servicePackagesToBeScheduled = [];
    },
    setIsServicePackageDialogOpen: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isServicePackageDialogOpen = payload;
    },
    removeServicePacakgeToBeScheduled: (
      state,
      {
        payload: { serviceItemId },
      }: PayloadAction<{
        serviceItemId: string;
      }>
    ) => {
      state.servicePackagesToBeScheduled =
        state.servicePackagesToBeScheduled.filter(
          (servicePackage) => servicePackage.iditemservico !== serviceItemId
        );
    },
    setCurrentServicePackage: (
      state,
      { payload }: PayloadAction<IServicePackage>
    ) => {
      state.currentServicePackage = payload;
    },
    updateFilter: (state, { payload }: PayloadAction<IFilter>) => {
      const index = state.filterArray.findIndex(
        (item) => item.key === payload.key
      );
      if (index === -1) {
        state.filterArray.push({ key: payload.key, value: payload.value });
      } else {
        state.filterArray[index].value = payload.value;
      }
    },
    clearFilterArray: (state) => {
      state.filterArray = [
        { key: "titulo", value: [] },
        { key: "ativo", value: null },
      ];
    },
    setServicePackageServiceItems: (
      state,
      {
        payload: { promotional_items, total, page },
      }: PayloadAction<{
        promotional_items: IServicePackageServiceItem[];
        total: number;
        page: number;
      }>
    ) => {
      state.servicePackageServiceItems = promotional_items;
      state.servicePackageServiceItemsTotal = total;
      state.servicePackageServiceItemsPage = page;
    },
    setIsFetchingServicePackageServiceItems: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingServicePacakgeServiceItems = payload;
    },
    setIsLinkingServiceItems: (state, { payload }: PayloadAction<boolean>) => {
      state.isLinkingServiceItems = payload;
    },
    setIsUnlinkingServiceItems: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isUnlinkingServiceItems = payload;
    },
  },
});

export const fetchServicePackages =
  ({
    page = 1,
    limit = 6,
    ativo,
  }: {
    page?: number;
    limit?: number;
    ativo?: boolean;
  }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingServicePackages } = servicePackagesSlice.actions;
    dispatch(setIsFetchingServicePackages(true));
    try {
      const state = getState();
      const { filterArray } = state.servicePackages;
      const queryParameters = queryStringFromFilterArray(filterArray);
      const pageAndLimit =
        queryParameters.length === 0
          ? `?page=${page}&limit=${limit}`
          : `&page=${page}&limit=${limit}`;

      const active = ativo ? "&ativo=1" : "";

      const response = await api.get(
        `/api/combos${queryParameters}${pageAndLimit}${active}`
      );
      dispatch(
        servicePackagesSlice.actions.setServicePackages(response.data.data)
      );
      dispatch(setIsFetchingServicePackages(false));
    } catch (error: any) {
      dispatch(setIsFetchingServicePackages(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchServicePackageById =
  (comboId: string): AppThunk =>
  async (dispatch) => {
    const { setIsFetchingServicePackages, setCurrentServicePackage } =
      servicePackagesSlice.actions;
    dispatch(setIsFetchingServicePackages(true));
    try {
      const response = await api.get(`/api/combos/${comboId}`);
      dispatch(setCurrentServicePackage(response.data.data));
      dispatch(setIsFetchingServicePackages(false));
    } catch (error: any) {
      dispatch(setIsFetchingServicePackages(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const createServicePackage =
  (combo: IServicePackageForm): AppThunk =>
  async (dispatch) => {
    const { setisCreatingServicePackages } = servicePackagesSlice.actions;
    dispatch(setisCreatingServicePackages(true));
    try {
      const response = await api.post(`/api/combos`, combo);
      history.replace(`/service-packages/form/${response.data.data.idcombo}`);
      dispatch(setisCreatingServicePackages(false));
      toast.success("Combo cadastrado", toastOptions);
    } catch (error: any) {
      dispatch(setisCreatingServicePackages(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const updateServicePackage =
  ({
    combo,
    comboId,
  }: {
    combo: IServicePackageForm;
    comboId: string | number;
  }): AppThunk =>
  async (dispatch) => {
    const { setisCreatingServicePackages } = servicePackagesSlice.actions;
    dispatch(setisCreatingServicePackages(true));
    try {
      await api.put(`/api/combos/${comboId}`, combo);
      dispatch(setisCreatingServicePackages(false));
      toast.success("Combo atualizado", toastOptions);
      history.replace("/service-packages");
    } catch (error: any) {
      dispatch(setisCreatingServicePackages(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const deleteServicePackage =
  (comboId: string): AppThunk =>
  async (dispatch) => {
    const { setisDeletingServicePackages } = servicePackagesSlice.actions;
    dispatch(setisDeletingServicePackages(true));
    try {
      await api.delete(`/api/combos/${comboId}`);
      dispatch(setisDeletingServicePackages(false));
      toast.success("Combo excluido com sucesso", toastOptions);
      dispatch(fetchServicePackages({}));
    } catch (error: any) {
      dispatch(setisDeletingServicePackages(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const listServicePackageServiceItems =
  ({
    idcombo,
    page = 1,
    limit = 999,
  }: {
    page?: number;
    limit?: number;
    idcombo: string;
  }): AppThunk =>
  async (dispatch) => {
    const {
      setIsFetchingServicePackageServiceItems,
      setServicePackageServiceItems,
    } = servicePackagesSlice.actions;
    dispatch(setIsFetchingServicePackageServiceItems(true));
    try {
      const response = await api.get(
        `/api/combos/${idcombo}/items?page=${page}&limit=${limit}`
      );
      dispatch(setIsFetchingServicePackageServiceItems(false));
      dispatch(setServicePackageServiceItems(response.data.data));
    } catch (error: any) {
      dispatch(setIsFetchingServicePackageServiceItems(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const addServiceItemToServicePackage =
  ({
    idcombo,
    promotional_items,
  }: {
    idcombo: string;
    promotional_items: {
      iditemservico: string;
      desconto: { valor: number; tipo: string };
    }[];
  }): AppThunk =>
  async (dispatch) => {
    const { setIsLinkingServiceItems } = servicePackagesSlice.actions;
    dispatch(setIsLinkingServiceItems(true));
    try {
      await api.post(`/api/combos/${idcombo}/items-batch`, {
        promotional_items,
      });
      dispatch(setIsLinkingServiceItems(false));
      const message =
        promotional_items.length > 0
          ? "Itens vinculados com sucesso"
          : "Item vinculado com sucesso";
      toast.success(message, toastOptions);
      history.replace(`/service-packages/${idcombo}/service-items`);
      dispatch(fetchServicePackages({}));
    } catch (error: any) {
      dispatch(setIsLinkingServiceItems(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const removeServiceItemFromServicePackage =
  ({
    servicePackageId,
    serviceItemId,
  }: {
    servicePackageId: string;
    serviceItemId: string;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsUnlinkingServiceItems } = servicePackagesSlice.actions;
    dispatch(setIsUnlinkingServiceItems(true));
    try {
      await api.delete(
        `/api/combos/${servicePackageId}/items/${serviceItemId}`
      );
      dispatch(setIsUnlinkingServiceItems(false));
      toast.success("Item desvinculado com sucesso", toastOptions);
      history.replace(`/service-packages/${servicePackageId}/service-items`);
      dispatch(listServicePackageServiceItems({ idcombo: servicePackageId }));
    } catch (error: any) {
      dispatch(setIsUnlinkingServiceItems(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const updateServicePackageServiceItemDiscount =
  ({
    servicePackageId,
    serviceItemId,
    discount,
    promotionalItemAdditionalIds,
    agendamentoativo,
    informations,
    agendamentodia,
    agendamentoposterior,
  }: {
    servicePackageId: string;
    serviceItemId: string;
    discount: { valor: number; tipo: string };
    promotionalItemAdditionalIds?: string[];
    informations?: string;
    agendamentoativo?: boolean;
    agendamentodia?: boolean;
    agendamentoposterior?: boolean;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsUnlinkingServiceItems } = servicePackagesSlice.actions;
    dispatch(setIsUnlinkingServiceItems(true));
    try {
      await api.put(`/api/combos/${servicePackageId}/items/${serviceItemId}`, {
        desconto: { ...discount },
        idsitensadicionais: promotionalItemAdditionalIds,
        informacoes: informations,
        agendamentoativo,
        agendamentodia,
        agendamentoposterior,
      });
      dispatch(setIsUnlinkingServiceItems(false));
      toast.success("Item atualizado com sucesso", toastOptions);
      history.replace(`/service-packages/${servicePackageId}/service-items`);
      dispatch(listServicePackageServiceItems({ idcombo: servicePackageId }));
    } catch (error: any) {
      dispatch(setIsUnlinkingServiceItems(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const updateServicePackagePromotionalItemPosition =
  ({
    servicePackageId,
    items,
  }: {
    servicePackageId: string;
    items: { iditempromocional: string; novaposicao: number }[];
  }): AppThunk =>
  async (dispatch) => {
    const { setIsFetchingServicePackageServiceItems } =
      servicePackagesSlice.actions;
    dispatch(setIsFetchingServicePackageServiceItems(true));
    try {
      await api.put(`/api/combos/${servicePackageId}/promotional-items`, {
        items,
      });
      dispatch(setIsFetchingServicePackageServiceItems(false));
      toast.success(
        "Ordenação de agendamento atualizado com sucesso",
        toastOptions
      );
      history.replace(`/service-packages/${servicePackageId}/service-items`);
      dispatch(listServicePackageServiceItems({ idcombo: servicePackageId }));
    } catch (error: any) {
      dispatch(setIsFetchingServicePackageServiceItems(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const updateServicePackagePromotionalItemPreparation =
  ({
    servicePackageId,
    items,
  }: {
    servicePackageId: string;
    items: {
      iditempromocional: string;
      grupopreparo: string;
      preparo: string;
    }[];
  }): AppThunk =>
  async (dispatch) => {
    const { setIsFetchingServicePackageServiceItems } =
      servicePackagesSlice.actions;
    dispatch(setIsFetchingServicePackageServiceItems(true));
    try {
      await api.put(
        `/api/combos/${servicePackageId}/preparation-promotional-items`,
        {
          items,
        }
      );
      dispatch(setIsFetchingServicePackageServiceItems(false));
      toast.success(
        "Preparo de agendamento adicionado com sucesso",
        toastOptions
      );
      dispatch(listServicePackageServiceItems({ idcombo: servicePackageId }));
    } catch (error: any) {
      dispatch(setIsFetchingServicePackageServiceItems(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const insertServicePackageGlobalDiscount =
  ({
    servicePackageId,
    data,
    callback,
  }: {
    servicePackageId: string;
    data: any;
    callback: Function;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsUnlinkingServiceItems } = servicePackagesSlice.actions;
    dispatch(setIsUnlinkingServiceItems(true));
    try {
      await api.post(`/api/combos/${servicePackageId}/discount`, data);
      dispatch(setIsUnlinkingServiceItems(false));

      if (callback) {
        callback();
      }

      toast.success("Desconto global cadastrado com sucesso", toastOptions);
      history.replace(`/service-packages/${servicePackageId}/service-items`);
      dispatch(listServicePackageServiceItems({ idcombo: servicePackageId }));
    } catch (error: any) {
      dispatch(setIsUnlinkingServiceItems(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const {
  setServicePackageServiceItems,
  setServicePackageToBeScheduled,
  setIsServicePackageDialogOpen,
  updateFilter: updateServicePackagesFilter,
  removeServicePacakgeToBeScheduled,
  setIsSchedulingServicePackages,
  setCurrentServicePackage,
  clearFilterArray,clearServicePackagesToBeScheduled
} = servicePackagesSlice.actions;

export default servicePackagesSlice.reducer;
