import {
  AnyAction,
  createSlice,
  PayloadAction,
  ThunkAction,
} from "@reduxjs/toolkit";
import { AppThunk, rootState } from "store/index";
import { toast } from "react-toastify";
import toastOptions from "utils/toastOptions";
import api from "utils/API";
import { IFilter } from "models/shared";
import { queryStringFromFilterArray } from "utils/network";
import { history } from "utils/history";
import { IAttendanceGroup, IAttendanceGroupForm } from "models/AttendanceGroup";

interface IInitialState {
  isFetchingAttendanceGroup: boolean;
  isCreatingAttendanceGroup: boolean;
  isDeletingAttendanceGroup: boolean;
  isEditBondDialogOpen: boolean;
  amountOfItemsToShow: number;
  attendanceGroups: IAttendanceGroup[] | null;
  currentAttendanceGroup: IAttendanceGroup | null;
  toAddPage: number;
  toAddTotal: number;
  total: number;
  page: number;
  filterArray: IFilter[];
  pageToAttendanceGroup: number;
}

const initialState: IInitialState = {
  isFetchingAttendanceGroup: false,
  isCreatingAttendanceGroup: false,
  isDeletingAttendanceGroup: false,
  isEditBondDialogOpen: false,
  toAddPage: 0,
  toAddTotal: 0,
  attendanceGroups: null,
  currentAttendanceGroup: null,
  amountOfItemsToShow: 6,
  total: 0,
  page: 0,
  filterArray: [{ key: "nome", value: [] }],
  pageToAttendanceGroup: 1,
};

const attendanceGrouplice = createSlice({
  name: "attendanceGrouplice",
  initialState,
  reducers: {
    setIsFetchingAttendanceGroup: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingAttendanceGroup = payload;
    },
    setIsCreatingAttendanceGroup: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isCreatingAttendanceGroup = payload;
    },
    setisDeletingAttendanceGroup: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isDeletingAttendanceGroup = payload;
    },
    setIsEditBondDialogOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isEditBondDialogOpen = payload;
    },
    setAmountOfItemsToShow: (state, { payload }: PayloadAction<number>) => {
      state.amountOfItemsToShow = payload;
    },
    setAttendanceGroup: (
      state,
      {
        payload: { attendance_groups, total, page },
      }: PayloadAction<{
        attendance_groups: IAttendanceGroup[];
        total: number;
        page: number;
      }>
    ) => {
      state.attendanceGroups = attendance_groups;
      state.total = total;
      state.page = page;
    },
    setToAddPagination: (
      state,
      {
        payload: { total, page },
      }: PayloadAction<{
        page: number;
        total: number;
      }>
    ) => {
      state.toAddPage = page;
      state.toAddTotal = total;
    },
    setCurrentCounter: (
      state,
      { payload }: PayloadAction<IAttendanceGroup>
    ) => {
      state.currentAttendanceGroup = payload;
    },
    setPageToAttendanceGroup: (state, { payload }: PayloadAction<number>) => {
      state.pageToAttendanceGroup = payload;
    },
  },
});

export const fetchAttendanceGroup =
  ({
    page = 1,
    limit = 6,
    idclinica,
    idbloco,
    idsetor,
  }: {
    page?: number;
    limit?: number;
    idclinica?: string;
    idbloco?: string;
    idsetor?: string;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { setIsFetchingAttendanceGroup, setAttendanceGroup } =
      attendanceGrouplice.actions;
    dispatch(setIsFetchingAttendanceGroup(true));
    try {
      const state = getState();
      const { filterArray } = state.attendanceGroup;
      const queryParameters = queryStringFromFilterArray(filterArray);
      const pageAndLimit =
        queryParameters.length === 0
          ? `?page=${page}&limit=${limit}`
          : `&page=${page}&limit=${limit}`;
      const response = await api.get(
        `/api/attendance-group${pageAndLimit}&idclinica=${idclinica}&idbloco=${idbloco}&idsetor=${idsetor}`
      );
      dispatch(setAttendanceGroup(response.data.data));
      dispatch(setIsFetchingAttendanceGroup(false));
    } catch (error: any) {
      dispatch(setIsFetchingAttendanceGroup(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchAttendanceGroupById =
  (
    attendanceGroupId: string
  ): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsFetchingAttendanceGroup, setCurrentCounter } =
      attendanceGrouplice.actions;
    dispatch(setIsFetchingAttendanceGroup(true));
    try {
      const response = await api.get(
        `/api/attendance-group/${attendanceGroupId}`
      );
      dispatch(setCurrentCounter(response.data.data));
      dispatch(setIsFetchingAttendanceGroup(false));
    } catch (error: any) {
      dispatch(setIsFetchingAttendanceGroup(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const updateAttendanceGroup =
  ({
    attendanceGroup,
    attendanceGroupId,
    idclinica,
    idbloco,
    idsetor,
    idspainelchamado,
    cb,
  }: {
    attendanceGroup: IAttendanceGroupForm;
    attendanceGroupId: string | number;
    idclinica?: string;
    idsetor?: string;
    idbloco?: string;
    idspainelchamado?: string[];
    cb?: () => void;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingAttendanceGroup } = attendanceGrouplice.actions;

    dispatch(setIsCreatingAttendanceGroup(true));
    try {
      await api.put(`/api/attendance-group/${attendanceGroupId}`, {
        ...attendanceGroup,
        idclinica: idclinica,
        idbloco,
        idsetor,
        idspainelchamado,
      });
      dispatch(setIsCreatingAttendanceGroup(false));
      toast.success("Nível de atendimento atualizado", toastOptions);
      history.replace(
        `/clinics/${idclinica}/departments/${idsetor}/blocks/${idbloco}/attendance/attendance-groups`
      );
      if (cb) cb();
    } catch (error: any) {
      dispatch(setIsCreatingAttendanceGroup(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const createAttendanceGroup =
  (
    attendanceGroup: IAttendanceGroupForm,
    idclinica?: string,
    idsetor?: string,
    idbloco?: string
  ): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsCreatingAttendanceGroup } = attendanceGrouplice.actions;
    dispatch(setIsCreatingAttendanceGroup(true));
    try {
      await api.post(`/api/attendance-group`, {
        ...attendanceGroup,
        idclinica: idclinica,
        idsetor,
        idbloco,
      });
      dispatch(setIsCreatingAttendanceGroup(false));
      history.replace(
        `/clinics/${idclinica}/departments/${idsetor}/blocks/${idbloco}/attendance/attendance-groups`
      );
      toast.success("Nível de atendimento cadastrado", toastOptions);
    } catch (error: any) {
      dispatch(setIsCreatingAttendanceGroup(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const deleteAttendanceGroup =
  (
    attendanceGroupId: string,
    idclinica: string,
    idbloco: string,
    idsetor: string
  ): AppThunk =>
  async (dispatch) => {
    const { setisDeletingAttendanceGroup } = attendanceGrouplice.actions;
    dispatch(setisDeletingAttendanceGroup(true));
    try {
      await api.delete(`/api/attendance-group/${attendanceGroupId}`);
      dispatch(setisDeletingAttendanceGroup(false));
      toast.success("Nível de atendimento excluido com sucesso", toastOptions);
      dispatch(
        fetchAttendanceGroup({
          idclinica,
          idbloco,
          idsetor,
        })
      );
    } catch (error: any) {
      dispatch(setisDeletingAttendanceGroup(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const {
  setIsEditBondDialogOpen,
  setPageToAttendanceGroup,
  setAmountOfItemsToShow,
} = attendanceGrouplice.actions;

export default attendanceGrouplice.reducer;
