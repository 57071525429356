import { IFilter } from "models/shared/index";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "store/index";
import { toast } from "react-toastify";
import toastOptions from "utils/toastOptions";
import api from "utils/API";
import { history } from "utils/history";
import { queryStringFromFilterArray } from "utils/network";
import { IBlock, IBlockForm } from "models/Blocks";

interface IInitialState {
  isFetchingBlocks: boolean;
  isCreatingBlock: boolean;
  isDeletingBlock: boolean;
  blocks: IBlock[] | null;
  currentBlock: IBlock | null;
  total: number;
  page: number;
  filterArray: IFilter[];
}

const initialState: IInitialState = {
  isFetchingBlocks: false,
  isCreatingBlock: false,
  isDeletingBlock: false,
  blocks: null,
  currentBlock: null,
  total: 0,
  page: 0,
  filterArray: [{ key: "nome", value: [] }],
};

const blocksSlice = createSlice({
  name: "blocksSlice",
  initialState,
  reducers: {
    setIsFetchingBlocks: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingBlocks = payload;
    },
    setIsCreatingBlock: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreatingBlock = payload;
    },
    setisDeletingBlock: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeletingBlock = payload;
    },
    setBlocks: (
      state,
      {
        payload: { blocks, total, page },
      }: PayloadAction<{
        blocks: IBlock[];
        total: number;
        page: number;
      }>
    ) => {
      state.blocks = blocks;
      state.total = total;
      state.page = page;
    },
    setBlocksByClinic: (state, { payload }: PayloadAction<IBlock[]>) => {
      state.blocks = payload;
    },
    setCurrentBlock: (state, { payload }: PayloadAction<IBlock | null>) => {
      state.currentBlock = payload;
    },
    updateFilter: (state, { payload }: PayloadAction<IFilter>) => {
      const index = state.filterArray.findIndex(
        (item) => item.key === payload.key
      );
      state.filterArray[index].value = payload.value;
    },
  },
});

export const fetchBlocks =
  ({
    page = 1,
    limit = 6,
    departmentId,
  }: {
    page?: number;
    limit?: number;
    departmentId: number | string;
  }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingBlocks, setBlocks } = blocksSlice.actions;
    dispatch(setIsFetchingBlocks(true));
    try {
      const state = getState();
      const { filterArray } = state.blocks;
      const queryParameters = queryStringFromFilterArray(filterArray);
      const pageAndLimit =
        queryParameters.length === 0
          ? `?page=${page}&limit=${limit}`
          : `&page=${page}&limit=${limit}`;
      const response = await api.get(
        `/api/blocks${queryParameters}${pageAndLimit}&idsetor=${departmentId}`
      );
      dispatch(setBlocks(response.data.data));
      dispatch(setIsFetchingBlocks(false));
    } catch (error: any) {
      dispatch(setIsFetchingBlocks(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };
export const fetchBlocksByClinic =
  ({ clinicId }: { clinicId: string }): AppThunk =>
  async (dispatch) => {
    const { setIsFetchingBlocks, setBlocksByClinic } = blocksSlice.actions;
    dispatch(setIsFetchingBlocks(true));
    try {
      const response = await api.get(
        `/api/blocks-by-clinic?idclinica=${clinicId}`
      );
      dispatch(setBlocksByClinic(response.data.data));
      dispatch(setIsFetchingBlocks(false));
    } catch (error: any) {
      dispatch(setIsFetchingBlocks(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const blockActivation =
  ({
    isBlockActive,
    departmentId,
    blockId,
  }: {
    isBlockActive: boolean;
    departmentId: string | number;
    blockId: string | number;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingBlock } = blocksSlice.actions;
    dispatch(setIsCreatingBlock(true));
    try {
      await api.put(`/api/blocks/${blockId}`, { ativo: !isBlockActive });
      dispatch(setIsCreatingBlock(false));
      dispatch(
        fetchBlocks({
          departmentId,
        })
      );
      toast.success(
        `Bloco ${isBlockActive ? "desativado" : "ativado"} com sucesso`,
        toastOptions
      );
    } catch (error: any) {
      dispatch(setIsCreatingBlock(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchBlockById =
  (blockId: string): AppThunk =>
  async (dispatch) => {
    const { setIsFetchingBlocks, setCurrentBlock } = blocksSlice.actions;
    dispatch(setIsFetchingBlocks(true));
    try {
      const response = await api.get(`/api/blocks/${blockId}`);
      dispatch(setCurrentBlock(response.data.data));
      dispatch(setIsFetchingBlocks(false));
    } catch (error: any) {
      dispatch(setIsFetchingBlocks(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const createBlock =
  ({ block, clinicId }: { block: IBlockForm; clinicId: string }): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingBlock } = blocksSlice.actions;
    dispatch(setIsCreatingBlock(true));
    try {
      await api.post(`/api/blocks`, block);
      dispatch(setIsCreatingBlock(false));
      history.replace(
        `/clinics/${clinicId}/departments/${block.idsetor}/blocks`
      );
      toast.success("Bloco cadastrado", toastOptions);
    } catch (error: any) {
      dispatch(setIsCreatingBlock(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const updateBlock =
  ({
    block,
    blockId,
    clinicId,
  }: {
    block: IBlockForm;
    blockId: string;
    clinicId: string;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingBlock } = blocksSlice.actions;
    dispatch(setIsCreatingBlock(true));
    try {
      await api.put(`/api/blocks/${blockId}`, block);
      dispatch(setIsCreatingBlock(false));
      toast.success("Bloco atualizado", toastOptions);
      history.replace(
        `/clinics/${clinicId}/departments/${block.idsetor}/blocks`
      );
    } catch (error: any) {
      dispatch(setIsCreatingBlock(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const deleteBlock =
  ({
    blockId,
    departmentId,
  }: {
    blockId: string;
    departmentId: string;
  }): AppThunk =>
  async (dispatch) => {
    const { setisDeletingBlock } = blocksSlice.actions;
    dispatch(setisDeletingBlock(true));
    try {
      await api.delete(`/api/blocks/${blockId}`);
      dispatch(setisDeletingBlock(false));
      toast.success("Bloco excluido com sucesso", toastOptions);
      dispatch(fetchBlocks({ departmentId }));
    } catch (error: any) {
      dispatch(setisDeletingBlock(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const { updateFilter: updateBlocksFilter, setCurrentBlock, setBlocksByClinic } =
  blocksSlice.actions;

export default blocksSlice.reducer;
