import React from "react";

import { v4 as uuidv4 } from "uuid";

import {
  Box,
  Chip,
  createStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  withStyles,
  Grid,
  Button,
  Popover,
  CircularProgress
} from "@material-ui/core";

import {
  BarChart,
  TrendingDown,
  TrendingUp,
  AttachMoneyTwoTone,
} from "@material-ui/icons";

import { IRankingCollaborator } from "store/reducers/ReportsReducer";
import { ServiceOrderResultsRows } from "./ServiceOrderResultsRows";
import { formatCurrency } from "utils/formatCurrency";
import GenericTableHead from "components/table/GenericTableHead";
import CardGeneralInfoDashboard from "components/Dashboard/CardGeneralInfoDashboard";
import CardGeneralInfoRank from "components/Dashboard/CardGeneralInfoRank";
import CardGeneralInfoRankAmount from "components/Dashboard/CardGeneralInfoRank/amount";
import TableDefault from "components/TableDefault";
import * as XLSX from "xlsx";
import dayjs from "dayjs";

import PrintRankingCollaborators from "./PrintRankingCollaborators";
import { PDFDownloadLink, pdf } from "@react-pdf/renderer";

const tableCells = [
  // { id: uuidv4(), alignment: "left", label: "NFS-e em Lote" },
  { id: uuidv4(), alignment: "left", label: "Top" },
  { id: uuidv4(), alignment: "left", label: "Categoria" },
  { id: uuidv4(), alignment: "center", label: "Item" },
  { id: uuidv4(), alignment: "center", label: "Quantidade" },
  { id: uuidv4(), alignment: "center", label: "Valor Faturamento" },
  { id: uuidv4(), alignment: "center", label: "% Faturamento" },
  { id: uuidv4(), alignment: "center", label: "Custo direto" },
  { id: uuidv4(), alignment: "center", label: "% Custo" },
  { id: uuidv4(), alignment: "center", label: "Margem" },
  { id: uuidv4(), alignment: "center", label: "% Margem" },
];

const tableAnalyticsServiceOrderCells = [
  { id: uuidv4(), alignment: "center", label: "Quitados", key: "payer_user_name" },
  {
    id: uuidv4(),
    alignment: "center",
    label: "Agendamentos",
    key: "appointment",
  },
  {
    id: uuidv4(),
    alignment: "center",
    label: "Data do atendimento",
    key: "served_date",
    typeData: "date",
    formatDate: "DD/MM/YYYY HH:mm",
  },
  {
    id: uuidv4(),
    alignment: "center",
    label: "Pagamento",
    key: "paid_date",
    typeData: "date",
  },
  {
    id: uuidv4(),
    alignment: "center",
    label: "Profissional",
    key: "professional_name",
  },
  { id: uuidv4(), alignment: "center", label: "Paciente", key: "patient_name" },
  {
    id: uuidv4(),
    alignment: "center",
    label: "Ordem de Serviço",
    style: { width: "24%" },
    key: "os_number",
  },
  { id: uuidv4(), alignment: "center", label: "Item", key: "item_description" },
  {
    id: uuidv4(),
    alignment: "center",
    label: "Valor da venda",
    key: "base_cost",
    typeData: "money",
  },
  {
    id: uuidv4(),
    alignment: "center",
    label: "Fat. Convênio",
    key: "amount_health_plan_billed",
    typeData: "money",
  },
  {
    id: uuidv4(),
    alignment: "center",
    label: "Valor Desconto",
    key: "amount_discount",
    typeData: "money",
  },
  {
    id: uuidv4(),
    alignment: "center",
    label: "Valor Líquido",
    key: "amount_paid",
    typeData: "money",
  },
  {
    id: uuidv4(),
    alignment: "center",
    label: "Custo direto",
    key: "direct_costs",
    typeData: "money",
  },
];

interface IProps {
  data: IRankingCollaborator;
  filter: Date[];
}

function iconCard(data: any) {
  if (data == "PRODUTIVIDADE") {
    return <TrendingUp className="trending-up" />;
  } else {
    return <AttachMoneyTwoTone />;
  }
}

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "rgba(0, 161, 169, 0.1)",
      borderBottom: 0,
      display: "tableRowGroup",
    },
    body: {
      fontSize: 14,
      fontWeight: 600,
    },
  })
)(TableCell);

const RankingCollaboratorsTable = ({ data, filter }: IProps) => {
  const [instancePdf, setInstancePdf] = React.useState<boolean>(false);
  const [instanceExcel, setInstanceExcel] = React.useState<boolean>(false);
  const [anchorExport, setAnchorExport] = React.useState(null);
  const open = Boolean(anchorExport);
  const id = open ? "simple-popover" : undefined;
  const handleClick = (event: any) => {
    setAnchorExport(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorExport(null);
  };

  async function createExcelFile(data: any) {
    setInstanceExcel(true)
    const wb = XLSX.utils.book_new();
    const summary = [
      {
        "Receita Total": data.total_all_groups,
        Agendamentos: data.total_all_appointmens,
        "Total de o.s quitadas": data.total_all_orders,
        "Receita de convênios": data.total_all_groups_plan,
      },
    ];
    const wsSummary = XLSX.utils.json_to_sheet(summary);
    XLSX.utils.book_append_sheet(
      wb,
      wsSummary,
      "Resumo geral dos COLABORADORES"
    );

    const aux = data.groupByRoleResponse.map((e: any) => {
      return {
        Cargo: e.role,
        "Receita do grupo": e.total_amount_billed,
        Agendamentos: e.total_appointments,
        "O.S quitadas": e.total_orders,
      };
    });
    const auxTest = XLSX.utils.json_to_sheet(aux);
    XLSX.utils.book_append_sheet(wb, auxTest, "Resumo entre cargos");

    data.groupByRoleResponse.forEach((role: any) => {
      const rows = role.data.map((row: any) => {
        const appointments = row.appointments.reduce(
          (acc: any, appointment: any) => {
            switch (appointment.type.toUpperCase()) {
              case "CONSULTAS":
                acc.C = `${appointment.value.toFixed(2)} (${appointment.percent.toFixed(
                  2
                )}%)`;
                break;
              case "EXAMES":
                acc.E = `${appointment.value.toFixed(2)} (${appointment.percent.toFixed(
                  2
                )}%)`;
                break;
              case "PROCEDIMENTOS":
                acc.P = `${appointment.value.toFixed(2)} (${appointment.percent.toFixed(
                  2
                )}%)`;
                break;
            }
            return acc;
          },
          {}
        );

        if (!appointments.C) appointments.C = "0 (0%)";
        if (!appointments.E) appointments.E = "0 (0%)";
        if (!appointments.P) appointments.P = "0 (0%)";

        const payments = row.payments.reduce((acc: any, payment: any) => {
          let paymentType = payment.type.toLowerCase();
          if (paymentType === "debito" || paymentType.includes("debito")) {
            paymentType = "Débito";
          }
          if (paymentType === "credito" || paymentType.includes("credito")) {
            paymentType = "credito";
          }
          if (
            paymentType === "transferência" ||
            paymentType === "transferencia"
          ) {
            paymentType = "transferencia";
          }
          acc[paymentType] = `${payment.value.toFixed(2)} (${payment.percent.toFixed(
            2
          )}%)`;
          return acc;
        }, {});

        [
          "dinheiro",
          "pix",
          "boleto",
          "credito",
          "Débito",
          "transferencia",
        ].forEach((paymentType) => {
          if (!payments[paymentType]) payments[paymentType] = "0 (0%)";
        });

        return {
          Colaborador: row.name,
          Receita: row.amount_billed,
          Agendamentos: row.appointments_amount,
          OS: row.serviceorders_amount,
          "Ticket Medio": row.medium_ticket ? row.medium_ticket.toFixed(2) : row.medium_ticket,
          Desconto: row.discounts_amount,
          "Orcamentos Criados": `${row.customer_quotes_amount
            } (${row.customer_quotes_percent.toFixed(1)}%)`,
          "Orcamentos Vendidos": `${row.customer_quotes_amount_done
            } (${row.customer_quotes_percent_done.toFixed(1)}%)`,
          Combos: row.combos_amount,
          ...appointments,
          ...payments,
        };
      });
      const wsRole = XLSX.utils.json_to_sheet(rows);

      XLSX.utils.book_append_sheet(wb, wsRole, role.role);
    });

    await XLSX.writeFile(wb, "PERFORMANCE DE COLABORADORES.xlsx");
    setInstanceExcel(false)
  }
  function dataFormatter(data: any) {
    return data.map((e: any) => {
      return {
        ...e,
        appointment: `${dayjs(e.appointment_date).format("DD/MM/YYYY HH:mm")} ${e.appointment_schedule_by || "-"
          }`,
      };
    });
  }

  async function downloadPDF() {
    setInstancePdf(true);
     await pdf(<PrintRankingCollaborators datas={data} filter={filter} />)
      .toBlob()
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "PERFORMANCE DE COLABORADORES.pdf";
        link.click();
       
      });
      setInstancePdf(false);
      return  
  }

  const dataCalculator = (data: any, type: string) => {
    return `${formatCurrency(
      data.find((item: any) =>
        item.type.toUpperCase().includes(type.toUpperCase())
      )?.value || 0
    )}
       (${Number(
      data.find((item: any) =>
        item.type.toUpperCase().includes(type.toUpperCase())
      )?.percent || 0
    ).toFixed(1)}%)`;
  };

  return (
    <>
      {/* New implemenations cards summary information */}
      <Grid
          item
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "1rem 1rem",
          }}
        >
          <Typography
            variant="body1"
            color="primary"
            style={{ fontSize: "17px", fontWeight: "bold" }}
          >
            Resumo geral
          </Typography>
          <Button
            variant="outlined"
            size="small"
            style={{
              backgroundColor: "#00a1a9",
              color: "#fff",
              border: "none",
              height: "40px",
            }}
            onClick={handleClick}
          >
            Exportar
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorExport}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Grid
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "5px",
                gap: "10px",
                width: "150px",
              }}
            >
              <Button
                variant="outlined"
                size="small"
                style={{
                  backgroundColor: "#00a1a9",
                  color: "#fff",
                  border: "none",
                }}
                disabled={instanceExcel}
                onClick={() => createExcelFile(data)}
              >
               { instanceExcel ? <CircularProgress size={22} style={{ color: "#fff" }}/> : "Excel"}
              </Button>
              <Button
                variant="outlined"
                size="small"
                style={{
                  backgroundColor: "#00a1a9",
                  color: "#fff",
                  border: "none",
                }}
                disabled={instancePdf}
                onClick={() => downloadPDF()}
              >
               { instancePdf ? <CircularProgress size={22} style={{ color: "#fff" }}/> : "PDF"}
              </Button>
            </Grid>
          </Popover>
        </Grid>
      <TableContainer>
        
        <Table size="small" >
          <TableBody>
            <TableRow key={"resumo"}>
              <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                Receita total
              </TableCell>
              <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                Receita de convênios
              </TableCell>
              <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                Agendamentos
              </TableCell>
              <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                Total de O.S quitadas
              </TableCell>
            </TableRow>
            <TableRow key={"resumo"}>
              <TableCell style={{ textAlign: "center" }}>
                {formatCurrency(data.total_all_groups)}
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                {formatCurrency(data.total_all_groups_plan)}
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                {data.total_all_appointmens}
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                {data.total_all_orders}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {data.groupByRoleResponse.map((ranking) => (
        <>
          <Typography
            variant="body1"
            style={{
              margin: "2rem 1rem 0rem",
              fontSize: "15px",
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
          >
            {ranking.role}
          </Typography>

          {ranking.tipo_visualizacao && (
            <Typography
              variant="body1"
              style={{
                margin: "0rem 1rem 0rem",
                fontSize: "13px",
                fontWeight: "500",
                textTransform: "uppercase",
                color: "#00A1AA",
              }}
            >
              Faturamento por {ranking.tipo_visualizacao}{" "}
              {iconCard(ranking.tipo_visualizacao)}
            </Typography>
          )}
          <TableContainer key={uuidv4()}>
          <Table size="small" style={{ marginTop: "2rem" }}>
            <TableBody>
              <TableRow key={"resumo"}>
                <TableCell style={{ fontWeight: "bold" }}>
                  Receita do grupo
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Convênio
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Agendamentos
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  O.S quitadas
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow key={"resumo"}>
                <TableCell>
                  {formatCurrency(ranking.total_amount_billed)}
                </TableCell>
                <TableCell>
                  {formatCurrency(ranking.total_amount_plan)}
                </TableCell>
                <TableCell>{ranking.total_appointments}</TableCell>
                <TableCell>{ranking.total_orders}</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <Table size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell style={{ textAlign: "center" }}>
                  Colaborador
                </StyledTableCell>
                <StyledTableCell style={{ textAlign: "center" }}>
                  Receita
                </StyledTableCell>
                <StyledTableCell style={{ textAlign: "center" }}>
                  Convênio
                </StyledTableCell>
                <StyledTableCell style={{ textAlign: "center" }}>
                  Agendamentos
                </StyledTableCell>
                <StyledTableCell style={{ textAlign: "center" }}>
                  O.S
                </StyledTableCell>
                <StyledTableCell style={{ textAlign: "center" }}>
                  Ticket Médio
                </StyledTableCell>
                <StyledTableCell style={{ textAlign: "center" }}>
                  Pacientes
                </StyledTableCell>
                <StyledTableCell style={{ textAlign: "center" }}>
                  C
                </StyledTableCell>
                <StyledTableCell style={{ textAlign: "center" }}>
                  E
                </StyledTableCell>
                <StyledTableCell style={{ textAlign: "center" }}>
                  P
                </StyledTableCell>
                <StyledTableCell style={{ textAlign: "center" }}>
                  Dinheiro
                </StyledTableCell>
                <StyledTableCell style={{ textAlign: "center" }}>
                  Pix
                </StyledTableCell>
                <StyledTableCell style={{ textAlign: "center" }}>
                  Boleto
                </StyledTableCell>
                <StyledTableCell style={{ textAlign: "center" }}>
                  Crédito
                </StyledTableCell>
                <StyledTableCell style={{ textAlign: "center" }}>
                  Débito
                </StyledTableCell>
                <StyledTableCell style={{ textAlign: "center" }}>
                  Transferência
                </StyledTableCell>
                <StyledTableCell style={{ textAlign: "center" }}>
                  Descontos
                </StyledTableCell>
                <StyledTableCell style={{ textAlign: "center" }}>
                  Combos
                </StyledTableCell>
                <StyledTableCell style={{ textAlign: "center" }}>
                  Orçamentos criados
                </StyledTableCell>
                <StyledTableCell style={{ textAlign: "center" }}>
                  Orçamentos vendidos
                </StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {ranking.data.map((user) => (
                <TableRow key={user.user_id}>
                  <TableCell>{user.name}</TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Chip label={formatCurrency(user.amount_billed)} />
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Chip label={formatCurrency(user.amount_plan)} />
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Chip label={user.appointments_amount} />
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Chip label={user.serviceorders_amount} />
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Chip label={formatCurrency(user.medium_ticket)} />
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Chip label={user.patients_amount} />
                  </TableCell>
                  <TableCell style={{ textAlign: "center", color: "#00A1AA" }}>
                    {dataCalculator(user.appointments, "CONSULTAS")}
                  </TableCell>
                  <TableCell style={{ textAlign: "center", color: "#00A1AA" }}>
                    {dataCalculator(user.appointments, "EXAMES")}
                  </TableCell>
                  <TableCell style={{ textAlign: "center", color: "#00A1AA" }}>
                    {dataCalculator(user.appointments, "PROCEDIMENTOS")}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {dataCalculator(user.payments, "DINHEIRO")}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {dataCalculator(user.payments, "PIX")}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {dataCalculator(user.payments, "BOLETO")}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {dataCalculator(user.payments, "CREDITO")}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {dataCalculator(user.payments, "DEBITO")}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {dataCalculator(user.payments, "TRANSFER")}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Chip label={formatCurrency(user.discounts_amount)} />
                  </TableCell>
                  <TableCell align="center">
                    <Chip label={formatCurrency(user.combos_amount)} />
                    {/* <Typography variant="body1" style={{ fontSize: "14px" }}>
                      ({Number(user.combo_ids.length)})
                    </Typography> */}
                  </TableCell>

                  {/* ORCAMENTOS CRIADOS */}
                  <TableCell align="center">
                    {formatCurrency(user.customer_quotes_amount)}
                    <Typography variant="body1" style={{ fontSize: "14px" }}>
                      ({`${Number(user.customer_quotes_percent).toFixed(1)}%`})
                    </Typography>
                  </TableCell>

                  <TableCell align="center">
                    {formatCurrency(user.customer_quotes_amount_done)}
                    <Typography variant="body1" style={{ fontSize: "14px" }}>
                      (
                      {`${Number(user.customer_quotes_percent_done).toFixed(
                        1
                      )}%`}
                      )
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </>
      ))}

      <TableContainer key={uuidv4()}>
        {/* EXTRATO ANALÍTICO DAS MOVIMENTAÇÕES FINANCEIRAS */}
        <Grid style={{ margin: "2rem 1rem 0rem" }}>
          <TableDefault
            titleColor="#00A1A9"
            titleSection="Extrato analitico das movimentacões:"
            fileNameCsv="EXTRATO"
            table={tableAnalyticsServiceOrderCells}
            data={dataFormatter(data.ordersItens)}
            titlePdf="Extrato analitico: "
            pagination="front"
            pdfOrientation="landscape"
            pdfBodyFontSize={7}
            pdfHeaderFontSize={10}
            paginationHeader={true}
            styleHeader={{ position: "sticky", top : 0, backgroundColor: "#fff"}}
          ></TableDefault>
        </Grid>
      </TableContainer>
    </>
  );
};

export default RankingCollaboratorsTable;
