export function getFullAge(data_nascimento: any) { // data nascimento formato YYYY-MM-DD
  const startingDate = new Date();
  let startDate = new Date(new Date(startingDate).toISOString().substr(0, 10));
  if (!data_nascimento) {
    data_nascimento = new Date().toISOString().substr(0, 10); // need date in YYYY-MM-DD format
  }
  let endDate = new Date(data_nascimento);
  if (startDate > endDate) {
    const swap = startDate;
    startDate = endDate;
    endDate = swap;
  }
  const startYear = startDate.getFullYear();
  const february = (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0 ? 29 : 28;
  const daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  let yearDiff = endDate.getFullYear() - startYear;
  let monthDiff = endDate.getMonth() - startDate.getMonth();
  if (monthDiff < 0) {
    yearDiff--;
    monthDiff += 12;
  }
  let dayDiff = endDate.getDate() - startDate.getDate();
  if (dayDiff < 0) {
    if (monthDiff > 0) {
      monthDiff--;
    } else {
      yearDiff--;
      monthDiff = 11;
    }
    dayDiff += daysInMonth[startDate.getMonth()];
  }

  const anos = yearDiff <= 1 ? "ano" : "anos";
  const meses = monthDiff <= 1 ? "mês" : "meses";
  const dias = dayDiff <= 1 ? "dia" : "dias";

  return `${yearDiff} ${anos} ${monthDiff} ${meses} ${dayDiff} ${dias}`
}