import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "store/index";
import { toast } from "react-toastify";
import toastOptions from "utils/toastOptions";
import api from "utils/API";
import {
  ICashDesk,
  ICashDeskForm,
  ICashDeskSumary,
  ICashDeskOverAllReport,
} from "models/CashDesk";
import { history } from "utils/history";
import { IFilter } from "models/shared";
import { queryStringFromFilterArray } from "utils/network";
import { ITransaction } from "models/Transaction";

type ISelectOption = {
  label: string;
  value: string;
};

interface IInitialState {
  isFetchingCashDesks: boolean;
  isCreatingOrUpdatingCashDesk: boolean;
  isDeletingCashDesk: boolean;
  isOpeningCashDesk: boolean;
  isClosingCashDesk: boolean;
  cashDesks: ICashDesk[];
  userCashDesk: null | ICashDesk;
  userAllCashDesk: ICashDesk[];
  page: number;
  total: number;
  pageUserCashDesk: number;
  totalUserCashDesk: number;
  currentCashDesk: null | ICashDesk;
  cashDesksFilterArray: IFilter[];
  cashDeskReportSumary: null | ICashDeskSumary;
  cashDeskReportTable: ITransaction[];
  cashDeskReportToPrint: any | null;
  isFetchingCashDeskReportData: boolean;
  cashDeskOverAllReport: ICashDeskOverAllReport | null;
  selectedClinics: ISelectOption[];
  selectedOperator: ISelectOption;
  allCashDeskOperators: ISelectOption[];
  blockedCloseCash: {
    state: boolean;
    message: string;
  };
}

const initialState: IInitialState = {
  cashDeskOverAllReport: null,
  isFetchingCashDesks: false,
  isCreatingOrUpdatingCashDesk: false,
  isDeletingCashDesk: false,
  isOpeningCashDesk: false,
  isClosingCashDesk: false,
  cashDesks: [],
  userCashDesk: null,
  userAllCashDesk: [],
  page: 0,
  total: 0,
  pageUserCashDesk: 0,
  totalUserCashDesk: 0,
  currentCashDesk: null,
  cashDesksFilterArray: [
    { key: "status", value: null },
    { key: "idusuario", value: null },
    { key: "idclinica", value: null },
    { key: "idoperador", value: null },
  ],
  cashDeskReportSumary: null,
  cashDeskReportTable: [],
  cashDeskReportToPrint: null,
  isFetchingCashDeskReportData: false,
  selectedClinics: [],
  selectedOperator: { label: "Todos", value: "" },
  allCashDeskOperators: [],
  blockedCloseCash: {
    state: false,
    message: "",
  },
};

const cashDesksSlice = createSlice({
  name: "cashDesksSlice",
  initialState,
  reducers: {
    setIsCreatingOrUpdatingCashDesk: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isCreatingOrUpdatingCashDesk = payload;
    },
    setIsFetchingCashDesks: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingCashDesks = payload;
    },
    setIsFetchingCashDeskReportData: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingCashDeskReportData = payload;
    },
    setIsDeletingCashDesk: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeletingCashDesk = payload;
    },
    setIsOpeningCashDesk: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpeningCashDesk = payload;
    },
    setIsClosingCashDesk: (state, { payload }: PayloadAction<boolean>) => {
      state.isClosingCashDesk = payload;
    },
    setCashDeskReportToPrint: (
      state,
      { payload }: PayloadAction<any | null>
    ) => {
      state.cashDeskReportToPrint = payload;
    },
    setCashDeskOverAllReportData: (
      state,
      { payload }: PayloadAction<ICashDeskOverAllReport | null>
    ) => {
      state.cashDeskOverAllReport = payload;
    },
    setCashDesks: (
      state,
      {
        payload: { cash_desks, page, total },
      }: PayloadAction<{
        cash_desks: ICashDesk[];
        page: number;
        total: number;
      }>
    ) => {
      state.page = page;
      state.cashDesks = cash_desks;
      state.total = total;
    },
    setCashDeskReport: (
      state,
      {
        payload: { stats, transactions },
      }: PayloadAction<{
        stats: ICashDeskSumary;
        transactions: ITransaction[];
      }>
    ) => {
      state.cashDeskReportSumary = stats;
      state.cashDeskReportTable = transactions;
    },
    setUserCashDesk: (state, { payload }: PayloadAction<ICashDesk>) => {
      state.userCashDesk = payload;
    },
    clearUserCashDesk: (state) => {
      state.userCashDesk = null;
    },
    setCurrentCashDesk: (state, { payload }: PayloadAction<ICashDesk>) => {
      state.currentCashDesk = payload;
    },
    clearCurrentCashDesk: (state) => {
      state.currentCashDesk = null;
    },
    updateCashDesksFilter: (state, { payload }: PayloadAction<IFilter>) => {
      const index = state.cashDesksFilterArray.findIndex(
        (item) => item.key === payload.key
      );
      state.cashDesksFilterArray[index].value = payload.value;
    },
    setUserAllCashDesks: (state, { payload }: PayloadAction<ICashDesk[]>) => {
      state.userAllCashDesk = payload;
    },
    setPageUserCashDesk: (state, { payload }: PayloadAction<number>) => {
      state.pageUserCashDesk = payload;
    },
    setTotalUserCashDesk: (state, { payload }: PayloadAction<number>) => {
      state.totalUserCashDesk = payload;
    },
    setSelectedClinics: (state, { payload }: PayloadAction<any>) => {
      state.selectedClinics = payload;
    },
    setSelectedOperator: (state, { payload }: PayloadAction<any>) => {
      state.selectedOperator = payload;
    },
    setAllCashDeskOperators: (state, { payload }: PayloadAction<any>) => {
      state.allCashDeskOperators = payload;
    },
    setBlockedCloseCash: (
      state,
      { payload }: PayloadAction<{ state: boolean; message: string }>
    ) => {
      state.blockedCloseCash = payload;
    },
  },
});

// * OK
export const fetchCashDesks =
  ({ page = 1, limit = 6 }: { page?: number; limit?: number }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingCashDesks, setCashDesks } = cashDesksSlice.actions;
    try {
      const state = getState();
      const { cashDesksFilterArray } = state.cashDesks;
      const queryParameters = queryStringFromFilterArray(cashDesksFilterArray);
      const pageAndLimit =
        queryParameters.length === 0
          ? `?page=${page}&limit=${limit}`
          : `&page=${page}&limit=${limit}`;
      dispatch(setIsFetchingCashDesks(true));
      const response = await api.get(
        `/api/cashdesks${queryParameters}${pageAndLimit}`
      );
      dispatch(setCashDesks(response.data.data));
      dispatch(setIsFetchingCashDesks(false));
    } catch (error: any) {
      dispatch(setIsFetchingCashDesks(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchAllCashDesksOperators = (): AppThunk => async (dispatch) => {
  const { setIsFetchingCashDesks, setAllCashDeskOperators } =
    cashDesksSlice.actions;
  try {
    dispatch(setIsFetchingCashDesks(true));
    const response = await api.get(`/api/cashdesks?page=1&limit=9999`);
    const mappedOperators = response.data.data.cash_desks.map(
      (cashDesk: ICashDesk) => {
        return {
          label: cashDesk.usuario.nome,
          value: cashDesk.usuario.idusuario,
        };
      }
    );
    const sorttedOperators = mappedOperators.sort((a: any, b: any) => {
      return a.label.localeCompare(b.label);
    });
    const removedDuplicatedOperators = sorttedOperators.filter(
      (item: any, index: number) => {
        if (item.value !== sorttedOperators[index + 1]?.value) {
          return item;
        }
      }
    );

    const settedOperators = Array.from(new Set(removedDuplicatedOperators));
    dispatch(setAllCashDeskOperators(settedOperators));
    dispatch(setIsFetchingCashDesks(false));
  } catch (error: any) {
    dispatch(setIsFetchingCashDesks(false));
    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);
    } else {
      console.log(error.message);
    }
  }
};

// * OK
export const fetchCashDeskById =
  (cashDeskId: string): AppThunk =>
  async (dispatch) => {
    const { setIsFetchingCashDesks, setCurrentCashDesk } =
      cashDesksSlice.actions;
    try {
      dispatch(setIsFetchingCashDesks(true));
      const response = await api.get(`/api/cashdesks/${cashDeskId}`);
      dispatch(setCurrentCashDesk(response.data.data));
      dispatch(setIsFetchingCashDesks(false));
    } catch (error: any) {
      dispatch(setIsFetchingCashDesks(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

// * OK
export const fetchCashDeskByUserId =
  (userId: string): AppThunk =>
  async (dispatch) => {
    const { setIsFetchingCashDesks, setUserCashDesk, clearUserCashDesk } =
      cashDesksSlice.actions;
    try {
      dispatch(setIsFetchingCashDesks(true));
      const response = await api.get(
        `/api/cashdesks/list/opened?idoperador=${userId}`
      );
      if (response.data.data.cash_desks.length === 0) {
        dispatch(clearUserCashDesk());
      } else {
        dispatch(setUserCashDesk(response.data.data.cash_desks[0]));
      }
      dispatch(setIsFetchingCashDesks(false));
    } catch (error: any) {
      dispatch(setIsFetchingCashDesks(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

// * OK
export const fetchCashDeskReport =
  (caixas: string[], start: string, end: string): AppThunk =>
  async (dispatch) => {
    const { setCashDeskReport } = cashDesksSlice.actions;
    try {
      const response = await api.get(
        `/api/cashdesk/report?idcaixa=${caixas.toString()}&start_date=${start}&end_date=${end}`
      );
      // const response = await api.get(`/api/cashdesk/report?idcaixa=b386ffca-ebab-45f9-b863-eafcf8cddf43,9427b160-9681-4b8d-944e-f7833e985eea,e02c17a4-570d-41e8-ae49-ba9a26c611f7,f30fa906-d009-4aa0-98f1-d5a1041ba3c8,45aa8774-bba3-44d5-959d-118ef026bb84,10c80865-180d-4092-96a5-ce4193f6ac93&start_date=${start}&end_date=${end}`);
      if (response.data.success) {
        dispatch(setCashDeskReport(response.data.data));
      }
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchAllCashDeskReportsFromUserId =
  ({
    userId,
    page = 1,
    limit = 999,
  }: {
    userId: string;
    page?: number;
    limit: number;
  }): AppThunk =>
  async (dispatch, getState) => {
    const {
      setIsFetchingCashDesks,
      setUserAllCashDesks,
      setPageUserCashDesk,
      setTotalUserCashDesk,
    } = cashDesksSlice.actions;

    const state = getState();
    const { cashDesksFilterArray } = state.cashDesks;
    const queryParameters = queryStringFromFilterArray(cashDesksFilterArray);
    const newQueryParams = queryParameters.replace("?", "&");

    try {
      dispatch(setIsFetchingCashDesks(true));
      const response = await api.get(
        `/api/cashdesks?idoperador=${userId}&page=${page}&limit=${limit}${newQueryParams}`
      );
      if (response.data.data.cash_desks.length === 0) {
        dispatch(setUserAllCashDesks([]));
        dispatch(setPageUserCashDesk(response.data.data.page));
        dispatch(setTotalUserCashDesk(response.data.data.total));
      } else {
        dispatch(setUserAllCashDesks(response.data.data.cash_desks));
        dispatch(setPageUserCashDesk(response.data.data.page));
        dispatch(setTotalUserCashDesk(response.data.data.total));
      }
      dispatch(setIsFetchingCashDesks(false));
    } catch (error: any) {
      dispatch(setIsFetchingCashDesks(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

// * OK
export const createCashDesk =
  ({
    cashDeskData,
    idconta,
    idclinica,
  }: {
    cashDeskData: ICashDeskForm;
    idconta: string;
    idclinica: string;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingOrUpdatingCashDesk } = cashDesksSlice.actions;
    dispatch(setIsCreatingOrUpdatingCashDesk(true));
    try {
      await api.post(`/api/cashdesks`, { ...cashDeskData, idconta, idclinica });
      dispatch(setIsCreatingOrUpdatingCashDesk(false));
      history.replace("/financeiro/caixas");
      toast.success("Caixa cadastrado", toastOptions);
    } catch (error: any) {
      dispatch(setIsCreatingOrUpdatingCashDesk(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

// * OK
export const updateCashDesk =
  ({
    // cashDeskData,
    nome,
    cashDeskId,
    idconta,
    idclinica,
  }: {
    // cashDeskData: ICashDeskForm;
    nome: string;
    cashDeskId: string;
    idconta: string;
    idclinica: string;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingOrUpdatingCashDesk } = cashDesksSlice.actions;
    dispatch(setIsCreatingOrUpdatingCashDesk(true));
    try {
      await api.put(`/api/cashdesks/${cashDeskId}`, {
        nome,
        idconta,
        idclinica,
      });
      dispatch(setIsCreatingOrUpdatingCashDesk(false));
      history.replace("/financeiro/caixas");
      toast.success("Caixa atualizado", toastOptions);
    } catch (error: any) {
      dispatch(setIsCreatingOrUpdatingCashDesk(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

// * OK
export const updateCashDeskStatus =
  ({
    newCashDeskStatus,
    cashDeskId,
  }: {
    newCashDeskStatus: boolean;
    cashDeskId: string;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingOrUpdatingCashDesk } = cashDesksSlice.actions;
    dispatch(setIsCreatingOrUpdatingCashDesk(true));
    try {
      await api.put(`/api/cashdesks/${cashDeskId}`, {
        ativo: newCashDeskStatus,
      });
      dispatch(setIsCreatingOrUpdatingCashDesk(false));
      dispatch(fetchCashDesks({}));
      toast.success(
        newCashDeskStatus ? "Caixa aberto" : "Caixa fechado",
        toastOptions
      );
    } catch (error: any) {
      dispatch(setIsCreatingOrUpdatingCashDesk(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

// * OK
export const deleteCashDesk =
  (cashDeskId: string): AppThunk =>
  async (dispatch) => {
    const { setIsDeletingCashDesk } = cashDesksSlice.actions;
    dispatch(setIsDeletingCashDesk(true));
    try {
      await api.delete(`/api/cashdesks/${cashDeskId}`);
      dispatch(setIsDeletingCashDesk(false));
      dispatch(fetchCashDesks({}));
      toast.success("Caixa excluida", toastOptions);
    } catch (error: any) {
      dispatch(setIsDeletingCashDesk(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const openCashDesk =
  ({
    cashDeskId,
    quantia,
  }: {
    cashDeskId: string;
    quantia?: string | number;
  }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsOpeningCashDesk, setUserCashDesk } = cashDesksSlice.actions;
    const { signedInUser } = getState().users;
    dispatch(setIsOpeningCashDesk(true));
    try {
      const response = await api.post(`/api/cashdesks/${cashDeskId}/open`, {
        quantia,
      });
      dispatch(setUserCashDesk(response.data.data));
      dispatch(setIsOpeningCashDesk(false));
      if (!!signedInUser?.cargo.admin) {
        dispatch(fetchCashDesks({ limit: 6 }));
      } else {
        signedInUser &&
          dispatch(
            fetchAllCashDeskReportsFromUserId({
              userId: signedInUser?.idusuario,
              limit: 6,
              page: 1,
            })
          );
      }
      toast.success("Caixa aberto", toastOptions);
    } catch (error: any) {
      dispatch(setIsOpeningCashDesk(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const openUserCashDesk = (): AppThunk => async (dispatch) => {
  const { setIsOpeningCashDesk, setUserCashDesk } = cashDesksSlice.actions;
  dispatch(setIsOpeningCashDesk(true));
  try {
    const response = await api.post(`/api/cashdesk/open`);
    dispatch(setUserCashDesk(response.data.data));
    dispatch(setIsOpeningCashDesk(false));
    dispatch(fetchCashDesks({}));
    toast.success("Caixa aberto", toastOptions);
  } catch (error: any) {
    dispatch(setIsOpeningCashDesk(false));
    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);
    } else {
      console.log(error.message);
    }
  }
};

export const closeCashDesk =
  ({
    cashDeskId,
    callback,
  }: {
    cashDeskId: string;
    callback?: Function;
  }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsClosingCashDesk, clearUserCashDesk } = cashDesksSlice.actions;
    dispatch(setIsClosingCashDesk(true));
    const { userCashDesk } = getState().cashDesks;
    const { signedInUser } = getState().users;
    try {
      await api.get(`/api/cashdesks/${cashDeskId}/close`);
      toast.success("Caixa fechado", toastOptions);
      if (!!signedInUser?.cargo.admin) {
        dispatch(fetchCashDesks({ limit: 6 }));
      } else {
        signedInUser &&
          dispatch(
            fetchAllCashDeskReportsFromUserId({
              userId: signedInUser?.idusuario,
              limit: 6,
              page: 1,
            })
          );
      }
      if (userCashDesk) {
        if (userCashDesk.idcaixa === cashDeskId) {
          dispatch(clearUserCashDesk());
        }
      }
      if (callback) {
        callback();
      }
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setIsClosingCashDesk(false));
    }
  };

export const fetchCashDeskOverAllRerpotData =
  ({
    initialDate,
    finalDate,
    cb,
  }: {
    initialDate: string;
    finalDate: string;
    cb?: () => void;
  }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingCashDeskReportData, setCashDeskOverAllReportData } =
      cashDesksSlice.actions;
    dispatch(setIsFetchingCashDeskReportData(true));
    try {
      const response = await api.get(
        `/api/cashdesk/general-report?start_date=${initialDate}&end_date=${finalDate}`
      );
      dispatch(setCashDeskOverAllReportData(response.data.data));
      dispatch(setIsFetchingCashDeskReportData(false));
      if (cb) {
        cb();
      }
    } catch (e) {
      dispatch(setIsFetchingCashDeskReportData(false));
    }
  };

export const fetchCashDeskReportData =
  ({
    cashDeskId,
    initialDate,
    finalDate,
    cb,
  }: {
    cashDeskId: string;
    initialDate: string;
    finalDate: string;
    cb?: () => void;
  }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingCashDeskReportData, setCashDeskReportToPrint } =
      cashDesksSlice.actions;
    try {
      dispatch(setIsFetchingCashDeskReportData(true));
      const response = await api.get(
        `/api/cashdesk/report?idcaixa=${cashDeskId}&start_date=${initialDate}&end_date=${finalDate}&limit=9999`
      );
      // console.log(respon)
      dispatch(setCashDeskReportToPrint(response.data.data));
      dispatch(setIsFetchingCashDeskReportData(false));
      if (cb) {
        cb();
      }
    } catch (e) {
      dispatch(setIsFetchingCashDeskReportData(false));
    }
  };

export const closeUserCashDesk =
  ({ callback }: { callback?: Function }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsClosingCashDesk, clearUserCashDesk, setBlockedCloseCash } =
      cashDesksSlice.actions;
    dispatch(setIsClosingCashDesk(true));
    const { userCashDesk } = getState().cashDesks;
    try {
      await api.post(`/api/cashdesk/close`);
      dispatch(setIsClosingCashDesk(false));
      dispatch(fetchCashDesks({}));
      toast.success("Caixa fechado", toastOptions);
      if (userCashDesk) {
        dispatch(clearUserCashDesk());
      }
      if (callback) {
        callback();
      }
    } catch (error: any) {
      dispatch(setIsClosingCashDesk(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
        dispatch(
          setBlockedCloseCash({
            state: true,
            message: error.response.data?.error?.message,
          })
        );
      } else {
        console.log(error.message);
      }
    }
  };

export const {
  updateCashDesksFilter,
  setCashDeskReportToPrint,
  setSelectedClinics,
  setSelectedOperator,
  setAllCashDeskOperators,
  setBlockedCloseCash,
} = cashDesksSlice.actions;

export default cashDesksSlice.reducer;
