import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { KeyboardDatePicker } from "@material-ui/pickers";

import { Formik, Form as FormikForm } from "formik";
import dayjs from "dayjs";
import * as Yup from "yup";

import { FullWidthContainer } from "components/layout/base";
import { Loader } from "components/Loader";

import { IPatientForm } from "models/Patients";
import { IOrderItem, MedicalRecordStatusEnum } from "models/MedicalRecord";

import { rootState } from "store";
import {
  fetchPatientById,
  updateMedicalData,
  updatePatientWithoutRedirect,
} from "store/reducers/PatientsReducer";
import { fetchDiagnostics } from "store/reducers/DiagnosticReducer";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { getFullAge } from "utils/getFullAge";
import { CircularProgress } from "@material-ui/core";
import { FormatListNumbered } from "@material-ui/icons";
import { fetchMedicalRecordsByPatientId } from "store/reducers/MedicalRecordReducer";
import useDebounce from "hooks/useDebouce";
import { patientInitialState } from "pages/MedicalRecord/components/PatientMedicalRecord/components/BasicInfo/data";

const validationSchema = Yup.object().shape({
  nomepaciente: Yup.string().required("O nome do paciente é obrigatório"),
  sexo: Yup.string().required("O sexo do paciente é obrigatório"),
  datanascimento: Yup.string().required("A data de nascimento é obrigatória"),
});

interface IBasicInfoPreAppointmentProps {
  patientId: string;
  idMedicalRecord: string;
}

function BasicInfoPreAppointment({
  patientId,
  idMedicalRecord,
}: IBasicInfoPreAppointmentProps) {
  const [initialValues, setInitialValues] =
    useState<IPatientForm>(patientInitialState);
  const [patientName, setPatientName] = useState<string>("");
  const [patientGender, setPatientGender] = useState<string>("");
  const [patientBirthday, setPatientBirthday] = useState<string>("");
  const [especialidades, setEspecialidades] = useState<string | undefined>(
    undefined
  );
  const [isReturn, setIsReturn] = useState(false);
  const [healthPlan, setHealthPlan] = useState<string>("");
  const [formTimeChange, setFormTimeChange] = useState<IPatientForm | null>(
    null
  );
  //@ts-ignore
  const debouncedFormikChange = useDebounce(formTimeChange, 3000);

  const dispatch = useDispatch();

  const { currentPatient, isFetchingPatients, isCreatingPatient } = useSelector(
    (state: rootState) => state.patients
  );
  const { selectedMedicalRecord, isFetchingMedicalRecord } = useSelector(
    (state: rootState) => state.medicalRecords
  );
  const { signedInUser, isFetchingSignedInUser } = useSelector(
    (state: rootState) => state.users
  );

  const formikValues = useRef<{ values: IPatientForm } | null>(null);

  function handleFillFormFields(patientData: IPatientForm) {
    if (patientData.datanascimento) {
      setPatientBirthday(
        dayjs(patientData.datanascimento).format("YYYY-MM-DD")
      );
    }

    const initialValuesToFill: IPatientForm = {
      ...patientData,
      nomepaciente: patientData.nomepaciente,
      datanascimento: patientData.datanascimento,
      sexo: patientData.sexo,
    };

    setPatientName(patientData.nomepaciente);
    setPatientGender(patientData.sexo);
    setPatientBirthday(patientData.datanascimento);

    setInitialValues(initialValuesToFill);
  }

  function onSubmit(patient: IPatientForm) {
    if (!(patient.datanascimento === "Invalid Date")) {
      const formmatedPatient: IPatientForm = {
        ...patient,
        nomepaciente: patient.nomepaciente,
        sexo: patient.sexo,
        datanascimento: patient.datanascimento,
        datanascimentotexto: dayjs(patient.datanascimento).format("DD/MM/YYYY"),
        peso: patient.peso ? patient.peso : 0,
        altura: patient.altura ? patient.altura : 0,
      };

      dispatch(
        updateMedicalData({
          patientId: patientId ? patientId : currentPatient.idpaciente,
          peso: formmatedPatient.peso,
          altura: formmatedPatient.altura,
          disableToast: true,
          cb: () => {},
        })
      );

      dispatch(
        updatePatientWithoutRedirect({
          patientId: patientId ? patientId : currentPatient.idpaciente,
          patient: formmatedPatient,
          cb: () => {
            if ((patientId || currentPatient) && idMedicalRecord) {
              dispatch(
                fetchPatientById({
                  patientId: patientId ? patientId : currentPatient.idpaciente,
                })
              );
              dispatch(
                fetchMedicalRecordsByPatientId(
                  patientId ? patientId : currentPatient.idpaciente,
                  idMedicalRecord
                )
              );
            }
          },
        })
      );
    }
  }

  const setUpdateDataInterval = () => {
    if (formikValues && formikValues.current && formikValues.current.values) {
      setFormTimeChange(formikValues.current.values);
    }
  };

  const syncData = (values: IPatientForm) => {
    if (
      values &&
      values.datanascimento &&
      values.datanascimento.toLowerCase() !== "invalid date"
    ) {
      const formmatedPatient: IPatientForm = {
        ...values,
        nomepaciente: values.nomepaciente,
        sexo: values.sexo,
        datanascimento: values.datanascimento,
        datanascimentotexto: dayjs(values.datanascimento).format("DD/MM/YYYY"),
        peso: values.peso ? values.peso : 0,
        altura: values.altura ? values.altura : 0,
      };

      // setTimeout(() => {
      dispatch(
        updateMedicalData({
          patientId: patientId ? patientId : currentPatient.idpaciente,
          peso: formmatedPatient.peso,
          altura: formmatedPatient.altura,
          disableToast: true,
          cb: () => {},
        })
      );

      dispatch(
        updatePatientWithoutRedirect({
          patientId: patientId ? patientId : currentPatient.idpaciente,
          patient: formmatedPatient,
          disableToast: true,
          cb: () => {
            if ((patientId || currentPatient) && idMedicalRecord) {
              dispatch(
                fetchPatientById({
                  patientId: patientId ? patientId : currentPatient.idpaciente,
                })
              );
              dispatch(
                fetchMedicalRecordsByPatientId(
                  patientId ? patientId : currentPatient.idpaciente,
                  idMedicalRecord
                )
              );
            }
          },
        })
      );
      // }, 1500);
    }
  };

  useEffect(() => {
    if (currentPatient && currentPatient.nomepaciente !== "") {
      handleFillFormFields(currentPatient);
    }
  }, [currentPatient]);

  useEffect(() => {
    //@ts-ignore
    if (
      formikValues &&
      formikValues.current &&
      formikValues.current.values &&
      formikValues.current.values.datanascimento &&
      formikValues.current.values.datanascimento.toLowerCase() !==
        "invalid date"
    ) {
      syncData(formikValues.current.values);
    }
  }, [debouncedFormikChange]);

  useEffect(() => {
    setHealthPlan("");
    if (selectedMedicalRecord) {
      const medicalRecordItems =
        selectedMedicalRecord.order_items as IOrderItem[];
      const isReturnVar =
        medicalRecordItems.filter((item: any) => item.itemservicos.retorno)
          .length > 0;
      setIsReturn(isReturnVar);

      var especialidades = "";
      medicalRecordItems.forEach((order_item: any) => {
        especialidades = order_item.itemservicos.nome + " " + especialidades;
      });

      setEspecialidades(especialidades);

      if (medicalRecordItems.length > 0 && !!medicalRecordItems[0]?.itemplano) {
        if (medicalRecordItems[0].itemplano.conveniocategoria.nome) {
          setHealthPlan(
            medicalRecordItems[0].itemplano.conveniocategoria.convenio.nome
          );
        }
      }
    }
  }, [selectedMedicalRecord]);

  useEffect(() => {
    if (idMedicalRecord) {
      dispatch(fetchDiagnostics({ idMedicalRecord: idMedicalRecord }));
    }
  }, [dispatch, idMedicalRecord]);

  return (
    <>
      {isFetchingMedicalRecord &&
      isFetchingPatients &&
      isFetchingSignedInUser &&
      !initialValues.nomepaciente ? (
        <Loader />
      ) : (
        <FullWidthContainer>
          <Formik
            initialValues={initialValues}
            //@ts-ignore
            innerRef={formikValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ errors, setFieldValue }) => (
              <FormikForm
                onChange={() => {
                  setUpdateDataInterval();
                }}
              >
                <Grid container spacing={2}>
                  <Grid container>
                    <Typography
                      variant="h6"
                      style={{
                        marginBottom: "1rem",
                        fontSize: 16,
                      }}
                    >
                      As informações abaixo serão salvas automaticamente
                    </Typography>
                  </Grid>
                  {!!healthPlan && (
                    <Grid container>
                      <Grid item style={{ marginBottom: "1rem" }}>
                        <Typography variant="body1">
                          Convênio: {healthPlan}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}

                  <Grid container spacing={2}>
                    {/* NOME E SEXO */}
                    <Grid container spacing={2} style={{ margin: ".5rem 0" }}>
                      <Grid item xs={12} md={6}>
                        <Typography variant="body1">
                          Nome do paciente
                        </Typography>
                        <TextField
                          style={{ width: "100%" }}
                          variant="outlined"
                          name="nomepaciente"
                          // label="Nome do paciente"
                          error={!!errors.nomepaciente}
                          helperText={
                            !!errors.nomepaciente && errors.nomepaciente
                          }
                          value={patientName && patientName.toUpperCase()}
                          required
                          onChange={(e) => {
                            // handleChange(e);
                            setPatientName(e.target.value.toUpperCase());
                            setFieldValue("nomepaciente", e.target.value);
                            // setUpdateDataInterval();
                          }}
                          disabled={
                            selectedMedicalRecord &&
                            // selectedMedicalRecord.idmedico ===
                            // signedInUser?.idusuario &&
                            (selectedMedicalRecord.status ===
                              MedicalRecordStatusEnum.ABERTO ||
                              selectedMedicalRecord.status ===
                                MedicalRecordStatusEnum.AGUARDANDO ||
                              (selectedMedicalRecord?.status ===
                                MedicalRecordStatusEnum.FECHADO &&
                                new Date().getTime() -
                                  new Date(
                                    selectedMedicalRecord.datafinalizado
                                  ).getTime() <
                                  2 * 24 * 60 * 60 * 1000))
                              ? false
                              : true
                          }
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Typography variant="body1">Sexo</Typography>
                        <Select
                          variant="outlined"
                          labelId="select-gender"
                          value={patientGender.toLowerCase()}
                          onChange={(e: any) => {
                            // handleChange(e);
                            setPatientGender(e.target.value);
                            setFieldValue("sexo", e.target.value);
                            setUpdateDataInterval();
                          }}
                          // label="Sexo"
                          name="sexo"
                          fullWidth
                          error={!!errors.sexo}
                          disabled={
                            selectedMedicalRecord &&
                            // selectedMedicalRecord.idmedico ===
                            // signedInUser?.idusuario &&
                            (selectedMedicalRecord.status ===
                              MedicalRecordStatusEnum.ABERTO ||
                              selectedMedicalRecord.status ===
                                MedicalRecordStatusEnum.AGUARDANDO ||
                              (selectedMedicalRecord?.status ===
                                MedicalRecordStatusEnum.FECHADO &&
                                new Date().getTime() -
                                  new Date(
                                    selectedMedicalRecord.datafinalizado
                                  ).getTime() <
                                  2 * 24 * 60 * 60 * 1000))
                              ? false
                              : true
                          }
                        >
                          <MenuItem value="masculino">Masculino</MenuItem>
                          <MenuItem value="feminino">Feminino</MenuItem>
                        </Select>
                      </Grid>
                    </Grid>

                    {/* DATA DE NASCIMENTO E IDADE */}
                    <Grid container spacing={2} style={{ margin: ".5rem 0" }}>
                      <Grid item xs={12} md={6}>
                        <Typography variant="body1">
                          Data de nascimento
                        </Typography>
                        <KeyboardDatePicker
                          id="date-picker-dialog"
                          // label="Data de nascimento"
                          format="DD/MM/YYYY"
                          value={
                            patientBirthday ||
                            dayjs(new Date()).format("YYYY-MM-DD")
                          }
                          onChange={(
                            date: MaterialUiPickersDate,
                            _?: string | null | undefined
                          ) => {
                            setFieldValue(
                              "datanascimento",
                              date?.format("YYYY-MM-DD")
                            );
                            setPatientBirthday(
                              String(date?.format("YYYY-MM-DD"))
                            );
                            // setUpdateDataInterval();
                          }}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          inputVariant="outlined"
                          style={{
                            width: "100%",
                          }}
                          disabled={
                            selectedMedicalRecord &&
                            // selectedMedicalRecord.idmedico ===
                            // signedInUser?.idusuario &&
                            (selectedMedicalRecord.status ===
                              MedicalRecordStatusEnum.ABERTO ||
                              selectedMedicalRecord.status ===
                                MedicalRecordStatusEnum.AGUARDANDO ||
                              (selectedMedicalRecord?.status ===
                                MedicalRecordStatusEnum.FECHADO &&
                                new Date().getTime() -
                                  new Date(
                                    selectedMedicalRecord.datafinalizado
                                  ).getTime() <
                                  2 * 24 * 60 * 60 * 1000))
                              ? false
                              : true
                          }
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Typography variant="body1">Idade</Typography>
                        <TextField
                          variant="outlined"
                          style={{ width: "100%" }}
                          // label="Idade"
                          value={String(getFullAge(patientBirthday))}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* {selectedMedicalRecord &&
                    // selectedMedicalRecord.idmedico ===
                    // signedInUser?.idusuario &&
                    (selectedMedicalRecord.status ===
                      MedicalRecordStatusEnum.ABERTO ||
                      selectedMedicalRecord.status ===
                        MedicalRecordStatusEnum.AGUARDANDO ||
                      (selectedMedicalRecord?.status ===
                        MedicalRecordStatusEnum.FECHADO &&
                        new Date().getTime() -
                          new Date(
                            selectedMedicalRecord.datafinalizado
                          ).getTime() <
                          2 * 24 * 60 * 60 * 1000)) && (
                      <Grid
                        container
                        justify="flex-end"
                        style={{ marginTop: ".5rem" }}
                      >
                        <Button
                          type="submit"
                          color="primary"
                          variant="contained"
                        >
                          {isCreatingPatient ? (
                            <CircularProgress color="inherit" size={24} />
                          ) : (
                            "Salvar"
                          )}
                        </Button>
                      </Grid>
                    )} */}
                </Grid>
              </FormikForm>
            )}
          </Formik>
        </FullWidthContainer>
      )}
    </>
  );
}

export default BasicInfoPreAppointment;
