import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, AppDispatch } from "store/index";
import { toast } from "react-toastify";
import toastOptions from "utils/toastOptions";
import api from "utils/API";
import { IStockItems } from "models/ItemStockReferences";
import { IFilter } from "models/shared";
import { queryStringFromFilterArray } from "utils/network";
import { IStockActivity, IStockPositions } from "models/StockItem";

interface IInitialState {
    stockItemsList: IStockItems[] | null;
    stockItems: IStockItems | null;
    isFetchingStockItems: Boolean;
    page: number;
    total: number;
    stockItemsFilterArray: {
      key:string,
      value: any
    }[],
    stockPositionList: IStockPositions[];
    stockActivityList: IStockActivity[];
}
const initialState: IInitialState = {
    stockItemsList: null,
    stockItems: null,
    isFetchingStockItems: false,
    page: 1,
    total: 0,
    stockItemsFilterArray: [
      { key: "nome", value: null },
      { key: "tipo", value: null },
      { key: "codigo", value: null },
      { key: "local", value: null },
      { key: "categoria", value: null },
      { key: "fabricante", value: null },
    ],
    stockPositionList: [],
    stockActivityList: []
};

const stockItemsSlice = createSlice({
  name: "stockItemsSlice",
  initialState,
  reducers: {
        setStockItems: (
        state,
        { payload }: PayloadAction<IStockItems>
        ) => {
        state.stockItems = payload;
        },
        setStockItemsFilterArray: ( state, {payload}:PayloadAction<IFilter>) => {
          const index = state.stockItemsFilterArray.findIndex(
            (item) => item.key === payload.key
          );
          if (state.stockItemsFilterArray[index]) {
            state.stockItemsFilterArray[index].value = payload.value;
          }
        },
        setStockItemsList: (
        state,
        { payload }: PayloadAction<{
          stock_items:IStockItems[]
          page: number;
          total: number;
        }>
        ) => {
        state.stockItemsList = payload.stock_items;
        state.page = payload.page;
        state.total = payload.total;
        },
        setIsFetchingStockItems: (state, { payload }:PayloadAction<Boolean>) => {
            state.isFetchingStockItems = payload
        },
        setStockPositionList: (
          state,
          { payload }: PayloadAction<IStockPositions[]>
        ) => {
          state.stockPositionList = payload;
        },
        setStockActivityList: (
          state,
          { payload }: PayloadAction<IStockActivity[]>
        ) => {
          state.stockActivityList = payload;
        },
    }
});

export const fetchStockItemsList = ({limit = 6, page = 1}:{limit?: number, page?: number}): AppThunk => async (dispatch, getState) => {
    const { setIsFetchingStockItems, setStockItemsList } = stockItemsSlice.actions;
    dispatch(setIsFetchingStockItems(true));
    try {
      const state = getState();
      const { stockItemsFilterArray } = state.itemsStock;
      const queryParameters = queryStringFromFilterArray(stockItemsFilterArray);
      const pageAndLimit =
        queryParameters.length === 0
          ? `?page=${page}&limit=${limit}`
          : `&page=${page}&limit=${limit}`;
      const response = await api.get(
        `/api/stockitems${queryParameters}${pageAndLimit}`
      );
      dispatch(setStockItemsList(response.data.data));
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
        dispatch(setIsFetchingStockItems(false))
    }
  };

  export const fetchStockItems = ({id}:{id:string}): AppThunk => async (dispatch, getState) => {
    const { setIsFetchingStockItems, setStockItems } = stockItemsSlice.actions;
    dispatch(setIsFetchingStockItems(true))
    try {
      const response = await api.get(
        `/api/stockitems/${id}`
      );
      dispatch(setStockItems(response.data.data));
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
        dispatch(setIsFetchingStockItems(false))
    }
  };

  export const submitStockItems = (data:IStockItems, cb?:Function): AppThunk => async (dispatch, getState) => {
    const { setIsFetchingStockItems, setStockItems } = stockItemsSlice.actions;
    dispatch(setIsFetchingStockItems(true))
    try {
      const response = await api.post(
        `/api/stockitems`, data
      );
      if(cb){
        cb(response.data.data.iditemestoque)
      }
      toast.success("Item cadastrado com sucesso", toastOptions);
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
        dispatch(setIsFetchingStockItems(false))
    }
  };

  export const editStockItems = ({id, data, cb}: {id: string, data: IStockItems, cb?: Function}): AppThunk => async (dispatch, getState) => {
    const { setIsFetchingStockItems, setStockItems } = stockItemsSlice.actions;
    dispatch(setIsFetchingStockItems(true))
    try {
      const response = await api.put(
        `/api/stockitems/${id}`, data
      );
      dispatch(setStockItems(response.data.stock_manufacturers))
      toast.success("item atualizado com sucesso", toastOptions);
      if(cb){
        cb()
      }
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
        dispatch(setIsFetchingStockItems(false))
    }
  };

  export const listStockPositions = ({ id }: {id: string}): AppThunk => async (dispatch, getState) => {
    const { setStockPositionList } = stockItemsSlice.actions;
    try {
      const response = await api.get(
        `/api/stockpositions?iditemestoque=${id}`
      );
      dispatch(setStockPositionList(response.data.data.stock_positions))
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
    }
  };

  export const sumitStockPositions = ({ data, cb }: {data: IStockPositions, cb: Function}): AppThunk => async (dispatch, getState) => {
    try {
      const response = await api.post(
        `/api/stockpositions`, data
      );
      console.log(response)
      if(response.status){
        cb()
      }
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
    }
  };

  export const sumitStockActivities = ({ data, cb }: {data: IStockActivity, cb: Function}): AppThunk => async (dispatch, getState) => {
    try {
      const response = await api.post(
        `/api/stockactivities`, data
      );
      console.log(response)
      if(response.status){
        cb()
      }
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
    }
  };

  export const listStockActivity = ({ id }: {id: string}): AppThunk => async (dispatch, getState) => {
    const { setStockActivityList } = stockItemsSlice.actions;
    try {
      const response = await api.get(
        `/api/stockactivities`
      );
      dispatch(setStockActivityList(response.data.data.stock_activities))
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
    }
  };

export const { setStockItems, setStockItemsFilterArray  } = stockItemsSlice.actions;

export default stockItemsSlice.reducer;
