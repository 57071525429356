import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, AppDispatch } from "store/index";
import { toast } from "react-toastify";
import toastOptions from "utils/toastOptions";
import api from "utils/API";
import { IManufacturers, IManufacturersForm } from "models/Manufacturers";
import { ICategoryStock } from "models/Category";

interface IInitialState {
    categoryStockList: ICategoryStock[] | null;
    categoryStock: ICategoryStock | null;
    isFetchingCategory: Boolean;
    page: number;
    total: number;
}
const initialState: IInitialState = {
    categoryStockList: null,
    categoryStock: null,
    isFetchingCategory: false,
    page: 1,
    total: 0
};

const categoryStockSlice = createSlice({
  name: "categoryStockSlice",
  initialState,
  reducers: {
        setCategoryStock: (
        state,
        { payload }: PayloadAction<ICategoryStock>
        ) => {
        state.categoryStock = payload;
        },
        setCategoryStockList: (
        state,
        { payload }: PayloadAction<{
          stock_categories:ICategoryStock[]
          page: number;
          total: number;
        }>
        ) => {
        state.categoryStockList = payload.stock_categories;
        state.page = payload.page;
        state.total = payload.total;
        },
        setIsFetchingCategoryStock: (state, { payload }:PayloadAction<Boolean>) => {
            state.isFetchingCategory = payload
        }
    }
});

export const fetchCategoryStockList = ({limit = 6, page = 1}:{limit?: number, page?: number}): AppThunk => async (dispatch, getState) => {
    const { setIsFetchingCategoryStock, setCategoryStockList } = categoryStockSlice.actions;
    dispatch(setIsFetchingCategoryStock(true));
    try {
      const pageAndLimit = `?page=${page}&limit=${limit}`
      
      const response = await api.get(
        `/api/stockcategories${pageAndLimit}`
      );
      dispatch(setCategoryStockList(response.data.data));
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
        dispatch(setIsFetchingCategoryStock(false))
    }
  };

  export const fetchCategoryStock = ({id}:{id:string}): AppThunk => async (dispatch, getState) => {
    const { setIsFetchingCategoryStock, setCategoryStock } = categoryStockSlice.actions;
    dispatch(setIsFetchingCategoryStock(true))
    try {
      const response = await api.get(
        `/api/stockcategories/${id}`
      );
      dispatch(setCategoryStock(response.data.data));
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
        dispatch(setIsFetchingCategoryStock(false))
    }
  };

  export const submitCategoryStock = (data:IManufacturersForm, cb?:Function): AppThunk => async (dispatch, getState) => {
    const { setIsFetchingCategoryStock, setCategoryStock } = categoryStockSlice.actions;
    dispatch(setIsFetchingCategoryStock(true))
    try {
      const response = await api.post(
        `/api/stockcategories`, data
      );
      if(cb){
        cb(response.data.data.idcategoriaestoque)
      }
      toast.success("Categorias cadastrado com sucesso", toastOptions);
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
        dispatch(setIsFetchingCategoryStock(false))
    }
  };

  export const editCategoryStock = ({id, data, cb}: {id: string, data: IManufacturersForm, cb?: Function}): AppThunk => async (dispatch, getState) => {
    const { setIsFetchingCategoryStock, setCategoryStock } = categoryStockSlice.actions;
    dispatch(setIsFetchingCategoryStock(true))
    try {
      const response = await api.put(
        `/api/stockcategories/${id}`, data
      );
      dispatch(setCategoryStock(response.data.stock_manufacturers))
      toast.success("Categoria editado com sucesso", toastOptions);
      if(cb){
        cb()
      }
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
        dispatch(setIsFetchingCategoryStock(false))
    }
  };

export const { setCategoryStock  } = categoryStockSlice.actions;

export default categoryStockSlice.reducer;
