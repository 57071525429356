import {
  IServiceCategory,
  IServiceCategoryFormFull,
} from "models/services/categories";
import { IFilter } from "models/shared/index";
import {
  AnyAction,
  createSlice,
  PayloadAction,
  ThunkAction,
} from "@reduxjs/toolkit";
import { AppThunk, rootState } from "store/index";
import { toast } from "react-toastify";
import toastOptions from "utils/toastOptions";
import api from "utils/API";
import { history } from "utils/history";
import { queryStringFromFilterArray } from "utils/network";

interface IInitialState {
  isFetchingServiceCategories: boolean;
  isFetchingServiceCategoriesFromCategoriesPage: boolean;
  isCreatingServiceCategory: boolean;
  isDeletingServiceCategory: boolean;
  serviceCategories: IServiceCategory[] | null;
  serviceCategoriesFromCategoriesPage: IServiceCategory[] | null;
  currentServiceCategory: IServiceCategory | null;
  total: number;
  totalFromCategoriesPage: number;
  page: number;
  pageFromCategoriesPage: number;
  filterArray: IFilter[];
  filterArrayFromCategoriesPage: IFilter[];
  amountOfItemsToShow: number;
  amountOfItemsToShowFromCategoriesPage: number;
  currentNameFilter: string;
  currentStatusFilter: string;
}

const initialState: IInitialState = {
  isFetchingServiceCategories: false,
  isFetchingServiceCategoriesFromCategoriesPage: false,
  isCreatingServiceCategory: false,
  isDeletingServiceCategory: false,
  serviceCategories: null,
  serviceCategoriesFromCategoriesPage: null,
  currentServiceCategory: null,
  total: 0,
  totalFromCategoriesPage: 0,
  page: 1,
  pageFromCategoriesPage: 1,
  filterArray: [{ key: "nome", value: [] }],
  filterArrayFromCategoriesPage: [{ key: "nome", value: [] }],
  amountOfItemsToShow: 6,
  amountOfItemsToShowFromCategoriesPage: 6,
  currentNameFilter: "",
  currentStatusFilter: "",
};

const serviceCategoriesSlice = createSlice({
  name: "serviceCategorysSlice",
  initialState,
  reducers: {
    setPageServiceCategories: (state, action: PayloadAction<number>) => {
      state.pageFromCategoriesPage = action.payload;
    },
    setIsFetchingServiceCategories: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingServiceCategories = payload;
    },
    setIsFetchingServiceCategoriesFromCategoriesPage: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingServiceCategoriesFromCategoriesPage = payload;
    },
    setisCreatingServiceCategory: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isCreatingServiceCategory = payload;
    },
    setisDeletingServiceCategory: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isDeletingServiceCategory = payload;
    },
    setServiceCategories: (
      state,
      {
        payload: { service_categories, total, page },
      }: PayloadAction<{
        service_categories: IServiceCategory[];
        total: number;
        page: number;
      }>
    ) => {
      state.serviceCategories = service_categories;
      state.total = total;
      state.page = page;
    },
    setServiceCategoriesFromCategoriesPage: (
      state,
      {
        payload: { service_categories, total, page },
      }: PayloadAction<{
        service_categories: IServiceCategory[];
        total: number;
        page: number;
      }>
    ) => {
      state.serviceCategoriesFromCategoriesPage = service_categories;
      state.totalFromCategoriesPage = total;
      state.pageFromCategoriesPage = page;
    },
    resetServiceCategories: (state) => {
      state.serviceCategories = [];
    },
    setCurrentServiceCategory: (
      state,
      { payload }: PayloadAction<IServiceCategory>
    ) => {
      state.currentServiceCategory = payload;
    },
    clearCurrentServiceCategory: (state) => {
      state.currentServiceCategory = null;
    },
    updateFilterFromCategoriesPage: (
      state,
      { payload }: PayloadAction<IFilter>
    ) => {
      const index = state.filterArrayFromCategoriesPage.findIndex(
        (item) => item.key === payload.key
      );
      if (index === -1) {
        state.filterArrayFromCategoriesPage.push({
          key: payload.key,
          value: payload.value,
        });
      } else {
        state.filterArrayFromCategoriesPage[index].value = payload.value;
      }
    },
    updateFilter: (state, { payload }: PayloadAction<IFilter>) => {
      const index = state.filterArray.findIndex(
        (item) => item.key === payload.key
      );
      if (index === -1) {
        state.filterArray.push({ key: payload.key, value: payload.value });
      } else {
        state.filterArray[index].value = payload.value;
      }
      // state.filterArray[index].value = payload.value;
    },
    setAmountOfItemsToShow: (state, { payload }: PayloadAction<number>) => {
      state.amountOfItemsToShow = payload;
    },
    setAmountOfItemsToShowFromCategoriesPage: (
      state,
      { payload }: PayloadAction<number>
    ) => {
      state.amountOfItemsToShowFromCategoriesPage = payload;
    },
    setCurrentNameFilter: (state, { payload }: PayloadAction<string>) => {
      state.currentNameFilter = payload;
    },
    setCurrentStatusFilter: (state, { payload }: PayloadAction<string>) => {
      state.currentStatusFilter = payload;
    },
  },
});

export const fetchServiceCategories =
  ({
    page = 1,
    limit = 6,
    ativo,
  }: {
    page?: number;
    limit?: number;
    ativo?: boolean;
  }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingServiceCategories } = serviceCategoriesSlice.actions;
    dispatch(setIsFetchingServiceCategories(true));
    try {
      const state = getState();
      const { filterArray } = state.serviceCategories;
      let queryParameters = queryStringFromFilterArray(filterArray);
      if (ativo) {
        queryParameters =
          queryParameters.length > 0
            ? `${queryParameters}&ativo=${ativo}`
            : `?ativo=${ativo}`;
      }
      const pageAndLimit =
        queryParameters.length === 0
          ? `?page=${page}&limit=${limit}`
          : `&page=${page}&limit=${limit}`;
      const response = await api.get(
        `/api/servicecategories${queryParameters}${pageAndLimit}`
      );
      dispatch(
        serviceCategoriesSlice.actions.setServiceCategories(response.data.data)
      );
      dispatch(setIsFetchingServiceCategories(false));
    } catch (error: any) {
      dispatch(setIsFetchingServiceCategories(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

  export const fetchServiceCategoriesActive =
  ({ page = 1, limit = 6 }: { page?: number; limit?: number }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingServiceCategories } = serviceCategoriesSlice.actions;
    dispatch(setIsFetchingServiceCategories(true));
    try {
      const state = getState();
      const { filterArray } = state.serviceCategories;
      const queryParameters = queryStringFromFilterArray(filterArray);
      const pageAndLimit =
        queryParameters.length === 0
          ? `?page=${page}&limit=${limit}`
          : `&page=${page}&limit=${limit}`;
      const response = await api.get(
        `/api/servicecategories/active${queryParameters}${pageAndLimit}`
      );
      dispatch(
        serviceCategoriesSlice.actions.setServiceCategories(response.data.data)
      );
      dispatch(setIsFetchingServiceCategories(false));
    } catch (error: any) {
      dispatch(setIsFetchingServiceCategories(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

  export const fetchServiceCategoriesToCategoriesPage =
  ({ page = 1, limit = 6 }: { page?: number; limit?: number }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingServiceCategoriesFromCategoriesPage } =
      serviceCategoriesSlice.actions;
    dispatch(setIsFetchingServiceCategoriesFromCategoriesPage(true));
    try {
      const state = getState();
      const { filterArrayFromCategoriesPage } = state.serviceCategories;
      const queryParameters = queryStringFromFilterArray(
        filterArrayFromCategoriesPage
      );
      const pageAndLimit =
        queryParameters.length === 0
          ? `?page=${page}&limit=${limit}`
          : `&page=${page}&limit=${limit}`;
      const response = await api.get(
        `/api/servicecategories${queryParameters}${pageAndLimit}`
      );
      dispatch(
        serviceCategoriesSlice.actions.setServiceCategoriesFromCategoriesPage(
          response.data.data
        )
      );
      dispatch(setIsFetchingServiceCategoriesFromCategoriesPage(false));
    } catch (error: any) {
      dispatch(setIsFetchingServiceCategoriesFromCategoriesPage(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchServiceCategoryById =
  (serviceCategoryId: string): AppThunk =>
  async (dispatch) => {
    const { setIsFetchingServiceCategories, setCurrentServiceCategory } =
      serviceCategoriesSlice.actions;
    dispatch(setIsFetchingServiceCategories(true));
    try {
      const response = await api.get(
        `/api/servicecategories/${serviceCategoryId}`
      );
      dispatch(setCurrentServiceCategory(response.data.data));
      dispatch(setIsFetchingServiceCategories(false));
    } catch (error: any) {
      dispatch(setIsFetchingServiceCategories(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const createServiceCategory =
  (serviceCategory: IServiceCategoryFormFull): AppThunk =>
  async (dispatch) => {
    const { setisCreatingServiceCategory } = serviceCategoriesSlice.actions;
    dispatch(setisCreatingServiceCategory(true));
    try {
      await api.post(`/api/servicecategories`, serviceCategory);
      dispatch(setisCreatingServiceCategory(false));
      history.replace("/services/categories");
      toast.success("Grupo de serviço cadastrado", toastOptions);
    } catch (error: any) {
      dispatch(setisCreatingServiceCategory(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const updateServiceCategory =
  ({
    serviceCategory,
    serviceCategoryId,
  }: {
    serviceCategory: IServiceCategoryFormFull;
    serviceCategoryId: string | number;
  }): AppThunk =>
  async (dispatch) => {
    const { setisCreatingServiceCategory } = serviceCategoriesSlice.actions;
    dispatch(setisCreatingServiceCategory(true));
    try {
      await api.put(
        `/api/servicecategories/${serviceCategoryId}`,
        serviceCategory
      );
      dispatch(setisCreatingServiceCategory(false));
      toast.success("Grupo de serviço atualizado", toastOptions);
      history.replace("/services/categories");
    } catch (error: any) {
      dispatch(setisCreatingServiceCategory(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const serviceCategoryActivation =
  ({
    serviceCategoryId,
    isItemActive,
  }: {
    serviceCategoryId: string;
    isItemActive: boolean;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { serviceCategories } = getState();
    const { setisDeletingServiceCategory } = serviceCategoriesSlice.actions;
    dispatch(setisDeletingServiceCategory(true));
    try {
      await api.put(`/api/servicecategories/${serviceCategoryId}`, {
        ativo: !isItemActive,
      });
      toast.success(
        `Categoria de serviço ${
          isItemActive ? "desativada" : "ativada"
        } com sucesso`,
        toastOptions
      );

      dispatch(
        fetchServiceCategoriesToCategoriesPage({
          page: serviceCategories.pageFromCategoriesPage,
        })
      );
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setisDeletingServiceCategory(false));
    }
  };

export const deleteServiceCategory =
  (serviceCategoryId: string): AppThunk =>
  async (dispatch) => {
    const { setisDeletingServiceCategory } = serviceCategoriesSlice.actions;
    dispatch(setisDeletingServiceCategory(true));
    try {
      await api.delete(`/api/servicecategories/${serviceCategoryId}`);
      dispatch(setisDeletingServiceCategory(false));
      toast.success("Grupo de serviço excluido com sucesso", toastOptions);
      dispatch(fetchServiceCategories({}));
    } catch (error: any) {
      dispatch(setisDeletingServiceCategory(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const {
  clearCurrentServiceCategory,
  updateFilter: updateServiceCategoryFilter,
  resetServiceCategories,
  setAmountOfItemsToShow,
  setCurrentNameFilter,
  setCurrentStatusFilter,
  setServiceCategories,
  setPageServiceCategories,
  setAmountOfItemsToShowFromCategoriesPage,
  updateFilterFromCategoriesPage,
  setIsFetchingServiceCategoriesFromCategoriesPage,
} = serviceCategoriesSlice.actions;

export default serviceCategoriesSlice.reducer;
