import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IInitialState {
    rowLimitPerPage: number;
}

const initialState: IInitialState = {
  rowLimitPerPage: 6,
};

const paginationSlice = createSlice({
  name: "paginationSlice",
  initialState,
  reducers: {
    setRowLimitPerPage: (
      state,
      { payload }: PayloadAction<number>
    ) => {
      state.rowLimitPerPage = payload;
    }
  },
});

export const { setRowLimitPerPage } = paginationSlice.actions;

export default paginationSlice.reducer;
