import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFilter } from "models/shared";
import { IPatientMedicalReportAnamneseOftalmo } from "pages/MedicalRecord/components/PatientMedicalRecord/pages/AnamneseOftalmo/data";
import { IPatientMedicalReportLensPrescription } from "pages/MedicalRecord/components/PatientMedicalRecord/pages/LensPrescriptionOftalmo/data";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { AppThunk } from "store/index";
import api from "utils/API";
import { queryStringFromFilterArray } from "utils/network";
import toastOptions from "utils/toastOptions";
import {
  ICurrentMedicalRecord,
  IManualPrescriptionForm,
  IMedicalRecord,
  IMedicinePrescription,
  IMemedPrescriptionForm,
  IPrescription,
  IPrescriptionForm,
} from "../../../models/MedicalRecord";
import { IOftalmoData } from "../../../pages/MedicalRecord/components/PatientMedicalRecord/pages/ProntuarioOftalmo";
import { checkinProfessionalInOffice } from "../OfficesReducer";
import { IProfessionalCheckin } from "models/Offices";

const CALLER_ORIGIN = {
  PRE_APPOINTMENT: "PRE_APPOINTMENT",
} as const;

type ObjectValues<T> = T[keyof T];

type CallerOrigin = ObjectValues<typeof CALLER_ORIGIN>;

interface IInitialState {
  isFetchingMedicalRecord: boolean;
  isFetchingPrescription: boolean;
  isDeletingPrescription: boolean;
  oftalmoMedicalRecord: IOftalmoData | null;
  medicalRecords: IMedicalRecord[] | null;
  medicines: IMedicinePrescription[];
  medicineReports: any[];
  medicalRecordsOfProfessional: IMedicalRecord[] | null;
  selectedMedicalRecord: IMedicalRecord | null;
  currentMedicalRecord: ICurrentMedicalRecord | null;
  currentPreAppointment: IPatientMedicalReportAnamneseOftalmo | null;
  isFetchingPreAppointment: boolean;
  currentLensPrescription: IPatientMedicalReportLensPrescription | null;
  prescriptions: IPrescription[] | null;
  total: number;
  pages: number;
  filterArray: IFilter[];
  isCreatingMedicalRecord: boolean;
  isCreatingPrescription: boolean;
  medicalRecordCallerOrigin: CallerOrigin | null;
  currenceIdMedicalRecord: string;
}

interface IChangeStatusBatchMedicalRecordsProps {
  medicalRecords: MedicalRecord[];
  cb: () => void;
}

export type MedicalRecord = {
  idMedicalRecord: string;
  idprofissional?: string;
  status: "aguardando" | "aberto" | "fechado";
  prioritario?: boolean;
  preferencial?: boolean;
};

const initialState: IInitialState = {
  currenceIdMedicalRecord: "",
  oftalmoMedicalRecord: null,
  isFetchingMedicalRecord: false,
  isFetchingPrescription: false,
  isDeletingPrescription: false,
  medicines: [],
  medicalRecords: null,
  medicineReports: [],
  medicalRecordsOfProfessional: null,
  selectedMedicalRecord: null,
  currentMedicalRecord: null,
  currentPreAppointment: null,
  isFetchingPreAppointment: false,
  currentLensPrescription: null,
  prescriptions: null,
  total: 0,
  pages: 0,
  filterArray: [
    { key: "patient", value: null },
    { key: "nome", value: null },
    { key: "rg", value: null },
    { key: "cpf", value: null },
  ],
  isCreatingMedicalRecord: false,
  isCreatingPrescription: false,
  medicalRecordCallerOrigin: null,
};

const medicalRecordSlice = createSlice({
  name: "medicalRecordSlice",
  initialState,
  reducers: {
    setCurrenceIdMedicalRecord: (state, { payload }: PayloadAction<string>) => {
      state.currenceIdMedicalRecord = payload;
    },
    setisFetchingMedicalRecords: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingMedicalRecord = payload;
    },
    setMedicalRecord: (
      state,
      {
        payload: { medicalRecords, total, page },
      }: PayloadAction<{
        medicalRecords: IMedicalRecord[];
        total: number;
        page: number;
      }>
    ) => {
      state.medicalRecords = medicalRecords;
      state.total = total;
      state.pages = page;
    },
    setMedicines: (
      state,
      { payload }: PayloadAction<IMedicinePrescription[]>
    ) => {
      state.medicines = payload;
    },
    setMedicineReports: (state, { payload }: PayloadAction<any[]>) => {
      state.medicineReports = payload;
    },
    setSelectedMedicalRecord: (
      state,
      { payload }: PayloadAction<IMedicalRecord>
    ) => {
      state.selectedMedicalRecord = payload;
    },
    clearSelectedMedicalRecord: (state) => {
      state.selectedMedicalRecord = null;
    },
    setcurrentMedicalRecord: (
      state,
      { payload }: PayloadAction<ICurrentMedicalRecord>
    ) => {
      state.currentMedicalRecord = payload;
    },
    setcurrentPreAppointment: (
      state,
      { payload }: PayloadAction<IPatientMedicalReportAnamneseOftalmo | null>
    ) => {
      state.currentPreAppointment = payload;
    },
    setIsFetchingPreAppointment: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingPreAppointment = payload;
    },
    setCurrentLensPrescription: (
      state,
      { payload }: PayloadAction<IPatientMedicalReportLensPrescription | null>
    ) => {
      state.currentLensPrescription = payload;
    },
    setMedicalRecordOfProfessional: (
      state,
      {
        payload: { medicalRecords, total, page },
      }: PayloadAction<{
        medicalRecords: IMedicalRecord[];
        total: number;
        page: number;
      }>
    ) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      state.medicalRecordsOfProfessional = medicalRecords;
      state.total = total;
      state.pages = page;
    },
    setOftalmoMedicalRecord: (
      state,
      { payload }: PayloadAction<IOftalmoData | null>
    ) => {
      state.oftalmoMedicalRecord = payload;
    },
    setisFetchingPrescription: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingPrescription = payload;
    },

    setPrescriptions: (state, { payload }: PayloadAction<IPrescription[]>) => {
      state.prescriptions = payload;
    },
    setisCreatingPrescription: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreatingPrescription = payload;
    },
    setisDeletingPrescription: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeletingPrescription = payload;
    },

    setisCreatingMedicalRecord: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isCreatingMedicalRecord = payload;
    },

    updateFilter: (state, { payload }: PayloadAction<IFilter>) => {
      const index = state.filterArray.findIndex(
        (item) => item.key === payload.key
      );
      if (index === -1) {
        state.filterArray.push({ key: payload.key, value: payload.value });
      } else {
        state.filterArray[index].value = payload.value;
      }
    },
    resetFilterArray: (state, { payload }: PayloadAction<IFilter[]>) => {
      state.filterArray = [
        { key: "patient", value: null },
        { key: "nome", value: null },
        { key: "rg", value: null },
        { key: "cpf", value: null },
      ];
    },
    setMedicalRecordCallerOrigin: (
      state,
      { payload }: PayloadAction<CallerOrigin | null>
    ) => {
      state.medicalRecordCallerOrigin = payload;
    },
  },
});

export const fetchMedicalRecords =
  ({ page = 1, limit = 6 }: { page?: number; limit?: number }): AppThunk =>
  async (dispatch, getState) => {
    const { setisFetchingMedicalRecords, setMedicalRecord } =
      medicalRecordSlice.actions;

    dispatch(setisFetchingMedicalRecords(true));
    try {
      const state = getState();
      const { filterArray } = state.medicalRecords;
      const queryParameters = queryStringFromFilterArray(filterArray);
      const pageAndLimit =
        queryParameters.length === 0
          ? `?page=${page}&limit=${limit}`
          : `&page=${page}&limit=${limit}`;
      const response = await api.get(
        `/api/medical-record${queryParameters}${pageAndLimit}`
      );
      dispatch(setMedicalRecord(response.data.data));
      dispatch(setisFetchingMedicalRecords(false));
    } catch (error: any) {
      dispatch(setisFetchingMedicalRecords(false));
      if (error.message) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error);
      }
    }
  };

interface MedicalRecordsByPatientIdData {
  patientId: string;
  medicalRecordId: string;
  cb?: () => void;
}

export const fetchMedicalRecordsByPatientId =
  (patientId: string, medicalRecordId: string, cb?: Function): AppThunk =>
  async (dispatch) => {
    const {
      setisFetchingMedicalRecords,
      setcurrentMedicalRecord,
      setSelectedMedicalRecord,
    } = medicalRecordSlice.actions;

    dispatch(setisFetchingMedicalRecords(true));
    try {
      const response = await api.get(
        `/api/medical-record/${patientId}?limit=9999`
      );
      dispatch(setcurrentMedicalRecord(response.data.data));
      if (medicalRecordId) {
        const medicalRecordList: ICurrentMedicalRecord = response.data.data;
        const record = medicalRecordList.medicalRecords.find(
          (item) => item.idMedicalRecord === medicalRecordId
        );
        if (record) {
          dispatch(setSelectedMedicalRecord(record));
          if (cb) {
            cb(record);
          }
        }
      }
      dispatch(setisFetchingMedicalRecords(false));
    } catch (error: any) {
      dispatch(setisFetchingMedicalRecords(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error);
      }
    }
  };

export const fetchMedicalRecordsByProfessionalId =
  ({
    professionalId,
    cb,
    page = 1,
    limit = 6,
    statusFilter = "all",
  }: {
    professionalId: string;
    cb?: Function;
    page?: number;
    limit?: number;
    statusFilter?: string;
  }): AppThunk =>
  async (dispatch, getState) => {
    const {
      setisFetchingMedicalRecords,
      setMedicalRecordOfProfessional,
      setMedicalRecord,
    } = medicalRecordSlice.actions;

    dispatch(setisFetchingMedicalRecords(true));
    try {
      // const state = getState()
      // const { filterArray } = state.medicalRecords
      // const queryParameters = queryStringFromFilterArray(filterArray)
      // const pageAndLimit =
      //   queryParameters.length === 0
      //     ? `?page=${page}&limit=${limit}`
      //     : `&page=${page}&limit=${limit}`
      // let uri = `/api/medical-record?idmedico=${professionalId}&page=${page}&limit=${limit}&statusFilter=${statusFilter}`;

      // if (rg && rg.length > 1) {
      //   uri += "&rg=" + rg;
      // }
      // if (cpf && cpf.length > 1) {
      //   uri += "&cpf=" + cpf;
      // }
      // if (nome && nome.length > 1) {
      //   uri += "&nome=" + nome;
      // }

      const state = getState();

      const { filterArray } = state.medicalRecords;
      const queryParameters = queryStringFromFilterArray(filterArray);

      let uri = `/api/medical-record?idmedico=${professionalId}&page=${page}&limit=${limit}&statusFilter=${statusFilter}`;
      uri += `&${queryParameters.substring(1)}`;

      const response = await api.get(uri);
      dispatch(setMedicalRecordOfProfessional(response.data.data));
      dispatch(setisFetchingMedicalRecords(false));
    } catch (error: any) {
      dispatch(setisFetchingMedicalRecords(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error);
      }
    }
  };

export const fetchPrescriptionByMedicalRecordId =
  (idMedicalRecord: string): AppThunk =>
  async (dispatch) => {
    const { setisFetchingPrescription, setPrescriptions } =
      medicalRecordSlice.actions;
    dispatch(setisFetchingPrescription(true));
    try {
      // const history = useHistory();
      const response = await api.get(`/api/prescription/${idMedicalRecord}`);
      dispatch(setPrescriptions(response.data.data.prescriptions));
      dispatch(setisFetchingPrescription(false));
      // toast.success("Prescrição cadastrada", toastOptions);
      //   history.replace("/patients/medical-records");
    } catch (error: any) {
      dispatch(setisFetchingPrescription(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchMedicineReports = (): AppThunk => async (dispatch) => {
  const { setisFetchingMedicalRecords, setMedicineReports } =
    medicalRecordSlice.actions;
  dispatch(setisFetchingMedicalRecords(true));
  try {
    // const history = useHistory();
    const response = await api.get(`/api/medicines-report`);
    dispatch(setMedicineReports(response.data.data));
    dispatch(setisFetchingMedicalRecords(false));
    // toast.success("Prescrição cadastrada", toastOptions);
    //   history.replace("/patients/medical-records");
  } catch (error: any) {
    dispatch(setisFetchingMedicalRecords(false));
    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);
    } else {
      console.log(error.message);
    }
  }
};

export const fetchMedicines =
  (query: string | null): AppThunk =>
  async (dispatch) => {
    const { setisFetchingMedicalRecords, setMedicines } =
      medicalRecordSlice.actions;
    dispatch(setisFetchingMedicalRecords(true));
    try {
      if (!query) return;
      const response = await api.get(`/api/medicines?query=${query}`);
      dispatch(setMedicines(response.data.data.medicines));
      dispatch(setisFetchingMedicalRecords(false));
    } catch (error: any) {
      dispatch(setisFetchingMedicalRecords(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const createPrescription =
  ({
    prescription,
    manualPrescription,
    memedPrescription,
    cb,
  }: {
    prescription?: IPrescriptionForm;
    manualPrescription?: IManualPrescriptionForm;
    memedPrescription?: IMemedPrescriptionForm;
    cb?: Function;
  }): AppThunk =>
  async (dispatch) => {
    const { setisCreatingPrescription, setisCreatingMedicalRecord } =
      medicalRecordSlice.actions;
    dispatch(setisCreatingPrescription(true));
    try {
      if (manualPrescription) {
        await api.post(`/api/prescription`, manualPrescription);
        dispatch(
          fetchPrescriptionByMedicalRecordId(
            manualPrescription?.idMedicalRecord!
          )
        );
      }
      if (memedPrescription) {
        await api.post(`/api/prescription`, memedPrescription);
        dispatch(
          fetchPrescriptionByMedicalRecordId(
            memedPrescription?.idMedicalRecord!
          )
        );
      }
      if (prescription) {
        await api.post(`/api/prescription`, prescription);
        dispatch(
          fetchPrescriptionByMedicalRecordId(prescription?.idMedicalRecord!)
        );
      }
      // const history = useHistory();
      dispatch(setisCreatingPrescription(false));
      toast.success("Prescrição cadastrada", {
        toastId: "prescription-created",
        ...toastOptions,
      });
      // history.replace("/patients/medical-records");
    } catch (error: any) {
      dispatch(setisCreatingPrescription(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const createPreAppointment =
  ({
    data,
    medicalRecordId,
    cb,
  }: {
    data?: IPatientMedicalReportAnamneseOftalmo;
    medicalRecordId: string;
    cb?: Function;
  }): AppThunk =>
    async (dispatch) => {
      const { setisCreatingMedicalRecord } = medicalRecordSlice.actions;
      dispatch(setisCreatingMedicalRecord(true));
      try {
        await api.post(`/api/pre-appointment`, {
          ...data,
          idprontuariomedico: medicalRecordId,
        });
        // const history = useHistory();
        dispatch(setisCreatingMedicalRecord(false));
        // toast.success("Pré-Consulta cadastrada", {
        //   toastId: "preappointment-created",
        //   ...toastOptions,
        // });

      if (cb) {
        cb();
      }
    } catch (error: any) {
      dispatch(setisCreatingMedicalRecord(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };
export const createLensPrescription =
  ({
    data,
    medicalRecordId,
    cb,
  }: {
    data: IPatientMedicalReportLensPrescription;
    medicalRecordId: string;
    cb?: Function;
  }): AppThunk =>
  async (dispatch) => {
    const { setisCreatingMedicalRecord } = medicalRecordSlice.actions;
    dispatch(setisCreatingMedicalRecord(true));
    try {
      await api.post(`/api/lens-prescription`, {
        ...data,
        idprontuariomedico: medicalRecordId,
      });
      // const history = useHistory();
      dispatch(setisCreatingMedicalRecord(false));
      toast.success("Receita de lentes cadastrada", {
        toastId: "lens-prescription-created",
        ...toastOptions,
      });

      if (cb) {
        cb();
      }
    } catch (error: any) {
      dispatch(setisCreatingMedicalRecord(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const updatePreAppointment =
  ({
    data,
    medicalRecordId,
    cb,
  }: {
    data: IPatientMedicalReportAnamneseOftalmo;
    medicalRecordId: string;
    cb?: Function;
  }): AppThunk =>
  async (dispatch) => {
    const { setisCreatingMedicalRecord } = medicalRecordSlice.actions;
    dispatch(setisCreatingMedicalRecord(true));
    try {
      await api.put(`/api/pre-appointment/${medicalRecordId}`, {
        ...data,
        idprontuariomedico: medicalRecordId,
      });
      // const history = useHistory();
      dispatch(setisCreatingMedicalRecord(false));

      if (cb) {
        cb();
      }
    } catch (error: any) {
      dispatch(setisCreatingMedicalRecord(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const updateLensPrescription =
  ({
    data,
    medicalRecordId,
    cb,
  }: {
    data: IPatientMedicalReportLensPrescription;
    medicalRecordId: string;
    cb?: Function;
  }): AppThunk =>
  async (dispatch) => {
    const { setisCreatingMedicalRecord } = medicalRecordSlice.actions;
    dispatch(setisCreatingMedicalRecord(true));
    try {
      await api.put(`/api/lens-prescription/${medicalRecordId}`, {
        ...data,
        idprontuariomedico: medicalRecordId,
      });
      // const history = useHistory();
      dispatch(setisCreatingMedicalRecord(false));

      if (cb) {
        cb();
      }
    } catch (error: any) {
      dispatch(setisCreatingMedicalRecord(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const updateStatusPreAppointment =
  ({
    status,
    medicalRecordId,
    cb,
  }: {
    status: string;
    medicalRecordId: string;
    cb?: Function;
  }): AppThunk =>
  async (dispatch) => {
    const { setisCreatingMedicalRecord } = medicalRecordSlice.actions;
    dispatch(setisCreatingMedicalRecord(true));
    try {
      await api.put(`/api/status-pre-appointment/${medicalRecordId}`, {
        idprontuariomedico: medicalRecordId,
        status: status,
      });
      // const history = useHistory();
      dispatch(setisCreatingMedicalRecord(false));

      if (cb) {
        cb();
      }
    } catch (error: any) {
      dispatch(setisCreatingMedicalRecord(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const updateStatusPreAppointmentStart =
  ({
    status,
    medicalRecordId,
    cb,
  }: {
    status: string;
    medicalRecordId: string;
    cb?: Function;
  }): AppThunk =>
  async (dispatch) => {
    const { setisCreatingMedicalRecord } = medicalRecordSlice.actions;
    dispatch(setisCreatingMedicalRecord(true));
    try {
      await api.put(`/api/status-pre-appointment/${medicalRecordId}`, {
        idprontuariomedico: medicalRecordId,
        status: status,
      });
      // const history = useHistory();
      dispatch(setisCreatingMedicalRecord(false));

      if (cb) {
        cb();
      }
    } catch (error: any) {
      dispatch(setisCreatingMedicalRecord(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const updateStatusLensPrescription =
  ({
    status,
    medicalRecordId,
  }: {
    status: string;
    medicalRecordId: string;
  }): AppThunk =>
  async (dispatch) => {
    const { setisCreatingMedicalRecord } = medicalRecordSlice.actions;
    dispatch(setisCreatingMedicalRecord(true));
    try {
      await api.put(`/api/status-lens-prescription/${medicalRecordId}`, {
        idprontuariomedico: medicalRecordId,
        status: status,
      });
      // const history = useHistory();
      dispatch(setisCreatingMedicalRecord(false));
    } catch (error: any) {
      dispatch(setisCreatingMedicalRecord(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchPreAppointment =
  ({
    medicalRecordId,
    cb,
  }: {
    medicalRecordId: string;
    cb?: Function;
  }): AppThunk =>
  async (dispatch) => {
    const { setcurrentPreAppointment, setIsFetchingPreAppointment } =
      medicalRecordSlice.actions;
    try {
      dispatch(setIsFetchingPreAppointment(true));
      const res = await api.get(`/api/pre-appointment/${medicalRecordId}`);
      dispatch(setcurrentPreAppointment(res.data.data));
      if (cb) {
        cb(res.data.data);
      }
      dispatch(setIsFetchingPreAppointment(false));
    } catch (error: any) {
      dispatch(setIsFetchingPreAppointment(false));
      dispatch(setcurrentPreAppointment(null));
      if (cb) {
        cb(null);
      }
    }
  };

export const fetchLensPrescription =
  ({
    medicalRecordId,
    cb,
  }: {
    medicalRecordId: string;
    cb?: Function;
  }): AppThunk =>
  async (dispatch) => {
    const { setCurrentLensPrescription } = medicalRecordSlice.actions;
    try {
      const res = await api.get(`/api/lens-prescription/${medicalRecordId}`);
      dispatch(setCurrentLensPrescription(res.data.data));
      if (cb) {
        cb(res.data.data);
      }
    } catch (error: any) {
      dispatch(setCurrentLensPrescription(null));
      if (cb) {
        cb(null);
      }
    }
  };

export const deletePrescription =
  ({
    idMedicalRecord,
    idprescricao,
    cb,
  }: {
    idMedicalRecord: string;
    idprescricao: string | number | undefined;
    cb?: () => void;
  }): AppThunk =>
  async (dispatch) => {
    const { setisDeletingPrescription } = medicalRecordSlice.actions;
    dispatch(setisDeletingPrescription(true));
    try {
      await api.delete(`/api/prescription/${idMedicalRecord}/${idprescricao}`);
      dispatch(setisDeletingPrescription(false));
      toast.success("Prescrição removida", toastOptions);
      // history.replace("/patients/medical-records");
      dispatch(fetchPrescriptionByMedicalRecordId(idMedicalRecord));
    } catch (error: any) {
      dispatch(setisDeletingPrescription(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const updatePrescription =
  ({
    prescription,
    idMedicalRecord,
    idprescricao,
    cb,
  }: {
    idMedicalRecord: string;
    idprescricao: string | number | undefined;
    prescription: IPrescriptionForm;
    cb?: () => void;
  }): AppThunk =>
  async (dispatch) => {
    const { setisCreatingPrescription } = medicalRecordSlice.actions;
    dispatch(setisCreatingPrescription(true));
    try {
      const history = useHistory();
      await api.post(
        `/api/prescription/${idMedicalRecord}/${idprescricao}`,
        prescription
      );
      dispatch(setisCreatingPrescription(false));
      toast.success("Prescrição editada", toastOptions);
      history.replace("/patients/medical-records");
    } catch (error: any) {
      dispatch(setisCreatingPrescription(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const createMedicalRecord =
  ({
    idpaciente,
    idconsulta,
  }: {
    idpaciente: string;
    idconsulta: string;
  }): AppThunk =>
  async (dispatch) => {
    const { setisCreatingMedicalRecord } = medicalRecordSlice.actions;
    dispatch(setisCreatingMedicalRecord(true));
    try {
      await api.post(`/api/medical-record`, { idconsulta, idpaciente });
      dispatch(setisCreatingMedicalRecord(false));
      toast.success("Prontuário Criado com Sucesso", toastOptions);
    } catch (error: any) {
      dispatch(setisCreatingMedicalRecord(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error);
      }
    }
  };

export const fetchOftalmoMedicalRecord =
  ({ medicalRecordId }: { medicalRecordId: string }): AppThunk =>
  async (dispatch) => {
    const { setOftalmoMedicalRecord, setisFetchingPrescription } =
      medicalRecordSlice.actions;
    try {
      dispatch(setisFetchingPrescription(true));
      const response = await api.get(`/api/ophthalmology/${medicalRecordId}`);
      dispatch(setOftalmoMedicalRecord(response.data.data));
      dispatch(setisFetchingPrescription(false));
    } catch (error: any) {
      dispatch(setisFetchingPrescription(false));
      if (error.response) {
        if (
          error.response.data?.error?.message !== "Oftalmologia não encontrado"
        ) {
          toast.error(error.response.data?.error?.message, toastOptions);
        }
      } else {
        console.log(error);
      }
    }
  };

export const editOftalmoMedicalRecord =
  ({
    oftalmoData,
    idMedicalRecord,
    oftalmoRecordId,
  }: {
    idMedicalRecord: string;
    oftalmoRecordId: string;
    oftalmoData: IOftalmoData;
  }): AppThunk =>
  async (dispatch) => {
    try {
      await api.put(
        `/api/ophthalmology/${idMedicalRecord}/${oftalmoRecordId}`,
        oftalmoData
      );
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error);
      }
    }
  };

export const createOftalmoMedicalRecord =
  ({ oftalmoData }: { oftalmoData: IOftalmoData }): AppThunk =>
  async (dispatch) => {
    const { setOftalmoMedicalRecord } = medicalRecordSlice.actions;
    try {
      const response = await api.post("/api/ophthalmology", oftalmoData);
      dispatch(setOftalmoMedicalRecord(response.data.data));
      toast.success(
        "Prontuário Oftalmológico criado com sucesso",
        toastOptions
      );
    } catch (error: any) {
      if (error.response) {
        if (
          error.response.data?.error?.message !== "Oftalmologia já cadastrada"
        ) {
          toast.error(error.response.data?.error?.message, toastOptions);
        }
      } else {
        console.log(error);
      }
    }
  };

export const changeAttendanceNumberStatus =
  ({
    idAppointment,
    idsenha,
    status,
    cb,
  }: {
    idAppointment?: string;
    idsenha?: string;
    status: "canceled" | "open" | "closed";
    cb?: () => void;
  }): AppThunk =>
  async (dispatch) => {
    const { setisCreatingMedicalRecord } = medicalRecordSlice.actions;
    dispatch(setisCreatingMedicalRecord(true));
    try {
      if (idsenha) {
        await api.put(`/api/attendance_number/${idsenha}/status`, {
          idsenha,
        });
      } else {
        await api.put(`/api/attendance_number/${idAppointment}/status`);
      }
      dispatch(setisCreatingMedicalRecord(false));
      if (status === "open") {
        toast.success("Atendimento iniciado", toastOptions);
      } else if (status === "closed") {
        toast.success("Atendimento finalizado", toastOptions);
      }
      if (cb) {
        cb();
      }
    } catch (error: any) {
      dispatch(setisCreatingMedicalRecord(false));
      // if (error.response) {
      //   toast.error(error.response.data?.error?.message, toastOptions);
      // } else {
      //   console.log(error);
      // }
    }
  };
export const changeStatusMedicalRecord =
  ({
    idMedicalRecord,
    idprofissional,
    status,
    prioritario,
    preferencial,
    cb,
  }: {
    idMedicalRecord: string;
    idprofissional?: string;
    status: "aguardando" | "aberto" | "fechado";
    prioritario?: boolean;
    preferencial?: boolean;
    cb?: () => void;
  }): AppThunk =>
  async (dispatch) => {
    const { setisCreatingMedicalRecord } = medicalRecordSlice.actions;
    dispatch(setisCreatingMedicalRecord(true));
    try {
      await api.put(`/api/medical-record/${idMedicalRecord}/status`, {
        status,
        idprofissional,
        prioritario,
        preferencial,
      });
      dispatch(setisCreatingMedicalRecord(false));
      if (status === "aberto") {
        toast.success("Atendimento iniciado", toastOptions);
      } else if (status === "fechado") {
        toast.success("Atendimento finalizado", toastOptions);
      }
      if (cb) {
        cb();
      }
    } catch (error: any) {
      dispatch(setisCreatingMedicalRecord(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error);
      }
    }
  };

export const changeStatusBatchMedicalRecords =
  ({ medicalRecords, cb }: IChangeStatusBatchMedicalRecordsProps): AppThunk =>
  async (dispatch) => {
    const { setisCreatingMedicalRecord } = medicalRecordSlice.actions;
    dispatch(setisCreatingMedicalRecord(true));
    try {
      await Promise.all(
        medicalRecords.map(async (medicalRecord) => {
          await api.put(
            `/api/medical-record/${medicalRecord.idMedicalRecord}/status`,
            {
              status: medicalRecord.status,
              idprofissional: medicalRecord.idprofissional,
              prioritario: medicalRecord.prioritario,
              preferencial: medicalRecord.preferencial,
            }
          );
        })
      );

      toast.success("Atendimentos executados com sucesso", {
        toastId: "releaseMedicalRecordsId",
      });
      dispatch(setisCreatingMedicalRecord(false));

      if (cb) {
        cb();
      }
    } catch (error: any) {
      dispatch(setisCreatingMedicalRecord(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error);
      }
    }
  };

export const handleCallPatientMedicalRecord =
  ({
    idconsultorio,
    nomePaciente,
    idpaciente,
    idprofissional,
    prioritario,
    cb,
  }: {
    idconsultorio?: string;
    nomePaciente?: string;
    idpaciente?: string;
    idprofissional?: string;
    prioritario?: boolean;
    cb?: () => void;
  }): AppThunk =>
  async (dispatch) => {
    const { setisCreatingMedicalRecord } = medicalRecordSlice.actions;
    dispatch(setisCreatingMedicalRecord(true));
    try {
      await api.post(`/api/medical-record/call/patient`, {
        idconsultorio,
        nomePaciente,
        idpaciente,
        idprofissional,
        prioritario,
      });
      dispatch(setisCreatingMedicalRecord(false));
      toast.success("Paciente chamado", toastOptions);
      if (cb) {
        cb();
      }
    } catch (error: any) {
      if(error.response.data.error.message === "ER_NO_DEFAULT_FOR_FIELD: Field 'idconsultorio' doesn't have a default value"){
        const idconsultorio = JSON.parse(localStorage.getItem("room") || "") 
        if(idconsultorio){
          dispatch(
            checkinProfessionalInOffice({
              officeId: idconsultorio,
              callback: async () => {
                try {
                  await api.post(`/api/medical-record/call/patient`, {
                    idconsultorio,
                    nomePaciente,
                    idpaciente,
                    idprofissional,
                    prioritario,
                  });
                  toast.success("Paciente chamado", toastOptions);
                }catch(error: any){
                  toast.error("Erro inesperado", toastOptions);
                } finally{
                  dispatch(setisCreatingMedicalRecord(false));
                }
                
              },
            })
        );
        return 
        }
      }
      dispatch(setisCreatingMedicalRecord(false));
      if (error.response.data?.error.status === 401) {
        return  toast.info("Necessário fazer o check-in", toastOptions);
      } 
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error);
      }
    }
  };

export const {
  updateFilter,
  resetFilterArray,
  setOftalmoMedicalRecord,
  clearSelectedMedicalRecord,
  setMedicalRecordCallerOrigin,
  setcurrentPreAppointment,
  setCurrenceIdMedicalRecord,
} = medicalRecordSlice.actions;

export default medicalRecordSlice.reducer;
