import styled from "styled-components";
interface IRootContainerProps {
  wrap?: boolean;
}

export const RootContainer = styled.div<IRootContainerProps>`
  width: 100%;
  background: rgba(0, 161, 169, 0.1);
  /* min-height: 80px; */
  padding: 20px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: ${({ wrap }) => (wrap ? "wrap" : "nowrap")};

  .title {
    font-weight: bold;
  }

  .children {
  }
`;
