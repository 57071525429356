import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Button,
  Dialog,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { Close, ExpandMore } from "@material-ui/icons";
import { SizedBox } from "components/layout/utils";
import BasicInfo from "pages/MedicalRecord/components/PatientMedicalRecord/components/BasicInfo";
import React from "react";
import PreAppointmentInfo from "../PreAppointmentInfo";
import { useDispatch, useSelector } from "react-redux";
import { IPatientMedicalReportAnamneseOftalmo } from "pages/MedicalRecord/components/PatientMedicalRecord/pages/AnamneseOftalmo/data";
import { rootState } from "store";
import { createPreAppointment, fetchPreAppointment, updatePreAppointment, updateStatusPreAppointment } from "store/reducers/MedicalRecordReducer";
import BasicInfoPreAppointment from "../BasicInfoPreAppointment";
import { syncPatientHeightAndWeight } from "store/reducers/PatientsReducer";
import { MedicalRecordStatusEnum } from "models/MedicalRecord";
import { AccordionActions } from "@mui/material";

interface IPreAppointmentMedicalRecordProps {
  isOpenMedicalRecordBasicInfo: boolean;
  onClose: () => void;
  patientName: string;
  idMedicalRecord: string;
  patientId: string;
}

export default function PreAppointmentMedicalRecord({
  isOpenMedicalRecordBasicInfo,
  onClose,
  patientName,
  idMedicalRecord,
  patientId,
}: IPreAppointmentMedicalRecordProps) {
  const { currentPreAppointment } = useSelector(
    (state: rootState) => state.medicalRecords
  );

  const {
    medicalRecords: {
      isCreatingMedicalRecord,
      isFetchingPreAppointment,
      isFetchingMedicalRecord,
      selectedMedicalRecord,
    },
    patients: { currentPatient },
    users: { signedInUser },
  } = useSelector((state: rootState) => state);
  const dispatch = useDispatch();
  const match = useMediaQuery("(max-width: 1300px)");
  const [valueFormik, setValueFormik] = React.useState<any>({})
  const [sendSubmit, setSendSubmit] = React.useState(false)
   function submitPreAppointment() {
    setSendSubmit(!sendSubmit)
   }

  

  return (
    <Dialog open={isOpenMedicalRecordBasicInfo} onClose={onClose} fullScreen>
      <AppBar>
        <Grid container>
          <Toolbar
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Grid
              item
              container
              style={{ justifyContent: "flex-start", alignItems: "center" }}
            >
              <IconButton edge="start" color="inherit" onClick={onClose}>
                <Close />
              </IconButton>

              <SizedBox width="10px" />
              {!match && <Typography variant="h6" style={{ textTransform: "uppercase" }}>
                Dados da Pré-consulta - {patientName}
              </Typography>}
            </Grid>

            <Button
              variant="contained"
              color="primary"
              style={{
                maxHeight: "40px",
                width: "300px",
                fontWeight: "regular",
              }}
              onClick={submitPreAppointment}
              disabled={
                currentPreAppointment &&
                currentPreAppointment.status &&
                currentPreAppointment.status.toLowerCase() === "finalizado"
                  ? true
                  : false
              }
            >
              {currentPreAppointment &&
              currentPreAppointment.status &&
              currentPreAppointment.status.toLowerCase() === "finalizado"
                ? "Pré-consulta Registrar"
                : "Registrar Pré-consulta"}
            </Button>
          </Toolbar>
        </Grid>
      </AppBar>
      <Grid container spacing={2} style={{ marginTop: "64px" }}>
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Grid>
                <Grid item>
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: "13px",
                      fontWeight: "800",
                      textTransform: "uppercase",
                      color: "#00A1AA",
                    }}
                  >
                    Informações básicas:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: "13px",
                      fontWeight: "400",
                      color: "red",
                    }}
                  >
                    (As informações abaixo serão salvas automaticamente)
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <BasicInfoPreAppointment
                idMedicalRecord={idMedicalRecord}
                patientId={patientId}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Grid>
                <Grid item>
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: "13px",
                      fontWeight: "800",
                      textTransform: "uppercase",
                      color: "#00A1AA",
                    }}
                  >
                    Informações da pré-consulta:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: "13px",
                      fontWeight: "400",
                      color: "red",
                    }}
                  >
                    (As informações abaixo serão salvas automaticamente)
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <PreAppointmentInfo onClose={onClose} idMedicalRecord={idMedicalRecord} sendSubmitFinal={sendSubmit} setFormikCurrentValue={setValueFormik} />
            </AccordionDetails>
            <AccordionActions>
              <Button
                variant="contained"
                color="primary"
                style={{
                  maxHeight: "40px",
                  width: "100px",
                  fontWeight: "regular",
                }}
                onClick={() => {
                  setSendSubmit(!sendSubmit)
                }}
                
              >salvar</Button>
            </AccordionActions>
          </Accordion>
        </Grid>
      </Grid>
    </Dialog>
  );
}
