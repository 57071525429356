import { IFilter } from "./../../../models/shared/index";
import { IUser, IUserForm } from "./../../../models/User/index";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "store/index";
import { toast } from "react-toastify";
import toastOptions from "utils/toastOptions";
import api from "utils/API";
import { history } from "utils/history";
import { queryStringFromFilterArray } from "utils/network";
import { IScheduleRestrictionForm } from "models/Schedule";
import { ISelectOption } from "components/CallPatient/CallPatient.types";
import { ClearUpdateOfficePatientsFetchInterval, ClearUpdatePatientsWithoutAppointmentFecthInterval, clearAppointments, clearUpdateSchedulesFecthInterval, setIsSchedulingServiceItems, setRescheduleOrderItem } from "../AppointmentsReducer";
import { clearUpdateDisponibilityMapFetchInterval, setDisponibilityMapProfessionals, setDisponibilityMapServiceCategories, setDisponibilityMapServiceItems } from "../DisponibilityMapReducer";
import { ClearUpdateExpiresCheckinProfessionalInOfficeInterval } from "../OfficesReducer";
import { setIsSchedulingCombo, setSchedulePageCurrentDate, setSchedulePageCurrentPatient, setSchedulePageProfessionals, setSchedulePageServiceCategories, setSchedulepageserviceItems, updateMultiSchedulePageFilterArray } from "../SchedulePageReducer";
import { updateServiceItemsSchedulePageFilter } from "../ServiceItemsReducer";
import { setIsSchedulingServicePackages } from "../ServicePackages";
import { setHealthPlanCategoryFromQuote, setIsSchedulingItemsFromQuote } from "../CustomerQuotesReducer";
interface IInitialState {
  isFetchingUsers: boolean;
  isFetchingSignedInUser: boolean;
  isUpdatingUserCurrentClinic: boolean;
  isCreatingScheduleRestriction: boolean;
  isUpdatingScheduleRestriction: boolean;
  isUpdatingMemed: boolean;
  isClearTable: boolean;
  filterCargo: ISelectOption[];
  filterStatus: ISelectOption;
  filterCategoriaservico: ISelectOption[];
  filterClinic: ISelectOption[];
  isCreatingUser: boolean;
  isDeletingUser: boolean;
  signedInUser: IUser | null;
  usersSelectOptions: { name: string; id: string }[];
  users: IUser[] | null;
  currentUser: IUser | null;
  total: number;
  idClinicCash: string;
  page: number;
  filterArray: IFilter[];
}

const originalFilterArray: IFilter[] = [
  { key: "email", value: null },
  { key: "telefone", value: null },
  { key: "cargos", value: [] },
];



const initialState: IInitialState = {
  isFetchingUsers: false,
  isFetchingSignedInUser: false,
  isCreatingUser: false,
  isUpdatingUserCurrentClinic: false,
  isCreatingScheduleRestriction: false,
  isUpdatingScheduleRestriction: false,
  isUpdatingMemed: false,
  isClearTable: false ,
  filterCargo: [],
  filterStatus: { label: "TODOS", value: "" },
  filterCategoriaservico: [],
  filterClinic: [],
  isDeletingUser: false,
  users: null,
  usersSelectOptions: [],
  currentUser: null,
  signedInUser: null,
  idClinicCash: "",
  total: 0,
  page: 0,
  filterArray: [
    { key: "email", value: null },
    { key: "telefone", value: null },
    { key: "cargos", value: [] },
    { key: "nome", value: [] },
    { key: "ativo", value: null },
    { key: "idclinica", value: [] },
    { key: "idcategoriaservico", value: [] },
  ],
};

const usersSlice = createSlice({
  name: "usersSlice",
  initialState,
  reducers: {
    setFilterStatus: (state, { payload }:PayloadAction<ISelectOption>) => {
      state.filterStatus = payload;
    },
    setFilterCategoriaservico: (state, { payload }: PayloadAction<ISelectOption[]>) => {
      state.filterCategoriaservico = payload;
    },
    setFilterCargo: (state, { payload }: PayloadAction<ISelectOption[]>) => {
      state.filterCargo = payload;
    },
    setFilterClinic: (state, { payload }: PayloadAction<ISelectOption[]>) => {
      state.filterClinic = payload;
    },
    setIsClearTable: (state, { payload }: PayloadAction<boolean>) => {
      state.isClearTable = payload;
    },
    setisFetchingUsers: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingUsers = payload;
    },
    setisFetchingSignedInUser: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingSignedInUser = payload;
    },
    setisCreatingUser: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreatingUser = payload;
    },
    setIsUpdatingUserCurrentClinic: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isUpdatingUserCurrentClinic = payload;
    },
    setisDeletingUser: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeletingUser = payload;
    },

    setIsCreatingScheduleRestriction: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isCreatingScheduleRestriction = payload;
    },

    setIsUpdatingScheduleRestriction: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isUpdatingScheduleRestriction = payload;
    },

    setIsUpdatingMemed: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isUpdatingMemed = payload;
    },

    setUsers: (
      state,
      {
        payload: { users, total, page },
      }: PayloadAction<{ users: IUser[] | null; total: number; page: number }>
    ) => {
      state.users = users;
      state.total = total;
      state.page = page;
    },
    setUsersSelectOptions: (
      state,
      { payload }: PayloadAction<{ name: string; id: string }[]>
    ) => {
      state.usersSelectOptions = payload;
    },
    setCurrentUser: (state, { payload }: PayloadAction<IUser | null>) => {
      state.currentUser = payload;
    },
    setSignedInUser: (state, { payload }: PayloadAction<IUser>) => {
      state.signedInUser = payload;
    },
    clearFilter: (state, { payload }: PayloadAction<IFilter[]>) => {
      state.filterArray = payload
    },
    updateFilter: (state, { payload }: PayloadAction<IFilter>) => {
      const index = state.filterArray.findIndex(
        (item) => item.key === payload.key
      );
      state.filterArray[index].value = payload.value;
    },
    resetUsersFilterArray: (state, { payload }: PayloadAction<IFilter>) => {
      state.filterArray = originalFilterArray;
    },
    setIdClinicCash : (state, { payload }: PayloadAction<string>) => {
      state.idClinicCash = payload;
    },
  },
});
export const clearFilterUsers = (): AppThunk => async (dispatch, getState) => {
  const { clearFilter } = usersSlice.actions;
  dispatch(clearFilter([
    { key: "email", value: null },
    { key: "telefone", value: null },
    { key: "cargos", value: [] },
    { key: "nome", value: [] },
    { key: "ativo", value: null },
    { key: "idclinica", value: [] },
    { key: "idcategoriaservico", value: [] },
  ]))
}

export const fetchUsers =
  ({ page = 1, limit = 6 }: { page?: number; limit?: number }): AppThunk =>
    async (dispatch, getState) => {
      const { setisFetchingUsers } = usersSlice.actions;
      dispatch(setisFetchingUsers(true));
      try {
        const state = getState();
        const { filterArray } = state.users;
        const queryParameters = queryStringFromFilterArray(filterArray);
        const pageAndLimit =
          queryParameters.length === 0
            ? `?page=${page}&limit=${limit}`
            : `&page=${page}&limit=${limit}`;
        const response = await api.get(
          `/api/users${queryParameters}${pageAndLimit}`
        );
        dispatch(usersSlice.actions.setUsers(response.data.data));
        dispatch(setisFetchingUsers(false));
      } catch (error: any) {
        dispatch(setisFetchingUsers(false));
        if (error.response) {
          toast.error(error.response.data?.error?.message, toastOptions);
        } else {
          console.log(error.message);
        }
      }
    };

export const fetchSignedInUser = (): AppThunk => async (dispatch) => {
  const { setisFetchingSignedInUser, setSignedInUser } = usersSlice.actions;
  dispatch(setisFetchingSignedInUser(true));
  try {
    const { data } = await api.get(`/api/users/me`);
    dispatch(setSignedInUser(data.data));
    dispatch(setisFetchingSignedInUser(false));
  } catch (error: any) {
    dispatch(setisFetchingSignedInUser(false));
    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);

      if (error.response.data?.error?.code == "auth/user-token-expired") {
        localStorage.removeItem("@windowSet");
        localStorage.removeItem("@counter");
        localStorage.removeItem("@token");
        localStorage.removeItem("@refresh_token");
        localStorage.removeItem("userCheckedIn");
        localStorage.removeItem("labCheckedIn");
        localStorage.removeItem("room");
        localStorage.removeItem("currentClinicString");
        localStorage.removeItem("currentProfessionalCheckinStorage");
        localStorage.removeItem("currentBlockString");

        //Clear intervals
        dispatch(clearUpdateSchedulesFecthInterval());
        dispatch(clearUpdateDisponibilityMapFetchInterval());
        dispatch(ClearUpdatePatientsWithoutAppointmentFecthInterval());
        dispatch(ClearUpdateOfficePatientsFetchInterval());
        dispatch(ClearUpdateExpiresCheckinProfessionalInOfficeInterval());

        //Clean states
        dispatch(setDisponibilityMapServiceCategories([]));
        dispatch(setDisponibilityMapServiceItems([]));
        dispatch(setDisponibilityMapProfessionals([]));
        dispatch(
          updateMultiSchedulePageFilterArray([
            {
              key: "idcategoriaservico",
              value: [],
            },
            {
              key: "idcategories",
              value: [],
            },
            {
              key: "iditemservico",
              value: [],
            },
            {
              key: "idprofissional",
              value: [],
            },
          ])
        );
        dispatch(setSchedulePageCurrentDate(new Date()));
        dispatch(setSchedulePageServiceCategories([]));
        dispatch(setSchedulepageserviceItems([]));

        dispatch(setSchedulePageProfessionals([]));
        dispatch(
          updateServiceItemsSchedulePageFilter({
            key: "idprofessionals",
            value: [],
          })
        );
        dispatch(clearAppointments());
        dispatch(
          setSchedulePageCurrentPatient({
            label: "",
            value: "",
            idpaciente: "",
          })
        );
        dispatch(setIsSchedulingServicePackages(false));
        dispatch(setIsSchedulingCombo(false));
        dispatch(setIsSchedulingServiceItems(false));
        dispatch(setRescheduleOrderItem(false));
        dispatch(setIsSchedulingItemsFromQuote(false));
        dispatch(
          setHealthPlanCategoryFromQuote({
            label: "",
            value: "",
          })
        );

        history.replace("/auth/signin");
      }
    } else {
      console.log(error.message);
    }
  }
};

export const setUserCurrentClinic =
  (clinicId: string): AppThunk =>
    async (dispatch, getState) => {
      const { setisFetchingSignedInUser } = usersSlice.actions;
      dispatch(setisFetchingSignedInUser(true));
      try {
        const { signedInUser } = getState().users;
        if (signedInUser) {
          await api.put(`/api/users`, {
            idclinica: clinicId,
          });
          dispatch(fetchSignedInUser());
        }
        dispatch(setisFetchingSignedInUser(false));
      } catch (error: any) {
        dispatch(setisFetchingSignedInUser(false));
        if (error.response) {
          toast.error(error.response.data?.error?.message, toastOptions);
        } else {
          console.log(error.message);
        }
      }
    };

export const changeUserPassword =
  (
    senhaAtual: string,
    novaSenha: string,
    confirmarSenha: string,
    callback?: Function
  ): AppThunk =>
    async (dispatch, getState) => {
      const { setisFetchingSignedInUser } = usersSlice.actions;
      dispatch(setisFetchingSignedInUser(true));
      try {
        const { signedInUser } = getState().users;
        if (signedInUser) {
          await api.put(`/api/users/password`, {
            senhaAtual,
            novaSenha,
            confirmarSenha,
          });
          dispatch(fetchSignedInUser());
          if (callback) callback();
        }
        dispatch(setisFetchingSignedInUser(false));
        toast.success("Senha alterada com sucesso", toastOptions);
      } catch (error: any) {
        dispatch(setisFetchingSignedInUser(false));
        if (error.response) {
          toast.error(error.response.data?.error?.message, toastOptions);
        } else {
          console.log(error.message);
        }
      }
    };

export const fetchUsersBySingleRole =
  ({ role }: { page?: number; limit?: number; role: string }): AppThunk =>
    async (dispatch) => {
      const { setisFetchingUsers } = usersSlice.actions;
      dispatch(setisFetchingUsers(true));
      try {
        const response = await api.get(`/api/users?cargos=${role}`);
        dispatch(usersSlice.actions.setUsers(response.data.data));
        dispatch(setisFetchingUsers(false));
      } catch (error: any) {
        dispatch(setisFetchingUsers(false));
        if (error.response) {
          toast.error(error.response.data?.error?.message, toastOptions);
        } else {
          console.log(error.message);
        }
      }
    };
export const changeClinicCash = (userId: string): AppThunk =>
async (dispatch) => {
  const { setIdClinicCash } = usersSlice.actions;
  dispatch(setIdClinicCash(userId))
};
export const fetchUserById =
  (userId: string): AppThunk =>
    async (dispatch) => {
      const { setisFetchingUsers, setCurrentUser } = usersSlice.actions;
      dispatch(setisFetchingUsers(true));
      try {
        const response = await api.get(`/api/users/id/${userId}`);
        dispatch(setCurrentUser(response.data.data));
        dispatch(setisFetchingUsers(false));
      } catch (error: any) {
        dispatch(setCurrentUser(null));
        dispatch(setisFetchingUsers(false));
        if (error.response) {
          toast.error(error.response.data?.error?.message, toastOptions);
        } else {
          console.log(error.message);
        }
      }
    };

export const createUser =
  (user: IUserForm): AppThunk =>
    async (dispatch) => {
      const { setisCreatingUser } = usersSlice.actions;
      dispatch(setisCreatingUser(true));
      try {
        const response = await api.post(`/api/signup`, user);
        dispatch(setisCreatingUser(false));
        // console.log("responseee", response)
        // dispatch(fetchUserById(user.idusuario));
        toast.success("Usuário cadastrado", toastOptions);
        // history.action()
        history.replace(`/users/form/${response.data.data.user.idusuario}`);
      } catch (error: any) {
        dispatch(setisCreatingUser(false));
        if (error.response) {
          toast.error(error.response.data?.error?.message, toastOptions);
        } else {
          console.log(error.message);
        }
      }
    };

export const updateUser =
  ({ user, userId }: { user: IUserForm; userId: string | number }): AppThunk =>
    async (dispatch) => {
      const { setisCreatingUser } = usersSlice.actions;
      dispatch(setisCreatingUser(true));
      try {
        await api.put(`/api/users`, user);
        dispatch(setisCreatingUser(false));
        if (user.idusuario) {
          dispatch(fetchUserById(user.idusuario));
        }
        toast.success("Usuário atualizado", toastOptions);
        // if (user.idusuario) {
        //   dispatch(fetchUserById(user.idusuario));
        // }
        // history.replace("/users");
      } catch (error: any) {
        dispatch(setisCreatingUser(false));
        if (error.response) {
          toast.error(error.response.data?.error?.message, toastOptions);
        } else {
          console.log(error.message);
        }
      }
    };

export const inactivateUser =
  ({
    userId,
    isUserActive,
  }: {
    isUserActive: boolean;
    userId: string | undefined;
  }): AppThunk =>
    async (dispatch) => {
      const { setisDeletingUser } = usersSlice.actions;
      dispatch(setisDeletingUser(true));
      try {
        await api.post(`/api/users/activation/${userId}`, {
          ativo: !isUserActive,
        });
        dispatch(setisDeletingUser(false));
        const msg = isUserActive
          ? "Usuario desativado com sucesso"
          : "Usuário ativado com sucesso";
        toast.success(msg, toastOptions);
        dispatch(fetchUsers({}));
      } catch (error: any) {
        dispatch(setisDeletingUser(false));
        if (error.response) {
          toast.error(error.response.data?.error?.message, toastOptions);
        } else {
          console.log(error.message);
        }
      }
    };

export const updateScheduleRestriction =
  (userId: string, scheduleRestriction: IScheduleRestrictionForm): AppThunk =>
    async (dispatch) => {
      const { setIsUpdatingScheduleRestriction } = usersSlice.actions;
      setIsUpdatingScheduleRestriction(true);
      try {
        await api.put(`/api/users`, {
          ...scheduleRestriction,
          idusuario: userId,
        });
        setIsUpdatingScheduleRestriction(false);
        toast.success(
          "Restrições de Atendimento atualizadas com sucesso",
          toastOptions
        );
      } catch (error: any) {
        dispatch(setIsUpdatingScheduleRestriction(false));
        if (error.response) {
          toast.error(error.response.data?.error?.message, toastOptions);
        } else {
          console.log(error.message);
        }
      }
    };

export const createMemedRegister = (userId: string): AppThunk =>
  async (dispatch) => {
    const { setIsUpdatingMemed } = usersSlice.actions;
    setIsUpdatingMemed(true);
    try {
      await api.post(`/api/users/${userId}/memed`);
      setIsUpdatingMemed(false);
      toast.success(
        "Registro na memed criado com sucesso",
        toastOptions
      );
      dispatch(fetchUserById(userId));
    } catch (error: any) {
      dispatch(setIsUpdatingMemed(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const updateMemedRegister = (userId: string): AppThunk =>
  async (dispatch) => {
    const { setIsUpdatingMemed } = usersSlice.actions;
    setIsUpdatingMemed(true);
    try {
      await api.get(`/api/users/${userId}/memed`);
      setIsUpdatingMemed(false);
      toast.success(
        "Token da memed atualizado com sucesso",
        toastOptions
      );
      dispatch(fetchUserById(userId));
    } catch (error: any) {
      dispatch(setIsUpdatingMemed(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchUsersByName =
  ({ userName }: { userName: string }): AppThunk =>
    async (dispatch) => {
      const { setisFetchingUsers, setUsers } = usersSlice.actions;
      dispatch(setisFetchingUsers(true));
      try {
        if (userName) {
          const response = await api.get(
            `/api/users/search?search=${userName}&verify_ativo=true`
          );
          dispatch(setUsers(response.data.data));
        }
        dispatch(setisFetchingUsers(false));
      } catch (error: any) {
        dispatch(setisFetchingUsers(false));
        if (error.response) {
          toast.error(error.response.data?.error?.message, toastOptions);
        } else {
          console.log(error.message);
        }
      }
    };

export const { updateFilter: updateUsersFilter, resetUsersFilterArray, setUsers, setIsClearTable, setFilterCargo, setFilterStatus, setFilterCategoriaservico, setFilterClinic} =
  usersSlice.actions;

export default usersSlice.reducer;
