import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, AppDispatch } from "store/index";
import { toast } from "react-toastify";
import toastOptions from "utils/toastOptions";
import api from "utils/API";
import {
  IDiscount,
  IServiceOrderServiceItemDiscountAdditionForm,
  IServiceOrderServiceItemDiscountAdditionUpdate,
  IServiceOrderDiscountAdditionForm,
  IServiceOrderDiscountAdditionUpdate,
} from "models/Discount";
import { fetchServiceOrderById } from "../ServiceOrderReducer";

interface IInitialState {
  isApplyingAddition: boolean;
  isFetchingAddition: boolean;
  isDeletingAddition: boolean;
  currentAddition: IDiscount | null;
}

const initialState: IInitialState = {
  isApplyingAddition: false,
  isFetchingAddition: false,
  isDeletingAddition: false,
  currentAddition: null,
};

const additionsSlice = createSlice({
  name: "additionsSlice",
  initialState,
  reducers: {
    setIsApplyingAddition: (state, { payload }: PayloadAction<boolean>) => {
      state.isApplyingAddition = payload;
    },
    setIsFetchingAddition: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingAddition = payload;
    },
    setIsDeletingAddition: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeletingAddition = payload;
    },
    setCurrentAddition: (state, { payload }: PayloadAction<IDiscount>) => {
      state.currentAddition = payload;
    },
    clearCurrentAddition: (state) => {
      state.currentAddition = null;
    },
  },
});

export const updateServiceOrderServiceItemAddition =
  ({
    data,
    callback,
  }: {
    data: IServiceOrderServiceItemDiscountAdditionUpdate;
    callback?: Function;
  }): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { setIsApplyingAddition } = additionsSlice.actions;
    try {
      dispatch(setIsApplyingAddition(true));
      await api.put(`/api/addition/order-item/${data.id}`, data);
      if (callback) {
        callback();
      }
      toast.success("Acréscimo editado com sucesso", toastOptions);
      dispatch(setIsApplyingAddition(false));
      dispatch(fetchServiceOrderById(data.idordemservico));
    } catch (error) {
      dispatch(setIsApplyingAddition(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };
export const updateServiceOrderAddition =
  ({
    data,
    callback,
  }: {
    data: IServiceOrderDiscountAdditionUpdate;
    callback?: Function;
  }): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { setIsApplyingAddition } = additionsSlice.actions;
    try {
      dispatch(setIsApplyingAddition(true));
      await api.put(`/api/addition/service-order/${data.id}`, data);
      if (callback) {
        callback();
      }
      toast.success("Acréscimo editado com sucesso", toastOptions);
      dispatch(setIsApplyingAddition(false));
      dispatch(fetchServiceOrderById(data.idordemservico));
    } catch (error) {
      dispatch(setIsApplyingAddition(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const createServiceOrderServiceItemAddition =
  ({
    data,
    callback,
  }: {
    data: IServiceOrderServiceItemDiscountAdditionForm;
    callback?: Function;
  }): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { setIsApplyingAddition } = additionsSlice.actions;
    try {
      dispatch(setIsApplyingAddition(true));
      await api.post(`/api/addition/order-item`, data);
      if (callback) {
        callback();
      }
      toast.success("Acréscimo aplicado com sucesso", toastOptions);
      dispatch(setIsApplyingAddition(false));
      dispatch(fetchServiceOrderById(data.idordemservico));
    } catch (error) {
      dispatch(setIsApplyingAddition(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const createServiceOrderAddition =
  ({
    data,
    callback,
  }: {
    data: IServiceOrderDiscountAdditionForm;
    callback?: Function;
  }): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { setIsApplyingAddition } = additionsSlice.actions;
    try {
      dispatch(setIsApplyingAddition(true));
      await api.post(`/api/addition/service-order`, data);
      if (callback) {
        callback();
      }
      toast.success("Acréscimo aplicado com sucesso", toastOptions);
      dispatch(setIsApplyingAddition(false));
      dispatch(fetchServiceOrderById(data.idordemservico));
    } catch (error) {
      dispatch(setIsApplyingAddition(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchAddition =
  (additionId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { setIsFetchingAddition, setCurrentAddition } =
      additionsSlice.actions;
    try {
      dispatch(setIsFetchingAddition(true));
      const response = await api.get(`/api/addition/${additionId}`);
      dispatch(setCurrentAddition(response.data.data));
      dispatch(setIsFetchingAddition(false));
    } catch (error) {
      dispatch(setIsFetchingAddition(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const deleteServiceOrderServiceItemAddition =
  ({
    additionId,
    callback,
    idordemservico,
    email,
    password,
    isForced,
  }: {
    additionId: string;
    idordemservico: string;
    email: string | undefined;
    password: string | undefined;
    callback?: Function;
    isForced?: boolean;
  }): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { setIsDeletingAddition } = additionsSlice.actions;
    try {
      dispatch(setIsDeletingAddition(true));
      await api.delete(`/api/addition/order-item/${additionId}`, {
        data: { email, password, isForced },
      });
      if (callback) {
        callback();
      }
      dispatch(fetchServiceOrderById(idordemservico));
      toast.success("Acréscimo excluido com sucesso", toastOptions);
      dispatch(setIsDeletingAddition(false));
    } catch (error) {
      dispatch(setIsDeletingAddition(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };
export const deleteServiceOrderAddition =
  ({
    callback,
    idordemservico,
    email,
    password,
    motivo,
    isForced,
  }: {
    idordemservico: string;
    callback?: Function;
    email: string;
    password: string;
    motivo?: string;
    isForced?: boolean;
  }): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { setIsDeletingAddition } = additionsSlice.actions;
    try {
      dispatch(setIsDeletingAddition(true));
      await api.delete(`/api/addition/service-order`, {
        data: { idordemservico, email, password, motivo, isForced },
      });
      if (callback) {
        callback();
      }
      dispatch(fetchServiceOrderById(idordemservico));
      toast.success("Acréscimo excluido com sucesso", toastOptions);
      dispatch(setIsDeletingAddition(false));
    } catch (error) {
      dispatch(setIsDeletingAddition(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const { clearCurrentAddition } = additionsSlice.actions;

export default additionsSlice.reducer;
