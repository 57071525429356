import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, AppDispatch } from "store/index";
import { toast } from "react-toastify";
import toastOptions from "utils/toastOptions";
import api from "utils/API";
import {
  IPatientAttendance,
  IPatientAttendanceForm,
} from "models/PatientAttendance";

interface IInitialState {
  isFetchingPatientAttendanceHistory: boolean;
  isCreatingOrUpdatingPatientAttendanceHistory: boolean;
  isDeletingPatientAttendanceHistory: boolean;
  patientAttendanceHistory: IPatientAttendance[] | null;
  page: number;
  total: number;
}

const initialState: IInitialState = {
  isFetchingPatientAttendanceHistory: false,
  isCreatingOrUpdatingPatientAttendanceHistory: false,
  isDeletingPatientAttendanceHistory: false,
  patientAttendanceHistory: null,
  page: 0,
  total: 0,
};

const patientAttendanceHistorySlice = createSlice({
  name: "patientAttendanceHistorySlice",
  initialState,
  reducers: {
    setIsCreatingOrUpdatingPatientAttendanceHistory: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isCreatingOrUpdatingPatientAttendanceHistory = payload;
    },
    setIsFetchingPatientAttendanceHistory: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingPatientAttendanceHistory = payload;
    },
    setIsDeletingPatientAttendanceHistory: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isDeletingPatientAttendanceHistory = payload;
    },
    setPatientAttendanceHistory: (
      state,
      {
        payload: { attendanceHistory, page, total },
      }: PayloadAction<{
        attendanceHistory: IPatientAttendance[];
        page: number;
        total: number;
      }>
    ) => {
      state.page = page;
      state.patientAttendanceHistory = attendanceHistory;
      state.total = total;
    },
  },
});

export const fetchPatientAttendanceHistory = ({
  page = 1,
  limit = 6,
  patientId,
}: {
  patientId: string;
  page?: number;
  limit?: number;
}): AppThunk => async (dispatch: AppDispatch) => {
  const {
    setIsFetchingPatientAttendanceHistory,
    setPatientAttendanceHistory,
  } = patientAttendanceHistorySlice.actions;
  try {
    dispatch(setIsFetchingPatientAttendanceHistory(true));
    const response = await api.get(
      `/api/patients/${patientId}/attendance-history?page=${page}&limit=${limit}`
    );
    dispatch(setPatientAttendanceHistory(response.data.data));
    dispatch(setIsFetchingPatientAttendanceHistory(false));
  } catch (error) {
    dispatch(setIsFetchingPatientAttendanceHistory(false));
    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);
    } else {
      console.log(error.message);
    }
  }
};

export const createPatientAttendanceHistory = ({
  attendanceHistory,
  callback,
}: {
  attendanceHistory: IPatientAttendanceForm;
  callback?: Function;
}): AppThunk => async (dispatch: AppDispatch) => {
  const {
    setIsCreatingOrUpdatingPatientAttendanceHistory,
  } = patientAttendanceHistorySlice.actions;
  dispatch(setIsCreatingOrUpdatingPatientAttendanceHistory(true));
  try {
    await api.post(`/api/patients/attendance-history`, attendanceHistory);
    dispatch(setIsCreatingOrUpdatingPatientAttendanceHistory(false));
    toast.success("Observação cadastrada", toastOptions);
    if (callback) {
      callback();
    }
    dispatch(
      fetchPatientAttendanceHistory({ patientId: attendanceHistory.idpaciente })
    );
  } catch (error) {
    dispatch(setIsCreatingOrUpdatingPatientAttendanceHistory(false));
    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);
    } else {
      console.log(error.message);
    }
  }
};

export default patientAttendanceHistorySlice.reducer;
