import {
  AnyAction,
  createSlice,
  PayloadAction,
  ThunkAction,
} from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { IOffice, IProfessionalCheckin } from "models/Offices";
import { IFilter } from "models/shared";
import { toast } from "react-toastify";
import { AppThunk, rootState } from "store";
import api from "utils/API";
import { queryStringFromFilterArray } from "utils/network";
import toastOptions from "utils/toastOptions";
import {
  AttendanceNumbersByPatientsByDay,
  PatientsAttended,
  PatientsAttendedReducerState,
  PatientsAttendedResponse,
} from "./PatientsAttended.types";

const initialState: PatientsAttendedReducerState = {
  patientsAttended: [],
  isFetchingPatientsAttended: false,
  page: 1,
  total: 0,
  limit: 6,
  laboratoryProduction: [],
  filterArrayLaboratoryProduction: [
    { key: "nomepaciente", value: null },
    {
      key: "start_date",
      value: dayjs(new Date()).format("YYYY-MM-DD").toString(),
    },
    {
      key: "end_date",
      value: dayjs(new Date()).format("YYYY-MM-DD").toString(),
    },
    { key: "status", value: null },
    { key: "idprofissional", value: null },
    { key: "idclinica", value: null },
  ],
  filterArray: [
    { key: "nomepaciente", value: null },
    {
      key: "start_date",
      value: dayjs(new Date()).format("YYYY-MM-DD").toString(),
    },
    {
      key: "end_date",
      value: dayjs(new Date()).format("YYYY-MM-DD").toString(),
    },
    { key: "status", value: null },
    { key: "idprofissional", value: null },
    { key: "idclinica", value: null },
  ],
  isOpen: false,
  updatePatientsAttendedFecthIntervalId: -1,
  isCheckinMade: false,
  professionalOffices: [],
  roomId: "",
  currentProfessionalCheckin: null,
  isFetchingOffices: false,
};

const patientsAttendedSlice = createSlice({
  name: "patientsAttended",
  initialState,
  reducers: {
    setPatientsAttended: (
      state,
      { payload }: PayloadAction<PatientsAttended[]>
    ) => {
      state.patientsAttended = payload;
    },
    setLaboratoryProduction: (state,  { payload }: PayloadAction<AttendanceNumbersByPatientsByDay[]>) => {
      state.laboratoryProduction = payload
    },
    setPatientsFinishedHistory: (
      state,
      { payload }: PayloadAction<PatientsAttended[]>
    ) => {
      state.patientsFinishedHistory = payload;
    },
    setShowPatientsFinishedHistoryReport: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.showPatientsFinishedHistoryReport = payload;
    },
    setIsFetchingPatientsAttended: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingPatientsAttended = payload;
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setTotal: (state, { payload }: PayloadAction<number>) => {
      state.total = payload;
    },
    setLimit: (state, { payload }: PayloadAction<number>) => {
      state.limit = payload;
    },
    updateFilterLaboratoryProduction: (state,{payload}: PayloadAction<IFilter>) =>{
      const index = state.filterArrayLaboratoryProduction.findIndex(
        (item) => item.key === payload.key
      );
      if (index === -1) {
        state.filterArrayLaboratoryProduction.push({ key: payload.key, value: payload.value });
      } else {
        state.filterArrayLaboratoryProduction[index].value = payload.value;
      }
    },
    updateFilter: (state, { payload }: PayloadAction<IFilter>) => {
      const index = state.filterArray.findIndex(
        (item) => item.key === payload.key
      );
      if (index === -1) {
        state.filterArray.push({ key: payload.key, value: payload.value });
      } else {
        state.filterArray[index].value = payload.value;
      }
    },
    setIsOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpen = payload;
    },
    setUpdatePatientsAttendedFecthIntervalId: (
      state,
      { payload }: PayloadAction<number>
    ) => {
      state.updatePatientsAttendedFecthIntervalId = payload;
    },
    setIsCheckinMade: (state, { payload }: PayloadAction<boolean>) => {
      state.isCheckinMade = payload;
    },
    setRoomId: (state, { payload }: PayloadAction<string>) => {
      state.roomId = payload;
    },
    setProfessionalCurrentCheckin: (
      state,
      { payload }: PayloadAction<IProfessionalCheckin | null>
    ) => {
      state.currentProfessionalCheckin = payload;
    },
    setProfessionalOffices: (state, { payload }: PayloadAction<IOffice[]>) => {
      state.professionalOffices = payload;
    },
    setIsFetchingOffices: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingOffices = payload;
    },
  },
});

export const fetchPatientsAttended =
  ({ page = 1, limit = 6 }: { page?: number; limit?: number }): AppThunk =>
  async (dispatch, getState) => {
    const {
      setPatientsAttended,
      setIsFetchingPatientsAttended,
      setPage,
      setTotal,
    } = patientsAttendedSlice.actions;
    const state = getState();
    const { filterArray } = state.patientAttended;
    const queryParameters = queryStringFromFilterArray(filterArray);
    const pageAndLimit =
      queryParameters.length === 0
        ? `?page=${page}&limit=${limit}`
        : `&page=${page}&limit=${limit}`;

    dispatch(setIsFetchingPatientsAttended(true));

    try {
      const { data }: PatientsAttendedResponse = await api.get(
        `api/professional/patient-history${queryParameters}${pageAndLimit}`
      );
      dispatch(setPatientsAttended(data.data.attendance_number));
      dispatch(setPage(data.data.page));
      dispatch(setTotal(data.data.total));
    } catch (error: any) {
      console.log(error);
      if (error.response) {
        toast.error(error.response.data.message, toastOptions);
      }
    } finally {
      dispatch(setIsFetchingPatientsAttended(false));
    }
  };

export const fetchPatientsAttendedWithoutLoading =
  ({ page = 1, limit = 6 }: { page?: number; limit?: number }): AppThunk =>
  async (dispatch, getState) => {
    const { setPatientsAttended, setPage, setTotal } =
      patientsAttendedSlice.actions;
    const state = getState();
    const { filterArray } = state.patientAttended;
    const queryParameters = queryStringFromFilterArray(filterArray);
    const pageAndLimit =
      queryParameters.length === 0
        ? `?page=${page}&limit=${limit}`
        : `&page=${page}&limit=${limit}`;

    try {
      const { data }: PatientsAttendedResponse = await api.get(
        `api/professional/patient-history${queryParameters}${pageAndLimit}`
      );
      dispatch(setPatientsAttended(data.data.attendance_number));
      dispatch(setPage(data.data.page));
      dispatch(setTotal(data.data.total));
    } catch (error: any) {
      console.log(error);
      if (error.response) {
        toast.error(error.response.data.message, toastOptions);
      }
    }
  };

  export const setShowPatientsFinishedHistoryReport = (value: boolean): AppThunk => 
  async (dispatch, getState) => {
    const {
      setShowPatientsFinishedHistoryReport
    } = patientsAttendedSlice.actions;

    dispatch(setShowPatientsFinishedHistoryReport(value));
  };
  export const fetchLaboratoryProduction = 
  (callback?: Function): AppThunk =>
  async (dispatch, getState) => {
    const {
      setPatientsFinishedHistory,
      setIsFetchingPatientsAttended,
      setLaboratoryProduction
    } = patientsAttendedSlice.actions;
    const state = getState();
    const { filterArrayLaboratoryProduction } = state.patientAttended;
    const queryParameters = queryStringFromFilterArray(filterArrayLaboratoryProduction);

    dispatch(setIsFetchingPatientsAttended(true));

    try {
      const { data }: PatientsAttendedResponse = await api.get(
        `api/professional/patient-finished-history${queryParameters}`
      );
      dispatch(setPatientsFinishedHistory(data.data.attendance_number));
      dispatch(setLaboratoryProduction(data.data.attendance_numbers_by_patients_by_day));
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data.message, toastOptions);
      }
    } finally {
      dispatch(setIsFetchingPatientsAttended(false));
    }
  }

  export const fetchPatientsFinishedHistory =
  (callback?: Function): AppThunk =>
  async (dispatch, getState) => {
    const {
      setPatientsFinishedHistory,
      setIsFetchingPatientsAttended,
      setShowPatientsFinishedHistoryReport,
      setPage,
      setTotal,
    } = patientsAttendedSlice.actions;
    const state = getState();
    const { filterArray } = state.patientAttended;
    const queryParameters = queryStringFromFilterArray(filterArray);

    dispatch(setPatientsFinishedHistory([]));

    dispatch(setIsFetchingPatientsAttended(true));

    try {
      const { data }: PatientsAttendedResponse = await api.get(
        `api/professional/patient-finished-history${queryParameters}`
      );
      dispatch(setPatientsFinishedHistory(data.data.attendance_number));
      if (callback) {
        callback();
      }
    } catch (error: any) {
      console.log(error);
      if (error.response) {
        toast.error(error.response.data.message, toastOptions);
      }
    } finally {
      dispatch(setIsFetchingPatientsAttended(false));
    }
  };

export const clearUpdatePatientsAttendedFecthInterval =
  (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const { updatePatientsAttendedFecthIntervalId } = state.patientAttended;

    if (updatePatientsAttendedFecthIntervalId) {
      clearInterval(updatePatientsAttendedFecthIntervalId);
    }
  };

export const startUpdatePatientsAttendedFecthInterval =
  ({ page, limit }: { page: number; limit: number }): AppThunk =>
  async (dispatch, getState) => {
    const { setUpdatePatientsAttendedFecthIntervalId } =
      patientsAttendedSlice.actions;

    const state = getState();
    const { updatePatientsAttendedFecthIntervalId } = state.patientAttended;
    if (updatePatientsAttendedFecthIntervalId) {
      clearInterval(updatePatientsAttendedFecthIntervalId);
    }

    var _tid = setInterval(() => {
      const location = window.location.href.split("/");
      const inSchedulePage = location[location.length - 1].includes("sala");

      if (inSchedulePage) {
        dispatch(fetchPatientsAttendedWithoutLoading({ page, limit }));
      } else {
        dispatch(clearUpdatePatientsAttendedFecthInterval());
      }
    }, 10000);

    dispatch(setUpdatePatientsAttendedFecthIntervalId(_tid));
  };

export const fetchOfficesByProfessional =
  (professionalId: string, idclinica?: string, idbloco?: string): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingOffices, setProfessionalOffices } =
      patientsAttendedSlice.actions;
    dispatch(setIsFetchingOffices(true));
    try {
      const state = getState();
      const { filterArray } = state.patientAttended;
      const queryParameters = queryStringFromFilterArray(filterArray);
      const response = await api.get(
        `/api/professional/${professionalId}/offices${queryParameters}&idclinica=${idclinica}&idbloco=${idbloco}`
      );
      dispatch(setProfessionalOffices(response.data.data));
      dispatch(setIsFetchingOffices(false));
    } catch (error: any) {
      dispatch(setIsFetchingOffices(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const checkinProfessionalInOffice =
  ({
    officeId,
    callback,
  }: {
    officeId: string;
    callback?: Function;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsCheckinMade, setProfessionalCurrentCheckin } =
      patientsAttendedSlice.actions;
    try {
      const response = await api.post(`/api/professional/checkin`, {
        idconsultorio: officeId,
        isLab: true,
      });
      dispatch(setProfessionalCurrentCheckin(response.data.data));
      dispatch(setIsCheckinMade(true));
      toast.success("Check-in realizado", toastOptions);
      if (callback) callback(response.data.data);
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
      if (callback) callback(null);
    }
  };

export const checkoutProfessionalInOffice =
  ({
    idcheckin,
    callback,
  }: {
    idcheckin: string;
    callback?: Function;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsCheckinMade } = patientsAttendedSlice.actions;
    try {
      await api.patch(`/api/professional/checkin/${idcheckin}`);
      dispatch(setIsCheckinMade(false));
      toast.success("Check-out realizado");
      if (callback) callback();
    } catch (error: any) {
      dispatch(setIsCheckinMade(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchProfessionalCheckin =
  (): ThunkAction<void, rootState, unknown, AnyAction> => async (dispatch) => {
    const { setProfessionalCurrentCheckin } = patientsAttendedSlice.actions;
    try {
      const response = await api.get(`/api/professional/checkin`);
      dispatch(setProfessionalCurrentCheckin(response.data.data));
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message);
      } else {
        console.log(error.message);
      }
    }
  };

export const {
  setLimit,
  setPage,
  updateFilter,
  setIsOpen,
  setIsCheckinMade,
  setRoomId,
  setProfessionalCurrentCheckin,
  updateFilterLaboratoryProduction,
  setPatientsAttended
} = patientsAttendedSlice.actions;

export default patientsAttendedSlice.reducer;
