import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  LinearProgress,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { FullWidthContainer } from "components/layout/base";
import { Formik, Form as FormikForm } from "formik";
import { IPatientMedicalReportAnamneseOftalmo } from "pages/MedicalRecord/components/PatientMedicalRecord/pages/AnamneseOftalmo/data";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { rootState } from "store";
import {
  createPreAppointment,
  fetchPreAppointment,
  setcurrentPreAppointment,
  updatePreAppointment,
  setCurrenceIdMedicalRecord,
  updateStatusPreAppointment,
} from "store/reducers/MedicalRecordReducer";
import { initialState } from "../../../MedicalRecord/components/PatientMedicalRecord/pages/AnamneseOftalmo/data";
import { toast } from "react-toastify";
import toastOptions from "utils/toastOptions";
// @ts-ignore
import { FormikTextField } from "formik-material-fields";
import { IPatient } from "models/Patients";
import {
  setCurrentPatient,
  syncPatientHeightAndWeight,
} from "store/reducers/PatientsReducer";
import { MedicalRecordStatusEnum } from "models/MedicalRecord";

interface IPreAppointmentInfoProps {
  idMedicalRecord: string;
  medicalRecord?: boolean;
  setFormikCurrentValue: Function;
  sendSubmitFinal: boolean;
  onClose: Function
}

export default function PreAppointmentInfo({
  idMedicalRecord,
  medicalRecord = false,
  setFormikCurrentValue, sendSubmitFinal, onClose
}: IPreAppointmentInfoProps) {
  const match = useMediaQuery("(max-width: 800px)");
  const [initialValues, setInitialValues] = useState(initialState);
  const [arterialPressure, setArterialPressure] = useState<string>("");
  const [temperature, setTemperature] = useState<string>("");
  const [oximetry, setOximetry] = useState<string>("");
  const [weight, setWeight] = useState<number | undefined>();
  const [height, setHeight] = useState<number | undefined>();
  const [notes, setNotes] = useState<string>("");
  const [firstIteration, setFirstIteration] = useState<boolean>(false);
  const [timerId, setTimerId] = useState<number | null>(null);

  const {
    medicalRecords: {
      isCreatingMedicalRecord,
      currentPreAppointment,
      isFetchingPreAppointment,
      isFetchingMedicalRecord,
      selectedMedicalRecord,
    },
    patients: { currentPatient },
    users: { signedInUser },
  } = useSelector((state: rootState) => state);

  const dispatch = useDispatch();

  const formikValues = useRef<{
    values: IPatientMedicalReportAnamneseOftalmo;
  } | null>(null);

  const setUpdateDataInterval = () => {
    if (timerId) {
      clearTimeout(timerId);
    }

    var _tid = setTimeout(() => {
      //@ts-ignore
      if (formikValues.current && formikValues.current.values) {
        //@ts-ignore
        syncData(formikValues.current.values);
      }
    }, 3000);

    setTimerId(_tid);
  };

  useEffect(() => {
    if(firstIteration){
      if (timerId) {
        clearTimeout(timerId);
      }
      //@ts-ignore
      syncData(formikValues.current.values, () => {
        if (currentPreAppointment) {
          dispatch(
            updateStatusPreAppointment({
              medicalRecordId: idMedicalRecord,
              status: "finalizado",
              cb: () => {
                onClose();
              },
            })
          );
        }
      });
    }
    setFirstIteration(true)
  },[sendSubmitFinal])

  const syncData = (values: IPatientMedicalReportAnamneseOftalmo, cb?: Function) => {
    const newValues: IPatientMedicalReportAnamneseOftalmo = {
      ...values,
    };
    delete newValues.status;
    if (currentPreAppointment) {
      delete newValues.altura;
      delete newValues.peso;
      dispatch(
        updatePreAppointment({
          medicalRecordId: idMedicalRecord,
          data: newValues,
          cb: cb ? cb() : () => {}
        })
      );

      if (
        currentPreAppointment.peso !== values.peso ||
        currentPreAppointment.altura !== values.altura
      ) {
        dispatch(
          syncPatientHeightAndWeight({
            weight: values.peso ? values.peso : 0,
            height: values.altura ? values.altura : 0,
            idmedicalrecord: idMedicalRecord,
            idpaciente: currentPatient.idpaciente,
            cb: () => {
              dispatch(
                fetchPreAppointment({
                  medicalRecordId: idMedicalRecord,
                  cb: (data: IPatientMedicalReportAnamneseOftalmo) =>
                    fillInputsWithData(data),
                })
              );
            },
          })
        );
      }
    } else {
      if (
        selectedMedicalRecord &&
        !selectedMedicalRecord.datafinalizado &&
        selectedMedicalRecord.status &&
        selectedMedicalRecord.status !== MedicalRecordStatusEnum.FECHADO
      ) {
        if (currentPatient) {
          newValues.peso = currentPatient.peso
            ? Number(currentPatient.peso)
            : 0;
          newValues.altura = currentPatient.altura
            ? Number(currentPatient.altura)
            : 0;
        }
        dispatch(
          createPreAppointment({
            medicalRecordId: idMedicalRecord,
            data: newValues,
            cb: () => {
              dispatch(
                fetchPreAppointment({
                  medicalRecordId: idMedicalRecord,
                  cb: (data: IPatientMedicalReportAnamneseOftalmo) =>{
                    fillInputsWithData(data)
                    if(cb){
                      cb()
                    }
                  }
                })
              );
            },
          })
        );
      }
    }
  };

  function isDisabledByFinishingTime(): boolean {
    if (
      selectedMedicalRecord &&
      (selectedMedicalRecord.status === MedicalRecordStatusEnum.ABERTO ||
        selectedMedicalRecord.status === MedicalRecordStatusEnum.AGUARDANDO ||
        (selectedMedicalRecord?.status === MedicalRecordStatusEnum.FECHADO &&
          new Date().getTime() -
            new Date(selectedMedicalRecord.datafinalizado).getTime() <
            2 * 24 * 60 * 60 * 1000))
    ) {
      return false;
    } else {
      return true;
    }
  }

  function fillInputsWithData(values: IPatientMedicalReportAnamneseOftalmo) {
    setInitialValues(values);
    if (values.pressao_arterial) {
      setArterialPressure(values.pressao_arterial);
    }
    if (values.temperatura) {
      setTemperature(values.temperatura);
    }
    if (values.oximetria) {
      setOximetry(values.oximetria);
    }
    if (values.peso) {
      setWeight(values.peso);
    } else {
      setWeight(0);
    }
    if (values.altura) {
      setHeight(values.altura);
    } else {
      setHeight(0);
    }
    if (values.notas) {
      setNotes(values.notas);
    }
  }

  // useEffect(() => {
  //   dispatch(setcurrentPreAppointment(null));
  // }, []);

  useEffect(() => {
    if (idMedicalRecord) {
      dispatch(setCurrenceIdMedicalRecord(idMedicalRecord));

      dispatch(
        fetchPreAppointment({
          medicalRecordId: idMedicalRecord,
          cb: (data: IPatientMedicalReportAnamneseOftalmo) => {
            if (data && data !== null) {
              fillInputsWithData(data);
            }
            if (
              data === null &&
              idMedicalRecord &&
              selectedMedicalRecord &&
              !selectedMedicalRecord.datafinalizado &&
              selectedMedicalRecord.status &&
              selectedMedicalRecord.status !== MedicalRecordStatusEnum.FECHADO
            ) {
              dispatch(
                createPreAppointment({
                  medicalRecordId: idMedicalRecord,
                  cb: () => {
                    dispatch(
                      fetchPreAppointment({
                        medicalRecordId: idMedicalRecord,
                      })
                    );
                  },
                })
              );
            }
          },
        })
      );
    }
  }, [idMedicalRecord, dispatch]);

  useEffect(() => {
    if (currentPreAppointment) {
      fillInputsWithData(currentPreAppointment);
    }
  }, [currentPreAppointment]);

  return (
    <FullWidthContainer>
      {isFetchingMedicalRecord && !medicalRecord ? (
        <LinearProgress color="primary" />
      ) : (
        <Formik
          initialValues={initialValues}
          //@ts-ignore
          innerRef={formikValues}
          // validationSchema={validationSchema}
          onSubmit={(values) => {
            const newValues: IPatientMedicalReportAnamneseOftalmo = {
              ...values,
            };

            delete newValues.altura;
            delete newValues.peso;

            dispatch(
              updatePreAppointment({
                medicalRecordId: idMedicalRecord,
                data: newValues,
                cb: () => {
                  dispatch(
                    fetchPreAppointment({
                      medicalRecordId: idMedicalRecord,
                      cb: () => {
                        toast.success(
                          "Dados de pré-consulta atualizados!",
                          toastOptions
                        );
                      },
                    })
                  );
                },
              })
            );

            if (
              currentPreAppointment &&
              (currentPreAppointment.peso !== values.peso ||
                currentPreAppointment.altura !== values.altura)
            ) {
              dispatch(
                syncPatientHeightAndWeight({
                  weight: values.peso ? values.peso : 0,
                  height: values.altura ? values.altura : 0,
                  idmedicalrecord: idMedicalRecord,
                  idpaciente: currentPatient.idpaciente,
                  cb: () => {
                    dispatch(
                      fetchPreAppointment({
                        medicalRecordId: idMedicalRecord,
                        cb: (data: IPatientMedicalReportAnamneseOftalmo) =>
                          fillInputsWithData(data),
                      })
                    );
                  },
                })
              );
            }
          }}
          enableReinitialize
        >
          {({ errors, setFieldValue, isValid, ...props }) => (
            <FormikForm
              onChange={() => {
                setUpdateDataInterval();
              }}
            >
              <Grid container spacing={2} xs={12}>
                <Grid container>
                  <Typography
                    variant="h6"
                    style={{
                      fontSize: 16,
                      color: "red",
                    }}
                  >
                    A altura e peso abaixo representam as informações do
                    paciente no momento da realização desta consulta
                  </Typography>
                </Grid>
                {/* Pressão arterial */}
                <Grid
                  container
                  spacing={2}
                  xs={12}
                  style={{
                    margin: ".5rem 7px",
                  }}
                >
                  <Grid
                    container
                    xs={12}
                    spacing={4}
                    direction={match ? "column" : "row"}
                  >
                    <Grid item>
                      <Typography variant="body1">Pressão Arterial</Typography>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="pressao_arterial"
                        // label="Pressão arterial"
                        // error={!!errors.nomepaciente}
                        // helperText={!!errors.nomepaciente && errors.nomepaciente}
                        value={arterialPressure}
                        onChange={(e) => {
                          setArterialPressure(e.target.value);
                          setFieldValue("pressao_arterial", e.target.value);
                          setFormikCurrentValue(props.values)
                        }}
                        disabled={isDisabledByFinishingTime()}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">Temperatura</Typography>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="temperatura"
                        // label="Temperatura"
                        // error={!!errors.nomepaciente}
                        // helperText={!!errors.nomepaciente && errors.nomepaciente}
                        onChange={(e) => {
                          setTemperature(e.target.value);
                          setFieldValue("temperatura", e.target.value);
                          setFormikCurrentValue(props.values)
                          
                        }}
                        value={temperature}
                        disabled={isDisabledByFinishingTime()}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">
                        Saturação de oxigênio
                      </Typography>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="oximetria"
                        // label="Saturação de oxigênio"
                        // error={!!errors.nomepaciente}
                        // helperText={!!errors.nomepaciente && errors.nomepaciente}
                        onChange={(e) => {
                          setOximetry(e.target.value);
                          setFieldValue("oximetria", e.target.value);
                          setFormikCurrentValue(props.values)

                        }}
                        value={oximetry}
                        disabled={isDisabledByFinishingTime()}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">Altura</Typography>
                      <TextField
                        fullWidth
                        variant="outlined"
                        type="number"
                        name="altura"
                        // label="Altura"
                        // error={!!errors.altura}
                        // helperText={!!errors.altura && errors.altura}
                        value={height}
                        onChange={(e: any) => {
                          setFieldValue("altura", Number(e.target.value));
                          setHeight(e.target.value);
                          setFormikCurrentValue(props.values)

                        }}
                        disabled={isDisabledByFinishingTime()}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">Peso</Typography>
                      <TextField
                        fullWidth
                        variant="outlined"
                        type="number"
                        name="peso"
                        // label="Peso"
                        // error={!!errors.peso}
                        // helperText={!!errors.peso && errors.peso}
                        value={weight}
                        onChange={(e: any) => {
                          setFieldValue("peso", Number(e.target.value));
                          setWeight(e.target.value);
                        }}
                        disabled={isDisabledByFinishingTime()}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1">Observações:</Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="notas"
                    // label="Observações"
                    multiline
                    value={notes}
                    rowsMax={10}
                    onChange={(e) => {
                      setNotes(e.target.value);
                      setFieldValue("notas", e.target.value);
                      setFormikCurrentValue(props.values)
                    }}
                    disabled={isDisabledByFinishingTime()}
                  />
                </Grid>
              </Grid>

              {/* <Grid container justify="flex-end" style={{ marginTop: ".5rem" }}>
                <Button type="submit" color="primary" variant="contained">
                  {isFetchingMedicalRecord ? (
                    <CircularProgress color="inherit" size={24} />
                  ) : (
                    "Salvar"
                  )}
                </Button>
              </Grid> */}
            </FormikForm>
          )}
        </Formik>
      )}
    </FullWidthContainer>
  );
}
