import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AppThunk } from "store";
import api from "utils/API";
import toastOptions from "utils/toastOptions";
import {
  BillsState,
  BillsFetchResponse,
  BillsInBatchResponse,
  CurrentLiquidValueCard,
  ModalBatchInfos,
  IFinancialHistory,
  BillFetchResponse,
} from "./BillsReducer.types";
import { history } from "utils/history";
import { queryStringFromFilterArray } from "utils/network";
import { IFilter } from "models/shared";
import {
  BillsInBatch,
  FinancialStatement,
  IContasAPagarEReceber,
  IContasAPagarEReceberForm,
} from "models/financeiro/ContasAPagarEReceber";
import dayjs from "dayjs";

const initialState: BillsState = {
  billsInBatch: [],
  bills: [],
  allBills: [],
  bill: null,
  cashFlowHistory: [],
  billHistory: [],
  isCreatingFinancialHistory: false,
  isFetchingFinancialHistory: false,
  isFetchingBills: false,
  isFetchingBillsInBatch: false,
  isFetchingBill: false,
  page: 1,
  total: 0,
  itemsPerPage: 6,
  isCreatingBill: false,
  billsFilterArray: [
    { key: "idclinica", value: null },
    { key: "tipo", value: null }, // entrada | saida
    { key: "bandeira", value: null },
    { key: "formapagamento", value: null },
    { key: "status", value: [] }, // autorizado | efetivado
    { key: "statusrecebiveis", value: [] }, // autorizado | efetivado
    { key: "descricao", value: null },
    { key: "documento", value: null },
    { key: "codigo_os", value: null },
    { key: "idconvenio", value: null },
    { key: "numerolote", value: null },
    { key: "protocolo", value: null },
    { key: "guia", value: null },
    { key: "idconta", value: null },
    { key: "numeroparcela", value: null },
    { key: "start_date", value: dayjs(new Date()).format("YYYY-MM-DD") },
    { key: "end_date", value: dayjs(new Date()).format("YYYY-MM-DD") },
    { key: "reprogram_start_date", value: dayjs(new Date()).format("YYYY-MM-DD") },
    { key: "reprogram_end_date", value: dayjs(new Date()).format("YYYY-MM-DD") },
    { key: "idfluxocaixa", value: null },
    { key: "idpaciente", value: null },
    { key: "lancamento", value: null },
    { key: "idprestador", value: null },
    { key: "idprofissional", value: null },
    { key: "idclientefornecedor", value: null },
    { key: "idordemdeservico", value: null },
    { key: "idmaquinapagamento", value: null },
    { key: "operacao", value: null },
    { key: "idplano", value: null },
    { key: "idloteguias", value: null },
    { key: "recurso", value: null },
    { key: "glosa", value: null },
  ],
  billsInBatchFilterArray: [
    { key: "tipo", value: null }, // saida | entrada
    { key: "idclinica", value: null },
    { key: "status", value: [] }, // autorizado | efetivado
    { key: "descricao", value: null },
    { key: "documento", value: null },
    { key: "idclientefornecedor", value: null },
    { key: "idpaciente", value: null },
    { key: "idprofissional", value: null },
    { key: "idprestador", value: null },
    { key: "efetivadopor", value: null },
    { key: "autorizadopor", value: null },
    { key: "idconta", value: null },
    { key: "formapagamento", value: null },
    { key: "operacao", value: null },
    { key: "bandeira", value: null },
    { key: "numeroparcela", value: null },
    { key: "idmaquinapagamento", value: null },
    { key: "start_date", value: dayjs(new Date()).format("YYYY-MM-DD") },
    { key: "end_date", value: dayjs(new Date()).format("YYYY-MM-DD") },
  ],
  isBatchModalOpen: false,
  modalBatchInfos: {
    name: "",
    type: "",
    grossAmount: 0,
    liquidAmount: 0,
    receivedAmount: 0,
    conta: null,
    dueDate: new Date(),
    divergenceReason: "",
    idclinica: ""
  },
  currentLiquidValue: {
    title: "",
    conta: null,
    datavencimento: new Date(),
    datavencimentoreprogramada: new Date(),
    valorbrutogeral: 0,
    valorliquidogeral: 0,
    valorliquidorecebido: 0,
    idmovimentacoes: [],
    motivodivergencia: "",
    idclinica: ""
  },
  billsSelected: [],
  billsSelectedToReverse: [],
  financialStatement: {
    quantiageral: 0,
    quantialiquidageral: 0,
    quantiarecebidageral: 0,
    quantiaglosada: 0,
    quantiarecuperada: 0
  },
};

const billsSlice = createSlice({
  name: "billsToPay",
  initialState,
  reducers: {
    setBillsInBatch: (state, { payload }: PayloadAction<BillsInBatch[]>) => {
      state.billsInBatch = payload;
    },
    setBills: (state, { payload }: PayloadAction<IContasAPagarEReceber[]>) => {
      state.bills = payload;
    },
    setAllBills: (
      state,
      { payload }: PayloadAction<IContasAPagarEReceber[]>
    ) => {
      state.allBills = payload;
    },
    setBill: (
      state,
      { payload }: PayloadAction<IContasAPagarEReceber | null>
    ) => {
      state.bill = payload;
    },
    setIsFetchingFinancialHistory: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingFinancialHistory = payload;
    },
    setIsCreatingFinancialHistory: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreatingFinancialHistory = payload;
    },
    setCashFlowHistory: (state, { payload }: PayloadAction<IFinancialHistory[]>) => {
      state.cashFlowHistory = payload;
    },
    setBillHistory: (state, { payload }: PayloadAction<IFinancialHistory[]>) => {
      state.billHistory = payload;
    },
    setIsFetchingBills: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingBills = payload;
    },
    setIsFetchingBillsInBatch: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingBillsInBatch = payload;
    },
    setIsFetchingBill: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingBill = payload;
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setTotal: (state, { payload }: PayloadAction<number>) => {
      state.total = payload;
    },
    setItemsPerPage: (state, { payload }: PayloadAction<number>) => {
      state.itemsPerPage = payload;
    },
    setIsCreatingBill: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreatingBill = payload;
    },
    updateBillFilterArray: (state, { payload }: PayloadAction<IFilter>) => {
      const index = state.billsFilterArray.findIndex(
        (item) => item.key === payload.key
      );
      state.billsFilterArray[index].value = payload.value;
      state.billsFilterArray[index].label = payload.label;

    },
    updateBillInBatchFilterArray: (
      state,
      { payload }: PayloadAction<IFilter>
    ) => {
      const index = state.billsInBatchFilterArray.findIndex(
        (item) => item.key === payload.key
      );
      state.billsInBatchFilterArray[index].value = payload.value;
    },
    setIsBatchModalOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isBatchModalOpen = payload;
    },
    setModalBatchInfos: (
      state,
      { payload }: PayloadAction<ModalBatchInfos>
    ) => {
      state.modalBatchInfos = payload;
    },
    setCurrentLiquidValue: (
      state,
      { payload }: PayloadAction<CurrentLiquidValueCard>
    ) => {
      state.currentLiquidValue = payload;
    },
    setBillsSelected: (state, { payload }: PayloadAction<string[]>) => {
      state.billsSelected = payload;
    },
    setBillsSelectedToReverse: (state, { payload }: PayloadAction<string[]>) => {
      state.billsSelectedToReverse = payload;
    },
    setFinancialStatement: (
      state,
      { payload }: PayloadAction<FinancialStatement>
    ) => {
      state.financialStatement = payload;
    },
  },
});

export const fetchCardBillsInBatch = (): AppThunk => async (dispatch, getState) => {
  const { setIsFetchingBillsInBatch, setBillsInBatch, setFinancialStatement } =
    billsSlice.actions;
  const { billsInBatchFilterArray } = getState().bills;

  dispatch(setIsFetchingBillsInBatch(true));
  const queryParameters = queryStringFromFilterArray(billsInBatchFilterArray);

  try {
    const { data }: BillsInBatchResponse = await api.get(
      `/api/financialactivity/group/cards${queryParameters}`
    );
    dispatch(setBillsInBatch(data.data.grouped_financial_activities));
    dispatch(setFinancialStatement(data.data.financial_statement));
  } catch (error: any) {
    console.log(error);
    if (error.response) {
      toast.error(error.response?.data?.error?.message, toastOptions);
    }
  } finally {
    dispatch(setIsFetchingBillsInBatch(false));
  }
};

export const fetchBillsInBatch = (): AppThunk => async (dispatch, getState) => {
  const { setIsFetchingBillsInBatch, setBillsInBatch, setFinancialStatement } =
    billsSlice.actions;
  const { billsInBatchFilterArray } = getState().bills;

  dispatch(setIsFetchingBillsInBatch(true));
  const queryParameters = queryStringFromFilterArray(billsInBatchFilterArray);

  try {
    const { data }: BillsInBatchResponse = await api.get(
      `/api/financialactivity/group${queryParameters}`
    );
    dispatch(setBillsInBatch(data.data.grouped_financial_activities));
    dispatch(setFinancialStatement(data.data.financial_statement));
  } catch (error: any) {
    console.log(error);
    if (error.response) {
      toast.error(error.response?.data?.error?.message, toastOptions);
    }
  } finally {
    dispatch(setIsFetchingBillsInBatch(false));
  }
};

export const fetchBills =
  ({ page = 1, limit = 6 }: { page?: number; limit?: number }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingBills, setBills, setPage, setTotal } =
      billsSlice.actions;
    const { billsFilterArray } = getState().bills;

    dispatch(setIsFetchingBills(true));
    const queryParameters = queryStringFromFilterArray(billsFilterArray);
    const pageAndLimit =
      queryParameters.length === 0
        ? `?page=${page}&limit=${limit}`
        : `&page=${page}&limit=${limit}`;

    try {
      const { data }: BillsFetchResponse = await api.get(
        `/api/financialactivity${queryParameters}${pageAndLimit}`
      );
      dispatch(setBills(data.data.financial_activities));
      dispatch(setPage(data.data.page));
      dispatch(setTotal(data.data.total));
    } catch (error: any) {
      console.log(error);

      if (error.response) {
        toast.error(error.response?.data?.error?.message, toastOptions);
      }
    } finally {
      dispatch(setIsFetchingBills(false));
    }
  };

export const fetchCardBills =
  ({ page = 1, limit = 6 }: { page?: number; limit?: number }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingBills, setBills, setPage, setTotal } =
      billsSlice.actions;
    const { billsFilterArray } = getState().bills;

    dispatch(setIsFetchingBills(true));
    const queryParameters = queryStringFromFilterArray(billsFilterArray);
    const pageAndLimit =
      queryParameters.length === 0
        ? `?page=${page}&limit=${limit}`
        : `&page=${page}&limit=${limit}`;

    try {
      const { data }: BillsFetchResponse = await api.get(
        `/api/financialactivity/cards${queryParameters}${pageAndLimit}`
      );
      dispatch(setBills(data.data.financial_activities));
      if (data.data.financial_statement) {
        dispatch(setFinancialStatement(data.data.financial_statement));
      }
      dispatch(setPage(data.data.page));
      dispatch(setTotal(data.data.total));
    } catch (error: any) {
      console.log(error.message ,"message");
      console.log(error.response ,"ett");
      
      if (error.response) {
        toast.error(error.response?.data?.error?.message, toastOptions);
      }
    } finally {
      dispatch(setIsFetchingBills(false));
    }
  };

export const fetchPaymentWriteOffs =
  ({ 
    page = 1, 
    limit = 6 
  }: { 
    page?: number; 
    limit?: number 
  }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingBills, setBills, setPage, setTotal } =
      billsSlice.actions;
    const { billsFilterArray } = getState().bills;

    dispatch(setIsFetchingBills(true));

    const queryParameters = queryStringFromFilterArray(billsFilterArray);
    const pageAndLimit =
      queryParameters.length === 0
        ? `?page=${page}&limit=${limit}`
        : `&page=${page}&limit=${limit}`;

    try {
      const { data }: BillsFetchResponse = await api.get(
        `/api/financialactivity/payment-write-offs${queryParameters}${pageAndLimit}`
      );
      dispatch(setBills(data.data.financial_activities));
      if (data.data.financial_statement) {
        dispatch(setFinancialStatement(data.data.financial_statement));
      }
      dispatch(setPage(data.data.page));
      dispatch(setTotal(data.data.total));
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response?.data?.error?.message, toastOptions);
      }
    } finally {
      dispatch(setIsFetchingBills(false));
    }
  };

export const fetchAllBills = (): AppThunk => async (dispatch, getState) => {
  const { setIsFetchingBills, setAllBills } = billsSlice.actions;
  dispatch(setIsFetchingBills(true));

  try {
    const { data }: BillsFetchResponse = await api.get(
      `/api/financialactivity?page=1&limit=999`
    );
    dispatch(setAllBills(data.data.financial_activities));
  } catch (error: any) {
    console.log(error);

    if (error.response) {
      toast.error(error.response?.data?.error?.message, toastOptions);
    }
  } finally {
    dispatch(setIsFetchingBills(false));
  }
};

export const fetchBillById =
  (billId: string): AppThunk =>
  async (dispatch, getState) => {
    const { setBill, setIsFetchingBill } = billsSlice.actions;
    dispatch(setIsFetchingBill(true));
    try {
      const { data } = await api.get(`/api/financialactivity/${billId}`);
      dispatch(setBill(data.data));
    } catch (error: any) {
      console.log(error);
      if (error.response) {
        toast.error(error.response?.data?.error?.message, toastOptions);
      }
    } finally {
      dispatch(setIsFetchingBill(false));
    }
  };

export const createBill =
  (bill: IContasAPagarEReceberForm): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setIsCreatingBill(true));
    try {
      await api.post("/api/financialactivity", bill);
      toast.success("Conta criada com sucesso");
      // history.push("/financeiro/maquinas");
    } catch (error: any) {
      console.log(error);
      if (error.response) {
        toast.error(error.response?.data?.error?.message, toastOptions);
      }
    } finally {
      dispatch(setIsCreatingBill(false));
    }
  };

export const editBill =
  ({
    billId,
    body,
    submit
  }: {
    billId: string;
    body: IContasAPagarEReceberForm;
    submit?: Function;
  }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsCreatingBill, setIsFetchingBills } = billsSlice.actions;
    dispatch(setIsCreatingBill(true));
    dispatch(setIsFetchingBills(true))
    try {
      await api.put(`/api/financialactivity/${billId}`, body);
      toast.success("Conta editada com sucesso");

      if (submit) {
        submit();
      }
    } catch (error: any) {
      console.log(error);
      if (error.response) {
        toast.error(error.response?.data?.error?.message, toastOptions);
      }
    } finally {
      dispatch(setIsFetchingBills(false))
      dispatch(setIsCreatingBill(false));
    }
  };

export const editLiquidValue =
  ({
    body,
  }: {
    body: {
      idmovimentacoes: string[];
      quantiarecebidatotal: number;
      motivodivergencia: string;
      idconta?: string;
      idmetodopagamento?: string;
      encargos?: number;
      taxaantecipacao?: {
        tipo: string;
        valor: number;
      }
    };
  }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingBills, setModalBatchInfos } = billsSlice.actions;
    const { modalBatchInfos } = getState().bills;
    dispatch(setIsFetchingBills(true));
    try {
      const { data } = await api.patch(`/api/financialactivity/group`, body);
      toast.success("Lote editado com sucesso");

      dispatch(
        setModalBatchInfos({
          ...modalBatchInfos,
          name: `${data.data[0].metodopagamento.nome} - ${data.data[0].conta.nome}`,
          receivedAmount: body.quantiarecebidatotal,
          divergenceReason: body.motivodivergencia,
          anticipateTax: body.taxaantecipacao,
          charges: body.encargos,
          conta: data.data[0].conta,
          metodopagamento: data.data[0].metodopagamento,
          idclinica:  data.data[0].idclinica
        })
      );      
      dispatch(fetchBills({ limit: 999 }));
    } catch (error: any) {
      console.log(error);
      if (error.response) {
        toast.error(error.response?.data?.error?.message, toastOptions);
      }
    } finally {
      dispatch(setIsFetchingBills(false));
    }
  };

export const effectuateBillBatch =
  ({
    body,
    anticipate,
    payment_write_offs
  }: {
    body: {
      idmovimentacoes: string[];
      idefetivadopor: string;
      quantiarecebidatotal?: number;
      datapagamento?: string;
      taxaantecipacao?: {
        tipo: string;
        valor: number;
      };
      encargos?: number;
    }, 
    anticipate?: boolean;
    payment_write_offs?: boolean;
  }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingBillsInBatch, setModalBatchInfos } = billsSlice.actions;
    const { modalBatchInfos, bills } = getState().bills;
    dispatch(setIsFetchingBillsInBatch(true));
    try {
      const { data }: BillFetchResponse = await api.patch("/api/financialactivity/effectuate", body);
      toast.success("Movimentações efetivadas com sucesso");

      if (payment_write_offs) {
         dispatch(fetchPaymentWriteOffs({}));
      } else {
        if (anticipate) {
          dispatch(fetchCardBills({ limit: 999 }));
        } else {
          const billsModifiedIds: string[] = [];
  
          const newReceivedAmount = data.data.reduce((acc, item) => {
            billsModifiedIds.push(item.idmovimentacao);
            acc += item.quantiarecebida;
            return acc;
          }, 0)
  
          const oldReceivedAmount = bills
            .filter(bill => !billsModifiedIds.includes(bill.idmovimentacao))
            .reduce((acc, item) => {
              acc += item.quantiarecebida;
              return acc;
            }, 0)
  
          dispatch(
            setModalBatchInfos({
              ...modalBatchInfos,
              receivedAmount: newReceivedAmount + oldReceivedAmount
            })
          );
  
          dispatch(fetchBillsInBatch());
          dispatch(fetchBills({ limit: 999 }));
        }
      }
      
    } catch (error: any) {
      console.log(error);
      if (error.response) {
        toast.error(error.response?.data?.error?.message, toastOptions);
      }
    } finally {
      dispatch(setIsFetchingBillsInBatch(false));
    }
  };

export const reverseBillBatch =
  ({
    body,
    anticipate,
    payment_write_offs
  }: {
    body: {
      idmovimentacoes: string[];
    };
    anticipate?: boolean;
    payment_write_offs?: boolean
  }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingBillsInBatch, setModalBatchInfos } = billsSlice.actions;
    const { modalBatchInfos, bills } = getState().bills;
    dispatch(setIsFetchingBillsInBatch(true));
    try {
      const { data }: BillFetchResponse = await api.patch("/api/financialactivity/reverse", body);
      toast.success("Movimentações estornadas com sucesso");

      const billsModifiedIds = data.data.map(item => item.idmovimentacao);

      const newReceivedAmount = bills
        .filter(bill => !billsModifiedIds.includes(bill.idmovimentacao))
        .reduce((acc, item) => {
          acc += item.quantiarecebida;
          return acc;
        }, 0)

      if (payment_write_offs) {
        dispatch(fetchPaymentWriteOffs({}))
      } else {
        if (anticipate) {
          dispatch(fetchCardBills({ limit: 999 }))
        } else {
          dispatch(
            setModalBatchInfos({
              ...modalBatchInfos,
              receivedAmount: newReceivedAmount
            })
          );
  
          dispatch(fetchBillsInBatch());
          dispatch(fetchBills({ limit: 999 }));
        }
      }
    } catch (error: any) {
      console.log(error);
      if (error.response) {
        toast.error(error.response?.data?.error?.message, toastOptions);
      }
    } finally {
      dispatch(setIsFetchingBillsInBatch(false));
    }
  };

export const reprogramBillBatch =
  ({
    body,
  }: {
    body: {
      idmovimentacoes: string[];
      datavencimentoreprogramada: Date;
    };
  }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingBillsInBatch } = billsSlice.actions;
    dispatch(setIsFetchingBillsInBatch(true));
    try {
      await api.patch("/api/financialactivity/reprogram", body);
      toast.success("Movimentações reprogramadas com sucesso");
      dispatch(fetchBillsInBatch());
      dispatch(fetchBills({ limit: 999 }));
    } catch (error: any) {
      console.log(error);
      if (error.response) {
        toast.error(error.response?.data?.error?.message, toastOptions);
      }
    } finally {
      dispatch(setIsFetchingBillsInBatch(false));
    }
  };

export const authorizeBills =
  ({
    body,
  }: {
    body: {
      idmovimentacoes: string[];
    };
  }, cards: boolean): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingBills } = billsSlice.actions;
    dispatch(setIsFetchingBills(true));

    try {
      await api.patch("/api/financialactivity/authorize", body);
      toast.success("Movimentações modificadas com sucesso");

      if (cards) {
        dispatch(fetchCardBills({ limit: 999 }));
      } else {
        // dispatch(fetchBillsInBatch());
        dispatch(fetchBills({ limit: 999 }));
      }
    } catch (error: any) {
      console.log(error);
      if (error.response) {
        toast.error(error.response?.data?.error?.message, toastOptions);
      }
    } finally {
      dispatch(setIsFetchingBills(false));
    }
  };

export const deleteBill =
  (billId: string, cards?: boolean): AppThunk =>
  async (dispatch, getState) => {
    const { page, itemsPerPage } = getState().bills;
    const { setIsFetchingBills } = billsSlice.actions;
    dispatch(setIsFetchingBills(true));
    try {
      await api.delete(`/api/financialactivity/${billId}`);

      if (cards) {
        dispatch(fetchCardBills({ limit: 999 }));
      } else {
        dispatch(fetchBillsInBatch());
        dispatch(fetchBills({ page, limit: itemsPerPage }));
      }

      toast.success("Movimentação modificada com sucesso");
    } catch (error: any) {
      console.log(error);
      if (error.response) {
        toast.error(error.response?.data?.error?.message, toastOptions);
      }
    } finally {
      dispatch(setIsFetchingBills(false));
    }
  };

export const fetchFinancialHistory = ({ billId, cashFlowId }:
  {
    billId?: string,
    cashFlowId?: string,
  }): AppThunk => async (
    dispatch
  ) => {
    const {
      setIsFetchingFinancialHistory,
      setBillHistory,
      setCashFlowHistory,
    } = billsSlice.actions;
    dispatch(setIsFetchingFinancialHistory(true));
    try {
      if (billId) {
        const response = await api.get(
          `/api/financialchangelogs?idmovimentacao=${billId}`
        );
        dispatch(setBillHistory(response.data.data.documents))
      }
      if (cashFlowId) {
        const response = await api.get(
          `/api/financialchangelogs?idfluxocaixa=${cashFlowId}`
        );
        dispatch(setCashFlowHistory(response.data.data.documents))
      }
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setIsFetchingFinancialHistory(false));
    }
  };

export const createFinancialHistory = ({ billId, cashFlowId, descricao, callback }:
  {
    billId?: string,
    cashFlowId?: string,
    descricao: string,
    callback?: Function,
  }): AppThunk => async (
    dispatch
  ) => {
    const { setIsCreatingFinancialHistory } = billsSlice.actions;
    
    dispatch(setIsCreatingFinancialHistory(true));
    try {
      if (billId) {
        await api.post(`/api/financialchangelogs`,
          {
            idmovimentacao: billId,
            descricao: descricao,
          });
          
        toast.success("Atividade adicionada ao histórico", toastOptions);
      }
      if (cashFlowId) {
        await api.post(`/api/financialchangelogs`,
          {
            idfluxocaixa: cashFlowId,
            descricao: descricao,
          });

        toast.success("Atividade adicionada ao histórico", toastOptions);
      }
      if (callback) callback()
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setIsCreatingFinancialHistory(false));
    }
  };

export const {
  setIsFetchingBillsInBatch,
  updateBillInBatchFilterArray,
  setBillsInBatch,
  setBills,
  setBill,
  setIsFetchingBills,
  setPage,
  setTotal,
  setIsCreatingBill,
  setIsFetchingBill,
  setItemsPerPage,
  setAllBills,
  updateBillFilterArray,
  setIsBatchModalOpen,
  setModalBatchInfos,
  setCurrentLiquidValue,
  setBillsSelected,
  setBillsSelectedToReverse,
  setFinancialStatement,
} = billsSlice.actions;

export default billsSlice.reducer;
