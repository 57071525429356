import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AppThunk } from "store";
import api from "utils/API";
import toastOptions from "utils/toastOptions";
import {
	IProfessionalServiceHealthPlan,
	ProfessionalServiceHealthPlanState,
} from "./HealthPlanProfessionalReducer.types";

const initialState: ProfessionalServiceHealthPlanState = {
	isCreatingHealthPlanCategoryProfessional: false,
	isFetchingServiceItemHealthPlan: false,
	includedHealthPlansCategoryProfessional: [],
	includedAllHealthPlansCategoryProfessional: [],
	currentIdItemServico: "",
	currentIdProfessional: "",
	currentIdProvider: "",
};

const healthPlanCategorySlice = createSlice({
	name: "healthPlanCategory",
	initialState,
	reducers: {
		setIsCreatingHealthPlanCategoryProfessional: (state, { payload }) => {
			state.isCreatingHealthPlanCategoryProfessional = payload;
		},
		setIsFetchingServiceItemHealthPlan: (state, { payload }) => {
			state.isFetchingServiceItemHealthPlan = payload;
		},
		setIncludesHealthPlansCategoryProfessional: (state, { payload }) => {
			state.includedHealthPlansCategoryProfessional = payload;
		},
		setAllIncludesHealthPlansCategoryProfessional: (state, { payload }) => {
			state.includedAllHealthPlansCategoryProfessional = payload;
		},
		setCurrentIdItemServico: (state, { payload }) => {
			state.currentIdItemServico = payload;
		},
		setCurrentIdProfessional: (state, { payload }) => {
			state.currentIdProfessional = payload;
		},
		setCurrentIdProvider: (state, { payload }) => {
			state.currentIdProvider = payload;
		},
	},
});

export const fetchAllHealthPlanCategoryProfessionalByServiceId =
	({
		service_item_id,
		professional_id,
	}: {
		service_item_id: string;
		professional_id: string;
	}): AppThunk =>
	async (dispatch, getState) => {
		const {
			setAllIncludesHealthPlansCategoryProfessional,
			setIsFetchingServiceItemHealthPlan,
		} = healthPlanCategorySlice.actions;
		dispatch(setIsFetchingServiceItemHealthPlan(true));
		try {
			const { data } = await api.get(
				`/api/servicehealthplancategoriesprofessional/all?service_item_id=${service_item_id}&professional_id=${professional_id}`
			);
			dispatch(setAllIncludesHealthPlansCategoryProfessional(data.data));
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingServiceItemHealthPlan(false));
		}
	};

export const fetchHealthPlanCategoryProfessionalByServiceId =
	({
		service_item_id,
		professional_id,
	}: {
		service_item_id: string;
		professional_id: string;
	}): AppThunk =>
	async (dispatch, getState) => {
		const {
			setIncludesHealthPlansCategoryProfessional,
			setIsFetchingServiceItemHealthPlan,
		} = healthPlanCategorySlice.actions;
		dispatch(setIsFetchingServiceItemHealthPlan(true));
		try {
			const { data } = await api.get(
				`/api/servicehealthplancategoriesprofessional/healthplans?service_item_id=${service_item_id}&professional_id=${professional_id}`
			);
			dispatch(setIncludesHealthPlansCategoryProfessional(data.data));
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingServiceItemHealthPlan(false));
		}
	};

export const fetchHealthPlanCategoryProviderByServiceId =
	({
		service_item_id,
		prestador_id,
	}: {
		service_item_id: string;
		prestador_id: string;
	}): AppThunk =>
	async (dispatch, getState) => {
		const {
			setIncludesHealthPlansCategoryProfessional,
			setIsFetchingServiceItemHealthPlan,
		} = healthPlanCategorySlice.actions;
		dispatch(setIsFetchingServiceItemHealthPlan(true));
		try {
			const { data } = await api.get(
				`/api/servicehealthplancategoriesprofessional/healthplans?service_item_id=${service_item_id}&prestador_id=${prestador_id}`
			);
			dispatch(setIncludesHealthPlansCategoryProfessional(data.data));
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingServiceItemHealthPlan(false));
		}
	};

export const createHealthPlanCategoryServiceProfessional =
	(
		service_health_plan_category_professional: IProfessionalServiceHealthPlan
	): AppThunk =>
	async (dispatch, getState) => {
		const { setIsCreatingHealthPlanCategoryProfessional } =
			healthPlanCategorySlice.actions;
		const { currentIdItemServico, currentIdProfessional } =
			getState().healthPlanProfessional;
		dispatch(setIsCreatingHealthPlanCategoryProfessional(true));
		try {
			await api.post(
				"/api/servicehealthplancategoriesprofessional",
				service_health_plan_category_professional
			);

			dispatch(
				fetchHealthPlanCategoryProfessionalByServiceId({
					service_item_id: currentIdItemServico,
					professional_id: currentIdProfessional,
				})
			);

			toast.success("Serviços vinculados ao plano com sucesso", {
				toastId: "createHealthPlanCategoryService",
			});
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsCreatingHealthPlanCategoryProfessional(false));
		}
	};

export const editHealthPlanCategoryProfessional =
	({
		service_health_plan_category_id,
		body,
	}: {
		service_health_plan_category_id: string;
		body: IProfessionalServiceHealthPlan;
	}): AppThunk =>
	async (dispatch, getState) => {
		const { setIsFetchingServiceItemHealthPlan } =
			healthPlanCategorySlice.actions;
		const { currentIdItemServico, currentIdProfessional } =
			getState().healthPlanProfessional;
		dispatch(setIsFetchingServiceItemHealthPlan(true));
		try {
			await api.put(
				`/api/servicehealthplancategoriesprofessional/${service_health_plan_category_id}`,
				body
			);

			dispatch(
				fetchHealthPlanCategoryProfessionalByServiceId({
					service_item_id: currentIdItemServico,
					professional_id: currentIdProfessional,
				})
			);
			toast.success("Plano editado com sucesso", {
				toastId: "editHealthPlanCategory",
			});
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingServiceItemHealthPlan(false));
		}
	};

export const editManyHealthPlanCategoryProfessional =
	(arrayOfEditedItems: IProfessionalServiceHealthPlan[]): AppThunk =>
	async (dispatch, getState) => {
		const { setIsFetchingServiceItemHealthPlan } =
			healthPlanCategorySlice.actions;
		dispatch(setIsFetchingServiceItemHealthPlan(true));
		try {
			await Promise.all(
				arrayOfEditedItems.map(async (item) => {
					await api.put(
						`/api/servicehealthplancategoriesprofessional/${item.id}`,
						item
					);
				})
			);

			toast.success("Plano editado com sucesso", {
				toastId: "editHealthPlanCategory",
			});
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingServiceItemHealthPlan(false));
		}
	};

export const deleteHealthPlanCategoryServiceProfessional =
	(service_health_plan_category_id: string): AppThunk =>
	async (dispatch, getState) => {
		const { setIsFetchingServiceItemHealthPlan } =
			healthPlanCategorySlice.actions;
		const { currentIdItemServico, currentIdProfessional } =
			getState().healthPlanProfessional;
		dispatch(setIsFetchingServiceItemHealthPlan(true));
		try {
			await api.delete(
				`/api/servicehealthplancategoriesprofessional/${service_health_plan_category_id}`
			);

			dispatch(
				fetchHealthPlanCategoryProfessionalByServiceId({
					service_item_id: currentIdItemServico,
					professional_id: currentIdProfessional,
				})
			);
			toast.success("Plano desvinculado com sucesso", {
				toastId: "deleteHealthPlanCategoryService",
			});
		} catch (error: any) {
			console.log(error);
			
			if (error.response) {
				toast.error(error.response.data.error.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingServiceItemHealthPlan(false));
		}
	};

export const deleteManyHealthPlanCategoriesServicesProfessional =
	(service_health_plan_category_ids: string[]): AppThunk =>
	async (dispatch, getState) => {
		const { setIsFetchingServiceItemHealthPlan } =
			healthPlanCategorySlice.actions;
		dispatch(setIsFetchingServiceItemHealthPlan(true));
		try {
			await Promise.all(
				service_health_plan_category_ids.map(async (id) => {
					await api.delete(
						`/api/servicehealthplancategoriesprofessional/${id}`
					);
				})
			);

			toast.success("Planos desvinculados com sucesso", {
				toastId: "deleteHealthPlanCategoryService",
			});
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingServiceItemHealthPlan(false));
		}
	};

export const {
	setAllIncludesHealthPlansCategoryProfessional,
	setIncludesHealthPlansCategoryProfessional,
	setCurrentIdItemServico,
	setCurrentIdProfessional,
	setCurrentIdProvider,
} = healthPlanCategorySlice.actions;

export default healthPlanCategorySlice.reducer;
