import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IInitialState {
  isMenuDrawerOpen: boolean;
  menuDrawerWidth: number;
}

const initialState: IInitialState = {
  isMenuDrawerOpen: false,
  menuDrawerWidth: 240,
};

const uiSlice = createSlice({
  name: "uiSlice",
  initialState,
  reducers: {
    setIsMenuDrawerOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isMenuDrawerOpen = payload;
    },
  },
});

export const { setIsMenuDrawerOpen } = uiSlice.actions;

export default uiSlice.reducer;
