import React, { useState, createContext } from "react";
import { getThemeByName } from "./../index";
import { MuiThemeProvider } from "@material-ui/core";

const ThemeContext = createContext((themeName: string): void => {});

const ThemeProvider: React.FC = ({ children }) => {
  const currentThemeName = localStorage.getItem("appTheme") || "lightTheme";

  const [themeName, _setThemeName] = useState(currentThemeName);

  const theme = getThemeByName(themeName);

  const handleChangeTheme = (themeName: string): void => {
    localStorage.setItem("appTheme", themeName);
    _setThemeName(themeName);
  };

  return (
    <ThemeContext.Provider value={handleChangeTheme}>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
