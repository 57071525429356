import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";

interface initialStateTypes {
  startDate: string;
  endDate: string;
  idclinica: string | null;
}

const initialState: initialStateTypes = {
  startDate: dayjs(new Date()).format("YYYY-MM-DD"),
  endDate: dayjs(new Date()).format("YYYY-MM-DD"),
  idclinica: null,
}

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialState,
  reducers: {
    setStartDate: (state, { payload }: PayloadAction<string>) => {
      state.startDate = payload;
    },
    setEndDate: (state, { payload }: PayloadAction<string>) => {
      state.endDate = payload;
    },
    setClinicId: (state, { payload }: PayloadAction<string | null>) => {
      state.idclinica = payload;
    }
  }
})

export const { setStartDate, setEndDate, setClinicId } = dashboardSlice.actions;

export default dashboardSlice.reducer;