import styled from "styled-components";

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;


  @media only screen and (max-width: 480px) {
    .btn-submit{
      display: flex;
      justify-content: center;
    }
  }
`;
