import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, AppDispatch } from "store/index";
import { toast } from "react-toastify";
import toastOptions from "utils/toastOptions";
import api from "utils/API";
import { IBankAccount, IBankAccountForm } from "models/BankAccount";
import { history } from "utils/history";
import { IFilter } from "models/shared";
import { queryStringFromFilterArray } from "utils/network";
import axios from "axios";
import { IManufacturers, IManufacturersForm } from "models/Manufacturers";

interface IInitialState {
    manufacturersList: IManufacturers[] | null;
    manufacturers: IManufacturers | null;
    isFetchingManufacturers: Boolean;
    page: number;
    total: number;
}
const initialState: IInitialState = {
    manufacturersList: null,
    manufacturers: null,
    isFetchingManufacturers: false,
    page: 1,
    total: 0
};

const manufacturersSlice = createSlice({
  name: "manufacturersSlice",
  initialState,
  reducers: {
        setManufacturers: (
        state,
        { payload }: PayloadAction<IManufacturers>
        ) => {
        state.manufacturers = payload;
        },
        setManufacturersList: (
        state,
        { payload }: PayloadAction<{
          stock_manufacturers:IManufacturers[]
          page: number;
          total: number;
        }>
        ) => {
        state.manufacturersList = payload.stock_manufacturers;
        state.page = payload.page;
        state.total = payload.total;
        },
        setIsFetchingManufacturers: (state, { payload }:PayloadAction<Boolean>) => {
            state.isFetchingManufacturers = payload
        }
    }
});

export const fetchManufacturersList = ({limit = 6, page = 1}:{limit?: number, page?: number}): AppThunk => async (dispatch, getState) => {
    const { setIsFetchingManufacturers, setManufacturersList } = manufacturersSlice.actions;
    dispatch(setIsFetchingManufacturers(true));
    try {
      const pageAndLimit = `?page=${page}&limit=${limit}`
      
      const response = await api.get(
        `/api/stockmanufacturers${pageAndLimit}`
      );
      dispatch(setManufacturersList(response.data.data));
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
        dispatch(setIsFetchingManufacturers(false))
    }
  };

  export const fetchManufacturers = ({id}:{id:string}): AppThunk => async (dispatch, getState) => {
    const { setIsFetchingManufacturers, setManufacturers } = manufacturersSlice.actions;
    dispatch(setIsFetchingManufacturers(true))
    try {
      const response = await api.get(
        `/api/stockmanufacturers/${id}`
      );
      dispatch(setManufacturers(response.data.data));
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
        dispatch(setIsFetchingManufacturers(false))
    }
  };

  export const submitManufacturers = (data:IManufacturersForm, cb?:Function): AppThunk => async (dispatch, getState) => {
    const { setIsFetchingManufacturers, setManufacturers } = manufacturersSlice.actions;
    dispatch(setIsFetchingManufacturers(true))
    try {
      const response = await api.post(
        `/api/stockmanufacturers`, data
      );
      if(cb){
        cb(response.data.data.idfabricante)
      }
      toast.success("Fabricante cadastrado com sucesso", toastOptions);
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
        dispatch(setIsFetchingManufacturers(false))
    }
  };

  export const editManufacturers = ({id, data, cb}: {id: string, data: IManufacturersForm, cb?: Function}): AppThunk => async (dispatch, getState) => {
    const { setIsFetchingManufacturers, setManufacturers } = manufacturersSlice.actions;
    dispatch(setIsFetchingManufacturers(true))
    try {
      const response = await api.put(
        `/api/stockmanufacturers/${id}`, data
      );
      dispatch(setManufacturers(response.data.stock_manufacturers))
      toast.success("Fabricante atualizado com sucesso", toastOptions);
      if(cb){
        cb()
      }
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
        dispatch(setIsFetchingManufacturers(false))
    }
  };

export const { setManufacturers  } = manufacturersSlice.actions;

export default manufacturersSlice.reducer;
