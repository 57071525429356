import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "store/index";
import { toast } from "react-toastify";
import toastOptions from "utils/toastOptions";
import api from "utils/API";
import { IMedicalTemplate } from "models/MedicalTemplate";

interface IInitialState {
  isFetchingTemplates: boolean;
  isCreatingTemplate: boolean;
  isDeletingTemplate: boolean;
  isUpdatingTemplate: boolean;
  medicalTemplates: IMedicalTemplate[] | null;
  currentMedicalTemplateTitle: string;
  currentMedicalTemplateText: string;
  currentMedicalTemplateId: string;
}

const initialState: IInitialState = {
  isFetchingTemplates: false,
  isCreatingTemplate: false,
  isDeletingTemplate: false,
  isUpdatingTemplate: false,
  medicalTemplates: null,
  currentMedicalTemplateTitle: "",
  currentMedicalTemplateText: "",
  currentMedicalTemplateId: "",
};

const medicalTemplateReducer = createSlice({
  name: "medicalTemplateReducer",
  initialState,
  reducers: {
    setIsFetchingTemplates: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingTemplates = payload;
    },
    setIsCreatingTemplate: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreatingTemplate = payload;
    },
    setIsDeletingTemplate: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeletingTemplate = payload;
    },
    setIsUpdatingTemplate: (state, { payload }: PayloadAction<boolean>) => {
      state.isUpdatingTemplate = payload;
    },
    setMedicalTemplates: (
      state,
      { payload }: PayloadAction<IMedicalTemplate[]>
    ) => {
      state.medicalTemplates = payload;
    },
    setCurrentMedicalTemplateTitle: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      state.currentMedicalTemplateTitle = payload;
    },
    setCurrentMedicalTemplateText: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      state.currentMedicalTemplateText = payload;
    },
    setCurrentMedicalTemplateId: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      state.currentMedicalTemplateId = payload;
    },
    clearToInitialState: (state) => {
      state = {
        isFetchingTemplates: false,
        isCreatingTemplate: false,
        isDeletingTemplate: false,
        isUpdatingTemplate: false,
        medicalTemplates: null,
        currentMedicalTemplateTitle: "",
        currentMedicalTemplateText: "",
        currentMedicalTemplateId: "",
      };
    },
  },
});

export const fetchMedicalTemplates =
  (type = "laudo"): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingTemplates, setMedicalTemplates } =
      medicalTemplateReducer.actions;
    try {
      dispatch(setIsFetchingTemplates(true));
      const response = await api.get(
        `/api/medical-templates/medical-forms?tipo=${type}`
      );
      dispatch(setMedicalTemplates(response.data.data));
      dispatch(setIsFetchingTemplates(false));
    } catch (error: any) {
      dispatch(setIsFetchingTemplates(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const createMedicalTemplate =
  ({
    tipo,
    titulo,
    conteudo,
    cb,
  }: {
    tipo: string;
    titulo: string;
    conteudo: string;
    cb?: () => void;
  }): AppThunk =>
  async (dispatch, getState) => {
    const {
      setIsCreatingTemplate,
      setCurrentMedicalTemplateText,
      setCurrentMedicalTemplateTitle,
    } = medicalTemplateReducer.actions;

    try {
      dispatch(setIsCreatingTemplate(true));
      const response = await api.post("/api/medical-templates/medical-forms", {
        tipo,
        titulo,
        conteudo,
      });

      dispatch(setCurrentMedicalTemplateTitle(response.data.data.titulo));
      dispatch(setCurrentMedicalTemplateText(response.data.data.conteudo));
      dispatch(setCurrentMedicalTemplateId(response.data.data.idtemplate));

      dispatch(setIsCreatingTemplate(false));
      toast.success("Criado com sucesso", toastOptions);
      if (cb) {
        cb();
      }
    } catch (err: any) {
      if (err.response) {
        toast.error(err.response.data?.error?.message, toastOptions);
      } else {
        console.log(err.message);
      }
    }
  };

export const updateMedicalTemplate =
  ({
    idtemplate,
    titulo,
    conteudo,
    cb,
  }: {
    idtemplate: string;
    titulo: string;
    conteudo: string;
    cb?: () => void;
  }): AppThunk =>
  async (dispatch, getState) => {
    const {
      setIsUpdatingTemplate,
      setCurrentMedicalTemplateTitle,
      setCurrentMedicalTemplateText,
      setCurrentMedicalTemplateId,
    } = medicalTemplateReducer.actions;

    try {
      dispatch(setIsUpdatingTemplate(true));
      const response = await api.patch("/api/medical-templates/medical-forms", {
        idtemplate,
        titulo,
        conteudo,
      });

      dispatch(setCurrentMedicalTemplateTitle(response.data.data.titulo));
      dispatch(setCurrentMedicalTemplateText(response.data.data.conteudo));
      dispatch(setCurrentMedicalTemplateId(response.data.data.idtemplate));

      dispatch(setIsUpdatingTemplate(false));
      toast.success("Salvo com sucesso", toastOptions);
      if (cb) {
        cb();
      }
    } catch (err: any) {
      if (err.response) {
        toast.error(err.response.data?.error?.message, toastOptions);
      } else {
        console.log(err.message);
      }
    }
  };

export const deleteMedicalTemplate =
  ({ idtemplate, cb }: { idtemplate: string; cb?: () => void }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsDeletingTemplate, clearToInitialState } =
      medicalTemplateReducer.actions;

    try {
      dispatch(setIsDeletingTemplate(true));
      const response = await api.delete(
        "/api/medical-templates/medical-forms?idtemplate=" + idtemplate
      );

      dispatch(setIsDeletingTemplate(false));
      toast.success("Deletado com sucesso", toastOptions);
      if (cb) {
        cb();
      }
    } catch (err: any) {
      if (err.response) {
        toast.error(err.response.data?.error?.message, toastOptions);
      } else {
        console.log(err.message);
      }
    }
  };

export const {
  setCurrentMedicalTemplateTitle,
  setCurrentMedicalTemplateText,
  setCurrentMedicalTemplateId,
} = medicalTemplateReducer.actions;

export default medicalTemplateReducer.reducer;
