import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { OdontogramModel } from "models/Odontogram/odontogram";
import {
  DentalFace,
  OdontogramObservationModel,
} from "models/Odontogram/odontogramObservation";
import { ToothStatus, ToothStatusModel } from "models/Odontogram/toothStatus";
import { toast } from "react-toastify";
import { AppThunk } from "store";
import api from "utils/API";
import toastOptions from "utils/toastOptions";

interface IInitialState {
  isFetchingOdontogram: boolean;
  isFetchingOdontogramObservations: boolean;
  isCreatingOdontogramObservation: boolean;
  isEditingOdontogramObservation: boolean;
  isDeletingOdontogramObservation: boolean;
  isFetchingSingleToothStatus: boolean;
  isCreatingToothStatus: boolean;
  currentOdontogram: OdontogramModel | null;
  currentToothObservations: OdontogramObservationModel[] | null;
  currentToothStatus: ToothStatusModel | null;
}

const initialState: IInitialState = {
  isFetchingOdontogram: false,
  isFetchingOdontogramObservations: false,
  isCreatingOdontogramObservation: false,
  isEditingOdontogramObservation: false,
  isDeletingOdontogramObservation: false,
  isFetchingSingleToothStatus: false,
  isCreatingToothStatus: false,
  currentOdontogram: null,
  currentToothObservations: null,
  currentToothStatus: null,
};

const odontogramSlice = createSlice({
  name: "odontogramSlice",
  initialState,
  reducers: {
    setIsFetchingOdontogram: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingOdontogram = payload;
    },
    setCurrentOdontogram: (
      state,
      { payload }: PayloadAction<OdontogramModel | null>
    ) => {
      state.currentOdontogram = payload;
    },
    setIsFetchingOdontogramObservations: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingOdontogramObservations = payload;
    },
    setCurrentToothObservations: (
      state,
      { payload }: PayloadAction<OdontogramObservationModel[] | null>
    ) => {
      state.currentToothObservations = payload;
    },
    setIsCreatingToothObservation: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isCreatingOdontogramObservation = payload;
    },
    setIsEditingToothObservation: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isEditingOdontogramObservation = payload;
    },
    setIsDeletingToothObservation: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isDeletingOdontogramObservation = payload;
    },
    setIsCreatingToothStatus: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreatingToothStatus = payload;
    },
    setIsFetchingSingleToothStatus: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingSingleToothStatus = payload;
    },
    setCurrentToothStatus: (
      state,
      { payload }: PayloadAction<ToothStatusModel | null>
    ) => {
      state.currentToothStatus = payload;
    },
  },
});

export const fetchOdontogram =
  (idMedicalRecord: string): AppThunk =>
  async (dispatch) => {
    const { setIsFetchingOdontogram, setCurrentOdontogram } =
      odontogramSlice.actions;
    dispatch(setIsFetchingOdontogram(true));
    try {
      const response = await api.get(
        `/api/odontogram?idmedicalrecord=${idMedicalRecord}`
      );
      dispatch(setCurrentOdontogram(response.data.data));
      dispatch(setIsFetchingOdontogram(false));
    } catch (error: any) {
      dispatch(setIsFetchingOdontogram(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error);
      }
    }
  };

export const fetchOdontogramObservationsByToothId =
  ({
    iddente,
    idodontograma,
  }: {
    iddente: string;
    idodontograma: string;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsFetchingOdontogramObservations, setCurrentToothObservations } =
      odontogramSlice.actions;
    dispatch(setIsFetchingOdontogramObservations(true));
    try {
      const response = await api.get(
        `/api/odontogram-observations?idodontograma=${idodontograma}&iddente=${iddente}`
      );
      dispatch(setCurrentToothObservations(response.data.data));
      dispatch(setIsFetchingOdontogramObservations(false));
    } catch (error: any) {
      dispatch(setIsFetchingOdontogramObservations(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error);
      }
    }
  };

export const createOdontogramObservation =
  ({
    iddente,
    idodontograma,
    conteudo,
    faces,
    cb,
  }: {
    iddente: string;
    idodontograma: string;
    conteudo: string;
    faces?: DentalFace[];
    cb?: () => void;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingToothObservation } = odontogramSlice.actions;
    dispatch(setIsCreatingToothObservation(true));
    try {
      const response = await api.post(`/api/odontogram-observations`, {
        idodontograma,
        iddente,
        conteudo,
        faces,
      });

      dispatch(setIsCreatingToothObservation(false));

      if (cb) {
        cb();
      }
    } catch (error: any) {
      dispatch(setIsCreatingToothObservation(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error);
      }
    }
  };

export const editOdontogramObservation =
  ({
    idobservacao,
    conteudo,
    faces,
    cb,
  }: {
    idobservacao: string;
    conteudo: string;
    faces?: DentalFace[];
    cb?: () => void;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsEditingToothObservation } = odontogramSlice.actions;
    dispatch(setIsEditingToothObservation(true));
    try {
      const response = await api.put(`/api/odontogram-observations`, {
        idobservacao,
        conteudo,
        faces,
      });

      dispatch(setIsEditingToothObservation(false));

      toast.success("Atualizado com sucesso", toastOptions);

      if (cb) {
        cb();
      }
    } catch (error: any) {
      dispatch(setIsEditingToothObservation(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error);
      }
    }
  };

export const deleteOdontogramObservation =
  ({ idobservacao, cb }: { idobservacao: string; cb?: () => void }): AppThunk =>
  async (dispatch) => {
    const { setIsDeletingToothObservation } = odontogramSlice.actions;
    dispatch(setIsDeletingToothObservation(true));
    try {
      const response = await api.delete(
        `/api/odontogram-observations/${idobservacao}`
      );

      toast.success("Deletado com sucesso", toastOptions);

      dispatch(setIsDeletingToothObservation(false));

      if (cb) {
        cb();
      }
    } catch (error: any) {
      dispatch(setIsDeletingToothObservation(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error);
      }
    }
  };

export const createToothStatus =
  ({
    iddente,
    idodontograma,
    status,
    cb,
  }: {
    iddente: string;
    idodontograma: string;
    status: ToothStatus;
    cb?: () => void;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingToothStatus } = odontogramSlice.actions;
    dispatch(setIsCreatingToothStatus(true));
    try {
      const response = await api.post(`/api/tooth-status`, {
        idodontograma,
        iddente,
        status,
      });

      dispatch(setIsCreatingToothStatus(false));

      if (cb) {
        cb();
      }
    } catch (error: any) {
      dispatch(setIsCreatingToothStatus(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error);
      }
    }
  };

export const fetchSingleToothStatus =
  ({
    iddente,
    idodontograma,
  }: {
    iddente: string;
    idodontograma: string;
    cb?: () => void;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsFetchingSingleToothStatus, setCurrentToothStatus } =
      odontogramSlice.actions;
    dispatch(setIsFetchingSingleToothStatus(true));
    try {
      const response = await api.get(
        `/api/tooth-status?idodontograma=${idodontograma}&iddente=${iddente}`
      );

      dispatch(setCurrentToothStatus(response.data.data));

      dispatch(setIsFetchingSingleToothStatus(false));
    } catch (error: any) {
      dispatch(setIsFetchingSingleToothStatus(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error);
      }
    }
  };

export const { setCurrentToothObservations } = odontogramSlice.actions;

export default odontogramSlice.reducer;
