import { IServiceOrderServiceItemMetadata } from "models/ServiceOrder/ServiceOrderServiceItem";
import { IPlano } from "pages/Covenant/SpSadt/List/model";
import { IPatientMedicalReportAnamneseOftalmo } from "pages/MedicalRecord/components/PatientMedicalRecord/pages/AnamneseOftalmo/data";
import { ReactChild, ReactFragment, ReactPortal } from "react";
import { UsageType } from "store/reducers/MedicalKitsReducer/MedicalKits.types";

export interface IMedicalRecord {
  idMedicalRecord: string;
  idconsulta: string;
  idclinica: string;
  idmedico: string;
  idpaciente: string;
  sigiloso: boolean;
  paciente: IPaciente | null;
  evolucao: IEvolucao | null;
  consulta: IConsulta | null;
  datacriacao: Date | string;
  status?: MedicalRecordStatusEnum;
  preconsulta?: IPatientMedicalReportAnamneseOftalmo;
  datafinalizado: Date | string;
  datainiciado?: Date | string;
  office?: any;
  clinic?: any;
  medico?: any;
  order_items?: IOrderItem[];
  checkin?: {
    datacriacao: Date | string;
  };
}

export interface IOrderItem {
  guiaspsadt: any;
  guiaconsulta: any;
  movimentacoes: any;
  iditemplano: string;
  iditemservico: string;
  idplano: string;
  plano: IPlano ;
  itemplano: {
    id: string;
    iditemservico: string;
    idconveniocategoria: string;
    codigo: string;
    conveniocategoria: {
      nome: string;
      idconvenio: string;
      id: string;
      convenio: {
        nome: string;
      };
    };
  };
}

export enum MedicalRecordStatusEnum {
  AGUARDANDO = "aguardando",
  ABERTO = "aberto",
  FECHADO = "fechado",
  CANCELADO = "cancelado",
}

export interface ICurrentMedicalRecord {
  medicalRecords: IMedicalRecord[];
}

export interface IOrderItem {
  itemservicos: IServiceOrderServiceItemMetadata;
}

export interface IConsulta {
  data: string;
  orderitems: IOrderItem[];
  preferencial: boolean;
  prioritario: boolean;
}

export interface IPaciente {
  nomepaciente: string;
  nomesocial?: string;
  cpf: string;
  datanascimentotexto: string;
  datanascimento: string;
  rg: string;
  sexo: string;
  descricaopacientefonte: string;
  nomecidade: string;
  siglaestado: string;
  logradouro: string;
  numero: string;
  complemento: string;
  bairro: string;
  cep: string;
  telefonecelular: string;
  telefonecomercial: string;
  telefoneresidencial: string;
  nomeresponsavel: string;
  cpfresponsavel: string;
  prioritario: string;
}

export interface IEvolucao {
  datacriacao: string;
  idevolucao: string;
  idMedicalRecord: string;
  evolucao?: string;
  queixaPrincipal: string;
  historicoDoenca: string;
  medicamentoUso: string;
  depressaoAnsiedade: boolean;
  coronariopatia: boolean;
  valvopatia: boolean;
  diabetes: boolean;
  has: boolean;
  alergias: boolean;
  cirurgiasPrevias: boolean;
  convulsoes: boolean;
  doencasCongenitas: boolean;
  hipoHipertireoidismo: boolean;
  internacoesPrevias: boolean;
  neoplasia: boolean;
  neuropatias: boolean;
  nefropatias: boolean;
  osteopatias: boolean;
  pneumonias: boolean;
  transfusoesPrevias: boolean;
  checklistObservacoes: string;
  avc: boolean;
  dac: boolean;
  dm: boolean;
  doencasGeneticas: boolean;
  hasGenetica: boolean;
  neoplasiaGenetica: boolean;
  obesidade: boolean;
  exameFisico: string;
  conduta: string;
  etilismo: boolean;
  etilismoDescricao: string;
  tabagismo: boolean;
  tabagismoDescricao: string;
  substanciasIlicitas: boolean;
  substanciasIlicitasDescricao: string;
  atividadesFisicas: boolean;
  atividadesFisicasDescricao: string;
  dst: boolean;
  dstDescricao: string;
  dataatualizacao: string;
  historicoFamiliar: string;
}

export interface IManualPrescriptionForm {
  prescricaomanual: string;
  idMedicalRecord: string;
  usorestrito: boolean;
}

export interface IPrescriptionForm {
  idMedicalRecord: string;
  medicamentos: string;
  uso: UsageType;
  quantidade: number;
  unidadeMedida: "Unidade" | "Comprimido" | "Caixa";
  posologia: string;
  prescricaomanual: string;
  usorestrito: boolean;
}

export interface IMemedPrescriptionForm {
  idMedicalRecord: string;
  medicamentos: string;
  quantidade: number;
  posologia: string,
  prescricaomanual: string;
  usorestrito: boolean;
  idmemed: string;
  descricao: string;
  unidadeMemed: string;
  tarja: string;
  idMedicamentoMemed: string;
}

export interface IPrescription {
  // prescriptions: [
  //   {
  idprescricao: string;
  idprofissional: string;
  idMedicalRecord: string;
  medicamentos: string;
  uso:
    | "Oral"
    | "Sublingual"
    | "Retal"
    | "Injetável Intramuscular"
    | "Injetável Subcutâneo"
    | "Inalatória"
    | "Ocular"
    | "Nasal"
    | "Auricular"
    | "Tópico"
    | "Vaginal";
  quantidade: number;
  unidadeMedida: "Unidade" | "Comprimido" | "Caixa";
  posologia: string;
  datacriacao: string | Date;
  prescricaomanual: string;
  usorestrito: boolean;
  nomemedico: string;
  tipodocumentomedico: string;
  numerodocumentomedico: string;
  idmemed: string;
  //   }
  // ]
}

// export interface ICurrentPrescription {
//   idprescricao: string;
//   idprofissional: string;
//   idMedicalRecord: string;
//   medicamentos: string;
//   uso: "Interno" | "Externo" | "Tópico";
//   quantidade: number;
//   unidadeMedida: "Unidade" | "Comprimido" | "Caixa",
//   posologia: string,
//   datacriacao: string | Date,
//   prescricaomanual: string;
//   usorestrito: boolean;
//   nomemedico: string;

// }

export interface IManualExamForm {
  pedidoexamemanual: string;
  idMedicalRecord: string;
}

export interface IExamForm {
  idMedicalRecord: string;
  idexame: string;
  preparo: string;
  detalhamento: string;
  idservicoconveniocategoria?: string | null;
  valorflutuante?: number | null;
}

export interface IMedicinePrescription {
  idmedicamento: string;
  produto: string;
  substancia: string;
  apresentacao: string;
  laboratorio: string;
  tipo: string;
  tarja: string;
  destaque: boolean;
  datacriacao: Date;
  dataatualizacao: Date;
}
