import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AppThunk } from "store";
import api from "utils/API";
import toastOptions from "utils/toastOptions";
import { MedicineResponse, MedicinesState } from "./Medicines.types";

const initialState: MedicinesState = {
	medicines: [],
	medicineFilterId: "",
	isFetchingMedicines: false,
	listPage: 0,
	listLimit: 6,
	listTotalSize: 0,
};

const medicinesSlice = createSlice({
	name: "medicines",
	initialState,
	reducers: {
		setMedicines: (state, action) => {
			state.medicines = action.payload;
		},
		setMedicineFilterId: (state, action) => {
			state.medicineFilterId = action.payload;
		},
		setIsFetchingMedicines: (state, action) => {
			state.isFetchingMedicines = action.payload;
		},
		setListPage: (state, { payload }) => {
			state.listPage = payload;
		},
		setListLimit: (state, { payload }) => {
			state.listLimit = payload;
		},
		setListTotalSize: (state, { payload }) => {
			state.listTotalSize = payload;
		},
	},
});

export const fetchMedicines = ({ medicine_name }: { medicine_name?: string}): AppThunk => async (dispatch, getState) => {
	const { setMedicines, setIsFetchingMedicines } = medicinesSlice.actions;
	dispatch(setIsFetchingMedicines(true));

	try {
		if (medicine_name) {
			const { data }: MedicineResponse = await api.get(`/api/medicines?query=${medicine_name}`);
			dispatch(setMedicines(data.data.medicines));
		} else {
			const { data }: MedicineResponse = await api.get(`/api/medicines`);
			dispatch(setMedicines(data.data.medicines));
		}
	} catch (error: any) {
		console.log(error);

		if (error.response) {
			toast.error(error.response.data.message, toastOptions);
		}
	} finally {
		dispatch(setIsFetchingMedicines(false));
	}
};

export const fetchMedicineById =
	(healthPlanId: string): AppThunk =>
	async (dispatch, getState) => {
		const { setMedicines, setIsFetchingMedicines } = medicinesSlice.actions;
		dispatch(setIsFetchingMedicines(true));
		try {
			const { data } = await api.get(
				`/api/medicines?idmedicamento=${healthPlanId}`
			);
			dispatch(setMedicines(data.data));
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingMedicines(false));
		}
	};

//   export const fetchHealthPlansByServiceItemId =
//   (service_item_id: string): AppThunk =>
//   async (dispatch, getState) => {
//     const { setHealthPlanByServiceItem, setIsFetchingHealthPlan } = healthPlanSlice.actions;
//     dispatch(setIsFetchingHealthPlan(true));
//     try {
//       const { data }: HealthPlanFetchResponse = await api.get(`/api/service-items/healthplans?service_item_id=${service_item_id}`);
//       dispatch(setHealthPlanByServiceItem(data.data));
//     } catch (error: any) {
//       console.log(error);
//       if (error.response) {
//         toast.error(error.response.data.message, toastOptions);
//       }
//     } finally {
//       dispatch(setIsFetchingHealthPlan(false));
//     }
//   };

// export const fetchAllHealthPlans = (): AppThunk => async (dispatch, getState) => {
//   const { setAllHealthPlans, setIsFetchingHealthPlans } = healthPlanSlice.actions;
//   dispatch(setIsFetchingHealthPlans(true));

//   try {
//     const { data }: HealthPlanFetchResponse = await api.get(`/api/healthplans?page=1&limit=9999`);
//     dispatch(setAllHealthPlans(data.data.health_plans));
//   } catch (error: any) {
//     console.log(error);

//     if (error.response) {
//       toast.error(error.response.data.message, toastOptions);
//     }
//   } finally {
//     dispatch(setIsFetchingHealthPlans(false));
//   }
// };

// export const createHealthPlan =
//   (healthPlan: ICreateHealthPlan): AppThunk =>
//   async (dispatch, getState) => {
//     dispatch(setIsCreatingHealthPlan(true));
//     try {
//       await api.post("/api/healthplans", healthPlan);
//       toast.success("Convênio criado com sucesso");
//     } catch (error: any) {
//       console.log(error);
//       if (error.response) {
//         toast.error(error.response.data.message, toastOptions);
//       }
//     } finally {
//       setIsCreatingHealthPlan(false);
//     }
//   };

// export const editHealthPlan =
//   ({ idconvenio, body }: { idconvenio: string; body: ICreateHealthPlan }): AppThunk =>
//   async (dispatch, getState) => {
//     const { setIsFetchingHealthPlan } = healthPlanSlice.actions;
//     dispatch(setIsFetchingHealthPlan(true));
//     try {
//       await api.put(`/api/healthplans/${idconvenio}`, body);
//       toast.success("Convênio editado com sucesso");
//     } catch (error: any) {
//       console.log(error);
//       if (error.response) {
//         toast.error(error.response.data.message, toastOptions);
//       }
//     } finally {
//       dispatch(setIsFetchingHealthPlan(false));
//     }
//   };

// export const deleteHealthPlan =
//   (healthPlanId: string): AppThunk =>
//   async (dispatch, getState) => {
//     const { setIsFetchingHealthPlans } = healthPlanSlice.actions;
//     dispatch(setIsFetchingHealthPlans(true));
//     try {
//       await api.delete(`/api/healthplans/${healthPlanId}`);
//       dispatch(fetchAllHealthPlans());
//       toast.success("Convênio excluído com sucesso");
//     } catch (error: any) {
//       console.log(error);
//       if (error.response) {
//         toast.error(error.response.data.message, toastOptions);
//       }
//     } finally {
//       dispatch(setIsFetchingHealthPlans(false));
//     }
//   };

export const {
	setMedicines,
	setIsFetchingMedicines,
	setListPage,
	setListTotalSize,
	setListLimit,
	setMedicineFilterId,
} = medicinesSlice.actions;

export default medicinesSlice.reducer;
