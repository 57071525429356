import {
  AnyAction,
  createSlice,
  PayloadAction,
  ThunkAction,
} from "@reduxjs/toolkit";
import { AppThunk, rootState } from "store/index";
import { toast } from "react-toastify";
import toastOptions from "utils/toastOptions";
import api from "utils/API";
import { ICounter, ICounterForm } from "models/Counter";
import { IFilter } from "models/shared";
import { queryStringFromFilterArray } from "utils/network";
import { history } from "utils/history";

interface IInitialState {
  isFetchingCounter: boolean;
  isCreatingCounter: boolean;
  isDeletingCounter: boolean;
  isEditBondDialogOpen: boolean;
  amountOfItemsToShow: number;
  counters: ICounter[] | null;
  countersByWindowSet: ICounter[] | null;
  currentCounter: ICounter | null;
  toAddPage: number;
  toAddTotal: number;
  total: number;
  page: number;
  filterArray: IFilter[];
  pageToCounter: number;
}

const initialState: IInitialState = {
  isFetchingCounter: false,
  isCreatingCounter: false,
  isDeletingCounter: false,
  isEditBondDialogOpen: false,
  toAddPage: 0,
  toAddTotal: 0,
  counters: null,
  countersByWindowSet: null,
  currentCounter: null,
  amountOfItemsToShow: 6,
  total: 0,
  page: 0,
  filterArray: [{ key: "nome", value: [] }],
  pageToCounter: 1,
};

const counterSlice = createSlice({
  name: "counteSlice",
  initialState,
  reducers: {
    setIsFetchingCounter: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingCounter = payload;
    },
    setIsCreatingCounter: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreatingCounter = payload;
    },
    setisDeletingCounter: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeletingCounter = payload;
    },
    setIsEditBondDialogOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isEditBondDialogOpen = payload;
    },
    setAmountOfItemsToShow: (state, { payload }: PayloadAction<number>) => {
      state.amountOfItemsToShow = payload;
    },
    setCounters: (
      state,
      {
        payload: { counters, total, page },
      }: PayloadAction<{
        counters: ICounter[];
        total: number;
        page: number;
      }>
    ) => {
      state.counters = counters;
      state.total = total;
      state.page = page;
    },
    setCountersByWindowSet: (
      state,
      {
        payload: { counters, total, page },
      }: PayloadAction<{
        counters: ICounter[];
        total: number;
        page: number;
      }>
    ) => {
      state.countersByWindowSet = counters;
      state.total = total;
      state.page = page;
    },
    setToAddPagination: (
      state,
      {
        payload: { total, page },
      }: PayloadAction<{
        page: number;
        total: number;
      }>
    ) => {
      state.toAddPage = page;
      state.toAddTotal = total;
    },
    setCurrentCounter: (state, { payload }: PayloadAction<ICounter>) => {
      state.currentCounter = payload;
    },
    setPageToCounter: (state, { payload }: PayloadAction<number>) => {
      state.pageToCounter = payload;
    },
  },
});

export const fetchCounters =
  ({
    page = 1,
    limit = 6,
    idconjuntoguiche,
    idclinica,
    idbloco,
    idsetor,
  }: {
    page?: number;
    limit?: number;
    idconjuntoguiche?: string;
    idclinica?: string;
    idsetor?: string;
    idbloco?: string;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { setIsFetchingCounter, setCounters } = counterSlice.actions;
    dispatch(setIsFetchingCounter(true));
    try {
      const state = getState();
      const { filterArray } = state.counter;
      const queryParameters = queryStringFromFilterArray(filterArray);
      const pageAndLimit =
        queryParameters.length === 0
          ? `?page=${page}&limit=${limit}`
          : `&page=${page}&limit=${limit}`;
      const response = await api.get(
        `/api/counter${pageAndLimit}&idclinica=${idclinica}&idconjuntoguiche=${idconjuntoguiche}&idbloco=${idbloco}&idsetor=${idsetor}`
      );
      dispatch(setCounters(response.data.data));
      dispatch(setIsFetchingCounter(false));
    } catch (error: any) {
      dispatch(setIsFetchingCounter(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };
export const fetchCountersByWindowSetId =
  ({
    page = 1,
    limit = 6,
    idconjuntoguiche,
  }: {
    page?: number;
    idconjuntoguiche: string;
    limit?: number;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { setIsFetchingCounter, setCountersByWindowSet } = counterSlice.actions;
    dispatch(setIsFetchingCounter(true));
    try {
      const state = getState();
      const { filterArray } = state.counter;
      const queryParameters = queryStringFromFilterArray(filterArray);
      const pageAndLimit =
        queryParameters.length === 0
          ? `?page=${page}&limit=${limit}`
          : `&page=${page}&limit=${limit}`;
      const response = await api.get(
        `/api/counter${pageAndLimit}&idconjuntoguiche=${idconjuntoguiche}`
      );
      dispatch(setCountersByWindowSet(response.data.data));
      dispatch(setIsFetchingCounter(false));
    } catch (error: any) {
      dispatch(setIsFetchingCounter(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

  export const fetchCountersByWindowSetIdOpens =
  ({
    page = 1,
    limit = 6,
    idconjuntoguiche,
  }: {
    page?: number;
    idconjuntoguiche: string;
    limit?: number;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { setIsFetchingCounter, setCountersByWindowSet } = counterSlice.actions;
    dispatch(setIsFetchingCounter(true));
    try {
      const state = getState();
      const { filterArray } = state.counter;
      const queryParameters = queryStringFromFilterArray(filterArray);
      const pageAndLimit =
        queryParameters.length === 0
          ? `?page=${page}&limit=${limit}`
          : `&page=${page}&limit=${limit}`;
      const response = await api.get(
        `/api/counter/open${pageAndLimit}&idconjuntoguiche=${idconjuntoguiche}`
      );
      dispatch(setCountersByWindowSet(response.data.data));
      dispatch(setIsFetchingCounter(false));
    } catch (error: any) {
      dispatch(setIsFetchingCounter(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchCounterById =
  (counterId: string): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsFetchingCounter, setCurrentCounter } = counterSlice.actions;
    dispatch(setIsFetchingCounter(true));
    try {
      const response = await api.get(`/api/counter/${counterId}`);
      dispatch(setCurrentCounter(response.data.data));
      dispatch(setIsFetchingCounter(false));
    } catch (error: any) {
      dispatch(setIsFetchingCounter(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const updateCounter =
  ({
    counter,
    counterId,
    idclinica,
    idconjuntoguiche,
    idbloco,
    idsetor,
    cb,
  }: {
    counter: ICounterForm;
    counterId: string | number;
    idclinica?: string;
    idconjuntoguiche?: string;
    idsetor?: string;
    idbloco?: string;
    cb?: () => void;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingCounter } = counterSlice.actions;
    dispatch(setIsCreatingCounter(true));
    try {
      await api.put(`/api/counter/${counterId}`, {
        ...counter,
        idclinica,
        idconjuntoguiche,
        idbloco,
        idsetor,
      });
      dispatch(setIsCreatingCounter(false));
      toast.success("Guichê atualizado", toastOptions);
      history.replace(
        `/clinics/${idclinica}/departments/${idsetor}/blocks/${idbloco}/attendance/window-sets/${idconjuntoguiche}/counter`
      );
      if (cb) cb();
    } catch (error: any) {
      dispatch(setIsCreatingCounter(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const createCounter =
  (
    counter: ICounterForm,
    idclinica?: string,
    idconjuntoguiche?: string,
    idsetor?: string,
    idbloco?: string
  ): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsCreatingCounter } = counterSlice.actions;
    dispatch(setIsCreatingCounter(true));
    try {
      await api.post(`/api/counter`, {
        ...counter,
        idclinica,
        idconjuntoguiche,
        idsetor,
        idbloco,
      });
      dispatch(setIsCreatingCounter(false));
      history.replace(
        `/clinics/${idclinica}/departments/${idsetor}/blocks/${idbloco}/attendance/window-sets/${idconjuntoguiche}/counter`
      );
      toast.success("Guichê cadastrado", toastOptions);
    } catch (error: any) {
      dispatch(setIsCreatingCounter(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const deleteCounter =
  (
    counterId: string,
    idclinica?: string,
    idconjuntoguiche?: string,
    idsetor?: string,
    idbloco?: string
  ): AppThunk =>
  async (dispatch) => {
    const { setisDeletingCounter } = counterSlice.actions;
    dispatch(setisDeletingCounter(true));
    try {
      await api.delete(`/api/counter/${counterId}`);
      dispatch(setisDeletingCounter(false));
      toast.success("Guichê excluido com sucesso", toastOptions);
      dispatch(
        fetchCounters({
          idconjuntoguiche,
          idclinica,
          idbloco,
          idsetor,
        })
      );
    } catch (error: any) {
      dispatch(setisDeletingCounter(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const {
  setIsEditBondDialogOpen,
  setPageToCounter,
  setAmountOfItemsToShow,
} = counterSlice.actions;

export default counterSlice.reducer;
