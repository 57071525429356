import styled from "styled-components";

interface IFullWidthContainerProps {
  noPadding?: boolean;
  isHealthPlan?: boolean;
}

export const FullWidthContainer = styled.div<IFullWidthContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: ${({ noPadding }) => (noPadding ? 0 : "24px")};
`;

export const FormBaseContainer = styled.div<IFullWidthContainerProps>`
  min-width: "800px";
  max-width: ${({ isHealthPlan }) => (isHealthPlan ? "1000px" : "800px")};
  margin: 0 auto;
`;

export const FormBaseContainerGuide = styled.div`
  min-width: 100%;
  max-width: 100%;
  margin: 0 auto;
`;
