import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "store/index";
import { toast } from "react-toastify";
import toastOptions from "utils/toastOptions";
import api from "utils/API";
import { IFilter } from "models/shared";
import { queryStringFromFilterArray } from "utils/network";
import { IRole, IRoleUser } from "models/Role";

interface IInitialState {
  currentRole: IRole | null;
  currentRoleNewName: string;
  isFetchingRoles: boolean;
  isDeletingRole: boolean;
  isUpdatingRoleName: boolean;
  isCreatingOrUpdatingRole: boolean;
  roles: IRole[] | null;
  localRoles: IRole[] | null;
  isFetchingRoleUsers: boolean;
  isEditingRoleUsers: boolean;
  currentRoleUsers: IRoleUser[] | null;
  currentRoleUsersFilterArray: IFilter[];
  currentRoleUsersPage: number;
  currentRoleUsersTotal: number;
  usersNotInCurrentRole: IRoleUser[];
  usersNotInCurrentRoleFilterArray: IFilter[];
  usersNotInCurrentRolePage: number;
  usersNotInCurrentRoleTotal: number;
  isFetchingUsersNotInCurrentRole: boolean;
}

const initialState: IInitialState = {
  currentRole: null,
  currentRoleNewName: "",
  isFetchingRoles: false,
  isDeletingRole: false,
  isUpdatingRoleName: false,
  isCreatingOrUpdatingRole: false,
  roles: null,
  localRoles: null,
  currentRoleUsers: null,
  isFetchingRoleUsers: false,
  isEditingRoleUsers: false,
  currentRoleUsersFilterArray: [
    { key: "page", value: 1 },
    { key: "limit", value: 6 },
  ],
  currentRoleUsersPage: 0,
  currentRoleUsersTotal: 0,
  usersNotInCurrentRole: [],
  usersNotInCurrentRoleTotal: 0,
  usersNotInCurrentRolePage: 0,
  usersNotInCurrentRoleFilterArray: [
    { key: "page", value: 1 },
    { key: "limit", value: 6 },
  ],
  isFetchingUsersNotInCurrentRole: false,
};

const rolesSlice = createSlice({
  name: "rolesSlice",
  initialState,
  reducers: {
    setIsCreatingOrUpdatingRole: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isCreatingOrUpdatingRole = payload;
    },
    setIsFetchingRoles: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingRoles = payload;
    },
    setIsDeletingRoles: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeletingRole = payload;
    },
    setCurrentRole: (state, { payload }: PayloadAction<IRole>) => {
      state.currentRole = payload;
    },
    setCurrentRoleNewName: (state, { payload }: PayloadAction<string>) => {
      state.currentRoleNewName = payload;
    },
    setRoles: (
      state,
      {
        payload: { roles },
      }: PayloadAction<{ roles: IRole[]; total: number; page: number }>
    ) => {
      state.roles = roles;
      state.localRoles = roles;
      if (roles.length > 0) {
        state.currentRole = roles[0];
      }
    },
    updateCurrentRoleUsersFilterArray: (
      state,
      { payload: { key, value } }: PayloadAction<IFilter>
    ) => {
      state.currentRoleUsersFilterArray = state.currentRoleUsersFilterArray.map(
        (filter) => {
          if (filter.key === key) {
            filter.value = value;
          }
          return filter;
        }
      );
    },
    updateUsersNotInCurrentRoleFilterArray: (
      state,
      { payload: { key, value } }: PayloadAction<IFilter>
    ) => {
      state.usersNotInCurrentRoleFilterArray = state.usersNotInCurrentRoleFilterArray.map(
        (filter) => {
          if (filter.key === key) {
            filter.value = value;
          }
          return filter;
        }
      );
    },
    setCurrentRoleUsers: (
      state,
      {
        payload: { users, total, page },
      }: PayloadAction<{ users: IRoleUser[]; total: number; page: number }>
    ) => {
      state.currentRoleUsers = users;
      state.currentRoleUsersPage = page;
      state.currentRoleUsersTotal = total;
    },
    setIsFetchingRoleUsers: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingRoleUsers = payload;
    },
    setIsEditingRoleUsers: (state, { payload }: PayloadAction<boolean>) => {
      state.isEditingRoleUsers = payload;
    },
    setIsFetchingUsersNotInCurrentRole: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingUsersNotInCurrentRole = payload;
    },
    setUsersNotInCurrentRole: (
      state,
      {
        payload: { users, total, page },
      }: PayloadAction<{ users: IRoleUser[]; total: number; page: number }>
    ) => {
      state.usersNotInCurrentRole = users;
      state.usersNotInCurrentRolePage = page;
      state.usersNotInCurrentRoleTotal = total;
    },
    clearRolePermissions: (state) => {
      if (state.localRoles && state.currentRole) {
        state.localRoles = state.localRoles.map((role) => {
          if (role.idcargo === state.currentRole?.idcargo) {
            role.permissions.forEach(
              (permission) => (permission.value = false)
            );
            state.currentRole = role;
          }
          return role;
        });
      }
    },
    resetRolePermissions: (state) => {
      const originalCurrentRole = state.roles?.find(
        (role) => role.idcargo === state.currentRole?.idcargo
      );
      if (originalCurrentRole) {
        state.currentRole = originalCurrentRole;
        state.currentRoleNewName = originalCurrentRole.nome;
      }
      state.localRoles = state.roles;
    },
    updateRolePermission: (
      state,
      {
        payload: { permissionName, roleId },
      }: PayloadAction<{ permissionName: string; roleId: string }>
    ) => {
      if (state.localRoles) {
        state.localRoles = state.localRoles.map((role) => {
          if (role.idcargo === roleId) {
            role.permissions.map((permission) => {
              if (permission.nome === permissionName) {
                permission.value = !permission.value;
              }
              return permission;
            });
            state.currentRole = role;
          }
          return role;
        });
      }
    },
  },
});

export const fetchRoles = (rolesActive = true): AppThunk => async (dispatch) => {
  const { setIsFetchingRoles, setRoles } = rolesSlice.actions;
  try {
    dispatch(setIsFetchingRoles(true));
    const response = await api.get(`/api/roles`, { params: { active: rolesActive } });
    dispatch(setRoles(response.data.data));
    dispatch(setIsFetchingRoles(false));
  } catch (error: any) {
    dispatch(setIsFetchingRoles(false));
    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);
    } else {
      console.log(error.message);
    }
  }
};

export const createRole = ({
  nome,
  callback,
}: {
  nome: string;
  callback?: Function;
}): AppThunk => async (dispatch) => {
  const { setIsCreatingOrUpdatingRole } = rolesSlice.actions;

  try {
    dispatch(setIsCreatingOrUpdatingRole(true));
    await api.post(`/api/roles`, { nome });
    toast.success("Cargo cadastrado", toastOptions);
    dispatch(fetchRoles());
    dispatch(setIsCreatingOrUpdatingRole(false));
    if (callback) {
      callback();
    }
  } catch (error: any) {
    dispatch(setIsCreatingOrUpdatingRole(false));
    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);
    } else {
      console.log(error.message);
    }
  }
};

export const updateCurrentRole = (roleName?: string): AppThunk => async (
  dispatch,
  getState
) => {
  const { currentRole } = getState().roles;
  const { setIsCreatingOrUpdatingRole } = rolesSlice.actions;
  if (currentRole) {
    const updatedRole = currentRole.permissions.reduce(
      // @ts-ignore
      (a, b) => ((a[b.nome] = b.value), a),
      {}
    );
    try {
      dispatch(setIsCreatingOrUpdatingRole(true));
      await api.put(`/api/roles/${currentRole.idcargo}`, {
        ...updatedRole,
        nome: roleName,
      });
      toast.success("Cargo atualizado com sucesso", toastOptions);
      dispatch(fetchRoles());
      dispatch(setIsCreatingOrUpdatingRole(false));
    } catch (error: any) {
      dispatch(setIsCreatingOrUpdatingRole(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  }
};

export const deleteRole = (isDisabling: boolean): AppThunk => async (
  dispatch,
  getState
) => {
  const { setIsDeletingRoles } = rolesSlice.actions;
  try {
    dispatch(setIsDeletingRoles(true));
    const { currentRole } = getState().roles;
    if (currentRole) {
      if(isDisabling) {
        await api.put(`/api/roles/${currentRole.idcargo}`,{ativo: false});
        toast.success("Cargo desativado", toastOptions);
      }else{        
        await api.put(`/api/roles/${currentRole.idcargo}`,{ativo: true});
        toast.success("Cargo reativado", toastOptions);
      }
      dispatch(fetchRoles());
    }
    dispatch(setIsDeletingRoles(false));
  } catch (error: any) {
    dispatch(setIsDeletingRoles(false));
    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);
    } else {
      console.log(error.message);
    }
  }
};

export const fetchRoleUsers = (): AppThunk => async (
  dispatch,
  getState
) => {
  const { setIsFetchingRoleUsers, setCurrentRoleUsers } = rolesSlice.actions;
  try {
    dispatch(setIsFetchingRoleUsers(true));
    const { currentRole, currentRoleUsersFilterArray } = getState().roles;
    const queryParameters = queryStringFromFilterArray(
      currentRoleUsersFilterArray
    );
    if (currentRole) {
      const response = await api.get(
        `/api/roles/${currentRole.idcargo}/users${queryParameters}`
      );
      
      dispatch(setCurrentRoleUsers(response.data.data))
    }
    dispatch(setIsFetchingRoleUsers(false));
  } catch (error: any) {
    dispatch(setIsFetchingRoleUsers(false));
    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);
    } else {
      console.log(error.message);
    }
  }
};

export const linkUserToRole = (userId: string[]): AppThunk => async (
  dispatch,
  getState
) => {
  console.log(userId)
  const { setIsEditingRoleUsers } = rolesSlice.actions;
  try {
    dispatch(setIsEditingRoleUsers(true));
    const { currentRole } = getState().roles;
    if (currentRole) {
      await api.post(`/api/roles/${currentRole.idcargo}/users/add`, {
        usuarios: userId,
      });
    }
    toast.success('Usuário vinculado com sucesso', toastOptions);
    dispatch(setIsEditingRoleUsers(false));

    dispatch(fetchUsersNotInRole({}));
  } catch (error: any) {
    dispatch(setIsEditingRoleUsers(false));
    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);
    } else {
      console.log(error.message);
    }
  }
};

export const unlinkUserFromRole = (userId: string[]): AppThunk => async (
  dispatch,
  getState
) => {
  const { setIsEditingRoleUsers } = rolesSlice.actions;
  try {
    dispatch(setIsEditingRoleUsers(true));
    const { currentRole } = getState().roles;
    if (currentRole) {
      await api.post(`/api/roles/${currentRole.idcargo}/users/remove`, {
        usuarios: userId,
      });
    }
    dispatch(setIsEditingRoleUsers(false));
    dispatch(fetchRoleUsers());
  } catch (error: any) {
    dispatch(setIsEditingRoleUsers(false));
    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);
    } else {
      console.log(error.message);
    }
  }
};

export const fetchUsersNotInRole = ({limit=6, page=1} : {limit?: number, page?: number}): AppThunk => async (
  dispatch,
  getState
) => {
  const {
    setIsFetchingRoleUsers,
    setUsersNotInCurrentRole,
  } = rolesSlice.actions;
  try {
    dispatch(setIsFetchingRoleUsers(true));
    const { currentRole, usersNotInCurrentRoleFilterArray } = getState().roles;
    const queryParameters = queryStringFromFilterArray(
      usersNotInCurrentRoleFilterArray
    );
    const pageAndLimit =
    queryParameters.length === 0
      ? `?page=${page}&limit=${limit}`
      : `&page=${page}&limit=${limit}`;
    if (currentRole) {
      const response = await api.get(
        `/api/roles/${currentRole.idcargo}/users-missing?page=${page}&limit=${limit}`
      );
      dispatch(setUsersNotInCurrentRole(response.data.data));
    }
    dispatch(setIsFetchingRoleUsers(false));
  } catch (error: any) {
    dispatch(setIsFetchingRoleUsers(false));
    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);
    } else {
      console.log(error.message);
    }
  }
};

export const {
  setCurrentRole,
  updateRolePermission,
  resetRolePermissions,
  setCurrentRoleNewName,
  clearRolePermissions,
  updateCurrentRoleUsersFilterArray,
  updateUsersNotInCurrentRoleFilterArray,
} = rolesSlice.actions;

export default rolesSlice.reducer;
