import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, AppDispatch } from "store/index";
import { toast } from "react-toastify";
import toastOptions from "utils/toastOptions";
import api from "utils/API";
import { queryStringFromFilterArray } from "utils/network";
import axios from "axios";
import { IManufacturers, IManufacturersForm } from "models/Manufacturers";
import { ILocal } from "models/Local";

interface IInitialState {
    localList: ILocal[] | null;
    local: ILocal | null;
    isFetchingLocal: Boolean;
    page: number;
    total: number;
}
const initialState: IInitialState = {
    localList: null,
    local: null,
    isFetchingLocal: false,
    page: 1,
    total: 0
};

const localSlice = createSlice({
  name: "localSlice",
  initialState,
  reducers: {
        setLocal: (
        state,
        { payload }: PayloadAction<ILocal>
        ) => {
        state.local = payload;
        },
        setLocalList: (
        state,
        { payload }: PayloadAction<{
          stock_locations:ILocal[]
          page: number;
          total: number;
        }>
        ) => {
        state.localList = payload.stock_locations;
        state.page = payload.page;
        state.total = payload.total;
        },
        setIsFetchingLocal: (state, { payload }:PayloadAction<Boolean>) => {
            state.isFetchingLocal = payload
        }
    }
});

export const fetchLocalList = ({limit = 6, page = 1}:{limit?: number, page?: number}): AppThunk => async (dispatch, getState) => {
    const { setIsFetchingLocal, setLocalList } = localSlice.actions;
    dispatch(setIsFetchingLocal(true));
    try {
      const pageAndLimit = `?page=${page}&limit=${limit}`
      
      const response = await api.get(
        `/api/stocklocations${pageAndLimit}`
      );
      dispatch(setLocalList(response.data.data));
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
        dispatch(setIsFetchingLocal(false))
    }
  };

  export const fetchLocal = ({id}:{id:string}): AppThunk => async (dispatch, getState) => {
    const { setIsFetchingLocal, setLocal } = localSlice.actions;
    dispatch(setIsFetchingLocal(true))
    try {
      const response = await api.get(
        `/api/stocklocations/${id}`
      );
      dispatch(setLocal(response.data.data));
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
        dispatch(setIsFetchingLocal(false))
    }
  };

  export const submitLocal = (data:IManufacturersForm, cb?:Function): AppThunk => async (dispatch, getState) => {
    const { setIsFetchingLocal, setLocal } = localSlice.actions;
    dispatch(setIsFetchingLocal(true))
    try {
      const response = await api.post(
        `/api/stocklocations`, data
      );
      if(cb){
        cb(response.data.data.idlocalestoque)
      }
      toast.success("Local cadastrado com sucesso", toastOptions);
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
        dispatch(setIsFetchingLocal(false))
    }
  };

  export const editLocal = ({id, data, cb}: {id: string, data: IManufacturersForm, cb?: Function}): AppThunk => async (dispatch, getState) => {
    const { setIsFetchingLocal, setLocal } = localSlice.actions;
    dispatch(setIsFetchingLocal(true))
    try {
      const response = await api.put(
        `/api/stocklocations/${id}`, data
      );
      dispatch(setLocal(response.data.stock_manufacturers))
      toast.success("Local atualizado com sucesso", toastOptions);
      if(cb){
        cb()
      }
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
        dispatch(setIsFetchingLocal(false))
    }
  };

  export const fetchManufacturersList = ({limit = 6, page = 1}:{limit?: number, page?: number}): AppThunk => async (dispatch, getState) => {
    const { setIsFetchingLocal, setLocalList } = localSlice.actions;
    dispatch(setIsFetchingLocal(true));
    try {
      const pageAndLimit = `?page=${page}&limit=${limit}`
      
      const response = await api.get(
        `/api/stockmanufacturers${pageAndLimit}`
      );
      dispatch(setLocalList(response.data.data));
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
        dispatch(setIsFetchingLocal(false))
    }
  };

export const { setLocal  } = localSlice.actions;

export default localSlice.reducer;
