import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IBankAccount,
  IClienteEFornecedor,
} from "models/financeiro/ClientesEFornecedores";
import { IFilter } from "models/shared";
import { toast } from "react-toastify";
import { AppThunk } from "store";
import api from "utils/API";
import { queryStringFromFilterArray } from "utils/network";
import toastOptions from "utils/toastOptions";
import {
  ClienteEFornecedoresResponse,
  ClienteEFornecedorResponse,
  ClientesEFornecedoresState,
} from "./ClienteFornecedorReducer.types";

const initialState: ClientesEFornecedoresState = {
  customersProviders: [],
  allCustomersProviders: [],
  customerProvider: null,
  isCreatingCustomerProvider: false,
  isFetchingCustomersProviders: false,
  isFetchingCustomerProvider: false,
  total: 0,
  page: 1,
  limit: 6,
  selectedCheckboxes: [],
  bankAccount: null,
  isCreatingBankAccount: false,
  isFetchingBankAccount: false,
  customerProviderFilterArray: [
    { key: "idclientefornecedor", value: null },
    { key: "classificacao", value: null },
    { key: "documento", value: null },
    { key: "ativo", value: null },
  ],
};

const customerProvidersSlice = createSlice({
  name: "customerProvidersSlice",
  initialState,
  reducers: {
    setIsCreatingCustomerProvider: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isCreatingCustomerProvider = payload;
    },
    setIsFetchingCustomersProviders: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingCustomersProviders = payload;
    },
    setIsFetchingCustomerProvider: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingCustomerProvider = payload;
    },
    setCustomersProviders: (
      state,
      { payload }: PayloadAction<IClienteEFornecedor[]>
    ) => {
      state.customersProviders = payload;
    },
    setAllCustomersProviders: (
      state,
      { payload }: PayloadAction<IClienteEFornecedor[]>
    ) => {
      state.allCustomersProviders = payload;
    },
    setCustomerProvider: (
      state,
      { payload }: PayloadAction<IClienteEFornecedor | null>
    ) => {
      state.customerProvider = payload;
    },
    setTotal: (state, { payload }: PayloadAction<number>) => {
      state.total = payload;
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setLimit: (state, { payload }: PayloadAction<number>) => {
      state.limit = payload;
    },
    setSelectedCheckboxes: (state, { payload }: PayloadAction<string[]>) => {
      state.selectedCheckboxes = payload;
    },
    setBankAccount: (state, { payload }: PayloadAction<IBankAccount>) => {
      state.bankAccount = payload;
    },
    setIsFetchingBankAccount: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingBankAccount = payload;
    },
    setIsCreatingBankAccount: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreatingBankAccount = payload;
    },
    updateCustomerProviderFilterArray: (
      state,
      { payload: { key, value } }: PayloadAction<IFilter>
    ) => {
      state.customerProviderFilterArray = state.customerProviderFilterArray.map(
        (filter) => {
          if (filter.key === key) {
            filter.value = value;
          }
          return filter;
        }
      );
    },
  },
});

export const fetchCustomersProviders =
  ({ page = 1, limit = 6 }: { page: number; limit: number }): AppThunk =>
  async (dispatch, getState) => {
    const {
      setCustomersProviders,
      setPage,
      setTotal,
      setIsFetchingCustomersProviders,
    } = customerProvidersSlice.actions;
    const { customerProviderFilterArray } = getState().customersProviders;
    dispatch(setIsFetchingCustomersProviders(true));
    const queryParameters = queryStringFromFilterArray(
      customerProviderFilterArray
    );
    const pageAndLimit =
      queryParameters.length === 0
        ? `?page=${page}&limit=${limit}`
        : `&page=${page}&limit=${limit}`;

    try {
      const { data }: ClienteEFornecedoresResponse = await api.get(
        `/api/customer_provider${queryParameters}${pageAndLimit}`
      );
      dispatch(setCustomersProviders(data.data.customer_providers));
      dispatch(setPage(data.data.page));
      dispatch(setTotal(data.data.total));
    } catch (error: any) {
      console.log(error);

      if (error.response) {
        toast.error(error.response.data.message, toastOptions);
      }
    } finally {
      dispatch(setIsFetchingCustomersProviders(false));
    }
  };

export const fetchAllCustomersProviders = (): AppThunk => async (dispatch) => {
  const {
    setAllCustomersProviders,
    setPage,
    setTotal,
    setIsFetchingCustomersProviders,
  } = customerProvidersSlice.actions;
  dispatch(setIsFetchingCustomersProviders(true));
  try {
    const { data }: ClienteEFornecedoresResponse = await api.get(
      `/api/customer_provider?page=1&limit=999`
    );
    dispatch(setAllCustomersProviders(data.data.customer_providers));
    dispatch(setPage(data.data.page));
    dispatch(setTotal(data.data.total));
  } catch (error: any) {
    console.log(error);

    if (error.response) {
      toast.error(error.response.data.message, toastOptions);
    }
  } finally {
    dispatch(setIsFetchingCustomersProviders(false));
  }
};

export const fetchCustomerProviderById =
  (clienteFornecedorId: string): AppThunk =>
  async (dispatch) => {
    const { setCustomerProvider, setIsFetchingCustomerProvider } =
      customerProvidersSlice.actions;
    dispatch(setIsFetchingCustomerProvider(true));
    try {
      const { data }: ClienteEFornecedorResponse = await api.get(
        `/api/customer_provider/${clienteFornecedorId}`
      );
      dispatch(setCustomerProvider(data.data));
    } catch (error: any) {
      console.log(error);
      if (error.response) {
        toast.error(error.response.data.message, toastOptions);
      }
    } finally {
      dispatch(setIsFetchingCustomerProvider(false));
    }
  };

export const createCustomerProvider =
  (customer: IClienteEFornecedor): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingCustomerProvider, setCustomerProvider } = customerProvidersSlice.actions;
    dispatch(setIsCreatingCustomerProvider(true));
    try {
      const { data } = await api.post("/api/customer_provider", customer);
      toast.success("Cliente/fornecedor criado com sucesso");
      dispatch(setCustomerProvider(data.data))
    } catch (error: any) {
      console.log(error);
      if (error.response) {
        toast.error(error.response.data.message, toastOptions);
      }
    } finally {
      dispatch(setIsCreatingCustomerProvider(false));
    }
  };

export const editCustomerProvider =
  ({
    customerProviderId,
    body,
  }: {
    customerProviderId: string;
    body: IClienteEFornecedor;
  }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingCustomerProvider } = customerProvidersSlice.actions;
    dispatch(setIsFetchingCustomerProvider(true));
    try {
      await api.put(`/api/customer_provider/${customerProviderId}`, body);
      toast.success("Cliente/fornecedor editado com sucesso");
    } catch (error: any) {
      console.log(error);
      if (error.response) {
        toast.error(error.response.data.message, toastOptions);
      }
    } finally {
      dispatch(setIsFetchingCustomerProvider(false));
    }
  };

export const deleteCustomerProvider =
  (customerProviderId: string): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingCustomerProvider } = customerProvidersSlice.actions;
    dispatch(setIsFetchingCustomerProvider(true));
    try {
      await api.delete(`/api/customer_provider/${customerProviderId}`);
      dispatch(fetchCustomersProviders({ page: 1, limit: 6 }));
      toast.success("Cliente/fornecedor: status alterado sucesso");
    } catch (error: any) {
      console.log(error);
      if (error.response) {
        toast.error(error.response.data.message, toastOptions);
      }
    } finally {
      dispatch(setIsFetchingCustomerProvider(false));
    }
  };

// BANK ACCOUNTS
export const createBankAccount =
  (bankAccount: IBankAccount): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingBankAccount } = customerProvidersSlice.actions;
    dispatch(setIsCreatingBankAccount(true));
    try {
      await api.post("/api/customer_provider/account_bank", bankAccount);
      toast.success("Dados bancários vinculados com sucesso");
    } catch (error: any) {
      console.log(error);
      if (error.response) {
        toast.error(error.response.data.message, toastOptions);
      }
    } finally {
      dispatch(setIsCreatingBankAccount(false));
    }
  };

export const editBankAccount =
  ({ accountId, body }: { accountId: string; body: IBankAccount }): AppThunk =>
  async (dispatch) => {
    const { setIsFetchingBankAccount } = customerProvidersSlice.actions;
    dispatch(setIsFetchingBankAccount(true));
    try {
      await api.put(`/api/customer_provider/account_bank/${accountId}`, body);
      toast.success("Dados bancários editados com sucesso");
    } catch (error: any) {
      console.log(error);
      if (error.response) {
        toast.error(error.response.data.message, toastOptions);
      }
    } finally {
      dispatch(setIsFetchingBankAccount(false));
    }
  };

export const {
  setIsCreatingCustomerProvider,
  setIsFetchingCustomersProviders,
  setCustomersProviders,
  setCustomerProvider,
  setPage,
  setTotal,
  setLimit,
  setSelectedCheckboxes,
  setBankAccount,
  setIsCreatingBankAccount,
  setIsFetchingBankAccount,
  setAllCustomersProviders,
  updateCustomerProviderFilterArray,
} = customerProvidersSlice.actions;

export default customerProvidersSlice.reducer;
