import { IAppointmentWebSetupForm } from "store/reducers/AppointmentWebSetup";
import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  convenio: Yup.boolean().required("Campo obrigatório"),
  combo: Yup.boolean().required("Campo obrigatório"),
  pagamentoonline: Yup.boolean().required("Campo obrigatório"),
});

export const initialState: IAppointmentWebSetupForm = {
  convenio: false,
  combo: false,
  pagamentoonline: false,
  parcelamento: false,
  checkin: false,
  cor_primaria: "",
  cor_secundaria: "",
  cor_terciaria: "",
  cor_background: "",
  texto_titulo: "",
  texto_subtitulo: "",
  texto_secao_agendamento: "",
  text_botao_redirecionar_agendamento: "",
  url_imagem_fundo: "",
  texto_footer: "",
  url_logo: "",
  url_site_empresa: "",
  text_card_agendamento: "",
  url_imagem_card_agendamento: "",
};
