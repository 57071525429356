import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IProfessionalFavorite } from "models/ProfessionalFavorites";
import { toast } from "react-toastify";
import { AppThunk } from "store";
import api from "utils/API";
import toastOptions from "utils/toastOptions";

interface IInitialState {
  currentProfessionalFavorite: IProfessionalFavorite | null;
  isFetchingProfessionalFavorites: boolean;
  isCreatingProfessionalFavorites: boolean;
  isDeletingProfessionalFavorites: boolean;
  isUpdatingProfessionalFavorites: boolean;
  isDeletingItemsFromProfessionalFavorites: boolean;
  userHasFavoritesList: boolean;
}

const initialState: IInitialState = {
  currentProfessionalFavorite: null,
  isFetchingProfessionalFavorites: false,
  isCreatingProfessionalFavorites: false,
  isDeletingProfessionalFavorites: false,
  isUpdatingProfessionalFavorites: false,
  isDeletingItemsFromProfessionalFavorites: false,
  userHasFavoritesList: false,
};

const professionalFavoritesSlice = createSlice({
  name: "professionalFavoritesSlice",
  initialState,
  reducers: {
    setCurrentProfessionalFavorite: (
      state,
      { payload }: PayloadAction<IProfessionalFavorite | null>
    ) => {
      state.currentProfessionalFavorite = payload;
    },
    setIsFetchingProfessionalFavorites: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingProfessionalFavorites = payload;
    },
    setIsCreatingProfessionalFavorites: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isCreatingProfessionalFavorites = payload;
    },
    setIsDeletingProfessionalFavorites: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isDeletingProfessionalFavorites = payload;
    },
    setIsUpdatingProfessionalFavorites: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isUpdatingProfessionalFavorites = payload;
    },
    setIsDeletingItemsFromProfessionalFavorites: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isDeletingItemsFromProfessionalFavorites = payload;
    },
    setUserHasFavoritesList: (state, { payload }: PayloadAction<boolean>) => {
      state.userHasFavoritesList = payload;
    },
  },
});

export const fetchProfessionalFavorites =
  (): AppThunk => async (dispatch, getState) => {
    const {
      setIsFetchingProfessionalFavorites,
      setCurrentProfessionalFavorite,
      setUserHasFavoritesList,
    } = professionalFavoritesSlice.actions;

    try {
      dispatch(setIsFetchingProfessionalFavorites(true));
      const response = await api.get(`/api/professional-favorites`);
      dispatch(setIsFetchingProfessionalFavorites(false));
      dispatch(setCurrentProfessionalFavorite(response.data.data));
      dispatch(setUserHasFavoritesList(true));
    } catch (error: any) {
      dispatch(setIsFetchingProfessionalFavorites(false));
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.message &&
        error.response.data.error.message !==
          "Usuário não possui lista de favoritos"
      ) {
        toast.error(error.response.data.error.message, toastOptions);
      } else {
        dispatch(setUserHasFavoritesList(false));
      }
    }
  };

export const createProfessionalFavoritesList =
  ({
    idprofissional,
    cb,
  }: {
    idprofissional: string;
    cb?: () => void;
  }): AppThunk =>
  async (dispatch, getState) => {
    const {
      setIsCreatingProfessionalFavorites,
      setCurrentProfessionalFavorite,
      setUserHasFavoritesList,
    } = professionalFavoritesSlice.actions;

    try {
      dispatch(setIsCreatingProfessionalFavorites(true));
      const response = await api.post(`/api/professional-favorites`, {
        idprofissional,
      });
      dispatch(setIsCreatingProfessionalFavorites(false));
      dispatch(setCurrentProfessionalFavorite(response.data.data));
      dispatch(setUserHasFavoritesList(true));
      toast.success("Lista de favoritos criada com sucesso", toastOptions);
      if (cb) {
        cb();
      }
    } catch (error: any) {
      dispatch(setIsCreatingProfessionalFavorites(false));
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.message
      ) {
        toast.error(error.response.data.error.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const deleteProfessionalFavoritesList =
  ({
    idprofissional,
    idProfessionalFavorites,
    cb,
  }: {
    idprofissional: string;
    idProfessionalFavorites: string;
    cb?: () => void;
  }): AppThunk =>
  async (dispatch, getState) => {
    const {
      setIsDeletingProfessionalFavorites,
      setCurrentProfessionalFavorite,
      setUserHasFavoritesList,
    } = professionalFavoritesSlice.actions;

    try {
      dispatch(setIsDeletingProfessionalFavorites(true));
      const response = await api.delete(
        `/api/professional-favorites?idprofissional=${idprofissional}&id=${idProfessionalFavorites}`
      );
      dispatch(setIsDeletingProfessionalFavorites(false));
      dispatch(setCurrentProfessionalFavorite(null));
      dispatch(setUserHasFavoritesList(false));
      toast.success(response.data.data, toastOptions);
      if (cb) {
        cb();
      }
    } catch (error: any) {
      dispatch(setIsDeletingProfessionalFavorites(false));
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.message
      ) {
        toast.error(error.response.data.error.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const updateProfessionalFavoritesList =
  ({
    idprofissional,
    idProfessionalFavorites,
    serviceItemsIds,
    cb,
  }: {
    idprofissional: string;
    idProfessionalFavorites: string;
    serviceItemsIds: string[];
    cb?: () => void;
  }): AppThunk =>
  async (dispatch, getState) => {
    const {
      setIsUpdatingProfessionalFavorites,
      setCurrentProfessionalFavorite,
      setUserHasFavoritesList,
    } = professionalFavoritesSlice.actions;

    try {
      dispatch(setIsUpdatingProfessionalFavorites(true));
      const response = await api.put(
        `/api/professional-favorites/${idprofissional}/${idProfessionalFavorites}`,
        {
          serviceItemsIds,
        }
      );
      dispatch(setIsUpdatingProfessionalFavorites(false));
      dispatch(setCurrentProfessionalFavorite(response.data.data));
      dispatch(setUserHasFavoritesList(true));
      toast.success("Lista de favoritos atualizada com sucesso", toastOptions);
      if (cb) {
        cb();
      }
    } catch (error: any) {
      dispatch(setIsUpdatingProfessionalFavorites(false));
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.message
      ) {
        toast.error(error.response.data.error.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const deleteItemsFromProfessionalFavoritesList =
  ({
    idprofissional,
    idProfessionalFavorites,
    serviceItemsIds,
    isForced,
    cb,
  }: {
    idprofissional: string;
    idProfessionalFavorites: string;
    serviceItemsIds?: string[];
    isForced?: boolean;
    cb?: () => void;
  }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsDeletingItemsFromProfessionalFavorites } =
      professionalFavoritesSlice.actions;

    try {
      dispatch(setIsDeletingItemsFromProfessionalFavorites(true));
      if (isForced) {
        const response = await api.delete(
          `/api/professional-favorites/${idprofissional}/${idProfessionalFavorites}?deleteall=${isForced}`
        );
        toast.success(response.data.data, toastOptions);
      } else if (serviceItemsIds && serviceItemsIds.length > 0) {
        const response = await api.delete(
          `/api/professional-favorites/${idprofissional}/${idProfessionalFavorites}?idsitemservico=${serviceItemsIds.join(
            ","
          )}`
        );
        toast.success(response.data.data, toastOptions);
      } else {
        toast.error(
          "É preciso selecionar pelo menos um item para ser deletado",
          toastOptions
        );
      }

      dispatch(setIsDeletingItemsFromProfessionalFavorites(false));
      if (cb) {
        cb();
      }
    } catch (error: any) {
      dispatch(setIsDeletingItemsFromProfessionalFavorites(false));
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.message
      ) {
        toast.error(error.response.data.error.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export default professionalFavoritesSlice.reducer;
