import { IFilter } from "models/shared/index";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "store/index";
import { toast } from "react-toastify";
import toastOptions from "utils/toastOptions";
import api from "utils/API";
import { history } from "utils/history";
import { queryStringFromFilterArray } from "utils/network";
import { IForwardingGuide, ILab, ILabForm } from "models/Lab";
import { IServiceOrderServiceItem } from "models/ServiceOrder/ServiceOrderServiceItem";
import { ILabInfo } from "pages/ServiceOrders/Components/ServiceOrderAccordion";
import { ISelectLab } from "models/Autocomplete";

export const initialLabState = {
	nome: "",
	idlaboratorio: "",
	contato: "",
	logradouro: "",
	complemento: "",
	numero: "",
	bairro: "",
	cidade: "",
	estado: "",
};

interface IInitialState {
  isFetchingLabs: boolean;
  isCreatingLab: boolean;
  isDeletingLab: boolean;
  labs: ILab[];
  labsFilteredByItem: ILab[] | null;
  forwardingGuides: IForwardingGuide[] | null
  currentForwardingGuide: IForwardingGuide | null
  professionalLabs: ILab[];
  currentLab: ILab | null;
  total: number;
  page: number;
  filterArray: IFilter[];
  printExternalServices: boolean;
  selectedExternalServices: IServiceOrderServiceItem[];
  selectedLabInfo: ILabInfo;
  nomeResponsavel: string;
  dataEHora: Date;
  labToPrint: ISelectLab;
  flowScheduleTimeLine: boolean;
  tabFlowScheduleTimeLine: number;
}

const initialState: IInitialState = {
  isFetchingLabs: false,
  isCreatingLab: false,
  isDeletingLab: false,
  labs: [],
  labsFilteredByItem: null,
  forwardingGuides: null,
  currentForwardingGuide: null,
  professionalLabs: [],
  currentLab: null,
  total: 0,
  page: 0,
  filterArray: [
    { key: "nome", value: [] }
  ],
  printExternalServices: false,
  selectedExternalServices: [],
  selectedLabInfo: {
    dataehora: new Date(), nomeresponsavel: "",
    laboratory: { nome: "", idlaboratorio: "", contato: "", logradouro: "", complemento: "", numero: "", bairro: "", cidade: "", estado: "" }
  },
  nomeResponsavel: "",
  dataEHora: new Date(),
  labToPrint: initialLabState,
  flowScheduleTimeLine: false,
  tabFlowScheduleTimeLine: 0,
};

const labSlice = createSlice({
  name: "labSlice",
  initialState,
  reducers: {
    setTabFlowScheduleTimeLine: (state, { payload }: PayloadAction<number>) => {
      state.tabFlowScheduleTimeLine = payload;
    },
    setFlowScheduleTimeLine: (state, { payload }: PayloadAction<boolean>) => {
      state.flowScheduleTimeLine = payload;
    },
    setIsFetchingLabs: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingLabs = payload;
    },
    setIsCreatingLab: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreatingLab = payload;
    },
    setIsDeletingLab: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeletingLab = payload;
    },
    setPrintExternalServices: (state, { payload }: PayloadAction<boolean>) => {
      state.printExternalServices = payload;
    },
    setSelectedExternalServices: (state, { payload }: PayloadAction<IServiceOrderServiceItem[]>) => {
      state.selectedExternalServices = payload;
    },
    setSelectedLabInfo: (state, { payload }: PayloadAction<ILabInfo>) => {
      state.selectedLabInfo = payload;
    },
    setLabs: (
      state,
      {
        payload: { laboratories, total, page },
      }: PayloadAction<{
        laboratories: ILab[];
        total: number;
        page: number;
      }>
    ) => {
      state.labs = laboratories;
      state.total = total;
      state.page = page;
    },
    setLabsFilteredByItems: (
      state,
      {
        payload
      }: PayloadAction<ILab[]>
    ) => {
      state.labsFilteredByItem = payload;
    },
    setForwardingGuides: (
      state,
      {
        payload
      }: PayloadAction<IForwardingGuide[]>
    ) => {
      state.forwardingGuides = payload;
    },
    setCurrentForwardingGuide: (
      state,
      {
        payload
      }: PayloadAction<IForwardingGuide | null>
    ) => {
      state.currentForwardingGuide = payload;
    },
    setCurrentLab: (state, { payload }: PayloadAction<ILab>) => {
      state.currentLab = payload;
    },
    updateFilter: (state, { payload }: PayloadAction<IFilter>) => {
      const index = state.filterArray.findIndex(
        (item) => item.key === payload.key
      );
      if (index === -1) {
        state.filterArray.push({ key: payload.key, value: payload.value },)
      } else {
        state.filterArray[index].value = payload.value;
      }
      // state.filterArray[index].value = payload.value;
    },
    setNomeResponsavel: (state, { payload }: PayloadAction<string>) => {
      state.nomeResponsavel = payload;
    },
    setDataEHora: (state, { payload }: PayloadAction<Date>) => {
      state.dataEHora = payload;
    },
    setLabToPrint: (state, { payload }: PayloadAction<ISelectLab>) => {
      state.labToPrint = payload;
    },
  },
});

export const fetchLabs = ({
  page = 1,
  limit = 6
}: {
  page?: number;
  limit?: number;
  blockId?: number | string;
}): AppThunk => async (dispatch, getState) => {
  const { setIsFetchingLabs, setLabs } = labSlice.actions;
  dispatch(setIsFetchingLabs(true));
  try {
    const state = getState();
    const { filterArray } = state.lab;
    const queryParameters = queryStringFromFilterArray(filterArray);
    const pageAndLimit =
      queryParameters.length === 0
        ? `?page=${page}&limit=${limit}`
        : `&page=${page}&limit=${limit}`;
    const response = await api.get(
      `/api/laboratories${queryParameters}${pageAndLimit}`
    );
    dispatch(setLabs(response.data.data));
    dispatch(setIsFetchingLabs(false));
  } catch (error: any) {
    dispatch(setIsFetchingLabs(false));
    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);
    } else {
      console.log(error.message);
    }
  }
};

export const fetchLabById = (labId: string): AppThunk => async (
  dispatch
) => {
  const { setIsFetchingLabs, setCurrentLab } = labSlice.actions;
  dispatch(setIsFetchingLabs(true));
  try {
    const response = await api.get(`/api/laboratories/${labId}`);
    dispatch(setCurrentLab(response.data.data));
    dispatch(setIsFetchingLabs(false));
  } catch (error: any) {
    dispatch(setIsFetchingLabs(false));
    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);
    } else {
      console.log(error.message);
    }
  }
};

export const fetchLabsByServiceItems = ({ serviceItemId }: { serviceItemId: string[] }): AppThunk => async (
  dispatch
) => {
  const { setIsFetchingLabs, setLabsFilteredByItems } = labSlice.actions;
  dispatch(setIsFetchingLabs(true));
  try {
    const response = await api.get(`/api/partners?iditemservico=${serviceItemId}`);
    dispatch(setLabsFilteredByItems(response.data.data));
    dispatch(setIsFetchingLabs(false));
  } catch (error: any) {
    dispatch(setIsFetchingLabs(false));
    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);
    } else {
      console.log(error.message);
    }
  }
};

export const fetchForwardingGuides = ({ serviceOrderId }: { serviceOrderId: string }): AppThunk => async (
  dispatch
) => {
  const { setIsFetchingLabs, setForwardingGuides } = labSlice.actions;
  dispatch(setIsFetchingLabs(true));
  try {
    const response = await api.get(`/api/forwardingguides?idordemservico=${serviceOrderId}`);
    dispatch(setForwardingGuides(response.data.data.forwarding_guides));
    dispatch(setIsFetchingLabs(false));
  } catch (error: any) {
    dispatch(setIsFetchingLabs(false));
    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);
    } else {
      console.log(error.message);
    }
  }
};

export const fetchForwardGuideById = ({ idguiaencaminhamento }: { idguiaencaminhamento: string }): AppThunk => async (
  dispatch
) => {
  const { setIsFetchingLabs, setCurrentForwardingGuide } = labSlice.actions;
  dispatch(setIsFetchingLabs(true));
  dispatch(setCurrentForwardingGuide(null));
  try {
    const response = await api.get(`/api/forwardingguides/${idguiaencaminhamento}`);
    dispatch(setCurrentForwardingGuide(response.data.data));
    dispatch(setIsFetchingLabs(false));
  } catch (error: any) {
    dispatch(setIsFetchingLabs(false));
    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);
    } else {
      console.log(error.message);
    }
  }
};

export const saveForwardingGuide = ({
  orderitems,
  data,
  idlaboratorio,
  nomeresponsavel,
  callback
}:
  {
    idlaboratorio: string,
    nomeresponsavel: string,
    data: Date,
    orderitems: string[]
    callback?: Function
  }): AppThunk => async (
    dispatch,
  ) => {
    const { setIsCreatingLab, setCurrentForwardingGuide } = labSlice.actions;
    dispatch(setIsCreatingLab(true));
    try {
      const response = await api.post(`/api/forwardingguides`, {
        idlaboratorio,
        nomeresponsavel,
        orderitems,
        data
      });
      toast.success("Guia salva", toastOptions)
      dispatch(setCurrentForwardingGuide(response.data.data));
      // if (forwardingGuides) {
      //   forwardingGuides.push(response.data.data);
      //   dispatch(setForwardingGuides(forwardingGuides));
      // } else {
      //   dispatch(setForwardingGuides([response.data.data]));
      // }

      dispatch(fetchForwardingGuides({serviceOrderId: response.data.data.orderitems[0].idordemservico}));
      
      dispatch(setIsCreatingLab(false));

      if (callback) callback()
    } catch (error: any) {
      dispatch(setIsCreatingLab(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };


export const createLab = ({
  lab,
  cb,
}: {
  lab: ILabForm;
  cb?: () => void;
}): AppThunk => async (dispatch) => {
  const { setIsCreatingLab } = labSlice.actions;
  try {
    dispatch(setIsCreatingLab(true));
    const response = await api.post(`/api/laboratories`, lab);
    dispatch(setIsCreatingLab(false));
    history.replace(`/labs/form/${response.data.data.idlaboratorio}`);
    toast.success("Parceiro externo cadastrado com sucesso", toastOptions);
    if (cb) {
      cb();
    }
  } catch (error: any) {
    dispatch(setIsCreatingLab(false));
    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);
    } else {
      console.log(error.message);
    }
  }
};

export const updateLab = ({
  lab,
  labId,
  cb,
}: {
  lab: ILabForm;
  labId: string;
  cb?: () => void;
}): AppThunk => async (dispatch) => {
  const { setIsCreatingLab } = labSlice.actions;
  dispatch(setIsCreatingLab(true));
  try {
    await api.put(`/api/laboratories/${labId}`, lab);
    dispatch(setIsCreatingLab(false));
    if (labId) {
      dispatch(fetchLabById(labId));
    }
    toast.success("Parceiro externo atualizado", toastOptions);
    // history.replace(`/labs`);
  } catch (error: any) {
    dispatch(setIsCreatingLab(false));
    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);
    } else {
      console.log(error.message);
    }
  }
};

export const deleteLab = ({
  labId,
  cb,
}: {
  labId: string;
  cb?: () => void;
}): AppThunk => async (dispatch) => {
  const { setIsDeletingLab } = labSlice.actions;
  dispatch(setIsDeletingLab(true));
  try {
    await api.delete(`/api/laboratories/${labId}`);
    dispatch(setIsDeletingLab(false));
    toast.success("Parceiro externo excluído com sucesso", toastOptions);
    dispatch(fetchLabs({}));
  } catch (error: any) {
    dispatch(setIsDeletingLab(false));
    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);
    } else {
      console.log(error.message);
    }
  }
};

export const statusLab = ({
  labId,
  cb,
}: {
  labId: string;
  cb?: () => void;
}): AppThunk => async (dispatch) => {
  const { setIsDeletingLab } = labSlice.actions;
  dispatch(setIsDeletingLab(true));
  try {
    await api.delete(`/api/laboratories-status/${labId}`);
    dispatch(setIsDeletingLab(false));
    toast.success("Status alterado", toastOptions);
    dispatch(fetchLabs({}));
  } catch (error: any) {
    dispatch(setIsDeletingLab(false));
    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);
    } else {
      console.log(error.message);
    }
  }
};

export const { 
  updateFilter: updateLabsFilter,
  setForwardingGuides,
  setCurrentForwardingGuide,
  setPrintExternalServices,
  setSelectedExternalServices,
  setSelectedLabInfo,
  setNomeResponsavel,
  setDataEHora,
  setLabToPrint,
  setFlowScheduleTimeLine,
  setTabFlowScheduleTimeLine
} = labSlice.actions;

export default labSlice.reducer;
