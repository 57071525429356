import React, { useMemo } from "react";
import { Grid, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/pt-br";
import DisponibilityListItem from "../DisponibilityListItem";
import { IDisponibilityMapDay } from "models/DisponibilityMap";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import { rootState } from "store";

dayjs.extend(localizedFormat);
dayjs.locale("pt-br");

interface IProps {
	data: IDisponibilityMapDay[];
	onSelectItem: Function;
}

const DisponibilityList = ({ data, onSelectItem }: IProps) => {
	const { currentServiceItems } = useSelector(
		(state: rootState) => state.disponibilityMap
	);

	const dataThatContainsAllServiceItems = useMemo(() => {
		const serviceItemsNames = currentServiceItems.map((item) =>
			item.label.toUpperCase()
		);

		return data.map((day) => {
			return {
				...day,
				professionals: day.professionals.filter((professional) => {
					const professionalServiceItems = professional.itensservico.map(
						(item) => item.nome.toUpperCase()
					);
					if (
						serviceItemsNames.every((item) =>
							professionalServiceItems.includes(item)
						)
					) {
						return true;
					}
					return false;
				}),
			};
		});
	}, [data, currentServiceItems]);

	const isDataEmpty = useMemo(() => {
		return dataThatContainsAllServiceItems.every(
			(day) => day.professionals.length === 0
		);
	}, [dataThatContainsAllServiceItems]);

	return (
		<Grid container spacing={3}>
			{isDataEmpty ? (
				<Grid item xs={12}>
					<Typography
						variant="h5"
						style={{ fontSize: "1rem", textAlign: "center" }}
					>
						Nenhum horário para visualização (Tente efetuar uma busca ou alterar
						os filtros)
					</Typography>
				</Grid>
			) : (
				<>
				<Typography
						variant="h3"
						style={{ width: "100%",fontSize:"1.4rem", textAlign: "center", display: "flex", justifyContent: "center" }}
					>
						Selecione abaixo um horário e profissional para prosseguir com o agendamento.
					</Typography>
				{dataThatContainsAllServiceItems.map(
					(item) =>
						item.professionals.length > 0 && (
							<React.Fragment key={uuidv4()}>
								<Grid item xs={12}>
									<Typography
										variant="h6"
										style={{ textTransform: "uppercase" }}
									>
										{dayjs(item.date).format("dddd, LL")}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Grid container spacing={1}>
										{item.professionals.map((disponibilityListItem, index) => (
											<Grid item key={uuidv4()}>
												<DisponibilityListItem
													onSelectItem={onSelectItem}
													data={disponibilityListItem}
													date={item.date}
													dateIndex={index}
												/>
											</Grid>
										))}
									</Grid>
								</Grid>
							</React.Fragment>
						)
				)}
				</>
			)}
		</Grid>
	);
};

export default DisponibilityList;
