import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  // @ts-ignore
} from "@react-pdf/renderer";
import { formatCurrency } from "utils/formatCurrency";
import Header from "components/Pdf/header";
import dayjs from "dayjs";

interface IProps {
  datas: any;
  filter: Date[];
}
export default function PrintRankingCollaborators({ datas, filter }: IProps) {
  const dataCalculator = (data: any, type: string) => {
    return `${formatCurrency(
      data.find((item: any) =>
        item.type.toUpperCase().includes(type.toUpperCase())
      )?.value || 0
    )}
             ${Number(
               data.find((item: any) =>
                 item.type.toUpperCase().includes(type.toUpperCase())
               )?.percent || 0
             ).toFixed(1)}%`;
  };
  function rangeDate() {
    const initial = filter[0];
    const final = filter[1];
    const initialDate = dayjs(initial).format("DD/MM/YYYY");
    const finalDate = dayjs(final).format("DD/MM/YYYY");
    if (initialDate === finalDate) {
      return initialDate;
    }
    return `${initialDate} até ${finalDate} `;
  }
  return (
    <Document>
      <Page orientation="landscape" style={styles.body}>
        <Header
          isMedicalAppointment={false}
          subtitle={`PERFORMANCE DE COLABORADORES - Ref. de apuração: ${rangeDate()}`}
        />
        <View style={{ ...styles.legendBox }}>
          <View>
            <Text>Colab : Colaborador</Text>
          </View>
          <View>
            <Text>Conv : Convênio</Text>
          </View>
          <View>
            <Text>Agend : Agendamentos</Text>
          </View>
          <View>
            <Text>O.S : Ordem de serviço</Text>
          </View>
          <View>
            <Text>T.M : Ticket Médio</Text>
          </View>
          <View>
            <Text>c : Consulta</Text>
          </View>
          <View>
            <Text>e : Exame</Text>
          </View>
          <View>
            <Text>p : Procedimento</Text>
          </View>
          <View>
            <Text>Transf : Transferência</Text>
          </View>
          <View>
            <Text>O.C : Orçamentos criados</Text>
          </View>
          <View>
            <Text>O.V : Orçamentos vendidos</Text>
          </View>
        </View>
        <View style={{ ...styles.tablemain, marginTop: 10 }}>
          <View
            style={{
              width: "100%",
              backgroundColor: "lightgray",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              ...styles.tableHeaderBox,
            }}
            fixed
          >
            <View style={{ ...styles.tableCel, width: "33.33%" }}>
              <Text style={styles.tableHeader}>Receita total</Text>
            </View>
            <View style={{ ...styles.tableCel, width: "33.33%" }}>
              <Text style={styles.tableHeader}>Convênio</Text>
            </View>
            <View style={{ ...styles.tableCel, width: "33.33%" }}>
              <Text style={styles.tableHeader}>Agendamentos</Text>
            </View>
            <View style={{ ...styles.tableCel, width: "33.33%" }}>
              <Text style={styles.tableHeader}>Total de O.S quitadas</Text>
            </View>
          </View>

          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View style={{ ...styles.tableCel, width: "33.33%" }}>
              <Text style={styles.tableHeader}>
                {formatCurrency(datas.total_all_groups)}
              </Text>
            </View>
            <View style={{ ...styles.tableCel, width: "33.33%" }}>
              <Text style={styles.tableHeader}>
                {formatCurrency(datas.total_all_groups_plan)}
              </Text>
            </View>
            <View style={{ ...styles.tableCel, width: "33.33%" }}>
              <Text style={styles.tableHeader}>
                {datas.total_all_appointmens}
              </Text>
            </View>
            <View style={{ ...styles.tableCel, width: "33.33%" }}>
              <Text style={styles.tableHeader}>{datas.total_all_orders}</Text>
            </View>
          </View>
        </View>

        {datas.groupByRoleResponse.map((e: any) => {
          return (
            <>
              <View
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "10px",
                  marginBottom: "5px",
                }}
              >
                <Text style={{ ...styles.tableHeader, fontSize: 10 }}>
                  {e.role}
                </Text>
              </View>
              <View>
                <View style={{ ...styles.tablemain }}>
                  <View
                    style={{
                      width: "100%",
                      backgroundColor: "lightgray",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      ...styles.tableHeaderBox,
                    }}
                    fixed
                  >
                    <View style={{ ...styles.tableCel, width: "33.33%" }}>
                      <Text style={styles.tableHeader}>Receita do grupo</Text>
                    </View>
                    <View style={{ ...styles.tableCel, width: "33.33%" }}>
                      <Text style={styles.tableHeader}>Convênio</Text>
                    </View>
                    <View style={{ ...styles.tableCel, width: "33.33%" }}>
                      <Text style={styles.tableHeader}>Agendamentos</Text>
                    </View>
                    <View style={{ ...styles.tableCel, width: "33.33%" }}>
                      <Text style={styles.tableHeader}>O.S quitadas</Text>
                    </View>
                  </View>
                  <View
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View style={{ ...styles.tableCel, width: "33.33%" }}>
                      <Text style={styles.tableHeader}>
                        {formatCurrency(e.total_amount_billed)}
                      </Text>
                    </View>
                    <View style={{ ...styles.tableCel, width: "33.33%" }}>
                      <Text style={styles.tableHeader}>
                        {formatCurrency(e.total_amount_plan)}
                      </Text>
                    </View>
                    <View style={{ ...styles.tableCel, width: "33.33%" }}>
                      <Text style={styles.tableHeader}>
                        {e.total_appointments}
                      </Text>
                    </View>
                    <View style={{ ...styles.tableCel, width: "33.33%" }}>
                      <Text style={styles.tableHeader}>{e.total_orders}</Text>
                    </View>
                  </View>
                </View>
              </View>
              <View style={{ ...styles.tablemain }}>
                <View
                  style={{
                    width: "100%",
                    backgroundColor: "lightgray",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    ...styles.tableHeaderBox,
                  }}
                  fixed
                >
                  <View style={{ ...styles.tableCel, width: "5.5%" }}>
                    <Text style={styles.tableHeader}>Colab.</Text>
                  </View>
                  <View style={{ ...styles.tableCel, width: "5.5%" }}>
                    <Text style={styles.tableHeader}>Conv.</Text>
                  </View>
                  <View style={{ ...styles.tableCel, width: "5.5%" }}>
                    <Text style={styles.tableHeader}>Receita</Text>
                  </View>
                  <View style={{ ...styles.tableCel, width: "5.5%" }}>
                    <Text style={styles.tableHeader}>Agend.</Text>
                  </View>
                  <View style={{ ...styles.tableCel, width: "5.5%" }}>
                    <Text style={styles.tableHeader}>O.S</Text>
                  </View>
                  <View style={{ ...styles.tableCel, width: "5.5%" }}>
                    <Text style={styles.tableHeader}>T.M</Text>
                  </View>
                  <View style={{ ...styles.tableCel, width: "5.5%" }}>
                    <Text style={styles.tableHeader}>C</Text>
                  </View>
                  <View style={{ ...styles.tableCel, width: "5.5%" }}>
                    <Text style={styles.tableHeader}>E</Text>
                  </View>
                  <View style={{ ...styles.tableCel, width: "5.5%" }}>
                    <Text style={styles.tableHeader}>P</Text>
                  </View>
                  <View style={{ ...styles.tableCel, width: "5.5%" }}>
                    <Text style={styles.tableHeader}>Dinheiro</Text>
                  </View>
                  <View style={{ ...styles.tableCel, width: "5.5%" }}>
                    <Text style={styles.tableHeader}>Pix</Text>
                  </View>
                  <View style={{ ...styles.tableCel, width: "5.5%" }}>
                    <Text style={styles.tableHeader}>Boleto</Text>
                  </View>
                  <View style={{ ...styles.tableCel, width: "5.5%" }}>
                    <Text style={styles.tableHeader}>Crédito</Text>
                  </View>
                  <View style={{ ...styles.tableCel, width: "5.5%" }}>
                    <Text style={styles.tableHeader}>Débito</Text>
                  </View>
                  <View style={{ ...styles.tableCel, width: "5.5%" }}>
                    <Text style={styles.tableHeader}>Transf.</Text>
                  </View>
                  <View style={{ ...styles.tableCel, width: "5.5%" }}>
                    <Text style={styles.tableHeader}>Descontos</Text>
                  </View>
                  <View style={{ ...styles.tableCel, width: "5.5%" }}>
                    <Text style={styles.tableHeader}>Combos</Text>
                  </View>
                  <View style={{ ...styles.tableCel, width: "5.5%" }}>
                    <Text style={styles.tableHeader}>O.C</Text>
                  </View>
                  <View style={{ ...styles.tableCel, width: "5.5%" }}>
                    <Text style={styles.tableHeader}>O.V</Text>
                  </View>
                </View>
                {e.data.map((data: any, index: number) => (
                  <>
                    <View
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        backgroundColor: `${index % 2 ? "#f5f5f5" : "#fff"}`,
                      }}
                    >
                      <View style={{ ...styles.tableCel, width: "5.5%" }}>
                        <Text style={styles.tableHeader}>
                          {data.name.toUpperCase()}
                        </Text>
                      </View>
                      <View style={{ ...styles.tableCel, width: "5.5%" }}>
                        <Text style={styles.tableHeader}>
                          {formatCurrency(data.amount_plan)}
                        </Text>
                      </View>
                      <View style={{ ...styles.tableCel, width: "5.5%" }}>
                        <Text style={styles.tableHeader}>
                          {formatCurrency(data.amount_billed)}
                        </Text>
                      </View>
                      <View style={{ ...styles.tableCel, width: "5.5%" }}>
                        <Text style={styles.tableHeader}>
                          {data.appointments_amount}
                        </Text>
                      </View>
                      <View style={{ ...styles.tableCel, width: "5.5%" }}>
                        <Text style={styles.tableHeader}>
                          {data.serviceorders_amount}
                        </Text>
                      </View>
                      <View style={{ ...styles.tableCel, width: "5.5%" }}>
                        <Text style={styles.tableHeader}>
                          {formatCurrency(data.medium_ticket)}
                        </Text>
                      </View>
                      <View style={{ ...styles.tableCel, width: "5.5%" }}>
                        <Text style={styles.tableHeader}>
                          {dataCalculator(data.appointments, "CONSULTAS")}
                        </Text>
                      </View>
                      <View style={{ ...styles.tableCel, width: "5.5%" }}>
                        <Text style={styles.tableHeader}>
                          {dataCalculator(data.appointments, "EXAMES")}
                        </Text>
                      </View>
                      <View style={{ ...styles.tableCel, width: "5.5%" }}>
                        <Text style={styles.tableHeader}>
                          {dataCalculator(data.appointments, "Procedimentos")}
                        </Text>
                      </View>
                      <View style={{ ...styles.tableCel, width: "5.5%" }}>
                        <Text style={styles.tableHeader}>
                          {dataCalculator(data.payments, "DINHEIRO")}
                        </Text>
                      </View>
                      <View style={{ ...styles.tableCel, width: "5.5%" }}>
                        <Text style={styles.tableHeader}>
                          {dataCalculator(data.payments, "PIX")}
                        </Text>
                      </View>
                      <View style={{ ...styles.tableCel, width: "5.5%" }}>
                        <Text style={styles.tableHeader}>
                          {dataCalculator(data.payments, "BOLETO")}
                        </Text>
                      </View>
                      <View style={{ ...styles.tableCel, width: "5.5%" }}>
                        <Text style={styles.tableHeader}>
                          {dataCalculator(data.payments, "CREDITO")}
                        </Text>
                      </View>
                      <View style={{ ...styles.tableCel, width: "5.5%" }}>
                        <Text style={styles.tableHeader}>
                          {dataCalculator(data.payments, "DEBITO")}
                        </Text>
                      </View>
                      <View style={{ ...styles.tableCel, width: "5.5%" }}>
                        <Text style={styles.tableHeader}>
                          {dataCalculator(data.payments, "TRANSFER")}
                        </Text>
                      </View>
                      <View style={{ ...styles.tableCel, width: "5.5%" }}>
                        <Text style={styles.tableHeader}>
                          {formatCurrency(data.discounts_amount)}
                        </Text>
                      </View>
                      <View style={{ ...styles.tableCel, width: "5.5%" }}>
                        <Text style={styles.tableHeader}>
                          {formatCurrency(data.combos_amount)}
                        </Text>
                      </View>
                      <View style={{ ...styles.tableCel, width: "5.5%" }}>
                        <Text style={styles.tableHeader}>
                          {`${formatCurrency(
                            data.customer_quotes_amount
                          )}  ${Number(data.customer_quotes_percent).toFixed(
                            1
                          )}%`}
                        </Text>
                      </View>
                      <View style={{ ...styles.tableCel, width: "5.5%" }}>
                        <Text style={styles.tableHeader}>
                          {`${formatCurrency(
                            data.customer_quotes_amount_done
                          )}  ${Number(
                            data.customer_quotes_percent_done
                          ).toFixed(1)}%`}
                        </Text>
                      </View>
                    </View>
                  </>
                ))}
              </View>
            </>
          );
        })}

        <View style={{ height: "4%" }} />

        <Text style={{ textAlign: "center", fontSize: 10, marginTop: 50 }}>
          ________________________________
        </Text>
        <Text style={{ textAlign: "center", fontSize: 10, marginTop: 5 }}>
          Assinatura responsável
        </Text>
        <Text
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          style={styles.footer}
          fixed
        />
      </Page>
    </Document>
  );
}

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});
Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});
const styles = StyleSheet.create({
  title: {
    fontSize: 13,
    textAlign: "center",
    fontWeight: "ultrabold",
    fontFamily: "Oswald",
  },
  textFixed: {
    fontSize: 8,
    fontFamily: "Oswald",
    fontWeight: "ultrabold",
    maxWidth: "60%",
    marginTop: 10,
    textTransform: "uppercase",
  },
  tableCel: {
    textAlign: "center",
    justifyContent: "center",
    margin: 0,
    paddingVertical: 4,
    paddingHorizontal: 1,
    borderRightWidth: 1,
    borderRightColor: "#e5e5e5",
  },
  footer: {
    position: "absolute",
    bottom: 5,
    right: 5,
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "right",
    fontFamily: "Oswald",
  },
  marginRight: {
    marginRight: 70,
  },
  marginTop: {
    marginTop: 15,
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 10,
  },
  titleRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  subtitle: {
    fontSize: 10,
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "Oswald",
  },
  image: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "50",
    alignContent: "center",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
  },
  tableHeaderView: {
    width: "30%",
    textAlign: "center",
    justifyContent: "center",
  },
  tableSecondaryHead: {
    width: "25%",
    textAlign: "center",
    justifyContent: "center",
  },
  header: {
    fontSize: 14,
    fontWeight: "bold",
    fontFamily: "Oswald",
  },
  subHeader: {
    fontSize: 12,
    fontFamily: "Oswald",
  },
  tableHeader: {
    fontSize: 9,
    fontWeight: "bold",
    fontFamily: "Oswald",
    textTransform: "uppercase",
  },
  tableText: {
    fontSize: 9,
    fontWeight: "bold",
    fontFamily: "Oswald",
  },
  sideText: {
    marginTop: 10,
    fontSize: 9,
    fontFamily: "Oswald",
  },
  boldText: {
    fontSize: 9,
    fontWeight: "bold",
    fontFamily: "Oswald",
  },
  text: {
    fontSize: 9,
    fontFamily: "Oswald",
    maxWidth: "60%",
    textAlign: "justify",
  },
  verticalHr: {
    height: "100%",
    width: 1,
    backgroundColor: "#000",
    marginRight: 10,
  },
  hr: {
    height: 1,
    width: "100%",
    backgroundColor: "#000",
  },
  flexDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  textLeft: {
    textAlign: "right",
  },
  flexDivInside: {
    display: "flex",
    paddingVertical: 3,
    //@ts-ignore
    alignItems: "space-between",
    width: "50%",
  },
  flexDivWithPadding: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 3,
    width: "100%",
    paddingLeft: 10,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  tablemain: {
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#e5e5e5",
  },
  tableHeaderBox: {
    backgroundColor: "#00A1A9",
    color: "#fff",
  },
  legendBox: {
    margin: 10,
    fontSize: 9,
    fontFamily: "Oswald",
    textAlign: "justify",
    textTransform: "uppercase",
  },
});
