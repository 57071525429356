import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "store/index";
import { toast } from "react-toastify";
import toastOptions from "utils/toastOptions";
import api from "utils/API";
import { IFilter } from "models/shared";
import { queryStringFromFilterArray } from "utils/network";
import { IHealthPlanReport } from "models/ProfessionalReport";

interface IInitialState {
  isFetchingHealthPlansReports: boolean;
  isInsertingHealthPlanProcedure: boolean;
  healthPlansReports: null | IHealthPlanReport[];
  page: number;
  total: number;
  healthPlansReportsFilterArray: IFilter[];
}

const initialState: IInitialState = {
  isFetchingHealthPlansReports: false,
  isInsertingHealthPlanProcedure: false,
  healthPlansReports: null,
  page: 0,
  total: 0,
  healthPlansReportsFilterArray: [
    { key: "idordemdeservico", value: null },
    { key: "idprofissional", value: null },
    { key: "idconvenio", value: null },
    { key: "idclinica", value: null },
    { key: "status", value: null },
    { key: "guia", value: null },
    { key: "loteassociado", value: null },
    { key: "by_payment_date", value: null },
    { key: "start_date", value: null },
    { key: "end_date", value: null },
  ],
};

const healthPlansReportsSlice = createSlice({
  name: "healthPlansReportsSlice",
  initialState,
  reducers: {
    setIsFetchingHealthPlansReports: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingHealthPlansReports = payload;
    },
    setHealthPlansReports: (
      state,
      {
        payload: { transactions, page, total },
      }: PayloadAction<{
        transactions: IHealthPlanReport[] | null;
        page: number;
        total: number;
      }>
    ) => {
      state.page = page;
      state.healthPlansReports = transactions;
      state.total = total;
    },
    updateHealthPlansReportsFilter: (
      state,
      { payload }: PayloadAction<IFilter>
    ) => {
      const index = state.healthPlansReportsFilterArray.findIndex(
        (item) => item.key === payload.key
      );

      if (index === -1) {
        state.healthPlansReportsFilterArray.push({
          key: payload.key,
          value: payload.value,
        });
      } else {
        state.healthPlansReportsFilterArray[index].value = payload.value;
      }
    },
    setIsInsertingHealthPlanProcedure: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isInsertingHealthPlanProcedure = payload;
    },
  },
});

export const fetchHealthPlansReports =
  ({ page = 1, limit = 9999 }: { page?: number; limit?: number }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingHealthPlansReports, setHealthPlansReports } =
      healthPlansReportsSlice.actions;
    try {
      const state = getState();
      const { healthPlansReportsFilterArray } = state.healthPlansReports;

      const queryParameters = queryStringFromFilterArray(
        healthPlansReportsFilterArray
      );
      const pageAndLimit =
        queryParameters.length === 0
          ? `?page=${page}&limit=${limit}`
          : `&page=${page}&limit=${limit}`;
      dispatch(setIsFetchingHealthPlansReports(true));

      const response = await api.get(
        `/api/financial/healthplan-report${queryParameters}${pageAndLimit}`
      );

      dispatch(setHealthPlansReports(response.data.data));
      dispatch(setIsFetchingHealthPlansReports(false));
    } catch (error: any) {
      dispatch(setIsFetchingHealthPlansReports(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const insertHealthPlanProcedure =
  ({ 
    id_service_health_plan_category,
    id_transaction,
    id_professional,
    attendance_time,
    email,
    password,
    reason,
    callback,
  }: { 
    id_service_health_plan_category: string; 
    id_transaction: string;
    id_professional: string;
    attendance_time: Date;
    email: string;
    password: string;
    reason: string;
    callback?: Function;
  }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsInsertingHealthPlanProcedure } =
      healthPlansReportsSlice.actions;
    try {
      dispatch(setIsInsertingHealthPlanProcedure(true));

      await api.post(`/api/financial/healthplan-report`, {
        id_service_health_plan_category,
        id_transaction,
        id_professional,
        attendance_time,
        email,
        password,
        reason
      });

      toast.success("Procedimento inserido com sucesso", toastOptions);

      if (callback) {
        callback();
      }
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setIsInsertingHealthPlanProcedure(false));
    }
  };

export const editHealthPlanProcedure =
  ({ 
    id_service_health_plan_category,
    id_transaction,
    email,
    password,
    reason,
    callback,
  }: { 
    id_service_health_plan_category: string; 
    id_transaction: string;
    email: string;
    password: string;
    reason: string;
    callback?: Function;
  }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsInsertingHealthPlanProcedure } =
      healthPlansReportsSlice.actions;
    try {
      dispatch(setIsInsertingHealthPlanProcedure(true));

      await api.put(`/api/financial/healthplan-report`, {
        id_service_health_plan_category,
        id_transaction,
        email,
        password,
        reason
      });

      toast.success("Procedimento modificado com sucesso", toastOptions);

      if (callback) {
        callback();
      }
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setIsInsertingHealthPlanProcedure(false));
    }
  };

export const { updateHealthPlansReportsFilter, setHealthPlansReports } =
  healthPlansReportsSlice.actions;

export default healthPlansReportsSlice.reducer;
