import {
  AnyAction,
  createSlice,
  PayloadAction,
  ThunkAction,
} from "@reduxjs/toolkit";
import {
  ICounterTypeService,
  ICounterTypeServiceForm,
  ISetsAndServiceModel,
  ITotemTypeService,
} from "models/CounterTypeService";
import { IFilter } from "models/shared";
import { toast } from "react-toastify";
import { AppThunk, rootState } from "store/index";
import api from "utils/API";
import { history } from "utils/history";
import { queryStringFromFilterArray } from "utils/network";
import toastOptions from "utils/toastOptions";

interface IInitialState {
  isFetchingCounterTypeService: boolean;
  isCreatingCounterTypeService: boolean;
  isDeletingCounterTypeService: boolean;
  isEditBondDialogOpen: boolean;
  amountOfItemsToShow: number;
  counterTypeServices: ICounterTypeService[] | null;
  allListCounterTypeServices: ICounterTypeService[] | null;
  currentCounterTypeService: ICounterTypeService | null;
  typeServiceTotem: ITotemTypeService[] | null;
  typeServiceWindowSets: ISetsAndServiceModel[] | null;
  toAddPage: number;
  toAddTotal: number;
  total: number;
  page: number;
  filterArray: IFilter[];
  pageToCounterTypeService: number;
}

const initialState: IInitialState = {
  isFetchingCounterTypeService: false,
  isCreatingCounterTypeService: false,
  isDeletingCounterTypeService: false,
  isEditBondDialogOpen: false,
  toAddPage: 0,
  toAddTotal: 0,
  counterTypeServices: null,
  allListCounterTypeServices: null,
  currentCounterTypeService: null,
  typeServiceTotem: null,
  typeServiceWindowSets: null,
  amountOfItemsToShow: 6,
  total: 0,
  page: 0,
  filterArray: [{ key: "nome", value: [] }],
  pageToCounterTypeService: 1,
};

const counterTypeServiceSlice = createSlice({
  name: "counterTypeServiceSlice",
  initialState,
  reducers: {
    setIsFetchingCounterTypeService: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingCounterTypeService = payload;
    },
    setIsCreatingCounterTypeService: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isCreatingCounterTypeService = payload;
    },
    setisDeletingCounterTypeService: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isDeletingCounterTypeService = payload;
    },
    setIsEditBondDialogOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isEditBondDialogOpen = payload;
    },
    setAmountOfItemsToShow: (state, { payload }: PayloadAction<number>) => {
      state.amountOfItemsToShow = payload;
    },
    setCounterTypeServices: (
      state,
      {
        payload: { counter_type_services, total, page },
      }: PayloadAction<{
        counter_type_services: ICounterTypeService[];
        total: number;
        page: number;
      }>
    ) => {
      state.counterTypeServices = counter_type_services;
      state.total = total;
      state.page = page;
    },
    setAllListCounterTypeServices: (
      state,
      {
        payload: { counter_type_services },
      }: PayloadAction<{
        counter_type_services: ICounterTypeService[];
      }>
    ) => {
      state.allListCounterTypeServices = counter_type_services;
    },
    setAllCounterTypeServices: (
      state,
      { payload }: PayloadAction<ICounterTypeService[]>
    ) => {
      state.counterTypeServices = payload;
    },
    setToAddPagination: (
      state,
      {
        payload: { total, page },
      }: PayloadAction<{
        page: number;
        total: number;
      }>
    ) => {
      state.toAddPage = page;
      state.toAddTotal = total;
    },
    setCurrentCounterTypeService: (
      state,
      { payload }: PayloadAction<ICounterTypeService>
    ) => {
      state.currentCounterTypeService = payload;
    },
    setTypeServiceWindowSets: (
      state,
      { payload }: PayloadAction<ISetsAndServiceModel[]>
    ) => {
      state.typeServiceWindowSets = payload;
    },
    setTypeServiceTotem: (
      state,
      { payload }: PayloadAction<ITotemTypeService[]>
    ) => {
      state.typeServiceTotem = payload;
    },
    setPageToCounterTypeService: (
      state,
      { payload }: PayloadAction<number>
    ) => {
      state.pageToCounterTypeService = payload;
    },
  },
});

export const fetchAllCounterTypeServices =
  ({
    clinicId,
    idbloco,
    idsetor,
  }: {
    clinicId: string;
    idsetor: string;
    idbloco: string;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsFetchingCounterTypeService, setAllCounterTypeServices } =
      counterTypeServiceSlice.actions;
    dispatch(setIsFetchingCounterTypeService(true));
    try {
      const response = await api.get(
        `/api/counter-type-services/all?clinicId=${clinicId}&idbloco=${idbloco}&idsetor=${idsetor}`
      );
      dispatch(setAllCounterTypeServices(response.data.data));
      dispatch(setIsFetchingCounterTypeService(false));
    } catch (error: any) {
      dispatch(setIsFetchingCounterTypeService(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

  export const fetchAllListCounterTypeServices =
  (): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsFetchingCounterTypeService, setAllListCounterTypeServices } =
      counterTypeServiceSlice.actions;
    dispatch(setIsFetchingCounterTypeService(true));
    try {
      const response = await api.get(
        `/api/counter-type-services` 
      );
      dispatch(setAllListCounterTypeServices({counter_type_services: response.data.data.counter_type_services}));
      dispatch(setIsFetchingCounterTypeService(false));
    } catch (error: any) {
      dispatch(setIsFetchingCounterTypeService(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchCounterTypeServices =
  ({
    page = 1,
    limit = 6,
    idclinica,
    idbloco,
    idsetor,
  }: {
    page?: number;
    limit?: number;
    idclinica?: string;
    idsetor?: string;
    idbloco?: string;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { setIsFetchingCounterTypeService, setCounterTypeServices } =
      counterTypeServiceSlice.actions;
    dispatch(setIsFetchingCounterTypeService(true));
    try {
      const state = getState();
      const { filterArray } = state.counter;
      const queryParameters = queryStringFromFilterArray(filterArray);
      const pageAndLimit =
        queryParameters.length === 0
          ? `?page=${page}&limit=${limit}`
          : `&page=${page}&limit=${limit}`;
      const response = await api.get(
        `/api/counter-type-services${pageAndLimit}&idclinica=${idclinica}&idbloco=${idbloco}&idsetor=${idsetor}`
      );
      dispatch(setCounterTypeServices(response.data.data));
      dispatch(setIsFetchingCounterTypeService(false));
    } catch (error: any) {
      dispatch(setIsFetchingCounterTypeService(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchCounterTypeServiceById =
  (
    counterTypeServiceId: string
  ): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsFetchingCounterTypeService, setCurrentCounterTypeService } =
      counterTypeServiceSlice.actions;
    dispatch(setIsFetchingCounterTypeService(true));
    try {
      const response = await api.get(
        `/api/counter-type-services/${counterTypeServiceId}`
      );
      dispatch(setCurrentCounterTypeService(response.data.data));
      dispatch(setIsFetchingCounterTypeService(false));
    } catch (error: any) {
      dispatch(setIsFetchingCounterTypeService(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const updateCounterTypeService =
  ({
    counterTypeService,
    counterTypeServiceId,
    idclinica,
    idbloco,
    idsetor,
    cb,
  }: {
    counterTypeService: ICounterTypeServiceForm;
    counterTypeServiceId: string | number;
    idclinica?: string;
    idsetor?: string;
    idbloco?: string;
    cb?: () => void;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingCounterTypeService } = counterTypeServiceSlice.actions;
    dispatch(setIsCreatingCounterTypeService(true));
    try {
      await api.put(`/api/counter-type-services/${counterTypeServiceId}`, {
        ...counterTypeService,
        idclinica: idclinica,
        idbloco,
        idsetor,
      });
      dispatch(setIsCreatingCounterTypeService(false));
      toast.success("Tipo de Senha atualizado", toastOptions);
      history.replace(
        `/clinics/${idclinica}/departments/${idsetor}/blocks/${idbloco}/attendance/counter-type-service`
      );
      if (cb) cb();
    } catch (error: any) {
      dispatch(setIsCreatingCounterTypeService(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const createCounterTypeService =
  (
    counterTypeService: ICounterTypeServiceForm,
    idclinica?: string,
    idsetor?: string,
    idbloco?: string
  ): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsCreatingCounterTypeService } = counterTypeServiceSlice.actions;
    dispatch(setIsCreatingCounterTypeService(true));
    try {
      await api.post(`/api/counter-type-services`, {
        ...counterTypeService,
        idclinica: idclinica,
        idsetor,
        idbloco,
      });
      dispatch(setIsCreatingCounterTypeService(false));
      toast.success("Tipo de Senha cadastrado", toastOptions);
      history.replace(
        `/clinics/${idclinica}/departments/${idsetor}/blocks/${idbloco}/attendance/counter-type-service`
      );
    } catch (error: any) {
      dispatch(setIsCreatingCounterTypeService(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const deletecounterTypeService =
  (
    counterTypeServiceId: string,
    idclinica: string,
    idbloco: string,
    idsetor: string
  ): AppThunk =>
  async (dispatch) => {
    const { setisDeletingCounterTypeService } = counterTypeServiceSlice.actions;
    dispatch(setisDeletingCounterTypeService(true));
    try {
      await api.delete(`/api/counter-type-services/${counterTypeServiceId}`);
      dispatch(setisDeletingCounterTypeService(false));
      toast.success(
        "Tipo de senha do Guichê excluida com sucesso",
        toastOptions
      );
      dispatch(fetchCounterTypeServices({ idclinica, idbloco, idsetor }));
    } catch (error: any) {
      dispatch(setisDeletingCounterTypeService(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchTypeServiceByWindowSetId =
  ({
    idconjuntoguiche,
  }: {
    idconjuntoguiche: string;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsFetchingCounterTypeService, setTypeServiceWindowSets } =
      counterTypeServiceSlice.actions;
    dispatch(setIsFetchingCounterTypeService(true));
    try {
      const response = await api.get(
        `/api/counter-type-services-by-windowsetsId?idconjuntoguiche=${idconjuntoguiche}`
      );
      dispatch(setTypeServiceWindowSets(response.data.data));
      dispatch(setIsFetchingCounterTypeService(false));
    } catch (error: any) {
      dispatch(setIsFetchingCounterTypeService(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchTypeServiceByTotemId =
  ({
    idtotem,
  }: {
    idtotem: string;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsFetchingCounterTypeService, setTypeServiceTotem } =
      counterTypeServiceSlice.actions;
    dispatch(setIsFetchingCounterTypeService(true));
    try {
      const response = await api.get(
        `/api/counter-type-services-by-totemid?idtotem=${idtotem}`
      );
      dispatch(setTypeServiceTotem(response.data.data));
      dispatch(setIsFetchingCounterTypeService(false));
    } catch (error: any) {
      dispatch(setIsFetchingCounterTypeService(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const {
  setIsEditBondDialogOpen,
  setPageToCounterTypeService,
  setAmountOfItemsToShow,
} = counterTypeServiceSlice.actions;

export default counterTypeServiceSlice.reducer;
