import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPaymentMachines } from "models/financeiro/PaymentMachines";
import { toast } from "react-toastify";
import { AppThunk } from "store";
import api from "utils/API";
import toastOptions from "utils/toastOptions";
import {
  PaymentMachinesFetchResponse,
  PaymentMachinesState,
} from "./PaymentMachinesReducer.types";
import { history } from "utils/history";
import { queryStringFromFilterArray } from "utils/network";
import { IFilter } from "models/shared";

const initialState: PaymentMachinesState = {
  paymentMachines: null,
  allPaymentMachines: [],
  paymentMachine: null,
  isFetchingPaymentMachines: false,
  isFetchingPaymentMachine: false,
  page: 1,
  total: 0,
  itemsPerPage: 6,
  isCreatingPaymentMachine: false,
  paymentMachinesFilterArray: [
    { key: "idmaquinapagamento", value: null },
    { key: "idclinica", value: null },
    { key: "formapagamento", value: null}
  ],
};

const paymentMachinesSlice = createSlice({
  name: "paymentMachines",
  initialState,
  reducers: {
    setPaymentMachines: (
      state,
      { payload }: PayloadAction<IPaymentMachines[] | null>
    ) => {
      state.paymentMachines = payload;
    },
    setAllPaymentMachines: (
      state,
      { payload }: PayloadAction<IPaymentMachines[]>
    ) => {
      state.allPaymentMachines = payload;
    },
    setPaymentMachine: (
      state,
      { payload }: PayloadAction<IPaymentMachines>
    ) => {
      state.paymentMachine = payload;
    },
    setIsFetchingPaymentMachines: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingPaymentMachines = payload;
    },
    setIsFetchingPaymentMachine: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingPaymentMachine = payload;
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setTotal: (state, { payload }: PayloadAction<number>) => {
      state.total = payload;
    },
    setItemsPerPage: (state, { payload }: PayloadAction<number>) => {
      state.itemsPerPage = payload;
    },
    setIsCreatingPaymentMachine: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isCreatingPaymentMachine = payload;
    },
    updatePaymentMachinesFilterArray: (
      state,
      { payload }: PayloadAction<IFilter>
    ) => {
      const index = state.paymentMachinesFilterArray.findIndex(
        (item) => item.key === payload.key
      );
      state.paymentMachinesFilterArray[index].value = payload.value;
    },
  },
});

export const fetchPaymentMachines =
  ({ page = 1, limit = 6 }: { page?: number; limit?: number }): AppThunk =>
  async (dispatch, getState) => {
    const {
      setIsFetchingPaymentMachines,
      setPaymentMachines,
      setPage,
      setTotal,
    } = paymentMachinesSlice.actions;
    const { paymentMachinesFilterArray } = getState().paymentMachines;

    dispatch(setIsFetchingPaymentMachines(true));
    const queryParameters = queryStringFromFilterArray(
      paymentMachinesFilterArray
    );
    const pageAndLimit =
      queryParameters.length === 0
        ? `?page=${page}&limit=${limit}`
        : `&page=${page}&limit=${limit}`;

    try {
      const { data }: PaymentMachinesFetchResponse = await api.get(
        `/api/machine-payment${queryParameters}${pageAndLimit}`
      );
      dispatch(setPaymentMachines(data.data.payment_machines));
      dispatch(setPage(data.data.page));
      dispatch(setTotal(data.data.total));
    } catch (error: any) {
      console.log(error);

      if (error.response) {
        toast.error(error.response.data.message, toastOptions);
      }
    } finally {
      dispatch(setIsFetchingPaymentMachines(false));
    }
  };

export const fetchAllPaymentMachines =
  (): AppThunk => async (dispatch, getState) => {
    const { setIsFetchingPaymentMachines, setAllPaymentMachines } =
      paymentMachinesSlice.actions;
    dispatch(setIsFetchingPaymentMachines(true));

    try {
      const { data }: PaymentMachinesFetchResponse = await api.get(
        `/api/machine-payment?page=1&limit=999`
      );
      dispatch(setAllPaymentMachines(data.data.payment_machines));
    } catch (error: any) {
      console.log(error);

      if (error.response) {
        toast.error(error.response.data.message, toastOptions);
      }
    } finally {
      dispatch(setIsFetchingPaymentMachines(false));
    }
  };

export const fetchPaymentMachinesById =
  (paymentMachineId: string): AppThunk =>
  async (dispatch, getState) => {
    const { setPaymentMachine, setIsFetchingPaymentMachine } =
      paymentMachinesSlice.actions;
    dispatch(setIsFetchingPaymentMachine(true));
    try {
      const { data } = await api.get(
        `/api/machine-payment/${paymentMachineId}`
      );
      dispatch(setPaymentMachine(data.data));
    } catch (error: any) {
      console.log(error);
      if (error.response) {
        toast.error(error.response.data.message, toastOptions);
      }
    } finally {
      dispatch(setIsFetchingPaymentMachine(false));
    }
  };

export const createPaymentMachine =
  (paymentMachine: IPaymentMachines): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setIsCreatingPaymentMachine(true));
    try {
      await api.post("/api/machine-payment", paymentMachine);
      toast.success("Máquina de pagamento criada com sucesso");
      history.push("/financeiro/maquinas");
    } catch (error: any) {
      console.log(error);
      if (error.response) {
        toast.error(error.response.data.message, toastOptions);
      }
    } finally {
      dispatch(setIsCreatingPaymentMachine(false));
    }
  };

export const editPaymentMachine =
  ({
    paymentMachineId,
    body,
  }: {
    paymentMachineId: string;
    body: IPaymentMachines;
  }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsCreatingPaymentMachine } = paymentMachinesSlice.actions;
    dispatch(setIsCreatingPaymentMachine(true));
    try {
      await api.put(`/api/machine-payment/${paymentMachineId}`, body);
      toast.success("Máquina de pagamento editada com sucesso");
      history.push("/financeiro/maquinas");
    } catch (error: any) {
      console.log(error);
      if (error.response) {
        toast.error(error.response.data.message, toastOptions);
      }
    } finally {
      dispatch(setIsCreatingPaymentMachine(false));
    }
  };

export const deletePaymentMachine =
  (paymentMachine: string): AppThunk =>
  async (dispatch, getState) => {
    const { page, itemsPerPage } = getState().paymentMachines;
    const { setIsFetchingPaymentMachines } = paymentMachinesSlice.actions;
    dispatch(setIsFetchingPaymentMachines(true));
    try {
      await api.delete(`/api/machine-payment/${paymentMachine}`);
      dispatch(fetchPaymentMachines({ page, limit: itemsPerPage }));
      toast.success("Status da máquina de pagamento alterado com sucesso");
    } catch (error: any) {
      console.log(error);
      if (error.response) {
        toast.error(error.response.data.message, toastOptions);
      }
    } finally {
      dispatch(setIsFetchingPaymentMachines(false));
    }
  };

export const {
  setPaymentMachines,
  setIsFetchingPaymentMachines,
  setPage,
  setTotal,
  setIsCreatingPaymentMachine,
  setIsFetchingPaymentMachine,
  setItemsPerPage,
  setAllPaymentMachines,
  updatePaymentMachinesFilterArray,
} = paymentMachinesSlice.actions;

export default paymentMachinesSlice.reducer;
