import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import {
  Alert,
  AlertTitle,
  Autocomplete,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";

import { ISelectOption } from "components/CallPatient/CallPatient.types";
import { FormBaseContainer } from "components/layout/base";
import { Formik, Form as FormikForm } from "formik";
// @ts-ignore
import { FormikTextField } from "formik-material-fields";
import { useDispatch, useSelector } from "react-redux";
import { rootState } from "store";
import {
  ICheckinRule,
  createCheckinRolesAppointmentWebSetup
} from "store/reducers/AppointmentWebSetup";
import { fetchServiceGroups } from "store/reducers/ServiceGroupsReducer";
import { initialState, validationSchema } from "./data";
// @ts-ignore
import { Close } from "@material-ui/icons";
import { fetchClinics } from "store/reducers/ClinicsReducer";

interface IProps {
  open: boolean;
  onClose: Function;
  checkin: ICheckinRule | null;
}

export const AddAppointmentCheckinRoleModal = ({
  onClose,
  open,
  checkin,
}: IProps) => {
  const [initialValues, setInitialValues] = useState(initialState);
  const [clinicOptions, setClinicOptions] = useState<ISelectOption[]>([]);
  const [selectedClinic, setSelectedClinic] = useState<ISelectOption>({
    label: "",
    value: "",
  });

  const dispatch = useDispatch();

  const { isFetchingClinics, clinics } = useSelector(
    (state: rootState) => state.clinics
  );

  const { currentAppointmentWebSetup, isCreatingRole } = useSelector(
    (state: rootState) => state.appointmentWebSetup
  );

  const handleChangeClinic = (
    event: any,
    newValue: ISelectOption,
    setFieldValue: Function
  ) => {
    setSelectedClinic(newValue);
    if (newValue) {
      setFieldValue("idclinica", newValue.value);
    } else {
      setFieldValue("idclinica", "");
    }
  };

  useEffect(() => {
    if (checkin) {
      const clinic = clinics ? clinics.find(clin => clin.idclinica === checkin.idclinica) : null;
      if(clinic) {
        setSelectedClinic({
          label: clinic.nome.toUpperCase(),
          value: clinic.idclinica,
        })
      }
      
      setInitialValues({
        url: checkin.url,
        idclinica: checkin.idclinica
      });
    }
  }, [checkin, clinics]);

  const handleSubmit = (data: ICheckinRule) => {
    dispatch(
      createCheckinRolesAppointmentWebSetup({
        data: data,
      })
    );
    onClose();
  };

  useEffect(() => {
    setInitialValues(initialState);
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      fetchClinics({
        limit: 100000,
        page: 1,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      fetchServiceGroups({
        limit: 100000,
        forceAll: true,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (clinics) {
      let clinicssEnabled = clinics.filter(
        (clinic) => clinic.agendamentoonline
      );

      setClinicOptions(
        clinicssEnabled.map(({ idclinica, nome }) => ({
          label: nome.toUpperCase(),
          value: idclinica,
        }))
      );
    }
  }, [clinics, currentAppointmentWebSetup]);

  const downloadQR = () => {
    const svg = document.getElementById("QRCode");
    if (svg) {
      const svgData = new XMLSerializer().serializeToString(svg);
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      if (ctx) {
        const img = new Image();
        img.onload = () => {
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          const pngFile = canvas.toDataURL("image/png");
          const downloadLink = document.createElement("a");
          downloadLink.download = "QRCode";
          downloadLink.href = `${pngFile}`;
          downloadLink.click();
        };
        img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
      }
    }
  };

  return (
    <Dialog open={open} onClose={() => onClose()} maxWidth="lg">
      <DialogTitle>
        Configurar QR Code para checkin online
        <IconButton
          onClick={() => onClose()}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <FormBaseContainer>
          <Formik
            onSubmit={(values) => handleSubmit(values)}
            validationSchema={validationSchema}
            initialValues={initialValues}
            enableReinitialize
          >
            {({ errors, setFieldValue, values }) => (
              <FormikForm>
                <Grid
                  container
                  spacing={2}
                  style={{
                    marginTop: 2,
                    marginBottom: 48,
                  }}
                >
                  <Grid item lg={12}>
                    <Alert color="info">
                      Para configurar os QR Code, selecione a clínica que
                      utilizará o checkin online e informe a URL do agendamento
                      online!
                    </Alert>
                  </Grid>
                  <Grid item lg={6}>
                    <Autocomplete
                      name="idclinica"
                      getOptionLabel={(option: ISelectOption) => option.label}
                      options={clinicOptions}
                      disabled={isFetchingClinics || checkin ? true : false}
                      value={selectedClinic}
                      loading={isFetchingClinics}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      getOptionSelected={(
                        option: ISelectOption,
                        value: ISelectOption
                      ) => {
                        return value.value === option.value;
                      }}
                      // @ts-ignore
                      onChange={(event: any, newValue: ISelectOption) => {
                        handleChangeClinic(event, newValue, setFieldValue);
                      }}
                      fullWidth
                      renderInput={(params: any) => (
                        <TextField
                          error={errors.idclinica}
                          helperText={errors.idclinica}
                          name="idclinica"
                          style={{ minWidth: 200 }}
                          variant="outlined"
                          placeholder="Selecione uma clínica..."
                          {...params}
                          label="Clínica"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item lg={6}>
                    <FormikTextField
                      name="url"
                      variant="outlined"
                      placeholder="Digite a URL do agendamento online"
                      label="URL Agendamento Online"
                      fullWidth
                    />
                  </Grid>

                  {selectedClinic && values.url && (
                    <Grid item lg={12}>
                      <Grid
                        container
                        spacing={2}
                        style={{
                          marginTop: 2,
                        }}
                      >
                        <Grid item lg={12}>
                          <Alert color="warning">
                            <AlertTitle>Aviso</AlertTitle>
                            Você pode utilizar o QR Code abaixo na clínica{" "}
                            {selectedClinic.label}
                          </Alert>
                        </Grid>
                        <Grid item lg={12}>
                          <Grid
                            container
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Grid item lg={4}>
                              <QRCode
                                // size={256}
                                id="QRCode"
                                style={{
                                  height: "auto",
                                  maxWidth: "100%",
                                  width: "100%",
                                }}
                                value={`${values.url}/paciente/dashboard`}
                                viewBox={`0 0 256 256`}
                              />
                              <Button
                                color="primary"
                                fullWidth
                                variant="outlined"
                                size="small"
                                style={{
                                  marginTop: 4,
                                }}
                                onClick={() => downloadQR()}
                              >
                                Baixar imagem
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          lg={12}
                          style={{
                            marginTop: 16,
                          }}
                        >
                          <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            fullWidth
                            size="large"
                            disabled={isCreatingRole || !selectedClinic}
                          >
                            {checkin ? `Atualizar QR Code` :`Cadastrar QR Code`}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </FormikForm>
            )}
          </Formik>
        </FormBaseContainer>
      </DialogContent>
    </Dialog>
  );
};
