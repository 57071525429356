import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IinitialState {
  forms: { key: string; data: any }[];
}

const initialState: IinitialState = {
  forms: [],
};

const persistFormSlice = createSlice({
  name: "persistFormSlice",
  initialState,
  reducers: {
    persist: (
      state,
      { payload }: PayloadAction<{ key: string; data: any }>
    ) => {
      const index = state.forms.findIndex((i) => i.key === payload.key);
      if (index >= 0) {
        state.forms[index] = payload;
      } else {
        state.forms.push(payload);
      }
    },
    remove: (state, { payload }: PayloadAction<string>) => {
      state.forms = state.forms.filter((i) => i.key !== payload);
    },
  },
});

export const {
  persist: persistActionCreator,
  remove: removeActionCreator,
} = persistFormSlice.actions;

export default persistFormSlice.reducer;
